import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableColumnDrCrComponent } from "../common/dgdgtable/DGDGTableColumnDrCrComponent";

export class SalesManagerPayrollFinancialDetailComponent extends React.Component {
    constructor(props) {
        super(props);

        this.onCopyClick = this.onCopyClick.bind(this);
    }

    onCopyClick(event) {
        try {
            let amount = 0;
            let tableText = "Store #\tCategory\tSub Category\tDescription\tAccount Number\tAcct Type\tAccount Description\tType\tAmount\tPCL\r\n";
            this.props.tableData.forEach((row) => {
                amount += CommonService.parseDecimal(row.amt);
                tableText += row.sn + "\t" + (row.lc ? row.lc : "") + "\t" + (row.lsc ? row.lsc : "") + "\t" +
                    (row.ld ? row.ld : "") + "\t" + (row.an ? row.an : "") + "\t" + (row.at ? row.at : "") + "\t" + (row.ad ? row.ad : "") + "\t" +
                    (row.amt ? CommonService.getAmountType(row.amt) : "") + "\t" + (row.amt ? row.amt : "") + "\t" + (row.pcl ? row.pcl : "") + "\r\n";
            });
            tableText += "Total\t\t\t\t\t\t\t" + CommonService.getAmountType(amount) + "\t" + amount + "\t";
            navigator.clipboard.writeText(tableText);
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("FinancialStatementDetailComponent", "onCopyClick", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.props.showDialog} title={this.props.tableTitle}
            cssName="dgdg-service-manager-payroll-details-dialog"
            onCancelClick={this.props.onCancelClick}
        >
            <DGDGModalDialogHeaderButtonComponent>
                <button className="btn btn-primary" onClick={(event) => this.onCopyClick(event)}>
                    Copy<span className="far fa-copy dgdg-icon" />
                </button>
            </DGDGModalDialogHeaderButtonComponent>
            <div className="dgdg-report-body">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} cardCssName="dgdg-full-width" cardBlockCssName="overflow-auto" tableData={this.props.tableData} tableTitl={this.props.tableTitle} defaultSortColumn={CommonService.ordinalColumnName} defaultSortOrder="Asc" paintTable={this.props.paintTable} showFooter>
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <DGDGTableColumnComponent headerText="Store #" dataColumn="sn" sortColumn="sn" filterColumn="sn" footerText="Total" />
                        <DGDGTableColumnComponent headerText="Category" dataColumn="lc" sortColumn="lc" filterColumn="lc" />
                        <DGDGTableColumnComponent headerText="Sub Category" dataColumn="lsc" sortColumn="lsc" filterColumn="lsc" />
                        <DGDGTableColumnComponent headerText="Description" dataColumn="ld" sortColumn="ld" filterColumn="ld" />
                        <DGDGTableColumnComponent headerText="Account Number" dataType="string" dataColumn="an" sortColumn="an" filterColumn="an" />
                        <DGDGTableColumnComponent headerText="Acct Type" dataColumn="at" sortColumn="at" filterColumn="at" />
                        <DGDGTableColumnComponent headerText="Account Description" dataColumn="ad" sortColumn="ad" filterColumn="ad" />
                        <DGDGTableColumnDrCrComponent headerText="Type" dataColumn="amt" sortColumn="amt" footerFunction="sum" />
                        {
                            (this.props.enableDetailAmountClick)
                                ? <DGDGTableColumnComponent headerText="Amount" onCellClick={this.props.onLineDetailAmountClick} dataColumn="amt" sortColumn="amt" filterColumn="amt" dataType="formatted_number_2" isDrCr footerFunction="sum" cssName="dgdg-font-underline" onFooterCellClick={this.props.onLineDetailTotalAmountClick} footerCssName="text-right" />
                                : <DGDGTableColumnComponent headerText="Amount" dataColumn="amt" sortColumn="amt" filterColumn="amt" dataType="formatted_number_2" isDrCr footerFunction="sum" footerCssName="text-right" />
                        }
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
            {
                this.props.blobName && this.props.isDeveloper
                    ? <div className="text-success">
                        {this.props.blobName}
                    </div>
                    : null
            }
        </DGDGModalDialogComponent>;
    }
}
