import React from "react";
import moment from "moment";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";

export class CovidSubmitComponent extends React.Component {
    pageTitle = "Covid Submit";

    render() {
        return <DGDGPageComponent>
            <div className="dgdg-covid-submit">Thank you for submitting your vaccination status on {moment(this.props.location.state.signedDate).format("MM/DD/YYYY hh:mm:ss A")}.</div>
        </DGDGPageComponent>;
    }
}
