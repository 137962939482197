import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

export class PartsPayrollDetailComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payrollDetail: null,
            payrollDetailEditComponentTop: null,
        };
    }

    pageTitle = "Parts Payroll";

    render() {
        return <div className="dgdg-payroll" style={{ "marginLeft": "60px" }}>
            <table width="100%">
                <tbody>
                    <tr>
                        <td className="dgdg-no-border" style={{ "width": "170px" }}>Pay Month: {CommonService.formatMonthYear(moment(this.props.payrollMonth))}</td>
                        <td className="dgdg-no-border" style={{ "width": "150px" }}>Plan Name: {this.props.rowData.plan_name}</td>
                        <td className="dgdg-no-border" style={{ "width": "150px" }}>Wage Rate: {CommonService.formatRate(this.props.rowData.wage_rate)}</td>
                        <td className="dgdg-no-border" style={{ "width": "200px" }}>Mid Month Advance: {CommonService.formatCurrency_2(this.props.rowData.mid_month_advance)}</td>
                        <td className="dgdg-no-border" style={{ "width": "150px" }}>Monthy Guarantee: {CommonService.formatCurrency_2(this.props.rowData.monthly_guarantee)}</td>
                        <td className="dgdg-no-border" style={{ "width": "100px" }}>CSI: {CommonService.formatBoolString(this.props.rowData.csi)}</td>
                        <td className="dgdg-no-border" style={{ "width": "200px" }}>Parts Commissionable Gross: {CommonService.formatCurrency_2(this.props.rowData.parts_commissionable_gross)}</td>
                        <td className="dgdg-no-border">
                            {
                                (this.props.isEditEnabled)
                                    ? <button type="button" className="btn btn-link float-right" disabled={this.props.rowData.disable_edit} onClick={(event) => this.props.onEditClick(event, this.props.rowData)}>
                                        <span className="fas fa-edit" />
                                    </button>
                                    : null
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className="dgdg-payroll-period">
                <tbody>
                    <tr>
                        <td>Pay Period: 1</td>
                        <td>Commission Bonus: {CommonService.formatCurrency_2(this.props.rowData.pay_1_commission_bonus)}</td>
                        <td>Sick Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_1_sick_labor_rate)}</td>
                        <td>PTO Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_1_pto_labor_rate)}</td>
                        <td>Berv Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_1_bereavement_labor_rate)}</td>
                        <td>Holiday Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_1_holiday_labor_rate)}</td>
                        <td>Training Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_2_training_labor_rate)}</td>
                        <td>Non Commission Bonus: {CommonService.formatCurrency_2(this.props.rowData.pay_1_non_commission_bonus)}</td>
                        <td>Mealtime Prem Hours: {CommonService.formatNumber_1(this.props.rowData.pay_1_mealtime_premium_hours)}</td>
                        <td>Comments: {this.props.rowData.pay_1_comments}</td>
                    </tr>
                    <tr>
                        <td>Pay Period: 2</td>
                        <td>Commission Bonus: {CommonService.formatCurrency_2(this.props.rowData.pay_2_commission_bonus)}</td>
                        <td>Sick Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_2_sick_labor_rate)}</td>
                        <td>PTO Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_2_pto_labor_rate)}</td>
                        <td>Berv Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_2_bereavement_labor_rate)}</td>
                        <td>Holiday Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_2_holiday_labor_rate)}</td>
                        <td>Training Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_2_training_labor_rate)}</td>
                        <td>Non Commission Bonus: {CommonService.formatCurrency_2(this.props.rowData.pay_2_non_commission_bonus)}</td>
                        <td>Mealtime Prem Hours: {CommonService.formatNumber_1(this.props.rowData.pay_2_mealtime_premium_hours)}</td>
                        <td>Comments: {this.props.rowData.pay_2_comments}</td>
                    </tr>
                </tbody>
            </table>
        </div>;
    }
}
