import React, { Fragment } from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

export class SalesStoreReportFooterRowComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return <tr className="dgdg-sales-store-bg-title-foot font-weight-bold">
            <td className="text-right">
                {
                    this.props.summary
                        ? this.props.summary.store_name
                        : null
                }
            </td>
            <td className="text-center">
                {
                    this.props.summary
                        ? this.props.summary.volume
                        : null
                }
            </td>
            <td className="text-right">
                {
                    this.props.summary
                        ? CommonService.formatCurrency(this.props.summary.front_pvr)
                        : null
                }
            </td>
            <td className="text-right">
                {
                    this.props.summary
                        ? CommonService.formatCurrency(this.props.summary.front_gross)
                        : null
                }
            </td>
            <td className="text-right">
                {
                    this.props.summary
                        ? CommonService.formatCurrency(this.props.summary.back_pvr)
                        : null
                }
            </td>
            <td className="text-right">
                {
                    this.props.summary
                        ? CommonService.formatCurrency(this.props.summary.back_gross)
                        : null
                }
            </td>
            <td className="text-right">
                {
                    this.props.summary
                        ? CommonService.formatCurrency(this.props.summary.total_pvr)
                        : null
                }
            </td>
            <td className="text-right">
                {
                    this.props.summary
                        ? CommonService.formatCurrency(this.props.summary.total_gross)
                        : null
                }
            </td>
            {
                !this.props.isDetailsHidden
                    ? <Fragment>

                        {
                            this.props.title === "TOTAL"
                                ? <Fragment>
                                    <td colSpan="2" className="dgdg-sales-store-bg dgdg-no-border-right" />
                                    <td colSpan="2" className="dgdg-sales-store-name-cell">
                                        {
                                            this.props.summary
                                                ? <div className="text-right dgdg-font-blue dgdg-sales-store-name">{this.props.summary.store_name}</div>
                                                : null
                                        }
                                    </td>
                                </Fragment>
                                : <Fragment>
                                    <td className="dgdg-sales-store-bg dgdg-no-border-right" />
                                    <td colSpan="2" className="dgdg-sales-store-name-cell">
                                        {
                                            this.props.summary
                                                ? <div className="text-right dgdg-font-blue dgdg-sales-store-name">{this.props.summary.store_name}</div>
                                                : null
                                        }
                                    </td>
                                    <td className={(this.props.summary && this.props.summary.volume_goal_highlight === 1 ? "text-center dgdg-sales-store-bg-flag-red" : "text-center")}>
                                        {
                                            this.props.summary
                                                ? this.props.summary.volume_goal
                                                : null
                                        }
                                    </td>
                                </Fragment>
                        }
                        <td className="text-center">
                            {
                                this.props.summary
                                    ? CommonService.formatNumber(this.props.summary.volume_track)
                                    : null
                            }
                        </td>
                        <td className="text-right">
                            {
                                this.props.summary
                                    ? CommonService.formatCurrency(this.props.summary.total_gross_track)
                                    : null
                            }
                        </td>
                        <td className="dgdg-sales-store-bg dgdg-no-border-right" />
                        <td className="text-center" style={{ backgroundColor: this.props.summary ? this.props.summary.yoy_volume_delta_pct_color : "" }}>
                            {
                                this.props.summary
                                    ? CommonService.formatPercentage(this.props.summary.yoy_volume_delta_pct)
                                    : null
                            }
                        </td>
                        <td className="text-center" style={{ backgroundColor: this.props.summary ? this.props.summary.yoy_total_gross_delta_pct_color : "" }}>
                            {
                                this.props.summary
                                    ? CommonService.formatPercentage(this.props.summary.yoy_total_gross_delta_pct)
                                    : null
                            }
                        </td>
                        <td className="text-right" style={{ backgroundColor: this.props.summary ? this.props.summary.yoy_front_pvr_delta_color : "" }}>
                            {
                                this.props.summary
                                    ? CommonService.formatCurrency(this.props.summary.yoy_front_pvr_delta)
                                    : null
                            }
                        </td>
                        <td className="text-right" style={{ backgroundColor: this.props.summary ? this.props.summary.yoy_back_pvr_delta_color : "" }}>
                            {
                                this.props.summary
                                    ? CommonService.formatCurrency(this.props.summary.yoy_back_pvr_delta)
                                    : null
                            }
                        </td>

                    </Fragment>
                    : null
            }
        </tr>;
    }
}
