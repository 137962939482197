import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGFormCheckboxComponent } from "../common/dgdgcheckbox/DGDGFormCheckboxComponent";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";

export class FIPayrollDetailEditComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            if (state.showProgress) {
                newState.showProgress = !props.isSaveComplete;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            csi: "",
            pay_1_cit: "",
            ally: "",
            dmv: "",
            new_product_adjustment: "",
            new_reserve_adjustment: "",
            used_product_adjustment: "",
            used_reserve_adjustment: "",
            pay_1_payroll_bonus: "",
            pay_1_guaranteed_work_days: "",
            pay_1_sick_labor_rate: "",
            pay_1_pto_labor_rate: "",
            pay_1_bereavement_labor_rate: "",
            pay_1_holiday_labor_rate: "",
            pay_1_non_commission_flat_sum_bonus_spiff: "",
            pay_1_mealtime_premium_hours: "",
            pay_1_comments: "",
            pay_2_cit: "",
            pay_2_payroll_bonus: "",
            pay_2_guaranteed_work_days: "",
            pay_2_sick_labor_rate: "",
            pay_2_pto_labor_rate: "",
            pay_2_bereavement_labor_rate: "",
            pay_2_holiday_labor_rate: "",
            pay_2_non_commission_flat_sum_bonus_spiff: "",
            pay_2_mealtime_premium_hours: "",
            pay_2_comments: ""
        };

        this.onTextChange = this.onTextChange.bind(this);
        this.onCheckChanged = this.onCheckChanged.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.payrollDetail
                && (!prevProps.payrollDetail || prevProps.payrollDetail !== this.props.payrollDetail)) {
                this.setState({
                    csi: this.props.payrollDetail.csi,
                    ally: this.props.payrollDetail.ally,
                    dmv: this.props.payrollDetail.dmv,
                    new_product_adjustment: this.props.payrollDetail.new_product_adjustment ? CommonService.formatNumber_2(this.props.payrollDetail.new_product_adjustment) : "",
                    new_reserve_adjustment: this.props.payrollDetail.new_reserve_adjustment ? CommonService.formatNumber_2(this.props.payrollDetail.new_reserve_adjustment) : "",
                    used_product_adjustment: this.props.payrollDetail.used_product_adjustment ? CommonService.formatNumber_2(this.props.payrollDetail.used_product_adjustment) : "",
                    used_reserve_adjustment: this.props.payrollDetail.used_reserve_adjustment ? CommonService.formatNumber_2(this.props.payrollDetail.used_reserve_adjustment) : "",
                    pay_1_cit: this.props.payrollDetail.pay_1_cit,
                    pay_1_payroll_bonus: this.props.payrollDetail.pay_1_payroll_bonus ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_payroll_bonus) : "",
                    pay_1_guaranteed_work_days: this.props.payrollDetail.pay_1_guaranteed_work_days ? this.props.payrollDetail.pay_1_guaranteed_work_days : "",
                    pay_1_sick_labor_rate: this.props.payrollDetail.pay_1_sick_labor_rate ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_sick_labor_rate) : "",
                    pay_1_pto_labor_rate: this.props.payrollDetail.pay_1_pto_labor_rate ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_pto_labor_rate) : "",
                    pay_1_bereavement_labor_rate: this.props.payrollDetail.pay_1_bereavement_labor_rate ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_bereavement_labor_rate) : "",
                    pay_1_holiday_labor_rate: this.props.payrollDetail.pay_1_holiday_labor_rate ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_holiday_labor_rate) : "",
                    pay_1_non_commission_flat_sum_bonus_spiff: this.props.payrollDetail.pay_1_non_commission_flat_sum_bonus_spiff ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_non_commission_flat_sum_bonus_spiff) : "",
                    pay_1_mealtime_premium_hours: this.props.payrollDetail.pay_1_mealtime_premium_hours !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_mealtime_premium_hours) : "",
                    pay_1_comments: this.props.payrollDetail.pay_1_comments ? this.props.payrollDetail.pay_1_comments : "",
                    pay_2_cit: this.props.payrollDetail.pay_2_cit,
                    pay_2_payroll_bonus: this.props.payrollDetail.pay_2_payroll_bonus ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_payroll_bonus) : "",
                    pay_2_guaranteed_work_days: this.props.payrollDetail.pay_2_guaranteed_work_days ? this.props.payrollDetail.pay_2_guaranteed_work_days : "",
                    pay_2_sick_labor_rate: this.props.payrollDetail.pay_2_sick_labor_rate ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_sick_labor_rate) : "",
                    pay_2_pto_labor_rate: this.props.payrollDetail.pay_2_pto_labor_rate ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_pto_labor_rate) : "",
                    pay_2_bereavement_labor_rate: this.props.payrollDetail.pay_2_bereavement_labor_rate ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_bereavement_labor_rate) : "",
                    pay_2_holiday_labor_rate: this.props.payrollDetail.pay_2_holiday_labor_rate ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_holiday_labor_rate) : "",
                    pay_2_non_commission_flat_sum_bonus_spiff: this.props.payrollDetail.pay_2_non_commission_flat_sum_bonus_spiff ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_non_commission_flat_sum_bonus_spiff) : "",
                    pay_2_mealtime_premium_hours: this.props.payrollDetail.pay_2_mealtime_premium_hours !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_mealtime_premium_hours) : "",
                    pay_2_comments: this.props.payrollDetail.pay_2_comments ? this.props.payrollDetail.pay_2_comments : ""
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "FI Payroll";

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCheckChanged(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event) {
        try {
            this.setState({ showProgress: true });
            let inputData = {
                payrollMonth: CommonService.formatServerDateTime(this.props.payrollMonth),
                storeNumber: this.props.payrollDetail.store_number,
                employeeNumber: this.props.payrollDetail.employee_number,
                csi: this.state.csi,
                ally: this.state.ally,
                dmv: this.state.dmv,
                newProductAdjustment: this.state.new_product_adjustment,
                newReserveAdjustment: this.state.new_reserve_adjustment,
                usedProductAdjustment: this.state.used_product_adjustment,
                usedReserveAdjustment: this.state.used_reserve_adjustment,

                pay1Cit: this.state.pay_1_cit,
                pay1PayrollBonus: this.state.pay_1_payroll_bonus,
                pay1GuaranteedWorkDays: this.state.pay_1_guaranteed_work_days,
                pay1SickLaborRate: this.state.pay_1_sick_labor_rate,
                pay1PtoLaborRate: this.state.pay_1_pto_labor_rate,
                pay1BereavementLaborRate: this.state.pay_1_bereavement_labor_rate,
                pay1HolidayLaborRate: this.state.pay_1_holiday_labor_rate,
                pay1NonCommissionFlatSumBonusSpiff: this.state.pay_1_non_commission_flat_sum_bonus_spiff,
                pay1MealtimePremiumHours: this.state.pay_1_mealtime_premium_hours,
                pay1Comments: this.state.pay_1_comments,

                pay2Cit: this.state.pay_2_cit,
                pay2PayrollBonus: this.state.pay_2_payroll_bonus,
                pay2GuaranteedWorkDays: this.state.pay_2_guaranteed_work_days,
                pay2SickLaborRate: this.state.pay_2_sick_labor_rate,
                pay2PtoLaborRate: this.state.pay_2_pto_labor_rate,
                pay2BereavementLaborRate: this.state.pay_2_bereavement_labor_rate,
                pay2HolidayLaborRate: this.state.pay_2_holiday_labor_rate,
                pay2NonCommissionFlatSumBonusSpiff: this.state.pay_2_non_commission_flat_sum_bonus_spiff,
                pay2MealtimePremiumHours: this.state.pay_2_mealtime_premium_hours,
                pay2Comments: this.state.pay_2_comments
            };

            this.props.onSaveClick(event, inputData);
        }
        catch (error) {
            this.setState({ showProgress: false });
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                csi: "",
                ally: "",
                dmv: "",
                new_product_adjustment: "",
                new_reserve_adjustment: "",
                used_product_adjustment: "",
                used_reserve_adjustment: "",
                pay_1_cit: "",
                pay_1_payroll_bonus: "",
                pay_1_guaranteed_work_days: "",
                pay_1_sick_labor_rate: "",
                pay_1_pto_labor_rate: "",
                pay_1_bereavement_labor_rate: "",
                pay_1_holiday_labor_rate: "",
                pay_1_non_commission_flat_sum_bonus_spiff: "",
                pay_1_mealtime_premium_hours: "",
                pay_1_comments: "",
                pay_2_cit: "",
                pay_2_payroll_bonus: "",
                pay_2_guaranteed_work_days: "",
                pay_2_sick_labor_rate: "",
                pay_2_pto_labor_rate: "",
                pay_2_bereavement_labor_rate: "",
                pay_2_holiday_labor_rate: "",
                pay_2_non_commission_flat_sum_bonus_spiff: "",
                pay_2_mealtime_premium_hours: "",
                pay_2_comments: ""
            });
            this.props.onCancelClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        let title = "";
        if (this.props.payrollDetail) {
            title = "Edit Employee Pay Month & Pay Periods - " + this.props.payrollDetail.employee_name + " (" + this.props.payrollDetail.employee_number + ") - " + this.props.payrollDetail.store_name + " - " + CommonService.formatMonthYear(moment(this.props.payrollMonth));
        }

        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.props.payrollDetail !== null}
            cssName="dgdg-fi-payroll-detail-dialog"
            title={title}
            onCancelClick={this.onCancelClick}
        >
            <DGDGModalDialogHeaderButtonComponent>
                <button className="btn btn-primary" onClick={this.onSaveClick}>
                    Save <i className="fas fa-save dgdg-icon" />
                </button>
            </DGDGModalDialogHeaderButtonComponent>
            {
                this.props.payrollDetail
                    ? <div className="dgdg-payroll">
                        <div className="form-row">
                            &nbsp;Pay Month: {CommonService.formatMonthYear(moment(this.props.payrollMonth))}
                            <span style={{ "paddingLeft": "30px" }}>
                                <DGDGFormCheckboxComponent label="CSI" id="csi" value={this.state.csi} onChange={this.onCheckChanged} />
                            </span>
                            <span style={{ "paddingLeft": "30px" }}>
                                <DGDGFormCheckboxComponent label="DMV" id="dmv" value={this.state.dmv} onChange={this.onCheckChanged} />
                            </span>
                            <span style={{ "paddingLeft": "30px" }}>
                                <DGDGFormCheckboxComponent label="Ally" id="ally" value={this.state.ally} onChange={this.onCheckChanged} />
                            </span>
                            <span style={{ "paddingLeft": "30px" }}>
                                <DGDGFormInputComponent label="New Product Adjustment" controlCssName="dgdg-money" id="new_product_adjustment" inputType="money" value={this.state.new_product_adjustment} onChange={this.onTextChange} />
                            </span>
                            <span style={{ "paddingLeft": "30px" }}>
                                <DGDGFormInputComponent label="New Reserve Adjustment" controlCssName="dgdg-money" id="new_reserve_adjustment" inputType="money" value={this.state.new_reserve_adjustment} onChange={this.onTextChange} />
                            </span>
                            <span style={{ "paddingLeft": "30px" }}>
                                <DGDGFormInputComponent label="Used Product Adjustment" controlCssName="dgdg-money" id="used_product_adjustment" inputType="money" value={this.state.used_product_adjustment} onChange={this.onTextChange} />
                            </span>
                            <span style={{ "paddingLeft": "30px" }}>
                                <DGDGFormInputComponent label="Used Reserve Adjustment" controlCssName="dgdg-money" id="used_reserve_adjustment" inputType="money" value={this.state.used_reserve_adjustment} onChange={this.onTextChange} />
                            </span>
                        </div>
                        <table className="dgdg-payroll-period-edit">
                            <tbody>
                                <tr>
                                    <td rowSpan="2" className="dgdg-no-padding-bottom">Pay Period: 1</td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormCheckboxComponent label="CIT" id="pay_1_cit" value={this.state.pay_1_cit} onChange={this.onCheckChanged} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Pay Bonus" controlCssName="dgdg-money" id="pay_1_payroll_bonus" inputType="money" value={this.state.pay_1_payroll_bonus} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="GWD" controlCssName="dgdg-money" id="pay_1_guaranteed_work_days" inputType="decimal" value={this.state.pay_1_guaranteed_work_days} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Sick Rate" controlCssName="dgdg-money" id="pay_1_sick_labor_rate" inputType="money" value={this.state.pay_1_sick_labor_rate} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="PTO Rate" controlCssName="dgdg-money" id="pay_1_pto_labor_rate" inputType="money" value={this.state.pay_1_pto_labor_rate} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Berv Rate" controlCssName="dgdg-money" id="pay_1_bereavement_labor_rate" inputType="money" value={this.state.pay_1_bereavement_labor_rate} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Holiday Rate" controlCssName="dgdg-money" id="pay_1_holiday_labor_rate" inputType="money" value={this.state.pay_1_holiday_labor_rate} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Non Commission Spiff" controlCssName="dgdg-money" id="pay_1_non_commission_flat_sum_bonus_spiff" inputType="money" value={this.state.pay_1_non_commission_flat_sum_bonus_spiff} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Mealtime Prem Hours" labelCssName="dgdg-payroll-width" id="pay_1_mealtime_premium_hours" inputType="decimal" value={this.state.pay_1_mealtime_premium_hours} onChange={this.onTextChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dgdg-no-border-top text-right">Comments:</td>
                                    <td colSpan="8" className="dgdg-no-border-top">
                                        <DGDGFormInputComponent id="pay_1_comments" value={this.state.pay_1_comments} onChange={this.onTextChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan="2" className="dgdg-no-padding-bottom">Pay Period: 2</td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormCheckboxComponent label="CIT" id="pay_2_cit" value={this.state.pay_2_cit} onChange={this.onCheckChanged} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Pay Bonus" controlCssName="dgdg-money" id="pay_2_payroll_bonus" inputType="money" value={this.state.pay_2_payroll_bonus} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="GWD" controlCssName="dgdg-money" id="pay_2_guaranteed_work_days" inputType="decimal" value={this.state.pay_2_guaranteed_work_days} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Sick Rate" controlCssName="dgdg-money" id="pay_2_sick_labor_rate" inputType="money" value={this.state.pay_2_sick_labor_rate} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="PTO Rate" controlCssName="dgdg-money" id="pay_2_pto_labor_rate" inputType="money" value={this.state.pay_2_pto_labor_rate} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Berv Rate" controlCssName="dgdg-money" id="pay_2_bereavement_labor_rate" inputType="money" value={this.state.pay_2_bereavement_labor_rate} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Holiday Rate" controlCssName="dgdg-money" id="pay_2_holiday_labor_rate" inputType="money" value={this.state.pay_2_holiday_labor_rate} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Non Commission Spiff" controlCssName="dgdg-money" id="pay_2_non_commission_flat_sum_bonus_spiff" inputType="money" value={this.state.pay_2_non_commission_flat_sum_bonus_spiff} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Mealtime Prem Hours" labelCssName="dgdg-payroll-width" id="pay_2_mealtime_premium_hours" inputType="decimal" value={this.state.pay_2_mealtime_premium_hours} onChange={this.onTextChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dgdg-no-border-top text-right">Comments:</td>
                                    <td colSpan="8" className="dgdg-no-border-top">
                                        <DGDGFormInputComponent id="pay_2_comments" value={this.state.pay_2_comments} onChange={this.onTextChange} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    : null
            }
        </DGDGModalDialogComponent>;
    }
}
