import React, { Fragment } from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormTextAreaComponent } from "../common/dgdgtextarea/DGDGFormTextAreaComponent";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";
import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableHeaderComponent } from "../common/dgdgtable/DGDGTableHeaderComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { ManageReportsQueryComponent } from "./ManageReportsQueryComponent";
import { ManageReportsRolesManagement } from "./ManageReportsRolesManagement";

export class ManageReportsRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            metadata: [],
            tableData: [],
            reportName: null,
            rolesTableData: [],
            unAssignedRolesList: [],
            paintTable: false,
            isLoadingData: false,
            datasetTable: [],
            datasetPaintTable: false,
            viewMetadata: false
        }

        this.onNameClick = this.onNameClick.bind(this);
        this.onActionColumnCallback = this.onActionColumnCallback.bind(this);
        this.onRefreshColumnCallback = this.onRefreshColumnCallback.bind(this);
        this.onRoleManagementClick = this.onRoleManagementClick.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onCopyToClipboard = this.onCopyToClipboard.bind(this);
        this.onViewMetadataClick = this.onViewMetadataClick.bind(this);
        this.onViewMetadataCancelClick = this.onViewMetadataCancelClick.bind(this);
        this.onRefreshMetadataClick = this.onRefreshMetadataClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/ManageReports", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getReport();
            this.getLastUpdatedDate();
            CommonService.clientAzureStorageLog("ManageReportsRouteComponent", "ManageReportsRouteComponent", "componentDidMount", null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Manage Reports";

    getLastUpdatedDate() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/ManageReportsRouteComponent/getLastUpdatedDate", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ManageReports/GetLastUpdated")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let lastUpdatedDate = "";
                    if (CommonService.isDateValid(parsedResponse))
                        lastUpdatedDate = moment.utc(parsedResponse);
                    this.setState({ lastUpdatedDate: lastUpdatedDate });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ManageReportsRouteComponent/GetLastUpdated", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onActionColumnCallback(rowData) {
        return <Fragment>
            <button type="button" className="btn btn-link dgdg-icon" onClick={(event) => this.onDownloadReport(event, rowData)}>
                <i className="far fa-download" />
            </button>
            <button type="button" className="btn btn-link dgdg-icon" onClick={(event) => this.onRoleManagementClick(event, rowData.name)}>
                <i className="fa-solid fa-user-plus" />
            </button>
            <DGDGSpinnerComponent showSpinner={rowData.download_showSpinner} />
        </Fragment>
    }

    onRefreshColumnCallback(rowData) {
        return <Fragment>
            <button type="button" className="btn btn-link dgdg-icon" onClick={(event) => this.onRefreshDataset(event, rowData)}>
                <i className="far fa-refresh" />
            </button>
            <DGDGSpinnerComponent showSpinner={rowData.refresh_showSpinner} />
        </Fragment>
    }

    onNameClick(event, rowData) {
        this.getPowerBIReportDataTable(event, rowData.id);
    }

    onDownloadReport(event, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.download_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            let inputData = {
                tenant: rowData.tenant,
                workspaceId: rowData.workspaceId,
                reportId: rowData.id,
                reportName: rowData.name
            };
            this.props.applicationInsights.trackTrace({ message: "/ManageReportsRouteComponent/onDownloadReport", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ManageReports/DownloadReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response } else { throw response; } })
                .then(response => {
                    rowData.download_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    CommonService.downloadBlob(response);
                })
                .catch(notOKResponse => {
                    rowData.download_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        console.log(notOKResponse);
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ManageReports/DownloadReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.log(jsonParseError);
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onRefreshDataset(event, rowData) {
        try {
            let datasetTable = this.state.datasetTable;
            rowData.refresh_showSpinner = true;
            this.setState({
                datasetTable: datasetTable,
                datasetPaintTable: true
            }, () => {
                this.setState({ datasetPaintTable: false });
            });
            let inputData = {
                tenant: rowData.tenant,
                workspaceId: rowData.workspaceId,
                datasetId: rowData.id
            };
            this.props.applicationInsights.trackTrace({ message: "/ManageReportsRouteComponent/onRefreshDataset", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ManageReports/RefreshDataset", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.props.onShowAlert("success", { message: "Refresh on " + rowData.name + " report started" }, 4000);
                    rowData.refresh_showSpinner = false;
                    this.setState({
                        datasetTable: datasetTable,
                        datasetPaintTable: true
                    }, () => {
                        this.setState({ datasetPaintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.refresh_showSpinner = false;
                    this.setState({
                        datasetTable: datasetTable,
                        datasetPaintTable: true
                    }, () => {
                        this.setState({ datasetPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ManageReports/RefreshDataset", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onRoleManagementClick(event, reportName) {
        try {
            let inputData = {
                name: reportName
            };
            this.props.applicationInsights.trackTrace({ message: "/ManageReportsRouteComponent/getRoles", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ManageReports/GetRoles", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let tableData = parsedResponse.table.filter(row => row.is_assigned === 1);
                    let unAssignedRolesList = parsedResponse.table.filter(row => row.is_assigned === 0);
                    this.setState({
                        showDialog: true,
                        reportName: reportName,
                        rolesTableData: CommonService.addOrdinalColumn(tableData),
                        unAssignedRolesList: unAssignedRolesList,
                        rolesPainTable: true,
                    }, () => {
                        this.setState({ rolesPainTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        rolesTableData: [],
                        rolesPainTable: true
                    }, () => {
                        this.setState({ rolesPainTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ManageReports/GetRoles", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getReport() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/ManageReportsRouteComponent/getReport", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ManageReports/GetReport")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.datasets.forEach((rowData, index) => {
                        rowData.refreshScheduleDays_cellCssName += (rowData.refreshScheduleEnabled ? "" : " dgdg-manage-report-font-color");
                        rowData.refreshScheduleTimes_cellCssName += (rowData.refreshScheduleEnabled ? "" : " dgdg-manage-report-font-color");
                    });
                    this.setState({
                        metadata: JSON.stringify(parsedResponse.metadata, null, "\t"),
                        tableData: CommonService.addOrdinalColumn(parsedResponse.reportsAndDashBoard),
                        paintTable: true,
                        datasetTable: CommonService.addOrdinalColumn(parsedResponse.datasets),
                        datasetPaintTable: true
                    }, () => {
                        this.setState({ paintTable: false, datasetPaintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        metadata: null,
                        tableData: [],
                        paintTable: true,
                        datasetTable: [],
                        datasetPaintTable: true
                    }, () => {
                        this.setState({ paintTable: false, datasetPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ManageReports/GetReport", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onViewMetadataClick() {
        try {
            this.setState({ viewMetadata: true });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onViewMetadataCancelClick() {
        try {
            this.setState({ viewMetadata: false });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onRefreshMetadataClick() {
        try {
            this.setState({ showSpinner: true });
            this.props.applicationInsights.trackTrace({ message: "/ManageReportsRouteComponent/onRefreshMetadataClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ManageReports/RefreshMetadata")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({ showSpinner: false });
                    this.props.onShowAlert("success", { message: "Metadata refresh started" }, 4000);
                })
                .catch(notOKResponse => {
                    this.setState({ showSpinner: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ManageReports/RefreshMetadata", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

    }

    onSaveClick(event, role) {
        try {
            let inputData = role;
            this.props.applicationInsights.trackTrace({ message: "/ManageReportsRouteComponent/onSaveClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ManageReports/SaveRole", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.onRoleManagementClick(event, role.name);
                    this.props.onShowAlert("success", { message: "Role added." }, 4000);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ManageReports/SaveRole", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDeleteClick(event, role) {
        try {
            let inputData = role;
            this.props.applicationInsights.trackTrace({ message: "/ManageReportsRouteComponent/onDeleteClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ManageReports/DeleteRole", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.onRoleManagementClick(event, role.name);
                    this.props.onShowAlert("success", { message: "Role Deleted." }, 4000);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/OPDSales/DeleteRole", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                rowData: null,
                showDialog: false
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("ManageReportsRouteComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyToClipboard(event, value) {
        try {
            navigator.clipboard.writeText(value);
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("ManageReportsRouteComponent", "onCopyToClipboard", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-flex dgdg-flex-column">
            <div>
                <span className="float-right badge badge-primary dgdg-badge">
                    Last Updated Date: {this.state.lastUpdatedDate ? CommonService.formatDateTimeZone(this.state.lastUpdatedDate) : ""}
                </span>
            </div>
            <div className="dgdg-report-body dgdg-flex dgdg-flex-column dgdg-flex-item dgdg-site-content">
                <ManageReportsQueryComponent {...this.props}
                    applicationInsights={this.props.applicationInsights}
                    showSpinner={this.state.showSpinner}
                    onViewMetadataClick={this.onViewMetadataClick}
                    onRefreshMetadataClick={this.onRefreshMetadataClick}
                />
                <ManageReportsRolesManagement
                    applicationInsights={this.props.applicationInsights}
                    reportName={this.state.reportName}
                    showDialog={this.state.showDialog}
                    tableData={this.state.rolesTableData}
                    unAssignedRolesList={this.state.unAssignedRolesList}
                    onSaveClick={this.onSaveClick}
                    onDeleteClick={this.onDeleteClick}
                    onCancelClick={this.onCancelClick}
                    onShowAlert={this.props.onShowAlert}
                />
                <div className="dgdg-site-scrollable-content">
                    <div className="dgdg-manage-report">
                        <DGDGTableV3Component cardCssName="dgdg-service-schedule-summary-gap" cssName="dgdg-reports-table" applicationInsights={this.props.applicationInsights} headerText="Datasets" tableData={this.state.datasetTable} paintTable={this.state.datasetPaintTable}
                            copyTableConfig={{ "columns": [0, 2, 3, 4, 5, 6, 7, 8], onCopyTableCallBack: this.onCopyTableCallBack }}
                        >
                            <DGDGTableHeaderComponent position="PreHeader">
                                <tr className="dgdg-table-v3-header">
                                    <td colSpan="7" />
                                    <td colSpan="2" className="text-center font-weight-bol dgdg-manage-report-header-color">Scheduling</td>
                                </tr>
                            </DGDGTableHeaderComponent>
                            <DGDGTableRowComponent>
                                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                <DGDGTableColumnComponent headerText="Action" dataColumnCallback={this.onRefreshColumnCallback} cssName="text-center" />
                                <DGDGTableColumnComponent headerText="Name" dataColumn="name" sortColumn="name" filterColumn="name" />
                                <DGDGTableColumnComponent headerText="Configured By" dataColumn="configuredBy" sortColumn="configuredBy" filterColumn="configuredBy" />
                                <DGDGTableColumnComponent headerText="Created Date" dataColumn="createdDate" sortColumn="createdDate" filterColumn="createdDate" dataType="dateTime" />
                                <DGDGTableColumnComponent headerText="Last Updated Date" dataColumn="lastUpdatedDate" sortColumn="lastUpdatedDate" filterColumn="lastUpdatedDate" dataType="dateTime" />
                                <DGDGTableColumnComponent headerText="Workspace Name" dataColumn="workspaceName" sortColumn="workspaceName" filterColumn="workspaceName" />
                                <DGDGTableColumnComponent headerText="Days" dataColumn="refreshScheduleDays" sortColumn="refreshScheduleDays" filterColumn="refreshScheduleDays" />
                                <DGDGTableColumnComponent headerText="Times (PT)" dataColumn="refreshScheduleTimes" sortColumn="refreshScheduleTimes" filterColumn="refreshScheduleTimes" />
                            </DGDGTableRowComponent>
                        </DGDGTableV3Component>
                        <DGDGTableV3Component cssName="dgdg-reports-table" applicationInsights={this.props.applicationInsights} headerText="Reports & Dashboards" tableData={this.state.tableData} paintTable={this.state.paintTable}
                            copyTableConfig={{ "columns": [0, 2, 3, 4, 5], onCopyTableCallBack: this.onCopyTableCallBack }}
                        >
                            <DGDGTableRowComponent>
                                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                <DGDGTableColumnComponent headerText="Action" dataColumnCallback={this.onActionColumnCallback} cssName="text-center" />
                                <DGDGTableColumnComponent headerText="Name" dataColumn="name" sortColumn="name" filterColumn="name" />
                                <DGDGTableColumnComponent headerText="Type" dataColumn="type" sortColumn="type" filterColumn="type" />
                                <DGDGTableColumnComponent headerText="Dataset" dataColumn="datasetName" sortColumn="datasetName" filterColumn="datasetName" />
                                <DGDGTableColumnComponent headerText="Workspace Name" dataColumn="workspaceName" sortColumn="workspaceName" filterColumn="workspaceName" />
                                <DGDGTableColumnComponent headerText="Roles" dataColumn="roles" sortColumn="roles" filterColumn="roles" maxLength="50" />
                            </DGDGTableRowComponent >
                        </DGDGTableV3Component >
                    </div >
                </div>
            </div >
            <DGDGModalDialogComponent
                applicationInsights={this.props.applicationInsights}
                title={"Metadata"}
                showDialog={this.state.viewMetadata}
                cssName="dgdg-manage-report-metadata-dialog"
                onCancelClick={(event) => { this.setState({ viewMetadata: false }); }}>
                <DGDGModalDialogHeaderButtonComponent>
                    <button className="btn btn-primary dgdg-copy-button" onClick={(event) => this.onCopyToClipboard(event, this.state.metadata)}>
                        Copy Metadata<span className="far fa-copy dgdg-icon" />
                    </button>
                </DGDGModalDialogHeaderButtonComponent>
                <div className="dgdg-report-body">
                    <div className="card">
                        <div className="card-block">
                            <div className="form">
                                <DGDGFormTextAreaComponent value={this.state.metadata} rows="30" readOnly controlSpan="dgdg-manage-report-metadata-textbox-width" />
                            </div>
                        </div>
                    </div>
                </div>
            </DGDGModalDialogComponent>
        </DGDGPageComponent >;
    }
}
