import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

export class SalesStoreReportSummaryRowComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return <tr className="dgdg-table-v3-row font-weight-bold">
            <td className="dgdg-sales-store-bg dgdg-no-border-top" colSpan="14" />
            <td>
                {this.props.title}
            </td>
            <td style={{ backgroundColor: this.props.summary ? this.props.summary.yoy_volume_delta_pct_color : "" }}>
                {
                    this.props.summary
                        ? CommonService.formatPercentage(this.props.summary.yoy_volume_delta_pct)
                        : null
                }
            </td>
            <td style={{ backgroundColor: this.props.summary ? this.props.summary.yoy_total_gross_delta_pct_color : "" }}>
                {
                    this.props.summary
                        ? CommonService.formatPercentage(this.props.summary.yoy_total_gross_delta_pct)
                        : null
                }
            </td>
            <td className="text-right" style={{ backgroundColor: this.props.summary ? this.props.summary.yoy_front_pvr_delta_color : "" }}>
                {
                    this.props.summary
                        ? CommonService.formatCurrency(this.props.summary.yoy_front_pvr_delta)
                        : null
                }
            </td>
            <td className="text-right" style={{ backgroundColor: this.props.summary ? this.props.summary.yoy_back_pvr_delta_color : "" }}>
                {
                    this.props.summary
                        ? CommonService.formatCurrency(this.props.summary.yoy_back_pvr_delta)
                        : null
                }
            </td>
        </tr>;
    }
}
