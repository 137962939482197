import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGWidgetComponent } from "../common/dgdgwidget/DGDGWidgetComponent";

import { OpenROsSummaryQueryComponent } from "./OpenROsSummaryQueryComponent";
import { OpenROsSummaryStoreStatusChartComponent } from "./OpenROsSummaryStoreStatusChartComponent";

export class OpenROsSummaryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            paintTable: false,
            storeSummaryTableData: [],
            daysCountTableData: [],
            statusCountTableTitle: "Open ROs Summary By Status",
            statusCountTableData: [],
            maximizedChart: null
        };

        this.onScrollClick = this.onScrollClick.bind(this);
        this.onRestoreClick = this.onRestoreClick.bind(this);
        this.onMaximizeClick = this.onMaximizeClick.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onCopyClick = this.onCopyClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    pageTitle = "Open ROs";

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "OpenROs/Summary", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("OpenROsSummaryComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onScrollClick(event, selector) {
        try {
            CommonService.smoothScrollTo(".dgdg-open-ro", selector);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onMaximizeClick(event, chartName) {
        try {
            this.setState({ maximizedChart: chartName });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onRestoreClick(event) {
        try {
            this.setState({ maximizedChart: null });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(selectedStore) {
        try {
            this.props.applicationInsights.trackTrace({ message: "/OpenROsSummaryComponent/onGetReportClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.setState({ isLoadingData: true });
            fetch("/OpenROs/GetSummaryReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                }
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false,
                        storeSummaryTableData: CommonService.addOrdinalColumn(parsedResponse.table),
                        daysCountTableData: CommonService.addOrdinalColumn(parsedResponse.table1),
                        statusCountTableData: CommonService.addOrdinalColumn(parsedResponse.table2),
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: "",
                        tableData: [],
                        paintTable: true,
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/OpenROs/onGetReportClick", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyClick(event) {
        try {
            let tableText = "#\tStore Name\tStatus\tCount\tOver 5 Days from Estimated Date of Completion\r\n";
            this.state.storeSummaryTableData.forEach((row) => {
                tableText += row[CommonService.ordinalColumnName] + "\t" + (row.store_web_name ? row.store_web_name : "") + "\t" + (row.status ? row.status : "") + "\t" +
                    (row.count ? row.count : "") + "\t" + (row.over_5_days_est_completion_date_count ? row.over_5_days_est_completion_date_count : "") + "\r\n";
            });
            navigator.clipboard.writeText(tableText);
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("OpenROsSummaryComponent", "onCopyClick", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <OpenROsSummaryQueryComponent
                applicationInsights={this.props.applicationInsights}
                isLoadingData={this.state.isLoadingData}
                onGetReportClick={this.onGetReportClick} />
            <nav className="nav nav-pills dgdg-nav">
                <button type="button" className="btn btn-link nav-item nav-link dgdg-quick-link dgdg-quick-link-bg" onClick={(event) => this.onScrollClick(event, "#table1")}>By Status</button>
                <button type="button" className="btn btn-link nav-item nav-link dgdg-quick-link dgdg-quick-link-bg" onClick={(event) => this.onScrollClick(event, "#table2")}>By Days</button>
            </nav>
            <div className="dgdg-open-ro dgdg-site-scrollable-content">
                <div id="table1" className="card dgdg-card-gutter">
                    <div className="card-header">
                        Store Summary By Status
                        <button type="button" className="btn btn-primary dgdg-copy-button" onClick={(event) => this.onCopyClick(event)}>
                            Copy<span className="far fa-copy dgdg-icon" />
                        </button>
                    </div>
                    <div className="card-block">
                        <div className="dgdg-card-gutter">
                            <DGDGWidgetComponent
                                maximizedCss="dgdg-open-ro-widget-maximized" restoredCss="dgdg-open-ro-widget-restored"
                                isMaximized={this.state.maximizedChart === "StoreSummaryByStatus" ? true : false} isVisible={this.state.maximizedChart === "StoreSummaryByStatus" || this.state.maximizedChart === null}
                                onMaximizeClick={(event) => this.onMaximizeClick(event, "StoreSummaryByStatus")} onRestoreClick={(event) => this.onRestoreClick(event)}
                            >
                                <OpenROsSummaryStoreStatusChartComponent
                                    chartData={this.state.storeSummaryTableData} stores={this.props.stores} isMaximized={this.state.maximizedChart === "StoreSummaryByStatus"}
                                />
                            </DGDGWidgetComponent>
                        </div>
                        <DGDGTableV3Component applicationInsights={this.props.applicationInsights} tableData={this.state.storeSummaryTableData} paintTable={this.state.paintTable} stickyHeader>
                            <DGDGTableRowComponent>
                                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_web_name" filterColumn="store_web_name" sortColumn="store_number" />
                                <DGDGTableColumnComponent headerText="Status" dataColumn="status" sortColumn="status" filterColumn="status" />
                                <DGDGTableColumnComponent headerText="Count" dataColumn="count" sortColumn="count" filterColumn="count" dataType="number" />
                                <DGDGTableColumnComponent headerText="Over 5 Days from Estimated Date of Completion" dataColumn="over_5_days_est_completion_date_count" sortColumn="over_5_days_est_completion_date_count" filterColumn="over_5_days_est_completion_date_count" dataType="number" />
                            </DGDGTableRowComponent>
                        </DGDGTableV3Component>
                    </div>
                </div>
                <div id="table2">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText="Store Summary By Status" tableData={this.state.daysCountTableData} paintTable={this.state.paintTable} stickyHeader
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                            <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_web_name" filterColumn="store_web_name" sortColumn="store_number" />
                            <DGDGTableColumnComponent headerText="Service Advisor" dataColumn="service_advisor" sortColumn="service_advisor" filterColumn="service_advisor" />
                            <DGDGTableColumnComponent headerText="Less Than 7 Days" dataColumn="less_than_7_days" sortColumn="less_than_7_days" filterColumn="less_than_7_days" dataType="number" />
                            <DGDGTableColumnComponent headerText="Over 7 Days" dataColumn="over_7_days" sortColumn="over_7_days" filterColumn="over_7_days" dataType="number" />
                            <DGDGTableColumnComponent headerText="Over 30 Days" dataColumn="over_30_days" sortColumn="over_30_days" filterColumn="over_30_days" dataType="number" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
                {/* <div id="table3" className="d-none">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText="Store Summary By Days" tableData={this.state.statusCountTableData} paintTable={this.state.paintTable} stickyHeader
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                            <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_web_name" filterColumn="store_web_name" sortColumn="store_number" />
                            <DGDGTableColumnComponent headerText="Service Advisor" dataColumn="service_advisor" sortColumn="service_advisor" filterColumn="service_advisor" />
                            <DGDGTableColumnComponent headerText="All Lab Posted" dataColumn="all LAB. POSTED" sortColumn="all LAB. POSTED" filterColumn="all LAB. POSTED" />
                            <DGDGTableColumnComponent headerText="Line On Hold" dataColumn="line ON HOLD" sortColumn="line ON HOLD" filterColumn="line ON HOLD" />
                            <DGDGTableColumnComponent headerText="Opened" dataColumn="opened" sortColumn="opened" filterColumn="opened" />
                            <DGDGTableColumnComponent headerText="Pre Invoiced" dataColumn="prE-INVOICED" sortColumn="prE-INVOICED" filterColumn="prE-INVOICED" />
                            <DGDGTableColumnComponent headerText="Pre Assigned" dataColumn="preassigned" sortColumn="preassigned" filterColumn="preassigned" />
                            <DGDGTableColumnComponent headerText="Ready To Post" dataColumn="ready TO POST" sortColumn="ready TO POST" filterColumn="ready TO POST" />
                            <DGDGTableColumnComponent headerText="Veh Disabled" dataColumn="veH. DISABLED" sortColumn="veH. DISABLED" filterColumn="veH. DISABLED" />
                            <DGDGTableColumnComponent headerText="Working" dataColumn="working" sortColumn="working" filterColumn="working" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div> */}
            </div>
        </div>;
    }
}
