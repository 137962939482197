import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";

export class FIPayrollPlan2TierRowEditComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            baseBonus: "",
            bonusWithDMV: "",
            pvr: "",
            ppr: "",
            vsc: "",
            maintenance: "",
            csi: "",
            cit: "",
            evProductIncrement: "",
            evGrossIncrement: "",
            hourly: ""
        };

        this.onTextChange = this.onTextChange.bind(this);
        this.onDropdownChange = this.onDropdownChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidMount() {
        try {
            if (this.props.editRow) {
                this.setState({
                    baseBonus: this.props.editRow.base_bonus ? CommonService.formatNumber_2(parseFloat(this.props.editRow.base_bonus) * 100) : 0,
                    bonusWithDMV: this.props.editRow.bonus_with_dmv ? CommonService.formatNumber_2(parseFloat(this.props.editRow.bonus_with_dmv) * 100) : 0,
                    pvr: this.props.editRow.pvr ? CommonService.formatNumber_2(parseFloat(this.props.editRow.pvr)) : 0,
                    ppr: this.props.editRow.ppr ? CommonService.formatNumber_2(parseFloat(this.props.editRow.ppr)) : 0,
                    vsc: this.props.editRow.vsc ? CommonService.formatNumber_2(parseFloat(this.props.editRow.vsc) * 100) : 0,
                    maintenance: this.props.editRow.maintenance ? CommonService.formatNumber_2(parseFloat(this.props.editRow.maintenance) * 100) : 0,
                    csi: this.props.editRow.csi ? CommonService.formatNumber_2(parseFloat(this.props.editRow.csi) * 100) : 0,
                    cit: this.props.editRow.cit ? CommonService.formatNumber_2(parseFloat(this.props.editRow.cit) * 100) : 0,
                    evProductIncrement: this.props.editRow.ev_product_increment ? CommonService.formatNumber_2(parseFloat(this.props.editRow.ev_product_increment)) : 0,
                    evGrossIncrement: this.props.editRow.ev_gross_increment ? CommonService.formatNumber_2(parseFloat(this.props.editRow.ev_gross_increment)) : 0,
                    hourly: this.props.editRow.hourly
                });
            }
            CommonService.clientAzureStorageLog("FIPayrollPlan2TierRowEditComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "FI Payroll";
    hourlyData = ["Yes", "No"];

    onTextChange(id, value) {
        try {
            this.setState({
                [id]: value
            }, () => {
                let editRow = this.props.editRow;
                editRow.base_bonus = this.state.baseBonus ? parseFloat(this.state.baseBonus.toString().replace(",", "")) / 100 : null;
                editRow.bonus_with_dmv = this.state.bonusWithDMV ? parseFloat(this.state.bonusWithDMV.toString().replace(",", "")) / 100 : null;
                editRow.pvr = this.state.pvr ? parseFloat(this.state.pvr.toString().replace(",", "")) : null;
                editRow.ppr = this.state.ppr ? parseFloat(this.state.ppr.toString().replace(",", "")) : null;
                editRow.vsc = this.state.vsc ? parseFloat(this.state.vsc.toString().replace(",", "")) / 100 : null;
                editRow.maintenance = this.state.maintenance ? parseFloat(this.state.maintenance.toString().replace(",", "")) / 100 : null;
                editRow.csi = this.state.csi ? parseFloat(this.state.csi.toString().replace(",", "")) / 100 : null;
                editRow.cit = this.state.cit ? parseFloat(this.state.cit.toString().replace(",", "")) / 100 : null;
                editRow.ev_product_increment = this.state.evProductIncrement ? parseFloat(this.state.evProductIncrement.toString().replace(",", "")) : null;
                editRow.ev_gross_increment = this.state.evGrossIncrement ? parseFloat(this.state.evGrossIncrement.toString().replace(",", "")) : null;
                this.props.onRowEdited(editRow);
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDropdownChange(id, hourly) {
        try {
            this.setState({
                [id]: hourly
            }, () => {
                let editRow = this.props.editRow;
                editRow.hourly = this.state.hourly;
                this.props.onRowEdited(editRow);
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        if (event.keyCode === 40) {
            const inputs = Array.from(document.querySelectorAll("input[id=" + event.target.id + "]"));
            let nextInput = inputs[inputs.indexOf(document.activeElement) + 1];
            nextInput?.focus();
            nextInput?.select();
            event.preventDefault();
        }

        else if (event.keyCode === 38) {
            const inputs = Array.from(document.querySelectorAll("input[id=" + event.target.id + "]"));
            let nextInput = inputs[inputs.indexOf(document.activeElement) - 1];
            nextInput?.focus();
            nextInput?.select();
            event.preventDefault();
        }
    }

    render() {
        return <tr>
            <td>
                {this.props.editRow?.tier}
            </td>
            <td>
                <DGDGFormInputComponent id="baseBonus" value={this.state.baseBonus} onChange={this.onTextChange} onKeyDown={this.onKeyDown} inputType="percent_2" hideClear />
            </td>
            <td>
                <DGDGFormInputComponent id="bonusWithDMV" value={this.state.bonusWithDMV} onChange={this.onTextChange} onKeyDown={this.onKeyDown} inputType="percent_2" hideClear />
            </td>
            <td>
                <DGDGFormInputComponent id="pvr" cssName="dgdg-fi-payroll-plan2-pvr-edit" value={this.state.pvr} onChange={this.onTextChange} onKeyDown={this.onKeyDown} inputType="money" hideClear />
            </td>
            <td>
                <DGDGFormInputComponent id="ppr" value={this.state.ppr} onChange={this.onTextChange} onKeyDown={this.onKeyDown} inputType="decimal" hideClear />
            </td>
            <td>
                <DGDGFormInputComponent id="vsc" value={this.state.vsc} onChange={this.onTextChange} onKeyDown={this.onKeyDown} inputType="percent" hideClear />
            </td>
            <td >
                <DGDGFormInputComponent id="maintenance" value={this.state.maintenance} onChange={this.onTextChange} onKeyDown={this.onKeyDown} inputType="percent_2" hideClear />
            </td>
            <td>
                {this.props.editRow.addtionalColumn ? <DGDGFormInputComponent id="csi" value={this.state.csi} onChange={this.onTextChange} inputType="percent_2" hideClear /> : null}
            </td>
            <td>
                {this.props.editRow.addtionalColumn ? <DGDGFormInputComponent id="cit" value={this.state.cit} onChange={this.onTextChange} inputType="percent_2" hideClear /> : null}
            </td>
            <td>
                {this.props.editRow.addtionalColumn ? <DGDGFormInputComponent id="evProductIncrement" value={this.state.evProductIncrement} onChange={this.onTextChange} inputType="decimal" hideClear /> : null}
            </td>
            <td>
                {this.props.editRow.addtionalColumn ? <DGDGFormInputComponent id="evGrossIncrement" value={this.state.evGrossIncrement} onChange={this.onTextChange} inputType="money" hideClear /> : null}
            </td>
            <td>
                {this.props.editRow.addtionalColumn ? <DGDGFormDropdownComponent id="hourly" data={this.hourlyData} value={this.state.hourly ? this.state.hourly : "Select Hourly"} onItemClick={this.onDropdownChange} /> : null}
            </td>
        </tr>;
    }
}