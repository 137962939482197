import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGQueryComponent } from "../common/dgdgquery/DGDGQueryComponent";

export class MissingTitlesDetailQueryComponent extends React.Component {
    static StoreNumberCookie = "MissingTitlesDetailStoreNumberCookie";

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            if (state.showSpinner) {
                newState.showSpinner = props.isLoadingData;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedStore: null,
            selectedTitleStatus: null,
            showSpinner: false
        };

        this.onStoreClick = this.onStoreClick.bind(this);
        this.onTitleStatusChange = this.onTitleStatusChange.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
    }

    pageTitle = "Missing Titles";

    titleStatusList = [
        { key: "NoTitle", value: "No Title" },
        { key: "AllInventory", value: "All Inventory" }
    ];

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.stores && this.state.selectedStore === null) {
                let selectedStore = this.props.stores[0];

                if (this.props.match.params.StoreNumber) {
                    let storeNumber = Number(this.props.match.params.StoreNumber);
                    if (storeNumber && storeNumber > 0) {
                        let filterStore = this.props.stores.filter(store => storeNumber === store.store_number);
                        if (filterStore.length > 0) {
                            selectedStore = filterStore[0];
                        }
                    }
                }
                else {
                    let cookieStoreNumber = CommonService.getCookie(MissingTitlesDetailQueryComponent.StoreNumberCookie);
                    if (cookieStoreNumber) {
                        cookieStoreNumber = parseInt(cookieStoreNumber);
                        let filterStore = this.props.stores.filter(store => store.store_number === cookieStoreNumber);
                        if (filterStore.length === 1) {
                            selectedStore = filterStore[0];
                        }
                    }
                }

                this.setState({
                    selectedStore: selectedStore,
                    selectedTitleStatus: this.titleStatusList[0]
                }, () => {
                    this.onGetReportClick(null);
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStoreClick(event, store) {
        try {
            this.setState({
                selectedStore: store
            }, this.props.onStoreClick(store));
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTitleStatusChange(id, status) {
        try {
            this.setState({ selectedTitleStatus: status })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event) {
        try {
            CommonService.setCookie(MissingTitlesDetailQueryComponent.StoreNumberCookie, this.state.selectedStore.store_number, 30);
            this.setState({ showSpinner: true });
            this.props.onGetReportClick(this.state.selectedStore, this.state.selectedTitleStatus);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGQueryComponent cssName="dgdg-card-gutter">
            <DGDGFormDropdownComponent label="Store #" itemKey="display_name" data={this.props.stores} value={this.state.selectedStore ? this.state.selectedStore.display_name : "Select Store"} onItemClick={this.onStoreClick} />
            <DGDGFormDropdownComponent label="Title Status" itemKey="value" data={this.titleStatusList} value={this.state.selectedTitleStatus ? this.state.selectedTitleStatus.value : "Select Title Status"} onItemClick={this.onTitleStatusChange} />
            <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-icon" onClick={(event) => this.onGetReportClick(event)}>
                Get Report<i className="fas fa-redo-alt dgdg-icon" />
            </button>
            <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
        </DGDGQueryComponent>;
    }
}
