import React from "react";
import moment from "moment";
import $ from "jquery";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGChartLineComponent } from "../common/dgdgchart/DGDGChartLineComponent";
import { DGDGFormMultiSelectComponent } from "../common/dgdgmultiselect/DGDGFormMultiSelectComponent"
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGWidgetComponent } from "../common/dgdgwidget/DGDGWidgetComponent"

import { TechnicianScoreCardPayPeriodQueryComponent } from "./TechnicianScoreCardPayPeriodQueryComponent";

export class TechnicianScoreCardPayPeriodComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            months: CommonService.getMonths("06/01/2023"),
            isLoadingData: false,
            paintTable: false,
            tableTitle: "",
            primaryTableData: [],
            secondaryTableData: [],
            footerRow: null,
            technicians: [],
            techniciansToShow: null,
            showSpinnerChart: false,
            chartData: null,
            chartOption: {
                hAxis: {
                    title: "Month",
                    format: "MM/y",
                    gridlines: {
                        color: 'transparent'
                    },
                    textStyle: {
                        fontSize: 10
                    },
                    titleTextStyle: {
                        fontSize: 12,
                        italic: false
                    }
                },
                vAxes: {
                    0: {
                        title: "Efficiency",
                        format: "percent",
                        textStyle: {
                            fontSize: 10
                        },
                        titleTextStyle: {
                            fontSize: 12,
                            italic: false
                        }
                    }
                },
                legend: {
                    textStyle: {
                        fontSize: 12
                    }
                }
            }
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onScrollClick = this.onScrollClick.bind(this);
        this.onTechnicianChange = this.onTechnicianChange.bind(this);
        this.onPrintClick = this.onPrintClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    pageTitle = "Technician Scorecard";

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/TechnicianScoreCard/PayPeriod", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("TechnicianScoreCardPayPeriodComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onScrollClick(event, selector) {
        try {
            CommonService.smoothScrollTo(".dgdg-tech-score-card-pay-period", selector);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTechnicianChange(id, changedTechnicians) {
        try {
            let technicians = this.state.technicians;
            let techniciansToShow = [];
            technicians.forEach((technician, index) => {
                if (technician.checked !== false)
                    techniciansToShow.push(index + 1);
            });

            this.setState({
                technicians: technicians,
                techniciansToShow: techniciansToShow
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getLastUpdatedDate(month, payPeriod) {
        try {
            let inputData = {
                month: CommonService.formatServerDateTime(month),
                payPeriod: payPeriod
            };
            this.props.applicationInsights.trackTrace({ message: "/TechnicianScoreCardDayComponent/getLastUpdatedDate", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/TechnicianScoreCard/GetLastUpdatedByPayPeriod", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (CommonService.isDateValid(parsedResponse)) {
                        this.props.onLastUpdatedDateChanged(moment.utc(parsedResponse));
                    }
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {

                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(selectedMonth, selectedStore, selectedPayPeriod) {
        try {
            this.setState({ isLoadingData: true });
            this.getLastUpdatedDate(selectedMonth, selectedPayPeriod);
            let inputData = {
                storeNumber: selectedStore.store_number,
                month: CommonService.formatServerDateTime(selectedMonth),
                payPeriod: selectedPayPeriod
            };
            this.getChartData(selectedStore.store_number, selectedPayPeriod);
            this.setState({ isLoadingData: true });
            this.props.applicationInsights.trackTrace({ message: "/TechnicianScoreCardDayComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/TechnicianScoreCard/GetPayPeriodReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.forEach((responseItem, index) => {
                        responseItem.store_web_name = responseItem.store_number + " | " + responseItem.store_name;
                        responseItem.technician_web_name = responseItem.employee_number ? responseItem.technician_name + " (" + responseItem.employee_number + ")" : "";
                    });

                    let secondaryTableData = parsedResponse.filter((rowData) => { return rowData.employee_number; });
                    let primaryTableData = JSON.parse(JSON.stringify(secondaryTableData));
                    primaryTableData = primaryTableData.filter((rowData) => { return rowData.store_name !== "Genesis of Stevens Creek"; });
                    let footerRow = selectedStore.store_number === null
                        ? parsedResponse.filter(rowData => !rowData.employee_number && rowData.store_number === 99)[0]
                        : parsedResponse.filter((rowData) => { return !rowData.employee_number; })[0];

                    this.setState({
                        isLoadingData: false,
                        tableTitle: CommonService.formatDate(moment(selectedMonth)),
                        primaryTableData: CommonService.addOrdinalColumn(primaryTableData),
                        secondaryTableData: CommonService.addOrdinalColumn(secondaryTableData),
                        footerRow: footerRow,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        tableTitle: "",
                        primaryTableData: [],
                        secondaryTableData: [],
                        footerRow: null,
                        isLoadingData: false,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TechnicianScoreCard/GetPayPeriodReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("TechnicianScoreCardRouteComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getChartData(store, payPeriod) {
        this.setState({
            showSpinnerChart: true
        });
        let inputData = {
            storeNumber: store,
            payPeriod: payPeriod
        };
        this.props.applicationInsights.trackTrace({ message: "/TechnicianScoreCardDayComponent/getChartData", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/TechnicianScoreCard/GetPayPeriodChartData", {
            method: "POST",
            headers: {
                'Content-Type': "application/json; charset=utf-8;"
            },
            body: JSON.stringify(inputData)
        })
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                let technicians = [];
                for (let index = 1; index < parsedResponse[0].length; index++) {
                    technicians.push(parsedResponse[0][index]);
                }

                this.setState({
                    chartData: parsedResponse,
                    technicians: technicians
                });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TechnicianScoreCard/GetPayPeriodChartData", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            })
            .finally(() => {
                this.setState({
                    showSpinnerChart: false
                });
            });
    }

    onPrintClick(event) {
        try {
            this.props.applicationInsights.trackTrace({ message: "/TechnicianScoreCardDayComponent/onPrintClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/TechnicianScoreCard/PrintClick")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let printContent = '<div class="table-title">Technician Scorecard Primary - ' + this.state.tableTitle
                        + '</div><table class="table table-bordered">' + $("#table1  .dgdg-main-table").html()
                        + '</table><div class="dgdg-page-break" /><div class="table-title">Technician Scorecard Secondary  - ' + this.state.tableTitle
                        + '</div><table class="table table-bordered">' + $("#table2  .dgdg-main-table").html()
                        + '</table><div class="dgdg-page-break" /><div class="table-title">Technician Efficiency for YTD</div>' + $("#technicianEfficiency").html();
                    CommonService.showPrint("Technician Scorecard", printContent, true, true);
                })
                .catch(notOKResponse => {
                    this.setState({ isDownLoading: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TechnicianScoreCard/PrintClick", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <TechnicianScoreCardPayPeriodQueryComponent
                applicationInsights={this.props.applicationInsights}
                months={this.state.months}
                stores={this.props.stores}
                isLoadingData={this.state.isLoadingData}
                onGetReportClick={this.onGetReportClick}
                onPrintClick={this.onPrintClick}
            />
            <nav className="nav nav-pills dgdg-nav">
                <button type="button" className="btn btn-link nav-item nav-link dgdg-quick-link dgdg-quick-link-bg" onClick={(event) => this.onScrollClick(event, "#table1")}>Primary</button>
                <button type="button" className="btn btn-link nav-item nav-link dgdg-quick-link dgdg-quick-link-bg" onClick={(event) => this.onScrollClick(event, "#table2")}>Secondary</button>
            </nav>
            <div className="dgdg-tech-score-card-pay-period dgdg-site-scrollable-content">
                <div id="table1" className="dgdg-card-gutter">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights}
                        headerText={"Technician Scorecard Primary - " + this.state.tableTitle} tableData={this.state.primaryTableData} paintTable={this.state.paintTable} footerRow={this.state.footerRow} showFooter
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} cssName="dgdg-sl-width" />
                            <DGDGTableColumnComponent headerText="Store" dataColumn="store_web_name" filterColumn="store_web_name" sortColumn="store_number" headerCssName="dgdg-tech-score-card-store-width" footerText=" " />
                            <DGDGTableColumnComponent headerText="Technician Name" dataColumn="technician_web_name" sortColumn="technician_web_name" filterColumn="technician_web_name" headerCssName="dgdg-tech-score-card-technician-name-width" footerText="Total" />
                            <DGDGTableColumnComponent headerText="Clocked Hours" dataColumn="clocked_hours" sortColumn="clocked_hours" dataType="formatted_number_1" cssName="text-right" footerCssName="text-right" headerCssName="dgdg-tech-score-palocity-hours-width" columnHelp="Clocked Hours on Paylocity Timecard" columnHelpCssName="dgdg-tech-score-card-columnHelp-width" />
                            <DGDGTableColumnComponent headerText="Worked Hours" dataColumn="worked_hours" sortColumn="worked_hours" dataType="formatted_number_1" cssName="text-right" headerCssName="dgdg-tech-score-palocity-hours-width" footerCssName="text-right" columnHelp="Total Hours Flagged on ROs from CDK" columnHelpCssName="dgdg-tech-score-card-columnHelp-width" />
                            <DGDGTableColumnComponent headerText="Booked Hours" dataColumn="booked_hours" sortColumn="booked_hours" dataType="formatted_number_1" cssName="text-right" footerCssName="text-right" headerCssName="dgdg-tech-score-booked-hours-mpi-completed-width" columnHelp="Total Hours Sold (and Booked) on ROs from CDK" columnHelpCssName="dgdg-tech-score-card-columnHelp-width" />
                            <DGDGTableColumnComponent headerText="Productivity %" dataColumn="tech_productivity" sortColumn="tech_productivity" dataType="percent" headerCssName="dgdg-tech-score-palocity-hours-width" footerCssName="text-right" backgroundColor="tech_productivity_color_bg_color" columnHelp="Worked Hours/Clocked Hours" columnHelpCssName="dgdg-tech-score-card-columnHelp-width" />
                            <DGDGTableColumnComponent headerText="Efficiency %" dataColumn="tech_efficiency" sortColumn="tech_efficiency" dataType="percent" footerCssName="text-right" backgroundColor="tech_efficiency_color_bg_color" headerCssName="dgdg-tech-score-flat-rate-width" columnHelp="Booked Hours/Worked Hours" columnHelpCssName="dgdg-tech-score-card-columnHelp-align-left" />
                            <DGDGTableColumnComponent headerText="Proficiency %" dataColumn="tech_proficiency" sortColumn="tech_proficiency" dataType="percent" footerCssName="text-right" backgroundColor="tech_proficiency_color_bg_color" headerCssName="dgdg-tech-score-flat-rate-width" columnHelp="Booked Hours/Clocked Hours" columnHelpCssName="dgdg-tech-score-card-columnHelp-align-left" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
                <div id="table2" className="dgdg-card-gutter">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights}
                        headerText={"Technician Scorecard Secondary  - " + this.state.tableTitle} tableData={this.state.secondaryTableData} paintTable={this.state.paintTable} footerRow={this.state.footerRow} showFooter
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} cssName="dgdg-sl-width" />
                            <DGDGTableColumnComponent headerText="Store" dataColumn="store_web_name" filterColumn="store_web_name" sortColumn="store_number" headerCssName="dgdg-tech-score-card-store-width" footerText=" " />
                            <DGDGTableColumnComponent headerText="Technician Name" dataColumn="technician_web_name" sortColumn="technician_web_name" filterColumn="technician_web_name" headerCssName="dgdg-tech-score-card-technician-name-width" footerText="Total" />
                            <DGDGTableColumnComponent headerText="RO Count" dataColumn="ro_count" sortColumn="ro_count" dataType="formatted_number_1" cssName="text-right" footerCssName="text-right" headerCssName="dgdg-tech-score-ro-count-width" />
                            <DGDGTableColumnComponent headerText="MPI Completed" dataColumn="mpi_completed" sortColumn="mpi_completed" dataType="number" cssName="text-right" footerCssName="text-right" headerCssName="dgdg-tech-score-booked-hours-mpi-completed-width" />
                            <DGDGTableColumnComponent headerText="Clocked Hours REG" dataColumn="clocked_hours_reg" sortColumn="clocked_hours_reg" dataType="formatted_number_1" cssName="text-right" footerCssName="text-right" headerCssName="dgdg-tech-score-clocked-hours-width" />
                            <DGDGTableColumnComponent headerText="Clocked Hours OT1" dataColumn="clocked_hours_ot1" sortColumn="clocked_hours_ot1" dataType="formatted_number_1" cssName="text-right" footerCssName="text-right" headerCssName="dgdg-tech-score-clocked-hours-width" />
                            <DGDGTableColumnComponent headerText="Clocked Hours OT2" dataColumn="clocked_hours_ot2" sortColumn="clocked_hours_ot2" dataType="formatted_number_1" cssName="text-right" footerCssName="text-right" headerCssName="dgdg-tech-score-clocked-hours-width" />
                            <DGDGTableColumnComponent dataText="" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
                <div className="card">
                    <DGDGWidgetComponent title="Technician Efficiency for YTD" restoredCss="dgdg-widget-maximized" isVisible>
                        <div className="d-flex">
                            <DGDGFormMultiSelectComponent applicationInsights={this.props.applicationInsights} id="ddlTechnicians" colSpan="dgdg-tech-score-card-multi-select" data={this.state.technicians} itemKey="label" value="" nonSelectedText="Select Technicians" allSelectedText="All Technicians" multipleSelectedText="Multiple Technicians" onChange={this.onTechnicianChange} />
                            <DGDGSpinnerComponent showSpinner={this.state.showSpinnerChart} />
                        </div>
                        {
                            this.state.chartData
                                ? <div className="dgdg-tech-score-widget" id="technicianEfficiency">
                                    <DGDGChartLineComponent cssName="dgdg-tech-score-card-chart" chartData={this.state.chartData} chartOption={this.state.chartOption} indexToShow={this.state.techniciansToShow} />
                                </div>
                                : null
                        }
                    </DGDGWidgetComponent>
                </div>
            </div>
        </div>;
    }
}
