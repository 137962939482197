import React from "react";

import { DGDGChartAreaComponent } from "../common/dgdgchart/DGDGChartAreaComponent";

export class DemoChartStackedAreaComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            chartOption: {
                isStacked: true,
                hAxis: {
                    title: "Month",
                    format: "MM/y",
                    gridlines: {
                        color: 'transparent'
                    },
                    textStyle: {
                        fontSize: 10
                    },
                    titleTextStyle: {
                        fontSize: 12,
                        italic: false
                    }
                },
                vAxis: {
                    title: "Total Volume",
                    format: "#,###",
                    textStyle: {
                        fontSize: 10
                    },
                    titleTextStyle: {
                        fontSize: 12,
                        italic: false
                    }
                },
                legend: {
                    position: "top",
                    textStyle: {
                        fontSize: 12
                    }
                }
            }
        };
    }

    render() {
        return <div className="dgdg-widget-item">
            <DGDGChartAreaComponent chartData={this.props.dashboardItem ? this.props.dashboardItem.data : null} chartOption={this.state.chartOption} isMaximized={this.props.isMaximized} />
        </div>;
    }
}
