import React from "react";
import { Link } from "react-router-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom"

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";

import { FIStoreReportStoreComponent } from "./FIStoreReportStoreComponent";
import { FIStoreReportFIManagerComponent } from "./FIStoreReportFIManagerComponent";
import { FIStoreReportSalesManagerComponent } from "./FIStoreReportSalesManagerComponent";
import { PageNotFoundRouteComponent } from "../pagenotfound/PageNotFoundRouteComponent";

export class FIStoreReportRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stores: null,
            lastUpdatedDate: ""
        };

        this.onLastUpdatedDateChanged = this.onLastUpdatedDateChanged.bind(this);
    }

    componentDidMount() {
        try {
            this.getStores();
            CommonService.clientAzureStorageLog("FIStoreReportRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "F&I Store Report";

    getStores() {
        this.props.applicationInsights.trackTrace({ message: "/FIStoreReportRouteComponent/getStores", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/FIStoreReport/GetStores")
            .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
            .then(parsedResponse => {
                this.setState({
                    stores: parsedResponse
                });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FIStoreReport/GetStores", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    onLastUpdatedDateChanged(lastUpdatedDate) {
        try {
            this.setState({ lastUpdatedDate: lastUpdatedDate });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-responsive-page dgdg-flex dgdg-flex-column">
            <BrowserRouter>
                <nav className="nav nav-pills dgdg-tab">
                    <Link to="/FIStoreReport/Store" className={"nav-item nav-link" + (window.location.pathname.indexOf("/Store") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Store</Link>
                    <Link to="/FIStoreReport/FIManager" className={"nav-item nav-link mr-auto" + (window.location.pathname.indexOf("/FIManager") !== -1 ? " btn btn-dark active" : " btn btn-link")}>F&I Manager</Link>
                    <Link to="/FIStoreReport/SalesManager" className={"nav-item nav-link mr-auto" + (window.location.pathname.indexOf("/SalesManager") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Sales Manager</Link>
                    <div className="mt-auto dgdg-flex-item dgdg-badge-margin">
                        <span className="float-right badge badge-primary dgdg-badge">Last Updated Date: {this.state.lastUpdatedDate}</span>
                    </div>
                </nav>
                <div className="dgdg-flex-item dgdg-site-content dgdg-fi-store-report-content">
                    <Switch>
                        <Route exact path="/FIStoreReport/FIManager/:storeNumber?" render={(routeProps) => <FIStoreReportFIManagerComponent {...this.props} {...routeProps} stores={this.state.stores} onLastUpdatedDateChanged={this.onLastUpdatedDateChanged} />} />
                        <Route exact path="/FIStoreReport/SalesManager/:storeNumber?" render={(routeProps) => <FIStoreReportSalesManagerComponent {...this.props} {...routeProps} stores={this.state.stores} onLastUpdatedDateChanged={this.onLastUpdatedDateChanged} />} />
                        <Route exact path="/FIStoreReport/Store" render={(routeProps) => <FIStoreReportStoreComponent {...this.props} stores={this.state.stores} onLastUpdatedDateChanged={this.onLastUpdatedDateChanged} />} />
                        <Route render={(routeProps) => <PageNotFoundRouteComponent {...this.props} {...routeProps} />} />
                    </Switch>
                </div>
            </BrowserRouter>
        </DGDGPageComponent>;
    }
}
