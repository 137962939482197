import React, { Fragment } from "react";

export class SalesStoreReportTitleHeaderRowComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return <Fragment>
            <tr className="dgdg-sales-store-bg-title-foot">
                <td className="dgdg-sales-store-bg" />
                <td colSpan="7" className="text-left dgdg-font-grey dgdg-font-medium">{this.props.title}</td>
                {
                    !this.props.isDetailsHidden
                        ? <Fragment>
                            {
                                this.props.title === "Total Sales"
                                    ? <Fragment>
                                        <td colSpan="3" className="dgdg-sales-store-bg dgdg-no-border-right" />
                                        <td className="dgdg-sales-store-bg" />
                                        <td colSpan="2" className="text-left dgdg-font-grey dgdg-font-medium">{this.props.title} Tracking Totals</td>
                                    </Fragment>
                                    : <Fragment>
                                        <td colSpan="3" className="dgdg-sales-store-bg" />
                                        <td colSpan="3" className="text-left dgdg-font-grey dgdg-font-medium">{this.props.title} Tracking Totals</td>
                                    </Fragment>

                            }
                            <td className="dgdg-sales-store-bg" />
                            <td colSpan="4" className="text-left dgdg-font-grey dgdg-font-medium">{this.props.title} Tracking Year-Over-Year</td>
                        </Fragment>
                        : null
                }
            </tr>
            <tr className="dgdg-table-v3-header">
                <td className="dgdg-sales-store-bg dgdg-no-border-right" />
                <td>VOLUME</td>
                <td>FRONT PVR</td>
                <td>TOTAL FRONT</td>
                <td>BACK PVR</td>
                <td>TOTAL BACK</td>
                <td>TOTAL PVR</td>
                <td className="dgdg-no-border-right">TOTAL GROSS</td>
                {
                    !this.props.isDetailsHidden
                        ? <Fragment>
                            {
                                this.props.title === "Total Sales"
                                    ? <td colSpan="4" className="dgdg-sales-store-bg dgdg-no-border-right" />
                                    : <Fragment>
                                        <td colSpan="3" className="dgdg-sales-store-bg dgdg-no-border-right" />
                                        <td>BUDGET GOAL</td>
                                    </Fragment>
                            }
                            <td>VOLUME TRACK</td>
                            <td className=" dgdg-no-border-right">GROSS TRACK</td>
                            <td className="dgdg-sales-store-bg dgdg-no-border-right" />
                            <td>VOLUME %&Delta;</td>
                            <td>TOTAL GROSS %&Delta;</td>
                            <td>FRONT PVR $&Delta;</td>
                            <td>BACK PVR $&Delta;</td></Fragment>
                        : null
                }
            </tr>
        </Fragment>;
    }
}
