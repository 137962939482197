import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";

export class SalesforceRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/Salesforce", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("SalesforceRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Salesforce";

    render() {
        return <DGDGPageComponent>
            <div className="dgdg-report-body">
                <div className="dgdg-site-scrollable-content">
                    <div className="card">
                        <div className="card-header">Salesforce Links</div>
                        <div className="card-block">
                            <div className="form-inline">
                                <a className="btn btn-info dgdg-icon" href="https://delgrandedealergroup.my.salesforce.com/" target="_blank" rel="noreferrer">
                                    Sales Cloud <span className="fas fa-link dgdg-icon" />
                                </a>
                                <a className="btn btn-info dgdg-icon" href="https://myapps.microsoft.com/signin/Salesforce%20Marketing%20Cloud/80a27638-f565-4d45-ae4a-e8e8847bfd1c?tenantId=5130a840-e60d-4236-a32f-19de6ccb8e2e" target="_blank" rel="noreferrer">
                                    Marketing Cloud <span className="fas fa-link dgdg-icon" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}