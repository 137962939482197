import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

export class DemoPageView3Component extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/DemoPage/View3", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getData();
            CommonService.clientAzureStorageLog("DemoPageRouteComponent", "DemoPageView3Component", "componentDidMount", null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Demo Page";

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <div className="dgdg-site-scrollable-content">
                <div className="card dgdg-card-gutter">
                    <div className="card-block">
                        <iframe title="Sharepoint Sheet" width="700" height="400" frameborder="0" scrolling="no" src="https://delgran.sharepoint.com/sites/DGDGEngineering/_layouts/15/Doc.aspx?sourcedoc={6edf231b-8549-4e2e-be09-32e6382b6b3f}&action=embedview&wdAllowInteractivity=False&ActiveCell='Pivot'!A1&wdInConfigurator=True"></iframe>
                    </div>
                </div>
                <div className="card">
                    <div className="card-block">
                        <iframe title="OneDrive Sheet" width="700" height="400" frameborder="0" scrolling="no" src="https://delgran-my.sharepoint.com/:x:/g/personal/dgdgeng_dgdg_com/EZVmOV-93AhKqP9uiYDIZR4Bdke9YOV438cqhWa4_pjBSQ?e=IexMUb"></iframe>
                    </div>
                </div>
            </div>
        </div>;
    }
}
