import React from "react";

import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGQueryComponent } from "../common/dgdgquery/DGDGQueryComponent";
export class NewPDIAuditQueryComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            if (state.showSpinner) {
                newState.showSpinner = props.isLoadingData || props.isPublishing;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            vin: "",
            stockNumber: "",
            showSpinner: false
        };
        this.onTextChange = this.onTextChange.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event) {
        try {
            this.setState({ showSpinner: true });
            this.props.onGetReportClick(this.state.vin, this.state.stockNumber);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGetReportClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGQueryComponent cssName="dgdg-card-gutter">
            <DGDGFormInputComponent id="stockNumber" label="Stock #" value={this.state.stockNumber} onChange={this.onTextChange} onKeyDown={this.onKeyDown} />
            <DGDGFormInputComponent id="vin" label="VIN" value={this.state.vin} onChange={this.onTextChange} onKeyDown={this.onKeyDown} />
            <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-new-pdi-button-width dgdg-icon" onClick={this.onGetReportClick}>
                Get Report<i className="fas fa-redo-alt dgdg-icon" />
            </button>
            <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
        </DGDGQueryComponent>;
    }
}
