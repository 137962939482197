import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGFormCalendarComponent } from "../common/dgdgcalendar/DGDGFormCalendarComponent";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";

export class ManualCheckAddComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSpinner: false,
            selectedStore: null,
            employeeNumber: "",
            checkDate: null,
            checkNumber: "",
            checkAmount: ""
        };

        this.onStoreClick = this.onStoreClick.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
    }

    pageTitle = "Add Employee Check";

    onStoreClick(id, store) {
        try {
            this.setState({ selectedStore: store });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDateChange(id, value) {
        try {
            this.setState({ [id]: moment(value) });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event) {
        try {
            if (CommonService.isDateValid(this.state.checkDate)
                && this.state.selectedStore !== null
                && this.state.employeeNumber !== ""
                && this.state.checkNumber !== ""
                && this.state.checkAmount !== ""
            ) {
                this.props.onSaveClick(event, {
                    storeNumber: this.state.selectedStore.store_number,
                    employeeNumber: this.state.employeeNumber,
                    checkDate: CommonService.formatServerDateTime(this.state.checkDate),
                    checkNumber: this.state.checkNumber,
                    checkAmount: this.state.checkAmount
                });
                this.onCancelClick(event);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                selectedStore: null,
                employeeNumber: "",
                checkDate: null,
                checkNumber: "",
                checkAmount: ""
            });

            this.props.onCancelClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.props.showDialog}
            cssName="dgdg-manual-check-dialog"
            title="Add Employee Check"
            onCancelClick={this.onCancelClick}
        >
            <DGDGModalDialogHeaderButtonComponent>
                <button className="btn btn-primary" onClick={this.onSaveClick}>
                    Save <i className="fas fa-save dgdg-icon" />
                </button>
            </DGDGModalDialogHeaderButtonComponent>
            <div className="dgdg-report-body">
                <div className="card">
                    <div className="card-block">
                        <div className="form-inline">
                            <div className="form-row">
                                <DGDGFormDropdownComponent label="Store #" data={this.props.stores} itemKey="display_name" value={this.state.selectedStore ? this.state.selectedStore.display_name : "Select Store"} onItemClick={this.onStoreClick} colSpan="col-4" labelSpan="col-4" controlSpan="col-8" />
                                <DGDGFormInputComponent id="employeeNumber" label="Employee #" value={this.state.employeeNumber} onChange={this.onTextChange} colSpan="col-4" labelSpan="col-4" controlSpan="col-8" />
                                <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="checkDate" label="Check Date" value={this.state.checkDate} onDateChange={this.onDateChange} colSpan="col-4" labelSpan="col-4" controlSpan="col-8" />
                                <DGDGFormInputComponent id="checkNumber" label="Check #" value={this.state.checkNumber} onChange={this.onTextChange} inputType="number" colSpan="col-4" labelSpan="col-4" controlSpan="col-8" />
                                <DGDGFormInputComponent id="checkAmount" label="Check Amt" value={this.state.checkAmount} onChange={this.onTextChange} inputType="money" colSpan="col-4" labelSpan="col-4" controlSpan="col-8" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DGDGModalDialogComponent>;
    }
}
