import React from "react";
import moment from "moment";

import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";

export class GLReconciliationQueryComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            if (state.showSpinner) {
                newState.showSpinner = props.isLoadingData;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedMonth: null,
            selectedStore: null,
            selectedAdjustType: "All",
            showSpinner: false
        };

        this.onMonthClick = this.onMonthClick.bind(this);
        this.onStoreClick = this.onStoreClick.bind(this);
        this.onAdjustTypeClick = this.onAdjustTypeClick.bind(this);
        this.onGetDetailsClick = this.onGetDetailsClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.months && this.props.stores
                && this.state.selectedMonth === null && this.state.selectedStore === null) {
                let selectedMonth = this.props.months[1];
                if (moment().date() > 6) {
                    selectedMonth = this.props.months[0];
                }
                this.setState({
                    selectedMonth: selectedMonth,
                    selectedStore: this.props.stores[0]
                }, () => {
                    this.onMonthClick(null, selectedMonth);
                    this.onGetDetailsClick(null);
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onMonthClick(event, month) {
        try {
            this.setState({
                selectedMonth: month
            });

            this.props.onMonthClick(month);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStoreClick(id, store) {
        try {
            this.setState({ selectedStore: store });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAdjustTypeClick(event, adjustType) {
        try {
            this.setState({
                selectedAdjustType: adjustType
            }, this.validateForm);;
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetDetailsClick(event) {
        try {
            this.setState({ showSpinner: true });
            this.props.onGetDetailsClick(this.state.selectedMonth, this.state.selectedStore, this.state.selectedAdjustType);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGetDetailsClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="card dgdg-card-gutter">
            <div className="card-block">
                <div className="form-inline">
                    <DGDGFormDropdownComponent label="Month" data={this.props.months} displayFormat="monthYear" value={this.state.selectedMonth ?? ""} onItemClick={this.onMonthClick} onKeyDown={this.onKeyDown} />
                    <DGDGFormDropdownComponent label="Store #" itemKey="display_name" data={this.props.stores} value={this.state.selectedStore ? this.state.selectedStore.display_name : "Select Store"} onItemClick={this.onStoreClick} onKeyDown={this.onKeyDown} />
                    <DGDGFormDropdownComponent label="Adjust Type" data={["All", "Product", "Reserve"]} value={this.state.selectedAdjustType} onItemClick={this.onAdjustTypeClick} onKeyDown={this.onKeyDown} />
                    <button disabled={this.state.showSpinner} className="btn btn-primary" onClick={(event) => this.onGetDetailsClick(event)}>
                        Get Details <span className="far fa-sync-alt dgdg-icon" />
                    </button>
                    <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
                    <button className="btn btn-primary dgdg-copy-button" onClick={(event) => this.props.onCopyClick(event)}>
                        Copy<span className="far fa-copy dgdg-icon" />
                    </button>
                </div>
            </div>
        </div>;
    }
}
