import React, { Fragment } from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";
import { OrgChartGroupSummaryQueryComponent } from "./OrgChartGroupSummaryQueryComponent";

export class OrgChartGroupSummaryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            tableData: [],
            tableTitle: "",
            groupSummaryTree: []
        };

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        this.onGetChartClick = this.onGetChartClick.bind(this);
    }

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/OrgChart/GroupSummary", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("OrgChartGroupSummaryComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetChartClick(selectedMonth) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                chartDate: CommonService.formatServerDateTime(selectedMonth)
            };

            this.setState({ isLoadingData: true });
            this.props.applicationInsights.trackTrace({ message: "/OrgChartDGDGComponent/onGetChartClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/OrgChart/GetChart", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableData: parsedResponse.table,
                        tableTitle: "Org Chart - Group Summary - " + CommonService.formatMonthYear(moment(selectedMonth)),
                    }, this.getGroupSummaryData);
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableData: [],
                        groupSummaryTree: [],
                        tableTitle: ""
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/OrgChart/GetChart", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getGroupSummaryData() {
        if (this.props.stores) {
            let groupSummaryTree = this.getGroupSummaryTree();
            this.formGroupSummaryTree(groupSummaryTree);
        }
    }

    formGroupSummaryTree(groupSummaryTree) {
        let tableRows = []
        for (var i = 0; i < groupSummaryTree.length; i++) {
            tableRows.push(<tr className="text-center" key={"groupSummaryTreeRow" + i}>
                {
                    (groupSummaryTree.length > i && groupSummaryTree[i] !== null && groupSummaryTree[i].length === 24)
                        ? <Fragment>
                            <td colSpan="2" className={"text-left dgdg-border-thick-left dgdg-border-right dgdg-border-dotted-top dgdg-border-dotted-bottom"}>
                                {
                                    groupSummaryTree[i][0]
                                }
                            </td>
                            <td colSpan="2" className={"dgdg-border-left dgdg-border-right dgdg-border-dotted-top dgdg-border-dotted-bottom"} style={{ "backgroundColor": "#f4b084" }}>
                                {
                                    groupSummaryTree[i][1]
                                }
                            </td>
                            <td colSpan="2" className={"dgdg-border-left dgdg-border-right dgdg-border-dotted-top dgdg-border-dotted-bottom"} style={{ "backgroundColor": "#f4b084" }}>
                                {
                                    groupSummaryTree[i][2]
                                }
                            </td>
                            <td colSpan="2" className={"dgdg-border-left dgdg-border-right dgdg-border-dotted-top dgdg-border-dotted-bottom"} style={{ "backgroundColor": "#f4b084" }}>
                                {
                                    groupSummaryTree[i][3]
                                }
                            </td>
                            <td colSpan="2" className={"dgdg-border-left dgdg-border-right dgdg-border-dotted-top dgdg-border-dotted-bottom"} style={{ "backgroundColor": "#f4b084" }}>
                                {
                                    groupSummaryTree[i][4]
                                }
                            </td>
                            <td colSpan="2" className={"dgdg-border-left dgdg-border-right dgdg-border-dotted-top dgdg-border-dotted-bottom"} style={{ "backgroundColor": "#f4b084" }}>
                                {
                                    groupSummaryTree[i][5]
                                }
                            </td>
                            <td colSpan="2" className={"dgdg-border-left dgdg-border-right dgdg-border-dotted-top dgdg-border-dotted-bottom"} style={{ "backgroundColor": "#f4b084" }}>
                                {
                                    groupSummaryTree[i][6]
                                }
                            </td>
                            <td colSpan="2" className={"dgdg-border-left dgdg-border-right dgdg-border-dotted-top dgdg-border-dotted-bottom"} style={{ "backgroundColor": "#f4b084" }}>
                                {
                                    groupSummaryTree[i][7]
                                }
                            </td>
                            <td colSpan="2" className={"dgdg-border-left dgdg-border-right dgdg-border-dotted-top dgdg-border-dotted-bottom"} style={{ "backgroundColor": "#ffc000" }}>
                                {
                                    groupSummaryTree[i][8]
                                }
                            </td>
                            <td colSpan="2" className={"dgdg-border-left dgdg-border-right dgdg-border-dotted-top dgdg-border-dotted-bottom"} style={{ "backgroundColor": "#ffc000" }}>
                                {
                                    groupSummaryTree[i][9]
                                }
                            </td>
                            <td colSpan="2" className={"dgdg-border-left dgdg-border-right dgdg-border-dotted-top dgdg-border-dotted-bottom"} style={{ "backgroundColor": "#ffc000" }}>
                                {
                                    groupSummaryTree[i][10]
                                }
                            </td>
                            <td colSpan="2" className={"dgdg-border-left dgdg-border-right dgdg-border-dotted-top dgdg-border-dotted-bottom"} style={{ "backgroundColor": "#ffc000" }}>
                                {
                                    groupSummaryTree[i][11]
                                }
                            </td>
                            <td colSpan="2" className={"dgdg-border-left dgdg-border-right dgdg-border-dotted-top dgdg-border-dotted-bottom"} style={{ "backgroundColor": "#ffc000" }}>
                                {
                                    groupSummaryTree[i][12]
                                }
                            </td>
                            <td colSpan="2" className={"dgdg-border-left dgdg-border-right dgdg-border-dotted-top dgdg-border-dotted-bottom"}>
                                {
                                    groupSummaryTree[i][13]
                                }
                            </td>
                            <td colSpan="2" className={"dgdg-border-left dgdg-border-right dgdg-border-dotted-top dgdg-border-dotted-bottom"} style={{ "backgroundColor": "#92d050" }}>
                                {
                                    groupSummaryTree[i][14]
                                }
                            </td>
                            <td colSpan="2" className={"dgdg-border-left dgdg-border-right dgdg-border-dotted-top dgdg-border-dotted-bottom"} style={{ "backgroundColor": "#92d050" }}>
                                {
                                    groupSummaryTree[i][15]
                                }
                            </td>
                            <td colSpan="2" className={"dgdg-border-left dgdg-border-thick-right dgdg-border-dotted-top dgdg-border-dotted-bottom"} style={{ "backgroundColor": "#92d050" }}>
                                {
                                    groupSummaryTree[i][16]
                                }
                            </td>
                            <td colSpan="2" className={"dgdg-border-thick-left dgdg-border-thick-right dgdg-border-dotted-top dgdg-border-dotted-bottom"}>
                                {
                                    groupSummaryTree[i][17]
                                }
                            </td>
                            <td colSpan="2" className={"dgdg-border-thick-left dgdg-no-border-right dgdg-no-border-top dgdg-no-border-bottom"}>
                                {
                                    groupSummaryTree[i][18]
                                }
                            </td>
                            <td colSpan="2" className={"dgdg-no-border"}>
                                {
                                    groupSummaryTree[i][19]
                                }
                            </td>
                            <td colSpan="2" className={"dgdg-no-border"}>
                                {
                                    groupSummaryTree[i][20]
                                }
                            </td>
                            <td colSpan="2" className={"dgdg-no-border-left dgdg-no-border-top dgdg-no-border-bottom dgdg-border-thick-right"}>
                                {
                                    groupSummaryTree[i][21]
                                }
                            </td>
                            <td colSpan="2" className={"dgdg-border-thick-left dgdg-no-border-top dgdg-no-border-bottom dgdg-border-thick-right"}>
                                {
                                    groupSummaryTree[i][22]
                                }
                            </td>
                            <td colSpan="2" className={"dgdg-border-thick-left dgdg-no-border-top dgdg-no-border-bottom dgdg-border-thick-right"}>
                                {
                                    groupSummaryTree[i][23]
                                }
                            </td>
                        </Fragment>
                        : (groupSummaryTree.length > i && groupSummaryTree[i] !== null && groupSummaryTree[i].length === 3)
                            ? <Fragment>
                                <td colSpan="2" className="text-left dgdg-border-thick-left dgdg-border-thick-top dgdg-border-thick-bottom dgdg-no-border-right">{groupSummaryTree[i][0]}</td>
                                <td colSpan="32" className="dgdg-no-border-left dgdg-border-thick-top dgdg-border-thick-bottom dgdg-border-thick-right" style={{ "backgroundColor": groupSummaryTree[i][1] }}>&nbsp;</td>
                                <td colSpan="2" className="dgdg-border-thick-left dgdg-border-thick-right dgdg-border-thick-top dgdg-border-thick-bottom">{groupSummaryTree[i][2]}</td>
                                <td colSpan="2" className="dgdg-border-left dgdg-border-right dgdg-border-top dgdg-border-bottom" style={{ "backgroundColor": "#ffff00" }}>4.5</td>
                                <td colSpan="2" className="dgdg-border-left dgdg-border-right dgdg-border-top dgdg-border-bottom" style={{ "backgroundColor": "#ffff00" }}>3</td>
                                <td colSpan="2" className="dgdg-border-left dgdg-border-right dgdg-border-top dgdg-border-bottom" style={{ "backgroundColor": "#ffff00" }}>4.5</td>
                                <td colSpan="2" className="dgdg-border-left dgdg-border-right dgdg-border-top dgdg-border-bottom" style={{ "backgroundColor": "#ffff00" }}>5</td>
                                <td colSpan="2" className="dgdg-border-left dgdg-border-right dgdg-border-top dgdg-border-bottom" style={{ "backgroundColor": "#ffff00" }}>3</td>
                                <td colSpan="2" className="dgdg-border-left dgdg-border-right dgdg-border-top dgdg-border-bottom" style={{ "backgroundColor": "#ffff00" }}>5</td>
                            </Fragment>
                            : (groupSummaryTree.length > i && groupSummaryTree[i] !== null && groupSummaryTree[i].length === 2)
                                ? <Fragment>
                                    <td colSpan="34" className="text-right">{groupSummaryTree[i][0]}</td>
                                    <td colSpan="2" className="dgdg-border-thick-left dgdg-border-thick-right dgdg-border-thick-top dgdg-border-thick-bottom">{groupSummaryTree[i][1]}</td>
                                </Fragment>
                                : (groupSummaryTree.length > i && groupSummaryTree[i] !== null && groupSummaryTree[i].length === 1 && groupSummaryTree[i][0] === null)
                                    ? <Fragment>
                                        <td colSpan="34" className="dgdg-no-border-left dgdg-no-border-right dgdg-border-thick-top dgdg-border-thick-bottom">&nbsp;</td>
                                        <td colSpan="2" className="dgdg-no-border-left dgdg-no-border-right dgdg-border-thick-top dgdg-border-thick-bottom">&nbsp;</td>
                                        <td colSpan="12" className="dgdg-no-border-left dgdg-no-border-right dgdg-border-thick-top dgdg-border-bottom">&nbsp;</td>
                                    </Fragment>
                                    : (groupSummaryTree.length > i && groupSummaryTree[i] !== null && groupSummaryTree[i].length === 1)
                                        ? <Fragment>
                                            <td colSpan="34" className="dgdg-no-border">&nbsp;</td>
                                            <td colSpan="2" className="dgdg-no-border">&nbsp;</td>
                                        </Fragment>
                                        : <Fragment>
                                            <td colSpan="34" className="dgdg-no-border-left dgdg-no-border-right dgdg-border-thick-top dgdg-border-thick-bottom">&nbsp;</td>
                                            <td colSpan="2" className="dgdg-no-border-left dgdg-no-border-right dgdg-border-thick-top dgdg-border-thick-bottom">&nbsp;</td>
                                            <td colSpan="12" className="dgdg-no-border-left dgdg-no-border-right dgdg-border-thick-top dgdg-border-thick-bottom">&nbsp;</td>
                                        </Fragment>
                }
            </tr>);
        }

        this.setState({
            groupSummaryTree: tableRows
        });
    }

    getGroupSummaryTree() {
        let dataList = [];
        let tableData = this.state.tableData;

        let generalMgrTotal = 0;
        let salesMgrTotal = 0;
        let usedCarManagerTotal = 0;
        let financeTeamTotal = 0;
        let productSpecialistsTotal = 0;
        let salesProductSpecialistsTotal = 0;
        let salesSupportTotal = 0;
        let serviceMgmtTotal = 0;
        let serviceAdvisorTotal = 0;
        let technicianTotal = 0;
        let serviceSupportTotal = 0;
        let servicePortarTotal = 0;
        let detailDeptTotal = 0;
        let partsManagerTotal = 0;
        let counterTeamTotal = 0;
        let partsSupportTotal = 0;

        this.props.stores.forEach((store, rowIndex) => {
            let generalMgr = tableData.filter(row => row.store_number === store.store_number && row.org_chart_position === "Admin-General Manager");
            generalMgrTotal += generalMgr.length;

            let salesGeneralMgr = tableData.filter(row => row.store_number === store.store_number && row.org_chart_position === "Sales-General Sales Manager");
            let salesMgr = tableData.filter(row => row.store_number === store.store_number && row.org_chart_position === "Sales-Sales Manager");
            salesMgrTotal += salesGeneralMgr.length + salesMgr.length;

            let usedCarManager = tableData.filter(row => row.store_number === store.store_number && row.org_chart_position === "Sales-Used Car Manager");
            usedCarManagerTotal += usedCarManager.length;

            let salesFinaceDirector = tableData.filter(row => row.store_number === store.store_number && row.org_chart_position === "Sales-Finance Director");
            let salesFinaceManager = tableData.filter(row => row.store_number === store.store_number && row.org_chart_position === "Sales-Finance Manager");
            financeTeamTotal += salesFinaceDirector.length + salesFinaceManager.length;

            let productSpecialists = tableData.filter(row => row.store_number === store.store_number && row.org_chart_position === "Sales-Product Specialists");
            productSpecialistsTotal += productSpecialists.length;

            let salesProductSpecialists = tableData.filter(row => row.store_number === store.store_number && row.org_chart_position === "Sales-Online Product Specialists");
            salesProductSpecialistsTotal += salesProductSpecialists.length;

            let salesSupport = tableData.filter(row => row.store_number === store.store_number && row.org_chart_position === "Sales-Support");
            let salesPorter = tableData.filter(row => row.store_number === store.store_number && row.org_chart_position === "Sales-Porter");
            let adminSupport = tableData.filter(row => row.store_number === store.store_number && row.org_chart_position === "Admin-Support");
            salesSupportTotal += salesSupport.length + salesPorter.length + adminSupport.length;

            let partsDirector = tableData.filter(row => row.store_number === store.store_number && row.org_chart_position === "Service-Service & Parts Director");
            let serviceDirector = tableData.filter(row => row.store_number === store.store_number && row.org_chart_position === "Service-Service Director");
            let serviceManager = tableData.filter(row => row.store_number === store.store_number && row.org_chart_position === "Service-Service Manager");
            serviceMgmtTotal += partsDirector.length + serviceDirector.length + serviceManager.length;

            let assistanceServiceDirector = tableData.filter(row => row.store_number === store.store_number && row.org_chart_position === "Service-Asst Service Manager");
            let serviceAdvisor = tableData.filter(row => row.store_number === store.store_number && row.org_chart_position === "Service-Service Advisors");
            serviceAdvisorTotal += assistanceServiceDirector.length + serviceAdvisor.length;

            let foreman = tableData.filter(row => row.store_number === store.store_number && row.org_chart_position === "Service-Shop Foreman");
            let technicians = tableData.filter(row => row.store_number === store.store_number && row.org_chart_position === "Service-Technicians");
            technicianTotal += foreman.length + technicians.length;

            let driver = tableData.filter(row => row.store_number === store.store_number && row.org_chart_position === "Service-Shuttle Driver");
            let warrantyAdmin = tableData.filter(row => row.store_number === store.store_number && row.org_chart_position === "Service-Warranty Admin");
            let dispatcher = tableData.filter(row => row.store_number === store.store_number && row.org_chart_position === "Service-Dispatcher");
            let serviceSupport = tableData.filter(row => row.store_number === store.store_number && row.org_chart_position === "Service-Support");
            serviceSupportTotal += driver.length + warrantyAdmin.length + dispatcher.length + serviceSupport.length;

            let servicePorter = tableData.filter(row => row.store_number === store.store_number && row.org_chart_position === "Service-Porter");
            servicePortarTotal += servicePorter.length;

            let detailManager = tableData.filter(row => row.store_number === store.store_number && row.org_chart_position === "Service-Detail Manager");
            let detailDept = tableData.filter(row => row.store_number === store.store_number && row.org_chart_position === "Service-Detail Dept");
            detailDeptTotal += detailManager.length + detailDept.length;

            let partsManager = tableData.filter(row => row.store_number === store.store_number && row.org_chart_position === "Parts-Parts Manager");
            partsManagerTotal += partsManager.length;

            let counterTeam = tableData.filter(row => row.store_number === store.store_number && row.org_chart_position === "Parts-Counter Team");
            counterTeamTotal += counterTeam.length;

            let partsSupport = tableData.filter(row => row.store_number === store.store_number && row.org_chart_position === "Parts-Parts Support");
            partsSupportTotal += partsSupport.length;

            let totalCount = generalMgr.length + salesGeneralMgr.length + salesMgr.length + usedCarManager.length + salesFinaceDirector.length + salesFinaceManager.length +
                productSpecialists.length + salesProductSpecialists.length + salesSupport.length + salesPorter.length + adminSupport.length + partsDirector.length + serviceDirector.length + serviceManager.length +
                assistanceServiceDirector.length + serviceAdvisor.length + foreman.length + technicians.length + driver.length + warrantyAdmin.length + dispatcher.length + serviceSupport.length +
                servicePorter.length + detailManager.length + detailDept.length + partsManager.length + counterTeam.length + partsSupport.length;

            dataList.push([store.name, generalMgr.length, salesGeneralMgr.length + salesMgr.length, usedCarManager.length, salesFinaceDirector.length + salesFinaceManager.length,
            productSpecialists.length, salesProductSpecialists.length, salesSupport.length + salesPorter.length + adminSupport.length, partsDirector.length + serviceDirector.length + serviceManager.length,
            assistanceServiceDirector.length + serviceAdvisor.length, foreman.length + technicians.length, driver.length + warrantyAdmin.length + dispatcher.length + serviceSupport.length,
            servicePorter.length, detailManager.length + detailDept.length, partsManager.length, counterTeam.length, partsSupport.length, totalCount,
            CommonService.formatNumber_1((foreman.length + technicians.length) / counterTeam.length), CommonService.formatNumber_1((foreman.length + technicians.length) / (assistanceServiceDirector.length + serviceAdvisor.length)),
            CommonService.formatNumber_1((foreman.length + technicians.length) / (driver.length + warrantyAdmin.length + dispatcher.length + serviceSupport.length)), CommonService.formatNumber_1((foreman.length + technicians.length) / servicePorter.length),
            CommonService.formatNumber_1((productSpecialists.length + salesProductSpecialists.length) / (detailManager.length + detailDept.length)), CommonService.formatNumber_1((productSpecialists.length + salesProductSpecialists.length) / (salesFinaceDirector.length + salesFinaceManager.length))]);
        });
        dataList.push(null);

        let groupTotal = generalMgrTotal + salesMgrTotal + usedCarManagerTotal + financeTeamTotal + productSpecialistsTotal + salesProductSpecialistsTotal + salesSupportTotal + serviceMgmtTotal + serviceAdvisorTotal + technicianTotal + serviceSupportTotal +
            servicePortarTotal + detailDeptTotal + partsManagerTotal + counterTeamTotal + partsSupportTotal;
        dataList.push(["Group Totals", generalMgrTotal, salesMgrTotal, usedCarManagerTotal, financeTeamTotal, productSpecialistsTotal, salesProductSpecialistsTotal, salesSupportTotal, serviceMgmtTotal, serviceAdvisorTotal, technicianTotal, serviceSupportTotal,
            servicePortarTotal, detailDeptTotal, partsManagerTotal, counterTeamTotal, partsSupportTotal, groupTotal,
            CommonService.formatNumber_1(technicianTotal / counterTeamTotal), CommonService.formatNumber_1(technicianTotal / serviceAdvisorTotal),
            CommonService.formatNumber_1(technicianTotal / serviceSupportTotal), CommonService.formatNumber_1(technicianTotal / servicePortarTotal),
            CommonService.formatNumber_1((productSpecialistsTotal + salesProductSpecialistsTotal) / detailDeptTotal), CommonService.formatNumber_1((productSpecialistsTotal + salesProductSpecialistsTotal) / salesMgrTotal)]);
        dataList.push([null]);

        let totalCount = tableData.filter(row => row.store_number === 99);
        dataList.push(["DGDG", "#bfbfbf", totalCount.length]);
        dataList.push(["DGDG"]);

        dataList.push(["Grand Total :", groupTotal + totalCount.length]);

        return dataList;
    }

    pageTitle = "Org Chart";

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <OrgChartGroupSummaryQueryComponent
                applicationInsights={this.props.applicationInsights}
                isLoadingData={this.state.isLoadingData}
                isDownLoading={this.props.isDownLoading}
                stores={this.props.stores}
                months={this.props.months}
                onGetChartClick={this.onGetChartClick}
                onDownloadClick={this.props.onDownloadClick}
            />
            <div className="dgdg-site-scrollable-content">
                <div className="card">
                    <div className="card-header">{this.state.tableTitle}</div>
                    <div className="card-block">
                        <table className="dgdg-org-chart-group-summary-table">
                            <tbody>
                                <tr className="text-center" style={{ "fontSize": "16px", "fontWeight": "bold" }}>
                                    <td colSpan="37" className="dgdg-border-thick-top dgdg-border-thick-bottom dgdg-border-thick-left dgdg-border-thick-right">Dealership Department Headcount Breakdown</td>
                                    <td colSpan="8" className="dgdg-border-thick-top dgdg-border-thick-left dgdg-border-thick-right">Ratio of Techs to</td>
                                    <td colSpan="2" className="dgdg-border-thick-top dgdg-border-thick-left dgdg-border-thick-right">Products to</td>
                                </tr>
                                <tr className="text-center">
                                    <td colSpan="2" className="text-left dgdg-org-chart-table-header dgdg-border-thick-left">Stores</td>
                                    <td colSpan="2" className="dgdg-org-chart-table-header dgdg-no-border">General Manager</td>
                                    <td colSpan="2" className="dgdg-org-chart-table-header dgdg-no-border">GSM/SM</td>
                                    <td colSpan="2" className="dgdg-org-chart-table-header dgdg-no-border">Used Car Manager	</td>
                                    <td colSpan="2" className="dgdg-org-chart-table-header dgdg-no-border">Finance Team</td>
                                    <td colSpan="2" className="dgdg-org-chart-table-header dgdg-no-border">Product Specialists</td>
                                    <td colSpan="2" className="dgdg-org-chart-table-header dgdg-no-border">Online Product Specialists</td>
                                    <td colSpan="2" className="dgdg-org-chart-table-header dgdg-no-border">Sales Support</td>
                                    <td colSpan="2" className="dgdg-org-chart-table-header dgdg-no-border">Service Mgmt</td>
                                    <td colSpan="2" className="dgdg-org-chart-table-header dgdg-no-border">Service Advisors</td>
                                    <td colSpan="2" className="dgdg-org-chart-table-header dgdg-no-border">Technicians</td>
                                    <td colSpan="2" className="dgdg-org-chart-table-header dgdg-no-border">Service Support</td>
                                    <td colSpan="2" className="dgdg-org-chart-table-header dgdg-no-border">Service Porter</td>
                                    <td colSpan="2" className="dgdg-org-chart-table-header dgdg-no-border">Detail Dept</td>
                                    <td colSpan="2" className="dgdg-org-chart-table-header dgdg-no-border">Parts Manager</td>
                                    <td colSpan="2" className="dgdg-org-chart-table-header dgdg-no-border">Counter Team</td>
                                    <td colSpan="2" className="dgdg-org-chart-table-header dgdg-no-border">Parts Support</td>
                                    <td colSpan="2" className="dgdg-org-chart-table-header dgdg-border-thick-right">Totals</td>
                                    <td colSpan="2" className="dgdg-org-chart-table-header dgdg-border-thick-left">Counter</td>
                                    <td colSpan="2" className="dgdg-org-chart-table-header dgdg-no-border">Advisors</td>
                                    <td colSpan="2" className="dgdg-org-chart-table-header dgdg-no-border">Support</td>
                                    <td colSpan="2" className="dgdg-org-chart-table-header dgdg-no-border">Porters</td>
                                    <td colSpan="2" className="dgdg-org-chart-table-header dgdg-no-border">Detailers</td>
                                    <td colSpan="2" className="dgdg-org-chart-table-header dgdg-border-thick-right">Finance</td>
                                </tr>
                                {this.state.groupSummaryTree}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>;
    }
}
