import React, { Fragment } from "react";

import moment from "moment";
import $ from "jquery";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGModalConfirmationComponent } from "../common/dgdgmodalconfirmation/DGDGModalConfirmationComponent";
import { DGDGFormTextAreaComponent } from "../common/dgdgtextarea/DGDGFormTextAreaComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableColumnDropdownComponent } from "../common/dgdgtable/DGDGTableColumnDropdownComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

import { DealIssuesDetailQueryComponent } from "./DealIssuesDetailQueryComponent";
import { DealIssuesAddComponent } from "./DealIssuesAddComponent";

export class DealIssuesDetailComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            showDialog: false,
            paintTable: false,
            selectedStore: null,
            selectedType: null,
            selectedStatus: null,
            selectedRow: null,
            tableTitle: "",
            tableData: [],
            showCloseDialog: false,
            showOpenDialog: false,
            actionHelpText: "",
            addTableData: [],
            addTableTitle: "",
            addPaintTable: false
        };

        this.onStoreClick = this.onStoreClick.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onDealNumberClick = this.onDealNumberClick.bind(this);
        this.onAddCommentColumnCallback = this.onAddCommentColumnCallback.bind(this);
        this.onToggleViewComments = this.onToggleViewComments.bind(this);
        this.onToggleAddComments = this.onToggleAddComments.bind(this);
        this.onCommentTextChange = this.onCommentTextChange.bind(this);
        this.onSaveCommentClick = this.onSaveCommentClick.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onEditClick = this.onEditClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onAddDealIssuesClick = this.onAddDealIssuesClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onActionColumnCallback = this.onActionColumnCallback.bind(this);
        this.onActionEditClick = this.onActionEditClick.bind(this);
        this.onOpenOkClick = this.onOpenOkClick.bind(this);
        this.onCloseOkClick = this.onCloseOkClick.bind(this);
        this.onOpenCancelClick = this.onOpenCancelClick.bind(this);
        this.onCloseCancelClick = this.onCloseCancelClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    pageTitle = "Deal Issues"
    statusData = [
        { key: "Open", value: "Open" },
        { key: "Resolved", value: "Resolved" },
        { key: "Closed", value: "Closed" }
    ];

    legendText = <Fragment>
        <div className="d-inline-block dgdg-deal-issues-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-deal-issues-status-bg-resolved" />&nbsp;&nbsp;&nbsp;Resolved</div>
        <div className="d-inline-block dgdg-deal-issues-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-deal-issues-status-bg-closed" />&nbsp;&nbsp;&nbsp;Closed</div>
    </Fragment>;

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/DealIssues/Detail", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("DealIssuesDetailComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStoreClick(selectedStore) {
        try {
            this.setState({
                selectedStore: selectedStore
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(selectedStore, selectedType, selectedStatus, id) {
        try {
            this.setState({
                isLoadingData: true,
                selectedStore: selectedStore,
                selectedType: selectedType,
                selectedStatus: selectedStatus,
            });
            let inputData = {
                id: id,
                storeNumber: selectedStore === "All Stores" ? null : selectedStore.store_number,
                type: selectedType && selectedType.key !== "All" ? selectedType.key : null,
                status: selectedStatus && selectedStatus.key !== "All" ? selectedStatus.key : null
            };
            this.props.applicationInsights.trackTrace({ message: "/DealIssues/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/DealIssues/GetReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let tableData = parsedResponse;
                    let actionHelpText = this.columnText(tableData[0]);
                    tableData.forEach((responseItem, index) => {
                        responseItem.is_status_disabled = responseItem.status === "Closed" ? true : false;
                        responseItem.rowCssName = responseItem.status === "Resolved" ? "dgdg-deal-issues-status-bg-resolved" : responseItem.status === "Closed" ? "dgdg-deal-issues-status-bg-closed" : "";
                    });
                    this.setState({
                        isLoadingData: false,
                        tableData: tableData,
                        tableTitle: selectedStore.display_name,
                        actionHelpText: actionHelpText,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableData: [],
                        tableTitle: "",
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/DealIssues/onGetReportClick", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDealNumberClick(selectedStore, dealNumber) {
        try {
            let inputData = {
                storeNumber: selectedStore.store_number,
                dealNumber: dealNumber,
            };
            this.props.applicationInsights.trackTrace({ message: "/DealIssues/GetReportByDealNumber", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/DealIssues/GetReportByDealNumber", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        addTableData: parsedResponse,
                        addTableTitle: "Existing Deal Issues - " + selectedStore.display_name + " - " + dealNumber,
                        addPaintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        addTableData: [],
                        addTableTitle: "",
                        addPaintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/DealIssues/onGetReportClick", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAddCommentColumnCallback(rowData) {
        let comments = this.getComments(rowData.comment);

        return <Fragment>
            <button key="btnViewComments" type="button" className="btn btn-link" onClick={(event) => this.onToggleViewComments(event, rowData)}>{comments.length / 2} Comments
                {
                    rowData.showViewComments
                        ? <span className="far fa-sort-up dgdg-icon" />
                        : rowData.showViewComments === undefined
                            ? <span className="far fa-sort-up dgdg-icon" >{this.onToggleViewComments(null, rowData)}</span>
                            : <span className="far fa-sort-down dgdg-icon" />
                }
            </button>
            <button key="btnAddComment" type="button" className="btn btn-link dgdg-icon" onClick={(event) => this.onToggleAddComments(event, rowData)}>Add Comments
                {
                    rowData.showAddComment
                        ? <span className="fas fa-times text-danger dgdg-icon" />
                        : <span className="fas fa-plus dgdg-icon" />
                }
            </button>
            <br />
            {
                rowData.showViewComments
                    ? comments
                    : null
            }
            {
                rowData.showAddComment
                    ? <div key="frmAddComment" className="form-inline">
                        <DGDGFormTextAreaComponent id={"comments_" + rowData.store_number + "_" + rowData.deal_number + "_" + rowData.type} value={rowData["comments_" + rowData.store_number + "_" + rowData.deal_number + "_" + rowData.type]} rows="3" onChange={this.onCommentTextChange} colSpan="col-11" controlSpan="col-12" />
                        <button type="button" className="btn btn-link dgdg-font-size-18" onClick={(event) => this.onSaveCommentClick(event, rowData)}>
                            <span className="fas fa-save" />
                        </button>
                        <DGDGSpinnerComponent showSpinner={rowData.comments_showSpinner} />
                    </div>
                    : null
            }
        </Fragment>;
    }

    onToggleViewComments(event, rowData) {
        let tableData = this.state.tableData;
        rowData.showViewComments = rowData.showViewComments ? false : true;
        this.setState({
            tableData: tableData,
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
        });
    }

    onToggleAddComments(event, rowData) {
        let tableData = this.state.tableData;
        rowData.showAddComment = rowData.showAddComment ? false : true;
        this.setState({
            tableData: tableData,
            paintTable: true
        }, () => {
            this.setState({
                paintTable: false
            }, () => {
                if (rowData.showAddComment) {
                    let id = "#comments_" + rowData.store_number + "_" + rowData.deal_number + "_" + rowData.type;
                    $(id).focus();
                }
            });
        });
    }

    onCommentTextChange(id, value) {
        try {
            let tableData = this.state.tableData;
            let rowData = tableData.filter((row) => row.store_number === parseInt(id.split('_')[1]) && row.deal_number === id.split('_')[2] && row.type === id.split('_')[3])[0];
            rowData["comments_" + rowData.store_number + "_" + rowData.deal_number + "_" + rowData.type] = value;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event, issueId) {
        try {
            this.setState({
                selectedRow: null,
                showDialog: false,
                addTableData: [],
                addTableTitle: "",
                addPaintTable: true
            }, () => {
                if (issueId) {
                    window.location = "/DealIssues/Detail/All/All/All/" + issueId;
                }
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAddDealIssuesClick(event) {
        try {
            this.setState({ showDialog: true });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveCommentClick(event, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.comments_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            let inputData = {
                id: rowData.id,
                comments: rowData["comments_" + rowData.store_number + "_" + rowData.deal_number + "_" + rowData.type].replace(/\n|\r/g, " "),
            };
            this.props.applicationInsights.trackTrace({ message: "/DealIssues/onSaveCommentClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/DealIssues/SaveComments", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    rowData.comment = moment().format("MM/DD/YYYY hh:mm A") + ", " + this.props.remoteUpn + ": " + rowData["comments_" + rowData.store_number + "_" + rowData.deal_number + "_" + rowData.type] + (rowData.comment ? '\r' + rowData.comment : "");
                    rowData["comments_" + rowData.store_number + "_" + rowData.deal_number + "_" + rowData.type] = "";
                    rowData.comments_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.comments_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/DealIssues/onSaveCommentClick", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event, dealIssues) {
        try {
            let inputData = dealIssues;
            this.props.applicationInsights.trackTrace({ message: "/DealIssues/onSaveClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/DealIssues/SaveDealIssue", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.onGetReportClick(this.state.selectedStore, this.state.selectedType, this.state.selectedStatus);
                    this.props.onShowAlert("success", { message: "Deal Issues saved" }, 4000);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/DealIssues/SaveDealIssue", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onEditClick(event, dealIssues) {
        try {
            let inputData = dealIssues;
            this.props.applicationInsights.trackTrace({ message: "/DealIssues/onEditClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/DealIssues/EditDealIssue", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.onGetReportClick(this.state.selectedStore, this.state.selectedType, this.state.selectedStatus);
                    this.props.onShowAlert("success", { message: "Deal Issues saved" }, 4000);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/DealIssues/EditDealIssue", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("DealIssues", "onCopyTableCallBack");
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStatusChange(id, selectedItem, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.status = selectedItem;
            rowData.status_showSpinner = true;
            this.setRowColor(rowData);
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                id: rowData.id,
                status: selectedItem,
            };

            this.props.applicationInsights.trackTrace({ message: "/DealIssues/onStatusChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/DealIssues/SaveDealIssueStatus", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    rowData.last_updated_by = this.props.remoteUpn;
                    rowData.status_showSpinner = false;
                    this.setState({
                        isLoadingData: false,
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.onGetReportClick(this.state.selectedStore, this.state.selectedType, this.state.selectedStatus);
                        this.setState({ paintTable: false });
                    });

                    this.props.onShowAlert("success", { message: "Deal Issues Deal #" + rowData.deal_number + " Status saved" }, 4000);
                })
                .catch(notOKResponse => {
                    rowData.status_showSpinner = false;
                    this.setState({
                        isLoadingData: false,
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/DealIssues/SaveDealIssueStatus", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onActionColumnCallback(selectedRow) {
        return <Fragment>
            <button type="button" className="btn btn-link" onClick={(event) => this.onActionEditClick(event, selectedRow)} disabled={!selectedRow.edit_deal_issue}>
                <i className="fas fa-edit" />
            </button>
            {selectedRow.can_close_deal_issue
                ? selectedRow.status === "Closed"
                    ? <button type="button" className="btn btn-link dgdg-deal-issues-action-margin" onClick={(event) => this.onOpenButtonClick(event, selectedRow)} >
                        <i className="fas fa-lock-open" />
                    </button>
                    : <button type="button" className="btn btn-link dgdg-deal-issues-action-margin" onClick={(event) => this.onCloseButtonClick(event, selectedRow)}>
                        <i className="fas fa-lock" />
                    </button>
                : null
            }
        </Fragment >;
    }

    onOpenButtonClick(event, selectedRow) {
        this.setState({
            showOpenDialog: true,
            selectedRow: selectedRow
        });
    }

    onCloseButtonClick(event, selectedRow) {
        this.setState({
            showCloseDialog: true,
            selectedRow: selectedRow
        });
    }

    onOpenOkClick(event) {
        this.onStatusChange(event, "Open", this.state.selectedRow);
        this.setState({ showOpenDialog: false });
    }

    onCloseOkClick(event) {
        this.onStatusChange(event, "Closed", this.state.selectedRow);
        this.setState({ showCloseDialog: false });
    }

    onOpenCancelClick(event) {
        this.setState({ showOpenDialog: false });
    }

    onCloseCancelClick(event) {
        this.setState({ showCloseDialog: false });
    }

    onActionEditClick(event, selectedRow) {
        try {
            this.setState({
                selectedRow: selectedRow,
                showDialog: true
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getComments(comments) {
        let htmlContent = [];
        if (comments !== null) {
            let commentParts = [];
            comments.split('\r').forEach(function (value) {
                commentParts.push(value.split(': '));
            });
            commentParts.forEach((commentPart, commentIndex) => {
                if (commentPart[1]) {
                    htmlContent.push(
                        <Fragment>
                            <span key="comment_part1" style={{ "color": "blue" }}>
                                {(commentPart[0] !== "" ? commentPart[0] + ": " : null)}
                            </span>
                            <span key="comment_part2">
                                {(commentPart[1])}
                            </span>
                        </Fragment>);
                    htmlContent.push(<br key={"comment_br_" + commentIndex} />);
                }
            });
        }

        return htmlContent;
    }

    setRowColor(rowData) {
        switch (rowData.status) {
            case "Open":
                rowData.rowCssName = "dgdg-deal-issues-status-bg-open";
                break;
            case "Resolved":
                rowData.rowCssName = "dgdg-deal-issues-status-bg-resolved";
                break;
            default:
                if (rowData.status) {
                    rowData.rowCssName = "dgdg-deal-issues-status-bg-default";
                }
                else {
                    rowData.rowCssName = null;
                }
        }
    }

    columnText() {
        return <Fragment>
            <span> <i className="fas fa-edit" /> : Edit<br /></span>
            <span> <i className="fas fa-lock" /> : Close, <i className="fas fa-lock-open" /> : Open </span>
        </Fragment>
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText="Close Deal Issue"
                bodyText={"Do you want to close Deal Number: " + this.state.selectedRow?.deal_number + ", Type: " + this.state.selectedRow?.type}
                showConfirmation={this.state.showCloseDialog}
                onOkClick={this.onCloseOkClick}
                onCancelClick={this.onCloseCancelClick}
            />

            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText="Open Deal Issue"
                bodyText={"Do you want to open Deal Number: " + this.state.selectedRow?.deal_number + ", Type: " + this.state.selectedRow?.type}
                showConfirmation={this.state.showOpenDialog}
                onOkClick={this.onOpenOkClick}
                onCancelClick={this.onOpenCancelClick}
            />
            <DealIssuesAddComponent
                applicationInsights={this.props.applicationInsights}
                stores={this.props.stores}
                showDialog={this.state.showDialog}
                selectedStore={this.state.selectedStore}
                rowData={this.state.selectedRow}
                tableData={this.state.addTableData}
                tableTitle={this.state.addTableTitle}
                paintTable={this.state.addPaintTable}
                lastUpdatedBy={this.props.lastUpdatedBy}
                onSaveClick={this.onSaveClick}
                onEditClick={this.onEditClick}
                onCancelClick={this.onCancelClick}
                onDealNumberClick={this.onDealNumberClick}
                onShowAlert={this.props.onShowAlert}
            />

            <DealIssuesDetailQueryComponent {...this.props}
                applicationInsights={this.props.applicationInsights}
                stores={this.props.stores}
                isLoadingData={this.state.isLoadingData}
                onStoreClick={this.onStoreClick}
                onGetReportClick={this.onGetReportClick}
                onAddDealIssuesClick={this.onAddDealIssuesClick}
            />

            <div className="dgdg-site-scrollable-content">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} stickyHeader legendText={this.legendText}
                    paintTable={this.state.paintTable} tablePageSize={CommonService.tablePageSize}
                    copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="Issue Number" dataColumn="id" dataType="number" filterColumn="id" sortColumn="id" />
                        <DGDGTableColumnComponent headerText="Action" dataColumnCallback={this.onActionColumnCallback} cssName="dgdg-directory-employee-action-column text-nowrap" columnHelp={this.state.actionHelpText} columnHelpCssName="dgdg-deal-issues-action-columnHelp-width" />
                        <DGDGTableColumnDropdownComponent headerText="Status" dataColumn="status" filterColumn="status" sortColumn="status" data={["Open", "Resolved"]} disabledColumn="is_status_disabled" headerCssName="dgdg-deal-issues-col-status" onItemClick={this.onStatusChange} showSpinner />
                        <DGDGTableColumnComponent headerText="Type" dataColumn="type" filterColumn="type" sortColumn="type" headerCssName="dgdg-deal-issues-col-type" showSpinner />
                        <DGDGTableColumnComponent headerText="Store Name" cssName="text-nowrap" dataColumn="store_web_name" filterColumn="store_web_name" sortColumn="store_number" />
                        <DGDGTableColumnComponent headerText="Deal Number" dataColumn="deal_number" filterColumn="deal_number" sortColumn="deal_number" />
                        <DGDGTableColumnComponent headerText="Contract Date" dataColumn="contract_date" sortColumn="contract_date" filterColumn="contract_date" dataType="date" />
                        <DGDGTableColumnComponent headerText="Created By" dataColumn="created_by" filterColumn="created_by" sortColumn="created_by" />
                        <DGDGTableColumnComponent headerText="Created Date" dataColumn="created_date" sortColumn="created_date" filterColumn="created_date" dataType="dateTime" />
                        <DGDGTableColumnComponent headerText="Amount" dataColumn="amount" filterColumn="amount" sortColumn="amount" dataType="money_2" />
                        <DGDGTableColumnComponent headerText="New Used Wholesale" dataColumn="new_used_wholesale" filterColumn="new_used_wholesale" sortColumn="new_used_wholesale" />
                        <DGDGTableColumnComponent headerText="Make" dataColumn="make" filterColumn="make" sortColumn="make" />
                        <DGDGTableColumnComponent headerText="Year" dataColumn="year" filterColumn="year" sortColumn="year" />
                        <DGDGTableColumnComponent headerText="Model" dataColumn="model" filterColumn="model" sortColumn="model" />
                        <DGDGTableColumnComponent headerText="Stock Number" dataColumn="stock_number" filterColumn="stock_number" sortColumn="stock_number" />
                        <DGDGTableColumnComponent headerText="VIN" dataColumn="vin" filterColumn="vin" sortColumn="vin" />
                        <DGDGTableColumnComponent headerText="Customer Name" dataColumn="customer_name" filterColumn="customer_name" sortColumn="customer_name" />
                        <DGDGTableColumnComponent headerText="Finance Source" dataColumn="finance_source" filterColumn="finance_source" sortColumn="finance_source" />
                        <DGDGTableColumnComponent headerText="Sales Manager Name" dataColumn="sales_mgr_name" filterColumn="sales_mgr_name" sortColumn="sales_mgr_name" />
                        <DGDGTableColumnComponent headerText="Finance Manager Name" dataColumn="finance_mgr_name" filterColumn="finance_mgr_name" sortColumn="finance_mgr_name" />
                        <DGDGTableColumnComponent headerText="Deal Status" dataColumn="wip_status" filterColumn="wip_status" sortColumn="wip_status" />
                        <DGDGTableColumnComponent headerText="Last Updated By" dataColumn="last_updated_by" filterColumn="last_updated_by" sortColumn="last_updated_by" />
                        <DGDGTableColumnComponent headerText="Last Updated Date" dataColumn="last_updated_date" sortColumn="last_updated_date" filterColumn="last_updated_date" dataType="dateTime" />
                    </DGDGTableRowComponent>
                    <DGDGTableRowComponent cssName="dgdg-no-border-top">
                        <DGDGTableColumnComponent colSpan="6" headerText="Issue" dataColumn="issue" filterColumn="issue" sortColumn="issue" showHeader />
                        <DGDGTableColumnComponent colSpan="12" dataColumnCallback={this.onAddCommentColumnCallback} />
                        <DGDGTableColumnComponent colSpan="5" />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
        </div>;
    }
}
