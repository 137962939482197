import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGModalConfirmationComponent } from "../common/dgdgmodalconfirmation/DGDGModalConfirmationComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

export class FIPayrollQueryComponent extends React.Component {
    static StoreNumberCookie = "FIPayrollStoreNumberCookie";

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            if (state.showSpinner) {
                newState.showSpinner = props.isProcessingAdjustments || props.isDownLoading;
            }

            if (props.stores && state.selectedStore === null) {
                let selectedStore = props.stores[0];
                let cookieStoreNumber = CommonService.getCookie(FIPayrollQueryComponent.StoreNumberCookie);
                if (cookieStoreNumber) {
                    cookieStoreNumber = parseInt(cookieStoreNumber);
                    let filterStore = props.stores.filter(store => store.store_number === cookieStoreNumber);
                    if (filterStore.length === 1) {
                        selectedStore = filterStore[0];
                    }
                }

                newState.selectedStore = selectedStore;
            }

            let selectedMonth = moment(state.selectedMonth);
            if (props.months && state.selectedMonth === null) {
                selectedMonth = props.months[0];
                newState.selectedMonth = selectedMonth;
                selectedMonth = moment(selectedMonth);
            }

            let isImportPayrollDataEnabled = true, isProcessAdjustmentEnabled = true;
            let minCutoffDate = moment().startOf("month").subtract(1, "month");
            let prevMonthCutoffDate = moment().startOf("month").add(9, "day");
            let currentDate = moment();
            if (selectedMonth < minCutoffDate
                || (selectedMonth.month() === currentDate.month() - 1 && currentDate > prevMonthCutoffDate)) {
                isImportPayrollDataEnabled = false;
                isProcessAdjustmentEnabled = false;
            }

            newState.isImportPayrollDataEnabled = isImportPayrollDataEnabled;
            newState.isProcessAdjustmentEnabled = isProcessAdjustmentEnabled;
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedMonth: null,
            selectedStore: null,
            additionalEmployeeNumber: "",
            isImportPayrollDataEnabled: false,
            isProcessAdjustmentEnabled: false,
            showSpinner: false,
            showProcessAdjustmentDialog: false
        };

        this.onMonthClick = this.onMonthClick.bind(this);
        this.onStoreClick = this.onStoreClick.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onGetPayrollClick = this.onGetPayrollClick.bind(this);
        this.onImportPayrollDataClick = this.onImportPayrollDataClick.bind(this);
        this.onProcessAdjustmentsCheckClick = this.onProcessAdjustmentsCheckClick.bind(this);
        this.onProcessAdjustmentsCheckOkClick = this.onProcessAdjustmentsCheckOkClick.bind(this);
        this.onProcessAdjustmentsCheckCancelClick = this.onProcessAdjustmentsCheckCancelClick.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onDownload2Click = this.onDownload2Click.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.stores && this.props.months && this.state.selectedMonth === null && this.state.selectedStore === null) {
                this.setState({
                    selectedStore: this.props.stores[0],
                    selectedMonth: this.props.months[0]
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onMonthClick(id, month) {
        try {
            this.setState({ selectedMonth: month });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStoreClick(id, store) {
        try {
            this.setState({ selectedStore: store });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetPayrollClick(event) {
        this.setState({ showSpinner: true });
        CommonService.setCookie(FIPayrollQueryComponent.StoreNumberCookie, this.state.selectedStore.store_number, 365);
        this.props.onGetPayrollClick(this.state.selectedMonth, this.state.selectedStore, this.state.additionalEmployeeNumber);
    }

    onImportPayrollDataClick() {
        this.setState({ showSpinner: true });
        this.props.onImportPayrollDataClick(this.state.selectedMonth);
    }

    onProcessAdjustmentsCheckClick(event) {
        try {
            this.setState({ showProcessAdjustmentDialog: true });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onProcessAdjustmentsCheckOkClick(event) {
        this.props.onProcessAdjustments(event, this.state.selectedMonth);
        this.setState({
            showProcessAdjustmentDialog: false,
            showSpinner: true
        });
    }

    onProcessAdjustmentsCheckCancelClick(event) {
        try {
            this.setState({ showProcessAdjustmentDialog: false });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDownloadClick(event) {
        try {
            this.setState({ showSpinner: true });
            CommonService.setCookie(FIPayrollQueryComponent.StoreNumberCookie, this.state.selectedStore.store_number, 30);
            this.props.onDownloadClick(this.state.selectedMonth, this.state.selectedStore);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDownload2Click(event) {
        try {
            this.setState({ showSpinner: true });
            CommonService.setCookie(FIPayrollQueryComponent.StoreNumberCookie, this.state.selectedStore.store_number, 30);
            this.props.onDownload2Click(this.state.selectedMonth, this.state.selectedStore);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGetPayrollClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="card dgdg-card-gutter">
            <div className="card-block">
                <div className="form-inline">
                    <DGDGModalConfirmationComponent
                        applicationInsights={this.props.applicationInsights}
                        headerText={"Process F&I Payroll Adjustments"}
                        bodyText="Are you sure?"
                        showConfirmation={this.state.showProcessAdjustmentDialog === true}
                        onOkClick={this.onProcessAdjustmentsCheckOkClick}
                        onCancelClick={this.onProcessAdjustmentsCheckCancelClick}
                    />
                    <DGDGFormDropdownComponent label="Store #" data={this.props.stores} itemKey="display_name" value={this.state.selectedStore ? this.state.selectedStore.display_name : "Select Store"} onItemClick={this.onStoreClick} onKeyDown={this.onKeyDown} />
                    <DGDGFormDropdownComponent label="Month" displayFormat="monthYear" data={this.props.months} value={this.state.selectedMonth} onItemClick={this.onMonthClick} onKeyDown={this.onKeyDown} />
                    <DGDGFormInputComponent id="additionalEmployeeNumber" label="Addl Emp #" value={this.state.additionalEmployeeNumber} onChange={this.onTextChange} onKeyDown={this.onKeyDown} />
                    <button disabled={this.state.showSpinner || this.state.selectedStore === null} className="btn btn-primary dgdg-icon" onClick={(event) => this.onGetPayrollClick(event)}>
                        Get Payroll
                    </button>
                    <button disabled={this.state.showSpinner || this.state.selectedMonth === null || !this.state.isImportPayrollDataEnabled || !this.props.canProcess} className="btn btn-primary dgdg-icon" onClick={(event) => this.onImportPayrollDataClick(event)}>
                        Import Payroll Data<i className="far fa-copy dgdg-icon" />
                    </button>
                    <button disabled={this.state.showSpinner || this.state.selectedMonth === null || !this.state.isProcessAdjustmentEnabled || !this.props.canProcess} className="btn btn-primary dgdg-icon" onClick={(event) => this.onProcessAdjustmentsCheckClick(event)}>
                        Process Adjustments<i className="fas fa-sync-alt dgdg-icon" />
                    </button>
                    <button disabled={this.state.showSpinner || this.state.selectedStore === null || !this.state.selectedStore || !this.state.selectedStore.store_number} className="btn btn-primary dgdg-icon" onClick={(event) => this.onDownloadClick(event)}>
                        Download<span className="far fa-download dgdg-icon" />
                    </button>
                    <button disabled={this.state.showSpinner || this.state.selectedStore === null || !this.state.selectedStore || !this.state.selectedStore.store_number} className="btn btn-primary dgdg-icon" onClick={(event) => this.onDownload2Click(event)}>
                        Download 2<span className="far fa-download dgdg-icon" />
                    </button>
                    <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
                </div>
            </div>
        </div>;
    }
}
