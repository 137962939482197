import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { ServiceManagerPayrollQueryComponent } from "./ServiceManagerPayrollQueryComponent";

export class ServiceManagerPayrollPrimaryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            months: CommonService.getMonths(),
            tableTitle: "",
            subStoresTableTitle: ""
        };

        this.onGetPayrollClick = this.onGetPayrollClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/ServiceManagerPayroll", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("ServiceManagerPayrollPrimaryComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Service & Parts Manager Payroll";

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("ServiceManagerPayrollSecondaryComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetPayrollClick(selectedMonth) {
        try {
            if (selectedMonth !== null) {
                this.props.onGetPayrollClick(selectedMonth);
                this.setState({
                    tableTitle: "Service & Parts Stores Primary - " + CommonService.formatMonthYear(moment(selectedMonth)),
                    subStoresTableTitle: "Service & Parts Primary Sub Stores - " + CommonService.formatMonthYear(moment(selectedMonth))
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <ServiceManagerPayrollQueryComponent
                isLoadingData={this.props.isLoadingData}
                months={this.props.months}
                applicationInsights={this.props.applicationInsights}
                onGetPayrollClick={this.onGetPayrollClick}
            />
            <div className="dgdg-site-scrollable-content">
                <div className="dgdg-service-manager-payroll">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} cardCssName="dgdg-service-manager-payroll-gap" tableData={this.props.tableData} headerText={this.state.tableTitle} paintTable={this.props.paintTable} stickyHeader showFooter
                        tablePageSize={CommonService.tablePageSize} copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                            <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_name_web" sortColumn="store_number" filterColumn="store_name_web" footerText="DGDG" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Loaner Income/Expense" dataColumn="loaner_income_expense" sortColumn="loaner_income_expense" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Proficiency %" dataColumn="proficiency" sortColumn="proficiency" dataType="percent" footerCssName="text-right" footerFunction="average" />
                            <DGDGTableColumnComponent headerText="Monthy Budget" dataColumn="monthly_budget" sortColumn="monthly_budget" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Tracking Fixed Comm Gross vs Budget" dataColumn="fixed_gross_track_goal_diff" sortColumn="fixed_gross_track_goal_diff" dataType="money" footerCssName="text-right" footerFunction="sum" headerColSpan="2" />
                            <DGDGTableColumnComponent headerText="" dataColumn="fixed_gross_track_goal_pct_diff" sortColumn="fixed_gross_track_goal_pct_diff" dataType="signed_percent" footerCssName="text-right" footerFunction="average" />
                            <DGDGTableColumnComponent headerText="Used Days in Shop" dataColumn="used_days_in_shop_avg" sortColumn="used_days_in_shop_avg" dataType="formatted_number_2" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="CP & Ext/US Warranty Gross" dataColumn="cp_us_warranty_gross" sortColumn="cp_us_warranty_gross" filterColumn="cp_us_warranty_gross" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Service Gross" dataColumn="service_gross" sortColumn="service_gross" dataType="money" columnHelp="Service Gross: OEM Service Gross excluding GO&G" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Parts Gross" dataColumn="parts_gross" sortColumn="parts_gross" dataType="money_2" columnHelp="Parts Gross: OEM Parts Gross including GO&G and including Inventory Adjustments" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Fixed Gross" dataColumn="fixed_gross" sortColumn="fixed_gross" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} tableData={this.props.subStoresTableData} headerText={this.state.subStoresTableTitle} paintTable={this.props.paintTable}
                        tablePageSize={CommonService.tablePageSize} copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], onCopyTableCallBack: this.onCopyTableCallBack }} showFooter
                    >
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                            <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_name_web" sortColumn="store_number" filterColumn="store_name_web" footerText="DGDG" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Sub Store" dataColumn="sub_store" sortColumn="sub_store" filterColumn="sub_store" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Loaner Income/Expense" dataColumn="loaner_income_expense" sortColumn="loaner_income_expense" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Proficiency %" dataColumn="proficiency" sortColumn="proficiency" dataType="percent" footerCssName="text-right" footerFunction="average" />
                            <DGDGTableColumnComponent headerText="Monthy Budget" dataColumn="monthly_budget" sortColumn="monthly_budget" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Tracking Fixed Comm Gross vs Budget" dataColumn="fixed_gross_track_goal_diff" dataType="money_2" sortColumn="fixed_gross_track_goal_diff" footerCssName="text-right" footerFunction="sum" headerColSpan="2" />
                            <DGDGTableColumnComponent headerText="" dataColumn="fixed_gross_track_goal_pct_diff" sortColumn="fixed_gross_track_goal_pct_diff" dataType="signed_percent" footerCssName="text-right" footerFunction="average" />
                            <DGDGTableColumnComponent headerText="Used Days in Shop" dataColumn="used_days_in_shop_avg" sortColumn="used_days_in_shop_avg" dataType="formatted_number_2" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="CP & Ext/US Warranty Gross" dataColumn="cp_us_warranty_gross" sortColumn="cp_us_warranty_gross" filterColumn="cp_us_warranty_gross" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Service Gross" dataColumn="service_gross" sortColumn="service_gross" dataType="money" columnHelp="Service Gross: OEM Service Gross excluding GO&G" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Parts Gross" dataColumn="parts_gross" sortColumn="parts_gross" dataType="money_2" columnHelp="Parts Gross: OEM Parts Gross including GO&G and including Inventory Adjustments" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Fixed Gross" dataColumn="fixed_gross" sortColumn="fixed_gross" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
            </div>
        </div>;
    }
}