import React, { Fragment } from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";

export class TechnicianPayrollDetailEditComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pay_1_plan_name: "",
            pay_1_base_hourly_rate: "",
            pay_1_flat_rate: "",
            pay_1_bonused_rate: "",
            pay_1_sold_hours: "",
            pay_1_commission_bonus: "",
            pay_1_sick_labor_rate: "",
            pay_1_pto_labor_rate: "",
            pay_1_bereavement_labor_rate: "",
            pay_1_holiday_labor_rate: "",
            pay_1_training_labor_rate: "",
            pay_1_non_commission_bonus: "",
            pay_1_mealtime_premium_hours: "",
            pay_1_comments: "",
            pay_2_plan_name: "",
            pay_2_base_hourly_rate: "",
            pay_2_flat_rate: "",
            pay_2_bonused_rate: "",
            pay_2_sold_hours: "",
            pay_2_commission_bonus: "",
            pay_2_sick_labor_rate: "",
            pay_2_pto_labor_rate: "",
            pay_2_bereavement_labor_rate: "",
            pay_2_holiday_labor_rate: "",
            pay_2_training_labor_rate: "",
            pay_2_non_commission_bonus: "",
            pay_2_mealtime_premium_hours: "",
            pay_2_comments: "",
            planNames: ["B", "F", "H", "G", "G1", "D1", "M1", "L1", "R1"]
        };

        this.onTextChange = this.onTextChange.bind(this);
        this.onCheckChanged = this.onCheckChanged.bind(this);
        this.onPlanNameChange = this.onPlanNameChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.payrollDetail
                && (!prevProps.payrollDetail || prevProps.payrollDetail.employee_number !== this.props.payrollDetail.employee_number)) {
                this.setState({
                    pay_1_plan_name: this.props.payrollDetail.pay_1_plan_name ? this.props.payrollDetail.pay_1_plan_name : "Select Plan",
                    pay_1_base_hourly_rate: this.props.payrollDetail.pay_1_base_hourly_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_base_hourly_rate) : "",
                    pay_1_flat_rate: this.props.payrollDetail.pay_1_flat_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_flat_rate) : "",
                    pay_1_bonused_rate: this.props.payrollDetail.pay_1_bonused_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_bonused_rate) : "",
                    pay_1_sold_hours: this.props.payrollDetail.pay_1_sold_hours !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_sold_hours) : "",
                    pay_1_commission_bonus: this.props.payrollDetail.pay_1_commission_bonus !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_commission_bonus) : "",
                    pay_1_sick_labor_rate: this.props.payrollDetail.pay_1_sick_labor_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_sick_labor_rate) : "",
                    pay_1_pto_labor_rate: this.props.payrollDetail.pay_1_pto_labor_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_pto_labor_rate) : "",
                    pay_1_bereavement_labor_rate: this.props.payrollDetail.pay_1_bereavement_labor_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_bereavement_labor_rate) : "",
                    pay_1_holiday_labor_rate: this.props.payrollDetail.pay_1_holiday_labor_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_holiday_labor_rate) : "",
                    pay_1_training_labor_rate: this.props.payrollDetail.pay_1_training_labor_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_training_labor_rate) : "",
                    pay_1_non_commission_bonus: this.props.payrollDetail.pay_1_non_commission_bonus !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_non_commission_bonus) : "",
                    pay_1_mealtime_premium_hours: this.props.payrollDetail.pay_1_mealtime_premium_hours !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_mealtime_premium_hours) : "",
                    pay_1_comments: this.props.payrollDetail.pay_1_comments !== null ? this.props.payrollDetail.pay_1_comments : "",
                    pay_2_plan_name: this.props.payrollDetail.pay_2_plan_name ? this.props.payrollDetail.pay_2_plan_name : "Select Plan",
                    pay_2_base_hourly_rate: this.props.payrollDetail.pay_2_base_hourly_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_base_hourly_rate) : "",
                    pay_2_flat_rate: this.props.payrollDetail.pay_2_flat_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_flat_rate) : "",
                    pay_2_bonused_rate: this.props.payrollDetail.pay_2_bonused_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_bonused_rate) : "",
                    pay_2_sold_hours: this.props.payrollDetail.pay_2_sold_hours !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_sold_hours) : "",
                    pay_2_commission_bonus: this.props.payrollDetail.pay_2_commission_bonus !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_commission_bonus) : "",
                    pay_2_sick_labor_rate: this.props.payrollDetail.pay_2_sick_labor_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_sick_labor_rate) : "",
                    pay_2_pto_labor_rate: this.props.payrollDetail.pay_2_pto_labor_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_pto_labor_rate) : "",
                    pay_2_bereavement_labor_rate: this.props.payrollDetail.pay_2_bereavement_labor_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_bereavement_labor_rate) : "",
                    pay_2_holiday_labor_rate: this.props.payrollDetail.pay_2_holiday_labor_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_holiday_labor_rate) : "",
                    pay_2_training_labor_rate: this.props.payrollDetail.pay_2_training_labor_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_training_labor_rate) : "",
                    pay_2_non_commission_bonus: this.props.payrollDetail.pay_2_non_commission_bonus !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_non_commission_bonus) : "",
                    pay_2_mealtime_premium_hours: this.props.payrollDetail.pay_2_mealtime_premium_hours !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_mealtime_premium_hours) : "",
                    pay_2_comments: this.props.payrollDetail.pay_2_comments !== null ? this.props.payrollDetail.pay_2_comments : ""
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Technician Payroll";

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCheckChanged(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPlanNameChange(id, planName) {
        try {
            this.setState({ [id]: planName });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event) {
        try {
            this.setState({ showProgress: true });
            let inputData = {
                payPeriod: this.props.payPeriod,
                payrollMonth: CommonService.formatServerDateTime(this.props.payrollMonth),
                storeNumber: this.props.payrollDetail.store_number,
                employeeNumber: this.props.payrollDetail.employee_number,

                pay1planName: this.state.pay_1_plan_name === "Select Plan" ? null : this.state.pay_1_plan_name,
                pay1baseHourlyRate: this.state.pay_1_base_hourly_rate,
                pay1FlatRate: this.state.pay_1_flat_rate,
                pay1BonusedRate: this.state.pay_1_bonused_rate,
                pay1SoldHours: this.state.pay_1_sold_hours,
                pay1CommissionBonus: this.state.pay_1_commission_bonus,
                pay1SickLaborRate: this.state.pay_1_sick_labor_rate,
                pay1PtoLaborRate: this.state.pay_1_pto_labor_rate,
                pay1BereavementLaborRate: this.state.pay_1_bereavement_labor_rate,
                pay1HolidayLaborRate: this.state.pay_1_holiday_labor_rate,
                pay1TrainingLaborRate: this.state.pay_1_training_labor_rate,
                pay1NonCommissionBonus: this.state.pay_1_non_commission_bonus,
                pay1MealtimePremiumHours: this.state.pay_1_mealtime_premium_hours,
                pay1Comments: this.state.pay_1_comments,

                pay2planName: this.state.pay_2_plan_name === "Select Plan" ? null : this.state.pay_2_plan_name,
                pay2baseHourlyRate: this.state.pay_2_base_hourly_rate,
                pay2FlatRate: this.state.pay_2_flat_rate,
                pay2BonusedRate: this.state.pay_2_bonused_rate,
                pay2SoldHours: this.state.pay_2_sold_hours,
                pay2CommissionBonus: this.state.pay_2_commission_bonus,
                pay2SickLaborRate: this.state.pay_2_sick_labor_rate,
                pay2PtoLaborRate: this.state.pay_2_pto_labor_rate,
                pay2BereavementLaborRate: this.state.pay_2_bereavement_labor_rate,
                pay2HolidayLaborRate: this.state.pay_2_holiday_labor_rate,
                pay2TrainingLaborRate: this.state.pay_2_training_labor_rate,
                pay2NonCommissionBonus: this.state.pay_2_non_commission_bonus,
                pay2MealtimePremiumHours: this.state.pay_2_mealtime_premium_hours,
                pay2Comments: this.state.pay_2_comments
            };

            this.props.applicationInsights.trackTrace({ message: "/TechnicianPayrollDetailEditComponent/onSaveClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/TechnicianPayroll/UpdateTechnicianPayroll", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.props.onSaveClick(event);
                    this.setState({ showProgress: false });
                })
                .catch(notOKResponse => {
                    this.setState({ showProgress: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TeamMemberProfile/UpdateTechnicianPayroll", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                pay_1_plan_name: "",
                pay_1_base_hourly_rate: "",
                pay_1_flat_rate: "",
                pay_1_bonused_rate: "",
                pay_1_sold_hours: "",
                pay_1_commission_bonus: "",
                pay_1_sick_labor_rate: "",
                pay_1_pto_labor_rate: "",
                pay_1_bereavement_labor_rate: "",
                pay_1_holiday_labor_rate: "",
                pay_1_training_labor_rate: "",
                pay_1_non_commission_bonus: "",
                pay_1_mealtime_premium_hours: "",
                pay_1_comments: "",
                pay_2_plan_name: "",
                pay_2_base_hourly_rate: "",
                pay_2_flat_rate: "",
                pay_2_bonused_rate: "",
                pay_2_sold_hours: "",
                pay_2_commission_bonus: "",
                pay_2_sick_labor_rate: "",
                pay_2_pto_labor_rate: "",
                pay_2_bereavement_labor_rate: "",
                pay_2_holiday_labor_rate: "",
                pay_2_training_labor_rate: "",
                pay_2_non_commission_bonus: "",
                pay_2_mealtime_premium_hours: "",
                pay_2_comments: ""
            });
            this.props.onCancelClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        let title = "";
        if (this.props.payrollDetail) {
            title = "Edit Employee Pay Month & Pay Periods - " + this.props.payrollDetail.employee_name + " (" + this.props.payrollDetail.employee_number + ") - " + this.props.payrollDetail.store_name + " - " + CommonService.formatMonthYear(moment(this.props.payrollMonth));
        }

        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.props.payrollDetail !== null}
            cssName="dgdg-technician-payroll-detail-dialog"
            title={title}
            onCancelClick={this.onCancelClick}
        >
            <DGDGModalDialogHeaderButtonComponent>
                <button className="btn btn-primary" onClick={this.onSaveClick}>
                    Save <i className="fas fa-save dgdg-icon" />
                </button>
            </DGDGModalDialogHeaderButtonComponent>
            {
                this.props.payrollDetail
                    ? <div className="dgdg-payroll">
                        <div className="form-inline">
                            &nbsp;Pay Month: {CommonService.formatMonthYear(moment(this.props.payrollMonth))}
                        </div>
                        <table className="dgdg-payroll-period-edit">
                            <tbody>
                                {
                                    this.props.payPeriod === 1
                                        ? <Fragment>
                                            <tr>
                                                <td rowSpan="2" className="dgdg-no-padding-bottom">Pay Period: 1</td>
                                                <td className="dgdg-no-padding-bottom" style={{ "minWidth": "100px" }}>
                                                    <div>
                                                        <DGDGFormDropdownComponent controlCssName="dgdg-tech-payroll-plan-name" label="Plan Name" id="pay_1_plan_name" data={this.state.planNames} value={this.state.pay_1_plan_name} onItemClick={this.onPlanNameChange} />
                                                    </div>
                                                </td>
                                                <td className="dgdg-no-padding-bottom">
                                                    <DGDGFormInputComponent label="Base Hourly Rate" controlCssName="dgdg-money" id="pay_1_base_hourly_rate" inputType="money" value={this.state.pay_1_base_hourly_rate} onChange={this.onTextChange} />
                                                </td>
                                                <td className="dgdg-no-padding-bottom">
                                                    <DGDGFormInputComponent label="Flat Rate" controlCssName="dgdg-money" id="pay_1_flat_rate" inputType="money" value={this.state.pay_1_flat_rate} onChange={this.onTextChange} />
                                                </td>
                                                <td className="dgdg-no-padding-bottom">
                                                    <DGDGFormInputComponent label="Bonused Rate" controlCssName="dgdg-money" id="pay_1_bonused_rate" inputType="money" value={this.state.pay_1_bonused_rate} onChange={this.onTextChange} />
                                                </td>
                                                <td className="dgdg-no-padding-bottom">
                                                    <DGDGFormInputComponent label="Comm Bonus" controlCssName="dgdg-money" id="pay_1_commission_bonus" inputType="money" value={this.state.pay_1_commission_bonus} onChange={this.onTextChange} />
                                                </td>
                                                <td className="dgdg-no-padding-bottom">
                                                    <DGDGFormInputComponent label="Sick Rate" controlCssName="dgdg-money" id="pay_1_sick_labor_rate" inputType="money" value={this.state.pay_1_sick_labor_rate} onChange={this.onTextChange} />
                                                </td>
                                                <td className="dgdg-no-padding-bottom">
                                                    <DGDGFormInputComponent label="PTO Rate" controlCssName="dgdg-money" id="pay_1_pto_labor_rate" inputType="money" value={this.state.pay_1_pto_labor_rate} onChange={this.onTextChange} />
                                                </td>
                                            </tr>
                                            <tr style={{ "borderBottom": "none" }}>
                                                <td class="dgdg-no-padding-bottom" colspan="1"></td>
                                                <td className="dgdg-no-padding-bottom">
                                                    <DGDGFormInputComponent label="Berv Rate" controlCssName="dgdg-money" id="pay_1_bereavement_labor_rate" inputType="money" value={this.state.pay_1_bereavement_labor_rate} onChange={this.onTextChange} />
                                                </td>
                                                <td className="dgdg-no-padding-bottom">
                                                    <DGDGFormInputComponent label="Holiday Rate" controlCssName="dgdg-money" id="pay_1_holiday_labor_rate" inputType="money" value={this.state.pay_1_holiday_labor_rate} onChange={this.onTextChange} />
                                                </td>
                                                <td className="dgdg-no-padding-bottom">
                                                    <DGDGFormInputComponent label="Training Rate" controlCssName="dgdg-money" id="pay_1_training_labor_rate" inputType="money" value={this.state.pay_1_training_labor_rate} onChange={this.onTextChange} />
                                                </td>
                                                <td className="dgdg-no-padding-bottom">
                                                    <DGDGFormInputComponent label="Non Comm Bonus" controlCssName="dgdg-money" id="pay_1_non_commission_bonus" inputType="money" value={this.state.pay_1_non_commission_bonus} onChange={this.onTextChange} />
                                                </td>
                                                <td className="dgdg-no-padding-bottom">
                                                    <DGDGFormInputComponent label="Sold Hours" controlCssName="dgdg-money" id="pay_1_sold_hours" inputType="decimal" value={this.state.pay_1_sold_hours} onChange={this.onTextChange} />
                                                </td>
                                                <td className="dgdg-no-padding-bottom">
                                                    <DGDGFormInputComponent label="Mealtime Prem Hours" controlCssName="dgdg-money" labelCssName="dgdg-payroll-width" id="pay_1_mealtime_premium_hours" inputType="decimal" value={this.state.pay_1_mealtime_premium_hours} onChange={this.onTextChange} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="dgdg-no-border-top text-right">Comments:</td>
                                                <td colSpan="12" className="dgdg-no-border-top">
                                                    <DGDGFormInputComponent controlCssName="dgdg-technician-payroll-comments" id="pay_1_comments" value={this.state.pay_1_comments} onChange={this.onTextChange} />
                                                </td>
                                            </tr>
                                        </Fragment>
                                        : <Fragment>
                                            <tr>
                                                <td rowSpan="2" className="dgdg-no-padding-bottom">Pay Period: 2</td>
                                                <td className="dgdg-no-padding-bottom">
                                                    <DGDGFormDropdownComponent controlCssName="dgdg-tech-payroll-plan-name" label="Plan Name" id="pay_2_plan_name" data={this.state.planNames} value={this.state.pay_2_plan_name} onItemClick={this.onPlanNameChange} />
                                                </td>
                                                <td className="dgdg-no-padding-bottom">
                                                    <DGDGFormInputComponent label="Base Hourly Rate" controlCssName="dgdg-money" id="pay_2_base_hourly_rate" inputType="money" value={this.state.pay_2_base_hourly_rate} onChange={this.onTextChange} />
                                                </td>
                                                <td className="dgdg-no-padding-bottom">
                                                    <DGDGFormInputComponent label="Flat Rate" controlCssName="dgdg-money" id="pay_2_flat_rate" inputType="money" value={this.state.pay_2_flat_rate} onChange={this.onTextChange} />
                                                </td>
                                                <td className="dgdg-no-padding-bottom">
                                                    <DGDGFormInputComponent label="Bonused Rate" controlCssName="dgdg-money" id="pay_2_bonused_rate" inputType="money" value={this.state.pay_2_bonused_rate} onChange={this.onTextChange} />
                                                </td>
                                                <td className="dgdg-no-padding-bottom">
                                                    <DGDGFormInputComponent label="Comm Bonus" controlCssName="dgdg-money" id="pay_2_commission_bonus" inputType="money" value={this.state.pay_2_commission_bonus} onChange={this.onTextChange} />
                                                </td>
                                                <td className="dgdg-no-padding-bottom">
                                                    <DGDGFormInputComponent label="Sick Rate" controlCssName="dgdg-money" id="pay_2_sick_labor_rate" inputType="money" value={this.state.pay_2_sick_labor_rate} onChange={this.onTextChange} />
                                                </td>
                                                <td className="dgdg-no-padding-bottom">
                                                    <DGDGFormInputComponent label="PTO Rate" controlCssName="dgdg-money" id="pay_2_pto_labor_rate" inputType="money" value={this.state.pay_2_pto_labor_rate} onChange={this.onTextChange} />
                                                </td>
                                            </tr>
                                            <tr style={{ "borderBottom": "none" }}>
                                                <td class="dgdg-no-padding-bottom" colspan="1"></td>
                                                <td className="dgdg-no-padding-bottom">
                                                    <DGDGFormInputComponent label="Berv Rate" controlCssName="dgdg-money" id="pay_2_bereavement_labor_rate" inputType="money" value={this.state.pay_2_bereavement_labor_rate} onChange={this.onTextChange} />
                                                </td>
                                                <td className="dgdg-no-padding-bottom">
                                                    <DGDGFormInputComponent label="Holiday Rate" controlCssName="dgdg-money" id="pay_2_holiday_labor_rate" inputType="money" value={this.state.pay_2_holiday_labor_rate} onChange={this.onTextChange} />
                                                </td>
                                                <td className="dgdg-no-padding-bottom">
                                                    <DGDGFormInputComponent label="Training Rate" controlCssName="dgdg-money" id="pay_2_training_labor_rate" inputType="money" value={this.state.pay_2_training_labor_rate} onChange={this.onTextChange} />
                                                </td>
                                                <td className="dgdg-no-padding-bottom">
                                                    <DGDGFormInputComponent label="Non Comm Bonus" controlCssName="dgdg-money" id="pay_2_non_commission_bonus" inputType="money" value={this.state.pay_2_non_commission_bonus} onChange={this.onTextChange} />
                                                </td>
                                                <td className="dgdg-no-padding-bottom">
                                                    <DGDGFormInputComponent label="Sold Hours" controlCssName="dgdg-money" id="pay_2_sold_hours" inputType="decimal" value={this.state.pay_2_sold_hours} onChange={this.onTextChange} />
                                                </td>
                                                <td className="dgdg-no-padding-bottom">
                                                    <DGDGFormInputComponent label="Mealtime Prem Hours" controlCssName="dgdg-money" labelCssName="dgdg-payroll-width" id="pay_2_mealtime_premium_hours" inputType="decimal" value={this.state.pay_2_mealtime_premium_hours} onChange={this.onTextChange} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="dgdg-no-border-top text-right">Comments:</td>
                                                <td colSpan="12" className="dgdg-no-border-top">
                                                    <DGDGFormInputComponent id="pay_2_comments" controlCssName="dgdg-technician-payroll-comments" value={this.state.pay_2_comments} onChange={this.onTextChange} />
                                                </td>
                                            </tr>
                                        </Fragment>
                                }
                            </tbody>
                        </table>
                    </div>
                    : null
            }
        </DGDGModalDialogComponent>;
    }
}
