import React from "react";
import { Link } from "react-router-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom"

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";

import { FIPayrollComponent } from "./FIPayrollComponent";
import { FIPayrollPlanComponent } from "./FIPayrollPlanComponent";
import { FIPayrollEmployeeComponent } from "./FIPayrollEmployeeComponent";
import { FIPayrollPlan2Component } from "./FIPayrollPlan2Component";
import { PageNotFoundRouteComponent } from "../pagenotfound/PageNotFoundRouteComponent";

export class FIPayrollRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stores: null,
            lastUpdatedDate: ""
        };

        this.onLastUpdatedDateChanged = this.onLastUpdatedDateChanged.bind(this);
    }

    componentDidMount() {
        try {
            this.getStores();
            CommonService.clientAzureStorageLog("FIPayrollRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "F&I Payroll";

    getStores() {
        this.props.applicationInsights.trackTrace({ message: "/FIPayrollRouteComponent/getStores", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/FIPayroll/GetStores")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({
                    stores: parsedResponse
                });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FIPayroll/GetStores", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    onLastUpdatedDateChanged(lastUpdatedDate) {
        try {
            this.setState({ lastUpdatedDate: lastUpdatedDate });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }
    render() {
        return <DGDGPageComponent bodyCssName="dgdg-flex dgdg-flex-column">
            <BrowserRouter>
                <nav className="nav nav-pills dgdg-tab">
                    <Link to="/FIPayroll/Payroll" className={"nav-item nav-link" + (window.location.pathname.indexOf("/Payroll") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Payroll</Link>
                    <Link to="/FIPayroll/Plan" className={"nav-item nav-link" + ((window.location.pathname.indexOf("/Plan2") === -1 && window.location.pathname.indexOf("/Plan") !== -1) ? " btn btn-dark active" : " btn btn-link")}>Plan</Link>
                    <Link to="/FIPayroll/Plan2" className={"nav-item nav-link" + (window.location.pathname.indexOf("/Plan2") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Plan 2</Link>
                    <Link to="/FIPayroll/Employee" className={"nav-item nav-link mr-auto" + (window.location.pathname.indexOf("/Employee") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Employee</Link>
                    {
                        this.state.lastUpdatedDate
                            ? <div className="mt-auto dgdg-flex-item dgdg-badge-margin">
                                <span className="float-right badge badge-primary dgdg-badge">Last Updated Date: {CommonService.formatDateTimeZone(this.state.lastUpdatedDate)}</span>
                            </div>
                            : null
                    }
                </nav>
                <div className="dgdg-flex-item dgdg-site-content">
                    <Switch>
                        <Route exact path="/FIPayroll/Payroll" render={(routeProps) => <FIPayrollComponent {...this.props} stores={this.state.stores} onLastUpdatedDateChanged={this.onLastUpdatedDateChanged} />} />
                        <Route exact path="/FIPayroll/Plan" render={(routeProps) => <FIPayrollPlanComponent {...this.props} />} />
                        <Route exact path="/FIPayroll/Plan2" render={(routeProps) => <FIPayrollPlan2Component {...this.props} />} />
                        <Route exact path="/FIPayroll/Employee" render={(routeProps) => <FIPayrollEmployeeComponent {...this.props} />} />
                        <Route render={(routeProps) => <PageNotFoundRouteComponent {...this.props} {...routeProps} />} />
                    </Switch>
                </div>
            </BrowserRouter>
        </DGDGPageComponent>;
    }
}