import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableHeaderComponent } from "../common/dgdgtable/DGDGTableHeaderComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";

import { ServiceScheduleSummaryQueryComponent } from "./ServiceScheduleSummaryQueryComponent";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export class ServiceScheduleSummaryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            paintTable: false,
            schedule120TableData: [],
            s120Days0Count: null,
            s120Days30Count: null,
            s120Days60Count: null,
            s120Days90Count: null,
            s120Days120Count: null,
            s120TotalCount: null,
            s120TotalPostingAmount: null,
            s120Total1Text: null,
            s120Total2Text: null,
            s120Total3Text: null,
            s120Total4Text: null,
            s120Total5Text: null,

            schedule121TableData: [],
            s121Days0Count: null,
            s121Days30Count: null,
            s121Days60Count: null,
            s121Days90Count: null,
            s121Days120Count: null,
            s121TotalCount: null,
            s121TotalPostingAmount: null,
            s121Total1Text: null,
            s121Total2Text: null,
            s121Total3Text: null,
            s121Total4Text: null,
            s121Total5Text: null,

            schedule150TableData: [],
            s150Days0Count: null,
            s150Days30Count: null,
            s150Days60Count: null,
            s150Days90Count: null,
            s150Days120Count: null,
            s150TotalPostingAmount: null,
            s150TotalCount: null,
            s150Total1Text: null,
            s150Total2Text: null,
            s150Total3Text: null,
            s150Total4Text: null,
            s150Total5Text: null
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onCountColumnCallbackFactoryWarranty = this.onCountColumnCallbackFactoryWarranty.bind(this);
        this.onCountColumnCallbackExtendedWarranty = this.onCountColumnCallbackExtendedWarranty.bind(this);
        this.onCountColumnCallbackSunbit = this.onCountColumnCallbackSunbit.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    pageTitle = "Service Schedule"

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/ServiceSchedule/Summary", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("ServiceScheduleSummaryComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick() {
        try {
            this.setState({
                isLoadingData: true
            });

            this.props.applicationInsights.trackTrace({ message: "/ServiceScheduleSummaryComponent/onGetReportClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceSchedule/GetSummaryReport", {
                method: "GET",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                }
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let schedule120TableData = parsedResponse.filter(tableRow => tableRow.store_number !== null && tableRow.schedule_number === 120);
                    let schedule120FooterRow = parsedResponse.filter(tableRow => tableRow.store_number === null && tableRow.schedule_number === 120)[0];
                    let s120Days120Count = schedule120FooterRow.days_120_count ? schedule120FooterRow.days_120_count : "";
                    let s120Days90Count = schedule120FooterRow.days_90_count ? schedule120FooterRow.days_90_count : "";
                    let s120Days60Count = schedule120FooterRow.days_60_count ? schedule120FooterRow.days_60_count : "";
                    let s120Days30Count = schedule120FooterRow.days_30_count ? schedule120FooterRow.days_30_count : "";
                    let s120Days0Count = schedule120FooterRow.days_0_count ? schedule120FooterRow.days_0_count : "";
                    let s120TotalCount = schedule120FooterRow.total_count ? schedule120FooterRow.total_count : "";
                    let s120TotalPostingAmount = schedule120FooterRow.total_posting_amount ? CommonService.formatCurrency(schedule120FooterRow.total_posting_amount) : "";
                    let s120Total5Percentage = schedule120FooterRow.days_120_posting_amount && schedule120FooterRow.total_posting_amount ? (schedule120FooterRow.days_120_posting_amount / schedule120FooterRow.total_posting_amount) : "";
                    let s120Total4Percentage = schedule120FooterRow.days_90_posting_amount && schedule120FooterRow.total_posting_amount ? (schedule120FooterRow.days_90_posting_amount / schedule120FooterRow.total_posting_amount) : "";
                    let s120Total3Percentage = schedule120FooterRow.days_60_posting_amount && schedule120FooterRow.total_posting_amount ? (schedule120FooterRow.days_60_posting_amount / schedule120FooterRow.total_posting_amount) : "";
                    let s120Total2Percentage = schedule120FooterRow.days_30_posting_amount && schedule120FooterRow.total_posting_amount ? (schedule120FooterRow.days_30_posting_amount / schedule120FooterRow.total_posting_amount) : "";
                    let s120Total1Percentage = schedule120FooterRow.days_0_posting_amount && schedule120FooterRow.total_posting_amount ? (schedule120FooterRow.days_0_posting_amount / schedule120FooterRow.total_posting_amount) : "";

                    let schedule121TableData = parsedResponse.filter(tableRow => tableRow.store_number !== null && tableRow.schedule_number === 121);
                    let schedule121FooterRow = parsedResponse.filter(tableRow => tableRow.store_number === null && tableRow.schedule_number === 121)[0];
                    let s121Days120Count = schedule121FooterRow.days_120_count ? schedule121FooterRow.days_120_count : "";
                    let s121Days90Count = schedule121FooterRow.days_90_count ? schedule121FooterRow.days_90_count : "";
                    let s121Days60Count = schedule121FooterRow.days_60_count ? schedule121FooterRow.days_60_count : "";
                    let s121Days30Count = schedule121FooterRow.days_30_count ? schedule121FooterRow.days_30_count : "";
                    let s121Days0Count = schedule121FooterRow.days_0_count ? schedule121FooterRow.days_0_count : "";
                    let s121TotalCount = schedule121FooterRow.total_count ? schedule121FooterRow.total_count : "";
                    let s121TotalPostingAmount = schedule121FooterRow.total_posting_amount ? CommonService.formatCurrency(schedule121FooterRow.total_posting_amount) : "";
                    let s121Total5Percentage = schedule121FooterRow.days_120_posting_amount && schedule121FooterRow.total_posting_amount ? (schedule121FooterRow.days_120_posting_amount / schedule121FooterRow.total_posting_amount) : "";
                    let s121Total4Percentage = schedule121FooterRow.days_90_posting_amount && schedule121FooterRow.total_posting_amount ? (schedule121FooterRow.days_90_posting_amount / schedule121FooterRow.total_posting_amount) : "";
                    let s121Total3Percentage = schedule121FooterRow.days_60_posting_amount && schedule121FooterRow.total_posting_amount ? (schedule121FooterRow.days_60_posting_amount / schedule121FooterRow.total_posting_amount) : "";
                    let s121Total2Percentage = schedule121FooterRow.days_30_posting_amount && schedule121FooterRow.total_posting_amount ? (schedule121FooterRow.days_30_posting_amount / schedule121FooterRow.total_posting_amount) : "";
                    let s121Total1Percentage = schedule121FooterRow.days_0_posting_amount && schedule121FooterRow.total_posting_amount ? (schedule121FooterRow.days_0_posting_amount / schedule121FooterRow.total_posting_amount) : "";

                    let schedule150TableData = parsedResponse.filter(tableRow => tableRow.store_number !== null && tableRow.schedule_number === 150);
                    let schedule150FooterRow = parsedResponse.filter(tableRow => tableRow.store_number === null && tableRow.schedule_number === 150)[0];
                    let s150Days120Count = schedule150FooterRow.days_120_count ? schedule150FooterRow.days_120_count : "";
                    let s150Days90Count = schedule150FooterRow.days_90_count ? schedule150FooterRow.days_90_count : "";
                    let s150Days60Count = schedule150FooterRow.days_60_count ? schedule150FooterRow.days_60_count : "";
                    let s150Days30Count = schedule150FooterRow.days_30_count ? schedule150FooterRow.days_30_count : "";
                    let s150Days0Count = schedule150FooterRow.days_0_count ? schedule150FooterRow.days_0_count : "";
                    let s150TotalCount = schedule150FooterRow.total_count ? schedule150FooterRow.total_count : "";
                    let s150TotalPostingAmount = schedule150FooterRow.total_posting_amount ? CommonService.formatCurrency(schedule150FooterRow.total_posting_amount) : "";
                    let s150Total5Percentage = schedule150FooterRow.days_120_posting_amount && schedule150FooterRow.total_posting_amount ? (schedule150FooterRow.days_120_posting_amount / schedule150FooterRow.total_posting_amount) : "";
                    let s150Total4Percentage = schedule150FooterRow.days_90_posting_amount && schedule150FooterRow.total_posting_amount ? (schedule150FooterRow.days_90_posting_amount / schedule150FooterRow.total_posting_amount) : "";
                    let s150Total3Percentage = schedule150FooterRow.days_60_posting_amount && schedule150FooterRow.total_posting_amount ? (schedule150FooterRow.days_60_posting_amount / schedule150FooterRow.total_posting_amount) : "";
                    let s150Total2Percentage = schedule150FooterRow.days_30_posting_amount && schedule150FooterRow.total_posting_amount ? (schedule150FooterRow.days_30_posting_amount / schedule150FooterRow.total_posting_amount) : "";
                    let s150Total1Percentage = schedule150FooterRow.days_0_posting_amount && schedule150FooterRow.total_posting_amount ? (schedule150FooterRow.days_0_posting_amount / schedule150FooterRow.total_posting_amount) : "";

                    this.setState({
                        isLoadingData: false,
                        schedule120TableData: schedule120TableData,
                        s120Days120Count: s120Days120Count,
                        s120Days90Count: s120Days90Count,
                        s120Days60Count: s120Days60Count,
                        s120Days30Count: s120Days30Count,
                        s120Days0Count: s120Days0Count,
                        s120TotalCount: s120TotalCount,
                        s120TotalPostingAmount: s120TotalPostingAmount,
                        s120Total5Text: (schedule120FooterRow.days_120_posting_amount ? CommonService.formatCurrency(schedule120FooterRow.days_120_posting_amount) : "") + (s120Total5Percentage ? " (" + CommonService.formatPercentage(s120Total5Percentage) + ")" : ""),
                        s120Total4Text: (schedule120FooterRow.days_90_posting_amount ? CommonService.formatCurrency(schedule120FooterRow.days_90_posting_amount) : "") + (s120Total4Percentage ? " (" + CommonService.formatPercentage(s120Total4Percentage) + ")" : ""),
                        s120Total3Text: (schedule120FooterRow.days_60_posting_amount ? CommonService.formatCurrency(schedule120FooterRow.days_60_posting_amount) : "") + (s120Total3Percentage ? " (" + CommonService.formatPercentage(s120Total3Percentage) + ")" : ""),
                        s120Total2Text: (schedule120FooterRow.days_30_posting_amount ? CommonService.formatCurrency(schedule120FooterRow.days_30_posting_amount) : "") + (s120Total2Percentage ? " (" + CommonService.formatPercentage(s120Total2Percentage) + ")" : ""),
                        s120Total1Text: (schedule120FooterRow.days_0_posting_amount ? CommonService.formatCurrency(schedule120FooterRow.days_0_posting_amount) : "") + (s120Total1Percentage ? " (" + CommonService.formatPercentage(s120Total1Percentage) + ")" : ""),

                        schedule121TableData: schedule121TableData,
                        s121Days120Count: s121Days120Count,
                        s121Days90Count: s121Days90Count,
                        s121Days60Count: s121Days60Count,
                        s121Days30Count: s121Days30Count,
                        s121Days0Count: s121Days0Count,
                        s121TotalCount: s121TotalCount,
                        s121TotalPostingAmount: s121TotalPostingAmount,
                        s121Total5Text: (schedule121FooterRow.days_120_posting_amount ? CommonService.formatCurrency(schedule121FooterRow.days_120_posting_amount) : "") + (s121Total5Percentage ? " (" + CommonService.formatPercentage(s121Total5Percentage) + ")" : ""),
                        s121Total4Text: (schedule121FooterRow.days_90_posting_amount ? CommonService.formatCurrency(schedule121FooterRow.days_90_posting_amount) : "") + (s121Total4Percentage ? " (" + CommonService.formatPercentage(s121Total4Percentage) + ")" : ""),
                        s121Total3Text: (schedule121FooterRow.days_60_posting_amount ? CommonService.formatCurrency(schedule121FooterRow.days_60_posting_amount) : "") + (s121Total3Percentage ? " (" + CommonService.formatPercentage(s121Total3Percentage) + ")" : ""),
                        s121Total2Text: (schedule121FooterRow.days_30_posting_amount ? CommonService.formatCurrency(schedule121FooterRow.days_30_posting_amount) : "") + (s121Total2Percentage ? " (" + CommonService.formatPercentage(s121Total2Percentage) + ")" : ""),
                        s121Total1Text: (schedule121FooterRow.days_0_posting_amount ? CommonService.formatCurrency(schedule121FooterRow.days_0_posting_amount) : "") + (s121Total1Percentage ? " (" + CommonService.formatPercentage(s121Total1Percentage) + ")" : ""),

                        schedule150TableData: schedule150TableData,
                        s150Days120Count: s150Days120Count,
                        s150Days90Count: s150Days90Count,
                        s150Days60Count: s150Days60Count,
                        s150Days30Count: s150Days30Count,
                        s150Days0Count: s150Days0Count,
                        s150TotalCount: s150TotalCount,
                        s150TotalPostingAmount: s150TotalPostingAmount,
                        s150Total5Text: (schedule150FooterRow.days_120_posting_amount ? CommonService.formatCurrency(schedule150FooterRow.days_120_posting_amount) : "") + (s150Total5Percentage ? " (" + CommonService.formatPercentage(s150Total5Percentage) + ")" : ""),
                        s150Total4Text: (schedule150FooterRow.days_90_posting_amount ? CommonService.formatCurrency(schedule150FooterRow.days_90_posting_amount) : "") + (s150Total4Percentage ? " (" + CommonService.formatPercentage(s150Total4Percentage) + ")" : ""),
                        s150Total3Text: (schedule150FooterRow.days_60_posting_amount ? CommonService.formatCurrency(schedule150FooterRow.days_60_posting_amount) : "") + (s150Total3Percentage ? " (" + CommonService.formatPercentage(s150Total3Percentage) + ")" : ""),
                        s150Total2Text: (schedule150FooterRow.days_30_posting_amount ? CommonService.formatCurrency(schedule150FooterRow.days_30_posting_amount) : "") + (s150Total2Percentage ? " (" + CommonService.formatPercentage(s150Total2Percentage) + ")" : ""),
                        s150Total1Text: (schedule150FooterRow.days_0_posting_amount ? CommonService.formatCurrency(schedule150FooterRow.days_0_posting_amount) : "") + (s150Total1Percentage ? " (" + CommonService.formatPercentage(s150Total1Percentage) + ")" : ""),
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        schedule120TableData: [],
                        schedule121TableData: [],
                        schedule150TableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ServiceSchedule/GetSummaryReport", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("ServiceScheduleSummaryComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCountColumnCallbackSunbit(rowData, columnName) {
        return <Link to={"/ServiceSchedule/Sunbit/" + rowData.store_number}> {rowData[columnName]}</Link>;
    }

    onCountColumnCallbackExtendedWarranty(rowData, columnName) {
        return <Link to={"/ServiceSchedule/ExtendedWarranty/" + rowData.store_number}> {rowData[columnName]}</Link>;
    }

    onCountColumnCallbackFactoryWarranty(rowData, columnName) {
        return <Link to={"/ServiceSchedule/FactoryWarranty/" + rowData.store_number}> {rowData[columnName]}</Link>;
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <ServiceScheduleSummaryQueryComponent applicationInsights={this.props.applicationInsights} isLoadingData={this.state.isLoadingData} onGetReportClick={this.onGetReportClick} />
            <div className="dgdg-site-scrollable-content">
                <div className="dgdg-service-schedule-summary">
                    <DGDGTableV3Component cardCssName="dgdg-service-schedule-summary-gap" applicationInsights={this.props.applicationInsights} headerText="Summary for Schedule 120 - Sunbit" tableData={this.state.schedule120TableData} paintTable={this.state.paintTable} showFooter
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableHeaderComponent position="PreHeader">
                            <tr className="dgdg-table-v3-header">
                                <td />
                                <td colSpan="2" className="text-center font-weight-bold">Current</td>
                                <td colSpan="2" className="text-center font-weight-bold dgdg-cit-summary-gt-5">Over 30 Days</td>
                                <td colSpan="2" className="text-center font-weight-bold dgdg-cit-summary-gt-20">Over 60 Days</td>
                                <td colSpan="2" className="text-center font-weight-bold dgdg-cit-summary-gt-10">Over 90 Days</td>
                                <td colSpan="2" className="text-center font-weight-bold dgdg-cit-summary-gt-30">Over 120 Days</td>
                                <td colSpan="2" className="text-center font-weight-bold dgdg-cit-summary-total">Total</td>
                            </tr>
                        </DGDGTableHeaderComponent>
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="Store" dataColumn="store_web_name" sortColumn="store_number" filterColumn="store_web_name" footerText="Total" />
                            <DGDGTableColumnComponent headerText="Count" dataColumn="days_0_count" sortColumn="days_0_count" dataColumnCallback={this.onCountColumnCallbackSunbit} filterColumn="days_0_count" dataType="number" cssName="text-center" footerCssName="text-center" footerText={this.state.s120Days0Count} />
                            <DGDGTableColumnComponent headerText="Total" dataColumn="days_0_posting_amount" sortColumn="days_0_posting_amount" filterColumn="days_0_posting_amount" dataType="money_2_simple" cssName="text-center" footerCssName="text-center" footerText={this.state.s120Total1Text} />
                            <DGDGTableColumnComponent headerText="Count" dataColumn="days_30_count" sortColumn="days_30_count" dataColumnCallback={this.onCountColumnCallbackSunbit} filterColumn="days_30_count" dataType="number" cssName="text-center" footerCssName="text-center" footerText={this.state.s120Days30Count} />
                            <DGDGTableColumnComponent headerText="Total" dataColumn="days_30_posting_amount" sortColumn="days_30_posting_amount" filterColumn="days_30_posting_amount" dataType="money_2_simple" cssName="text-center" footerCssName="text-center" footerText={this.state.s120Total2Text} />
                            <DGDGTableColumnComponent headerText="Count" dataColumn="days_60_count" sortColumn="days_60_count" dataColumnCallback={this.onCountColumnCallbackSunbit} filterColumn="days_60_count" dataType="number" cssName="text-center" footerCssName="text-center" footerText={this.state.s120Days60Count} />
                            <DGDGTableColumnComponent headerText="Total" dataColumn="days_60_posting_amount" sortColumn="days_60_posting_amount" filterColumn="days_60_posting_amount" dataType="money_2_simple" cssName="text-center" footerCssName="text-center" footerText={this.state.s120Total3Text} />
                            <DGDGTableColumnComponent headerText="Count" dataColumn="days_90_count" sortColumn="days_90_count" dataColumnCallback={this.onCountColumnCallbackSunbit} filterColumn="days_90_count" dataType="number" cssName="text-center" footerCssName="text-center" footerText={this.state.s120Days90Count} />
                            <DGDGTableColumnComponent headerText="Total" dataColumn="days_90_posting_amount" sortColumn="days_90_posting_amount" filterColumn="days_90_posting_amount" dataType="money_2_simple" cssName="text-center" footerCssName="text-center" footerText={this.state.s120Total4Text} />
                            <DGDGTableColumnComponent headerText="Count" dataColumn="days_120_count" sortColumn="days_120_count" dataColumnCallback={this.onCountColumnCallbackSunbit} filterColumn="days_120_count" dataType="number" cssName="text-center" footerCssName="text-center" footerText={this.state.s120Days120Count} />
                            <DGDGTableColumnComponent headerText="Total" dataColumn="days_120_posting_amount" sortColumn="days_120_posting_amount" filterColumn="days_120_posting_amount" dataType="money_2_simple" cssName="text-center" footerCssName="text-center" footerText={this.state.s120Total5Text} />
                            <DGDGTableColumnComponent headerText="Count" dataColumn="total_count" sortColumn="total_count" dataColumnCallback={this.onCountColumnCallbackSunbit} filterColumn="total_count" dataType="number" cssName="text-center" footerCssName="text-center" footerText={this.state.s120TotalCount} />
                            <DGDGTableColumnComponent headerText="Total" dataColumn="total_posting_amount" sortColumn="total_posting_amount" filterColumn="total_posting_amount" dataType="money_2_simple" cssName="text-center" footerCssName="text-center" footerText={this.state.s120TotalPostingAmount} />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                    <DGDGTableV3Component cardCssName="dgdg-service-schedule-summary-gap" applicationInsights={this.props.applicationInsights} headerText="Summary for Schedule 121 - Extended Warranty" tableData={this.state.schedule121TableData} paintTable={this.state.paintTable} showFooter
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableHeaderComponent position="PreHeader">
                            <tr className="dgdg-table-v3-header">
                                <td />
                                <td colSpan="2" className="text-center font-weight-bold">Current</td>
                                <td colSpan="2" className="text-center font-weight-bold dgdg-cit-summary-gt-5">Over 30 Days</td>
                                <td colSpan="2" className="text-center font-weight-bold dgdg-cit-summary-gt-20">Over 60 Days</td>
                                <td colSpan="2" className="text-center font-weight-bold dgdg-cit-summary-gt-10">Over 90 Days</td>
                                <td colSpan="2" className="text-center font-weight-bold dgdg-cit-summary-gt-30">Over 120 Days</td>
                                <td colSpan="2" className="text-center font-weight-bold dgdg-cit-summary-total">Total</td>
                            </tr>
                        </DGDGTableHeaderComponent>
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="Store" dataColumn="store_web_name" sortColumn="store_number" filterColumn="store_web_name" footerText="Total" />
                            <DGDGTableColumnComponent headerText="Count" dataColumn="days_0_count" sortColumn="days_0_count" dataColumnCallback={this.onCountColumnCallbackExtendedWarranty} filterColumn="days_0_count" dataType="number" cssName="text-center" footerCssName="text-center" footerText={this.state.s121Days0Count} />
                            <DGDGTableColumnComponent headerText="Total" dataColumn="days_0_posting_amount" sortColumn="days_0_posting_amount" filterColumn="days_0_posting_amount" dataType="money_2_simple" cssName="text-center" footerCssName="text-center" footerText={this.state.s121Total1Text} />
                            <DGDGTableColumnComponent headerText="Count" dataColumn="days_30_count" sortColumn="days_30_count" dataColumnCallback={this.onCountColumnCallbackExtendedWarranty} filterColumn="days_30_count" dataType="number" cssName="text-center" footerCssName="text-center" footerText={this.state.s121Days30Count} />
                            <DGDGTableColumnComponent headerText="Total" dataColumn="days_30_posting_amount" sortColumn="days_30_posting_amount" filterColumn="days_30_posting_amount" dataType="money_2_simple" cssName="text-center" footerCssName="text-center" footerText={this.state.s121Total2Text} />
                            <DGDGTableColumnComponent headerText="Count" dataColumn="days_60_count" sortColumn="days_60_count" dataColumnCallback={this.onCountColumnCallbackExtendedWarranty} filterColumn="days_60_count" dataType="number" cssName="text-center" footerCssName="text-center" footerText={this.state.s121Days60Count} />
                            <DGDGTableColumnComponent headerText="Total" dataColumn="days_60_posting_amount" sortColumn="days_60_posting_amount" filterColumn="days_60_posting_amount" dataType="money_2_simple" cssName="text-center" footerCssName="text-center" footerText={this.state.s121Total3Text} />
                            <DGDGTableColumnComponent headerText="Count" dataColumn="days_90_count" sortColumn="days_90_count" dataColumnCallback={this.onCountColumnCallbackExtendedWarranty} filterColumn="days_90_count" dataType="number" cssName="text-center" footerCssName="text-center" footerText={this.state.s121Days90Count} />
                            <DGDGTableColumnComponent headerText="Total" dataColumn="days_90_posting_amount" sortColumn="days_90_posting_amount" filterColumn="days_90_posting_amount" dataType="money_2_simple" cssName="text-center" footerCssName="text-center" footerText={this.state.s121Total4Text} />
                            <DGDGTableColumnComponent headerText="Count" dataColumn="days_120_count" sortColumn="days_120_count" dataColumnCallback={this.onCountColumnCallbackExtendedWarranty} filterColumn="days_120_count" dataType="number" cssName="text-center" footerCssName="text-center" footerText={this.state.s121Days120Count} />
                            <DGDGTableColumnComponent headerText="Total" dataColumn="days_120_posting_amount" sortColumn="days_120_posting_amount" filterColumn="days_120_posting_amount" dataType="money_2_simple" cssName="text-center" footerCssName="text-center" footerText={this.state.s121Total5Text} />
                            <DGDGTableColumnComponent headerText="Count" dataColumn="total_count" sortColumn="total_count" dataColumnCallback={this.onCountColumnCallbackExtendedWarranty} filterColumn="total_count" dataType="number" cssName="text-center" footerCssName="text-center" footerText={this.state.s121TotalCount} />
                            <DGDGTableColumnComponent headerText="Total" dataColumn="total_posting_amount" sortColumn="total_posting_amount" filterColumn="total_posting_amount" dataType="money_2_simple" cssName="text-center" footerCssName="text-center" footerText={this.state.s121TotalPostingAmount} />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                    <DGDGTableV3Component cardCssName="dgdg-service-schedule-summary-gap" applicationInsights={this.props.applicationInsights} headerText="Summary for Schedule 150 - Factory Warranty" tableData={this.state.schedule150TableData} paintTable={this.state.paintTable} showFooter
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableHeaderComponent position="PreHeader">
                            <tr className="dgdg-table-v3-header">
                                <td />
                                <td colSpan="2" className="text-center font-weight-bold">Current</td>
                                <td colSpan="2" className="text-center font-weight-bold dgdg-cit-summary-gt-5">Over 30 Days</td>
                                <td colSpan="2" className="text-center font-weight-bold dgdg-cit-summary-gt-20">Over 60 Days</td>
                                <td colSpan="2" className="text-center font-weight-bold dgdg-cit-summary-gt-10">Over 90 Days</td>
                                <td colSpan="2" className="text-center font-weight-bold dgdg-cit-summary-gt-30">Over 120 Days</td>
                                <td colSpan="2" className="text-center font-weight-bold dgdg-cit-summary-total">Total</td>
                            </tr>
                        </DGDGTableHeaderComponent>
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="Store" dataColumn="store_web_name" sortColumn="store_number" filterColumn="store_web_name" footerText="Total" />
                            <DGDGTableColumnComponent headerText="Count" dataColumn="days_0_count" sortColumn="days_0_count" dataColumnCallback={this.onCountColumnCallbackFactoryWarranty} filterColumn="days_0_count" dataType="number" cssName="text-center" footerCssName="text-center" footerText={this.state.s150Days0Count} />
                            <DGDGTableColumnComponent headerText="Total" dataColumn="days_0_posting_amount" sortColumn="days_0_posting_amount" filterColumn="days_0_posting_amount" dataType="money_2_simple" cssName="text-center" footerCssName="text-center" footerText={this.state.s150Total1Text} />
                            <DGDGTableColumnComponent headerText="Count" dataColumn="days_30_count" sortColumn="days_30_count" dataColumnCallback={this.onCountColumnCallbackFactoryWarranty} filterColumn="days_30_count" dataType="number" cssName="text-center" footerCssName="text-center" footerText={this.state.s150Days30Count} />
                            <DGDGTableColumnComponent headerText="Total" dataColumn="days_30_posting_amount" sortColumn="days_30_posting_amount" filterColumn="days_30_posting_amount" dataType="money_2_simple" cssName="text-center" footerCssName="text-center" footerText={this.state.s150Total2Text} />
                            <DGDGTableColumnComponent headerText="Count" dataColumn="days_60_count" sortColumn="days_60_count" dataColumnCallback={this.onCountColumnCallbackFactoryWarranty} filterColumn="days_60_count" dataType="number" cssName="text-center" footerCssName="text-center" footerText={this.state.s150Days60Count} />
                            <DGDGTableColumnComponent headerText="Total" dataColumn="days_60_posting_amount" sortColumn="days_60_posting_amount" filterColumn="days_60_posting_amount" dataType="money_2_simple" cssName="text-center" footerCssName="text-center" footerText={this.state.s150Total3Text} />
                            <DGDGTableColumnComponent headerText="Count" dataColumn="days_90_count" sortColumn="days_90_count" dataColumnCallback={this.onCountColumnCallbackFactoryWarranty} filterColumn="days_90_count" dataType="number" cssName="text-center" footerCssName="text-center" footerText={this.state.s150Days90Count} />
                            <DGDGTableColumnComponent headerText="Total" dataColumn="days_90_posting_amount" sortColumn="days_90_posting_amount" filterColumn="days_90_posting_amount" dataType="money_2_simple" cssName="text-center" footerCssName="text-center" footerText={this.state.s150Total4Text} />
                            <DGDGTableColumnComponent headerText="Count" dataColumn="days_120_count" sortColumn="days_120_count" dataColumnCallback={this.onCountColumnCallbackFactoryWarranty} filterColumn="days_120_count" dataType="number" cssName="text-center" footerCssName="text-center" footerText={this.state.s150Days120Count} />
                            <DGDGTableColumnComponent headerText="Total" dataColumn="days_120_posting_amount" sortColumn="days_120_posting_amount" filterColumn="days_120_posting_amount" dataType="money_2_simple" cssName="text-center" footerCssName="text-center" footerText={this.state.s150Total5Text} />
                            <DGDGTableColumnComponent headerText="Count" dataColumn="total_count" sortColumn="total_count" dataColumnCallback={this.onCountColumnCallbackFactoryWarranty} filterColumn="total_count" dataType="number" cssName="text-center" footerCssName="text-center" footerText={this.state.s150TotalCount} />
                            <DGDGTableColumnComponent headerText="Total" dataColumn="total_posting_amount" sortColumn="total_posting_amount" filterColumn="total_posting_amount" dataType="money_2_simple" cssName="text-center" footerCssName="text-center" footerText={this.state.s150TotalPostingAmount} />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
            </div>
        </div>;
    }
}
