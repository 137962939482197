import React from "react";
import { Link } from "react-router-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom"

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { FrontEndAdjustmentComponent } from "./FrontEndAdjustmentComponent";
import { FrontEndGLReconciliationComponent } from "./FrontEndGLReconciliationComponent";
import { FrontEndAdjustmentReportComponent } from "./FrontEndAdjustmentReportComponent";
import { PageNotFoundRouteComponent } from "../pagenotfound/PageNotFoundRouteComponent";

export class FrontEndAdjustmentsRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lastUpdatedDate: "",
            stores: null,
            months: CommonService.getMonths(),
        };

        this.onLastUpdatedDateChanged = this.onLastUpdatedDateChanged.bind(this);
    }

    componentDidMount() {
        try {
            this.getStores();
            CommonService.clientAzureStorageLog("FrontEndAdjustmentsRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Front-end Adjustments";

    getStores() {
        this.props.applicationInsights.trackTrace({ message: "/FrontEndAdjustmentsRouteComponent/getStores", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("FrontEndAdjustments/GetStores")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({ stores: parsedResponse });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FrontEndAdjustments/GetStores", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    onLastUpdatedDateChanged(lastUpdatedDate) {
        try {
            this.setState({ lastUpdatedDate: lastUpdatedDate });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-responsive-page dgdg-flex dgdg-flex-column">
            <BrowserRouter>
                <nav className="nav nav-pills dgdg-tab">
                    <Link to="/FrontEndAdjustments/FrontEndAdjustments" className={"nav-item nav-link" + (window.location.pathname.indexOf("/FrontEndAdjustments/FrontEndAdjustments") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Front-end Adjustments</Link>
                    <Link to="/FrontEndAdjustments/GLReconciliation" className={"nav-item nav-link" + (window.location.pathname.indexOf("/GLReconciliation") !== -1 ? " btn btn-dark active" : " btn btn-link")}>GL Reconciliation</Link>
                    <Link to="/FrontEndAdjustments/AdjustmentReport" className={"nav-item nav-link mr-auto" + (window.location.pathname.indexOf("/AdjustmentReport") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Adjustment Report</Link>
                    {
                        this.state.lastUpdatedDate
                            ? <div className="mt-auto dgdg-flex-item dgdg-badge-margin">
                                <span className="float-right badge badge-primary dgdg-badge">Last Updated Date: {CommonService.formatDateTimeZone(this.state.lastUpdatedDate)}</span>
                            </div>
                            : null
                    }
                </nav>
                <div className="dgdg-flex-item dgdg-site-content dgdg-front-end-adjustment-content">
                    <Switch>
                        <Route exact path="/FrontEndAdjustments/FrontEndAdjustments" render={(routeProps) => <FrontEndAdjustmentComponent {...this.props}{...routeProps} stores={this.state.stores} months={this.state.months} lastUpdatedBy={this.props.remoteUpn ? this.props.remoteUpn : ""} onLastUpdatedDateChanged={this.onLastUpdatedDateChanged} />} />
                        <Route exact path="/FrontEndAdjustments/GLReconciliation" render={(routeProps) => <FrontEndGLReconciliationComponent {...this.props} {...routeProps} stores={this.state.stores} months={this.state.months} onLastUpdatedDateChanged={this.onLastUpdatedDateChanged} />} />
                        <Route exact path="/FrontEndAdjustments/AdjustmentReport" render={(routeProps) => <FrontEndAdjustmentReportComponent {...this.props} stores={this.state.stores} months={this.state.months} onLastUpdatedDateChanged={this.onLastUpdatedDateChanged} />} />
                        <Route render={(routeProps) => <PageNotFoundRouteComponent {...this.props} {...routeProps} />} />
                    </Switch>
                </div>
            </BrowserRouter>
        </DGDGPageComponent>;
    }
}
