import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

export class SalesPayrollDetailComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payrollDetail: null,
            payrollDetailEditComponentTop: null,
        };
    }

    pageTitle = "Sales Payroll";

    render() {
        return <div className="dgdg-payroll" style={{ "marginLeft": "60px" }}>
            <table width="100%">
                <tbody>
                    <tr>
                        <td className="dgdg-no-border" style={{ "width": "170px" }}>Pay Month: {CommonService.formatMonthYear(moment(this.props.payrollMonth))}</td>
                        <td className="dgdg-no-border" style={{ "width": "100px" }}>New Units: {this.props.rowData.new_unit_count}</td>
                        <td className="dgdg-no-border" style={{ "width": "100px" }}>Used Units: {this.props.rowData.used_unit_count}</td>
                        <td className="dgdg-no-border" style={{ "width": "100px" }}>Total Units: {this.props.rowData.new_unit_count + this.props.rowData.used_unit_count}</td>
                        <td className="dgdg-no-border">CSI: {CommonService.formatBoolString(this.props.rowData.csi)}</td>
                        {
                            (this.props.rowData.store_number === 3)
                                ? <td className="dgdg-no-border">Subaru Integrity Score: {CommonService.formatBoolString(this.props.rowData.subaru_integrity_score)}</td>
                                : null
                        }
                        <td className="dgdg-no-border">
                            {
                                (this.props.isEditEnabled)
                                    ? <button type="button" className="btn btn-link float-right" disabled={this.props.rowData.disable_edit} onClick={(event) => this.props.onEditClick(event, this.props.rowData)}>
                                        <span className="fas fa-edit" />
                                    </button>
                                    : null
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className="dgdg-payroll-period">
                <tbody>
                    <tr>
                        <td>Pay Period: 1</td>
                        <td>Pay Bonus: {CommonService.formatCurrency_2(this.props.rowData.pay_1_payroll_bonus)}</td>
                        <td>GWD: {this.props.rowData.pay_1_guaranteed_work_days}</td>
                        <td>Sick Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_1_sick_labor_rate)}</td>
                        <td>PTO Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_1_pto_labor_rate)}</td>
                        <td>Berv Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_1_bereavement_labor_rate)}</td>
                        <td>Holiday Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_1_holiday_labor_rate)}</td>
                        <td>Non Commission Spiff: {CommonService.formatCurrency_2(this.props.rowData.pay_1_non_commission_flat_sum_bonus_spiff)}</td>
                        <td>Mealtime Prem Hours: {CommonService.formatNumber_1(this.props.rowData.pay_1_mealtime_premium_hours)}</td>
                        <td>Comments: {this.props.rowData.pay_1_comments}</td>
                    </tr>
                    <tr>
                        <td>Pay Period: 2</td>
                        <td>Pay Bonus: {CommonService.formatCurrency_2(this.props.rowData.pay_2_payroll_bonus)}</td>
                        <td>GWD: {this.props.rowData.pay_2_guaranteed_work_days}</td>
                        <td>Sick Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_2_sick_labor_rate)}</td>
                        <td>PTO Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_2_pto_labor_rate)}</td>
                        <td>Berv Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_2_bereavement_labor_rate)}</td>
                        <td>Holiday Rate: {CommonService.formatCurrency_2(this.props.rowData.pay_2_holiday_labor_rate)}</td>
                        <td>Non Commission Spiff: {CommonService.formatCurrency_2(this.props.rowData.pay_2_non_commission_flat_sum_bonus_spiff)}</td>
                        <td>Mealtime Prem Hours: {CommonService.formatNumber_1(this.props.rowData.pay_2_mealtime_premium_hours)}</td>
                        <td>Comments: {this.props.rowData.pay_2_comments}</td>
                    </tr>
                </tbody>
            </table>
        </div>;
    }
}
