import React, { Fragment } from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableCustomRowComponent } from "../common/dgdgtable/DGDGTableCustomRowComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

import { OPDSalesEditComponent } from "./OPDSalesEditComponent";
import { OPDSalesTeamResultEditComponent } from "./OPDSalesTeamResultEditComponent";
import { OPDSalesQueryComponent } from "./OPDSalesQueryComponent";

export class OPDSalesRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            stores: null,
            levelBonus: null,
            selectedStore: null,
            selectedYear: null,
            tableData: [],
            teamMembers: [],
            teamMembersDetail: [],
            tableColumns: null,
            teamMembersTableData: [],
            employeeList: null,
            rowData: null,
            editMonth: null,
            volumeOptions: [],
            closingRatioOptions: [],
            showDialog: false,
            showTeamResultDialog: false,
            paintTable: false,
            columnsToBeCopied: [],
            legendText: null,
            lastUpdatedDate: ""
        }

        this.onActionColumnCallback = this.onActionColumnCallback.bind(this);
        this.onVolumeGoalColumnCallback = this.onVolumeGoalColumnCallback.bind(this);
        this.onClosingGoalColumnCallback = this.onClosingGoalColumnCallback.bind(this);
        this.onTeamMemberRowCallback = this.onTeamMemberRowCallback.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onSaveEmployeeClick = this.onSaveEmployeeClick.bind(this);
        this.onDeleteEmployeeClick = this.onDeleteEmployeeClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);

        this.onVolumeMonthColumnCallback = this.onVolumeMonthColumnCallback.bind(this);
        this.onClosingMonthColumnCallback = this.onClosingMonthColumnCallback.bind(this);
        this.onTeamResultEditClick = this.onTeamResultEditClick.bind(this);
        this.onSaveTeamResultClick = this.onSaveTeamResultClick.bind(this);
        this.onCancelTeamResultClick = this.onCancelTeamResultClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/ManageReports", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getStores();
            this.getLevelBonus();
            this.getLegendText();
            CommonService.clientAzureStorageLog("ManageReportsRouteComponent", "ManageReportsRouteComponent", "componentDidMount", null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "OPD Sales";

    getStores() {
        this.props.applicationInsights.trackTrace({ message: "/OPDSalesRouteComponent/getStores", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/OPDSales/GetStores")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({ stores: parsedResponse });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/OPDSales/GetStores", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    getLevelBonus() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/OPDSalesRouteComponent/getLevelBonus", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/OPDSales/GetTeamResultBonusLevel")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({ levelBonus: parsedResponse });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/OPDSales/getLevelBonus", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getLegendText() {
        let legendText = <Fragment>
            <div className="d-inline-block"><div className="d-inline dgdg-import-wizard-legend dgdg-opd-sales-month-level-1" />&nbsp;&nbsp;&nbsp;Level 1</div>
            <div className="d-inline-block"><div className="d-inline dgdg-import-wizard-legend dgdg-opd-sales-month-level-2" />&nbsp;&nbsp;&nbsp;Level 2</div>
            <div className="d-inline-block"><div className="d-inline dgdg-import-wizard-legend dgdg-opd-sales-month-level-3" />&nbsp;&nbsp;&nbsp;Level 3</div>
            <div className="d-inline-block"><div className="d-inline dgdg-import-wizard-legend dgdg-opd-sales-month-level-4" />&nbsp;&nbsp;&nbsp;Level 4</div>
        </Fragment>
        this.setState({ legendText: legendText })
    }

    onActionColumnCallback(rowData) {
        return <Fragment>
            <button type="button" className="btn btn-link" onClick={(event) => this.onActionEditClick(event, rowData)}>
                <i className="far fa-edit" />
            </button>
            <DGDGSpinnerComponent showSpinner={rowData.action_showSpinner} />
        </Fragment>
    }

    onActionEditClick(event, rowData) {
        try {
            this.getEmployeeList(rowData.store_number);
            this.getTeamMembers(rowData.store_number, rowData.opd_sales_team_id)
            this.setState({
                rowData: rowData
            }, () => {
                this.setState({ showDialog: true })
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTeamResultEditClick(event, rowData, columnName) {
        try {
            let volumeOptions = [];
            let closingRatioOptions = [];

            volumeOptions.push({ level: 1, goal: rowData.volume_goal_1, value: this.state.levelBonus.filter(row => row.type === "Volume" && row.level === 1)[0].bonus });
            volumeOptions.push({ level: 2, goal: rowData.volume_goal_2, value: this.state.levelBonus.filter(row => row.type === "Volume" && row.level === 2)[0].bonus });
            volumeOptions.push({ level: 3, goal: rowData.volume_goal_3, value: this.state.levelBonus.filter(row => row.type === "Volume" && row.level === 3)[0].bonus });

            closingRatioOptions.push({ level: 1, goal: rowData.closing_ratio_goal_1, value: this.state.levelBonus.filter(row => row.type === "Closing Ratio" && row.level === 3)[0].bonus });
            closingRatioOptions.push({ level: 2, goal: rowData.closing_ratio_goal_2, value: this.state.levelBonus.filter(row => row.type === "Closing Ratio" && row.level === 3)[0].bonus });
            closingRatioOptions.push({ level: 3, goal: rowData.closing_ratio_goal_3, value: this.state.levelBonus.filter(row => row.type === "Closing Ratio" && row.level === 3)[0].bonus });
            closingRatioOptions.push({ level: 4, goal: rowData.closing_ratio_goal_4, value: this.state.levelBonus.filter(row => row.type === "Closing Ratio" && row.level === 3)[0].bonus });

            this.setState({
                volumeOptions: volumeOptions,
                closingRatioOptions: closingRatioOptions,
                rowData: rowData,
                editMonth: columnName.split("-")[0]
            }, () => {
                this.setState({ showTeamResultDialog: true })
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onVolumeGoalColumnCallback(rowData) {
        return <div>
            Level 1: {rowData.volume_goal_1}
            <br />
            Level 2: {rowData.volume_goal_2}
            <br />
            Level 3: {rowData.volume_goal_3}
        </div>
    }

    onClosingGoalColumnCallback(rowData) {
        return <div>
            Level 1: {rowData.closing_ratio_goal_1 ? CommonService.formatPercentage_1(rowData.closing_ratio_goal_1) : ""}
            <br />
            Level 2: {rowData.closing_ratio_goal_2 ? CommonService.formatPercentage_1(rowData.closing_ratio_goal_2) : ""}
            <br />
            Level 3: {rowData.closing_ratio_goal_3 ? CommonService.formatPercentage_1(rowData.closing_ratio_goal_3) : ""}
            <br />
            Level 4: {rowData.closing_ratio_goal_4 ? CommonService.formatPercentage_1(rowData.closing_ratio_goal_4) : ""}
        </div>
    }

    onVolumeMonthColumnCallback(rowData, columnName) {
        rowData[columnName + "_cellCssName"] = this.setColor(rowData[columnName + "_volume_bonus_level"]);
        return <div>
            Vol:&nbsp;
            {rowData[columnName + "_volume"] ? CommonService.formatNumber_1(rowData[columnName + "_volume"]) : ""}
            <br />
            {
                rowData.opd_sales_team_id
                    ? <div className="btn btn-link align-top dgdg-opd-sales-link-column-padding" onClick={(event) => this.onTeamResultEditClick(event, rowData, columnName)}>Level:&nbsp;</div>
                    : "Level:"
            }
            {rowData[columnName + "_volume_bonus_level"] ? rowData[columnName + "_volume_bonus_level"] : ""}
            <br />
            {
                rowData[columnName + "_volume_bonus"] != null
                    ? <Fragment>
                        Bonus:&nbsp;
                        {CommonService.formatCurrency(rowData[columnName + "_volume_bonus"])}
                    </Fragment>
                    : ""
            }
        </div>
    }

    onClosingMonthColumnCallback(rowData, columnName) {
        rowData[columnName + "_cellCssName"] = this.setColor(rowData[columnName + "_closing_ratio_bonus_level"]);
        return <div>
            Close:&nbsp;
            {rowData[columnName + "_closing_ratio"] ? CommonService.formatPercentage_1(rowData[columnName + "_closing_ratio"]) : ""}
            <br />
            {
                rowData.opd_sales_team_id
                    ? <div className="btn btn-link align-top dgdg-opd-sales-link-column-padding" onClick={(event) => this.onTeamResultEditClick(event, rowData, columnName)}>Level:&nbsp;</div>
                    : "Level:"
            }
            {rowData[columnName + "_closing_ratio_bonus_level"] ? rowData[columnName + "_closing_ratio_bonus_level"] : ""}
            <br />
            {
                rowData[columnName + "_closing_ratio_bonus"] != null
                    ? <Fragment>
                        Bonus:&nbsp;
                        {CommonService.formatCurrency(rowData[columnName + "_closing_ratio_bonus"])}
                    </Fragment>
                    : ""
            }
        </div >
    }

    onTeamMemberRowCallback(rowComponent, rowData, rowIndex) {
        let rows = []
        this.state.teamMembers.filter(teamMember => rowData.opd_sales_team_id === teamMember.opd_sales_team_id).forEach((opdTeamMember, index) => {
            let volumeColumns = [];
            let currentMonth = moment(this.state.selectedYear);
            let endMonth = moment(currentMonth);
            endMonth.add(1, "year");
            while (currentMonth < endMonth) {
                let teamMemberDetail = this.state.teamMembersDetail.filter(teamMemberVolumeDetail => teamMemberVolumeDetail.employee_number?.toString() === opdTeamMember.employee_number?.toString() && teamMemberVolumeDetail.result_month === currentMonth.format("YYYY-MM-DDTHH:mm:ss"))[0];
                volumeColumns.push(<Fragment>
                    <td>{teamMemberDetail?.volume ? CommonService.formatNumber_1(teamMemberDetail?.volume) : ""}</td>
                    <td className="dgdg-opd-sales-month">{teamMemberDetail?.closing_ratio ? CommonService.formatPercentage_1(teamMemberDetail?.closing_ratio) : ""}</td>
                </Fragment>)
                currentMonth.add(1, "months");
            }
            rows.push(<tr key={"teamMember" + index}>
                <td colSpan="3" />
                <td colSpan="3" className=" dgdg-border-right" >{opdTeamMember.employee_name + " (" + opdTeamMember.employee_number + ")"}</td>
                {volumeColumns}
            </tr>);
        })
        return rows;
    }

    onGetReportClick(selectedStore, selectedYear) {
        try {
            this.setState({
                isLoadingData: true,
                selectedStore: selectedStore,
                selectedYear: selectedYear
            });

            let inputData = {
                storeNumber: selectedStore ? selectedStore.store_number : null,
                year: selectedYear.year()
            };

            this.onGetLastUpdatedDate();

            this.props.applicationInsights.trackTrace({ message: "/OPDSalesRouteComponent/onGetReportClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/OPDSales/GetReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let tableData = parsedResponse.table;
                    let teamResult = parsedResponse.table1;
                    let tableColumns = [];
                    let columnsToBeCopied = [0, 2, 3, 6, 7, 8, 9, 10, 11, 12];
                    let copyIndex = 14;
                    tableColumns.push(<DGDGTableColumnComponent key="header" headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />);
                    tableColumns.push(<DGDGTableColumnComponent key="action" headerText="Action" dataColumnCallback={this.onActionColumnCallback} cssName="text-center" showSpinner />);
                    tableColumns.push(<DGDGTableColumnComponent key="store" headerText="Store" dataColumn="store_web_name" sortColumn="store_number" filterColumn="store_web_name" />);
                    tableColumns.push(<DGDGTableColumnComponent key="team" headerText="Team" dataColumn="team_name" sortColumn="team_name" filterColumn="team_name" cssName="font-weight-bold" />);
                    tableColumns.push(<DGDGTableColumnComponent key="volumeGoal" headerText="Volume Goal" dataColumnCallback={this.onVolumeGoalColumnCallback} cssName="align-top dgdg-opd-sales-column-width" />);
                    tableColumns.push(<DGDGTableColumnComponent key="cloasingGoal" headerText="Closing Goal" dataType="percent" dataColumnCallback={this.onClosingGoalColumnCallback} cssName="align-top dgdg-opd-sales-column-width dgdg-border-right" />);

                    tableColumns.push(<DGDGTableColumnComponent key="volume_goal_1" headerText={"Volume Goal 1"} dataColumn="volume_goal_1" isHidden />);
                    tableColumns.push(<DGDGTableColumnComponent key="volume_goal_2" headerText={"Volume Goal 2"} dataColumn="volume_goal_2" isHidden />);
                    tableColumns.push(<DGDGTableColumnComponent key="volume_goal_3" headerText={"Volume Goal 3"} dataColumn="volume_goal_3" isHidden />);
                    tableColumns.push(<DGDGTableColumnComponent key="closing_ratio_goal_1" headerText={"Closing Ratio 1"} dataColumn="closing_ratio_goal_1_formated" isHidden />);
                    tableColumns.push(<DGDGTableColumnComponent key="closing_ratio_goal_2" headerText={"Closing Ratio 2"} dataColumn="closing_ratio_goal_2_formated" isHidden />);
                    tableColumns.push(<DGDGTableColumnComponent key="closing_ratio_goal_3" headerText={"Closing Ratio 3"} dataColumn="closing_ratio_goal_3_formated" isHidden />);
                    tableColumns.push(<DGDGTableColumnComponent key="closing_ratio_goal_4" headerText={"Closing Ratio 4"} dataColumn="closing_ratio_goal_4_formated" isHidden />);

                    tableData.forEach((responseItem, index) => {
                        responseItem.closing_ratio_goal_1_formated = responseItem.closing_ratio_goal_1 ? CommonService.formatPercentage_1(responseItem.closing_ratio_goal_1) : ""
                        responseItem.closing_ratio_goal_2_formated = responseItem.closing_ratio_goal_2 ? CommonService.formatPercentage_1(responseItem.closing_ratio_goal_2) : ""
                        responseItem.closing_ratio_goal_3_formated = responseItem.closing_ratio_goal_3 ? CommonService.formatPercentage_1(responseItem.closing_ratio_goal_3) : ""
                        responseItem.closing_ratio_goal_4_formated = responseItem.closing_ratio_goal_4 ? CommonService.formatPercentage_1(responseItem.closing_ratio_goal_4) : ""
                    });

                    let currentMonth = moment(selectedYear);
                    let endMonth = moment(currentMonth);
                    endMonth.add(1, "year");
                    while (currentMonth < endMonth) {
                        let columnName = currentMonth.format("MMM");
                        tableColumns.push(<DGDGTableColumnComponent key={"volume" + columnName.toLowerCase()} headerText={columnName} dataColumn={columnName.toLowerCase()} headerColSpan="2" dataColumnCallback={this.onVolumeMonthColumnCallback} cssName="text-nowrap align-top"
                            sortColumn={
                                [
                                    { displayName: "Volume", column: columnName + "_volume", firstSortOrder: "Desc", hideSortDisplayName: true },
                                    { displayName: "Closing Ratio", column: columnName + "_closing_ratio", firstSortOrder: "Desc", hideSortDisplayName: true }
                                ]}
                        />);
                        tableColumns.push(<DGDGTableColumnComponent key={"closing" + columnName.toLowerCase()} dataColumn={columnName.toLowerCase()} dataColumnCallback={this.onClosingMonthColumnCallback} cssName="text-nowrap align-top dgdg-opd-sales-month" />);
                        tableData.forEach((responseItem, index) => {
                            let monthRow = teamResult.filter(rowData => rowData.opd_sales_team_id === responseItem.opd_sales_team_id && rowData.result_month === currentMonth.format("YYYY-MM-DDTHH:mm:ss"))[0];
                            if (monthRow) {
                                responseItem[columnName.toLowerCase() + "_volume"] = monthRow.volume;
                                responseItem[columnName.toLowerCase() + "_volume_formated"] = monthRow.volume ? CommonService.formatNumber_1(monthRow.volume) : "";
                                responseItem[columnName.toLowerCase() + "_volume_bonus_level"] = monthRow.volume_bonus_level;
                                responseItem[columnName.toLowerCase() + "_volume_bonus"] = monthRow.volume_bonus;
                                responseItem[columnName.toLowerCase() + "_closing_ratio"] = monthRow.closing_ratio
                                responseItem[columnName.toLowerCase() + "_closing_ratio_formated"] = monthRow.closing_ratio ? CommonService.formatPercentage_1(monthRow.closing_ratio) : "";
                                responseItem[columnName.toLowerCase() + "_closing_ratio_bonus_level"] = monthRow.closing_ratio_bonus_level;
                                responseItem[columnName.toLowerCase() + "_closing_ratio_bonus"] = monthRow.closing_ratio_bonus;
                            }
                        });

                        tableColumns.push(<DGDGTableColumnComponent key={columnName.toLowerCase() + "_volume"} headerText={columnName + " Volume"} dataColumn={columnName.toLowerCase() + "_volume_formated"} isHidden />);
                        tableColumns.push(<DGDGTableColumnComponent key={columnName.toLowerCase() + "_volume_bonus_level"} headerText={columnName + " Volume Bonus Level"} dataColumn={columnName.toLowerCase() + "_volume_bonus_level"} isHidden />);
                        tableColumns.push(<DGDGTableColumnComponent key={columnName.toLowerCase() + "_volume_bonus"} headerText={columnName + " Volume Bonus"} dataColumn={columnName.toLowerCase() + "_volume_bonus"} dataType="money" isHidden />);
                        tableColumns.push(<DGDGTableColumnComponent key={columnName.toLowerCase() + "_closing_ratio"} headerText={columnName + " Clsoing Ratio"} dataColumn={columnName.toLowerCase() + "_closing_ratio_formated"} isHidden />);
                        tableColumns.push(<DGDGTableColumnComponent key={columnName.toLowerCase() + "_closing_ratio_bonus_level"} headerText={columnName + " Closing Ratio Bonus Level"} dataColumn={columnName.toLowerCase() + "_closing_ratio_bonus_level"} isHidden />);
                        tableColumns.push(<DGDGTableColumnComponent key={columnName.toLowerCase() + "_closing_ratio_bonus"} headerText={columnName + " Closing Ratio Bonus"} dataColumn={columnName.toLowerCase() + "_closing_ratio_bonus"} dataType="money" isHidden />);

                        columnsToBeCopied.push(copyIndex + 1, copyIndex + 2, copyIndex + 3, copyIndex + 4, copyIndex + 5, copyIndex + 6);
                        copyIndex = copyIndex + 8;
                        currentMonth.add(1, "months");
                    }
                    this.setState({
                        isLoadingData: false,
                        tableColumns: tableColumns,
                        tableData: CommonService.addOrdinalColumn(tableData),
                        teamMembers: parsedResponse.table2,
                        teamMembersDetail: parsedResponse.table3,
                        columnsToBeCopied: columnsToBeCopied,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/OPDSales/onGetReportClick", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event, opdSales) {
        try {
            let inputData = opdSales;
            this.props.applicationInsights.trackTrace({ message: "/OPDSales/onSaveClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/OPDSales/SaveOPDSales", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.onGetReportClick(this.state.selectedStore);
                    this.props.onShowAlert("success", { message: "OPD Sales saved successfully." }, 4000);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/OPDSales/SaveOPDSales", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveEmployeeClick(event, storeNumber, opdTeamMember) {
        try {
            let inputData = opdTeamMember;
            this.props.applicationInsights.trackTrace({ message: "/OPDSales/onSaveEmployeeClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/OPDSales/SaveTeamMember", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.getTeamMembers(storeNumber, opdTeamMember.opdSalesTeamId);
                    this.onGetReportClick(this.state.selectedStore);
                    this.props.onShowAlert("success", { message: "Team member added." }, 4000);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/OPDSales/SaveTeamMember", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDeleteEmployeeClick(event, storeNumber, opdTeamMember) {
        try {
            let inputData = opdTeamMember;
            this.props.applicationInsights.trackTrace({ message: "/OPDSales/onDeleteEmployeeClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/OPDSales/DeleteTeamMember", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.getTeamMembers(storeNumber, opdTeamMember.opdSalesTeamId);
                    this.onGetReportClick(this.state.selectedStore);
                    this.props.onShowAlert("success", { message: "Team member Deleted." }, 4000);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/OPDSales/DeleteTeamMember", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                rowData: null,
                showDialog: false
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveTeamResultClick(event, opdTeamResult) {
        try {
            let inputData = opdTeamResult;
            this.props.applicationInsights.trackTrace({ message: "/OPDSalesRouteComponent/onSaveTeamResultClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/OPDSales/SaveTeamResult", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.onGetReportClick(this.state.selectedStore);
                    this.props.onShowAlert("success", { message: "Team Result updated." }, 4000);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/OPDSales/SaveTeamResult", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelTeamResultClick(event) {
        try {
            this.setState({
                rowData: null,
                showTeamResultDialog: false
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetLastUpdatedDate() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/OPDSalesRouteComponent/onGetLastUpdatedDate", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/OPDSales/GetLastUpdatedDate")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let lastUpdatedDate = "";
                    if (CommonService.isDateValid(parsedResponse))
                        lastUpdatedDate = moment.utc(parsedResponse);
                    this.setState({ lastUpdatedDate: lastUpdatedDate });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/OPDSales/GetLastUpdatedDate", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("ManageReportsRouteComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getEmployeeList(storeNumber) {
        let inputData = {
            storeNumber: storeNumber
        };
        this.props.applicationInsights.trackTrace({ message: "OPDSalesRouteComponent/getEmployeeList", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/OPDSales/GetEmployeeList", {
            method: "POST",
            headers: {
                'Content-Type': "application/json; charset=utf-8;"
            },
            body: JSON.stringify(inputData)
        })
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                parsedResponse.forEach((responseItem, index) => {
                    responseItem.employee = responseItem.employee_name + ", " + responseItem.employee_number
                });
                this.setState({ employeeList: parsedResponse });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    getTeamMembers(storeNumber, opdSalesTeamId) {
        let inputData = {
            storeNumber: storeNumber,
            opdSalesTeamId: opdSalesTeamId
        };
        this.props.applicationInsights.trackTrace({ message: "OPDSalesRouteComponent/getTeamMembers", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/OPDSales/GetTeamMembers", {
            method: "POST",
            headers: {
                'Content-Type': "application/json; charset=utf-8;"
            },
            body: JSON.stringify(inputData)
        })
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({ teamMembersTableData: parsedResponse });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    setColor(level) {
        switch (level) {
            case 1:
                return "dgdg-opd-sales-month-level-1";
            case 2:
                return "dgdg-opd-sales-month-level-2";
            case 3:
                return "dgdg-opd-sales-month-level-3";
            case 4:
                return "dgdg-opd-sales-month-level-4";
            default:
                return "";
        }
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-responsive-page dgdg-flex dgdg-flex-column">
            <div>
                <span className="float-right badge badge-primary dgdg-badge">
                    Last Updated Date: {this.state.lastUpdatedDate ? CommonService.formatDateTimeZone(this.state.lastUpdatedDate) : ""}
                </span>
            </div>
            <div className="dgdg-report-body dgdg-flex dgdg-flex-column dgdg-flex-item dgdg-site-content dgdg-opd-sales-content">
                <OPDSalesQueryComponent {...this.props}
                    applicationInsights={this.props.applicationInsights}
                    stores={this.state.stores}
                    isLoadingData={this.state.isLoadingData}
                    onGetReportClick={this.onGetReportClick}
                />
                <OPDSalesEditComponent
                    applicationInsights={this.props.applicationInsights}
                    rowData={this.state.rowData}
                    showDialog={this.state.showDialog}
                    employeeList={this.state.employeeList}
                    teamMembersTableData={this.state.teamMembersTableData}
                    onSaveClick={this.onSaveClick}
                    onSaveEmployeeClick={this.onSaveEmployeeClick}
                    onDeleteEmployeeClick={this.onDeleteEmployeeClick}
                    onCancelClick={this.onCancelClick}
                    onShowAlert={this.props.onShowAlert}
                />
                <OPDSalesTeamResultEditComponent
                    applicationInsights={this.props.applicationInsights}
                    showDialog={this.state.showTeamResultDialog}
                    rowData={this.state.rowData}
                    volumeOptions={this.state.volumeOptions}
                    closingRatioOptions={this.state.closingRatioOptions}
                    editMonth={this.state.editMonth}
                    onSaveClick={this.onSaveTeamResultClick}
                    onCancelClick={this.onCancelTeamResultClick}
                    onShowAlert={this.props.onShowAlert}
                />
                <div className="dgdg-site-scrollable-content">
                    <div className="dgdg-card-gutter">
                        <DGDGTableV3Component cssName="dgdg-reports-table" applicationInsights={this.props.applicationInsights} headerText="OPD Sales" tableData={this.state.tableData} paintTable={this.state.paintTable}
                            copyTableConfig={{ "columns": this.state.columnsToBeCopied, onCopyTableCallBack: this.onCopyTableCallBack }} stickyHeader legendText={this.state.legendText}
                        >
                            <DGDGTableRowComponent>
                                {this.state.tableColumns}
                            </DGDGTableRowComponent>
                            <DGDGTableCustomRowComponent onGetCustomRowsCallback={this.onTeamMemberRowCallback} />
                        </DGDGTableV3Component>
                    </div>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}
