import React from "react";

import { DGDGChartColumnComponent } from "../common/dgdgchart/DGDGChartColumnComponent";

export class DemoChartDualYAxisBarComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chartOption: {
                series: {
                    0: { targetAxisIndex: 0 },
                    1: { targetAxisIndex: 1 }
                },
                hAxis: {
                    title: "Month",
                    format: "M/y",
                    gridlines: {
                        color: 'transparent'
                    },
                    textStyle: {
                        fontSize: 10
                    },
                    titleTextStyle: {
                        fontSize: 12,
                        italic: false
                    }
                },
                vAxes: {
                    0: {
                        title: "Total Volume",
                        textStyle: {
                            fontSize: 10
                        },
                        titleTextStyle: {
                            fontSize: 12,
                            italic: false
                        }
                    },
                    1: {
                        title: "Total Gross",
                        format: "$#,###",
                        textStyle: {
                            fontSize: 10
                        },
                        titleTextStyle: {
                            fontSize: 12,
                            italic: false
                        }
                    }
                },
                legend: {
                    textStyle: {
                        fontSize: 12
                    }
                }
            }
        };
    }

    getDashboardData() {
        let filteredData = null;

        if (this.props.dashboardItem) {
            filteredData = [];
            filteredData.push(this.props.dashboardItem.data[0].slice(1)); // add header row.
            this.props.dashboardItem.data.forEach((dataRow, index) => {
                if (dataRow[0] === "1") {
                    filteredData.push(dataRow.slice(1));
                }
            });
        }

        return filteredData;
    }

    render() {
        return <div className="dgdg-widget-item">
            <DGDGChartColumnComponent chartData={this.getDashboardData()} chartOption={this.state.chartOption} isMaximized={this.props.isMaximized} onColumnClicked={this.props.onColumnClicked} />
        </div>;
    }
}
