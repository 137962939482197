import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { DGDGRODetailComponent } from "../common/dgdgrodetail/DGDGRODetailComponent";

export class ServiceAdvisorReportTotalCommissionableGrossComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            if (props.totalGrossROTableData.length === 0 && props.totalExcludedROTableData.length === 0 && props.totalRentalROTableData.length === 0) {
                newState.showDialog = false;
            } else {
                newState.showDialog = true;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            showDialog: false
        };

        this.onScrollClick = this.onScrollClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
    }

    onScrollClick(event, selector) {
        try {
            CommonService.smoothScrollTo("#totalCommissionableGrossComponent", selector);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("ServiceAdvisorReportTotalCommissionableGrossComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.state.showDialog} title={this.props.tableTitle}
            cssName="dgdg-service-advisor-total-commissionable-gross-dialog"
            onCancelClick={this.props.onCancelClick}
        >
            <DGDGRODetailComponent title={this.props.roDetailTitle} data={this.props.roDetailResponse} onCancelClick={this.props.onRODetailCancelClick} onShowAlert={this.props.onShowAlert} applicationInsights={this.props.applicationInsights} />
            <div id="totalCommissionableGrossComponent" className="dgdg-report-body">
                <nav className="nav nav-pills dgdg-nav">
                    <button type="button" className="btn btn-link nav-item nav-link dgdg-quick-link dgdg-quick-link-bg" onClick={(event) => this.onScrollClick(event, "#tblTotalGross")}>Total Gross</button>
                    <button type="button" className="btn btn-link nav-item nav-link dgdg-quick-link dgdg-quick-link-bg" onClick={(event) => this.onScrollClick(event, "#tblExcludedROs")}>Excluded ROs</button>
                    <button type="button" className="btn btn-link nav-item nav-link dgdg-quick-link dgdg-quick-link-bg" onClick={(event) => this.onScrollClick(event, "#tblRentalROs")}>Rental ROs</button>
                </nav>
                <div className="dgdg-flex dgdg-flex-row">
                    <div id="tblTotalGross">
                        <DGDGTableV3Component applicationInsights={this.props.applicationInsights} cardCssName="dgdg-full-width" cardBlockCssName="overflow-auto" tableData={this.props.totalGrossROTableData} headerText={this.props.totalGrossROTableTitle} defaultSortColumn={CommonService.ordinalColumnName} defaultSortOrder="Asc" paintTable={this.props.totalGrossROPaintTable} showFooter
                            copyTableConfig={{ "columns": [0, 1, 2, 3, 4], onCopyTableCallBack: this.onCopyTableCallBack }}
                        >
                            <DGDGTableRowComponent>
                                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                <DGDGTableColumnComponent headerText="RO Number" dataColumn="ro_number" sortColumn="ro_number" onCellClick={this.props.onRODetailCellClick} filterColumn="ro_number" footerText="Total" />
                                <DGDGTableColumnComponent headerText="Total Gross" dataColumn="total_gross" sortColumn="total_gross" dataType="money" footerFunction="sum" footerCssName="text-right" />
                                <DGDGTableColumnComponent headerText="Labor Discount Amount" dataColumn="labor_discount_amount" sortColumn="labor_discount_amount" dataType="money" footerFunction="sum" footerCssName="text-right" />
                                <DGDGTableColumnComponent headerText="Total Commissionable Gross" dataColumn="total_commissionable_gross" sortColumn="total_commissionable_gross" dataType="money" footerFunction="sum" footerCssName="text-right" />
                            </DGDGTableRowComponent>
                        </DGDGTableV3Component>
                    </div>
                    <div className="dgdg-flex-item">
                        <div className="dgdg-flex dgdg-flex-column">
                            <div id="tblExcludedROs">
                                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} tableData={this.props.totalExcludedROTableData} headerText={this.props.totalExcludedROTableTitle} defaultSortColumn={CommonService.ordinalColumnName} defaultSortOrder="Asc" paintTable={this.props.totalExcludedROPaintTable} cardCssName="overflow-auto dgdg-service-advisor-dialog-padding dgdg-service-advisor-total-commissionable-gross-table-width" showFooter
                                    copyTableConfig={{ "columns": [0, 1, 2, 3], onCopyTableCallBack: this.onCopyTableCallBack }}
                                >
                                    <DGDGTableRowComponent>
                                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                        <DGDGTableColumnComponent headerText="RO Number" dataColumn="ro_number" onCellClick={this.props.onRODetailCellClick} sortColumn="ro_number" filterColumn="ro_number" footerText="Total" />
                                        <DGDGTableColumnComponent headerText="Labor Type" dataColumn="labor_type" sortColumn="labor_type" filterColumn="labor_type" />
                                        <DGDGTableColumnComponent headerText="Excluded Total Commissionable Gross" dataColumn="excluded_total_commissionable_gross" sortColumn="excluded_total_commissionable_gross" dataType="money" footerFunction="sum" footerCssName="text-right" />
                                    </DGDGTableRowComponent>
                                </DGDGTableV3Component>
                            </div>
                            <br />
                            <div id="tblRentalROs">
                                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} tableData={this.props.totalRentalROTableData} headerText={this.props.totalRentalROTableTitle} defaultSortColumn={CommonService.ordinalColumnName} defaultSortOrder="Asc" paintTable={this.props.totalRentalROPaintTable} cardCssName="overflow-auto dgdg-service-advisor-dialog-padding dgdg-service-advisor-total-commissionable-gross-table-width" showFooter
                                    copyTableConfig={{ "columns": [0, 1, 2, 3], onCopyTableCallBack: this.onCopyTableCallBack }}
                                >
                                    <DGDGTableRowComponent>
                                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                        <DGDGTableColumnComponent headerText="RO Number" dataColumn="ro_number" sortColumn="ro_number" filterColumn="ro_number" onCellClick={this.props.onRODetailCellClick} footerText="Total" />
                                        <DGDGTableColumnComponent headerText="Labor Type" dataColumn="labor_type" sortColumn="labor_type" filterColumn="labor_type" />
                                        <DGDGTableColumnComponent headerText="Total Sale" dataColumn="total_sale" sortColumn="total_sale" dataType="money" footerFunction="sum" footerCssName="text-right" />
                                    </DGDGTableRowComponent>
                                </DGDGTableV3Component>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DGDGModalDialogComponent>;
    }
}
