import React from "react";
import $ from "jquery";

import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

import { DGDGFormComboBoxComponent } from "../common/dgdgcombobox/DGDGFormComboBoxComponent";

export class ControllerPolicyQueryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            controllerPolicyName: "",
            ddpControllerList: [],
            selectedControllerPolicy: ""
        };

        this.onControllerPolicyClick = this.onControllerPolicyClick.bind(this);
        this.onControllerPolicyChange = this.onControllerPolicyChange.bind(this);
        this.onGetControllerClick = this.onGetControllerClick.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.controllerList && (!prevProps.controllerList || prevProps.controllerList !== this.props.controllerList)) {
                this.setState({
                    ddpControllerList: this.props.controllerList,
                });
                $('#ddpConttrollerPolicy').trigger('focus');
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onControllerPolicyClick(id, filterValue) {
        try {
            this.setState({
                controllerPolicyName: filterValue.display_name,
                selectedControllerPolicy: filterValue
            })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onControllerPolicyChange(id, filterValue) {
        try {
            let filteredData = this.props.controllerList.filter(row => {
                return (row.controller_name.toLowerCase().indexOf(filterValue.toLowerCase()) === 0
                    || row.group_name.toLowerCase().indexOf(filterValue.toLowerCase()) === 0);
            });

            this.setState({
                controllerPolicyName: filterValue,
                ddpControllerList: filteredData
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetControllerClick(event) {
        this.props.onGetAssignedRoles(event, this.state.selectedControllerPolicy);
    }

    render() {
        return <div className="card dgdg-card-gutter">
            <div className="card-block">
                <div className="form-inline">
                    <DGDGFormComboBoxComponent id="ddpConttrollerPolicy" label="Controller" data={this.state.ddpControllerList} itemKey="display_name" value={this.state.controllerPolicyName} cssName="dgdg-controller-policy-ddp" onItemClick={this.onControllerPolicyClick} onItemChange={this.onControllerPolicyChange} labelSpan="col-2" controlSpan="col-10" autoDrop />
                    <button disabled={this.props.isLoadingData || !this.state.controllerPolicyName} className="btn btn-primary" onClick={(event) => this.onGetControllerClick(event)}>
                        Get Controller <span className="fas fa-edit dgdg-icon" />
                    </button>
                    <DGDGSpinnerComponent showSpinner={this.props.isLoadingData} />
                </div>
            </div>
        </div>
    }
}
