import React from "react";
import { Link } from "react-router-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom"

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";

import { NewPDIInventoryComponent } from "./NewPDIInventoryComponent";
import { NewPDISummaryComponent } from "./NewPDISummaryComponent";
import { NewPDIAuditComponent } from "./NewPDIAuditComponent";
import { PageNotFoundRouteComponent } from "../pagenotfound/PageNotFoundRouteComponent";

export class NewPDIRouteComponent extends React.Component {
    componentDidMount() {
        try {
            CommonService.clientAzureStorageLog("NewPDIRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "New Car Prep";

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-responsive-page dgdg-flex dgdg-flex-column">
            <BrowserRouter>
                <nav className="nav nav-pills dgdg-tab">
                    <Link to="/NewPDI/Inventory" className={"nav-item nav-link" + (window.location.pathname.indexOf("/Inventory") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Inventory</Link>
                    <Link to="/NewPDI/Summary" className={"nav-item nav-link" + (window.location.pathname.indexOf("/Summary") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Summary</Link>
                    <Link to="/NewPDI/Audit" className={"nav-item nav-link" + (window.location.pathname.indexOf("/Audit") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Audit</Link>
                </nav>
                <div className="dgdg-flex-item dgdg-site-content dgdg-new-pdi-audit-content">
                    <Switch>
                        <Route exact path="/NewPDI/Inventory" render={(routeProps) => <NewPDIInventoryComponent {...this.props} />} />
                        <Route exact path="/NewPDI/Summary" render={(routeProps) => <NewPDISummaryComponent {...this.props} />} />
                        <Route exact path="/NewPDI/Audit" render={(routeProps) => <NewPDIAuditComponent {...this.props} />} />
                        <Route render={(routeProps) => <PageNotFoundRouteComponent {...this.props} {...routeProps} />} />
                    </Switch>
                </div>
            </BrowserRouter>
        </DGDGPageComponent>;
    }
}
