import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";

import { USWarrantyReportQueryComponent } from "./USWarrantyReportQueryComponent";

export class USWarrantyReportRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        let months = CommonService.getMonths();
        months = months.slice(1);
        this.state = {
            isLoadingData: false,
            isDownLoading: false,
            usWarrantyReport: [],
            tableTitle: "",
            paintTable: false,
            months: months
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onGetUSWClick = this.onGetUSWClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/USWarrantyReport", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("USWarrantyReportRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "US Warranty Report";

    onGetReportClick(startDate, endDate) {
        try {
            let inputData = {
                startDate: CommonService.formatServerDateTime(startDate.toString()),
                endDate: CommonService.formatServerDateTime(endDate.toString())
            };

            this.setState({ isLoadingData: true });
            this.props.applicationInsights.trackTrace({ message: "/USWarrantyReportRouteComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/USWarrantyReport/GetUSWarrantyReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: CommonService.formatDate(moment(startDate)) + " - " +
                            CommonService.formatDate(moment(endDate)),
                        usWarrantyReport: CommonService.addOrdinalColumn(parsedResponse.table),
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: "",
                        usWarrantyReport: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/USWarrantyReport/GetUSWarrantyReport", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDownloadClick(startDate, endDate) {
        try {
            this.setState({ isDownLoading: true });
            let inputData = {
                startDate: CommonService.formatServerDateTime(startDate),
                endDate: CommonService.formatServerDateTime(endDate)
            };

            this.props.applicationInsights.trackTrace({ message: "/USWarrantyReportRouteComponent/onDownloadClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/USWarrantyReport/Download", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response; } else { throw response; } })
                .then(response => {
                    CommonService.downloadBlob(response);
                    this.setState({ isDownLoading: false });
                })
                .catch(notOKResponse => {
                    this.setState({ isDownLoading: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/USWarrantyReport/Download", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetUSWClick(startDate) {
        try {
            this.setState({ isDownLoading: true });
            let inputData = {
                startDate: CommonService.formatServerDateTime(startDate)
            };

            this.props.applicationInsights.trackTrace({ message: "/USWarrantyReportRouteComponent/onGetUSWClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/USWarrantyReport/GetUSWUpload", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response; } else { throw response; } })
                .then(response => {
                    CommonService.downloadBlob(response);
                    this.setState({ isDownLoading: false });
                })
                .catch(notOKResponse => {
                    this.setState({ isDownLoading: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/USWarrantyReport/GetUSWUpload", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("warning", parsedError, 4000);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("USWarrantyReportRouteComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-flex dgdg-flex-column">
            <div className="dgdg-report-body dgdg-flex dgdg-flex-column dgdg-flex-item dgdg-site-content">
                <USWarrantyReportQueryComponent
                    applicationInsights={this.props.applicationInsights}
                    isLoadingData={this.state.isLoadingData}
                    isDownLoading={this.state.isDownLoading}
                    months={this.state.months}
                    onGetReportClick={this.onGetReportClick}
                    onDownloadClick={this.onDownloadClick}
                    onGetUSWClick={this.onGetUSWClick}
                />
                <div className="dgdg-site-scrollable-content">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.usWarrantyReport} paintTable={this.state.paintTable}
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                            <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_name_web" sortColumn="store_number" filterColumn="store_name_web" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Dealer Code" dataColumn="dealer_code" sortColumn="dealer_code" filterColumn="dealer_code" />
                            <DGDGTableColumnComponent headerText="Customer First" dataColumn="customer_first" sortColumn="customer_first" filterColumn="customer_first" />
                            <DGDGTableColumnComponent headerText="Customer Middle" dataColumn="customer_middle" sortColumn="customer_middle" filterColumn="customer_middle" />
                            <DGDGTableColumnComponent headerText="Customer Last" dataColumn="customer_last" sortColumn="customer_last" filterColumn="customer_last" />
                            <DGDGTableColumnComponent headerText="Address" dataColumn="address" sortColumn="address" filterColumn="address" />
                            <DGDGTableColumnComponent headerText="City" dataColumn="city" sortColumn="city" filterColumn="city" />
                            <DGDGTableColumnComponent headerText="State" dataColumn="state" sortColumn="state" filterColumn="state" />
                            <DGDGTableColumnComponent headerText="Zip" dataColumn="zip" sortColumn="zip" filterColumn="zip" />
                            <DGDGTableColumnComponent headerText="Home Phone" dataColumn="home_phone" sortColumn="home_phone" filterColumn="home_phone" />
                            <DGDGTableColumnComponent headerText="Customer Email" dataColumn="customer_email_1" sortColumn="customer_email_1" filterColumn="customer_email_1" />
                            <DGDGTableColumnComponent headerText="Vin" dataColumn="vin" sortColumn="vin" filterColumn="vin" />
                            <DGDGTableColumnComponent headerText="Contract Date" dataColumn="contract_date" sortColumn="contract_date" filterColumn="contract_date" dataType="date" />
                            <DGDGTableColumnComponent headerText="Vehicle Mileage" dataColumn="vehicle_mileage" sortColumn="vehicle_mileage" filterColumn="vehicle_mileage" dataType="number" />
                            <DGDGTableColumnComponent headerText="Filed Amount" dataColumn="filed_amount" sortColumn="filed_amount" filterColumn="filed_amount" dataType="money_2" />
                            <DGDGTableColumnComponent headerText="Charged Amount" dataColumn="charged_amount" sortColumn="charged_amount" filterColumn="charged_amount" dataType="money_2" />
                            <DGDGTableColumnComponent headerText="Stock Number" dataColumn="stock_number" sortColumn="stock_number" filterColumn="stock_number" />
                            <DGDGTableColumnComponent headerText="Deal Number" dataColumn="deal_number" sortColumn="deal_number" filterColumn="deal_number" dataType="number" />
                            <DGDGTableColumnComponent headerText="Customer Number" dataColumn="customer_number" sortColumn="customer_number" filterColumn="customer_number" dataType="number" />
                            <DGDGTableColumnComponent headerText="VSC Income" dataColumn="vsc_income" sortColumn="vsc_income" filterColumn="vsc_income" dataType="money_2" />
                            <DGDGTableColumnComponent headerText="VSC Name" dataColumn="vsc_name" sortColumn="vsc_name" filterColumn="vsc_name" />
                            <DGDGTableColumnComponent headerText="Is Certified" dataColumn="is_certified" sortColumn="is_certified" filterColumn="is_certified" dataType="bool" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}
