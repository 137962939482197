import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableColumnDrCrComponent } from "../common/dgdgtable/DGDGTableColumnDrCrComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGFormTextAreaComponent } from "../common/dgdgtextarea/DGDGFormTextAreaComponent";

export class USWarrantyReconciliationGenerateCDKConcatenateView extends React.Component {
    constructor(props) {
        super(props);

        this.onCopyClick = this.onCopyClick.bind(this);
    }

    pageTitle = "US Warranty Reconciliation";

    onCopyClick(event) {
        try {
            navigator.clipboard.writeText(this.props.cdkConcatenateViewText);
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("USWarrantyReconciliationGenerateCDKConcatenateView", "onCopyClick", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        let cdkConcatenateViewText = "";
        if (this.props.cdkConcatenateViewText) {
            this.props.cdkConcatenateViewText.split('\n').forEach((cdkConcatenateViewItem, index) => {
                if (cdkConcatenateViewItem) {
                    cdkConcatenateViewText += (index + 1) + "\t" + cdkConcatenateViewItem + "\n";
                }
            });
        }

        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            cssName="dgdg-us-warranty-reconciliation-generate-cdk-concatenate-view-dialog"
            showDialog={this.props.showDialog} title={this.props.pageTitle}
            onCancelClick={this.props.onCancelClick}
        >

            <div className="dgdg-report-body">
                <div className="dgdg-flex dgdg-flex-row">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} tableData={this.props.tableData} headerText={this.props.tableTitle} defaultSortColumn={CommonService.ordinalColumnName} defaultSortOrder="Asc" paintTable={this.props.paintTable} cardCssName="dgdg-us-warranty-reconciliation-table-width" cardBlockCssName="dgdg-us-warranty-reconciliation-table-height" showFooter
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8], onCopyTableCallBack: this.props.onCopyTableCallBack }}
                    >
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                            <DGDGTableColumnComponent headerText="Store" dataColumn="store_web_name" filterColumn="store_web_name" sortColumn="store_number" />
                            <DGDGTableColumnComponent headerText="Status" dataColumn="status" sortColumn="status" />
                            <DGDGTableColumnDrCrComponent headerText="DR/CR" dataColumn="amounT_DUE_TO_WARRANTY_COMPAY_drcr" sortColumn="amounT_DUE_TO_WARRANTY_COMPAY_drcr" />
                            <DGDGTableColumnComponent headerText="Account No" dataColumn="account_number" sortColumn="account_number" filterColumn="account_number" />
                            <DGDGTableColumnComponent headerText="Amount" dataColumn="amounT_DUE_TO_WARRANTY_COMPAY" sortColumn="amounT_DUE_TO_WARRANTY_COMPAY" filterColumn="amounT_DUE_TO_WARRANTY_COMPAY" dataType="money_2" footerFunction="sum" footerCssName="text-right" />
                            <DGDGTableColumnComponent headerText="Control 1" dataColumn="control_1" sortColumn="control_1" filterColumn="control_11" />
                            <DGDGTableColumnComponent headerText="Control 2" dataColumn="control_2" sortColumn="control_2" filterColumn="control_2" />
                            <DGDGTableColumnComponent headerText="Description" dataColumn="description" sortColumn="description" filterColumn="description" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                    <div className="card dgdg-box-shadow" style={{ height: "100%", width: "40%", marginLeft: "15px" }}>
                        <div className="card-header">
                            <span dangerouslySetInnerHTML={{ __html: this.props.dataTitle }} className="float-left" />
                            <button className="btn btn-primary float-right" onClick={(event) => this.onCopyClick(event)}>
                                Copy<span className="far fa-copy dgdg-icon" />
                            </button>
                        </div>
                        <div className="card-block">
                            <DGDGFormTextAreaComponent value={this.props.cdkConcatenateViewText} cssName="dgdg-copy-textbox" readOnly />
                            <DGDGFormTextAreaComponent value={cdkConcatenateViewText} rows="20" colSpan="col-12" controlSpan="col-12" readOnly />
                        </div>
                    </div>
                </div>
            </div>
        </DGDGModalDialogComponent>;
    }
}
