import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGModalConfirmationComponent } from "../common/dgdgmodalconfirmation/DGDGModalConfirmationComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

export class UserDetailComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSpinner: false,
            displayName: "",
            firstName: "",
            lastName: "",
            remoteUpn: "",
            email: "",
            phone: "",
            status: "Select Status",
            tenantId: null,
            assignedRoles: [],
            unAssignedRoles: [],
            assignRole: null,
            paintTable: false,
            deleteAssignedRole: null
        };

        this.onTextChange = this.onTextChange.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onAssignedRoleColumnCallback = this.onAssignedRoleColumnCallback.bind(this);
        this.onActionColumnCallback = this.onActionColumnCallback.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
        this.onUnassignedRoleChange = this.onUnassignedRoleChange.bind(this);
        this.onAssignRoleClick = this.onAssignRoleClick.bind(this);
        this.onUnassignRoleClick = this.onUnassignRoleClick.bind(this);
        this.OnImpersonateClick = this.OnImpersonateClick.bind(this);
        this.onShowConfirmationClick = this.onShowConfirmationClick.bind(this);
        this.onConfirmationOkClick = this.onConfirmationOkClick.bind(this);
        this.onConfirmationCancelClick = this.onConfirmationCancelClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: false,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    componentDidMount() {
        try {
            if (this.props.match.params.guid) {
                this.getDetail();
                this.getAssignedRoles();
                this.getUnAssignedRoles();
            }
            CommonService.clientAzureStorageLog("UserRouteComponent", "UserDetailComponent", "componentDidMount", null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "User";

    getDetail() {
        let inputData = {
            identityGuid: this.props.match.params.guid
        };

        this.props.applicationInsights.trackTrace({ message: "/UserDetailComponent/getDetail", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/User/GetDetail", {
            method: "POST",
            headers: {
                'Content-Type': "application/json; charset=utf-8;"
            },
            body: JSON.stringify(inputData)
        })
            .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
            .then(parsedResponse => {
                this.setState({
                    displayName: parsedResponse[0].display_name,
                    firstName: parsedResponse[0].given_name,
                    lastName: parsedResponse[0].surname,
                    remoteUpn: parsedResponse[0].remote_upn,
                    email: parsedResponse[0].email,
                    phone: parsedResponse[0].phone ? parsedResponse[0].phone : "",
                    status: parsedResponse[0].status,
                    tenantId: parsedResponse[0].tenant_id
                });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedReconditioning/GetDetail", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    getAssignedRoles() {
        let inputData = {
            identityGuid: this.props.match.params.guid
        };

        this.props.applicationInsights.trackTrace({ message: "/UserDetailComponent/getAssignedRoles", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/User/GetAssignedRoles", {
            method: "POST",
            headers: {
                'Content-Type': "application/json; charset=utf-8;"
            },
            body: JSON.stringify(inputData)
        })
            .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
            .then(parsedResponse => {
                this.setState({
                    assignedRoles: parsedResponse.table,
                    paintTable: true
                }, () => {
                    this.setState({ paintTable: false });
                });
            })
            .catch(notOKResponse => {
                this.setState({
                    assignedRoles: [],
                    paintTable: true
                }, () => {
                    this.setState({ paintTable: false });
                });
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/User/GetAssignedRoles", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    getUnAssignedRoles() {
        let inputData = {
            identityGuid: this.props.match.params.guid
        };

        this.props.applicationInsights.trackTrace({ message: "/UserDetailComponent/getUnAssignedRoles", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/User/GetUnassignedRoles", {
            method: "POST",
            headers: {
                'Content-Type': "application/json; charset=utf-8;"
            },
            body: JSON.stringify(inputData)
        })
            .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
            .then(parsedResponse => {
                this.setState({
                    unAssignedRoles: parsedResponse.table,
                    paintTable: true
                }, () => {
                    this.setState({ paintTable: false });
                });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/User/GetUnassignedRoles", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    onAssignedRoleColumnCallback(rowData) {
        return rowData.id === undefined
            ? <DGDGFormDropdownComponent data={this.state.unAssignedRoles} itemKey="application_role_ext_name" value={this.state.assignRole ? this.state.assignRole.application_role_ext_name : "Select Role"} onItemClick={this.onUnassignedRoleChange} />
            : rowData.application_role_ext_name;
    }

    onActionColumnCallback(rowData) {
        return rowData.id === undefined
            ? <Fragment>
                <button type="button" className="btn btn-link" onClick={(event) => this.onAssignRoleClick(event, rowData)}>
                    <span className="fas fa-save dgdg-font-blue dgdg-font-size-18" aria-hidden="true" />
                </button>
                <button type="button" className="btn btn-link" onClick={(event) => this.onUnassignRoleClick(event, rowData)}>
                    <span className="fas fa-window-close text-danger dgdg-font-size-18 dgdg-icon" aria-hidden="true" />
                </button>
            </Fragment>
            : <button type="button" className="btn btn-link" onClick={(event) => this.onShowConfirmationClick(event, rowData)}>
                <span className="fas fa-window-close text-danger dgdg-font-size-18 dgdg-icon" aria-hidden="true" />
            </button>;
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStatusChange(id, newStatus) {
        try {
            this.setState({ status: newStatus });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event) {
        try {
            this.setState({ showSpinner: true });
            let inputData = {
                identityGuid: this.props.match.params.guid,
                displayName: this.state.displayName,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                phone: this.state.phone,
                remoteUpn: this.state.remoteUpn,
                status: this.state.status,
                tenantId: this.state.tenantId
            };

            this.props.applicationInsights.trackTrace({ message: "/UserDetailComponent/onSaveClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/User/SaveDetail", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({ showSpinner: false });
                })
                .catch(notOKResponse => {
                    this.setState({ showSpinner: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/User/SaveDetail", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onConfirmationOkClick(event) {
        try {
            this.onUnassignRoleClick();
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onConfirmationCancelClick(event, rowData) {
        try {
            this.setState({ deleteAssignedRole: null });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onShowConfirmationClick(event, rowData) {
        try {
            this.setState({ deleteAssignedRole: rowData });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onUnassignedRoleChange(id, asgnRole) {
        try {
            this.setState({
                assignRole: asgnRole,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAddClick(event) {
        try {
            let asgndRoles = this.state.assignedRoles;
            if (asgndRoles.length === 0 || asgndRoles[asgndRoles.length - 1].id !== undefined) {
                asgndRoles.push({});
                this.setState({
                    assignedRoles: asgndRoles,
                    paintTable: true
                }, () => {
                    this.setState({ paintTable: false });
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAssignRoleClick(event, rowData) {
        try {
            let inputData = {
                identityGuid: this.props.match.params.guid,
                roleId: this.state.assignRole.id
            };

            this.props.applicationInsights.trackTrace({ message: "/UserDetailComponent/onAssignRoleClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/User/AssignRole", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.getAssignedRoles();
                    this.getUnAssignedRoles();
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/User/AssignRole", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onUnassignRoleClick(event, rowData) {
        try {
            if (rowData && rowData.id === undefined) {
                let asgndRoles = this.state.assignedRoles;
                asgndRoles.pop();
                this.setState({
                    assignedRoles: asgndRoles,
                    assignRole: null,
                    paintTable: true
                }, () => {
                    this.setState({ paintTable: false });
                });

            } else {
                let inputData = {
                    applicationRoleExtMemberId: this.state.deleteAssignedRole.id
                };

                this.props.applicationInsights.trackTrace({ message: "/UserDetailComponent/onUnassignRoleClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                fetch("/User/UnassignRole", {
                    method: "POST",
                    headers: {
                        'Content-Type': "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData)
                })
                    .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                    .then(parsedResponse => {
                        this.getAssignedRoles();
                        this.getUnAssignedRoles();
                        this.setState({
                            assignRole: null
                        }, this.onConfirmationCancelClick);
                    })
                    .catch(notOKResponse => {
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/User/UnassignRole", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                    console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.devError(jsonParseError, null, this.props.applicationInsights);
                                });
                        }
                    });
            }
            if (this.state.deleteAssignedRole !== null) {
                this.setState({ deleteAssignedRole: null });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    OnImpersonateClick(event) {
        try {
            var id = "?id=" + this.props.match.params.guid;
            window.location = "/User/Impersonate" + id + "&RedirectURL=User/Detail/" + this.props.match.params.guid;
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent>
            <div className="dgdg-report-body">
                <div className="dgdg-site-scrollable-content">
                    <div className="dgdg-flex dgdg-flex-row">
                        <div className="card dgdg-flex-item">
                            <div className="card-header">
                                {this.state.remoteUpn}
                            </div>
                            <div className="card-block">
                                <div className="dgdg-report-body">
                                    <div className="card dgdg-card-gutter">
                                        <div className="card-header">
                                            <div className="form-inline">
                                                <button disabled={this.state.showSpinner} className="btn btn-primary" onClick={(event) => this.onSaveClick(event)}>
                                                    Save <span className="fas fa-save dgdg-icon"></span>
                                                </button>
                                                <Link to="/User" className="btn btn-primary dgdg-icon">
                                                    Cancel <span className="fas fa-window-close dgdg-icon"></span>
                                                </Link>
                                                <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-icon" onClick={(event) => this.OnImpersonateClick(event)}>
                                                    Impersonate <span className="fas fa-user-plus dgdg-icon"></span>
                                                </button>
                                                <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-block">
                                            <div className="form-inline">
                                                <div className="form-row">
                                                    <DGDGFormInputComponent id="displayName" label="Display name" value={this.state.displayName} onChange={this.onTextChange} colSpan="col-12" labelSpan="col-2" controlSpan="col-10" />
                                                </div>
                                            </div>
                                            <div className="form-inline">
                                                <div className="form-row">
                                                    <DGDGFormInputComponent id="firstName" label="First name" value={this.state.firstName} onChange={this.onTextChange} colSpan="col-12" labelSpan="col-2" controlSpan="col-10" />
                                                </div>
                                            </div>
                                            <div className="form-inline">
                                                <div className="form-row">
                                                    <DGDGFormInputComponent id="lastName" label="Last name" value={this.state.lastName} onChange={this.onTextChange} colSpan="col-12" labelSpan="col-2" controlSpan="col-10" />
                                                </div>
                                            </div>
                                            <div className="form-inline">
                                                <div className="form-row">
                                                    <DGDGFormInputComponent disabled={this.props.match.params.guid !== undefined} id="remoteUpn" label="Remote UPN" value={this.state.remoteUpn} onChange={this.onTextChange} colSpan="col-12" labelSpan="col-2" controlSpan="col-10" />
                                                </div>
                                            </div>
                                            <div className="form-inline">
                                                <div className="form-row">
                                                    <DGDGFormInputComponent id="email" label="Email" value={this.state.email} onChange={this.onTextChange} colSpan="col-12" labelSpan="col-2" controlSpan="col-10" />
                                                </div>
                                            </div>
                                            <div className="form-inline">
                                                <div className="form-row">
                                                    <DGDGFormInputComponent id="phone" label="Phone" value={this.state.phone} onChange={this.onTextChange} colSpan="col-12" labelSpan="col-2" controlSpan="col-10" />
                                                </div>
                                            </div>
                                            <div className="form-inline">
                                                <div className="form-row">
                                                    <DGDGFormDropdownComponent label="Status" data={["Active", "Inactive"]} value={this.state.status} onItemClick={this.onStatusChange} colSpan="col-12" labelSpan="col-2" controlSpan="col-10" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DGDGModalConfirmationComponent
                            applicationInsights={this.props.applicationInsights}
                            headerText={"Remove role: " + (this.state.deleteAssignedRole !== null ? this.state.deleteAssignedRole.application_role_ext_name : "")}
                            bodyText={"Are you sure?"}
                            showConfirmation={this.state.deleteAssignedRole !== null}
                            onOkClick={this.onConfirmationOkClick}
                            onCancelClick={this.onConfirmationCancelClick} />
                        <div className="card dgdg-flex-item dgdg-icon">
                            <div className="card-header">
                                Roles
                            </div>
                            <div className="card-block">
                                <div className="dgdg-report-body">
                                    <div className="card dgdg-card-gutter">
                                        <div className="card-header">
                                            <button className="btn btn-primary" onClick={(event) => this.onAddClick(event)}>
                                                Add <span className="fas fa-plus dgdg-icon" />
                                            </button>
                                        </div>
                                    </div>
                                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} tableData={this.state.assignedRoles} paintTable={this.state.paintTable}>
                                        <DGDGTableRowComponent>
                                            <DGDGTableColumnComponent headerText="Assigned Role" dataColumnCallback={this.onAssignedRoleColumnCallback} />
                                            <DGDGTableColumnComponent headerText="Action" dataColumnCallback={this.onActionColumnCallback} />
                                        </DGDGTableRowComponent>
                                    </DGDGTableV3Component>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}
