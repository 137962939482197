import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DashboardComponent } from "./DashboardComponent";

export class DashboardRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        try {
            CommonService.clientAzureStorageLog("DashboardRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Dashboard";

    render() {
        if (this.props.isAuthenticated) {
            return <DashboardComponent {...this.props} />;
        }

        return null;
    }
}
