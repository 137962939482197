import React from "react";
import { Link } from "react-router-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom"

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";

import { UsedReconditioningInventoryComponent } from "./UsedReconditioningInventoryComponent";
import { UsedReconditioningSummaryComponent } from "./UsedReconditioningSummaryComponent";
import { UsedReconditioningAuditComponent } from "./UsedReconditioningAuditComponent";
import { PageNotFoundRouteComponent } from "../pagenotfound/PageNotFoundRouteComponent";

export class UsedReconditioningMobileRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stores: null,
            activeStores: null,
            summaryStores: null
        };
    }

    componentDidMount() {
        try {
            this.getInventoryStores();
            this.getSummaryStores();
            this.getActiveStores();
            CommonService.clientAzureStorageLog("UsedReconditioningRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Used Car Prep";

    getInventoryStores() {
        fetch("/UsedReconditioning/GetInventoryStores")
            .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
            .then(parsedResponse => {
                this.setState({
                    stores: parsedResponse
                });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    getSummaryStores() {
        fetch("/UsedReconditioning/GetSummaryStores")
            .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
            .then(parsedResponse => {
                this.setState({
                    summaryStores: parsedResponse
                });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    getActiveStores() {
        fetch("/UsedReconditioning/GetActiveStores")
            .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
            .then(parsedResponse => {
                this.setState({
                    activeStores: parsedResponse
                });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-flex dgdg-flex-column">
            <BrowserRouter>
                <nav className="nav nav-pills dgdg-tab">
                    <Link to="/UsedReconditioningMobile/Inventory" className={"nav-item nav-link dgdg-used-car-nav" + (window.location.pathname.indexOf("/Inventory") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Inventory</Link>
                    <Link to="/UsedReconditioningMobile/Summary" className={"nav-item nav-link dgdg-used-car-nav" + (window.location.pathname.indexOf("/Summary") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Summary</Link>
                    <Link to="/UsedReconditioningMobile/Audit" className={"nav-item nav-link dgdg-used-car-nav" + (window.location.pathname.indexOf("/Audit") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Audit</Link>
                </nav>
                <div className="dgdg-flex-item dgdg-site-content">
                    <Switch>
                        <Route exact path="/UsedReconditioningMobile/Inventory" render={(routeProps) => <UsedReconditioningInventoryComponent {...this.props} stores={this.state.stores} activeStores={this.state.activeStores} />} />
                        <Route exact path="/UsedReconditioningMobile/Summary" render={(routeProps) => <UsedReconditioningSummaryComponent {...this.props} stores={this.state.summaryStores} />} />
                        <Route exact path="/UsedReconditioningMobile/Audit" render={(routeProps) => <UsedReconditioningAuditComponent {...this.props} stores={this.state.summaryStores} />} />
                        <Route render={(routeProps) => <PageNotFoundRouteComponent {...this.props} {...routeProps} />} />
                    </Switch>
                </div>
            </BrowserRouter>
        </DGDGPageComponent>;
    }
}
