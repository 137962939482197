import React, { Fragment } from "react";

export class SalesStoreReportWeekendTitleHeaderRowComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return <Fragment>
            <tr className="text-center dgdg-sales-store-bg-title-foot">
                <td className="dgdg-sales-store-bg">&nbsp;</td>
                <td colSpan="9" className="text-left dgdg-font-grey dgdg-font-medium font-weight-bold">{this.props.title}</td>
                <td className="dgdg-sales-store-bg" />
                <td colSpan="2">VOLUME</td>
                <td colSpan="2">GROSS</td>
                <td colSpan="2">PVR</td>
            </tr>
            <tr className="dgdg-table-v3-header text-center">
                <td className="dgdg-sales-store-bg dgdg-no-border-right" />
                <td>FRI VOLUME</td>
                <td>FRI GROSS</td>
                <td>SAT VOLUME</td>
                <td>SAT GROSS</td>
                <td>SUN VOLUME</td>
                <td>SUN GROSS</td>
                <td>WKD VOLUME</td>
                <td>WKD PVR</td>
                <td className="dgdg-no-border-right">WKD GROSS</td>
                <td className="dgdg-sales-store-bg">&nbsp;</td>
                <td>WOW %&Delta;</td>
                <td>YOY %&Delta;</td>
                <td>WOW %&Delta;</td>
                <td>YOY %&Delta;</td>
                <td>WOW $&Delta;</td>
                <td>YOY $&Delta;</td>
            </tr>
        </Fragment>;
    }
}
