import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";

import { DGDGModalConfirmationComponent } from "../common/dgdgmodalconfirmation/DGDGModalConfirmationComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

export class ServicePayrollQueryComponent extends React.Component {
    static StoreNumberCookie = "ServicePayrollStoreNumberCookie";

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            if (state.showSpinner) {
                newState.showSpinner = props.isProcessingAdjustments || props.isDownLoading;
            }

            if (props.stores && state.selectedStore === null) {
                let selectedStore = props.stores[0];
                let cookieStoreNumber = CommonService.getCookie(ServicePayrollQueryComponent.StoreNumberCookie);
                if (cookieStoreNumber) {
                    cookieStoreNumber = parseInt(cookieStoreNumber);
                    let filterStore = props.stores.filter(store => store.store_number === cookieStoreNumber);
                    if (filterStore.length === 1) {
                        selectedStore = filterStore[0];
                    }
                }

                newState.selectedStore = selectedStore;
            }

            let selectedMonth = moment(state.selectedMonth);
            if (props.months && state.selectedMonth === null) {
                selectedMonth = props.months[0];
                newState.selectedMonth = selectedMonth;
                selectedMonth = moment(selectedMonth);
            }

            let isProcessDisabled = false;
            let minCutoffDate = moment().startOf("month").subtract(1, "month");
            let prevMonthCutoffDate = moment().startOf("month").add(9, "day");
            let currentDate = moment();
            if (selectedMonth < minCutoffDate
                || (selectedMonth.month() === currentDate.month() - 1 && currentDate > prevMonthCutoffDate)) {
                isProcessDisabled = true;
            }

            newState.isProcessDisabled = isProcessDisabled;
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedMonth: null,
            selectedStore: null,
            additionalEmployeeNumber: "",
            isProcessDisabled: false,
            showSpinner: false,
            showProcessPayrollDataDialog: false
        };

        this.onMonthClick = this.onMonthClick.bind(this);
        this.onStoreClick = this.onStoreClick.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onGetPayrollClick = this.onGetPayrollClick.bind(this);
        this.onProcessPayrollDataClick = this.onProcessPayrollDataClick.bind(this);
        this.onProcessPayrollDataCheckClick = this.onProcessPayrollDataCheckClick.bind(this);
        this.onProcessPayrollDataOkClick = this.onProcessPayrollDataOkClick.bind(this);
        this.onProcessPayrollDataCancelClick = this.onProcessPayrollDataCancelClick.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    onMonthClick(id, month) {
        try {
            this.setState({ selectedMonth: month });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStoreClick(id, store) {
        try {
            this.setState({ selectedStore: store });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetPayrollClick(event) {
        try {
            this.setState({ showSpinner: true });
            CommonService.setCookie(ServicePayrollQueryComponent.StoreNumberCookie, this.state.selectedStore.store_number, 30);
            this.props.onGetPayrollClick(this.state.selectedMonth, this.state.selectedStore, this.state.additionalEmployeeNumber);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onProcessPayrollDataClick(event) {
        try {
            this.setState({ showSpinner: true });
            this.props.onProcessPayrollDataClick(event, this.state.selectedMonth, this.state.selectedStore);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onProcessPayrollDataCheckClick(event) {
        try {
            this.setState({ showProcessPayrollDataDialog: true });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onProcessPayrollDataOkClick(event) {
        try {
            this.props.onProcessPayrollDataClick(event, this.state.selectedMonth);
            this.setState({
                showProcessPayrollDataDialog: false,
                showSpinner: true
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onProcessPayrollDataCancelClick(event) {
        try {
            this.setState({
                showProcessPayrollDataDialog: false
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDownloadClick(event) {
        try {
            this.setState({ showSpinner: true });
            CommonService.setCookie(ServicePayrollQueryComponent.StoreNumberCookie, this.state.selectedStore.store_number, 30);
            this.props.onDownloadClick(this.state.selectedMonth, this.state.selectedStore, this.state.additionalEmployeeNumber);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGetPayrollClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="card dgdg-card-gutter">
            <div className="card-block">
                <div className="form-inline">
                    <DGDGModalConfirmationComponent
                        applicationInsights={this.props.applicationInsights}
                        headerText={"Process Payroll"}
                        bodyText={"Are you sure you want to process payroll for " + moment(this.state.selectedMonth).format("M/D/YYYY") + "?"}
                        showConfirmation={this.state.showProcessPayrollDataDialog === true}
                        onOkClick={this.onProcessPayrollDataOkClick}
                        onCancelClick={this.onProcessPayrollDataCancelClick}
                    />
                    <DGDGFormDropdownComponent label="Store #" data={this.props.stores} itemKey="display_name" value={this.state.selectedStore ? this.state.selectedStore.display_name : "Select Store"} onItemClick={this.onStoreClick} onKeyDown={this.onKeyDown} />
                    <DGDGFormDropdownComponent label="Month" data={this.props.months} displayFormat="monthYear" value={this.state.selectedMonth} onItemClick={this.onMonthClick} onKeyDown={this.onKeyDown} />
                    <DGDGFormInputComponent id="additionalEmployeeNumber" label="Addl Emp #" value={this.state.additionalEmployeeNumber} onChange={this.onTextChange} onKeyDown={this.onKeyDown} />
                    <button disabled={this.state.showSpinner || this.state.selectedStore === null} className="btn btn-primary dgdg-icon" onClick={(event) => this.onGetPayrollClick(event)}>
                        Get Payroll
                    </button>
                    <button disabled={this.state.showSpinner || this.state.selectedMonth === null || this.state.isProcessDisabled || !this.props.canProcess} className="btn btn-primary dgdg-icon" onClick={(event) => this.onProcessPayrollDataCheckClick(event)}>
                        Process Payroll Data<i className="fas fa-sync-alt dgdg-icon" />
                    </button>
                    <button disabled={this.state.showSpinner || this.state.selectedStore === null || !this.state.selectedStore || !this.state.selectedStore.store_number} className="btn btn-primary dgdg-icon" onClick={(event) => this.onDownloadClick(event)}>
                        Download<span className="far fa-download dgdg-icon" />
                    </button>
                    <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
                </div>
            </div>
        </div>;
    }
}
