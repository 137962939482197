import React from "react";
import { Link } from "react-router-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom"

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";

import { ProductAdjustmentComponent } from "./ProductAdjustmentComponent";
import { ReserveAdjustmentComponent } from "./ReserveAdjustmentComponent";
import { GrossAdjustmentComponent } from "./GrossAdjustmentComponent";
import { GLReconciliationComponent } from "./GLReconciliationComponent";
import { ProductAdjustmentReportComponent } from "./ProductAdjustmentReportComponent";
import { ReserveAdjustmentReportComponent } from "./ReserveAdjustmentReportComponent";
import { PageNotFoundRouteComponent } from "../pagenotfound/PageNotFoundRouteComponent";

export class ProductReserveAdjustmentsRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stores: null,
            months: CommonService.getMonths(),
            lastUpdatedDate: ""
        };

        this.onLastUpdatedDateChanged = this.onLastUpdatedDateChanged.bind(this);
    }

    componentDidMount() {
        try {
            this.getStores();
            CommonService.clientAzureStorageLog("ProductReserveAdjustmentsRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Product & Reserve Adjustments";

    getStores() {
        this.props.applicationInsights.trackTrace({ message: "/ProductReserveAdjustmentsRouteComponent/getStores", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/ProductReserveAdjustments/GetStores")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({
                    stores: parsedResponse
                });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ProductReserveAdjustments/GetStores", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    onLastUpdatedDateChanged(lastUpdatedDate) {
        try {
            this.setState({ lastUpdatedDate: lastUpdatedDate });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-flex dgdg-flex-column">
            <BrowserRouter>
                <nav className="nav nav-pills dgdg-tab">
                    <Link to="/ProductReserveAdjustments/ProductAdjustments" className={"nav-item nav-link" + (window.location.pathname.indexOf("/ProductAdjustments") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Product Adjustments</Link>
                    <Link to="/ProductReserveAdjustments/ReserveAdjustments" className={"nav-item nav-link" + (window.location.pathname.indexOf("/ReserveAdjustments") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Reserve Adjustments</Link>
                    <Link to="/ProductReserveAdjustments/GrossAdjustments" className={"nav-item nav-link" + (window.location.pathname.indexOf("/GrossAdjustments") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Gross Adjustments</Link>
                    <Link to="/ProductReserveAdjustments/GLReconciliation" className={"nav-item nav-link" + (window.location.pathname.indexOf("/GLReconciliation") !== -1 ? " btn btn-dark active" : " btn btn-link")}>GL Reconciliation</Link>
                    <Link to="/ProductReserveAdjustments/ProductAdjustmentReport" className={"nav-item nav-link" + (window.location.pathname.indexOf("/ProductAdjustmentReport") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Product Adjustment Report</Link>
                    <Link to="/ProductReserveAdjustments/ReserveAdjustmentReport" className={"nav-item nav-link mr-auto" + (window.location.pathname.indexOf("/ReserveAdjustmentReport") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Reserve Adjustment Report</Link>
                    {
                        this.state.lastUpdatedDate
                            ? <div className="mt-auto dgdg-flex-item dgdg-badge-margin">
                                <span className="float-right badge badge-primary dgdg-badge">Last Updated Date: {CommonService.formatDateTimeZone(this.state.lastUpdatedDate)}</span>
                            </div>
                            : null
                    }
                </nav>
                <div className="dgdg-flex-item dgdg-site-content">
                    <Switch>
                        <Route exact path="/ProductReserveAdjustments/ProductAdjustments" render={(routeProps) => <ProductAdjustmentComponent {...this.props} {...routeProps} stores={this.state.stores} months={this.state.months} lastUpdatedBy={this.props.remoteUpn ? this.props.remoteUpn : ""} />} />
                        <Route exact path="/ProductReserveAdjustments/ReserveAdjustments" render={(routeProps) => <ReserveAdjustmentComponent {...this.props} {...routeProps} stores={this.state.stores} months={this.state.months} lastUpdatedBy={this.props.remoteUpn ? this.props.remoteUpn : ""} />} />
                        <Route exact path="/ProductReserveAdjustments/GrossAdjustments" render={(routeProps) => <GrossAdjustmentComponent {...this.props} {...routeProps} stores={this.state.stores} months={this.state.months} lastUpdatedBy={this.props.remoteUpn ? this.props.remoteUpn : ""} />} />
                        <Route exact path="/ProductReserveAdjustments/GLReconciliation" render={(routeProps) => <GLReconciliationComponent {...this.props} {...routeProps} stores={this.state.stores} months={this.state.months} onLastUpdatedDateChanged={this.onLastUpdatedDateChanged} />} />
                        <Route exact path="/ProductReserveAdjustments/ProductAdjustmentReport" render={(routeProps) => <ProductAdjustmentReportComponent {...this.props} {...routeProps} stores={this.state.stores} months={this.state.months} />} />
                        <Route exact path="/ProductReserveAdjustments/ReserveAdjustmentReport" render={(routeProps) => <ReserveAdjustmentReportComponent {...this.props} {...routeProps} stores={this.state.stores} months={this.state.months} />} />
                        <Route render={(routeProps) => <PageNotFoundRouteComponent {...this.props} {...routeProps} />} />
                    </Switch>
                </div>
            </BrowserRouter>
        </DGDGPageComponent>;
    }
}
