import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

export class UsedReconditioningInventoryQueryComponent extends React.Component {
    static StoreNumberCookie = "UsedReconditioningInventoryStoreNumberCookie";

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            newState.showSpinner = props.isLoadingData;
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);

        this.state = {
            showSpinner: false,
            selectedStore: null,
            selectedSortBy: null,
            sortByList: [
                { key: "ok_publish", value: "Publish" },
                { key: "is_wholesale", value: "Retail / Wholesale" },
                { key: "sort_service_out", value: "Service Out" },
                { key: "sort_body_out", value: "Body Out" },
                { key: "sort_detail_out", value: "Detail Out" },
                { key: "sort_recon_out", value: "Recon Out" },
                { key: "sort_photo_out", value: "Photo Out" }
            ]
        };

        this.onStoreClick = this.onStoreClick.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onSortByChange = this.onSortByChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.stores && this.state.selectedStore === null) {
                let selectedStore = this.props.stores[0];
                let cookieStoreNumber = CommonService.getCookie(UsedReconditioningInventoryQueryComponent.StoreNumberCookie);
                if (cookieStoreNumber) {
                    cookieStoreNumber = parseInt(cookieStoreNumber);
                    let filterStore = this.props.stores.filter(store => store.store_number === cookieStoreNumber);
                    if (filterStore.length === 1) {
                        selectedStore = filterStore[0];
                    }
                }

                this.filterSortBy(selectedStore);
                this.setState({
                    selectedStore: selectedStore
                }, this.onGetReportClick);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    filterSortBy(store) {
        let sortByList = this.state.sortByList;
        let selectedSortBy = this.state.selectedSortBy;
        this.setState({
            sortByList: sortByList,
            selectedSortBy: selectedSortBy
        });

    }

    onStoreClick(event, store) {
        try {
            this.filterSortBy(store);
            this.setState({ selectedStore: store });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event) {
        try {
            CommonService.setCookie(UsedReconditioningInventoryQueryComponent.StoreNumberCookie, this.state.selectedStore.store_number, 30);
            this.props.onGetReportClick(this.state.selectedStore);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGetReportClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSortByChange(event, sortBy) {
        try {
            this.setState({
                selectedSortBy: sortBy
            }, this.props.onSortByChange(sortBy.key))
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="card dgdg-card-gutter">
            <div className="card-block">
                <div className="form-inline">
                    <DGDGFormDropdownComponent label="Store #" itemKey="display_name" data={this.props.stores} value={this.state.selectedStore ? this.state.selectedStore.display_name : "Select Store"} onItemClick={this.onStoreClick} onKeyDown={this.onKeyDown} />
                    <button disabled={this.state.showSpinner || !this.state.selectedStore} className="btn btn-primary dgdg-button-width dgdg-icon" onClick={this.onGetReportClick}>
                        Get Report<i className="fas fa-redo-alt dgdg-icon" />
                    </button>
                    <div className="dgdg-mobile">
                        <DGDGFormDropdownComponent label="Sort By " itemKey="value" data={this.state.sortByList} value={this.state.selectedSortBy ? this.state.selectedSortBy.value : "Select Sort By"} onItemClick={this.onSortByChange} onKeyDown={this.onKeyDown} />
                    </div>
                    <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
                </div>
            </div>
        </div>;
    }
}
