import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGQueryComponent } from "../common/dgdgquery/DGDGQueryComponent";

export class DealIssuesSummaryQueryComponent extends React.Component {

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            if (state.showSpinner) {
                newState.showSpinner = props.isLoadingData;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedStatus: this.statusList[0],
            showSpinner: false
        };

        this.onStatusChange = this.onStatusChange.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
    }

    statusList = [
        { key: "Open", value: "Open" },
        { key: "Resolved", value: "Resolved" }
    ];

    componentDidMount() {
        try {
            this.onGetReportClick();
            CommonService.clientAzureStorageLog("DealIssuesSummaryQueryComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStatusChange(id, status) {
        try {
            this.setState({ selectedStatus: status })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event) {
        try {
            this.setState({ showSpinner: true });
            this.props.onGetReportClick(this.state.selectedStatus);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGQueryComponent cssName="dgdg-card-gutter">
            <DGDGFormDropdownComponent label="Status" itemKey="value" data={this.statusList} value={this.state.selectedStatus ? this.state.selectedStatus.value : "Select Status"} onItemClick={this.onStatusChange} />
            <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-icon" onClick={(event) => this.onGetReportClick(event)}>
                Get Report<i className="fas fa-redo-alt dgdg-icon" />
            </button>
            <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
        </DGDGQueryComponent>;
    }
}
