import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGFormCalendarComponent } from "../common/dgdgcalendar/DGDGFormCalendarComponent";

export class FILenderReportQueryComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            if (state.showSpinner) {
                state.showSpinner = props.isLoadingData || props.isDownLoading;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        let startDate = moment().startOf('month');
        let endDate = moment().date(0).month(startDate.month()).year(startDate.year());

        this.state = {
            startDate: startDate,
            endDate: endDate,
            showSpinner: false
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidMount() {
        try {
            this.onGetReportClick(null);
            CommonService.clientAzureStorageLog("FILenderReportRouteComponent", "FILenderReportQueryComponent", "componentDidMount", null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDateChange(id, value) {
        try {
            this.setState({ [id]: moment(value) });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event) {
        try {
            if (CommonService.isDateValid(this.state.startDate) && CommonService.isDateValid(this.state.endDate)) {
                this.setState({ showSpinner: true });
                this.props.onGetReportClick(this.state.startDate, this.state.endDate);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDownloadClick(event) {
        try {
            if (CommonService.isDateValid(this.state.startDate) && CommonService.isDateValid(this.state.endDate)) {
                this.setState({ showSpinner: true });
                this.props.onDownloadClick(this.state.startDate, this.state.endDate);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGetReportClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="card dgdg-card-gutter">
            <div className="card-block">
                <div className="form-inline">
                    <div className="form-row">
                        <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="startDate" label="Start Date" value={this.state.startDate} onDateChange={this.onDateChange} numberOfMonths={2} onKeyDown={this.onKeyDown} />
                        <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="endDate" label="End Date" value={this.state.endDate} onDateChange={this.onDateChange} numberOfMonths={2} onKeyDown={this.onKeyDown} />
                        <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-icon" onClick={(event) => this.onGetReportClick(event)}>
                            Get Report <i className="fas fa-redo-alt dgdg-icon" />
                        </button>
                        <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-icon" onClick={(event) => this.onDownloadClick(event)}>
                            Download <i className="far fa-download dgdg-icon" />
                        </button>
                        <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
                    </div>
                </div>
            </div>
        </div>;
    }
}
