import React, { Fragment } from "react";

import moment from "moment";
import $ from "jquery";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormTextAreaComponent } from "../common/dgdgtextarea/DGDGFormTextAreaComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

import { MissingTitlesDetailQueryComponent } from "./MissingTitlesDetailQueryComponent";
import { MissingTitlesEditComponent } from "./MissingTitlesEditComponent";

export class MissingTitlesDetailComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            paintTable: false,
            selectedStore: null,
            selectedTitleStatus: null,
            selectedRow: null,
            tableTitle: "",
            tableData: [],
            showCloseDialog: false,
            showOpenDialog: false,
        };

        this.onStoreClick = this.onStoreClick.bind(this);
        this.onActionColumnCallback = this.onActionColumnCallback.bind(this);
        this.onActionEditClick = this.onActionEditClick.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);

        this.onIssueColumnCallback = this.onIssueColumnCallback.bind(this);
        this.onAddCommentColumnCallback = this.onAddCommentColumnCallback.bind(this);
        this.onToggleViewComments = this.onToggleViewComments.bind(this);
        this.onToggleAddComments = this.onToggleAddComments.bind(this);
        this.onCommentTextChange = this.onCommentTextChange.bind(this);
        this.onSaveCommentClick = this.onSaveCommentClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    pageTitle = "Missing Titles"

    legendText = <Fragment>
        <div className="d-inline-block dgdg-missing-title-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-missing-title-sold-color" />&nbsp;&nbsp;&nbsp;Sold Without Title</div>
    </Fragment>;

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/MissingTitles/Detail", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("MissingTitlesDetailComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStoreClick(selectedStore) {
        try {
            this.setState({
                selectedStore: selectedStore
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onActionColumnCallback(selectedRow) {
        return <Fragment>
            <button type="button" className="btn btn-link" onClick={(event) => this.onActionEditClick(event, selectedRow)} disabled={!selectedRow.edit_missing_title}>
                <i className="fas fa-edit" />
            </button>
        </Fragment >
    }

    onActionEditClick(event, selectedRow) {
        try {
            this.setState({
                selectedRow: selectedRow
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(selectedStore, selectedTitleStatus, id) {
        try {
            this.setState({
                isLoadingData: true,
                selectedStore: selectedStore,
                selectedTitleStatus: selectedTitleStatus,
            });
            let inputData = {
                id: id,
                storeNumber: selectedStore === "All Stores" ? null : selectedStore.store_number,
                titleStatus: selectedTitleStatus.key
            };
            this.props.applicationInsights.trackTrace({ message: "/MissingTitles/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/MissingTitles/GetReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.forEach(responseItem => {
                        responseItem.age_sort = responseItem.age;
                        responseItem.age = responseItem.age ? responseItem.age + " Days" : null;
                        responseItem.deal_number_date = (responseItem.deal_number ? responseItem.deal_number : "") + (responseItem.contract_date ? " (" + CommonService.formatDate(moment(responseItem.contract_date)) + ")" : "");
                        responseItem.rowCssName = responseItem.deal_number != null && responseItem.contract_date != null ? "dgdg-missing-title-sold-color" : "";
                    });
                    let tableData = parsedResponse;
                    this.setState({
                        isLoadingData: false,
                        tableData: CommonService.addOrdinalColumn(tableData),
                        tableTitle: selectedStore.display_name,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableData: [],
                        tableTitle: "",
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/MissingTitles/onGetReportClick", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event, missingTitle) {
        try {
            let inputData = missingTitle;

            this.props.applicationInsights.trackTrace({ message: "/MissingTitles/onSaveClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/MissingTitles/SaveIssue", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.onGetReportClick(this.state.selectedStore, this.state.selectedTitleStatus);
                    this.props.onShowAlert("success", { message: "Missing Title saved" }, 4000);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/MissingTitles/SaveIssue", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                selectedRow: null
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onIssueColumnCallback(rowData) {
        return rowData.issue
            ? <Fragment>
                <span className="font-weight-bold">Issue: </span>{rowData.issue}
            </Fragment>
            : null
    }

    onAddCommentColumnCallback(rowData) {
        let comments = this.getComments(rowData.comment);

        return <Fragment>
            <button key="btnViewComments" type="button" className="btn btn-link" onClick={(event) => this.onToggleViewComments(event, rowData)}>{comments.length / 2} Comments
                {
                    rowData.showViewComments
                        ? <span className="far fa-sort-up dgdg-icon" />
                        : rowData.showViewComments === undefined
                            ? <span className="far fa-sort-up dgdg-icon" >{this.onToggleViewComments(null, rowData)}</span>
                            : <span className="far fa-sort-down dgdg-icon" />
                }
            </button>
            <button key="btnAddComment" type="button" className="btn btn-link dgdg-icon" onClick={(event) => this.onToggleAddComments(event, rowData)}>Add Comments
                {
                    rowData.showAddComment
                        ? <span className="fas fa-times text-danger dgdg-icon" />
                        : <span className="fas fa-plus dgdg-icon" />
                }
            </button>
            <br />
            {
                rowData.showViewComments
                    ? comments
                    : null
            }
            {
                rowData.showAddComment
                    ? <div key="frmAddComment" className="form-inline">
                        <DGDGFormTextAreaComponent id={"comments_" + rowData.store_number + "_" + rowData.stock_number} value={rowData["comments_" + rowData.store_number + "_" + rowData.stock_number]} rows="3" onChange={this.onCommentTextChange} colSpan="col-11" controlSpan="col-12" />
                        <button type="button" className="btn btn-link dgdg-font-size-18" onClick={(event) => this.onSaveCommentClick(event, rowData)}>
                            <span className="fas fa-save" />
                        </button>
                        <DGDGSpinnerComponent showSpinner={rowData.comments_showSpinner} />
                    </div>
                    : null
            }
        </Fragment>;
    }

    onToggleViewComments(event, rowData) {
        let tableData = this.state.tableData;
        rowData.showViewComments = rowData.showViewComments ? false : true;
        this.setState({
            tableData: tableData,
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
        });
    }

    onToggleAddComments(event, rowData) {
        let tableData = this.state.tableData;
        rowData.showAddComment = rowData.showAddComment ? false : true;
        this.setState({
            tableData: tableData,
            paintTable: true
        }, () => {
            this.setState({
                paintTable: false
            }, () => {
                if (rowData.showAddComment) {
                    let id = "#comments_" + rowData.store_number + "_" + rowData.stock_number;
                    $(id).focus();
                }
            });
        });
    }

    onCommentTextChange(id, value) {
        try {
            let tableData = this.state.tableData;
            let rowData = tableData.filter((row) => row.store_number === id.split('_')[1] && row.stock_number === id.split('_')[2])[0];
            rowData["comments_" + rowData.store_number + "_" + rowData.stock_number] = value;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveCommentClick(event, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.comments_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            let inputData = {
                storeNumber: rowData.store_number,
                stockNumber: rowData.stock_number,
                comments: rowData["comments_" + rowData.store_number + "_" + rowData.stock_number].replace(/\n|\r/g, " "),
            };
            this.props.applicationInsights.trackTrace({ message: "/MissingTitles/onSaveCommentClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/MissingTitles/SaveComments", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    rowData.comment = moment().format("MM/DD/YYYY hh:mm A") + ", " + this.props.remoteUpn + ": " + rowData["comments_" + rowData.store_number + "_" + rowData.stock_number] + (rowData.comment ? '\r' + rowData.comment : "");
                    rowData["comments_" + rowData.store_number + "_" + rowData.stock_number] = "";
                    rowData.comments_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.comments_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/MissingTitles/onSaveCommentClick", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("MissingTitles", "onCopyTableCallBack");
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getComments(comments) {
        let htmlContent = [];
        if (comments !== null) {
            let commentParts = [];
            comments?.split('\r').forEach(function (value) {
                commentParts.push(value.split(': '));
            });
            commentParts.forEach((commentPart, commentIndex) => {
                if (commentPart[1]) {
                    htmlContent.push(
                        <Fragment>
                            <span key="comment_part1" style={{ "color": "blue" }}>
                                {(commentPart[0] !== "" ? commentPart[0] + ": " : null)}
                            </span>
                            <span key="comment_part2">
                                {(commentPart[1])}
                            </span>
                        </Fragment>);
                    htmlContent.push(<br key={"comment_br_" + commentIndex} />);
                }
            });
        }

        return htmlContent;
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <MissingTitlesEditComponent
                applicationInsights={this.props.applicationInsights}
                showDialog={this.state.selectedRow !== null}
                rowData={this.state.selectedRow}
                onSaveClick={this.onSaveClick}
                onEditClick={this.onEditClick}
                onCancelClick={this.onCancelClick}
            />

            <MissingTitlesDetailQueryComponent {...this.props}
                applicationInsights={this.props.applicationInsights}
                stores={this.props.stores}
                isLoadingData={this.state.isLoadingData}
                onStoreClick={this.onStoreClick}
                onGetReportClick={this.onGetReportClick}
            />

            <div className="dgdg-site-scrollable-content">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} stickyHeader legendText={this.legendText}
                    paintTable={this.state.paintTable} tablePageSize={CommonService.tablePageSize}
                    copyTableConfig={{ "columns": [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <DGDGTableColumnComponent headerText="Action" dataColumnCallback={this.onActionColumnCallback} />
                        <DGDGTableColumnComponent headerText="Title Status" dataColumn="title_status" filterColumn="title_status" sortColumn="title_status" cssName="text-center" />
                        <DGDGTableColumnComponent headerText="Store Name" cssName="text-nowrap" dataColumn="store_web_name" filterColumn="store_web_name" sortColumn="store_number" />
                        <DGDGTableColumnComponent headerText="Stock Number" dataColumn="stock_number" filterColumn="stock_number" sortColumn="stock_number" />
                        <DGDGTableColumnComponent headerText="VIN" dataColumn="vin" filterColumn="vin" sortColumn="vin" />
                        <DGDGTableColumnComponent headerText="YMM" dataColumn="ymm" filterColumn="ymm" sortColumn="ymm" />
                        <DGDGTableColumnComponent headerText="Entry Date" dataColumn="entry_date" sortColumn="entry_date" filterColumn="entry_date" dataType="date" />
                        <DGDGTableColumnComponent headerText="Age" dataColumn="age" filterColumn="age_sort" sortColumn="age_sort" cssName="text-nowrap" />
                        <DGDGTableColumnComponent headerText="Inventory Status" dataColumn="cdk_status" filterColumn="cdk_status" sortColumn="cdk_status" cssName="text-center" />
                        <DGDGTableColumnComponent headerText="Deal Number" dataColumn="deal_number_date" filterColumn="deal_number_date" sortColumn="deal_number_date" />
                        <DGDGTableColumnComponent headerText="Last Updated By" dataColumn="last_updated_by" filterColumn="last_updated_by" sortColumn="last_updated_by" />
                        <DGDGTableColumnComponent headerText="Last Updated Date" dataColumn="updated_date" sortColumn="updated_date" filterColumn="updated_date" dataType="dateTime" />
                        <DGDGTableColumnComponent headerText="Issue" dataColumn="issue" isHidden />
                        <DGDGTableColumnComponent headerText="Comments" dataColumn="comment" isHidden />
                    </DGDGTableRowComponent>
                    <DGDGTableRowComponent cssName="dgdg-no-border-top">
                        <DGDGTableColumnComponent colSpan="3" />
                        <DGDGTableColumnComponent colSpan="3" dataColumnCallback={this.onIssueColumnCallback} />
                        <DGDGTableColumnComponent colSpan="8" dataColumnCallback={this.onAddCommentColumnCallback} />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
        </div>;
    }
}
