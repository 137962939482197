import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { SalesforceUserQueryComponent } from "./SalesforceUserQueryComponent";

export class SalesforceUserRouteComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      paintTable: false,
      isLoadingData: false,
      lastUpdatedDate: null
    }

    this.onLookupClick = this.onLookupClick.bind(this);
    this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
    this.userNameColumnCallback = this.userNameColumnCallback.bind(this);
    this.onActionColumnCallback = this.onActionColumnCallback.bind(this);
    this.onActionDetailCallback = this.onActionDetailCallback.bind(this);
    this.onToggleAction = this.onToggleAction.bind(this);

    props.onBreadCrumbStateChanged({
      pageTitle: this.pageTitle,
      override: true,
      link: {
        name: this.pageTitle,
        action: "add"
      }
    });
  }

  componentDidMount() {
    try {
      this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/SalesforceUser", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
      this.onLookupClick();
      this.getLastUpdatedDate();
      CommonService.clientAzureStorageLog("SalesforceUserRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
    }
    catch (error) {
      console.devError(error, null, this.props.applicationInsights);
    }
  }

  pageTitle = "Salesforce Users";

  getLastUpdatedDate() {
    try {
      this.props.applicationInsights.trackTrace({ message: "/SalesforceUserRouteComponent/getLastUpdatedDate", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
      fetch("/SalesforceUser/GetLastUpdated")
        .then(response => { if (response.ok) { return response.json() } else { throw response; } })
        .then(parsedResponse => {
          let lastUpdatedDate = "";
          if (CommonService.isDateValid(parsedResponse))
            lastUpdatedDate = moment.utc(parsedResponse);
          this.setState({ lastUpdatedDate: lastUpdatedDate });
        })
        .catch(notOKResponse => {
          if (notOKResponse.status === 500) {
            notOKResponse.json()
              .then(parsedError => {
                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SalesforceUser/GetLastUpdated", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                this.props.onShowAlert("danger", parsedError);
              })
              .catch(jsonParseError => {
                console.devError(jsonParseError, null, this.props.applicationInsights);
              });
          }
        });
    }
    catch (error) {
      console.devError(error, null, this.props.applicationInsights);
    }
  }

  userNameColumnCallback(rowData) {
    return <a href={"https://delgrandedealergroup.lightning.force.com/lightning/r/User/" + rowData.Id + "/view"} target="_blank" rel="noreferrer"> {rowData.Username}</a>;
  }

  onLookupClick(id, value) {
    try {
      let inputData = {
        filterValue: value,
      };
      this.setState({ isLoadingData: true });
      this.props.applicationInsights.trackTrace({ message: "/SalesforceUserRouteComponent/onLookupClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
      fetch("/SalesforceUser/GetUsers", {
        method: "POST",
        headers: {
          'Content-Type': "application/json; charset=utf-8;"
        },
        body: JSON.stringify(inputData)
      })
        .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
        .then(parsedResponse => {
          parsedResponse.forEach((responseItem, index) => {
            responseItem.PermissionSetAssignments = JSON.parse(responseItem.PermissionSetAssignments)
          });
          this.setState({
            tableData: CommonService.addOrdinalColumn(parsedResponse),
            paintTable: true,
            isLoadingData: false
          }, () => {
            this.setState({ paintTable: false });
          });
        })
        .catch(notOKResponse => {
          this.setState({
            isLoadingData: false,
            tableData: [],
            paintTable: true
          }, () => {
            this.setState({ paintTable: false });
          });
          if (notOKResponse.status === 500) {
            notOKResponse.json()
              .then(parsedError => {
                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SalesforceUser/GetUsers", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                this.props.onShowAlert("danger", parsedError);
              })
              .catch(jsonParseError => {
                console.devError(jsonParseError, null, this.props.applicationInsights);
              });
          }
        });
    }
    catch (error) {
      console.devError(error, null, this.props.applicationInsights);
    }
  }

  onToggleAction(event, rowData) {
    rowData.showProfile = rowData.showProfile ? false : true;
    this.setState({
      paintTable: true
    }, () => {
      this.setState({ paintTable: false });
    });
  }

  onActionColumnCallback(rowData) {
    <i class="fa-solid fa-chevrons-down"></i>
    return <button key="btnViewDetails" type="button" className="btn btn-link" onClick={(event) => this.onToggleAction(event, rowData)}>
      {
        rowData.showProfile
          ? <span className="fa-solid fa-chevrons-up" />
          : <span className="fa-solid fa-chevrons-down" />
      }
    </button>;
  }

  onActionDetailCallback(rowData) {
    let detailElement = [];
    let permissionSetName = "";
    rowData.PermissionSetAssignments?.forEach((permissionSet, index) => {
      permissionSetName += permissionSetName === "" ? permissionSet.PermissionSetName : ", " + permissionSet.PermissionSetName;
    })

    if (rowData.showProfile) {
      detailElement = <table className="dgdg-salesforce-user-detail">
        <tbody>
          <tr>
            <td colSpan="9" className="font-weight-bold dgdg-salesforce-user-detail-header">Permission Sets</td>
          </tr>
          <tr className="dgdg-salesforce-user-detail">
            <td className="font-weight-bold" style={{ "width": "35px" }}>Name:</td>
            <td>{permissionSetName}</td>
          </tr>
        </tbody>
      </table>
    }
    return detailElement;
  }

  onCopyTableCallBack(event) {
    try {
      this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
      CommonService.clientAzureStorageLog("SalesforceUserRouteComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
    }
    catch (error) {
      console.devError(error, null, this.props.applicationInsights);
    }
  }

  render() {
    return <DGDGPageComponent bodyCssName="dgdg-flex dgdg-flex-column">
      <div className="mt-auto">
        <span className="float-right badge badge-primary dgdg-badge">
          Last Updated Date: {this.state.lastUpdatedDate ? CommonService.formatDateTimeZone(this.state.lastUpdatedDate) : ""}
        </span>
      </div>
      <div className="dgdg-flex-item dgdg-site-content">
        <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
          <SalesforceUserQueryComponent
            applicationInsights={this.props.applicationInsights}
            isLoadingData={this.state.isLoadingData}
            onLookupClick={this.onLookupClick}
          />
          <div className="dgdg-site-scrollable-content">
            <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText="Salesforce Users" tableData={this.state.tableData} paintTable={this.state.paintTable}
              copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8], onCopyTableCallBack: this.onCopyTableCallBack }}
            >
              <DGDGTableRowComponent>
                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} cssName="dgdg-directory-bg-white" />
                <DGDGTableColumnComponent headerText="Action" dataColumnCallback={this.onActionColumnCallback} cssName="text-center" dataColumn="showSpinner" showSpinner />
                <DGDGTableColumnComponent headerText="Name" dataColumn="Name" sortColumn="Name" filterColumn="Name" />
                <DGDGTableColumnComponent headerText="User Name" dataColumnCallback={this.userNameColumnCallback} dataColumn="Username" sortColumn="Username" filterColumn="Username" />
                <DGDGTableColumnComponent headerText="Email" dataColumn="Email" sortColumn="Email" filterColumn="Email" />
                <DGDGTableColumnComponent headerText="Federation Id" dataColumn="FederationIdentifier" sortColumn="FederationIdentifier" filterColumn="FederationIdentifier" />
                <DGDGTableColumnComponent headerText="Active" dataColumn="IsActive" sortColumn="IsActive" filterColumn="IsActive" dataType="bool" />
                <DGDGTableColumnComponent headerText="Profile Name" dataColumn="ProfileName" sortColumn="ProfileName" filterColumn="ProfileName" />
                <DGDGTableColumnComponent headerText="User License" dataColumn="UserLicense" sortColumn="UserLicense" filterColumn="UserLicense" />
              </DGDGTableRowComponent>
              <DGDGTableRowComponent>
                <DGDGTableColumnComponent colSpan="9" cssName="dgdg-no-padding" dataColumnCallback={this.onActionDetailCallback} />
              </DGDGTableRowComponent>
            </DGDGTableV3Component>
          </div>
        </div>
      </div>
    </DGDGPageComponent>;
  }
}
