import React from "react";

import { FSCommon } from "./FSCommon";
import { FinancialStatementValueCellComponent } from "./FinancialStatementValueCellComponent";

export class ExpenseBreakdownComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    rowNumber = 1;

    expenseBreakdownRowByKey(rowCssName, lineNumber, label, lc, ld, showDetail, enableDetailAmountClick) {
        return <tr className={rowCssName}>
            <td className="text-center font-weight-normal dgdg-fs-bg-sl-no-col">{lineNumber}</td>
            <td>{label}</td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent applicationInsights={this.props.applicationInsights} dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~" + lc + "~New~" + ld} onFinancialStatementDetailClick={showDetail ? this.props.onFinancialStatementDetailClick : null} enableDetailAmountClick={enableDetailAmountClick} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent applicationInsights={this.props.applicationInsights} dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~Y~" + lc + "~New~" + ld} onFinancialStatementDetailClick={showDetail ? this.props.onFinancialStatementDetailClick : null} enableDetailAmountClick={enableDetailAmountClick} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent applicationInsights={this.props.applicationInsights} dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~" + lc + "~Used~" + ld} onFinancialStatementDetailClick={showDetail ? this.props.onFinancialStatementDetailClick : null} enableDetailAmountClick={enableDetailAmountClick} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent applicationInsights={this.props.applicationInsights} dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~Y~" + lc + "~Used~" + ld} onFinancialStatementDetailClick={showDetail ? this.props.onFinancialStatementDetailClick : null} enableDetailAmountClick={enableDetailAmountClick} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent applicationInsights={this.props.applicationInsights} dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~" + lc + "~Service~" + ld} onFinancialStatementDetailClick={showDetail ? this.props.onFinancialStatementDetailClick : null} enableDetailAmountClick={enableDetailAmountClick} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent applicationInsights={this.props.applicationInsights} dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~Y~" + lc + "~Service~" + ld} onFinancialStatementDetailClick={showDetail ? this.props.onFinancialStatementDetailClick : null} enableDetailAmountClick={enableDetailAmountClick} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent applicationInsights={this.props.applicationInsights} dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~" + lc + "~Parts~" + ld} onFinancialStatementDetailClick={showDetail ? this.props.onFinancialStatementDetailClick : null} enableDetailAmountClick={enableDetailAmountClick} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent applicationInsights={this.props.applicationInsights} dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~Y~" + lc + "~Parts~" + ld} onFinancialStatementDetailClick={showDetail ? this.props.onFinancialStatementDetailClick : null} enableDetailAmountClick={enableDetailAmountClick} />
            </td>
        </tr>;
    }

    expenseBreakdownRowByValue(rowCssName, lineNumber, label, mNewMTD, yNewYTD, mUsedMTD, yUsedYTD, mServiceMTD, yServiceYTD, mPartsMTD, yPartsYTD) {
        return <tr className={rowCssName}>
            <td className="text-center font-weight-normal dgdg-fs-bg-sl-no-col">{lineNumber}</td>
            <td>{label}</td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={mNewMTD} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={yNewYTD} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={mUsedMTD} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={yUsedYTD} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={mServiceMTD} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={yServiceYTD} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={mPartsMTD} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={yPartsYTD} />
            </td>
        </tr>;
    }

    render() {
        let placeHolderRows = [];
        for (let index = 0; index < 20; index++) {
            this.rowNumber++;
            placeHolderRows.push(<tr key={"pH-" + index}>
                <td className="dgdg-no-border-left">
                    {this.rowNumber}
                </td>
                <td className="dgdg-no-border-right">
                    {this.rowNumber}
                </td>
            </tr>);
        }

        this.rowNumber = 1;
        let mNewMTDTotalSellingExpense = null, yNewYTDTotalSellingExpense = null, mUsedMTDTotalSellingExpense = null, yUsedYTDTotalSellingExpense = null, mServiceMTDTotalSellingExpense = null, yServiceYTDTotalSellingExpense = null, mPartsMTDTotalSellingExpense = null, yPartsYTDTotalSellingExpense = null;
        let mNewMTDTotalPersonnelExpense = null, yNewYTDTotalPersonnelExpense = null, mUsedMTDTotalPersonnelExpense = null, yUsedYTDTotalPersonnelExpense = null, mServiceMTDTotalPersonnelExpense = null, yServiceYTDTotalPersonnelExpense = null, mPartsMTDTotalPersonnelExpense = null, yPartsYTDTotalPersonnelExpense = null;
        let mNewMTDTotalSemiFixedExpense = null, yNewYTDTotalSemiFixedExpense = null, mUsedMTDTotalSemiFixedExpense = null, yUsedYTDTotalSemiFixedExpense = null, mServiceMTDTotalSemiFixedExpense = null, yServiceYTDTotalSemiFixedExpense = null, mPartsMTDTotalSemiFixedExpense = null, yPartsYTDTotalSemiFixedExpense = null;

        let newUsedGrossTotal = null;
        let servicePartsGrossTotal = null;
        let newUsedFinanceGross = null;
        let newUsedCompensationProductSpecialists = null;
        let newUsedCompensationFinance = null;
        let newUsedGuestExperienceAdjustments = null;
        let servicePartsGuestExperienceAdjustments = null;
        let mTtlVhcCnt = null, mTtlFinCnt = null;

        let advertisingVehicleRetailed = null, serviceAdvisorCompensationGross = null;
        let totalPC = null, totalPCManagers = null, totalPCProductSpecialists = null, totalPCFinanceManagers = null, totalPCTechnicians = null, totalPCServiceAdvisors = null, totalPCFixedSupportStaff = null, totalPCDetailers = null;
        let totalPCNew = null, totalPCUsed = null, totalPCService = null, totalPCParts = null;

        if (this.props.fsDict) {
            mNewMTDTotalSellingExpense = FSCommon.sumByKey(this.props.fsDict, "M", "Expenses", "New",
                ["Compensation - Product Specialists", "F&I Compensation", "Floor Plan Interest", "Floor Plan (Allowances)", "Demo Expense", "Advertising", "Advertising - Digital", "Advertising - Other", "Advertising (Allowances)", "Guest Experience Adjustments", "P.D.I & Vehicle Maintenance Expense"]);
            yNewYTDTotalSellingExpense = FSCommon.sumByKey(this.props.fsDict, "Y", "Expenses", "New",
                ["Compensation - Product Specialists", "F&I Compensation", "Floor Plan Interest", "Floor Plan (Allowances)", "Demo Expense", "Advertising", "Advertising - Digital", "Advertising - Other", "Advertising (Allowances)", "Guest Experience Adjustments", "P.D.I & Vehicle Maintenance Expense"]);
            mUsedMTDTotalSellingExpense = FSCommon.sumByKey(this.props.fsDict, "M", "Expenses", "Used",
                ["Compensation - Product Specialists", "F&I Compensation", "Floor Plan Interest", "Floor Plan (Allowances)", "Demo Expense", "Advertising", "Advertising - Digital", "Advertising - Other", "Advertising (Allowances)", "Guest Experience Adjustments", "P.D.I & Vehicle Maintenance Expense"]);
            yUsedYTDTotalSellingExpense = FSCommon.sumByKey(this.props.fsDict, "Y", "Expenses", "Used",
                ["Compensation - Product Specialists", "F&I Compensation", "Floor Plan Interest", "Floor Plan (Allowances)", "Demo Expense", "Advertising", "Advertising - Digital", "Advertising - Other", "Advertising (Allowances)", "Guest Experience Adjustments", "P.D.I & Vehicle Maintenance Expense"]);
            mServiceMTDTotalSellingExpense = FSCommon.sumByKey(this.props.fsDict, "M", "Expenses", "Service",
                ["Compensation - Product Specialists", "F&I Compensation", "Floor Plan Interest", "Floor Plan (Allowances)", "Demo Expense", "Advertising", "Advertising - Digital", "Advertising - Other", "Advertising (Allowances)", "Guest Experience Adjustments", "P.D.I & Vehicle Maintenance Expense"]);
            yServiceYTDTotalSellingExpense = FSCommon.sumByKey(this.props.fsDict, "Y", "Expenses", "Service",
                ["Compensation - Product Specialists", "F&I Compensation", "Floor Plan Interest", "Floor Plan (Allowances)", "Demo Expense", "Advertising", "Advertising - Digital", "Advertising - Other", "Advertising (Allowances)", "Guest Experience Adjustments", "P.D.I & Vehicle Maintenance Expense"]);
            mPartsMTDTotalSellingExpense = FSCommon.sumByKey(this.props.fsDict, "M", "Expenses", "Parts",
                ["Compensation - Product Specialists", "F&I Compensation", "Floor Plan Interest", "Floor Plan (Allowances)", "Demo Expense", "Advertising", "Advertising - Digital", "Advertising - Other", "Advertising (Allowances)", "Guest Experience Adjustments", "P.D.I & Vehicle Maintenance Expense"]);
            yPartsYTDTotalSellingExpense = FSCommon.sumByKey(this.props.fsDict, "Y", "Expenses", "Parts",
                ["Compensation - Product Specialists", "F&I Compensation", "Floor Plan Interest", "Floor Plan (Allowances)", "Demo Expense", "Advertising", "Advertising - Digital", "Advertising - Other", "Advertising (Allowances)", "Guest Experience Adjustments", "P.D.I & Vehicle Maintenance Expense"]);

            mNewMTDTotalPersonnelExpense = FSCommon.sumByKey(this.props.fsDict, "M", "Expenses", "New",
                ["Salaries - Supervision", "Service Advisor Compensation", "Salaries - Clerical", "Other Salaries and Wages", "Absentee Wages - Productive Personnel", "Taxes - Payroll", "Employee Benefits", "Workman's Compensation", "Pension Fund"]);
            yNewYTDTotalPersonnelExpense = FSCommon.sumByKey(this.props.fsDict, "Y", "Expenses", "New",
                ["Salaries - Supervision", "Service Advisor Compensation", "Salaries - Clerical", "Other Salaries and Wages", "Absentee Wages - Productive Personnel", "Taxes - Payroll", "Employee Benefits", "Workman's Compensation", "Pension Fund"]);
            mUsedMTDTotalPersonnelExpense = FSCommon.sumByKey(this.props.fsDict, "M", "Expenses", "Used",
                ["Salaries - Supervision", "Service Advisor Compensation", "Salaries - Clerical", "Other Salaries and Wages", "Absentee Wages - Productive Personnel", "Taxes - Payroll", "Employee Benefits", "Workman's Compensation", "Pension Fund"]);
            yUsedYTDTotalPersonnelExpense = FSCommon.sumByKey(this.props.fsDict, "Y", "Expenses", "Used",
                ["Salaries - Supervision", "Service Advisor Compensation", "Salaries - Clerical", "Other Salaries and Wages", "Absentee Wages - Productive Personnel", "Taxes - Payroll", "Employee Benefits", "Workman's Compensation", "Pension Fund"]);
            mServiceMTDTotalPersonnelExpense = FSCommon.sumByKey(this.props.fsDict, "M", "Expenses", "Service",
                ["Salaries - Supervision", "Service Advisor Compensation", "Salaries - Clerical", "Other Salaries and Wages", "Absentee Wages - Productive Personnel", "Taxes - Payroll", "Employee Benefits", "Workman's Compensation", "Pension Fund"]);
            yServiceYTDTotalPersonnelExpense = FSCommon.sumByKey(this.props.fsDict, "Y", "Expenses", "Service",
                ["Salaries - Supervision", "Service Advisor Compensation", "Salaries - Clerical", "Other Salaries and Wages", "Absentee Wages - Productive Personnel", "Taxes - Payroll", "Employee Benefits", "Workman's Compensation", "Pension Fund"]);
            mPartsMTDTotalPersonnelExpense = FSCommon.sumByKey(this.props.fsDict, "M", "Expenses", "Parts",
                ["Salaries - Supervision", "Service Advisor Compensation", "Salaries - Clerical", "Other Salaries and Wages", "Absentee Wages - Productive Personnel", "Taxes - Payroll", "Employee Benefits", "Workman's Compensation", "Pension Fund"]);
            yPartsYTDTotalPersonnelExpense = FSCommon.sumByKey(this.props.fsDict, "Y", "Expenses", "Parts",
                ["Salaries - Supervision", "Service Advisor Compensation", "Salaries - Clerical", "Other Salaries and Wages", "Absentee Wages - Productive Personnel", "Taxes - Payroll", "Employee Benefits", "Workman's Compensation", "Pension Fund"]);

            mNewMTDTotalSemiFixedExpense = FSCommon.sumByKey(this.props.fsDict, "M", "Expenses", "New",
                ["Company Car Expense", "Office Supplies & Expenses", "Small Tools & Other Supplies", "Uniform & Laundry", "Contributions", "Outside Services", "Travel and Entertainment", "Memberships, Dues and Publications", "Legal and Auditing Expense", "Telephone and FAX", "Training Expense", "Data Processing Expense", "Invalid Claim Expense", "Freight & Express", "Miscellaneous"]);
            yNewYTDTotalSemiFixedExpense = FSCommon.sumByKey(this.props.fsDict, "Y", "Expenses", "New",
                ["Company Car Expense", "Office Supplies & Expenses", "Small Tools & Other Supplies", "Uniform & Laundry", "Contributions", "Outside Services", "Travel and Entertainment", "Memberships, Dues and Publications", "Legal and Auditing Expense", "Telephone and FAX", "Training Expense", "Data Processing Expense", "Invalid Claim Expense", "Freight & Express", "Miscellaneous"]);
            mUsedMTDTotalSemiFixedExpense = FSCommon.sumByKey(this.props.fsDict, "M", "Expenses", "Used",
                ["Company Car Expense", "Office Supplies & Expenses", "Small Tools & Other Supplies", "Uniform & Laundry", "Contributions", "Outside Services", "Travel and Entertainment", "Memberships, Dues and Publications", "Legal and Auditing Expense", "Telephone and FAX", "Training Expense", "Data Processing Expense", "Invalid Claim Expense", "Freight & Express", "Miscellaneous"]);
            yUsedYTDTotalSemiFixedExpense = FSCommon.sumByKey(this.props.fsDict, "Y", "Expenses", "Used",
                ["Company Car Expense", "Office Supplies & Expenses", "Small Tools & Other Supplies", "Uniform & Laundry", "Contributions", "Outside Services", "Travel and Entertainment", "Memberships, Dues and Publications", "Legal and Auditing Expense", "Telephone and FAX", "Training Expense", "Data Processing Expense", "Invalid Claim Expense", "Freight & Express", "Miscellaneous"]);
            mServiceMTDTotalSemiFixedExpense = FSCommon.sumByKey(this.props.fsDict, "M", "Expenses", "Service",
                ["Company Car Expense", "Office Supplies & Expenses", "Small Tools & Other Supplies", "Uniform & Laundry", "Contributions", "Outside Services", "Travel and Entertainment", "Memberships, Dues and Publications", "Legal and Auditing Expense", "Telephone and FAX", "Training Expense", "Data Processing Expense", "Invalid Claim Expense", "Freight & Express", "Miscellaneous"]);
            yServiceYTDTotalSemiFixedExpense = FSCommon.sumByKey(this.props.fsDict, "Y", "Expenses", "Service",
                ["Company Car Expense", "Office Supplies & Expenses", "Small Tools & Other Supplies", "Uniform & Laundry", "Contributions", "Outside Services", "Travel and Entertainment", "Memberships, Dues and Publications", "Legal and Auditing Expense", "Telephone and FAX", "Training Expense", "Data Processing Expense", "Invalid Claim Expense", "Freight & Express", "Miscellaneous"]);
            mPartsMTDTotalSemiFixedExpense = FSCommon.sumByKey(this.props.fsDict, "M", "Expenses", "Parts",
                ["Company Car Expense", "Office Supplies & Expenses", "Small Tools & Other Supplies", "Uniform & Laundry", "Contributions", "Outside Services", "Travel and Entertainment", "Memberships, Dues and Publications", "Legal and Auditing Expense", "Telephone and FAX", "Training Expense", "Data Processing Expense", "Invalid Claim Expense", "Freight & Express", "Miscellaneous"]);
            yPartsYTDTotalSemiFixedExpense = FSCommon.sumByKey(this.props.fsDict, "Y", "Expenses", "Parts",
                ["Company Car Expense", "Office Supplies & Expenses", "Small Tools & Other Supplies", "Uniform & Laundry", "Contributions", "Outside Services", "Travel and Entertainment", "Memberships, Dues and Publications", "Legal and Auditing Expense", "Telephone and FAX", "Training Expense", "Data Processing Expense", "Invalid Claim Expense", "Freight & Express", "Miscellaneous"]);

            newUsedGrossTotal = FSCommon.parseDecimal(this.props.fsDict, "1~M~Income~New~Gross Profit");
            newUsedGrossTotal += FSCommon.parseDecimal(this.props.fsDict, "1~M~Income~Used~Gross Profit");

            servicePartsGrossTotal = FSCommon.parseDecimal(this.props.fsDict, "1~M~Income~Service~Gross Profit");
            servicePartsGrossTotal += FSCommon.parseDecimal(this.props.fsDict, "1~M~Income~Parts~Gross Profit");

            newUsedFinanceGross = FSCommon.parseDecimal(this.props.fsDict, "1~M~New Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total New F&I");
            newUsedFinanceGross += FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Used F&I");

            newUsedCompensationProductSpecialists = FSCommon.parseDecimal(this.props.fsDict, "1~M~Expenses~New~Compensation - Product Specialists");
            newUsedCompensationProductSpecialists += FSCommon.parseDecimal(this.props.fsDict, "1~M~Expenses~Used~Compensation - Product Specialists");

            newUsedCompensationFinance = FSCommon.parseDecimal(this.props.fsDict, "1~M~Expenses~New~F&I Compensation");
            newUsedCompensationFinance += FSCommon.parseDecimal(this.props.fsDict, "1~M~Expenses~Used~F&I Compensation");

            newUsedGuestExperienceAdjustments = FSCommon.parseDecimal(this.props.fsDict, "1~M~Expenses~New~Guest Experience Adjustments");
            newUsedGuestExperienceAdjustments += FSCommon.parseDecimal(this.props.fsDict, "1~M~Expenses~Used~Guest Experience Adjustments");

            servicePartsGuestExperienceAdjustments = FSCommon.parseDecimal(this.props.fsDict, "1~M~Expenses~Service~Guest Experience Adjustments");
            servicePartsGuestExperienceAdjustments += FSCommon.parseDecimal(this.props.fsDict, "1~M~Expenses~Parts~Guest Experience Adjustments");

            mTtlVhcCnt = FSCommon.parseDecimal(this.props.fsDict, "1~M~New Stat~New Car Sales~Count Total New Vehicle Dept");
            mTtlFinCnt = FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Stat~Used Car Sales~Count Total Used Vehicle Retail");

            advertisingVehicleRetailed = FSCommon.parseDecimal(this.props.fsDict, "1~M~Expenses~New~Advertising");
            advertisingVehicleRetailed += FSCommon.parseDecimal(this.props.fsDict, "1~M~Expenses~Used~Advertising");
            advertisingVehicleRetailed += FSCommon.parseDecimal(this.props.fsDict, "1~M~Expenses~New~Advertising - Digital");
            advertisingVehicleRetailed += FSCommon.parseDecimal(this.props.fsDict, "1~M~Expenses~Used~Advertising - Digital");
            advertisingVehicleRetailed += FSCommon.parseDecimal(this.props.fsDict, "1~M~Expenses~New~Advertising - Other");
            advertisingVehicleRetailed += FSCommon.parseDecimal(this.props.fsDict, "1~M~Expenses~Used~Advertising - Other");
            advertisingVehicleRetailed = advertisingVehicleRetailed / (mTtlVhcCnt + mTtlFinCnt);

            serviceAdvisorCompensationGross = FSCommon.parseDecimal(this.props.fsDict, "1~M~Expenses~Service~Service Advisor Compensation")
                / FSCommon.parseDecimal(this.props.fsDict, "1~M~Income~Service~Gross Profit");

            totalPCManagers = FSCommon.sumByKey(this.props.fsDict, "M", "Employee Stat", "All",
                ["New Managers", "Used Managers", "Service Managers", "Parts Managers"]);
            totalPCProductSpecialists = FSCommon.sumByKey(this.props.fsDict, "M", "Employee Stat", "All",
                ["Product Specialists"]);
            totalPCFinanceManagers = FSCommon.sumByKey(this.props.fsDict, "M", "Employee Stat", "All",
                ["Finance Managers"]);
            totalPCTechnicians = FSCommon.sumByKey(this.props.fsDict, "M", "Employee Stat", "All",
                ["Technicians"]);
            totalPCServiceAdvisors = FSCommon.sumByKey(this.props.fsDict, "M", "Employee Stat", "All",
                ["Service Advisors"]);
            totalPCFixedSupportStaff = FSCommon.sumByKey(this.props.fsDict, "M", "Employee Stat", "All",
                ["Service Support Staff", "Parts Support Staff"]);
            totalPCDetailers = FSCommon.sumByKey(this.props.fsDict, "M", "Employee Stat", "All",
                ["Detailers"]);

            totalPC = totalPCManagers + totalPCProductSpecialists + totalPCFinanceManagers + totalPCTechnicians + totalPCServiceAdvisors + totalPCFixedSupportStaff + totalPCDetailers
                + FSCommon.parseDecimal(this.props.fsDict, "1~M~Employee Stat~All~Others");

            totalPCNew = FSCommon.sumByKey(this.props.fsDict, "M", "Employee Stat", "All",
                ["New Managers", "Product Specialists", "Finance Managers"]);
            totalPCUsed = FSCommon.sumByKey(this.props.fsDict, "M", "Employee Stat", "All",
                ["Used Managers"]);
            totalPCService = FSCommon.sumByKey(this.props.fsDict, "M", "Employee Stat", "All",
                ["Service Managers", "Technicians", "Service Advisors", "Service Support Staff", "Detailers"]);
            totalPCParts = FSCommon.sumByKey(this.props.fsDict, "M", "Employee Stat", "All",
                ["Parts Managers", "Parts Support Staff"]);
        }

        return <table className="dgdg-fs-table">
            <tbody>
                <tr className="text-center font-weight-bold">
                    <td rowSpan="2" style={{ "width": "35px" }}>LINE</td>
                    <td rowSpan="2">NAME OF ACCOUNT</td>
                    <td colSpan="2">1 - NEW VEHICLE <br /> DEPARTMENT</td>
                    <td colSpan="2">2 - USED VEHICLE <br /> DEPARTMENT</td>
                    <td colSpan="2">3 - SERVICE <br /> DEPARTMENT</td>
                    <td colSpan="2">4 - PARTS AND ACCESS. <br /> DEPARTMENT</td>
                </tr>
                <tr className="text-center">
                    <td>MONTH</td>
                    <td>YR-TO-DATE</td>
                    <td>MONTH</td>
                    <td>YR-TO-DATE</td>
                    <td>MONTH</td>
                    <td>YR-TO-DATE</td>
                    <td>MONTH</td>
                    <td>YR-TO-DATE</td>
                </tr>
                {this.expenseBreakdownRowByKey("font-weight-bold dgdg-fs-heading-1-bg-color", "A", "SALES", "Income", "Net Sales")}
                {this.props.fsDict
                    ? this.expenseBreakdownRowByValue("font-weight-bold dgdg-fs-heading-1-bg-color", "B", "SALES GROSS",
                        this.props.fsDict["1~M~New Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total New Car"] ? this.props.fsDict["1~M~New Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total New Car"][8] : "",
                        this.props.fsDict["1~Y~New Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total New Car"] ? this.props.fsDict["1~Y~New Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total New Car"][8] : "",
                        this.props.fsDict["1~M~Used Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Used Car & Wholesale"] ? this.props.fsDict["1~M~Used Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Used Car & Wholesale"][8] : "",
                        this.props.fsDict["1~Y~Used Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Used Car & Wholesale"] ? this.props.fsDict["1~Y~Used Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Used Car & Wholesale"][8] : "",
                        this.props.fsDict["1~M~Service Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Service"] ? this.props.fsDict["1~M~Service Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Service"][8] : "",
                        this.props.fsDict["1~Y~Service Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Service"] ? this.props.fsDict["1~Y~Service Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Service"][8] : "",
                        this.props.fsDict["1~M~Parts Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Parts"] ? this.props.fsDict["1~M~Parts Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Parts"][8] : "",
                        this.props.fsDict["1~Y~Parts Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Parts"] ? this.props.fsDict["1~Y~Parts Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Parts"][8] : "")
                    : this.expenseBreakdownRowByValue("font-weight-bold dgdg-fs-heading-1-bg-color", "B", "SALES GROSS", "", "", "", "", "", "", "", "")
                }
                {this.props.fsDict
                    ? this.expenseBreakdownRowByValue("font-weight-bold dgdg-fs-heading-1-bg-color", "C", "FINANCE GROSS",
                        this.props.fsDict["1~M~New Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total New F&I"] ? this.props.fsDict["1~M~New Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total New F&I"][8] : "",
                        this.props.fsDict["1~Y~New Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total New F&I"] ? this.props.fsDict["1~Y~New Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total New F&I"][8] : "",
                        this.props.fsDict["1~M~Used Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Used F&I"] ? this.props.fsDict["1~M~Used Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Used F&I"][8] : "",
                        this.props.fsDict["1~Y~Used Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Used F&I"] ? this.props.fsDict["1~Y~Used Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Used F&I"][8] : "",
                        "", "", "", "")
                    : this.expenseBreakdownRowByValue("font-weight-bold dgdg-fs-heading-1-bg-color", "C", "FINANCE GROSS", "", "", "", "", "", "", "", "")
                }
                {this.expenseBreakdownRowByKey("font-weight-bold dgdg-fs-heading-1-bg-color", "D", "TOTAL GROSS", "Income", "Gross Profit")}
                <tr className="font-weight-bold dgdg-fs-heading-2-bg-color">
                    <td className="text-center font-weight-normal dgdg-fs-bg-sl-no-col">{this.rowNumber++}</td>
                    <td colSpan="9">SELLING EXPENSES</td>
                </tr>
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Compensation - Product Specialists", "Expenses", "Compensation - Product Specialists", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Compensation - Finance", "Expenses", "F&I Compensation", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Floor Plan Interest", "Expenses", "Floor Plan Interest", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Floor Plan (Allowances)", "Expenses", "Floor Plan (Allowances)", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Demo Expense", "Expenses", "Demo Expense", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Advertising", "Expenses", "Advertising", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Advertising - Digital", "Expenses", "Advertising - Digital", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Advertising - Other", "Expenses", "Advertising - Other", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Advertising (Allowances)", "Expenses", "Advertising (Allowances)", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Guest Experience Adjustments", "Expenses", "Guest Experience Adjustments", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "P.D.I & Vehicle Maintenance Expense", "Expenses", "P.D.I & Vehicle Maintenance Expense", true, true)}
                {
                    this.expenseBreakdownRowByValue("font-weight-bold dgdg-fs-heading-1-bg-color", this.rowNumber++, "TOTAL SELLING EXPENSE",
                        mNewMTDTotalSellingExpense, yNewYTDTotalSellingExpense,
                        mUsedMTDTotalSellingExpense, yUsedYTDTotalSellingExpense,
                        mServiceMTDTotalSellingExpense, yServiceYTDTotalSellingExpense,
                        mPartsMTDTotalSellingExpense, yPartsYTDTotalSellingExpense)
                }
                {this.expenseBreakdownRowByKey("font-weight-bold dgdg-fs-heading-2-bg-color", this.rowNumber++, "EMPLOYMENT EXPENSES", "", "")}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Salaries - Owners", "", "", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Salaries - Supervision", "Expenses", "Salaries - Supervision", true, this.props.isInSURole)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Salaries - Service Advisors", "Expenses", "Service Advisor Compensation", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Salaries - Clerical", "Expenses", "Salaries - Clerical", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Other Salaries and Wages", "Expenses", "Other Salaries and Wages", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Absentee Wages - Productive Personnel", "Expenses", "Absentee Wages - Productive Personnel", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Taxes - Payroll", "Expenses", "Taxes - Payroll", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Employee Benefits", "Expenses", "Employee Benefits", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Workman's Compensation", "Expenses", "Workman's Compensation", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Pension Fund", "Expenses", "Pension Fund", true, true)}
                {
                    this.expenseBreakdownRowByValue("font-weight-bold dgdg-fs-heading-1-bg-color", this.rowNumber++, "TOTAL PERSONNEL EXPENSE",
                        mNewMTDTotalPersonnelExpense, yNewYTDTotalPersonnelExpense,
                        mUsedMTDTotalPersonnelExpense, yUsedYTDTotalPersonnelExpense,
                        mServiceMTDTotalPersonnelExpense, yServiceYTDTotalPersonnelExpense,
                        mPartsMTDTotalPersonnelExpense, yPartsYTDTotalPersonnelExpense)
                }
                {this.expenseBreakdownRowByKey("font-weight-bold dgdg-fs-heading-2-bg-color", this.rowNumber++, "SEMI-FIXED EXPENSES", "", "")}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Company Car Expense", "Expenses", "Company Car Expense", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Office Supplies & Expenses", "Expenses", "Office Supplies & Expenses", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Small Tools & Other Supplies", "Expenses", "Small Tools & Other Supplies", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Uniform & Laundry", "Expenses", "Uniform & Laundry", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Contributions", "Expenses", "Contributions", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Outside Services", "Expenses", "Outside Services", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Travel and Entertainment", "Expenses", "Travel and Entertainment", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Memberships, Dues and Publications", "Expenses", "Memberships, Dues and Publications", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Legal and Auditing Expense", "Expenses", "Legal and Auditing Expense", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Telephone and Fax", "Expenses", "Telephone and FAX", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Training Expense", "Expenses", "Training Expense", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Data Processing Expense", "Expenses", "Data Processing Expense", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Invalid Claim Expense", "Expenses", "Invalid Claim Expense", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Freight & Express", "Expenses", "Freight & Express", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Miscellaneous", "Expenses", "Miscellaneous", true, true)}
                {
                    this.expenseBreakdownRowByValue("font-weight-bold dgdg-fs-heading-1-bg-color", this.rowNumber++, "TOTAL SEMI-FIXED EXPENSE",
                        mNewMTDTotalSemiFixedExpense, yNewYTDTotalSemiFixedExpense,
                        mUsedMTDTotalSemiFixedExpense, yUsedYTDTotalSemiFixedExpense,
                        mServiceMTDTotalSemiFixedExpense, yServiceYTDTotalSemiFixedExpense,
                        mPartsMTDTotalSemiFixedExpense, yPartsYTDTotalSemiFixedExpense)
                }
                {this.expenseBreakdownRowByKey("font-weight-bold dgdg-fs-heading-2-bg-color", this.rowNumber++, "FIXED EXPENSES", "", "")}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Rent", "Expenses", "Rent", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Amortization - Leaseholds", "Expenses", "Amortization - Leaseholds", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Repairs & Maintenance-Real Estate", "Expenses", "Repairs & Maintenance - Real Estate", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Depreciation - Buildings and Improvements", "Expenses", "Depreciation - Buildings and Improvements", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Taxes - Real Estate", "Expenses", "Taxes - Real Estate", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Insurance Buildings and Improvements", "Expenses", "Insurance - Buildings and Improvements", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Interest Expense", "Expenses", "Interest Real Estate Mortgage", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Heat, Light, Power and Water", "Expenses", "Heat, Light, Power and Water", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Insurance - Other", "Expenses", "Insurance - Other", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Taxes - Other", "Expenses", "Taxes - Other", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Repair & Maintenance - Equipment", "Expenses", "Repair & Maintenance - Equipment", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Depreciation - Equipment", "Expenses", "Depreciation - Equipment", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Equipment Rental", "Expenses", "Equipment Rental", true, true)}
                {this.expenseBreakdownRowByKey("", this.rowNumber++, "Parts Hub Fee", "Expenses", "Parts Hub Fee", true, true)}
                {this.expenseBreakdownRowByKey("font-weight-bold dgdg-fs-heading-1-bg-color", this.rowNumber++, "TOTAL FIXED EXPENSE", "Expenses", "Total Fixed Expense")}
                {this.expenseBreakdownRowByKey("font-weight-bold dgdg-fs-heading-1-bg-color", this.rowNumber++, "TOTAL OPERATING EXPENSE", "Expenses", "Total Operating Expense")}
                {this.expenseBreakdownRowByKey("font-weight-bold dgdg-fs-heading-1-bg-color", this.rowNumber++, "OPERATING NET PROFIT OR (LOSS)", "Net Income", "Operating Profit or Loss")}
                <tr>
                    <td colSpan="10" className="dgdg-no-padding">
                        <table className="dgdg-fs-table" style={{ "lineHeight": "16px" }}>
                            <tbody>
                                <tr>
                                    <td className="dgdg-no-border-left">
                                        {this.rowNumber}
                                    </td>
                                    <td rowSpan="21" className="dgdg-no-padding align-top">
                                        <table className="dgdg-fs-table">
                                            <tbody>
                                                <tr>
                                                    <td colSpan="5" className="dgdg-no-border-left dgdg-no-border-top dgdg-no-border-right dgdg-fs-bg-black">&nbsp;</td>
                                                </tr>
                                                <tr className="dgdg-fs-heading-2-bg-color">
                                                    <td className="dgdg-no-border-left">GUEST EXPERIENCE</td>
                                                    <td>STORE</td>
                                                    <td className="dgdg-fs-bg-black" />
                                                    <td>DISTRICT</td>
                                                    <td className="dgdg-no-border-right">NATIONAL</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="5" className="dgdg-no-border-left dgdg-no-border-right">Sales Customer Satisfaction Index</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="5" className="dgdg-no-border-left dgdg-no-border-right">Service Customer Satisfaction Index</td>
                                                </tr>
                                                <tr className="dgdg-fs-heading-2-bg-color">
                                                    <td colSpan="2" className="dgdg-no-border-left">SALES STATISTICS</td>
                                                    <td colSpan="3" className="dgdg-no-border-right">MTD</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="dgdg-no-border-left dgdg-no-border-right">Sales Compensation - % of Gross</td>
                                                    <td colSpan="3" className="dgdg-no-border-left dgdg-no-border-right dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="percent_2" mode={this.props.mode} value={newUsedCompensationProductSpecialists / newUsedGrossTotal} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="dgdg-no-border-left dgdg-no-border-right">Per Vehicle Retailed Sales Compensation</td>
                                                    <td colSpan="3" className="dgdg-no-border-left dgdg-no-border-right dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="number" mode={this.props.mode} value={newUsedCompensationProductSpecialists / (mTtlVhcCnt + mTtlFinCnt)} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="5" className="dgdg-no-border-left dgdg-no-border-right">Average Sales Count By Product Specialists</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="5" className="dgdg-no-border-left dgdg-no-border-right">Sales Manager Compensation - % of Gross</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="5" className="dgdg-no-border-left dgdg-no-border-right">Average Number of Deals By Sales Manager</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="dgdg-no-border-left dgdg-no-border-right">Finance Compensation - % of Finance Gross</td>
                                                    <td colSpan="3" className="dgdg-no-border-left dgdg-no-border-right dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="percent_2" mode={this.props.mode} value={newUsedCompensationFinance / newUsedFinanceGross} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="dgdg-no-border-left dgdg-no-border-right">Advertising Per Vehicle Retailed</td>
                                                    <td colSpan="3" className="dgdg-no-border-left dgdg-no-border-right dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="number" mode={this.props.mode} value={advertisingVehicleRetailed} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="dgdg-no-border-left dgdg-no-border-right">Service Advisor Compensation - % of Gross</td>
                                                    <td colSpan="3" className="dgdg-no-border-left dgdg-no-border-right dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="percent_2" mode={this.props.mode} value={serviceAdvisorCompensationGross} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="dgdg-no-border-left dgdg-no-border-right">Guest Experience Adjustments Variable - % of Gross</td>
                                                    <td colSpan="3" className="dgdg-no-border-left dgdg-no-border-right dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="percent_2" mode={this.props.mode} value={newUsedGuestExperienceAdjustments / newUsedGrossTotal} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="dgdg-no-border-left dgdg-no-border-right">Guest Experience Adjustments Fixed - % of Gross</td>
                                                    <td colSpan="3" className="dgdg-no-border-left dgdg-no-border-right dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="percent_2" mode={this.props.mode} value={servicePartsGuestExperienceAdjustments / servicePartsGrossTotal} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="dgdg-no-border-left dgdg-no-border-bottom">&nbsp;</td>
                                                    <td rowSpan="6" className="dgdg-fs-bg-black" />
                                                    <td rowSpan="6" colSpan="2" className="dgdg-no-border-right" />
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="dgdg-no-border-left dgdg-no-border-top dgdg-no-border-bottom">&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="dgdg-no-border-left dgdg-no-border-top dgdg-no-border-bottom">&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="dgdg-no-border-left dgdg-no-border-top dgdg-no-border-bottom">&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="dgdg-no-border-left dgdg-no-border-top dgdg-no-border-bottom">&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="dgdg-no-border-left dgdg-no-border-top">&nbsp;</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td rowSpan="21">
                                        &nbsp;
                                    </td>
                                    <td rowSpan="21" className="dgdg-no-padding align-top">
                                        <table className="dgdg-fs-table">
                                            <tbody>
                                                <tr>
                                                    <td colSpan="7" className="dgdg-no-border-left dgdg-no-border-top dgdg-no-border-right dgdg-fs-heading-2-bg-color">HOURLY LABOR RATES CHARGED</td>
                                                </tr>
                                                <tr>
                                                    <td className="dgdg-no-border-left">MECH. OEM1</td>
                                                    <td className="dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Employee Stat~All~Customer Hourly Labor Rate"} />
                                                    </td>
                                                    <td rowSpan="3" className="dgdg-fs-heading-2-bg-color" style={{ "width": "40px" }}>
                                                        <p className="dgdg-text-vertical">CUST</p>
                                                    </td>
                                                    <td className="dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Employee Stat~All~Warranty Hourly Labor Rate"} />
                                                    </td>
                                                    <td rowSpan="3" className="dgdg-no-border-right dgdg-fs-heading-2-bg-color" style={{ "width": "40px" }}>
                                                        <p className="dgdg-text-vertical">INT</p>
                                                    </td>
                                                    <td className="dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Employee Stat~All~Internal Hourly Labor Rate"} />
                                                    </td>
                                                    <td rowSpan="3" className="dgdg-fs-heading-2-bg-color" style={{ "width": "40px" }}>
                                                        <p className="dgdg-text-vertical">WARR</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="dgdg-no-border-left">MECH. OEM2</td>
                                                    <td />
                                                    <td />
                                                    <td />
                                                </tr>
                                                <tr>
                                                    <td className="dgdg-no-border-left">MECH. OEM3</td>
                                                    <td />
                                                    <td />
                                                    <td />
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br style={{ "lineHeight": "19px" }} />
                                        <table className="dgdg-fs-table">
                                            <tbody>
                                                <tr>
                                                    <td colSpan="7" className="dgdg-no-border-left dgdg-no-border-right dgdg-fs-heading-2-bg-color">PERSONNEL COUNT</td>
                                                </tr>
                                                <tr>
                                                    <td className="dgdg-no-border-left">POSITION</td>
                                                    <td>NEW</td>
                                                    <td>USED</td>
                                                    <td>SERV.</td>
                                                    <td>PARTS</td>
                                                    <td>BODY</td>
                                                    <td className="dgdg-no-border-right">TOTAL</td>
                                                </tr>
                                                <tr>
                                                    <td className="dgdg-no-border-left">MANAGERS</td>
                                                    <td className="dgdg-no-border-bottom dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Employee Stat~All~New Managers"} />
                                                    </td>
                                                    <td className="dgdg-no-border-bottom dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Employee Stat~All~Used Managers"} />
                                                    </td>
                                                    <td className="dgdg-no-border-bottom dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Employee Stat~All~Service Managers"} />
                                                    </td>
                                                    <td className="dgdg-no-border-bottom dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Employee Stat~All~Parts Managers"} />
                                                    </td>
                                                    <td className="dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-right dgdg-no-border-bottom dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={totalPCManagers} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="dgdg-no-border-left">PRODUCT SPECIALISTS</td>
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Employee Stat~All~Product Specialists"} />
                                                    </td>
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-right dgdg-no-border-bottom dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={totalPCProductSpecialists} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="dgdg-no-border-left">FINANCE MANAGERS</td>
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Employee Stat~All~Finance Managers"} />
                                                    </td>
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-right dgdg-no-border-bottom dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={totalPCFinanceManagers} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="dgdg-no-border-left">TECHNICIANS</td>
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Employee Stat~All~Technicians"} />
                                                    </td>
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-right dgdg-no-border-bottom dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={totalPCTechnicians} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="dgdg-no-border-left">SERVICE ADVISORS</td>
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Employee Stat~All~Service Advisors"} />
                                                    </td>
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-right dgdg-no-border-bottom dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={totalPCServiceAdvisors} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="dgdg-no-border-left">FIXED SUPPORT STAFF</td>
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Employee Stat~All~Service Support Staff"} />
                                                    </td>
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Employee Stat~All~Parts Support Staff"} />
                                                    </td>
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-right dgdg-no-border-bottom dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={totalPCFixedSupportStaff} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="dgdg-no-border-left">DETAILERS</td>
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Employee Stat~All~Detailers"} />
                                                    </td>
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-right dgdg-no-border-bottom dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={totalPCDetailers} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="dgdg-no-border-left">OTHER</td>
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-right dgdg-no-border-bottom dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Employee Stat~All~Others"} />
                                                    </td>
                                                </tr>
                                                <tr className="dgdg-no-border-left dgdg-fs-heading-1-bg-color">
                                                    <td className="dgdg-no-border-left">TOTAL</td>
                                                    <td className=" dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={totalPCNew} />
                                                    </td>
                                                    <td className=" dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={totalPCUsed} />
                                                    </td>
                                                    <td className=" dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={totalPCService} />
                                                    </td>
                                                    <td className=" dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={totalPCParts} />
                                                    </td>
                                                    <td />
                                                    <td className="dgdg-no-border-right dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={totalPC} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br style={{ "lineHeight": "20px" }} />
                                        <table className="dgdg-fs-table">
                                            <tbody>
                                                <tr>
                                                    <td rowSpan="4" className="dgdg-no-border-left">
                                                        <p className="dgdg-text-vertical">MEMO</p>
                                                    </td>
                                                    <td className="dgdg-no-border-bottom">HIRED YEAR TO DATE</td>
                                                    <td className="dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-right dgdg-no-border-bottom dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Employee Stat~All~Hired YTD"} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom">TERMINATED YEAR TO DATE</td>
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-right dgdg-no-border-bottom dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Employee Stat~All~Terminated YTD"} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom">TEAM MEMBERS 2+ YEARS</td>
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-bottom" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-right dgdg-no-border-bottom dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Employee Stat~All~Team Members 2+ Years"} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="dgdg-no-border-top">TEAM MEMBERS 5+ YEARS</td>
                                                    <td className="dgdg-no-border-top" />
                                                    <td className="dgdg-no-border-top" />
                                                    <td className="dgdg-no-border-top" />
                                                    <td className="dgdg-no-border-top" />
                                                    <td className="dgdg-no-border-top" />
                                                    <td className="dgdg-no-border-top dgdg-no-border-right dgdg-fs-value">
                                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~Employee Stat~All~Team Members 5+ Years"} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="dgdg-no-border-right">
                                        {this.rowNumber}
                                    </td>
                                </tr>
                                {placeHolderRows}
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>;
    }
}
