import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableColumnDrCrComponent } from "../common/dgdgtable/DGDGTableColumnDrCrComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { ServiceStoreReportMonthAccountDetailComponent } from "./ServiceStoreReportMonthAccountDetailComponent"

export class ServiceStoreReportMonthDetailComponent extends React.Component {

    pageTitle = "Service Store Report";

    constructor(props) {
        super(props);
        this.state = {
        };

        this.onCopyClick = this.onCopyClick.bind(this);
    }

    onCopyClick(event) {
        try {
            let amount = 0;
            let tableText = "OEM\tCategory\tSub Category\tDescription\tAccount Number\tAcct Type\tAccount Description\tType\tAmount\tPCL\r\n";
            this.props.tableData.forEach((row) => {
                amount += CommonService.parseDecimal(row.amt);
                tableText += (row.oem ? row.oem : "") + "\t" + (row.lc ? row.lc : "") + "\t" + (row.lsc ? row.lsc : "") + "\t" +
                    (row.ld ? row.ld : "") + "\t" + (row.an ? row.an : "") + "\t" + (row.at ? row.at : "") + "\t" + (row.ad ? row.ad : "") + "\t" +
                    (row.amt ? CommonService.getAmountType(row.amt) : "") + "\t" + (row.amt ? row.amt : "") + "\t" + (row.pcl ? row.pcl : "") + "\r\n";
            });

            tableText += "Total\t\t\t\t\t\t\t" + CommonService.getAmountType(amount) + "\t" + amount + "\t";
            navigator.clipboard.writeText(tableText);
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("ServiceStoreReportMonthDetailComponent", "onCopyClick", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.props.tableData.length > 0}
            cssName="dgdg-service-store-report-detail-dialog"
            title={this.props.tableTitle}
            onCancelClick={this.props.onCancelClick}
            childModelClosedTime={this.props.childModelClosedTime}
        >
            <DGDGModalDialogHeaderButtonComponent>
                <button className="btn btn-primary" onClick={(event) => this.onCopyClick(event)}>
                    Copy<span className="far fa-copy dgdg-icon" />
                </button>
            </DGDGModalDialogHeaderButtonComponent>
            <div className="dgdg-report-body">
                <ServiceStoreReportMonthAccountDetailComponent
                    applicationInsights={this.props.applicationInsights}
                    accountDetailBlobName={this.props.accountDetailBlobName}
                    allBlobs={this.props.allBlobs}
                    tableData={this.props.detailTableData}
                    paintTable={this.props.paintDetailTable}
                    tableTitle={this.props.detailTableTitle}
                    onCancelClick={this.props.onDetailTotalAmountCancelClick}
                    onShowAlert={this.props.onShowAlert}
                    totalAmount={this.props.totalAmount} />
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} cardCssName="dgdg-full-width" cardBlockCssName="overflow-auto" tableData={this.props.tableData} paintTable={this.props.paintTable} showFooter>
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <DGDGTableColumnComponent headerText="OEM" dataColumn="oem" sortColumn="oem" filterColumn="oem" footerText="Total" />
                        <DGDGTableColumnComponent headerText="Category" dataColumn="lc" sortColumn="lc" filterColumn="lc" />
                        <DGDGTableColumnComponent headerText="Sub Category" dataColumn="lsc" sortColumn="lsc" filterColumn="lsc" />
                        <DGDGTableColumnComponent headerText="Description" dataColumn="ld" sortColumn="ld" filterColumn="ld" />
                        <DGDGTableColumnComponent headerText="Account Number" dataType="string" dataColumn="an" sortColumn="an" filterColumn="an" />
                        <DGDGTableColumnComponent headerText="Acct Type" dataColumn="at" sortColumn="at" filterColumn="at" />
                        <DGDGTableColumnComponent headerText="Account Description" dataColumn="ad" sortColumn="ad" filterColumn="ad" />
                        <DGDGTableColumnDrCrComponent headerText="Type" dataColumn="amt" sortColumn="amt" footerFunction="sum" isDrCrHidden />
                        <DGDGTableColumnComponent headerText="Amount" onCellClick={this.props.onDetailAmountClick} dataColumn="display_amt" sortColumn="display_amt" filterColumn="display_amt" dataType="formatted_number_2" footerFunction="sum" cssName="dgdg-font-underline" footerCssName="text-right" onFooterCellClick={this.props.onDetailTotalAmountClick} columnHelp="Amounts are positive(+) for Sales and negative(-) for COS" />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
            {
                this.props.blobName
                    ? <div className="text-success">
                        {this.props.blobName}
                    </div>
                    : null
            }
        </DGDGModalDialogComponent>;
    }
}
