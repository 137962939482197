import React from "react";

import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

export class FIPayrollEmployeeQueryComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            if (state.showSpinner) {
                newState.showSpinner = props.isLoadingData;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            showSpinner: true
        };
    }

    render() {
        return <div className="card dgdg-card-gutter">
            <div className="card-block">
                <div className="form-inline">
                    <button disabled={this.state.showSpinner} className="btn btn-primary" onClick={(event) => this.props.onAddClick(event)}>
                        Add <span className="fas fa-plus dgdg-icon" />
                    </button>
                    <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
                </div>
            </div>
        </div>;
    }
}
