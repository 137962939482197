import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom"

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";

import { ServiceManagerPayrollPrimaryComponent } from "./ServiceManagerPayrollPrimaryComponent";
import { ServiceManagerPayrollSecondaryComponent } from "./ServiceManagerPayrollSecondaryComponent";
import { PageNotFoundRouteComponent } from "../pagenotfound/PageNotFoundRouteComponent";

export class ServiceManagerPayrollRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            months: CommonService.getMonths(),
            isLoadingData: false,
            lastUpdatedDate: null,
            tableData: [],
            subStoresTableData: []
        };

        this.onGetLastUpdatedDate = this.onGetLastUpdatedDate.bind(this);
        this.onGetPayrollClick = this.onGetPayrollClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    componentDidMount() {
        try {
            CommonService.clientAzureStorageLog("ServiceManagerPayrollRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Service & Parts Manager Payroll";

    onGetLastUpdatedDate(selectedMonth) {
        try {
            let inputData = {
                month: CommonService.formatServerDateTime(selectedMonth)
            };

            this.props.applicationInsights.trackTrace({ message: "ServiceManagerPayrollRouteComponent/onGetLastUpdatedDate", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceManagerPayroll/GetLastUpdated", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let lastUpdatedDate = "";
                    if (CommonService.isDateValid(parsedResponse))
                        lastUpdatedDate = moment.utc(parsedResponse);
                    this.setState({ lastUpdatedDate: lastUpdatedDate });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ServiceManagerPayroll/GetLastUpdated", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetPayrollClick(selectedMonth) {
        try {
            if (selectedMonth !== null) {
                this.setState({
                    isLoadingData: true,
                    paintTable: true,
                    selectedMonth: selectedMonth
                }, () => {
                    this.setState({ paintTable: false });
                });
                this.onGetLastUpdatedDate(selectedMonth);
                let inputData = {
                    month: CommonService.formatServerDateTime(selectedMonth)
                };
                this.props.applicationInsights.trackTrace({ message: "ServiceManagerPayrollSecondaryComponent/onGetPayrollClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                fetch("/ServiceManagerPayroll/GetServiceManagerPayroll", {
                    method: "POST",
                    headers: {
                        'Content-Type': "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData)
                })
                    .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                    .then(parsedResponse => {
                        let tableData = parsedResponse.table;
                        let subStoresTableData = parsedResponse.table1;
                        tableData.forEach((rowData) => {
                            this.updateRow(rowData);
                        });
                        subStoresTableData.forEach((rowData) => {
                            this.updateRow(rowData);
                        });
                        this.setState({
                            isLoadingData: false,
                            tableData: CommonService.addOrdinalColumn(tableData),
                            subStoresTableData: CommonService.addOrdinalColumn(subStoresTableData),
                            paintTable: true
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                    })
                    .catch(notOKResponse => {
                        this.setState({
                            isLoadingData: false,
                            tableData: [],
                            subStorestableData: []
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ServiceManagerPayroll/GetServiceManagerPayroll", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                    console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.devError(jsonParseError, null, this.props.applicationInsights);
                                });
                        }
                    });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    updateRow(rowData) {
        if (rowData.cp_labor_hours) {
            rowData.customer_effective_labor_rate = (rowData.cp_labor_sales + rowData.express_change_labor_sales) / rowData.cp_labor_hours;
        }
        if (rowData.cp_labor_hours + rowData.wp_labor_hours + rowData.ip_labor_hours) {
            rowData.overall_effective_labor_rate = (rowData.cp_labor_sales + rowData.wp_labor_sales + rowData.ip_labor_sales) / (rowData.cp_labor_hours + rowData.wp_labor_hours + rowData.ip_labor_hours);
        }
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-flex dgdg-flex-column">
            <BrowserRouter>
                <nav className="nav nav-pills dgdg-tab">
                    <Link to="/ServiceManagerPayroll/Primary" className={"nav-item nav-link" + (window.location.pathname.indexOf("/Primary") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Primary</Link>
                    <Link to="/ServiceManagerPayroll/Secondary" className={"nav-item nav-link mr-auto" + (window.location.pathname.indexOf("/Secondary") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Secondary</Link>
                    {<div className="mt-auto dgdg-flex-item dgdg-badge-margin">
                        <span className="float-right badge badge-primary dgdg-badge">Last Updated Date: {this.state.lastUpdatedDate ? CommonService.formatDateTimeZone(this.state.lastUpdatedDate) : ""}</span>
                    </div>
                    }
                </nav>
                <div className="dgdg-flex-item dgdg-site-content">
                    <Switch>
                        <Route exact path="/ServiceManagerPayroll/Primary" render={(routeProps) => <ServiceManagerPayrollPrimaryComponent {...this.props} {...routeProps} isLoadingData={this.state.isLoadingData} months={this.state.months} tableData={this.state.tableData} subStoresTableData={this.state.subStoresTableData} onGetLastUpdatedDate={this.onGetLastUpdatedDate} onGetPayrollClick={this.onGetPayrollClick} onShowAlert={this.props.onShowAlert} />} />
                        <Route exact path="/ServiceManagerPayroll/Secondary" render={(routeProps) => <ServiceManagerPayrollSecondaryComponent {...this.props} {...routeProps} isLoadingData={this.state.isLoadingData} months={this.state.months} tableData={this.state.tableData} subStoresTableData={this.state.subStoresTableData} onGetLastUpdatedDate={this.onGetLastUpdatedDate} onGetPayrollClick={this.onGetPayrollClick} />} onShowAlert={this.props.onShowAlert} />
                        <Route render={(routeProps) => <PageNotFoundRouteComponent {...this.props} {...routeProps} />} />
                    </Switch>
                </div>
            </BrowserRouter>
        </DGDGPageComponent>;
    }
}
