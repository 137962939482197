import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { FSCommon } from "./FSCommon";
import { FinancialStatementValueCellComponent } from "./FinancialStatementValueCellComponent";

export class SalesGrossBreakdownComponent extends React.Component {
    rowNumber = 1;
    mOEMTtlFinIncGrsPft = 0;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        this.rowNumber = 1;
        this.mOEMTtlFinIncGrsPft = 0;
        let mTtlVhcCnt = null,
            yTtlVhcCnt = null;

        let mTtlVhcSales = null,
            yTtlVhcSales = null;

        let mTtlVhcGrsPft = null,
            yTtlVhcGrsPft = null;

        // TOTAL NEW VEHICLE DEPARTMENT
        if (this.props.fsDict) {
            mTtlVhcCnt = FSCommon.parseDecimal(this.props.fsDict, "1~M~New Stat~New Car Sales~Count Total New Vehicle Dept");
            yTtlVhcCnt = FSCommon.parseDecimal(this.props.fsDict, "1~Y~New Stat~New Car Sales~Count Total New Vehicle Dept");

            mTtlVhcSales = FSCommon.parseDecimal(this.props.fsDict, "1~M~New Gross~All~Sales Total New Vehicle Dept");
            yTtlVhcSales = FSCommon.parseDecimal(this.props.fsDict, "1~Y~New Gross~All~Sales Total New Vehicle Dept");

            mTtlVhcGrsPft = FSCommon.parseDecimal(this.props.fsDict, "1~M~New Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total New Vehicle Dept");
            yTtlVhcGrsPft = FSCommon.parseDecimal(this.props.fsDict, "1~Y~New Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total New Vehicle Dept");
        }

        return <table className="dgdg-fs-table">
            <tbody>
                <tr className="text-center font-weight-bold">
                    <td />
                    <td colSpan="4">CURRENT MONTH</td>
                    <td colSpan="3">GROSS PROFIT ANALYSIS</td>
                    <td colSpan="4">YEAR TO DATE</td>
                </tr>
                <tr className="text-center">
                    <td style={{ "width": "35px" }}>LINE</td>
                    <td>UNITS</td>
                    <td>SALES</td>
                    <td>GROSS PROFIT</td>
                    <td>Per Unit</td>
                    <td style={{ width: "20px" }} />
                    <td>NEW VEHICLES</td>
                    <td>UNITS</td>
                    <td>SALES</td>
                    <td>GROSS PROFIT</td>
                    <td>Per Unit</td>
                </tr>
                {this.newVehicleRows("1")}
                {this.newVehicleRows("2")}
                {this.newVehicleRows("3")}
                {
                    this.salesGrossVehicleRowByValue("dgdg-fs-heading-1-bg-color font-weight-bold", 0, 1, "dgdg-fs-heading-1-bg-color font-weight-bold", null, "TOTAL NEW VEHICLE DEPARTMENT",
                        mTtlVhcCnt, mTtlVhcSales, mTtlVhcGrsPft, (mTtlVhcCnt > 0 ? mTtlVhcGrsPft / mTtlVhcCnt : 0),
                        yTtlVhcCnt, yTtlVhcSales, yTtlVhcGrsPft, (yTtlVhcCnt > 0 ? yTtlVhcGrsPft / yTtlVhcCnt : 0))
                }
                <tr className="text-center font-weight-bold">
                    <td colSpan="11">&nbsp;</td>
                </tr>
                <tr className="text-center">
                    <td style={{ "width": "35px" }}>LINE</td>
                    <td>UNITS</td>
                    <td>SALES</td>
                    <td>GROSS PROFIT</td>
                    <td>Per Unit</td>
                    <td style={{ width: "20px" }} />
                    <td>NEW VEHICLES</td>
                    <td>UNITS</td>
                    <td>SALES</td>
                    <td>GROSS PROFIT</td>
                    <td>Per Unit</td>
                </tr>
                {this.usedVehicleRows()}
            </tbody>
        </table>;
    }

    getVehicleKeys(object, key1, key2, key3) {
        let vehicleKeys = [];
        Object.keys(object).forEach((key) => {
            if (key.indexOf(key1) === 0 || key.indexOf(key2) === 0 || key.indexOf(key3) === 0) {
                key = key.replace("New Car Gross", "New Car Sales");
                if (!vehicleKeys.includes(key)) {
                    vehicleKeys.push(key);
                }
            }
        });

        return vehicleKeys;
    }

    salesGrossVehicleRowByKey(vehicleIndex, oemLength, oemCssName, text, vehicleName, mUnitsKey, mSalesKey, mGrossProfitKey, mGrossPerUnit, yUnitsKey, ySalesKey, yGrossProfitKey, yGrossPerUnit, showDetail) {
        return <tr key={"vehicle-" + this.rowNumber + "-" + vehicleIndex}>
            <td className="text-center">{this.rowNumber++}</td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent applicationInsights={this.props.applicationInsights} dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={mUnitsKey} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent applicationInsights={this.props.applicationInsights} dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={mSalesKey} onFinancialStatementDetailClick={showDetail ? this.props.onFinancialStatementDetailClick : null} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent applicationInsights={this.props.applicationInsights} dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={mGrossProfitKey} onFinancialStatementDetailClick={showDetail ? this.props.onFinancialStatementDetailClick : null} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent applicationInsights={this.props.applicationInsights} dataType="money" mode={this.props.mode} value={mGrossPerUnit} />
            </td>
            {
                vehicleIndex === 0
                    ? <td rowSpan={oemLength} className={oemCssName} style={{ "width": "25px" }}>
                        {
                            (text !== null)
                                ? <div style={{ "minHeight": "150px", "margin": "10px 0px" }}>
                                    <p className="dgdg-text-vertical">{text}</p>
                                </div>
                                : null
                        }

                    </td>
                    : null
            }
            <td className="dgdg-fs-value text-left dgdg-font-black">
                {vehicleName}
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={yUnitsKey} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={ySalesKey} onFinancialStatementDetailClick={showDetail ? this.props.onFinancialStatementDetailClick : null} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={yGrossProfitKey} onFinancialStatementDetailClick={showDetail ? this.props.onFinancialStatementDetailClick : null} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={yGrossPerUnit} />
            </td>
        </tr>;
    }

    salesGrossVehicleRowByValue(cssName, vehicleIndex, oemLength, oemCssName, text, vehicleName, mUnits, mSales, mGrossProfit, mGrossPerUnit, yUnits, ySales, yGrossProfit, yGrossPerUnit) {
        return <tr key={"vehicle-" + this.rowNumber + "-" + vehicleIndex}>
            <td className="text-center">{this.rowNumber++}</td>
            <td className={"dgdg-fs-value " + cssName}>
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={mUnits} />
            </td>
            <td className={"dgdg-fs-value " + cssName}>
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={mSales} />
            </td>
            <td className={"dgdg-fs-value " + cssName}>
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={mGrossProfit} />
            </td>
            <td className={"dgdg-fs-value " + cssName}>
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={mGrossPerUnit} />
            </td>
            {
                vehicleIndex === 0
                    ? <td rowSpan={oemLength} className={oemCssName}>
                        {
                            (text !== null)
                                ? <div style={{ "minHeight": "150px", "margin": "10px 0px" }}>
                                    <p className="dgdg-text-vertical">{text}</p>
                                </div>
                                : null
                        }

                    </td>
                    : null
            }
            <td className={"dgdg-fs-value text-left dgdg-font-black " + cssName}>
                {vehicleName}
            </td>
            <td className={"dgdg-fs-value " + cssName}>
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={yUnits} />
            </td>
            <td className={"dgdg-fs-value " + cssName}>
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={ySales} />
            </td>
            <td className={"dgdg-fs-value " + cssName}>
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={yGrossProfit} />
            </td>
            <td className={"dgdg-fs-value " + cssName}>
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={yGrossPerUnit} />
            </td>
        </tr>;
    }

    newVehicleRows(oem) {
        let oemRows = [];

        if (this.props.fsDict) {
            let text = (oem === "1" ? "NEW VEHICLE DEPT - OEM " : "NEW OEM ") + oem;
            let oemVehicleKeys = null;
            if (this.props.selectedStore.number === 99) {
                oemVehicleKeys = this.getVehicleKeys(this.props.fsDict, oem + "~Y~DGDG Gross~DGDG~", oem + "~Y~DGDG Gross~Recon~", oem + "~Y~DGDG Gross~BTP~");
            }
            else {
                oemVehicleKeys = this.getVehicleKeys(this.props.fsDict, oem + "~Y~New Gross~New Car Sales~", oem + "~Y~New Gross~New Car Gross~");
            }

            let mTtlUts = 0, mTtlSales = 0, mTtlGrsPft = 0,
                yTtlUts = 0, yTtlSales = 0, yTtlGrsPft = 0;

            oemVehicleKeys.forEach((oemVehicleKey, vehicleIndex) => {
                let keyParts = oemVehicleKey.split("~");
                let vehicleName = null;

                let mUtsKey = oem + "~M~New Stat~" + keyParts[3].replace("Gross", "Sales") + "~" + keyParts[4],
                    yUtsKey = oem + "~Y~New Stat~" + keyParts[3].replace("Gross", "Sales") + "~" + keyParts[4];

                let mSalesKey = null, mGrsPftKey = null,
                    ySalesKey = null, yGrsPftKey = null;

                if (keyParts[4] === "Gross Fleet") {
                    vehicleName = "Fleet";
                    mSalesKey = oem + "~M~" + keyParts[2] + "~" + keyParts[3].replace("Gross", "Sales") + "~" + keyParts[4];
                    ySalesKey = oem + "~Y~" + keyParts[2] + "~" + keyParts[3].replace("Gross", "Sales") + "~" + keyParts[4];

                    mGrsPftKey = oem + "~M~" + keyParts[2] + "~" + keyParts[3].replace("Sales", "Gross") + "~" + keyParts[4];
                    yGrsPftKey = oem + "~Y~" + keyParts[2] + "~" + keyParts[3].replace("Sales", "Gross") + "~" + keyParts[4];
                }
                else {
                    vehicleName = keyParts[4];
                    mSalesKey = oem + "~M~" + keyParts[2] + "~" + keyParts[3].replace("Gross", "Sales") + "~" + keyParts[4];
                    ySalesKey = oem + "~Y~" + keyParts[2] + "~" + keyParts[3].replace("Gross", "Sales") + "~" + keyParts[4];

                    mGrsPftKey = oem + "~M~" + keyParts[2] + "~" + keyParts[3].replace("Sales", "Gross") + "~" + keyParts[4];
                    yGrsPftKey = oem + "~Y~" + keyParts[2] + "~" + keyParts[3].replace("Sales", "Gross") + "~" + keyParts[4];
                }

                let mUts = FSCommon.parseDecimal(this.props.fsDict, mUtsKey),
                    yUts = FSCommon.parseDecimal(this.props.fsDict, yUtsKey),
                    mSales = FSCommon.parseDecimal(this.props.fsDict, mSalesKey),
                    ySales = FSCommon.parseDecimal(this.props.fsDict, ySalesKey),
                    mGrsPft = FSCommon.parseDecimal(this.props.fsDict, mGrsPftKey),
                    yGrsPft = FSCommon.parseDecimal(this.props.fsDict, yGrsPftKey);

                let mGrsPerUnit = mUts > 0 ? mGrsPft / mUts : 0,
                    yGrsPerUnit = yUts > 0 ? yGrsPft / yUts : 0;

                mTtlUts += mUts;
                yTtlUts += yUts;
                mTtlSales += mSales;
                yTtlSales += ySales;
                mTtlGrsPft += mGrsPft;
                yTtlGrsPft += yGrsPft;

                oemRows.push(this.salesGrossVehicleRowByKey(vehicleIndex, (oem === "1" & vehicleIndex === 0 ? oemVehicleKeys.length + 16 : (vehicleIndex === 0 ? oemVehicleKeys.length + 3 : 0)), "dgdg-fs-bg-green-oem-" + oem, text, vehicleName,
                    mUtsKey, mSalesKey, mGrsPftKey, mGrsPerUnit,
                    yUtsKey, ySalesKey, yGrsPftKey, yGrsPerUnit, true));
            });

            if (oemVehicleKeys.length > 0) {
                // OEM - Bonus | Incentive
                let mOEMBonusIncentive = FSCommon.parseDecimal(this.props.fsDict, oem + "~M~New Gross~Mgmt Fee & Other Add Ded Gross Recast~New Car Incentives"),
                    yOEMBonusIncentive = FSCommon.parseDecimal(this.props.fsDict, oem + "~Y~New Gross~Mgmt Fee & Other Add Ded Gross Recast~New Car Incentives");

                mTtlGrsPft += mOEMBonusIncentive;
                yTtlGrsPft += yOEMBonusIncentive;

                if (oem === "1") {
                    oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "OEM - Bonus | Incentive",
                        "", "", oem + "~M~New Gross~Mgmt Fee & Other Add Ded Gross Recast~New Car Incentives", (mTtlUts > 0 ? mOEMBonusIncentive / mTtlUts : 0),
                        "", "", oem + "~Y~New Gross~Mgmt Fee & Other Add Ded Gross Recast~New Car Incentives", (yTtlUts > 0 ? yOEMBonusIncentive / yTtlUts : 0), true));
                }

                // Management & Pic Fees
                let mManagementPicFees = FSCommon.parseDecimal(this.props.fsDict, oem + "~M~Gross Recast~Mgmt & Pic Fee~New Car Gross"),
                    yManagementPicFees = FSCommon.parseDecimal(this.props.fsDict, oem + "~Y~Gross Recast~Mgmt & Pic Fee~New Car Gross");

                mTtlGrsPft += mManagementPicFees;
                yTtlGrsPft += yManagementPicFees;

                if (oem === "1") {
                    oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Management & Pic Fees",
                        "", "", oem + "~M~Gross Recast~Mgmt & Pic Fee~New Car Gross", (mTtlUts > 0 ? mManagementPicFees / mTtlUts : 0),
                        "", "", oem + "~Y~Gross Recast~Mgmt & Pic Fee~New Car Gross", (yTtlUts > 0 ? yManagementPicFees / yTtlUts : 0), true));
                }
            }

            // TOTAL OEM
            oemRows.push(this.salesGrossVehicleRowByValue("dgdg-fs-bg-green-oem-" + oem, oemVehicleKeys.length, 3, "dgdg-fs-bg-green-oem-" + oem, text, "TOTAL OEM " + oem + " - Sales Income",
                mTtlUts, mTtlSales, mTtlGrsPft, (mTtlUts > 0 ? mTtlGrsPft / mTtlUts : 0),
                yTtlUts, yTtlSales, yTtlGrsPft, (yTtlUts > 0 ? yTtlGrsPft / yTtlUts : 0)));

            let mTtlFinUts = 0, mTtlFinSales = 0, mTtlFinGrsPft = 0,
                yTtlFinUts = 0, yTtlFinSales = 0, yTtlFinGrsPft = 0;

            if (oemVehicleKeys.length > 0) {
                // Finance & Insurance Income
                let mCntFinIns = FSCommon.parseDecimal(this.props.fsDict, oem + "~M~New Stat~New F&I Sales~Count Finance & Insurance Income"),
                    yCntFinIns = FSCommon.parseDecimal(this.props.fsDict, oem + "~Y~New Stat~New F&I Sales~Count Finance & Insurance Income");

                let mGrsFinIns = FSCommon.parseDecimal(this.props.fsDict, oem + "~M~New Gross~New F&I Gross~Gross Finance & Insurance Income"),
                    yGrsFinIns = FSCommon.parseDecimal(this.props.fsDict, oem + "~Y~New Gross~New F&I Gross~Gross Finance & Insurance Income");

                mTtlFinUts += mCntFinIns;
                yTtlFinUts += yCntFinIns;

                mTtlFinGrsPft += mGrsFinIns;
                yTtlFinGrsPft += yGrsFinIns;

                if (oem === "1") {
                    oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Finance Income - Reserve",
                        oem + "~M~New Stat~New F&I Sales~Count Finance & Insurance Income", "", oem + "~M~New Gross~New F&I Gross~Gross Finance & Insurance Income", (mTtlUts > 0 ? mGrsFinIns / mTtlUts : 0),
                        oem + "~Y~New Stat~New F&I Sales~Count Finance & Insurance Income", "", oem + "~Y~New Gross~New F&I Gross~Gross Finance & Insurance Income", (yTtlUts > 0 ? yGrsFinIns / yTtlUts : 0), true));
                }

                // Finance Income - Extended Service Contract
                let mCntFinIncExtSrvCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~M~New Stat~New F&I Sales~Count Service Contracts"),
                    yCntFinIncExtSrvCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~Y~New Stat~New F&I Sales~Count Service Contracts");

                let mSalesFinIncExtSrvCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~M~New Gross~New F&I Sales~Sales Service Contracts"),
                    ySalesFinIncExtSrvCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~Y~New Gross~New F&I Sales~Sales Service Contracts");

                let mGrsFinIncExtSrvCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~M~New Gross~New F&I Gross~Gross Service Contracts"),
                    yGrsFinIncExtSrvCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~Y~New Gross~New F&I Gross~Gross Service Contracts");

                mTtlFinUts += mCntFinIncExtSrvCnt;
                yTtlFinUts += yCntFinIncExtSrvCnt;

                mTtlFinSales += mSalesFinIncExtSrvCnt;
                yTtlFinSales += ySalesFinIncExtSrvCnt;

                mTtlFinGrsPft += mGrsFinIncExtSrvCnt;
                yTtlFinGrsPft += yGrsFinIncExtSrvCnt;

                if (oem === "1") {
                    oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Finance Income - Vehicle Service Contract",
                        oem + "~M~New Stat~New F&I Sales~Count Service Contracts", oem + "~M~New Gross~New F&I Sales~Sales Service Contracts", oem + "~M~New Gross~New F&I Gross~Gross Service Contracts", (mTtlUts > 0 ? mGrsFinIncExtSrvCnt / mTtlUts : 0),
                        oem + "~Y~New Stat~New F&I Sales~Count Service Contracts", oem + "~Y~New Gross~New F&I Sales~Sales Service Contracts", oem + "~Y~New Gross~New F&I Gross~Gross Service Contracts", (yTtlUts > 0 ? yGrsFinIncExtSrvCnt / yTtlUts : 0), true));
                }

                // Finance Income - Prepaid Maintenance
                let mCntFinIncPrepaidMaintCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~M~New Stat~New F&I Sales~Count Prepaid Maintenance"),
                    yCntFinIncPrepaidMaintCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~Y~New Stat~New F&I Sales~Count Prepaid Maintenance");

                let mSalesFinIncPrepaidMaintCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~M~New Gross~New F&I Sales~Sales Prepaid Maintenance"),
                    ySalesFinIncPrepaidMaintCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~Y~New Gross~New F&I Sales~Sales Prepaid Maintenance");

                let mGrsFinIncPrepaidMaintCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~M~New Gross~New F&I Gross~Gross Prepaid Maintenance"),
                    yGrsFinIncPrepaidMaintCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~Y~New Gross~New F&I Gross~Gross Prepaid Maintenance");

                mTtlFinUts += mCntFinIncPrepaidMaintCnt;
                yTtlFinUts += yCntFinIncPrepaidMaintCnt;

                mTtlFinSales += mSalesFinIncPrepaidMaintCnt;
                yTtlFinSales += ySalesFinIncPrepaidMaintCnt;

                mTtlFinGrsPft += mGrsFinIncPrepaidMaintCnt;
                yTtlFinGrsPft += yGrsFinIncPrepaidMaintCnt;

                if (oem === "1") {
                    oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Finance Income - Prepaid Maintenance",
                        oem + "~M~New Stat~New F&I Sales~Count Prepaid Maintenance", oem + "~M~New Gross~New F&I Sales~Sales Prepaid Maintenance", oem + "~M~New Gross~New F&I Gross~Gross Prepaid Maintenance", (mTtlUts > 0 ? mGrsFinIncPrepaidMaintCnt / mTtlUts : 0),
                        oem + "~Y~New Stat~New F&I Sales~Count Prepaid Maintenance", oem + "~Y~New Gross~New F&I Sales~Sales Prepaid Maintenance", oem + "~Y~New Gross~New F&I Gross~Gross Prepaid Maintenance", (yTtlUts > 0 ? yGrsFinIncPrepaidMaintCnt / yTtlUts : 0), true));
                }

                // Finance Income - GAP
                let mCntFinIncGAPCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~M~New Stat~New F&I Sales~Count GAP"),
                    yCntFinIncGAPCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~Y~New Stat~New F&I Sales~Count GAP");

                let mSalesFinIncGAPCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~M~New Gross~New F&I Sales~Sales GAP"),
                    ySalesFinIncGAPCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~Y~New Gross~New F&I Sales~Sales GAP");

                let mGrsFinIncGAPCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~M~New Gross~New F&I Gross~Gross GAP"),
                    yGrsFinIncGAPCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~Y~New Gross~New F&I Gross~Gross GAP");

                mTtlFinUts += mCntFinIncGAPCnt;
                yTtlFinUts += yCntFinIncGAPCnt;

                mTtlFinSales += mSalesFinIncGAPCnt;
                yTtlFinSales += ySalesFinIncGAPCnt;

                mTtlFinGrsPft += mGrsFinIncGAPCnt;
                yTtlFinGrsPft += yGrsFinIncGAPCnt;

                if (oem === "1") {
                    oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Finance Income - GAP",
                        oem + "~M~New Stat~New F&I Sales~Count GAP", oem + "~M~New Gross~New F&I Sales~Sales GAP", oem + "~M~New Gross~New F&I Gross~Gross GAP", (mTtlUts > 0 ? mGrsFinIncGAPCnt / mTtlUts : 0),
                        oem + "~Y~New Stat~New F&I Sales~Count GAP", oem + "~Y~New Gross~New F&I Sales~Sales GAP", oem + "~Y~New Gross~New F&I Gross~Gross GAP", (yTtlUts > 0 ? yGrsFinIncGAPCnt / yTtlUts : 0), true));
                }

                // Finance Income - Tire & Wheel
                let mCntFinIncTireWheelCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~M~New Stat~New F&I Sales~Count Tire & Wheel"),
                    yCntFinIncTireWheelCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~Y~New Stat~New F&I Sales~Count Tire & Wheel");

                let mSalesFinIncTireWheelCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~M~New Gross~New F&I Sales~Sales Tire & Wheel"),
                    ySalesFinIncTireWheelCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~Y~New Gross~New F&I Sales~Sales Tire & Wheel");

                let mGrsFinIncTireWheelCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~M~New Gross~New F&I Gross~Gross Tire & Wheel"),
                    yGrsFinIncTireWheelCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~Y~New Gross~New F&I Gross~Gross Tire & Wheel");

                mTtlFinUts += mCntFinIncTireWheelCnt;
                yTtlFinUts += yCntFinIncTireWheelCnt;

                mTtlFinSales += mSalesFinIncTireWheelCnt;
                yTtlFinSales += ySalesFinIncTireWheelCnt;

                mTtlFinGrsPft += mGrsFinIncTireWheelCnt;
                yTtlFinGrsPft += yGrsFinIncTireWheelCnt;

                if (oem === "1") {
                    oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Finance Income - Tire & Wheel",
                        oem + "~M~New Stat~New F&I Sales~Count Tire & Wheel", oem + "~M~New Gross~New F&I Sales~Sales Tire & Wheel", oem + "~M~New Gross~New F&I Gross~Gross Tire & Wheel", (mTtlUts > 0 ? mGrsFinIncTireWheelCnt / mTtlUts : 0),
                        oem + "~Y~New Stat~New F&I Sales~Count Tire & Wheel", oem + "~Y~New Gross~New F&I Sales~Sales Tire & Wheel", oem + "~Y~New Gross~New F&I Gross~Gross Tire & Wheel", (yTtlUts > 0 ? yGrsFinIncTireWheelCnt / yTtlUts : 0), true));
                }

                // Finance Income - Key Replacement
                let mCntFinIncKeyReplmntCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~M~New Stat~New F&I Sales~Count Key Replacement"),
                    yCntFinIncKeyReplmntCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~Y~New Stat~New F&I Sales~Count Key Replacement");

                let mSalesFinIncKeyReplmntCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~M~New Gross~New F&I Sales~Sales Key Replacement"),
                    ySalesFinIncKeyReplmntCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~Y~New Gross~New F&I Sales~Sales Key Replacement");

                let mGrsFinIncKeyReplmntCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~M~New Gross~New F&I Gross~Gross Key Replacement"),
                    yGrsFinIncKeyReplmntCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~Y~New Gross~New F&I Gross~Gross Key Replacement");

                mTtlFinUts += mCntFinIncKeyReplmntCnt;
                yTtlFinUts += yCntFinIncKeyReplmntCnt;

                mTtlFinSales += mSalesFinIncKeyReplmntCnt;
                yTtlFinSales += ySalesFinIncKeyReplmntCnt;

                mTtlFinGrsPft += mGrsFinIncKeyReplmntCnt;
                yTtlFinGrsPft += yGrsFinIncKeyReplmntCnt;

                if (oem === "1") {
                    oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Finance Income - Key Replacement",
                        oem + "~M~New Stat~New F&I Sales~Count Key Replacement", oem + "~M~New Gross~New F&I Sales~Sales Key Replacement", oem + "~M~New Gross~New F&I Gross~Gross Key Replacement", (mTtlUts > 0 ? mGrsFinIncKeyReplmntCnt / mTtlUts : 0),
                        oem + "~Y~New Stat~New F&I Sales~Count Key Replacement", oem + "~Y~New Gross~New F&I Sales~Sales Key Replacement", oem + "~Y~New Gross~New F&I Gross~Gross Key Replacement", (yTtlUts > 0 ? yGrsFinIncKeyReplmntCnt / yTtlUts : 0), true));
                }

                // Finance Income - Wear & Use
                let mCntFinIncWearUseCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~M~New Stat~New F&I Sales~Count Wear & Use"),
                    yCntFinIncWearUseCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~Y~New Stat~New F&I Sales~Count Wear & Use");

                let mSalesFinIncWearUseCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~M~New Gross~New F&I Sales~Sales Wear & Use"),
                    ySalesFinIncWearUseCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~Y~New Gross~New F&I Sales~Sales Wear & Use");

                let mGrsFinIncWearUseCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~M~New Gross~New F&I Gross~Gross Wear & Use"),
                    yGrsFinIncWearUseCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~Y~New Gross~New F&I Gross~Gross Wear & Use");

                mTtlFinUts += mCntFinIncWearUseCnt;
                yTtlFinUts += yCntFinIncWearUseCnt;

                mTtlFinSales += mSalesFinIncWearUseCnt;
                yTtlFinSales += ySalesFinIncWearUseCnt;

                mTtlFinGrsPft += mGrsFinIncWearUseCnt;
                yTtlFinGrsPft += yGrsFinIncWearUseCnt;

                if (oem === "1") {
                    oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Finance Income - Wear & Use",
                        oem + "~M~New Stat~New F&I Sales~Count Wear & Use", oem + "~M~New Gross~New F&I Sales~Sales Wear & Use", oem + "~M~New Gross~New F&I Gross~Gross Wear & Use", (mTtlUts > 0 ? mGrsFinIncWearUseCnt / mTtlUts : 0),
                        oem + "~Y~New Stat~New F&I Sales~Count Wear & Use", oem + "~Y~New Gross~New F&I Sales~Sales Wear & Use", oem + "~Y~New Gross~New F&I Gross~Gross Wear & Use", (yTtlUts > 0 ? yGrsFinIncWearUseCnt / yTtlUts : 0), true));
                }

                // Finance Income - Aftermarket - New Vehicles
                let mCntFinIncAftermarketCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~M~New Stat~New F&I Sales~Count Aftermarket - New Vehicles"),
                    yCntFinIncAftermarketCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~Y~New Stat~New F&I Sales~Count Aftermarket - New Vehicles");

                let mSalesFinIncAftermarketCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~M~New Gross~New F&I Sales~Sales Aftermarket - New Vehicles"),
                    ySalesFinIncAftermarketCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~Y~New Gross~New F&I Sales~Sales Aftermarket - New Vehicles");

                let mGrsFinIncAftermarketCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~M~New Gross~New F&I Gross~Gross Aftermarket - New Vehicles"),
                    yGrsFinIncAftermarketCnt = FSCommon.parseDecimal(this.props.fsDict, oem + "~Y~New Gross~New F&I Gross~Gross Aftermarket - New Vehicles");

                mTtlFinUts += mCntFinIncAftermarketCnt;
                yTtlFinUts += yCntFinIncAftermarketCnt;

                mTtlFinSales += mSalesFinIncAftermarketCnt;
                yTtlFinSales += ySalesFinIncAftermarketCnt;

                mTtlFinGrsPft += mGrsFinIncAftermarketCnt;
                yTtlFinGrsPft += yGrsFinIncAftermarketCnt;

                if (oem === "1") {
                    oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Finance Income - Aftermarket - New Vehicles",
                        oem + "~M~New Stat~New F&I Sales~Count Aftermarket - New Vehicles", oem + "~M~New Gross~New F&I Sales~Sales Aftermarket - New Vehicles", oem + "~M~New Gross~New F&I Gross~Gross Aftermarket - New Vehicles", (mTtlUts > 0 ? mGrsFinIncAftermarketCnt / mTtlUts : 0),
                        oem + "~Y~New Stat~New F&I Sales~Count Aftermarket - New Vehicles", oem + "~Y~New Gross~New F&I Sales~Sales Aftermarket - New Vehicles", oem + "~Y~New Gross~New F&I Gross~Gross Aftermarket - New Vehicles", (yTtlUts > 0 ? yGrsFinIncAftermarketCnt / yTtlUts : 0), true));
                }

                // Finance Income - Other Adds and Deducts
                let mGrsFinIncOtr = FSCommon.parseDecimal(this.props.fsDict, oem + "~M~Gross Recast~Other Add Ded~New F&I Gross"),
                    yGrsFinIncOtr = FSCommon.parseDecimal(this.props.fsDict, oem + "~Y~Gross Recast~Other Add Ded~New F&I Gross");

                mTtlFinGrsPft += mGrsFinIncOtr;
                yTtlFinGrsPft += yGrsFinIncOtr;

                if (oem === "1") {
                    oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Finance Income - Other Adds and Deducts",
                        "", "", oem + "~M~Gross Recast~Other Add Ded~New F&I Gross", (mTtlUts > 0 ? mGrsFinIncOtr / mTtlUts : 0),
                        "", "", oem + "~Y~Gross Recast~Other Add Ded~New F&I Gross", (yTtlUts > 0 ? yGrsFinIncOtr / yTtlUts : 0), true));
                }

                // Finance Income - Bank Rebates
                let mGrsBnkRbts = FSCommon.parseDecimal(this.props.fsDict, oem + "~M~Gross Recast~Bank Rebates~New F&I Gross"),
                    yGrsBnkRbts = FSCommon.parseDecimal(this.props.fsDict, oem + "~Y~Gross Recast~Bank Rebates~New F&I Gross");

                mTtlFinGrsPft += mGrsBnkRbts;
                yTtlFinGrsPft += yGrsBnkRbts;

                if (oem === "1") {
                    oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Finance Income - Bank Rebates",
                        "", "", oem + "~M~Gross Recast~Bank Rebates~New F&I Gross", (mTtlUts > 0 ? mGrsBnkRbts / mTtlUts : 0),
                        "", "", oem + "~Y~Gross Recast~Bank Rebates~New F&I Gross", (yTtlUts > 0 ? yGrsBnkRbts / yTtlUts : 0), true));
                }

                // Finance Income - US Warranty Recapture
                let mGrsUSWrtRcpt = FSCommon.parseDecimal(this.props.fsDict, oem + "~M~Gross Recast~US Warranty~New F&I Gross"),
                    yGrsUSWrtRcpt = FSCommon.parseDecimal(this.props.fsDict, oem + "~Y~Gross Recast~US Warranty~New F&I Gross");

                mTtlFinGrsPft += mGrsUSWrtRcpt;
                yTtlFinGrsPft += yGrsUSWrtRcpt;

                if (oem === "1") {
                    oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Finance Income - US Warranty Recapture",
                        "", "", oem + "~M~Gross Recast~US Warranty~New F&I Gross", (mTtlUts > 0 ? mGrsUSWrtRcpt / mTtlUts : 0),
                        "", "", oem + "~Y~Gross Recast~US Warranty~New F&I Gross", (yTtlUts > 0 ? yGrsUSWrtRcpt / yTtlUts : 0), true));
                }
            }

            // TOTAL OEM - Finance Income
            this.mOEMTtlFinIncGrsPft += mTtlFinGrsPft;

            oemRows.push(this.salesGrossVehicleRowByValue("dgdg-fs-bg-green-oem-" + oem, this.rowNumber, 0, null, null, "TOTAL OEM " + oem + " - Finance Income",
                mTtlFinUts, mTtlFinSales, mTtlFinGrsPft, (mTtlUts > 0 ? mTtlFinGrsPft / mTtlUts : 0),
                yTtlFinUts, yTtlFinSales, yTtlFinGrsPft, (yTtlUts > 0 ? yTtlFinGrsPft / yTtlUts : 0)));

            // TOTAL OEM - Finance Income
            oemRows.push(this.salesGrossVehicleRowByValue("dgdg-fs-bg-green-oem-" + oem, this.rowNumber, 0, null, null, "TOTAL OEM " + oem + " GROSS",
                mTtlUts, mTtlSales + mTtlFinSales, mTtlGrsPft + mTtlFinGrsPft, (mTtlUts > 0 ? (mTtlGrsPft + mTtlFinGrsPft) / mTtlUts : 0),
                yTtlUts, yTtlSales + yTtlFinSales, yTtlGrsPft + yTtlFinGrsPft, (yTtlUts > 0 ? (yTtlGrsPft + yTtlFinGrsPft) / yTtlUts : 0)));
        }

        return oemRows;
    }

    usedVehicleRows() {
        let oemRows = [];

        if (this.props.fsDict) {
            //Used Car - Retail
            let mCntUsedCarRtl = FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Stat~Used Car Sales~Count Operation Used Vehicle - Retail"),
                yCntUsedCarRtl = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Used Stat~Used Car Sales~Count Operation Used Vehicle - Retail");

            let mGrsUsedCarRtl = FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Gross~Used Car Gross~Gross Operation Used Vehicle - Retail"),
                yGrsUsedCarRtl = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Used Gross~Used Car Gross~Gross Operation Used Vehicle - Retail");

            oemRows.push(this.salesGrossVehicleRowByKey(0, 25, "dgdg-fs-bg-green-oem-used", "USED VEHICLE DEPT", "Used Car - Retail",
                "1~M~Used Stat~Used Car Sales~Count Operation Used Vehicle - Retail", "1~M~Used Gross~Used Car Sales~Sales Operation Used Vehicle - Retail", "1~M~Used Gross~Used Car Gross~Gross Operation Used Vehicle - Retail", (mCntUsedCarRtl > 0 ? mGrsUsedCarRtl / mCntUsedCarRtl : 0),
                "1~Y~Used Stat~Used Car Sales~Count Operation Used Vehicle - Retail", "1~Y~Used Gross~Used Car Sales~Sales Operation Used Vehicle - Retail", "1~Y~Used Gross~Used Car Gross~Gross Operation Used Vehicle - Retail", (yCntUsedCarRtl > 0 ? yGrsUsedCarRtl / yCntUsedCarRtl : 0), true));

            //Used Car Reconditioning
            let mGrsUsedCarRecond = FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Gross~Used Car Gross~Gross Operation Used Vehicle Reconditioning"),
                yGrsUsedCarRecond = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Used Gross~Used Car Gross~Gross Operation Used Vehicle Reconditioning");

            oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Used Car Reconditioning",
                "", "", "1~M~Used Gross~Used Car Gross~Gross Operation Used Vehicle Reconditioning", (mCntUsedCarRtl > 0 ? mGrsUsedCarRecond / mCntUsedCarRtl : 0),
                "", "", "1~Y~Used Gross~Used Car Gross~Gross Operation Used Vehicle Reconditioning", (yCntUsedCarRtl > 0 ? yGrsUsedCarRecond / yCntUsedCarRtl : 0), true));

            //Used Truck - Retail
            oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Used Truck - Retail",
                "", "", "", "",
                "", "", "", "", true));

            //Used OEM1 CPO - Retail
            let mCntUsedOEM1CPORtl = FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Stat~Used Car Sales~Count Certified Pre-Owned - Retail"),
                yCntUsedOEM1CPORtl = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Used Stat~Used Car Sales~Count Certified Pre-Owned - Retail");

            let mGrsUsedOEM1CPORtl = FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Gross~Used Car Gross~Gross Certified Pre-Owned - Retail"),
                yGrsUsedOEM1CPORtl = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Used Gross~Used Car Gross~Gross Certified Pre-Owned - Retail");

            oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Used OEM1 CPO - Retail",
                "1~M~Used Stat~Used Car Sales~Count Certified Pre-Owned - Retail", "1~M~Used Gross~Used Car Sales~Sales Certified Pre-Owned - Retail", "1~M~Used Gross~Used Car Gross~Gross Certified Pre-Owned - Retail", (mCntUsedOEM1CPORtl > 0 ? mGrsUsedOEM1CPORtl / mCntUsedOEM1CPORtl : 0),
                "1~Y~Used Stat~Used Car Sales~Count Certified Pre-Owned - Retail", "1~Y~Used Gross~Used Car Sales~Sales Certified Pre-Owned - Retail", "1~Y~Used Gross~Used Car Gross~Gross Certified Pre-Owned - Retail", (yCntUsedOEM1CPORtl > 0 ? yGrsUsedOEM1CPORtl / yCntUsedOEM1CPORtl : 0), true));

            //Used OEM2 CPO - Retail
            oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Used OEM2 CPO - Retail",
                "", "", "", "",
                "", "", "", "", true));

            //Used OEM3 CPO - Retail
            oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Used OEM3 CPO - Retail",
                "", "", "", "",
                "", "", "", "", true));

            //Used CPO Reconditioning
            let mGrsUsedOEMRecond = FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Gross~Used Car Gross~Gross Certified Pre-Owned Reconditioning"),
                yGrsUsedOEMRecond = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Used Gross~Used Car Gross~Gross Certified Pre-Owned Reconditioning");

            oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Used CPO Reconditioning",
                "", "", "1~M~Used Gross~Used Car Gross~Gross Certified Pre-Owned Reconditioning", (mCntUsedOEM1CPORtl > 0 ? mGrsUsedOEMRecond / mCntUsedOEM1CPORtl : 0),
                "", "", "1~Y~Used Gross~Used Car Gross~Gross Certified Pre-Owned Reconditioning", (yCntUsedOEM1CPORtl > 0 ? yGrsUsedOEMRecond / yCntUsedOEM1CPORtl : 0), true));

            //Used Wholesale
            let mCntUsedWS = FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Stat~Used Car Sales~Count Used Vehicle - Wholesale"),
                yCntUsedWS = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Used Stat~Used Car Sales~Count Used Vehicle - Wholesale");

            let mGrsUsedWS = FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Gross~Used Car Gross~Gross Used Vehicle - Wholesale"),
                yGrsUsedWS = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Used Gross~Used Car Gross~Gross Used Vehicle - Wholesale");

            oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Used Wholesale",
                "1~M~Used Stat~Used Car Sales~Count Used Vehicle - Wholesale", "1~M~Used Gross~Used Car Sales~Sales Used Vehicle - Wholesale", "1~M~Used Gross~Used Car Gross~Gross Used Vehicle - Wholesale", (mCntUsedWS > 0 ? mGrsUsedWS / mCntUsedWS : 0),
                "1~Y~Used Stat~Used Car Sales~Count Used Vehicle - Wholesale", "1~Y~Used Gross~Used Car Sales~Sales Used Vehicle - Wholesale", "1~Y~Used Gross~Used Car Gross~Gross Used Vehicle - Wholesale", (yCntUsedWS > 0 ? yGrsUsedWS / yCntUsedWS : 0), true));

            //Adjustments Used Vehicle Inventory
            oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Adjustments Used Vehicle Inventory",
                "", "", "1~M~Used Gross~Used Car Gross~Gross Adjustments Used Vehicle Inventory", "",
                "", "", "1~Y~Used Gross~Used Car Gross~Gross Adjustments Used Vehicle Inventory", "", true));

            //Other Adds and Deducts
            let mGrsUsedOthIncExp = FSCommon.parseDecimal(this.props.fsDict, "1~M~Gross Recast~Other Add Ded~Used Car Gross"),
                yGrsUsedOthIncExp = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Gross Recast~Other Add Ded~Used Car Gross");

            let mUsedRetail = FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Stat~Used Car Sales~Count Total Used Vehicle Retail"),
                yUsedRetail = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Used Stat~Used Car Sales~Count Total Used Vehicle Retail");

            oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Other Adds and Deducts",
                "", "", "1~M~Gross Recast~Other Add Ded~Used Car Gross", (mUsedRetail > 0 ? mGrsUsedOthIncExp / mUsedRetail : 0),
                "", "", "1~Y~Gross Recast~Other Add Ded~Used Car Gross", (yUsedRetail > 0 ? yGrsUsedOthIncExp / yUsedRetail : 0), true));

            //Management & Pic Fees
            let mGrsUsedMgmtPicFee = FSCommon.parseDecimal(this.props.fsDict, "1~M~Gross Recast~Mgmt & Pic Fee~Used Car Gross"),
                yGrsUsedMgmtPicFee = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Gross Recast~Mgmt & Pic Fee~Used Car Gross");

            oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Management & Pic Fees",
                "", "", "1~M~Gross Recast~Mgmt & Pic Fee~Used Car Gross", (mUsedRetail > 0 ? mGrsUsedMgmtPicFee / mUsedRetail : 0),
                "", "", "1~Y~Gross Recast~Mgmt & Pic Fee~Used Car Gross", (yUsedRetail > 0 ? yGrsUsedMgmtPicFee / yUsedRetail : 0), true));

            // TOTAL OEM
            let mTtlUts = FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Stat~Used Car Sales~Count Total Used Vehicles"),
                yTtlUts = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Used Stat~Used Car Sales~Count Total Used Vehicles");

            let mTtlSales = FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Gross~All~Sales Total Used Car"),
                yTtlSales = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Used Gross~All~Sales Total Used Car");

            let mTtlGrsPft = FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Used Car & Wholesale"),
                yTtlGrsPft = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Used Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Used Car & Wholesale");

            oemRows.push(this.salesGrossVehicleRowByValue("dgdg-fs-bg-green-oem-used font-weight-bold", this.rowNumber, 0, null, null, "TOTAL Used Vehicle - Sales Income",
                mTtlUts, mTtlSales, mTtlGrsPft, (mUsedRetail > 0 ? mTtlGrsPft / mUsedRetail : 0),
                yTtlUts, yTtlSales, yTtlGrsPft, (yUsedRetail > 0 ? yTtlGrsPft / yUsedRetail : 0)));

            // Finance Income - Reserve
            let mGrsFinIncRsv = FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Gross~Used F&I Gross~Gross Finance & Insurance Income - Used Operation"),
                yGrsFinIncRsv = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Used Gross~Used F&I Gross~Gross Finance & Insurance Income - Used Operation");

            oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Finance Income - Reserve",
                "", "", "1~M~Used Gross~Used F&I Gross~Gross Finance & Insurance Income - Used Operation", ((mTtlUts - mCntUsedWS) > 0 ? mGrsFinIncRsv / (mTtlUts - mCntUsedWS) : 0),
                "", "", "1~Y~Used Gross~Used F&I Gross~Gross Finance & Insurance Income - Used Operation", ((yTtlUts - yCntUsedWS) > 0 ? yGrsFinIncRsv / (yTtlUts - yCntUsedWS) : 0), true));

            //Finance Income - Reserve CPO
            let mGrsFinIncRsvCPO = FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Gross~Used F&I Gross~Gross Finance & Insurance Income - CPO"),
                yGrsFinIncRsvCPO = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Used Gross~Used F&I Gross~Gross Finance & Insurance Income - CPO");

            oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Finance Income - Reserve CPO",
                "", "", "1~M~Used Gross~Used F&I Gross~Gross Finance & Insurance Income - CPO", ((mTtlUts - mCntUsedWS) > 0 ? mGrsFinIncRsvCPO / (mTtlUts - mCntUsedWS) : 0),
                "", "", "1~Y~Used Gross~Used F&I Gross~Gross Finance & Insurance Income - CPO", ((yTtlUts - yCntUsedWS) > 0 ? yGrsFinIncRsvCPO / (yTtlUts - yCntUsedWS) : 0), true));

            //Finance Income - Vehicle Service Contract
            let mGrsFinIncSrvCnt = FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Gross~Used F&I Gross~Gross Service Contracts - Used Operation"),
                yGrsFinIncSrvCnt = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Used Gross~Used F&I Gross~Gross Service Contracts - Used Operation");

            oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Finance Income - Vehicle Service Contract",
                "1~M~Used Stat~Used F&I Sales~Count Service Contracts - Used Operation", "1~M~Used Gross~Used F&I Sales~Sales Service Contracts - Used Operation", "1~M~Used Gross~Used F&I Gross~Gross Service Contracts - Used Operation", ((mTtlUts - mCntUsedWS) > 0 ? mGrsFinIncSrvCnt / (mTtlUts - mCntUsedWS) : 0),
                "1~Y~Used Stat~Used F&I Sales~Count Service Contracts - Used Operation", "1~Y~Used Gross~Used F&I Sales~Sales Service Contracts - Used Operation", "1~Y~Used Gross~Used F&I Gross~Gross Service Contracts - Used Operation", ((yTtlUts - yCntUsedWS) > 0 ? yGrsFinIncSrvCnt / (yTtlUts - yCntUsedWS) : 0), true));

            //Finance Income - Vehicle Service Contract CPO
            let mGrsFinIncSrvCntCPO = FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Gross~Used F&I Gross~Gross Service Contracts - CPO"),
                yGrsFinIncSrvCntCPO = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Used Gross~Used F&I Gross~Gross Service Contracts - CPO");

            oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Finance Income - Vehicle Service Contract CPO",
                "1~M~Used Stat~Used F&I Sales~Count Service Contracts - CPO", "1~M~Used Gross~Used F&I Sales~Sales Service Contracts - CPO", "1~M~Used Gross~Used F&I Gross~Gross Service Contracts - CPO", ((mTtlUts - mCntUsedWS) > 0 ? mGrsFinIncSrvCntCPO / (mTtlUts - mCntUsedWS) : 0),
                "1~Y~Used Stat~Used F&I Sales~Count Service Contracts - CPO", "1~Y~Used Gross~Used F&I Sales~Sales Service Contracts - CPO", "1~Y~Used Gross~Used F&I Gross~Gross Service Contracts - CPO", ((yTtlUts - yCntUsedWS) > 0 ? yGrsFinIncSrvCntCPO / (yTtlUts - yCntUsedWS) : 0), true));

            //Finance Income - Prepaid Maintenance
            let mGrsFinIncPrepaidMaintenance = FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Gross~Used F&I Gross~Gross Prepaid Maintenance - Used"),
                yGrsFinIncPrepaidMaintenance = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Used Gross~Used F&I Gross~Gross Prepaid Maintenance - Used");

            oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Finance Income - Prepaid Maintenance",
                "1~M~Used Stat~Used F&I Sales~Count Prepaid Maintenance - Used", "1~M~Used Gross~Used F&I Sales~Sales Prepaid Maintenance - Used", "1~M~Used Gross~Used F&I Gross~Gross Prepaid Maintenance - Used", ((mTtlUts - mCntUsedWS) > 0 ? mGrsFinIncPrepaidMaintenance / (mTtlUts - mCntUsedWS) : 0),
                "1~Y~Used Stat~Used F&I Sales~Count Prepaid Maintenance - Used", "1~Y~Used Gross~Used F&I Sales~Sales Prepaid Maintenance - Used", "1~Y~Used Gross~Used F&I Gross~Gross Prepaid Maintenance - Used", ((yTtlUts - yCntUsedWS) > 0 ? yGrsFinIncPrepaidMaintenance / (yTtlUts - yCntUsedWS) : 0), true));

            //Finance Income - Prepaid Maintenance - CPO
            let mGrsFinIncPrepaidMaintenanceCPO = FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Gross~Used F&I Gross~Gross Prepaid Maintenance - CPO"),
                yGrsFinIncPrepaidMaintenanceCPO = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Used Gross~Used F&I Gross~Gross Prepaid Maintenance - CPO");

            oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Finance Income - Prepaid Maintenance - CPO",
                "1~M~Used Stat~Used F&I Sales~Count Prepaid Maintenance - CPO", "1~M~Used Gross~Used F&I Sales~Sales Prepaid Maintenance - CPO", "1~M~Used Gross~Used F&I Gross~Gross Prepaid Maintenance - CPO", ((mTtlUts - mCntUsedWS) > 0 ? mGrsFinIncPrepaidMaintenanceCPO / (mTtlUts - mCntUsedWS) : 0),
                "1~Y~Used Stat~Used F&I Sales~Count Prepaid Maintenance - CPO", "1~Y~Used Gross~Used F&I Sales~Sales Prepaid Maintenance - CPO", "1~Y~Used Gross~Used F&I Gross~Gross Prepaid Maintenance - CPO", ((yTtlUts - yCntUsedWS) > 0 ? yGrsFinIncPrepaidMaintenanceCPO / (yTtlUts - yCntUsedWS) : 0), true));

            //Finance Income - GAP
            let mGrsFinIncGAP = FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Gross~Used F&I Gross~Gross GAP - Used"),
                yGrsFinIncGAP = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Used Gross~Used F&I Gross~Gross GAP - Used");

            oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Finance Income - GAP",
                "1~M~Used Stat~Used F&I Sales~Count GAP - Used", "1~M~Used Gross~Used F&I Sales~Sales GAP - Used", "1~M~Used Gross~Used F&I Gross~Gross GAP - Used", ((mTtlUts - mCntUsedWS) > 0 ? mGrsFinIncGAP / (mTtlUts - mCntUsedWS) : 0),
                "1~Y~Used Stat~Used F&I Sales~Count GAP - Used", "1~Y~Used Gross~Used F&I Sales~Sales GAP - Used", "1~Y~Used Gross~Used F&I Gross~Gross GAP - Used", ((yTtlUts - yCntUsedWS) > 0 ? yGrsFinIncGAP / (yTtlUts - yCntUsedWS) : 0), true));

            //Finance Income - GAP - CPO
            let mGrsFinIncGAPCPO = FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Gross~Used F&I Gross~Gross GAP - CPO"),
                yGrsFinIncGAPCPO = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Used Gross~Used F&I Gross~Gross GAP - CPO");

            oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Finance Income - GAP - CPO",
                "1~M~Used Stat~Used F&I Sales~Count GAP - CPO", "1~M~Used Gross~Used F&I Sales~Sales GAP - CPO", "1~M~Used Gross~Used F&I Gross~Gross GAP - CPO", ((mTtlUts - mCntUsedWS) > 0 ? mGrsFinIncGAPCPO / (mTtlUts - mCntUsedWS) : 0),
                "1~Y~Used Stat~Used F&I Sales~Count GAP - CPO", "1~Y~Used Gross~Used F&I Sales~Sales GAP - CPO", "1~Y~Used Gross~Used F&I Gross~Gross GAP - CPO", ((yTtlUts - yCntUsedWS) > 0 ? yGrsFinIncGAPCPO / (yTtlUts - yCntUsedWS) : 0), true));

            //Finance Income - Tire & Wheel
            let mGrsFinIncTireWheel = FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Gross~Used F&I Gross~Gross Tire & Wheel - Used"),
                yGrsFinIncTireWheel = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Used Gross~Used F&I Gross~Gross Tire & Wheel - Used");

            oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Finance Income - Tire & Wheel",
                "1~M~Used Stat~Used F&I Sales~Count Tire & Wheel - Used", "1~M~Used Gross~Used F&I Sales~Sales Tire & Wheel - Used", "1~M~Used Gross~Used F&I Gross~Gross Tire & Wheel - Used", ((mTtlUts - mCntUsedWS) > 0 ? mGrsFinIncTireWheel / (mTtlUts - mCntUsedWS) : 0),
                "1~Y~Used Stat~Used F&I Sales~Count Tire & Wheel - Used", "1~Y~Used Gross~Used F&I Sales~Sales Tire & Wheel - Used", "1~Y~Used Gross~Used F&I Gross~Gross Tire & Wheel - Used", ((yTtlUts - yCntUsedWS) > 0 ? yGrsFinIncTireWheel / (yTtlUts - yCntUsedWS) : 0), true));

            //Finance Income - Aftermarket
            let mGrsFinIncAftermarket = FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Gross~Used F&I Gross~Gross Aftermarket - Used Vehicles"),
                yGrsFinIncAftermarket = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Used Gross~Used F&I Gross~Gross Aftermarket - Used Vehicles");

            oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Finance Income - Aftermarket",
                "1~M~Used Stat~Used F&I Sales~Count Aftermarket - Used Vehicles", "1~M~Used Gross~Used F&I Sales~Sales Aftermarket - Used Vehicles", "1~M~Used Gross~Used F&I Gross~Gross Aftermarket - Used Vehicles", ((mTtlUts - mCntUsedWS) > 0 ? mGrsFinIncAftermarket / (mTtlUts - mCntUsedWS) : 0),
                "1~Y~Used Stat~Used F&I Sales~Count Aftermarket - Used Vehicles", "1~Y~Used Gross~Used F&I Sales~Sales Aftermarket - Used Vehicles", "1~Y~Used Gross~Used F&I Gross~Gross Aftermarket - Used Vehicles", ((yTtlUts - yCntUsedWS) > 0 ? yGrsFinIncAftermarket / (yTtlUts - yCntUsedWS) : 0), true));

            //Finance Income - Bank Rebates
            let mGrsBankRebates = FSCommon.parseDecimal(this.props.fsDict, "1~M~Gross Recast~Bank Rebates~Used F&I Gross"),
                yGrsBankRebates = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Gross Recast~Bank Rebates~Used F&I Gross");

            oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Finance Income - Bank Rebates",
                "", "", "1~M~Gross Recast~Bank Rebates~Used F&I Gross", ((mTtlUts - mCntUsedWS) > 0 ? mGrsBankRebates / (mTtlUts - mCntUsedWS) : 0),
                "", "", "1~Y~Gross Recast~Bank Rebates~Used F&I Gross", ((yTtlUts - yCntUsedWS) > 0 ? yGrsBankRebates / (yTtlUts - yCntUsedWS) : 0), true));

            //Finance Income - US Warranty Recapture
            let mGrsUSWrtRec = FSCommon.parseDecimal(this.props.fsDict, "1~M~Gross Recast~US Warranty~Used F&I Gross"),
                yGrsUSWrtRec = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Gross Recast~US Warranty~Used F&I Gross");

            oemRows.push(this.salesGrossVehicleRowByKey(this.rowNumber, null, null, null, "Finance Income - US Warranty Recapture",
                "", "", "1~M~Gross Recast~US Warranty~Used F&I Gross", ((mTtlUts - mCntUsedWS) > 0 ? mGrsUSWrtRec / (mTtlUts - mCntUsedWS) : 0),
                "", "", "1~Y~Gross Recast~US Warranty~Used F&I Gross", ((yTtlUts - yCntUsedWS) > 0 ? yGrsUSWrtRec / (yTtlUts - yCntUsedWS) : 0), true));

            // Total Used Vehicle - Finance Income
            let mTtlFinCnt = FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Stat~Used Car Sales~Count Total Used Vehicle Retail"),
                yTtlFinCnt = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Used Stat~Used Car Sales~Count Total Used Vehicle Retail");

            let mTtlFinSales = FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Gross~All~Sales Total Used F&I"),
                yTtlFinSales = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Used Gross~All~Sales Total Used F&I");

            let mTtlFinGrsPft = FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Used F&I"),
                yTtlFinGrsPft = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Used Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Used F&I");

            oemRows.push(this.salesGrossVehicleRowByValue("dgdg-fs-bg-green-oem-used", this.rowNumber, 0, null, null, "Total Used Vehicle - Finance Income",
                "", mTtlFinSales, mTtlFinGrsPft, (mTtlFinCnt > 0 ? mTtlFinGrsPft / mTtlFinCnt : 0),
                "", yTtlFinSales, yTtlFinGrsPft, (yTtlFinCnt > 0 ? yTtlFinGrsPft / yTtlFinCnt : 0)));

            // TOTAL USED VEHICLE DEPARTMENT
            let mTtlUsedVhcDeptCnt = FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Stat~Used Car Sales~Count Total Used Vehicles"),
                yTtlUsedVhcDeptCnt = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Used Stat~Used Car Sales~Count Total Used Vehicles");

            let mTtlUsedVhcDeptSales = FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Gross~All~Sales Total Used Vehicles"),
                yTtlUsedVhcDeptSales = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Used Gross~All~Sales Total Used Vehicles");

            let mTtlUsedVhcDeptGrsPft = FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Used & Wholesale"),
                yTtlUsedVhcDeptGrsPft = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Used Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Used & Wholesale");

            oemRows.push(this.salesGrossVehicleRowByValue("dgdg-fs-heading-1-bg-color font-weight-bold", 0, 1, "dgdg-fs-heading-1-bg-color font-weight-bold", null, "TOTAL USED VEHICLE DEPARTMENT",
                mTtlUsedVhcDeptCnt, mTtlUsedVhcDeptSales, mTtlUsedVhcDeptGrsPft, (mUsedRetail > 0 ? mTtlUsedVhcDeptGrsPft / mUsedRetail : 0),
                yTtlUsedVhcDeptCnt, yTtlUsedVhcDeptSales, yTtlUsedVhcDeptGrsPft, (yUsedRetail > 0 ? yTtlUsedVhcDeptGrsPft / yUsedRetail : 0)));

            // TOTAL ALL VEHICLES
            let mTtlAllVhcDeptSales = FSCommon.parseDecimal(this.props.fsDict, "1~M~Vehicle Gross~All~Sales Total All Vehicles"),
                yTtlAllVhcDeptSales = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Vehicle Gross~All~Sales Total All Vehicles");

            let mTtlAllVhcDeptGrsPft = FSCommon.parseDecimal(this.props.fsDict, "1~M~Vehicle Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total All Vehicles"),
                yTtlAllVhcDeptGrsPft = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Vehicle Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total All Vehicles");

            oemRows.push(this.salesGrossVehicleRowByValue("dgdg-fs-heading-1-bg-color font-weight-bold", 0, 1, "dgdg-fs-heading-1-bg-color font-weight-bold", null, "TOTAL ALL VEHICLES",
                "", mTtlAllVhcDeptSales, mTtlAllVhcDeptGrsPft, "",
                "", yTtlAllVhcDeptSales, yTtlAllVhcDeptGrsPft, ""));

            let mTtlVhcCnt = FSCommon.parseDecimal(this.props.fsDict, "1~M~New Stat~New Car Sales~Count Total New Vehicle Dept"),
                yTtlVhcCnt = FSCommon.parseDecimal(this.props.fsDict, "1~Y~New Stat~New Car Sales~Count Total New Vehicle Dept");

            let mTtlVhcGrsPft = FSCommon.parseDecimal(this.props.fsDict, "1~M~New Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total New Vehicle Dept"),
                yTtlVhcGrsPft = FSCommon.parseDecimal(this.props.fsDict, "1~Y~New Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total New Vehicle Dept");

            oemRows.push(<tr key="summary" className="align-bottom">
                <td colSpan="6" className="dgdg-no-border-right">
                    <table style={{ width: "100%" }}>
                        <tbody>
                            <tr className="text-center">
                                <td className="text-center" style={{ "width": "35px" }}>{this.rowNumber++}</td>
                                <td />
                                <td>Month Count</td>
                                <td>Month Gross</td>
                                <td>MOM</td>
                                <td>YTD Count</td>
                                <td>YTD Gross</td>
                            </tr>
                            <tr>
                                <td className="text-center">{this.rowNumber++}</td>
                                <td>New</td>
                                <td className="dgdg-fs-value">
                                    <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~New Stat~New Car Sales~Count Total New Vehicle Dept" />
                                </td>
                                <td className="dgdg-fs-value">
                                    <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~New Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total New Vehicle Dept" />
                                </td>
                                <td className="dgdg-fs-value">
                                    <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="" />
                                </td>
                                <td className="dgdg-fs-value">
                                    <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~Y~New Stat~New Car Sales~Count Total New Vehicle Dept" />
                                </td>
                                <td className="dgdg-fs-value">
                                    <FinancialStatementValueCellComponent dataType="money" fsDict={this.props.fsDict} valueKey="1~Y~New Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total New Vehicle Dept" />
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center">{this.rowNumber++}</td>
                                <td>Used Retail</td>
                                <td className="dgdg-fs-value">
                                    <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={mUsedRetail} />
                                </td>
                                <td className="dgdg-fs-value">
                                    <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Used Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Used Vehicle Dept" />
                                </td>
                                <td className="dgdg-fs-value">
                                </td>
                                <td className="dgdg-fs-value">
                                    <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={yUsedRetail} />
                                </td>
                                <td className="dgdg-fs-value">
                                    <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~Y~Used Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Used Vehicle Dept" />
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center">{this.rowNumber++}</td>
                                <td>Total Retail</td>
                                <td className="dgdg-fs-value">
                                    <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={CommonService.parseDecimal(mTtlVhcCnt) + CommonService.parseDecimal(mTtlFinCnt)} />
                                </td>
                                <td className="dgdg-fs-value">
                                    <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={CommonService.parseDecimal(mTtlVhcGrsPft) + CommonService.parseDecimal(mTtlUsedVhcDeptGrsPft) - CommonService.parseDecimal(mGrsUsedWS)} />
                                </td>
                                <td className="dgdg-fs-value">
                                </td>
                                <td className="dgdg-fs-value">
                                    <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={CommonService.parseDecimal(yTtlVhcCnt) + CommonService.parseDecimal(yTtlFinCnt)} />
                                </td>
                                <td className="dgdg-fs-value">
                                    <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={CommonService.parseDecimal(yTtlVhcGrsPft) + CommonService.parseDecimal(yTtlUsedVhcDeptGrsPft) - CommonService.parseDecimal(yGrsUsedWS)} />
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center">{this.rowNumber++}</td>
                                <td>Wholesale</td>
                                <td className="dgdg-fs-value">
                                    <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Used Stat~Used Car Sales~Count Used Vehicle - Wholesale" />
                                </td>
                                <td className="dgdg-fs-value">
                                    <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Used Gross~Used Car Gross~Gross Used Vehicle - Wholesale" />
                                </td>
                                <td className="dgdg-fs-value">
                                </td>
                                <td className="dgdg-fs-value">
                                    <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~Y~Used Stat~Used Car Sales~Count Used Vehicle - Wholesale" />
                                </td>
                                <td className="dgdg-fs-value">
                                    <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~Y~Used Gross~Used Car Gross~Gross Used Vehicle - Wholesale" />
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center">{this.rowNumber++}</td>
                                <td className="dgdg-fs-heading-1-bg-color">Total</td>
                                <td className="dgdg-fs-heading-1-bg-color font-weight-bold dgdg-fs-value">
                                    <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={CommonService.parseDecimal(mTtlVhcCnt) + CommonService.parseDecimal(mTtlFinCnt) + CommonService.parseDecimal(mCntUsedWS)} />
                                </td>
                                <td className="dgdg-fs-heading-1-bg-color font-weight-bold dgdg-fs-value">
                                    <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={CommonService.parseDecimal(mTtlVhcGrsPft) + CommonService.parseDecimal(mTtlUsedVhcDeptGrsPft)} />
                                </td>
                                <td className="dgdg-fs-heading-1-bg-color font-weight-bold dgdg-fs-value">
                                </td>
                                <td className="dgdg-fs-heading-1-bg-color font-weight-bold dgdg-fs-value">
                                    <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={CommonService.parseDecimal(yTtlVhcCnt) + CommonService.parseDecimal(yTtlFinCnt) + CommonService.parseDecimal(yCntUsedWS)} />
                                </td>
                                <td className="dgdg-fs-heading-1-bg-color font-weight-bold dgdg-fs-value">
                                    <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={CommonService.parseDecimal(yTtlVhcGrsPft) + CommonService.parseDecimal(yTtlUsedVhcDeptGrsPft)} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
                <td colSpan="3" className="dgdg-no-border-left dgdg-no-border-right">
                    <table style={{ width: "100%" }}>
                        <tbody>
                            <tr>
                                <td>Used Vehicle Average Reconditioning Amount</td>
                                <td className="dgdg-fs-value">
                                    <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={(CommonService.parseDecimal(mGrsUsedCarRecond) + CommonService.parseDecimal(mGrsUsedOEMRecond)) / CommonService.parseDecimal(mTtlFinCnt)} />
                                </td>
                            </tr>
                            <tr>
                                <td>Average RO Days - Used Vehicle Recondition</td>
                                <td />
                            </tr>
                            <tr>
                                <td className="dgdg-fs-bg-brown">&nbsp;</td>
                                <td />
                            </tr>
                            <tr>
                                <td className="dgdg-fs-bg-brown">&nbsp;</td>
                                <td />
                            </tr>
                            <tr>
                                <td className="dgdg-fs-bg-brown">&nbsp;</td>
                                <td />
                            </tr>
                            <tr>
                                <td className="dgdg-fs-bg-brown">&nbsp;</td>
                                <td />
                            </tr>
                            <tr>
                                <td className="dgdg-fs-bg-brown">&nbsp;</td>
                                <td />
                            </tr>
                        </tbody>
                    </table>
                </td>
                <td colSpan="3" className="dgdg-no-border-left">
                    <table style={{ width: "100%" }}>
                        <tbody>
                            <tr>
                                <td>New Finance PVR</td>
                                <td className="dgdg-fs-value">
                                    <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={CommonService.parseDecimal(this.mOEMTtlFinIncGrsPft) / CommonService.parseDecimal(mTtlVhcCnt)} />
                                </td>
                            </tr>
                            <tr>
                                <td>Used Finance PVR</td>
                                <td className="dgdg-fs-value">
                                    <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={mTtlFinGrsPft / mTtlFinCnt} />
                                </td>
                            </tr>
                            <tr>
                                <td>Total Finance PVR</td>
                                <td className="dgdg-fs-value">
                                    <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={(CommonService.parseDecimal(this.mOEMTtlFinIncGrsPft) + CommonService.parseDecimal(mTtlFinGrsPft)) / (CommonService.parseDecimal(mTtlVhcCnt) + CommonService.parseDecimal(mTtlFinCnt))} />
                                </td>
                            </tr>
                            <tr>
                                <td>New VSC Pen</td>
                                <td />
                            </tr>
                            <tr>
                                <td>Used VSC Pen</td>
                                <td />
                            </tr>
                            <tr>
                                <td>Total VSC Pen</td>
                                <td />
                            </tr>
                            <tr>
                                <td>New Maintenance Pen</td>
                                <td />
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>)
        }

        return oemRows;
    }
}
