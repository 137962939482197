import React, { Fragment } from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

export class SalesStoreReportRowComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columnSpan1: this.props.title === "Total Sales" ? 2 : 1
        };
    }

    render() {
        return <tr className="dgdg-table-v3-row">
            <td className="text-right">
                {
                    this.props.rowData
                        ? this.props.rowData.store_number + " | " + this.props.rowData.store_name
                        : null
                }
            </td>
            <td className={"text-center" + (this.props.rowData && this.props.rowData.is_max_volume === 1 ? " dgdg-sales-store-bg-flag-green" : "")}>
                {
                    this.props.rowData
                        ? this.props.rowData.volume
                        : null
                }
            </td>
            <td className={"text-right" + (this.props.rowData && this.props.rowData.is_max_front_pvr === 1 ? " dgdg-sales-store-bg-flag-green" : "")}>
                {
                    this.props.rowData
                        ? CommonService.formatCurrency(this.props.rowData.front_pvr)
                        : null
                }
            </td>
            <td className={"text-right" + (this.props.rowData && this.props.rowData.is_max_front_gross === 1 ? " dgdg-sales-store-bg-flag-green" : "")}>
                {
                    this.props.rowData
                        ? CommonService.formatCurrency(this.props.rowData.front_gross)
                        : null
                }
            </td>
            <td className={"text-right" + (this.props.rowData && this.props.rowData.is_max_back_pvr === 1 ? " dgdg-sales-store-bg-flag-green" : "")}>
                {
                    this.props.rowData
                        ? CommonService.formatCurrency(this.props.rowData.back_pvr)
                        : null
                }
            </td>
            <td className={"text-right" + (this.props.rowData && this.props.rowData.is_max_back_gross === 1 ? " dgdg-sales-store-bg-flag-green" : "")}>
                {
                    this.props.rowData
                        ? CommonService.formatCurrency(this.props.rowData.back_gross)
                        : null
                }
            </td>
            <td className={"text-right" + (this.props.rowData && this.props.rowData.is_max_total_pvr === 1 ? " dgdg-sales-store-bg-flag-green" : "")}>
                {
                    this.props.rowData
                        ? CommonService.formatCurrency(this.props.rowData.total_pvr)
                        : null
                }
            </td>
            <td className={"text-right" + (this.props.rowData && this.props.rowData.is_max_total_gross === 1 ? " dgdg-sales-store-bg-flag-green" : "")}>
                {
                    this.props.rowData
                        ? CommonService.formatCurrency(this.props.rowData.total_gross)
                        : null
                }
            </td>
            {
                !this.props.isDetailsHidden
                    ? <Fragment>
                        {
                            this.props.title === "Total Sales"
                                ? <td colSpan="2" className="dgdg-sales-store-bg dgdg-no-border-right dgdg-no-border-top" />
                                : <Fragment>
                                    <td className="dgdg-sales-store-bg dgdg-no-border-right dgdg-no-border-top" />
                                    <td colSpan="2" className="dgdg-sales-store-name-cell dgdg-no-border-top">
                                        <div className="text-right dgdg-font-blue dgdg-sales-store-name">
                                            {this.props.rowData.store_number + " | " + this.props.rowData.store_name}
                                        </div>
                                    </td>
                                </Fragment>
                        }
                        {
                            this.props.title === "Total Sales"
                                ? <td colSpan="2" className="dgdg-sales-store-name-cell dgdg-no-border-top" style={{ "width": "10%", "minWidth": "70px" }}>
                                    <div className="text-right dgdg-font-blue dgdg-sales-store-name">
                                        {this.props.rowData.store_number + " | " + this.props.rowData.store_name}
                                    </div>
                                </td>
                                : <td className={(this.props.rowData && this.props.rowData.volume_goal_highlight === 1 ? "dgdg-sales-store-bg-flag-red" : "")}>
                                    {this.props.rowData.volume_goal}
                                </td>
                        }
                        <td className={"text-center" + (this.props.rowData && this.props.rowData.is_max_volume_track === 1 ? " dgdg-sales-store-bg-flag-green" : "")}>
                            {
                                this.props.rowData
                                    ? CommonService.formatNumber(this.props.rowData.volume_track)
                                    : null
                            }
                        </td>
                        <td className={"text-right" + (this.props.rowData && this.props.rowData.is_max_total_gross_track === 1 ? " dgdg-sales-store-bg-flag-green" : "")}>
                            {
                                this.props.rowData
                                    ? CommonService.formatCurrency(this.props.rowData.total_gross_track)
                                    : null
                            }
                        </td>
                        <td className="text-center">
                            {
                                this.props.rowData
                                    ? this.props.rowData.store_code
                                    : null
                            }
                        </td>
                        <td className="text-center" style={{ backgroundColor: this.props.rowData ? this.props.rowData.yoy_volume_delta_pct_color : "" }}>
                            {
                                this.props.rowData
                                    ? CommonService.formatPercentage(this.props.rowData.yoy_volume_delta_pct)
                                    : null
                            }
                        </td>
                        <td className="text-center" style={{ backgroundColor: this.props.rowData ? this.props.rowData.yoy_total_gross_delta_pct_color : "" }}>
                            {
                                this.props.rowData
                                    ? CommonService.formatPercentage(this.props.rowData.yoy_total_gross_delta_pct)
                                    : null
                            }
                        </td>
                        <td className="text-right" style={{ backgroundColor: this.props.rowData ? this.props.rowData.yoy_front_pvr_delta_color : "" }}>
                            {
                                this.props.rowData
                                    ? CommonService.formatCurrency(this.props.rowData.yoy_front_pvr_delta)
                                    : null
                            }
                        </td>
                        <td className="text-left" style={{ backgroundColor: this.props.rowData ? this.props.rowData.yoy_back_pvr_delta_color : "" }}>
                            {
                                this.props.rowData
                                    ? CommonService.formatCurrency(this.props.rowData.yoy_back_pvr_delta)
                                    : null
                            }
                        </td>
                    </Fragment>
                    : null
            }
        </tr>;
    }
}
