import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGChartLineComponent } from "../common/dgdgchart/DGDGChartLineComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGWidgetComponent } from "../common/dgdgwidget/DGDGWidgetComponent"

import { SalesStoreReportDayQueryComponent } from "./SalesStoreReportDayQueryComponent";

export class SalesStoreReportDayComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            currentReportMonth: null,
            totalStoreDayData: null,
            newStoreDayData: null,
            usedStoreDayData: null,
            totalStoreDaySummary: null,
            newStoreDaySummary: null,
            usedStoreDaySummary: null,
            showSpinnerChart: false,
            chartData: null,
            chartOption: {
                tooltip: { isHtml: true },
                series: {
                    0: { targetAxisIndex: 0 },
                    1: { targetAxisIndex: 1 },
                    2: { targetAxisIndex: 1 }
                },
                hAxis: {
                    title: "Date",
                    format: "M/d",
                    gridlines: {
                        color: 'transparent'
                    },
                    textStyle: {
                        fontSize: 10
                    },
                    titleTextStyle: {
                        fontSize: 12,
                        italic: false
                    }
                },
                vAxes: {
                    0: {
                        title: "Volume",
                        textStyle: {
                            fontSize: 10
                        },
                        titleTextStyle: {
                            fontSize: 12,
                            italic: false
                        }
                    },
                    1: {
                        title: "Gross",
                        format: "$#,###",
                        textStyle: {
                            fontSize: 10
                        },
                        titleTextStyle: {
                            fontSize: 12,
                            italic: false
                        }
                    }
                },
                legend: {
                    textStyle: {
                        fontSize: 12
                    }
                }
            }
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onCopyClick = this.onCopyClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/SalesStoreReport/Day", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getChartData();
            CommonService.clientAzureStorageLog("SalesStoreReportDayComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.state.paintTable) {
                this.setState({ paintTable: false });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Sales Store Report";

    getChartData() {
        this.setState({
            showSpinnerChart: true
        });
        this.props.applicationInsights.trackTrace({ message: "/SalesStoreReportDayComponent/getChartData", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/SalesStoreReport/GetChartData")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({ chartData: parsedResponse });
            })
            .catch(notOKResponse => {
                this.setState({ chartData: null });
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SalesStoreReport/GetChartData", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            })
            .finally(() => {
                this.setState({ showSpinnerChart: false });
            });
    }

    getLastUpdatedDate(day) {
        try {
            let inputData = {
                startDate: CommonService.formatServerDateTime(day)
            };

            this.props.applicationInsights.trackTrace({ message: "/SalesStoreReportDayComponent/getLastUpdatedDate", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SalesStoreReport/GetLastUpdatedDayReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (CommonService.isDateValid(parsedResponse)) {
                        this.props.onLastUpdatedDateChanged(moment.utc(parsedResponse));
                    }
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SalesStoreReport/GetLastUpdatedDayReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(day) {
        try {
            this.setState({ isLoadingData: true });
            this.getLastUpdatedDate(day);
            let inputData = {
                startDate: CommonService.formatServerDateTime(day)
            };

            this.props.applicationInsights.trackTrace({ message: "/SalesStoreReportDayComponent/getLastUpdatedDate", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SalesStoreReport/GetDayReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let ttlStoreDayData = parsedResponse.data.filter(row => row['stat'] === 'NUT' && row['store_number'] !== 99);
                    let ttlStoreDaySummary = parsedResponse.data.filter(row => row['stat'] === 'NUT' && row['store_number'] === 99)[0];
                    let nwStoreDayData = parsedResponse.data.filter(row => row['stat'] === 'New' && row['store_number'] !== 99);
                    let nwStoreDaySummary = parsedResponse.data.filter(row => row['stat'] === 'New' && row['store_number'] === 99)[0];
                    let usdStoreDayData = parsedResponse.data.filter(row => row['stat'] === 'Used' && row['store_number'] !== 99);
                    let usdStoreDaySummary = parsedResponse.data.filter(row => row['stat'] === 'Used' && row['store_number'] === 99)[0];

                    this.setState({
                        isLoadingData: false,
                        currentReportMonth: moment(parsedResponse.reportDate),
                        totalStoreDayData: ttlStoreDayData,
                        totalStoreDaySummary: ttlStoreDaySummary,
                        newStoreDayData: nwStoreDayData,
                        newStoreDaySummary: nwStoreDaySummary,
                        usedStoreDayData: usdStoreDayData,
                        usedStoreDaySummary: usdStoreDaySummary
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        currentReportMonth: null,
                        totalStoreDayData: null,
                        newStoreDayData: null,
                        usedStoreDayData: null,
                        totalStoreDaySummary: null,
                        newStoreDaySummary: null,
                        usedStoreDaySummary: null
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SalesStoreReport/GetDayReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyClick(event) {
        try {
            let tableText = this.copyTextToString("Total", this.state.totalStoreDayData, this.state.totalStoreDaySummary);
            tableText += this.copyTextToString("New", this.state.newStoreDayData, this.state.newStoreDaySummary);
            tableText += this.copyTextToString("Used", this.state.usedStoreDayData, this.state.usedStoreDaySummary);
            navigator.clipboard.writeText(tableText);
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("SalesStoreReportDayComponent", "onCopyClick", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    copyTextToString(title, monthData, summaryData) {
        let tableText = "\t" + title + " Sales\r\n";
        tableText += "Store #\tStore Name\tVolume\tFront PVR\tBack PVR\tTotal PVR\tTotal Gross\r\n";

        monthData.forEach((row) => {
            tableText += row.store_number + "\t" + row.store_name + "\t" +
                row.volume + "\t" + CommonService.formatCurrency(row.front_pvr) + "\t" + CommonService.formatCurrency(row.back_pvr) + "\t" +
                CommonService.formatCurrency(row.total_pvr) + "\t" + CommonService.formatCurrency(row.total_gross) + "\r\n";
        });

        let row = summaryData;
        tableText += "\tDGDG TOTAL\t" +
            row.volume + "\t" + CommonService.formatCurrency(row.front_pvr) + "\t" + CommonService.formatCurrency(row.back_pvr) + "\t" +
            CommonService.formatCurrency(row.total_pvr) + "\t" + CommonService.formatCurrency(row.total_gross) + "\r\n\r\n";

        return tableText;
    }

    getHtmlHeader(title) {
        let htmlHeaderRows = [];

        htmlHeaderRows.push(<tr key="header-1" className="dgdg-sales-store-bg-title-foot">
            <td className="dgdg-font-grey dgdg-font-medium">{title} Sales</td>
            <td colSpan="5" />
        </tr>);

        htmlHeaderRows.push(<tr key="header-2" className="dgdg-table-v3-header">
            <td>STORE</td>
            <td>VOLUME</td>
            <td>FRONT PVR</td>
            <td>BACK PVR</td>
            <td>TOTAL PVR</td>
            <td>TOTAL GROSS</td>
        </tr>);

        return htmlHeaderRows;
    }

    getHtmlRows(dataRows) {
        let htmlRows = [];
        if (dataRows) {
            dataRows.forEach((data, index) => {
                htmlRows.push(<tr key={"row" + index} className="dgdg-table-v3-row">
                    <td className="text-right">
                        {data.store_number + " | " + data.store_name}
                    </td>
                    <td className={data.is_max_volume === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                        {data.volume}
                    </td>
                    <td className={data.is_max_front_pvr === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                        {CommonService.formatCurrency(data.front_pvr)}
                    </td>
                    <td className={data.is_max_back_pvr === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                        {CommonService.formatCurrency(data.back_pvr)}
                    </td>
                    <td className={data.is_max_total_pvr === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                        {CommonService.formatCurrency(data.total_pvr)}
                    </td>
                    <td className={data.is_max_total_gross === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                        {CommonService.formatCurrency(data.total_gross)}
                    </td>
                </tr>);
            });
        }

        return htmlRows;
    }

    getHtmlFooter(data) {
        let htmlFooterRow = null;
        if (data) {
            htmlFooterRow = <tr className="dgdg-sales-store-bg-title-foot font-weight-bold">
                <td className="text-right">
                    DGDG TOTAL
                </td>
                <td className={data.is_max_volume === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                    {data.volume}
                </td>
                <td className={data.is_max_front_pvr === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                    {CommonService.formatCurrency(data.front_pvr)}
                </td>
                <td className={data.is_max_back_pvr === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                    {CommonService.formatCurrency(data.back_pvr)}
                </td>
                <td className={data.is_max_total_pvr === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                    {CommonService.formatCurrency(data.total_pvr)}
                </td>
                <td className={data.is_max_total_gross === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                    {CommonService.formatCurrency(data.total_gross)}
                </td>
            </tr>;
        }

        return htmlFooterRow;
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <SalesStoreReportDayQueryComponent
                applicationInsights={this.props.applicationInsights}
                onGetReportClick={this.onGetReportClick}
                isLoadingData={this.state.isLoadingData}
                onCopyClick={this.onCopyClick}
            />
            <div className="dgdg-site-scrollable-content">
                <div className="dgdg-sales-store dgdg-card-gutter">
                    <table className="dgdg-table-v3 text-center">
                        <tbody>
                            <tr className="dgdg-sales-store-bg-row">
                                <td className="text-right dgdg-sales-store-bg-row-alt dgdg-font-blue">REPORT DATE</td>
                                <td>
                                    {
                                        this.state.currentReportMonth
                                            ? CommonService.formatDate(this.state.currentReportMonth)
                                            : null
                                    }
                                    &nbsp;AS OF 4AM
                                </td>
                            </tr>
                            <tr>
                                <td><div className="dgdg-card-gutter" /></td>
                            </tr>
                            {this.getHtmlHeader("Total")}
                            {this.getHtmlRows(this.state.totalStoreDayData)}
                            <tr>
                                <td />
                            </tr>
                            {this.getHtmlFooter(this.state.totalStoreDaySummary)}
                            <tr>
                                <td><div className="dgdg-card-gutter" /></td>
                            </tr>
                            {this.getHtmlHeader("New")}
                            {this.getHtmlRows(this.state.newStoreDayData)}
                            <tr>
                                <td />
                            </tr>
                            {this.getHtmlFooter(this.state.newStoreDaySummary)}
                            <tr>
                                <td><div className="dgdg-card-gutter" /></td>
                            </tr>
                            {this.getHtmlHeader("Used")}
                            {this.getHtmlRows(this.state.usedStoreDayData)}
                            <tr>
                                <td />
                            </tr>
                            {this.getHtmlFooter(this.state.usedStoreDaySummary)}
                        </tbody>
                    </table>
                </div>
                <div className="card">
                    <DGDGWidgetComponent title="Volume and Gross for Last 30 Days" restoredCss="dgdg-widget-maximized" isVisible>
                        <DGDGSpinnerComponent showSpinner={this.state.showSpinnerChart} />
                        {
                            this.state.chartData
                                ? <DGDGChartLineComponent chartData={this.state.chartData} chartOption={this.state.chartOption} />
                                : null
                        }
                    </DGDGWidgetComponent>
                </div>
            </div>
        </div>;
    }
}
