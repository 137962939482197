import React, { Fragment } from "react";
import $ from "jquery";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormCheckboxComponent } from "../common/dgdgcheckbox/DGDGFormCheckboxComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGFormRadioComponent } from "../common/dgdgradiobutton/DGDGFormRadioComponent";
import { DGDGFormTextAreaComponent } from "../common/dgdgtextarea/DGDGFormTextAreaComponent";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

export class SellToDGDGCalculateOfferComponent extends React.Component {
    static RequestOptionCookie = "SellToDGDGRequestOptionCookie";
    static VINCookie = "SellToDGDGVINCookie";
    static LicensePlateCookie = "SellToDGDGLicensePlateCookie";
    static LicenseStateCookie = "SellToDGDGLicenseStateCookie";

    requestOptionData = [
        { key: "VIN", value: "VIN" },
        { key: "LicensePlate", value: "License Plate" }
    ];

    paintsAndBodyConditionData = [
        { key: "LikeNew", value: "Like New", description: "Absolutely flawless paint, like you just drove off the dealership lot!" },
        { key: "VeryGood", value: "Very Good", description: "Some minor imperfections, such as swirl marks or small dings and dents here and there. Your car may like to make friends with shopping carts." },
        { key: "Acceptable", value: "Acceptable", description: "Scratches or other damage longer than a dollar bill. Large dents bigger than a golf ball. May have numerous paint chips. Possible minor repairs needed from a previous accident." },
        { key: "MajorIssues", value: "Major Issues", description: "Widespread damage where major repairs are needed." }
    ];

    wheelsAndTiresConditionData = [
        { key: "LikeNew", value: "Like New", description: "All 4 tires match and have at least 75% of tread remaining. No scrapes or damage on any wheel." },
        { key: "VeryGood", value: "Very Good", description: "All 4 tires match and have at least 50% of tread remaining. Wheels may have minor curb scrapes shorter than a dollar bill." },
        { key: "Acceptable", value: "Acceptable", description: "Tires have less than 50% tread remaining or may not match. Wheels may have major curb scrapes longer than a dollar bill. Parallel parking makes you cry." },
        { key: "MajorIssues", value: "Major Issues", description: "Wheels have major damage such as bends, requiring replacement. Gotta love those Bay Area potholes!" }
    ];

    windowGlassAndExteriorLightingData = [
        { key: "LikeNew", value: "Like New", description: "No chips or cracks on the windshield or other windows. Headlights and taillights are completely clear and free of fading, discoloration, or damage." },
        { key: "VeryGood", value: "Very Good", description: "Windshield may have minor pitting or repairable chips. Headlights and taillights may have minor pitting but are otherwise clear and free of fading, discoloration, or damage." },
        { key: "Acceptable", value: "Acceptable", description: "Noticeable chips or scratches on the windshield or other windows. Headlights and taillights may have fading or discoloration. Bugs are no longer in question as the culprit!" },
        { key: "MajorIssues", value: "Major Issues", description: "Large cracks on the windshield, other windows may be missing or cracked. Headlights and taillights may have major damage requiring replacement." }
    ];

    interiorConditionData = [
        { key: "LikeNew", value: "Like New", description: "Looks and feels like new. Your car passes the new car smell test, and the hanging trees don't count!" },
        { key: "VeryGood", value: "Very Good", description: "Light wear, but no stains, rips, or other issues. Vehicle has never been smoked in." },
        { key: "Acceptable", value: "Acceptable", description: "Noticeable wear including small stains, rips, or other issues. Vehicle has never been smoked in. Perhaps a furry friend has taken over the back seat?" },
        { key: "MajorIssues", value: "Major Issues", description: "Major damage including large stains, rips, or other issues. Heavy odors, including any sign of smoke. Roll those windows down, man!" }
    ];

    vehicleEquipmentData = [
        { key: "LikeNew", value: "Like New", description: "No functional or cosmetic issues, everything works as expected!" },
        { key: "VeryGood", value: "Very Good", description: "Everything works as expected, but there may be minor cosmetic issues. Your favorite button or switch has your fingerprint permanently ingrained." },
        { key: "Acceptable", value: "Acceptable", description: "Minor functional issues and/or larger cosmetic issues.\"Don't push that button!\" is a sentence you frequent." },
        { key: "MajorIssues", value: "Major Issues", description: "Major functional issues or damaged items." }
    ];

    mechanicalConditionData = [
        { key: "LikeNew", value: "Like New", description: "Runs and drives like new with no issues whatsoever. Vehicle is unmodified." },
        { key: "VeryGood", value: "Very Good", description: "Runs and drives with no major issues. A simple warning light is OK, such as low fluids or an overdue service. Vehicle is unmodified." },
        { key: "Acceptable", value: "Acceptable", description: "May have minor faults or need repairs to the powertrain, suspension, brakes, or similar. Vehicle may have minor modifications." },
        { key: "MajorIssues", value: "Major Issues", description: "May have major issues requiring immediate repair. Must still be drivable and able to be smogged in California." }
    ];

    accidentData = [
        { key: "No", value: "No" },
        { key: "Yes", value: "Yes" }
    ];

    keySetData = [
        { key: "Yes", value: "Yes" },
        { key: "No", value: "No" }
    ];

    constructor(props) {
        super(props);
        this.state = {
            showVINSpinner: false,
            showLPSpinner: false,
            showYMMSpinner: false,
            showRefreshSpinner: false,
            showAutoCheckSpinner: false,
            showSaveOfferSpinner: false,
            firstLoad: true,
            trimMakeId: null,
            trimModelId: null,
            trimId: null,
            requestOption: this.requestOptionData[0],

            vin: "",

            usStates: CommonService.getUSStates(),
            licensePlate: "",
            licenseState: null,

            zipCode: "95136",
            odometer: "",

            years: CommonService.getPreviousYearsByDate(24),
            selectedYear: null,
            makes: null,
            selectedMake: null,
            models: null,
            selectedModel: null,
            trims: null,
            selectedTrim: null,

            vehicleYMM: "",
            vehicleVin: "",
            vehicleLP: "",
            vehicleLPState: "",
            vehicleId: "",
            imageType: "Front",
            engineOptions: [],
            selectedEngine: null,
            transmissionOptions: [],
            selectedTransmission: null,
            driveTrainOptions: [],
            selectedDriveTrain: null,

            kbbExteriorColors: [],

            evoxExteriorColors: null,
            selectedEvoxExteriorColor: null,

            isImageAvailable: {
                selectedImage: true,
                thumbnailFront: true,
                thumbnailSide: true,
                thumbnailRear: true
            },

            showAllOptions: false,
            showKBBOptionsAPIResponse: false,
            vehicleOptionsApiRequest: "",
            vehicleOptions: [],
            allOptionList: [],
            nonTypicalOptionList: [],
            showKBBAPIResponse: false,
            kbbAPIRequest: "",
            kbbAPIResponse: "",
            pricesTableData: [],
            fairAlerts: [],
            goodAlerts: [],
            veryGoodAlerts: [],
            excellentAlerts: [],
            typicalListingPriceAlerts: [],
            typicalListingPriceCPOAlerts: [],
            kbbComponents: [],
            showValidationAPIResponse: false,
            validationAPIRequest: "",
            validationAPIResponse: "",

            showAutocheckAPIResponse: false,
            autoCheckAPIRequest: "",
            autoCheckAPIResponse: "",
            autoCheckData: [],
            canShowApiResponse: false,
            selectedAccidentCount: null,
            accidentsMessage: "",

            selectedVCPaintBody: null,
            selectedVCWindowGlassExterior: null,
            selectedVCWheelsTires: null,
            selectedVCInterior: null,
            selectedVCEquipment: null,
            selectedVCMechanical: null,
            selectedKeySet: null,

            firstName: "",
            lastName: "",
            email: props.remoteUpn,
            phoneNumber: "",
            homeZipCode: "",

            showOffer: false,
            kbbCondition: 0,
            kbbMileageAdjustmentValue: 0,
            kbbOptionAdjustmentValue: 0,
            conditionFactor: 0,
            typicalListingPrice: 0,
            lowValue: 0,
            highValue: 0,
            baseOfferRange: 0,
            accidentDeduction: 0,
            keyDeduction: 0,
            generalAdjustment: 0,
            maxMileageAdjustment: 0,
            baseOffer: 0,
            offerAmount: 0,
            calculationVariable: {},
            message: "",
            offerNumber: ""

        };

        this.onCheckChanged = this.onCheckChanged.bind(this);
        this.onDropdownChange = this.onDropdownChange.bind(this);
        this.onEVOXImageClick = this.onEVOXImageClick.bind(this);
        this.onRadioButtonListChange = this.onRadioButtonListChange.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onYearClick = this.onYearClick.bind(this);
        this.onMakeClick = this.onMakeClick.bind(this);
        this.onModelClick = this.onModelClick.bind(this);
        this.onOdometerChange = this.onOdometerChange.bind(this);
        this.onGetVehicleByVINClick = this.onGetVehicleByVINClick.bind(this);
        this.onGetVehicleByLPClick = this.onGetVehicleByLPClick.bind(this);
        this.onTrimChange = this.onTrimChange.bind(this);
        this.onEvoxExteriorColorClick = this.onEvoxExteriorColorClick.bind(this);
        this.onToggleAllOptionsClick = this.onToggleAllOptionsClick.bind(this);
        this.onOptionDropdownChange = this.onOptionDropdownChange.bind(this);
        this.onVehicleOptionChange = this.onVehicleOptionChange.bind(this);
        this.onCalculateOfferData = this.onCalculateOfferData.bind(this);
        this.onCopyToClipboard = this.onCopyToClipboard.bind(this);
        this.onMissingImage = this.onMissingImage.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/SellToDGDGCalculateOfferComponent/CalculateOffer", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getIdentity();
            CommonService.clientAzureStorageLog("SellToDGDGCalculateOfferComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.state.firstLoad) {
                let requestOptionCookie = CommonService.getCookie(SellToDGDGCalculateOfferComponent.RequestOptionCookie);
                let vin = this.state.vin, cookieVIN = CommonService.getCookie(SellToDGDGCalculateOfferComponent.VINCookie);
                let licensePlate = this.state.licensePlate, cookieLicensePlate = CommonService.getCookie(SellToDGDGCalculateOfferComponent.LicensePlateCookie);
                let licenseState = this.state.licenseState, cookieLicenseState = CommonService.getCookie(SellToDGDGCalculateOfferComponent.LicenseStateCookie);

                let requestOption = this.state.requestOption;
                if (requestOptionCookie) {
                    requestOption = this.requestOptionData.filter(item => item.key === requestOptionCookie)[0];
                }

                if (cookieVIN) {
                    vin = cookieVIN;
                }
                if (cookieLicensePlate) {
                    licensePlate = cookieLicensePlate;
                }
                if (cookieLicenseState) {
                    licenseState = this.state.usStates.filter(usState => usState.key === cookieLicenseState)[0];
                }

                this.setState({
                    firstLoad: false,
                    requestOption: requestOption,
                    vin: vin,
                    licensePlate: licensePlate,
                    licenseState: licenseState
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getIdentity() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/SellToDGDGCalculateOfferComponent/getIdentity", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SellToDGDG/GetIdentity")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        firstName: parsedResponse.givenName,
                        lastName: parsedResponse.surname
                    })
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SellToDGDG/GetIdentity", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Sell To DGDG";
    imageBaseUrl = "https://cdn.dgdg.com/image/EVOX/";

    onCheckChanged(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onEVOXImageClick(event, imageType) {
        try {
            let isImageAvailable = this.state.isImageAvailable;
            isImageAvailable["selectedImage"] = true;
            this.setState({
                isImageAvailable: isImageAvailable,
                imageType: imageType
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDropdownChange(id, selectedDdpData) {
        try {
            this.setState({ [id]: selectedDdpData });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onRadioButtonListChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onYearClick(id, year) {
        try {
            this.setState({
                selectedYear: year,
                makes: null,
                selectedMake: null,
                models: null,
                selectedModel: null,
                trims: null,
                selectedTrim: null
            }, () => {
                this.clearVinDependents();
                this.clearTrimDependents();
                this.getVehicleMakes();
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onMakeClick(id, make) {
        try {
            this.setState({
                selectedMake: make,
                models: null,
                selectedModel: null,
                trims: null,
                selectedTrim: null
            }, () => {
                this.clearVinDependents();
                this.clearTrimDependents();
                this.getVehicleModels();
            });

        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onModelClick(id, model) {
        try {
            this.setState({
                selectedModel: model,
                trims: null,
                selectedTrim: null
            }, () => {
                this.clearVinDependents();
                this.clearTrimDependents();
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onOdometerChange(id, value) {
        try {
            this.setState({
                [id]: value
            }, this.refreshVehicleValues);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetVehicleByVINClick() {
        try {
            CommonService.setCookie(SellToDGDGCalculateOfferComponent.RequestOptionCookie, this.state.requestOption.key, 30);
            CommonService.setCookie(SellToDGDGCalculateOfferComponent.VINCookie, this.state.vin, 30);
            this.setState({
                showVINSpinner: true,
                trims: null,
                selectedTrim: null
            }, () => {
                this.clearVinDependents();
                this.clearTrimDependents();
                this.getTrims();
                this.getAutoCheck();
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetVehicleByLPClick() {
        try {
            CommonService.setCookie(SellToDGDGCalculateOfferComponent.RequestOptionCookie, this.state.requestOption.key, 30);
            CommonService.setCookie(SellToDGDGCalculateOfferComponent.LicensePlateCookie, this.state.licensePlate, 30);
            CommonService.setCookie(SellToDGDGCalculateOfferComponent.LicenseStateCookie, this.state.licenseState ? this.state.licenseState.key : "", 30);
            this.setState({
                showLPSpinner: true,
                trims: null,
                selectedTrim: null
            }, () => {
                this.clearVinDependents();
                this.clearTrimDependents();
                this.getTrims();
                this.getAutoCheck();
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTrimChange(id, trim) {
        try {
            let vehicleYMM = trim.yearId + " " + trim.makeName + " " + trim.modelName;
            this.setState({
                selectedTrim: trim,
                vehicleYMM: vehicleYMM,
                vehicleId: trim.vehicleId
            }, () => {
                this.clearTrimDependents();
                this.getVehicleOptions();
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onEvoxExteriorColorClick(event, selectedValue, isColorDropDownChanged) {
        try {
            this.setState({
                selectedEvoxExteriorColor: selectedValue,
                isImageAvailable: {
                    selectedImage: true,
                    thumbnailFront: true,
                    thumbnailSide: true,
                    thumbnailRear: true
                }
            }, () => {
                this.validateVehicleOptions(selectedValue, true);
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onToggleAllOptionsClick(event) {
        try {
            let showAllOptions = !this.state.showAllOptions;
            this.setState({ showAllOptions: showAllOptions });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onOptionDropdownChange(id, selectedDdpData) {
        try {
            this.setState({
                [id]: selectedDdpData
            }, () => {
                this.validateVehicleOptions(selectedDdpData, true);
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onVehicleOptionChange(id, isChecked, vehicleOption) {
        try {
            let vehicleOptions = this.state.vehicleOptions;
            vehicleOption.isChecked = isChecked;
            this.setState({
                vehicleOptions: vehicleOptions
            }, () => {
                this.validateVehicleOptions(vehicleOption, isChecked);
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyToClipboard(event, value) {
        try {
            navigator.clipboard.writeText(value);
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("SellToDGDGCalculateOfferComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCalculateOfferData() {
        try {
            CommonService.clientAzureStorageLog("SellToDGDGCalculateOfferComponent", "onCalculateOfferData", this.state.vehicleVin, null, null, this.props.applicationInsights);
            if (!this.state.vehicleOptions || !this.state.vehicleOptions.length === 0 || !this.state.zipCode)
                return;
            else if (!this.state.selectedEngine) {
                this.props.onShowAlert("warning", { message: "Select an Engine" });
                return;
            }
            else if (!this.state.selectedTransmission) {
                this.props.onShowAlert("warning", { message: "Select a Transmission" });
                return;
            }
            else if (!this.state.selectedDriveTrain) {
                this.props.onShowAlert("warning", { message: "Select a Drivetrain" });
                return;
            }
            else if (!this.state.odometer) {
                this.props.onShowAlert("warning", { message: "Odometer cannot be blank" });
                return;
            }
            else if (!this.state.selectedVCPaintBody) {
                this.props.onShowAlert("warning", { message: "Select a Paint and Body" });
                return;
            }
            else if (!this.state.selectedVCWindowGlassExterior) {
                this.props.onShowAlert("warning", { message: "Select a Windows and Lights" });
                return;
            }
            else if (!this.state.selectedVCWheelsTires) {
                this.props.onShowAlert("warning", { message: "Select a Wheels and Tires" });
                return;
            }
            else if (!this.state.selectedVCInterior) {
                this.props.onShowAlert("warning", { message: "Select an Upholstery" });
                return;
            }
            else if (!this.state.selectedVCEquipment) {
                this.props.onShowAlert("warning", { message: "Select an Interior Equipment" });
                return;
            }
            else if (!this.state.selectedVCMechanical) {
                this.props.onShowAlert("warning", { message: "Select a Mechanical" });
                return;
            }
            else if (!this.state.selectedKeySet) {
                this.props.onShowAlert("warning", { message: "Select a Key" });
                return;
            }
            this.setState({ showSaveOfferSpinner: true });

            let vehicleOptions = [];
            if (this.state.selectedEngine != null) {
                vehicleOptions.push(this.state.selectedEngine);
            }
            if (this.state.selectedTransmission != null) {
                vehicleOptions.push(this.state.selectedTransmission);
            }
            if (this.state.selectedDriveTrain != null) {
                vehicleOptions.push(this.state.selectedDriveTrain);
            }
            let kbbExteriorColor = this.getKBBExteriorColor();
            let imageUrl = this.imageBaseUrl + this.state.vehicleOptions[0].vehicleId + "." + this.state.imageType.toLowerCase() + ".default.png";
            if (this.state.selectedEvoxExteriorColor != null) {
                if (kbbExteriorColor !== undefined) {
                    vehicleOptions.push(kbbExteriorColor);
                }
                if (this.state.selectedEvoxExteriorColor.code) {
                    imageUrl = this.imageBaseUrl + this.state.vehicleOptions[0].vehicleId + "." + this.state.imageType.toLowerCase() + "." + this.state.selectedEvoxExteriorColor.code + ".png";
                }
            }
            if (this.state.vehicleOptions && this.state.vehicleOptions.length > 0) {
                this.state.vehicleOptions.forEach(vehicleOption => {
                    if (vehicleOption.isChecked) {
                        vehicleOptions.push(vehicleOption);
                    }
                });
            }

            let selectedEvoxExteriorColor = this.state.selectedEvoxExteriorColor;
            let offerDate = moment.utc().format("yyyyMMDDTHHmmss");
            let inputData = {
                source: "DGDGDash",
                offerNumber: offerDate + "." + CommonService.createGuid(),
                // offerDate: moment(offerDate).format("yyyy-MM-DDTHH:mm:ss"),

                vin: this.state.vehicleVin,
                licensePlate: this.state.vehicleLP,
                licensePlateState: this.state.vehicleLPState,
                odometer: this.state.odometer,
                valuationZip: this.state.zipCode,
                imageUrl: imageUrl,
                colorCode: selectedEvoxExteriorColor?.code ?? "",
                colorTitle: selectedEvoxExteriorColor?.title ?? kbbExteriorColor.optionName,
                colorSimpleTitle: selectedEvoxExteriorColor?.simpletitle ?? kbbExteriorColor.optionName,
                colorRgb1: selectedEvoxExteriorColor?.rgb1 ?? "",
                colorRgb2: selectedEvoxExteriorColor?.rgb2 ?? "",

                year: this.state.selectedTrim.yearId,
                make: this.state.selectedTrim.makeName,
                makeId: this.state.selectedTrim.makeId,
                model: this.state.selectedTrim.modelName,
                modelId: this.state.selectedTrim.modelId,
                trim: this.state.selectedTrim.trimName,
                trimId: this.state.selectedTrim.trimId,
                vehicleId: this.state.vehicleOptions[0].vehicleId,

                owners: this.state.autoCheckData && this.state.autoCheckData.score ? this.state.autoCheckData.score[0].owneR_COUNT : "",
                accidentCount: this.state.autoCheckData && this.state.autoCheckData.history ? this.state.autoCheckData.history[0].accidenT_COUNT : "0",
                accidentResponse: this.state.selectedAccidentCount ? this.state.selectedAccidentCount.value : "",
                autocheckResponse: this.state.autoCheckAPIResponse,

                paintBody: this.state.selectedVCPaintBody ? this.state.selectedVCPaintBody.value : "",
                windowsLights: this.state.selectedVCWindowGlassExterior ? this.state.selectedVCWindowGlassExterior.value : "",
                wheelsTires: this.state.selectedVCWheelsTires ? this.state.selectedVCWheelsTires.value : "",
                upholstery: this.state.selectedVCInterior ? this.state.selectedVCInterior.value : "",
                interiorEquipment: this.state.selectedVCEquipment ? this.state.selectedVCEquipment.value : "",
                mechanical: this.state.selectedVCMechanical ? this.state.selectedVCMechanical.value : "",
                keys: this.state.selectedKeySet ? this.state.selectedKeySet.value : "",

                first: this.state.firstName,
                last: this.state.lastName,
                email: this.state.email,
                phone: this.state.phoneNumber,
                zip: this.state.homeZipCode,

                vehicleOptions: vehicleOptions
            };

            this.props.applicationInsights.trackTrace({ message: "/SellToDGDGCalculateOfferComponent/onCalculateOfferData", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SellToDGDG/GetOffer", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse.status !== "Created" && !parsedResponse.offer) {
                        this.setState({
                            showSaveOfferSpinner: false
                        }, () => {
                            this.props.onShowAlert("danger", { message: parsedResponse.errorMessage ? parsedResponse.errorMessage : parsedResponse.message });
                        });
                    }
                    if (parsedResponse.offer) {
                        this.setState({
                            showSaveOfferSpinner: false,
                            showOffer: true,
                            kbbCondition: parsedResponse.kbbCondition,
                            kbbMileageAdjustmentValue: parsedResponse.kbbMileageAdjustmentValue,
                            kbbOptionAdjustmentValue: parsedResponse.kbbOptionAdjustmentValue,
                            conditionFactor: parsedResponse.conditionFactor,
                            typicalListingPrice: parsedResponse.typicalListingPrice,
                            lowValue: parsedResponse.lowValue,
                            highValue: parsedResponse.highValue,
                            baseOfferRange: parsedResponse.baseOfferRange,
                            accidentDeduction: parsedResponse.accidentDeduction,
                            keyDeduction: parsedResponse.keyDeduction,
                            generalAdjustment: parsedResponse.generalAdjustment,
                            maxMileageAdjustment: parsedResponse.maxMileageAdjustment,
                            baseOffer: parsedResponse.baseOffer,
                            offerAmount: parsedResponse.offer,
                            calculationVariable: parsedResponse.calculationVariable,
                            message: parsedResponse.errorMessage,
                            offerNumber: parsedResponse.offerNumber
                        });
                    }
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SellToDGDG/SellToDGDGCalculateOffer", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getVehicleMakes() {
        try {
            this.setState({ showYMMSpinner: true });
            let inputData = {
                year: CommonService.formatYear(this.state.selectedYear)
            };

            this.props.applicationInsights.trackTrace({ message: "/SellToDGDGCalculateOfferComponent/getVehicleMakes", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SellToDGDG/GetVehicleMakes", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        makes: parsedResponse.items,
                        showYMMSpinner: false
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SellToDGDG/GetVehicleMakes", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getVehicleModels() {
        try {
            this.setState({ showYMMSpinner: true });
            let inputData = {
                makeId: this.state.selectedMake.makeId,
                year: CommonService.formatYear(this.state.selectedYear)
            };

            this.props.applicationInsights.trackTrace({ message: "/SellToDGDGCalculateOfferComponent/getVehicleModels", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SellToDGDG/GetVehicleModels", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        models: parsedResponse.items,
                        showYMMSpinner: false
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SellToDGDG/GetVehicleModels", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getTrims() {
        try {
            let inputData = {
                vin: this.state.requestOption.key === "VIN" ? this.state.vin : "",
                licensePlate: this.state.requestOption.key === "LicensePlate" ? this.state.licensePlate : "",
                licenseState: this.state.requestOption.key === "LicensePlate" && this.state.licenseState ? this.state.licenseState.key : "",
                makeId: this.state.requestOption.key === "YMM" && this.state.selectedMake ? this.state.selectedMake.makeId : "",
                year: this.state.requestOption.key === "YMM" && this.state.selectedYear ? CommonService.formatYear(this.state.selectedYear) : "",
                modelId: this.state.requestOption.key === "YMM" && this.state.selectedModel ? this.state.selectedModel.modelId : ""
            };

            this.props.applicationInsights.trackTrace({ message: "/SellToDGDGCalculateOfferComponent/getTrims", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SellToDGDG/GetVehicleTrims", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let vehicleVin = "", vehicleLP = "", vehicleLPState = "", trims = null, selectedTrim = null;
                    if (parsedResponse.Error) {
                        this.props.onShowAlert("danger", { message: parsedResponse.Error });
                    }
                    else if (parsedResponse.warnings) {
                        let message = [];
                        message.push((this.state.requestOption.key === "VIN" ? "VIN : " + this.state.vin : "License : " + this.state.licensePlate + " " + this.state.licenseState.key) + " not found.");
                        parsedResponse.warnings.forEach(warning => {
                            message.push(<br />);
                            message.push(warning.message);
                        })
                        this.props.onShowAlert("danger", { message: message });
                    }
                    else {
                        if (parsedResponse) {
                            vehicleVin = parsedResponse.vin;
                            vehicleLP = parsedResponse.licensePlate;
                            vehicleLPState = parsedResponse.licenseState;
                            if (parsedResponse.items) {
                                trims = parsedResponse.items;
                                if (trims.length > 0) {
                                    selectedTrim = trims[0];
                                }
                            }
                        }
                    }

                    this.setState({
                        showRefreshSpinner: false,
                        showVINSpinner: false,
                        showLPSpinner: false,
                        showYMMSpinner: false,
                        trims: trims,
                        selectedTrim: selectedTrim,
                        vehicleVin: vehicleVin,
                        vehicleLP: vehicleLP,
                        vehicleLPState: vehicleLPState
                    }, () => {
                        if (selectedTrim !== null) {
                            this.getVehicleOptions();
                        }
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        showVINSpinner: false,
                        showLPSpinner: false,
                        showYMMSpinner: false,
                        trims: null,
                        selectedTrim: null
                    });

                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SellToDGDG/GetVehicleTrims", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    refreshVehicleValues(event, checkedVehicleOption, isChecked) {
        try {
            if (!this.state.selectedEngine || !this.state.selectedTransmission || !this.state.selectedDriveTrain ||
                !this.state.vehicleOptions || !this.state.vehicleOptions.length === 0 || !this.state.zipCode)
                return;

            let vehicleOptionIds = [];
            if (this.state.vehicleOptions && this.state.vehicleOptions.length > 0) {
                this.state.vehicleOptions.forEach(vehicleOption => {
                    if (vehicleOption.isChecked && vehicleOption.vehicleOptionId) {
                        vehicleOptionIds.push(vehicleOption.vehicleOptionId);
                    }
                });
            }
            if (this.state.selectedEngine != null) {
                vehicleOptionIds.push(this.state.selectedEngine.vehicleOptionId);
            }
            if (this.state.selectedTransmission != null) {
                vehicleOptionIds.push(this.state.selectedTransmission.vehicleOptionId);
            }
            if (this.state.selectedDriveTrain != null) {
                vehicleOptionIds.push(this.state.selectedDriveTrain.vehicleOptionId);
            }
            if (this.state.selectedEvoxExteriorColor != null) {
                let kbbExteriorColor = this.getKBBExteriorColor();
                if (kbbExteriorColor !== undefined) {
                    vehicleOptionIds.push(kbbExteriorColor.vehicleOptionId);
                }
            }

            this.setState({ showRefreshSpinner: true });
            let inputData = {
                vehicleId: this.state.vehicleOptions[0].vehicleId,
                zipCode: this.state.zipCode,
                odometer: this.state.odometer,
                vehicleOptionIds: vehicleOptionIds
            };

            this.props.applicationInsights.trackTrace({ message: "/SellToDGDGCalculateOfferComponent/refreshVehicleValues", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SellToDGDG/GetKBBVehicleValues", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse.errors) {
                        this.props.onShowAlert("danger", { message: parsedResponse.errors[0].message }, 4000);
                    }
                    else {
                        let prices = CommonService.sortJsonData(parsedResponse.apiResponse.prices, "configuredValue", "", "decimal");
                        let rowIndex = 0;
                        let kbbComponents = [];
                        let optionAdjustomentAlerts = null;

                        let priceOption = prices.filter(price => price.priceTypeDisplay === "Trade-In Value Fair");
                        if (priceOption.length === 1) {
                            optionAdjustomentAlerts = this.state.fairAlerts;
                            this.addKBBRow(kbbComponents, priceOption[0], rowIndex++, optionAdjustomentAlerts, checkedVehicleOption, isChecked)
                        }

                        priceOption = prices.filter(price => price.priceTypeDisplay === "Trade-In Value Good");
                        if (priceOption.length === 1) {
                            optionAdjustomentAlerts = this.state.goodAlerts;
                            this.addKBBRow(kbbComponents, priceOption[0], rowIndex++, optionAdjustomentAlerts, checkedVehicleOption, isChecked)
                        }

                        priceOption = prices.filter(price => price.priceTypeDisplay === "Trade-In Value Very Good");
                        if (priceOption.length === 1) {
                            optionAdjustomentAlerts = this.state.veryGoodAlerts;
                            this.addKBBRow(kbbComponents, priceOption[0], rowIndex++, optionAdjustomentAlerts, checkedVehicleOption, isChecked)
                        }

                        priceOption = prices.filter(price => price.priceTypeDisplay === "Trade-In Value Excellent");
                        if (priceOption.length === 1) {
                            optionAdjustomentAlerts = this.state.excellentAlerts;
                            this.addKBBRow(kbbComponents, priceOption[0], rowIndex++, optionAdjustomentAlerts, checkedVehicleOption, isChecked)
                        }

                        priceOption = prices.filter(price => price.priceTypeDisplay === "Typical Listing Price");
                        if (priceOption.length === 1) {
                            optionAdjustomentAlerts = this.state.typicalListingPriceAlerts;
                            this.addKBBRow(kbbComponents, priceOption[0], rowIndex++, optionAdjustomentAlerts, checkedVehicleOption, isChecked)
                        }

                        priceOption = prices.filter(price => price.priceTypeDisplay === "Typical Listing Price (CPO)");
                        if (priceOption.length === 1) {
                            optionAdjustomentAlerts = this.state.typicalListingPriceCPOAlerts;
                            this.addKBBRow(kbbComponents, priceOption[0], rowIndex++, optionAdjustomentAlerts, checkedVehicleOption, isChecked)
                        }

                        if (checkedVehicleOption && optionAdjustomentAlerts) {
                            setTimeout((alertElementId) => {
                                let alertElement = $(alertElementId);
                                while (alertElement.length > 0) {
                                    alertElement.alert('close');
                                    alertElement = $(alertElementId);
                                }
                            }, 4000, "#kbbVOAlert" + (optionAdjustomentAlerts.length - 1));
                        }

                        this.setState({
                            kbbAPIRequest: parsedResponse.apiRequest,
                            kbbAPIResponse: parsedResponse.apiResponse,
                            pricesTableData: prices,
                            kbbComponents: kbbComponents,
                            showRefreshSpinner: false
                        });
                    }
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SellToDGDG/GetKBBVehicleValues", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    validateVehicleOptions(vehicleOption, isChecked) {
        try {
            if (vehicleOption.categoryGroup === "Wheels and Tires"
                && this.state.vehicleOptions.filter(VO => VO.categoryGroup === "Wheels and Tires" && VO.isChecked).length === 0
                && this.state.vehicleOptions.filter(VO => VO.categoryGroup === "Wheels and Tires" && VO.isTypical).length === 1) {
                this.state.vehicleOptions.filter(VO => VO.categoryGroup === "Wheels and Tires" && VO.isTypical)[0].isChecked = true;
            }

            let vehicleOptionIds = [];
            if (this.state.vehicleOptions && this.state.vehicleOptions.length > 0) {
                this.state.vehicleOptions.forEach(vehicleOption => {
                    if (vehicleOption.isChecked && vehicleOption.vehicleOptionId) {
                        vehicleOptionIds.push(vehicleOption.vehicleOptionId);
                    }
                });
            }
            if (this.state.selectedEngine != null) {
                vehicleOptionIds.push(this.state.selectedEngine.vehicleOptionId);
            }
            if (this.state.selectedTransmission != null) {
                vehicleOptionIds.push(this.state.selectedTransmission.vehicleOptionId);
            }
            if (this.state.selectedDriveTrain != null) {
                vehicleOptionIds.push(this.state.selectedDriveTrain.vehicleOptionId);
            }
            if (this.state.selectedEvoxExteriorColor != null) {
                let kbbExteriorColor = this.getKBBExteriorColor();
                if (kbbExteriorColor !== undefined) {
                    vehicleOptionIds.push(kbbExteriorColor.vehicleOptionId);
                }
            }

            this.setState({ showRefreshSpinner: true });
            let inputData = {
                vehicleId: this.state.vehicleOptions[0].vehicleId,
                vehicleOptionIds: vehicleOptionIds
            };

            this.props.applicationInsights.trackTrace({ message: "/SellToDGDGCalculateOfferComponent/validateVehicleOptions", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SellToDGDG/ValidateConfiguration", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse.errors) {
                        this.props.onShowAlert("danger", { message: parsedResponse.errors[0].message }, 4000);
                    }
                    else {
                        let refreshValues = true;
                        let vehicleOptions = this.state.vehicleOptions;
                        let validateReponse = JSON.parse(parsedResponse.apiResponse);
                        if (!validateReponse.isValid) {
                            validateReponse.warnings.forEach((warning) => {
                                switch (warning.code) {
                                    case "Configuration.SelectOneChildId":
                                        if (vehicleOption.vehicleOptionId === warning.parentId) {
                                            let childSelected = false;
                                            warning.childIds.forEach(childId => {
                                                let childVehicleOption = vehicleOptions.filter(vehicleOption => vehicleOption.vehicleOptionId === childId)[0];
                                                if (childVehicleOption.isChecked) {
                                                    if (!childSelected) {
                                                        childSelected = true;
                                                    }
                                                }
                                            });
                                            if (!childSelected) {
                                                vehicleOptions.filter(vehicleOption => vehicleOption.vehicleOptionId === warning.childIds[0])[0].isChecked = true;
                                            }
                                        }
                                        else {
                                            warning.childIds.forEach(childId => {
                                                if (vehicleOption.vehicleOptionId === childId) {
                                                    let deselectVehicleOption = vehicleOptions.filter(vehicleOption => vehicleOption.vehicleOptionId === warning.parentId)[0];
                                                    deselectVehicleOption.isChecked = false;
                                                    deselectVehicleOption.showWarning = true;
                                                    deselectVehicleOption.message = "Not supported without " + vehicleOption.optionName;
                                                }
                                            });
                                        }
                                        break;

                                    case "Configuration.DeselectAllChildIds":
                                        if (vehicleOption.vehicleOptionId === warning.parentId) {
                                            warning.childIds.forEach(childId => {
                                                let deselectVehicleOption = null;
                                                let conflictWithVehicleOption = null
                                                if (childId === this.state.selectedEngine.vehicleOptionId
                                                    || childId === this.state.selectedTransmission.vehicleOptionId
                                                    || childId === this.state.selectedDriveTrain.vehicleOptionId) {
                                                    deselectVehicleOption = vehicleOptions.filter(vehicleOption => vehicleOption.vehicleOptionId === warning.parentId)[0];
                                                    conflictWithVehicleOption = vehicleOptions.filter(vehicleOption => vehicleOption.vehicleOptionId === childId)[0];
                                                }
                                                else {
                                                    deselectVehicleOption = vehicleOptions.filter(vehicleOption => vehicleOption.vehicleOptionId === childId)[0];
                                                    conflictWithVehicleOption = vehicleOptions.filter(vehicleOption => vehicleOption.vehicleOptionId === warning.parentId)[0];
                                                }

                                                if (refreshValues && deselectVehicleOption && deselectVehicleOption.isChecked) {
                                                    deselectVehicleOption.isChecked = false;
                                                    deselectVehicleOption.showWarning = true;
                                                    deselectVehicleOption.message = "Not supported with " + conflictWithVehicleOption.optionName;
                                                    if (childId === this.state.selectedEngine.vehicleOptionId
                                                        || childId === this.state.selectedTransmission.vehicleOptionId
                                                        || childId === this.state.selectedDriveTrain.vehicleOptionId) {
                                                        refreshValues = false;
                                                    }
                                                }
                                            });
                                        }
                                        else {
                                            warning.childIds.forEach(childId => {
                                                if (childId === this.state.selectedEngine.vehicleOptionId
                                                    || childId === this.state.selectedTransmission.vehicleOptionId
                                                    || childId === this.state.selectedDriveTrain.vehicleOptionId) {
                                                    let deselectVehicleOption = vehicleOptions.filter(vehicleOption => vehicleOption.vehicleOptionId === warning.parentId)[0];
                                                    let conflictWithVehicleOption = vehicleOptions.filter(vehicleOption => vehicleOption.vehicleOptionId === childId)[0];

                                                    if (refreshValues && deselectVehicleOption && deselectVehicleOption.isChecked) {
                                                        deselectVehicleOption.isChecked = false;
                                                        deselectVehicleOption.showWarning = true;
                                                        deselectVehicleOption.message = "Not supported with " + conflictWithVehicleOption.optionName;
                                                        if (childId === this.state.selectedEngine.vehicleOptionId
                                                            || childId === this.state.selectedTransmission.vehicleOptionId
                                                            || childId === this.state.selectedDriveTrain.vehicleOptionId) {
                                                            refreshValues = false;
                                                        }
                                                    }
                                                }
                                            });
                                        }
                                        break;

                                    default:
                                        this.props.onShowAlert("danger", { message: "Unknown ValidateConfiguration: " + warning.code });
                                        break;
                                }
                            });
                        }

                        if (this.state.vehicleOptions.filter(VO => VO.categoryGroup === "Wheels and Tires" && VO.isChecked).length === 0
                            && this.state.vehicleOptions.filter(VO => VO.categoryGroup === "Wheels and Tires" && VO.isTypical).length === 1) {
                            this.state.vehicleOptions.filter(VO => VO.categoryGroup === "Wheels and Tires" && VO.isTypical)[0].isChecked = true;
                        }

                        this.setState({
                            vehicleOptions: vehicleOptions,
                            validationAPIRequest: parsedResponse.apiRequest,
                            validationAPIResponse: JSON.parse(parsedResponse.apiResponse),
                            showRefreshSpinner: false
                        }, () => {
                            if (refreshValues)
                                this.refreshVehicleValues(null, vehicleOption, isChecked);
                            this.populateOptions();
                        });
                    }
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SellToDGDG/ValidateConfiguration", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getKBBExteriorColor() {
        let kbbExteriorColor = this.state.kbbExteriorColors.filter(eCP => eCP.optionName.toLowerCase() === this.state.selectedEvoxExteriorColor.simpletitle.toLowerCase());
        if (kbbExteriorColor.length === 0) {
            kbbExteriorColor = this.state.evoxExteriorColors.filter(eCP => eCP.default === true)[0];
            kbbExteriorColor = this.state.kbbExteriorColors.filter(eCP => eCP.optionName.toLowerCase() === kbbExteriorColor.simpletitle.toLowerCase())[0];
            return kbbExteriorColor
        }
        else {
            return kbbExteriorColor[0];
        }
    }

    getVehicleOptions() {
        try {
            if (this.state.showRefreshSpinner)
                return;

            let vehicleYMM = this.state.selectedTrim.yearId + " " + this.state.selectedTrim.makeName + " " + this.state.selectedTrim.modelName;
            this.setState({
                showRefreshSpinner: true,
                vehicleYMM: vehicleYMM,
                vehicleId: this.state.selectedTrim.vehicleId
            });

            let inputData = {
                vehicleId: this.state.selectedTrim.vehicleId,
                makeId: this.state.selectedTrim.makeId,
                year: this.state.selectedTrim.yearId,
                modelId: this.state.selectedTrim.modelId,
                trimId: this.state.selectedTrim.trimId
            };

            this.props.applicationInsights.trackTrace({ message: "/SellToDGDGCalculateOfferComponent/getVehicleOptions", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SellToDGDG/GetVehicleOptions", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let vehicleOptions = JSON.parse(parsedResponse.apiResponse).items;
                    let engineOptions = [], transmissionOptions = [], driveTrainOptions = [], kbbExteriorColors = [], evoxExteriorColors = [];
                    let selectedEvoxExteriorColor = null;
                    //using YMM selection, the vehicleId is not available, so setting it from options data.
                    let vehicleId = this.state.selectedTrim?.vehicleId;
                    if (!vehicleId && vehicleOptions.length > 0 && vehicleOptions[0].vehicleId) {
                        vehicleId = vehicleOptions[0].vehicleId;
                    }
                    vehicleOptions.forEach((vehicleOption, index) => {
                        switch (vehicleOption.categoryGroup) {
                            case "Engine":
                                engineOptions.push(vehicleOption);
                                break;
                            case "Transmission":
                                transmissionOptions.push(vehicleOption);
                                break;
                            case "Drivetrain":
                                driveTrainOptions.push(vehicleOption);
                                break;
                            case "Exterior Color":
                                kbbExteriorColors.push(vehicleOption);
                                break;
                            default:
                                vehicleOption.isChecked = vehicleOption.isTypical;
                                break;
                        }
                    });

                    if (parsedResponse.oemColors && parsedResponse.oemColors.length > 0) {
                        evoxExteriorColors = parsedResponse.oemColors;
                        evoxExteriorColors = CommonService.sortJsonData(evoxExteriorColors, "whiteness", "desc");
                        evoxExteriorColors.forEach((oemColor) => {
                            oemColor.colorBox = "#" + oemColor.rgb1;
                            if (oemColor.default) {
                                selectedEvoxExteriorColor = oemColor;
                            }
                        });
                    }
                    else {
                        kbbExteriorColors.forEach((exteriorColor, index) => {
                            let oemColor = {
                                vifnum: null,
                                code: null,
                                title: exteriorColor.optionName,
                                simpletitle: exteriorColor.optionName,
                                rgb1: null,
                                rgb2: null,
                                shotin: null,
                                id: null,
                                default: null,
                                whiteness: null,
                                colorBox: exteriorColor.optionName
                            }
                            evoxExteriorColors.push(oemColor);
                        });
                    }

                    if (!selectedEvoxExteriorColor) {
                        selectedEvoxExteriorColor = evoxExteriorColors[0]
                    }

                    this.setState({
                        isImageAvailable: {
                            selectedImage: true,
                            thumbnailFront: true,
                            thumbnailSide: true,
                            thumbnailRear: true
                        },
                        imageType: "Front",
                        engineOptions: engineOptions,
                        selectedEngine: engineOptions.length === 1 ? engineOptions[0] : null,
                        transmissionOptions: transmissionOptions,
                        selectedTransmission: transmissionOptions.length === 1 ? transmissionOptions[0] : null,
                        driveTrainOptions: driveTrainOptions,
                        selectedDriveTrain: driveTrainOptions.length === 1 ? driveTrainOptions[0] : null,
                        kbbExteriorColors: kbbExteriorColors,
                        evoxExteriorColors: evoxExteriorColors,
                        selectedEvoxExteriorColor: selectedEvoxExteriorColor,
                        vehicleOptionsApiRequest: parsedResponse.apiRequest,
                        vehicleOptions: vehicleOptions,
                        showRefreshSpinner: false
                    }, () => {
                        this.refreshVehicleValues();
                        this.populateOptions();
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SellToDGDG/GetVehicleOptions", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getAutoCheck() {
        try {
            if (!this.state.vin && !this.state.licensePlate)
                return;
            this.setState({ showAutoCheckSpinner: true });
            let inputData = {
                vin: this.state.requestOption.key === "VIN" ? this.state.vin : "",
                licensePlate: this.state.requestOption.key === "LicensePlate" ? this.state.licensePlate : "",
                licenseState: this.state.requestOption.key === "LicensePlate" && this.state.licenseState ? this.state.licenseState.key : "",
            };

            this.props.applicationInsights.trackTrace({ message: "/SellToDGDGCalculateOfferComponent/getAutoCheck", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SellToDGDG/GetAutoCheck", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let data = parsedResponse.data
                    let accidentsMessage = "";
                    let selectedAccidentCount = null;
                    if (data.vindecode != null) {
                        switch (data.history[0].accidenT_COUNT) {
                            case "0":
                                selectedAccidentCount = this.accidentData[0];
                                break;
                            //case "1":
                            //    selectedAccidentCount = this.accidentData[1];
                            //    accidentsMessage = "1 accident found.";
                            //    break;
                            //case "2":
                            //    selectedAccidentCount = this.accidentData[2];
                            //    accidentsMessage = "2 accidents found.";
                            //    break;
                            default:
                                selectedAccidentCount = this.accidentData[1];
                                accidentsMessage = data.history[0].accidenT_COUNT + " accidents found.";
                                break;
                        }
                    }
                    this.setState({
                        autoCheckAPIRequest: parsedResponse.apiRequest,
                        autoCheckAPIResponse: parsedResponse.apiResponse,
                        autoCheckData: parsedResponse.data,
                        canShowApiResponse: parsedResponse.canShowApiResponse,
                        showAutoCheckSpinner: false,
                        selectedAccidentCount: selectedAccidentCount,
                        accidentsMessage: accidentsMessage
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        showAutoCheckSpinner: false,
                        autoCheckAPIRequest: "",
                        autoCheckAPIResponse: "",
                        autoCheckData: []
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SellToDGDG/GetAutoCheck", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    addKBBRow(kbbComponents, priceOption, rowIndex, optionAdjustomentAlerts, vehicleOption, isChecked) {
        if (!priceOption.condition) {
            priceOption.condition = priceOption.priceTypeDisplay;
        }

        if (vehicleOption && optionAdjustomentAlerts) {
            let optionAdjustment = null;
            if (isChecked) {
                if (priceOption.optionPrices) {
                    optionAdjustment = priceOption.optionPrices.filter(optionPrice => optionPrice.vehicleOptionId.toString() === vehicleOption.vehicleOptionId.toString());
                }
            } else {
                let previousVehicleOptions = this.state.pricesTableData.filter(price => price.priceTypeDisplay === priceOption.priceTypeDisplay)[0]
                optionAdjustment = previousVehicleOptions.optionPrices.filter(optionPrice => optionPrice.vehicleOptionId.toString() === vehicleOption.vehicleOptionId.toString());
            }

            if (optionAdjustment && optionAdjustment.length === 1) {
                let optionAdjustmentPrice = optionAdjustment[0].price;
                let optionAdjustmentCss = "";
                if (!isChecked) {
                    optionAdjustmentPrice *= -1;
                }
                if (optionAdjustmentPrice > 0) {
                    optionAdjustmentCss = "alert-success"
                } else {
                    optionAdjustmentCss = "alert-danger"
                }

                optionAdjustomentAlerts.push(<div key={optionAdjustomentAlerts.length} id={"kbbVOAlert" + optionAdjustomentAlerts.length} className={"dgdg-sell-trade-vo-alert alert " + optionAdjustmentCss} style={{ "marginLeft": "5px" }} role="alert">
                    {CommonService.formatSignedCurrency(optionAdjustmentPrice)}
                </div>);
            }
        }

        kbbComponents.push(
            <tr key={rowIndex}>
                <td key="condition" className="text-right">{priceOption.condition}</td>
                {
                    priceOption.priceTypeDisplay === "Trade-In Value Fair"
                        || priceOption.priceTypeDisplay === "Trade-In Value Good"
                        || priceOption.priceTypeDisplay === "Trade-In Value Very Good"
                        || priceOption.priceTypeDisplay === "Trade-In Value Excellent"
                        ? <Fragment>
                            <td key="rangeLow" className="text-center">{CommonService.formatSignedCurrency(priceOption.rangeLow)}</td>
                            <td key="configured" className="text-center">{CommonService.formatSignedCurrency(priceOption.configuredValue)}</td>
                            <td key="rangeHigh" className="text-center">{CommonService.formatSignedCurrency(priceOption.rangeHigh)}</td>
                        </Fragment>
                        : <td key="configured" colSpan="3" className="text-center">{CommonService.formatSignedCurrency(priceOption.configuredValue)}</td>
                }
                <td key="mileageAdjustment" className="text-center">{CommonService.formatSignedCurrency(priceOption.mileageAdjustment)}</td>
                <td key="optionAdjustment" className="text-center">
                    {CommonService.formatSignedCurrency(priceOption.optionAdjustment)}
                    <div className="position-absolute text-left" style={{ "marginTop": "-20px", "marginLeft": "70px" }}>
                        {optionAdjustomentAlerts}
                    </div>
                </td>
            </tr>
        );
    }

    populateOptions() {
        let allOptionList = [];
        let allCategoryOptions = [];
        let nonTypicalOptionList = [];
        let nonTypicalCategoryOptions = [];
        let currentCategory = "";
        let vehicleOptions = this.state.vehicleOptions;
        vehicleOptions.sort((row1, row2) => { return row1.sortOrder - row2.sortOrder }).forEach((vehicleOption, index) => {
            let category = vehicleOption.categoryGroup;
            if (currentCategory !== category) {
                if (allCategoryOptions.length > 0) {
                    allOptionList.push(<div key={"OT-T-" + index.toString()} className="col-8">
                        <br />
                        <span className="font-weight-bold dgdg-font-blue">{currentCategory}</span>
                        <div className="form-inline">
                            {allCategoryOptions}
                        </div>
                    </div>);
                }
                if (nonTypicalCategoryOptions.length > 0) {
                    nonTypicalOptionList.push(<div key={"OT-T-" + index.toString()} className="col-8">
                        <br />
                        <span className="font-weight-bold dgdg-font-blue">{currentCategory}</span>
                        <div className="form-inline">
                            {nonTypicalCategoryOptions}
                        </div>
                    </div>);
                }

                currentCategory = category;
                allCategoryOptions = [];
                nonTypicalCategoryOptions = [];
            }

            switch (vehicleOption.categoryGroup) {
                case "Engine":
                case "Transmission":
                case "Drivetrain":
                case "Exterior Color":
                    break;
                default:
                    let label = vehicleOption.optionName + " (" + vehicleOption.vehicleOptionId + ")"
                    let alertElementId = "otWarning" + index.toString();
                    if (vehicleOption.isTypical !== true) {
                        nonTypicalCategoryOptions.push(<DGDGFormCheckboxComponent key={"OT-" + index.toString()} label={label} additionalData={vehicleOption} onChange={this.onVehicleOptionChange} value={vehicleOption.isChecked === undefined ? vehicleOption.isTypical : vehicleOption.isChecked} colSpan="col-6" labelSpan="col-9" controlSpan="col-3">
                            {
                                vehicleOption.showWarning
                                    ? <div style={{ "margin": "5px 0px 0px 10px" }}>
                                        <div key={"key-" + alertElementId} id={alertElementId} className={"dgdg-sell-trade-vo-alert alert alert-danger"} role="alert">
                                            {vehicleOption.message}
                                        </div>
                                    </div>
                                    : null
                            }
                        </DGDGFormCheckboxComponent>);
                    }

                    allCategoryOptions.push(<DGDGFormCheckboxComponent key={"OT-" + index.toString()} label={label} additionalData={vehicleOption} onChange={this.onVehicleOptionChange} value={vehicleOption.isChecked === undefined ? vehicleOption.isTypical : vehicleOption.isChecked} colSpan="col-6" labelSpan="col-9" controlSpan="col-3">
                        {
                            vehicleOption.showWarning
                                ? <div style={{ "margin": "5px 0px 0px 10px" }}>
                                    <div key={"key-" + alertElementId} id={alertElementId} className={"dgdg-sell-trade-vo-alert alert alert-danger"} role="alert">
                                        {vehicleOption.message}
                                    </div>
                                </div>
                                : null
                        }
                    </DGDGFormCheckboxComponent>);

                    if (vehicleOption.showWarning) {
                        vehicleOption.showWarning = false;
                        $("#" + alertElementId).removeAttr("hidden");
                        setTimeout((alertElementId) => {
                            let alertElement = $(alertElementId);
                            if (alertElement.length > 0) {
                                alertElement[0].hidden = true
                            };
                        }, 4000, "#" + alertElementId);
                    }
                    break;
            }
        });

        if (allCategoryOptions.length > 0) {
            allOptionList.push(<div key={"optionType-" + allOptionList.length.toString()} className="col-8">
                <br />
                <span className="font-weight-bold dgdg-font-blue">{currentCategory}</span>
                <div className="form-inline">
                    {allCategoryOptions}
                </div>
            </div>);
        }
        if (nonTypicalCategoryOptions.length > 0) {
            nonTypicalOptionList.push(<div key={"optionType-" + nonTypicalOptionList.length.toString()} className="col-8">
                <br />
                <span className="font-weight-bold dgdg-font-blue">{currentCategory}</span>
                <div className="form-inline">
                    {nonTypicalCategoryOptions}
                </div>
            </div>);
        }

        this.setState({
            vehicleOptions: vehicleOptions,
            allOptionList: allOptionList,
            nonTypicalOptionList: nonTypicalOptionList
        });
    }

    clearVinDependents() {
        this.setState({
            vehicleVin: "",
            vehicleLP: "",
            vehicleLPState: "",
            vehicleYMM: "",
            showAutocheckAPIResponse: false,
            autoCheckAPIRequest: "",
            autoCheckAPIResponse: "",
            autoCheckData: [],
            selectedAccidentCount: null,
            accidentsMessage: "",
        });
    }

    clearTrimDependents() {
        this.setState({
            vehicleId: "",
            imageType: "Front",
            engineOptions: [],
            selectedEngine: null,
            transmissionOptions: [],
            selectedTransmission: null,
            driveTrainOptions: [],
            selectedDriveTrain: null,
            kbbExteriorColors: [],
            evoxExteriorColors: null,
            selectedEvoxExteriorColor: null,
            odometer: "",

            showAllOptions: false,
            showKBBOptionsAPIResponse: false,
            vehicleOptionsApiRequest: "",
            vehicleOptions: [],
            allOptionList: [],
            nonTypicalOptionList: [],
            showKBBAPIResponse: false,
            kbbAPIRequest: "",
            kbbAPIResponse: "",
            pricesTableData: [],
            fairAlerts: [],
            goodAlerts: [],
            veryGoodAlerts: [],
            excellentAlerts: [],
            typicalListingPriceAlerts: [],
            typicalListingPriceCPOAlerts: [],
            kbbComponents: [],
            showValidationAPIResponse: false,
            validationAPIRequest: "",
            validationAPIResponse: "",

            selectedVCPaintBody: null,
            selectedVCWindowGlassExterior: null,
            selectedVCWheelsTires: null,
            selectedVCInterior: null,
            selectedVCEquipment: null,
            selectedVCMechanical: null,
            selectedKeySet: null,

            //firstName: "",
            //lastName: "",
            //email: "",
            //phoneNumber: "",
            //homeZipCode: "",

            showOffer: false,
            kbbCondition: 0,
            kbbMileageAdjustmentValue: 0,
            kbbOptionAdjustmentValue: 0,
            conditionFactor: 0,
            typicalListingPrice: 0,
            lowValue: 0,
            highValue: 0,
            baseOfferRange: 0,
            accidentDeduction: 0,
            keyDeduction: 0,
            generalAdjustment: 0,
            maxMileageAdjustment: 0,
            baseOffer: 0,
            offerAmount: 0,
            calculationVariable: {},
            offerNumber: ""
        })
    }

    onMissingImage(event) {
        try {
            let isImageAvailable = this.state.isImageAvailable;
            isImageAvailable[event.target.id] = false;
            this.setState({
                isImageAvailable: isImageAvailable
            });

            CommonService.clientAzureStorageLog("SellToDGDGCalculateOfferComponent", "CDN image not found", "JsError", event.target.src, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        let evoxExteriorColors = [];
        if (this.state.evoxExteriorColors && !this.state.evoxExteriorColors.status) {
            this.state.evoxExteriorColors.forEach((oemColor, evoxOEMIndex) => {
                let rgbColor = oemColor.colorBox;
                evoxExteriorColors.push(<div key={"oem-color" + evoxOEMIndex} className={"d-inline-block dgdg-sell-trade-kbb-evox-oem-color" + (oemColor.default ? " dgdg-sell-trade-kbb-evox-default-color" : "")} style={{ "backgroundColor": rgbColor }} role="button" onClick={(event) => this.onEvoxExteriorColorClick(event, oemColor)} />);
            });
        }

        return <div className="dgdg-report-body">
            <div className="card">
                <div className="card-header">Sell To DGDG</div>
                <div className="card-block" style={{ "paddingLeft": "10px" }}>
                    <div className={"form-group col-12"}>
                        <table style={{ "width": "100%" }}>
                            <tbody>
                                <tr>
                                    <td style={{ "width": "33%" }}>
                                        <div className={"custom-control custom-radio"}>
                                            <input type="radio" id="rblRequestOption_1" name="rblRequestOption" className="input-group custom-control-input"
                                                onChange={(event) => this.onRadioButtonListChange("requestOption", this.requestOptionData[0])} checked={this.state.requestOption.key === this.requestOptionData[0].key ? true : false} />
                                            <label className="custom-control-label" htmlFor="rblRequestOption_1" style={{ "paddingTop": "2px" }}>
                                                {this.requestOptionData[0].value}
                                            </label>
                                        </div>
                                    </td>
                                    <td style={{ "width": "33%" }}>
                                        <div className={"custom-control custom-radio"}>
                                            <input type="radio" id="rblRequestOption_2" name="rblRequestOption" className="input-group custom-control-input"
                                                onChange={(event) => this.onRadioButtonListChange("requestOption", this.requestOptionData[1])} checked={this.state.requestOption.key === this.requestOptionData[1].key ? true : false} />
                                            <label className="custom-control-label" htmlFor="rblRequestOption_2" style={{ "paddingTop": "2px" }}>
                                                {this.requestOptionData[1].value}
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {
                                            this.state.requestOption.key === "VIN"
                                                ? <div className="form-inline">
                                                    <div className="form-row">
                                                        <DGDGFormInputComponent id="vin" label="VIN" value={this.state.vin} onChange={this.onTextChange} />
                                                        <button className="btn btn-primary dgdg-icon" onClick={(event) => this.onGetVehicleByVINClick(event)} disabled={this.state.showVINSpinner || this.state.vin.length !== 17}>
                                                            Get Vehicle<span className="fas fa-redo-alt dgdg-icon" />
                                                        </button>
                                                        <DGDGSpinnerComponent showSpinner={this.state.showVINSpinner} />
                                                    </div>
                                                </div>
                                                : null
                                        }
                                    </td>
                                    <td>
                                        {
                                            this.state.requestOption.key === "LicensePlate"
                                                ? <div className="form-inline">
                                                    <div className="form-row">
                                                        <DGDGFormInputComponent id="licensePlate" label="License Plate" value={this.state.licensePlate} onChange={this.onTextChange} colSpan="col-7" labelSpan="col-5" controlSpan="col-7" />
                                                        <DGDGFormDropdownComponent id="licenseState" label="State" data={this.state.usStates} itemKey="key" value={this.state.licenseState ? this.state.licenseState.key : "Select State"} onItemClick={this.onDropdownChange} colSpan="col-5" labelSpan="col-3" controlSpan="col-9" />
                                                        <button className="btn btn-primary dgdg-icon" onClick={(event) => this.onGetVehicleByLPClick(event)} disabled={this.state.showLPSpinner || this.state.licensePlate === "" || this.state.licenseState === null}>
                                                            Get Vehicle<span className="fas fa-redo-alt dgdg-icon" />
                                                        </button>
                                                        <DGDGSpinnerComponent showSpinner={this.state.showLPSpinner} />
                                                    </div>
                                                </div>
                                                : null
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="dgdg-site-scrollable-content">
                        <div className="position-relative">
                            <span className="dgdg-sell-trade-header">VEHICLE DETAILS:</span> {this.state.vehicleYMM + (this.state.vehicleVin ? ", " + this.state.vehicleVin : "") + (this.state.vehicleLP ? ", " + this.state.vehicleLP : "")}
                            {
                                this.state.vehicleOptions.length > 0
                                    ? <div style={{ "position": "absolute", "left": "35%" }} className="text-center">
                                        {
                                            this.state.evoxExteriorColors
                                                ? <div className="text-center">
                                                    <div className="dgdg-sell-trade-kbb-evox-image">
                                                        {
                                                            this.state.isImageAvailable["selectedImage"]
                                                                ? <img id="selectedImage" height="100%" width="100%" src={this.imageBaseUrl + this.state.vehicleOptions[0].vehicleId + "." + this.state.imageType.toLowerCase() + "." + (this.state.selectedEvoxExteriorColor?.code ?? "default") + ".png"} alt="EVOX" onError={(event) => this.onMissingImage(event)} />
                                                                : <div className="dgdg-sell-trade-selected-image-water-mark">No Image Available.</div>
                                                        }
                                                    </div>
                                                    <a className="btn btn-link" href={"/EVOXImages/KBBEVOXMapping/" + this.state.vehicleId}>
                                                        {this.state.selectedEvoxExteriorColor ? this.state.selectedEvoxExteriorColor.title : ""} {this.state.imageType} Profile
                                                    </a>
                                                    <div>
                                                        <div className="dgdg-sell-trade-kbb-evox-thumbnail">
                                                            <div className="dgdg-sell-trade-kbb-evox-oem-colors">
                                                                {evoxExteriorColors}
                                                            </div>
                                                            <div style={{ "width": "195px" }}>
                                                                {
                                                                    this.state.selectedEvoxExteriorColor
                                                                        ? this.state.selectedEvoxExteriorColor.title + " (" + this.state.selectedEvoxExteriorColor.simpletitle + ")"
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="dgdg-sell-trade-kbb-evox-thumbnail">
                                                            <div className="dgdg-icon dgdg-sell-trade-kbb-evox-thumbnail-image">
                                                                {
                                                                    this.state.isImageAvailable["thumbnailFront"]
                                                                        ? <img id="thumbnailFront" src={this.imageBaseUrl + this.state.vehicleOptions[0].vehicleId + ".front." + (this.state.selectedEvoxExteriorColor?.code ?? "default") + ".png"} height="100%" width="100%" alt="Front" role="button" onClick={(event) => this.onEVOXImageClick(event, "Front")} onError={(event) => this.onMissingImage(event)} />
                                                                        : <div className="dgdg-sell-trade-thumbnail-water-mark">No Image Available.</div>
                                                                }
                                                            </div>
                                                            Front
                                                        </div>
                                                        <div className="dgdg-icon dgdg-sell-trade-kbb-evox-thumbnail">
                                                            <div className="dgdg-sell-trade-kbb-evox-thumbnail-image">
                                                                {
                                                                    this.state.isImageAvailable["thumbnailSide"]
                                                                        ? <img id="thumbnailSide" src={this.imageBaseUrl + this.state.vehicleOptions[0].vehicleId + ".side." + (this.state.selectedEvoxExteriorColor?.code ?? "default") + ".png"} height="100%" width="100%" alt="Side" role="button" onClick={(event) => this.onEVOXImageClick(event, "Side")} onError={(event) => this.onMissingImage(event)} />
                                                                        : <div className="dgdg-sell-trade-thumbnail-water-mark">No Image Available.</div>
                                                                }
                                                            </div>
                                                            Side
                                                        </div>
                                                        <div className="dgdg-icon dgdg-sell-trade-kbb-evox-thumbnail">
                                                            <div className="dgdg-sell-trade-kbb-evox-thumbnail-image">
                                                                {
                                                                    this.state.isImageAvailable["thumbnailRear"]
                                                                        ? <img id="thumbnailRear" src={this.imageBaseUrl + this.state.vehicleOptions[0].vehicleId + ".rear." + (this.state.selectedEvoxExteriorColor?.code ?? "default") + ".png"} height="100%" width="100%" alt="Rear" role="button" onClick={(event) => this.onEVOXImageClick(event, "Rear")} onError={(event) => this.onMissingImage(event)} />
                                                                        : <div className="dgdg-sell-trade-thumbnail-water-mark">No Image Available.</div>
                                                                }
                                                            </div>
                                                            Rear
                                                        </div>
                                                    </div>
                                                </div>
                                                : <div className="dgdg-sell-trade-photo-water-mark">No Image Available.</div>
                                        }
                                    </div>
                                    : null
                            }
                        </div>
                        <DGDGFormDropdownComponent label="Trim" data={this.state.trims} itemKey="trimName" onItemClick={this.onTrimChange} value={this.state.selectedTrim ? this.state.selectedTrim.trimName : "Select Trim"} colSpan="col-4" />
                        <DGDGFormInputComponent id="zipCode" label="Zip Code" disabled={true} value={this.state.zipCode} onChange={this.onTextChange} colSpan="col-4" />
                        {
                            this.state.selectedTrim
                                ? <Fragment>
                                    <DGDGModalDialogComponent
                                        applicationInsights={this.props.applicationInsights}
                                        title={"KBB Options API Response: " + this.state.selectedTrim.trimName}
                                        showDialog={this.state.showKBBOptionsAPIResponse}
                                        cssName="dgdg-sell-trade-api-response-dialog"
                                        onCancelClick={(event) => { this.setState({ showKBBOptionsAPIResponse: false }); }}>
                                        <DGDGModalDialogHeaderButtonComponent>
                                            <button className="btn btn-primary dgdg-copy-button" onClick={(event) => this.onCopyToClipboard(event, JSON.stringify(this.state.vehicleOptionsApiRequest))}>
                                                Copy Request<span className="far fa-copy dgdg-icon" />
                                            </button>
                                            <button className="btn btn-primary dgdg-copy-button" onClick={(event) => this.onCopyToClipboard(event, JSON.stringify(this.state.vehicleOptions))}>
                                                Copy Response<span className="far fa-copy dgdg-icon" />
                                            </button>
                                        </DGDGModalDialogHeaderButtonComponent>
                                        <div className="dgdg-report-body">
                                            <div className="card">
                                                <div className="card-block">
                                                    <div className="form">
                                                        <DGDGFormTextAreaComponent value={JSON.stringify(this.state.vehicleOptionsApiRequest)} rows="1" readOnly />
                                                        <br />
                                                        <DGDGFormTextAreaComponent value={JSON.stringify(this.state.vehicleOptions)} rows="10" readOnly />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </DGDGModalDialogComponent>

                                    <DGDGModalDialogComponent
                                        applicationInsights={this.props.applicationInsights}
                                        title={"KBB API Response: " + this.state.selectedTrim.trimName}
                                        showDialog={this.state.showKBBAPIResponse}
                                        cssName="dgdg-sell-trade-api-response-dialog"
                                        onCancelClick={(event) => { this.setState({ showKBBAPIResponse: false }); }}>
                                        <DGDGModalDialogHeaderButtonComponent>
                                            <button className="btn btn-primary dgdg-copy-button" onClick={(event) => this.onCopyToClipboard(event, JSON.stringify(this.state.kbbAPIRequest))}>
                                                Copy Request<span className="far fa-copy dgdg-icon" />
                                            </button>
                                            <button className="btn btn-primary dgdg-copy-button" onClick={(event) => this.onCopyToClipboard(event, JSON.stringify(this.state.kbbAPIResponse))}>
                                                Copy Response<span className="far fa-copy dgdg-icon" />
                                            </button>
                                        </DGDGModalDialogHeaderButtonComponent>
                                        <div className="dgdg-report-body">
                                            <div className="card">
                                                <div className="card-block">
                                                    <div className="form">
                                                        <DGDGFormTextAreaComponent id="kbbAPIRequest" value={JSON.stringify(this.state.kbbAPIRequest)} rows="10" readOnly />
                                                        <br />
                                                        <DGDGFormTextAreaComponent id="kbbAPIResponse" value={JSON.stringify(this.state.kbbAPIResponse)} rows="10" readOnly />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </DGDGModalDialogComponent>

                                    <DGDGModalDialogComponent
                                        applicationInsights={this.props.applicationInsights}
                                        title={"Validate Configuration API Response: " + this.state.selectedTrim.trimName}
                                        showDialog={this.state.showValidationAPIResponse}
                                        cssName="dgdg-sell-trade-api-response-dialog"
                                        onCancelClick={(event) => { this.setState({ showValidationAPIResponse: false }); }}>
                                        <DGDGModalDialogHeaderButtonComponent>
                                            <button className="btn btn-primary dgdg-copy-button" onClick={(event) => this.onCopyToClipboard(event, JSON.stringify(this.state.validationAPIRequest))}>
                                                Copy Request<span className="far fa-copy dgdg-icon" />
                                            </button>
                                            <button className="btn btn-primary dgdg-copy-button" onClick={(event) => this.onCopyToClipboard(event, JSON.stringify(this.state.validationAPIResponse))}>
                                                Copy Response<span className="far fa-copy dgdg-icon" />
                                            </button>
                                        </DGDGModalDialogHeaderButtonComponent>
                                        <div className="dgdg-report-body">
                                            <div className="card">
                                                <div className="card-block">
                                                    <div className="form">
                                                        <DGDGFormTextAreaComponent id="validationAPIRequest" value={JSON.stringify(this.state.validationAPIRequest)} rows="5" readOnly />
                                                        <br />
                                                        <DGDGFormTextAreaComponent id="validationAPIResponse" value={JSON.stringify(this.state.validationAPIResponse)} rows="10" readOnly />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </DGDGModalDialogComponent>

                                    <DGDGModalDialogComponent
                                        applicationInsights={this.props.applicationInsights}
                                        title={"Autocheck API Response: " + this.state.selectedTrim.trimName}
                                        showDialog={this.state.showAutocheckAPIResponse}
                                        cssName="dgdg-sell-trade-api-response-dialog"
                                        onCancelClick={(event) => { this.setState({ showAutocheckAPIResponse: false }); }}>
                                        <DGDGModalDialogHeaderButtonComponent>
                                            <button className="btn btn-primary dgdg-copy-button" onClick={(event) => this.onCopyToClipboard(event, JSON.stringify(this.state.autoCheckAPIRequest))}>
                                                Copy Request<span className="far fa-copy dgdg-icon" />
                                            </button>
                                            <button className="btn btn-primary dgdg-copy-button" onClick={(event) => this.onCopyToClipboard(event, JSON.stringify(this.state.autoCheckAPIResponse))}>
                                                Copy Response<span className="far fa-copy dgdg-icon" />
                                            </button>
                                        </DGDGModalDialogHeaderButtonComponent>
                                        <div className="dgdg-report-body">
                                            <div className="card">
                                                <div className="card-block">
                                                    <div className="form">
                                                        <DGDGFormTextAreaComponent id="autoCheckAPIRequest" value={JSON.stringify(this.state.autoCheckAPIRequest)} rows="1" readOnly />
                                                        <br />
                                                        <DGDGFormTextAreaComponent id="autoCheckAPIResponse" value={JSON.stringify(this.state.autoCheckAPIResponse)} rows="10" readOnly />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </DGDGModalDialogComponent>

                                    <DGDGFormDropdownComponent id="selectedEngine" label="Engine" data={this.state.engineOptions} itemKey="optionName" value={this.state.selectedEngine ? this.state.selectedEngine.optionName : "Select Engine"} onItemClick={this.onOptionDropdownChange} colSpan="col-4" />
                                    <DGDGFormDropdownComponent id="selectedTransmission" label="Transmission" data={this.state.transmissionOptions} itemKey="optionName" value={this.state.selectedTransmission ? this.state.selectedTransmission.optionName : "Select Transmission"} onItemClick={this.onOptionDropdownChange} colSpan="col-4" />
                                    <DGDGFormDropdownComponent id="selectedDriveTrain" label="Drivetrain" data={this.state.driveTrainOptions} itemKey="optionName" value={this.state.selectedDriveTrain ? this.state.selectedDriveTrain.optionName : "Select Drivetrain"} onItemClick={this.onOptionDropdownChange} colSpan="col-4" />
                                    <DGDGFormDropdownComponent id="selectedEvoxExteriorColor" label="Exterior Color" data={this.state.evoxExteriorColors} itemKey="title" value={this.state.selectedEvoxExteriorColor ? this.state.selectedEvoxExteriorColor.title : "Select Exterior Color"} onItemClick={this.onOptionDropdownChange} colSpan="col-4" />
                                    <DGDGFormInputComponent id="odometer" label="Odometer" value={this.state.odometer} onChange={this.onOdometerChange} colSpan="col-4" />
                                    <div className="form-inline">
                                        <button className="btn btn-primary dgdg-icon" style={{ "marginTop": "10px" }} onClick={(event) => this.onToggleAllOptionsClick(event)}>Show/Hide All Options</button>
                                        {
                                            this.state.canShowApiResponse
                                                ? <Fragment>
                                                    <button className="btn btn-primary dgdg-icon" style={{ "marginTop": "10px" }} onClick={(event) => { this.setState({ showKBBOptionsAPIResponse: true }); }}>KBB Options API Response</button>
                                                    <button className="btn btn-primary dgdg-icon" style={{ "marginTop": "10px" }} onClick={(event) => { this.setState({ showValidationAPIResponse: true }); }}>Validate Configuration API Response</button>
                                                </Fragment>
                                                : null
                                        }
                                        <DGDGSpinnerComponent showSpinner={this.state.showRefreshSpinner} />
                                    </div>
                                    {
                                        this.state.allOptionList.length > 0 || this.state.nonTypicalOptionList.length > 0
                                            ? this.state.showAllOptions
                                                ? this.state.allOptionList
                                                : this.state.nonTypicalOptionList
                                            : null
                                    }
                                    <table className="dgdg-sell-trade-table-result">
                                        <thead>
                                            <tr>
                                                <td colSpan="6" style={{ backgroundColor: "#FFFFFF" }}>KBB VALUES: {this.state.vehicleYMM + " " + this.state.selectedTrim.trimName}
                                                    {
                                                        this.state.kbbAPIResponse && this.state.canShowApiResponse
                                                            ? <button className="btn btn-primary dgdg-icon" onClick={(event) => { this.setState({ showKBBAPIResponse: true }); }}>KBB API Response</button>
                                                            : null
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td />
                                                <td colSpan="3">Range:</td>
                                                <td rowSpan="2" className="text-center">Mileage Adjustment</td>
                                                <td rowSpan="2" className="text-center">Option Adjustment</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">Condition:</td>
                                                <td className="text-center">Low</td>
                                                <td className="text-center">Configured</td>
                                                <td className="text-center">High</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.kbbComponents}
                                        </tbody>
                                    </table>
                                    {
                                        this.state.vin === "" && this.state.licensePlate === ""
                                            ? <Fragment>
                                                <div className="dgdg-sell-trade-autocheck-water-mark">Vin Needed</div>
                                                <table className="dgdg-sell-trade-autocheck-table-result-disabled">
                                                    <thead>
                                                        <tr>
                                                            <td colSpan="2">AUTOCHECK VALUES:&nbsp;{this.state.vehicleYMM + (this.state.vehicleVin ? ", " + this.state.vehicleVin : "") + (this.state.vehicleLP ? ", " + this.state.vehicleLP : "")}</td>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </Fragment>
                                            : <table className="dgdg-sell-trade-autocheck-table-result">
                                                <thead>
                                                    <tr>
                                                        <td colSpan="2" style={{ backgroundColor: "#FFFFFF" }}>AUTOCHECK VALUES:&nbsp;{this.state.vehicleYMM + (this.state.vehicleVin ? ", " + this.state.vehicleVin : "") + (this.state.vehicleLP ? ", " + this.state.vehicleLP : "")}
                                                            {
                                                                this.state.autoCheckAPIResponse !== "" && this.state.canShowApiResponse
                                                                    ? <button className="btn btn-primary dgdg-icon" onClick={(event) => { this.setState({ showAutocheckAPIResponse: true }); }}>AutoCheck API Response</button>
                                                                    : null
                                                            }
                                                            <DGDGSpinnerComponent showSpinner={this.state.showAutoCheckSpinner} />
                                                        </td>
                                                    </tr>
                                                </thead>
                                                {
                                                    this.state.autoCheckData.vindecode
                                                        ? <tbody>
                                                            <tr>
                                                                <td className="text-right">Number Of Accidents</td>
                                                                <td>{this.state.autoCheckData.history[0].accidenT_COUNT}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-right">Number Of Owners</td>
                                                                <td>{this.state.autoCheckData.score[0].owneR_COUNT}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-right">Last Odometer Reading</td>
                                                                <td>{CommonService.formatNumber(this.state.autoCheckData.history[0].lasT_ODOMETER)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-right">Categories</td>
                                                                <td>
                                                                    {
                                                                        this.state.autoCheckData.hdata
                                                                            ? this.state.autoCheckData.hdata.filter(history =>
                                                                                history.category === "Abandoned" || history.category === "Fire" || history.category === "Junk"
                                                                                || history.category === "Rebuilt" || history.category === "Buyback" || history.category === "Flood"
                                                                                || history.category === "Lemon" || history.category === "Rental" || history.category === "Canadian"
                                                                                || history.category === "Frame" || history.category === "Livery" || history.category === "Repossessed"
                                                                                || history.category === "Commercial" || history.category === "Government" || history.category === "Loss"
                                                                                || history.category === "Salvage" || history.category === "Crash Test" || history.category === "Grey Market"
                                                                                || history.category === "Major Damage" || history.category === "Taxi" || history.category === "Education"
                                                                                || history.category === "Hail" || history.category === "Odometer Problem" || history.category === "Theft"
                                                                                || history.category === "Exported" || history.category === "Impound" || history.category === "Police"
                                                                                || history.category === "Water")
                                                                                .map(history => history.category).join(', ')
                                                                            : ""
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        : null
                                                }
                                            </table>
                                    }
                                    <br />
                                    <div className="dgdg-sell-trade-header">VEHICLE CONDITION - Accidents</div>
                                    {
                                        this.state.accidentsMessage
                                            ? <Fragment>
                                                {this.state.accidentsMessage}
                                                <br />
                                            </Fragment>
                                            : <div className="form-inline">
                                                <div>Any accidents?</div>
                                                <DGDGFormRadioComponent name="rblAccidentCount" id="selectedAccidentCount" label={"Has your " + this.state.selectedTrim.trimName + " been in any accidents"} data={this.accidentData} itemKey="value" value={this.state.selectedAccidentCount ? this.state.selectedAccidentCount.value : ""} onChange={this.onRadioButtonListChange} colSpan="col-4" />
                                            </div>
                                    }
                                    <br />
                                    <div className="dgdg-sell-trade-header">VEHICLE CONDITION </div>
                                    <br />
                                    <div className="dgdg-sell-trade-header">Paint and Body</div>
                                    <div className="form-inline">
                                        <DGDGFormRadioComponent id="selectedVCPaintBody" name="rblPaintAndBodyCondition" data={this.paintsAndBodyConditionData} itemKey="value" value={this.state.selectedVCPaintBody ? this.state.selectedVCPaintBody.value : ""} onChange={this.onRadioButtonListChange} colSpan="col-6" controlSpan="col-3" />
                                    </div>
                                    {
                                        this.state.selectedVCPaintBody
                                            ? <div className="dgdg-sell-trade-radio-button-conditional-text"> {this.state.selectedVCPaintBody.description} </div>
                                            : ""
                                    }
                                    <div className="dgdg-sell-trade-header">Wheels and Tires</div>
                                    <div className="form-inline">
                                        <DGDGFormRadioComponent id="selectedVCWheelsTires" name="rblWheelsAndTires" data={this.wheelsAndTiresConditionData} itemKey="value" value={this.state.selectedVCWheelsTires ? this.state.selectedVCWheelsTires.value : ""} onChange={this.onRadioButtonListChange} colSpan="col-6" controlSpan="col-3" />
                                    </div>
                                    {
                                        this.state.selectedVCWheelsTires
                                            ? <div className="dgdg-sell-trade-radio-button-conditional-text"> {this.state.selectedVCWheelsTires.description} </div>
                                            : ""
                                    }
                                    <div className="dgdg-sell-trade-header">Windows and Lights</div>
                                    <div className="form-inline">
                                        <DGDGFormRadioComponent id="selectedVCWindowGlassExterior" name="rblWindwosAndGlass" data={this.windowGlassAndExteriorLightingData} itemKey="value" value={this.state.selectedVCWindowGlassExterior ? this.state.selectedVCWindowGlassExterior.value : ""} onChange={this.onRadioButtonListChange} colSpan="col-6" controlSpan="col-3" />
                                    </div>
                                    {
                                        this.state.selectedVCWindowGlassExterior
                                            ? <div className="dgdg-sell-trade-radio-button-conditional-text"> {this.state.selectedVCWindowGlassExterior.description} </div>
                                            : ""
                                    }
                                    <div className="dgdg-sell-trade-header">Upholstery and Odors</div>
                                    <div className="form-inline">
                                        <DGDGFormRadioComponent id="selectedVCInterior" name="rblInteriorCondition" data={this.interiorConditionData} itemKey="value" value={this.state.selectedVCInterior ? this.state.selectedVCInterior.value : ""} onChange={this.onRadioButtonListChange} colSpan="col-6" controlSpan="col-3" />
                                    </div>
                                    {
                                        this.state.selectedVCInterior
                                            ? <div className="dgdg-sell-trade-radio-button-conditional-text"> {this.state.selectedVCInterior.description} </div>
                                            : ""
                                    }
                                    <div className="dgdg-sell-trade-header">Interior Equipment</div>
                                    <div className="form-inline">
                                        <DGDGFormRadioComponent id="selectedVCEquipment" name="rblVehicleEquipment" data={this.vehicleEquipmentData} itemKey="value" value={this.state.selectedVCEquipment ? this.state.selectedVCEquipment.value : ""} onChange={this.onRadioButtonListChange} colSpan="col-6" controlSpan="col-3" />
                                    </div>
                                    {
                                        this.state.selectedVCEquipment
                                            ? <div className="dgdg-sell-trade-radio-button-conditional-text"> {this.state.selectedVCEquipment.description} </div>
                                            : ""
                                    }
                                    <div className="dgdg-sell-trade-header">Mechanical</div>
                                    <div className="form-inline">
                                        <DGDGFormRadioComponent id="selectedVCMechanical" name="rblMechanicalCondition" data={this.mechanicalConditionData} itemKey="value" value={this.state.selectedVCMechanical ? this.state.selectedVCMechanical.value : ""} onChange={this.onRadioButtonListChange} colSpan="col-6" controlSpan="col-3" />
                                    </div>
                                    {
                                        this.state.selectedVCMechanical
                                            ? <div className="dgdg-sell-trade-radio-button-conditional-text"> {this.state.selectedVCMechanical.description} </div>
                                            : ""
                                    }
                                    <div className="dgdg-sell-trade-header">Keys</div>
                                    <div className="dgdg-font-blue" style={{ "marginLeft": "12px" }}>Does your vehicle have 2 or more sets of keys?</div>
                                    <div className="form-inline">
                                        <DGDGFormRadioComponent id="selectedKeySet" name="rblMoreSetOfKeys" data={this.keySetData} itemKey="value" value={this.state.selectedKeySet ? this.state.selectedKeySet.value : ""} onChange={this.onRadioButtonListChange} colSpan="col-4" controlSpan="col-3" />
                                    </div>

                                    {/*"Get My Offer" sends email and generates the offer when lead detail is provided. Uncomment the Lead form to generate offers when user clicks on Get My Offer.*/}
                                    {/*<div className="dgdg-sell-trade-header">LEAD FORM</div>*/}
                                    {/*<DGDGFormInputComponent id="firstName" label="First Name" value={this.state.firstName} onChange={this.onTextChange} colSpan="col-4" />*/}
                                    {/*<DGDGFormInputComponent id="lastName" label="Last Name" value={this.state.lastName} onChange={this.onTextChange} colSpan="col-4" />*/}
                                    {/*<DGDGFormInputComponent id="email" label="Email" value={this.state.email} onChange={this.onTextChange} colSpan="col-4" />*/}
                                    {/*<DGDGFormInputComponent id="phoneNumber" label="Phone Number" value={this.state.phoneNumber} onChange={this.onTextChange} colSpan="col-4" />*/}
                                    {/*<DGDGFormInputComponent id="homeZipCode" label="Home Zip Code" value={this.state.homeZipCode} onChange={this.onTextChange} colSpan="col-4" />*/}

                                    <div className="form-inline">
                                        <button className="btn btn-primary" onClick={(event) => this.onCalculateOfferData(event)} disabled={!this.state.selectedTrim}>
                                            Get My Offer<span className="fas fa-redo-alt dgdg-icon" />
                                        </button>
                                        <DGDGSpinnerComponent showSpinner={this.state.showSaveOfferSpinner} />
                                    </div>
                                    {
                                        this.state.showOffer
                                            ? this.state.offerAmount
                                                ? <center>
                                                    <div className="dgdg-report-body dgdg-sell-trade-final-dialog">
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td valign="top">
                                                                        <div className="card">
                                                                            <div className="card-header">Calculation Variable</div>
                                                                            <div className="card-block">
                                                                                <table className="text-nowrap dgdg-sell-trade-final">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td className="dgdg-font-blue">Low Value Condition</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td><DGDGFormInputComponent disabled={true} id="low_value_condition" value={this.state.calculationVariable.value.lowValueCondition} hideClear /></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="dgdg-font-blue">High Value Condition</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td><DGDGFormInputComponent disabled={true} id="high_value_condition" value={this.state.calculationVariable.value.highValueVondition} hideClear /></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="dgdg-font-blue">Excellent Condition (0-1)</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td><DGDGFormInputComponent disabled={true} id="excellent_condition" value={this.state.calculationVariable.value.excellentCondition} hideClear /></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="dgdg-font-blue">Very Good Condition (0-1)</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td><DGDGFormInputComponent disabled={true} id="very_good_condition" value={this.state.calculationVariable.value.veryGoodCondition} hideClear /></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="dgdg-font-blue">Good Condition (0-1)</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td><DGDGFormInputComponent disabled={true} id="good_condition" value={this.state.calculationVariable.value.goodCondition} hideClear /></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="dgdg-font-blue">Fair Condition (0-1)</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td><DGDGFormInputComponent disabled={true} id="fair_condition" value={this.state.calculationVariable.value.fairCondition} hideClear /></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="dgdg-font-blue">Accident Deduction (%)</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td><DGDGFormInputComponent disabled={true} id="accident_deduction_pct" value={CommonService.formatNumber_1(this.state.calculationVariable.value.accidentDeductionPct * 100)} inputType="percent" inputCssName={this.state.calculationVariable.value.accidentDeductionPct < 0 ? "text-danger" : ""} hideClear /></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="dgdg-font-blue">Missing Key Deduction (%)</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td><DGDGFormInputComponent disabled={true} id="missing_key_deduction" value={CommonService.formatNumber_1(this.state.calculationVariable.value.missingKeyDeduction * 100)} inputType="percent" inputCssName={this.state.calculationVariable.value.missingKeyDeduction < 0 ? "text-danger" : ""} hideClear /></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="dgdg-font-blue">General Adjustment (%)</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td><DGDGFormInputComponent disabled={true} id="general_adjustment" value={CommonService.formatNumber_1(this.state.calculationVariable.value.generalAdjustmentPct * 100)} inputType="percent" inputCssName={this.state.calculationVariable.value.generalAdjustmentPct < 0 ? "text-danger" : ""} hideClear /></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="dgdg-font-blue">Max Mileage Adj Deduction (%)</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td><DGDGFormInputComponent disabled={true} id="max_mileage_adjustment_pct" value={CommonService.formatNumber_1(this.state.calculationVariable.value.maxMileageAdjustmentPct * 100)} inputType="percent" inputCssName={this.state.calculationVariable.value.maxMileageAdjustmentPct < 0 ? "text-danger" : ""} hideClear /></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="dgdg-font-blue">Enable Offer Calcuation</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td><DGDGFormInputComponent disabled={true} id="enable_offer_calculation" value={this.state.calculationVariable.value.enableOfferCalculation} hideClear /></td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td style={{ "width": "12px" }}></td>
                                                                    <td valign="top">
                                                                        <div className="card">
                                                                            <div className="card-header">Final Calculation</div>
                                                                            <div className="card-block">
                                                                                <table className="dgdg-sell-trade-final">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td className="dgdg-font-blue text-right">1.</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td className="dgdg-font-blue">KBB Typical Listing Price</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td><DGDGFormTextAreaComponent id="typicalListingPrice" value={CommonService.formatCurrency_2(this.state.typicalListingPrice)} rows="1" readOnly /></td>
                                                                                            <td>
                                                                                                <button className="btn btn-primary dgdg-copy-button" onClick={(event) => this.onCopyToClipboard(event, CommonService.formatCurrency_2(this.state.typicalListingPrice))}>
                                                                                                    <span className="far fa-copy" />
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="dgdg-font-blue text-right">2.</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td className="dgdg-font-blue">Low Value</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td><DGDGFormTextAreaComponent id="lowValue" value={CommonService.formatCurrency_2(this.state.lowValue)} rows="1" readOnly /></td>
                                                                                            <td>
                                                                                                <button className="btn btn-primary dgdg-copy-button" onClick={(event) => this.onCopyToClipboard(event, CommonService.formatCurrency_2(this.state.lowValue))}>
                                                                                                    <span className="far fa-copy" />
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="dgdg-font-blue text-right">3.</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td className="dgdg-font-blue">High Value</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td><DGDGFormTextAreaComponent id="highValue" value={CommonService.formatCurrency_2(this.state.highValue)} rows="1" readOnly /></td>
                                                                                            <td>
                                                                                                <button className="btn btn-primary dgdg-copy-button" onClick={(event) => this.onCopyToClipboard(event, CommonService.formatCurrency_2(this.state.highValue))}>
                                                                                                    <span className="far fa-copy" />
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="dgdg-font-blue text-right">4.</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td className="dgdg-font-blue">High & Low Diff</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td><DGDGFormTextAreaComponent id="highLowDiff" value={CommonService.formatCurrency_2(this.state.highValue - this.state.lowValue)} rows="1" readOnly /></td>
                                                                                            <td>
                                                                                                <button className="btn btn-primary dgdg-copy-button" onClick={(event) => this.onCopyToClipboard(event, CommonService.formatCurrency_2(this.state.highValue - this.state.lowValue))}>
                                                                                                    <span className="far fa-copy" />
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="dgdg-font-blue text-right">5.</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td className="dgdg-font-blue">KBB Mileage Adjustment</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td><DGDGFormTextAreaComponent id="kbbMileageAdjustmentValue" value={(this.state.kbbMileageAdjustmentValue < 0 ? "-" : "") + CommonService.formatCurrency_2(this.state.kbbMileageAdjustmentValue).replace("(", "").replace(")", "")} rows="1" inputCssName={this.state.kbbMileageAdjustmentValue < 0 ? "text-danger" : ""} readOnly /></td>
                                                                                            <td>
                                                                                                <button className="btn btn-primary dgdg-copy-button" onClick={(event) => this.onCopyToClipboard(event, (this.state.kbbMileageAdjustmentValue < 0 ? "-" : "") + CommonService.formatCurrency_2(this.state.kbbMileageAdjustmentValue).replace("(", "").replace(")", ""))}>
                                                                                                    <span className="far fa-copy" />
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="dgdg-font-blue text-right">6.</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td className="dgdg-font-blue">Average Condition Factor</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td><DGDGFormTextAreaComponent id="conditionFactor" value={CommonService.formatNumber_2(this.state.conditionFactor)} rows="1" readOnly /></td>
                                                                                            <td>
                                                                                                <button className="btn btn-primary dgdg-copy-button" onClick={(event) => this.onCopyToClipboard(event, CommonService.formatNumber_2(this.state.conditionFactor))}>
                                                                                                    <span className="far fa-copy" />
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="dgdg-font-blue text-right">7.</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td className="dgdg-font-blue">Base Offer</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td><DGDGFormTextAreaComponent id="baseOfferRange" value={CommonService.formatCurrency_2(this.state.baseOfferRange)} rows="1" readOnly /></td>
                                                                                            <td>
                                                                                                <button className="btn btn-primary dgdg-copy-button" onClick={(event) => this.onCopyToClipboard(event, CommonService.formatCurrency_2(this.state.baseOfferRange))}>
                                                                                                    <span className="far fa-copy" />
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="dgdg-font-blue text-right">8.</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td className="dgdg-font-blue">Accident Deductions</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td><DGDGFormTextAreaComponent id="accidentDeduction" value={(this.state.accidentDeduction > 0 ? "-" : "") + CommonService.formatCurrency_2(this.state.accidentDeduction).replace("(", "").replace(")", "")} rows="1" inputCssName={this.state.accidentDeduction > 0 ? "text-danger" : ""} readOnly /></td>
                                                                                            <td>
                                                                                                <button className="btn btn-primary dgdg-copy-button" onClick={(event) => this.onCopyToClipboard(event, (this.state.accidentDeduction > 0 ? "-" : "") + CommonService.formatCurrency_2(this.state.accidentDeduction).replace("(", "").replace(")", ""))}>
                                                                                                    <span className="far fa-copy" />
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="dgdg-font-blue text-right">9.</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td className="dgdg-font-blue">Key Deduction</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td><DGDGFormTextAreaComponent id="keyDeduction" value={(this.state.keyDeduction > 0 ? "-" : "") + CommonService.formatCurrency_2(this.state.keyDeduction).replace("(", "").replace(")", "")} rows="1" inputCssName={this.state.keyDeduction > 0 ? "text-danger" : ""} readOnly /></td>
                                                                                            <td>
                                                                                                <button className="btn btn-primary dgdg-copy-button" onClick={(event) => this.onCopyToClipboard(event, (this.state.keyDeduction > 0 ? "-" : "") + CommonService.formatCurrency_2(this.state.keyDeduction).replace("(", "").replace(")", ""))}>
                                                                                                    <span className="far fa-copy" />
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="dgdg-font-blue text-right">10.</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td className="dgdg-font-blue">General Adjustment</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td><DGDGFormTextAreaComponent id="generalAdjustment" value={(this.state.generalAdjustment < 0 ? "-" : "") + CommonService.formatCurrency_2(this.state.generalAdjustment).replace("(", "").replace(")", "")} rows="1" inputCssName={this.state.generalAdjustment < 0 ? "text-danger" : ""} readOnly /></td>
                                                                                            <td>
                                                                                                <button className="btn btn-primary dgdg-copy-button" onClick={(event) => this.onCopyToClipboard(event, (this.state.generalAdjustment < 0 ? "-" : "") + CommonService.formatCurrency_2(this.state.generalAdjustment).replace("(", "").replace(")", ""))}>
                                                                                                    <span className="far fa-copy" />
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="dgdg-font-blue text-right">11.</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td className="dgdg-font-blue">Max Mileage Adj Deduction</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td><DGDGFormTextAreaComponent id="maxMileageAdjustment" value={(this.state.maxMileageAdjustment > 0 ? "-" : "") + CommonService.formatCurrency_2(this.state.maxMileageAdjustment).replace("(", "").replace(")", "")} rows="1" inputCssName={this.state.maxMileageAdjustment > 0 ? "text-danger" : ""} readOnly /></td>
                                                                                            <td>
                                                                                                <button className="btn btn-primary dgdg-copy-button" onClick={(event) => this.onCopyToClipboard(event, (this.state.maxMileageAdjustment > 0 ? "-" : "") + CommonService.formatCurrency_2(this.state.maxMileageAdjustment).replace("(", "").replace(")", ""))}>
                                                                                                    <span className="far fa-copy" />
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="dgdg-font-blue text-right">12.</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td className="dgdg-font-blue">DGDG Offer</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td><DGDGFormTextAreaComponent id="baseOffer" value={CommonService.formatCurrency_2(this.state.baseOffer)} rows="1" readOnly /></td>
                                                                                            <td>
                                                                                                <button className="btn btn-primary dgdg-copy-button" onClick={(event) => this.onCopyToClipboard(event, CommonService.formatCurrency_2(this.state.baseOffer))}>
                                                                                                    <span className="far fa-copy" />
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="dgdg-font-blue text-right">13.</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td className="dgdg-font-blue">DGDG Offer (Rounded)</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td><DGDGFormTextAreaComponent id="offerAmount" value={CommonService.formatCurrency_2(this.state.offerAmount)} rows="1" readOnly /></td>
                                                                                            <td>
                                                                                                <button className="btn btn-primary dgdg-copy-button" onClick={(event) => this.onCopyToClipboard(event, CommonService.formatCurrency_2(this.state.offerAmount))}>
                                                                                                    <span className="far fa-copy" />
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="dgdg-font-blue text-right">14.</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td className="dgdg-font-blue">Message</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td><DGDGFormTextAreaComponent id="message" value={this.state.message} rows="3" readOnly /></td>
                                                                                            <td>
                                                                                                <button className="btn btn-primary dgdg-copy-button" onClick={(event) => this.onCopyToClipboard(event, this.state.message)}>
                                                                                                    <span className="far fa-copy" />
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="dgdg-font-blue text-right">15.</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td className="dgdg-font-blue">Offer Number</td>
                                                                                            <td>&nbsp;&nbsp;</td>
                                                                                            <td><DGDGFormTextAreaComponent id="offerNumber" value={this.state.offerNumber} rows="3" readOnly /></td>
                                                                                            <td>
                                                                                                <button className="btn btn-primary dgdg-copy-button" onClick={(event) => this.onCopyToClipboard(event, this.state.offerNumber)}>
                                                                                                    <span className="far fa-copy" />
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </center>
                                                : <center>
                                                    <table className="dgdg-sell-trade-final" style={{ "width": "300px" }}>
                                                        <tbody>
                                                            <tr>
                                                                <td className="font-weight-bold">Estimated Offer Range:</td>
                                                                <td>{CommonService.formatCurrency(this.state.lowConditionValueRound) + " - " + CommonService.formatCurrency(this.state.highConditionValueRound)}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </center>
                                            : null
                                    }
                                </Fragment>
                                : null
                        }
                    </div>
                </div>
            </div>
        </div>;
    }
}