import React from "react";

import { DGDGFormComboBoxComponent } from "../common/dgdgcombobox/DGDGFormComboBoxComponent";
import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";

export class DirectoryEmployeeAddComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedStore: null,
            id: null,
            storeList: null,
            store: null,
            employee: null,
            employeeList: null,
            selectedEmployee: null,
            extension: null
        };

        this.onEmployeeChange = this.onEmployeeChange.bind(this);
        this.onEmployeeClick = this.onEmployeeClick.bind(this);
        this.onStoreChange = this.onStoreChange.bind(this);
        this.onStoreClick = this.onStoreClick.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.employeeList && this.state.employeeList === null && this.props.stores && this.state.storeList == null) {
                this.setState({
                    employeeList: this.props.employeeList,
                    storeList: this.props.stores
                });
            }

            if (this.props.rowData != null && this.props.rowData !== prevProps.rowData && this.props.employeeList && this.props.stores) {
                let selectedEmployee = null;
                if (!this.props.isAdd) {
                    selectedEmployee = [this.props.rowData];
                }

                let selectedStore = this.props.stores.filter(storeRow => {
                    return storeRow.store_number === this.props.rowData.override_office_store_number;
                });
                this.setState({
                    id: this.props.rowData.id ? this.props.rowData.id : null,
                    employee: selectedEmployee[0]?.employee,
                    selectedEmployee: selectedEmployee[0],
                    store: selectedStore[0]?.display_name,
                    selectedStore: selectedStore[0],
                    extension: this.props.rowData.extension
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Edit Directory Employee";

    onEmployeeChange(id, filterValue) {
        try {
            let filteredData = [];
            if (this.props.employeeList) {
                filteredData = this.props.employeeList.filter(employeeRow => {
                    return employeeRow.employee.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0
                });
            }

            this.setState({
                employee: filterValue,
                employeeList: filteredData,
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onEmployeeClick(id, selectedEmployee) {
        try {
            let selectedStore = this.props.stores.filter(storeRow => {
                return storeRow.store_number === selectedEmployee.store_number;
            });

            this.setState({
                selectedEmployee: selectedEmployee,
                employee: selectedEmployee.employee,
                store: selectedStore[0]?.display_name,
                selectedStore: selectedStore[0]
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStoreChange(id, filterValue) {
        try {
            let filteredData = [];
            if (this.props.stores) {
                filteredData = this.state.selectedStore?.display_name !== filterValue
                    ? this.props.stores.filter(storeRow => {
                        return storeRow.display_name.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0
                    })
                    : this.state.storeList
            }

            this.setState({
                store: filterValue,
                storeList: filteredData
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStoreClick(id, selectedStore) {
        try {
            this.setState({
                selectedStore: selectedStore,
                store: selectedStore.display_name
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event) {
        try {
            this.props.onSaveClick(event, this.state.id, this.state.selectedEmployee.employee_number, this.state.selectedStore.store_number, this.state.extension);
            this.onCancelClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                id: null,
                employee: null,
                employeeList: null,
                storeList: null,
                selectedEmployee: null,
                store: null,
                selectedStore: null,
                extension: null
            });

            this.props.onCancelClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.props.showDialog}
            cssName="dgdg-directory-employee-edit-dialog"
            title={this.props.title}
            onCancelClick={this.onCancelClick}
        >
            <DGDGModalDialogHeaderButtonComponent>
                <button className="btn btn-primary" onClick={this.onSaveClick} disabled={!this.state.store || !this.state.employee || !this.state.extension} style={{ "marginLeft": "15px" }}>
                    Save  <i className="fas fa-save dgdg-icon" />
                </button>
            </DGDGModalDialogHeaderButtonComponent>
            <div className="dgdg-report-body">
                <div className="card">
                    <div className="card-block">
                        <table className="text-nowrap">
                            <tbody>
                                <tr>
                                    <td className="dgdg-font-blue">
                                        Employee :<span className="text-danger">*</span>
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td>
                                        <DGDGFormComboBoxComponent id="employee" disabled={!this.props.isAdd} data={this.state.employeeList} itemKey="employee" value={this.state.employee} onItemClick={this.onEmployeeClick} onItemChange={this.onEmployeeChange} autoDrop cssName="dgdg-directory-employee-edit-employee-column" />
                                    </td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        Override Office :<span className="text-danger">*</span>
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td>
                                        <DGDGFormComboBoxComponent id="store" data={this.state.storeList} itemKey="display_name" value={this.state.store} onItemClick={this.onStoreClick} onItemChange={this.onStoreChange} cssName="dgdg-directory-employee-edit-store-column" />
                                    </td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        Extension :<span className="text-danger">*</span>
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td>
                                        <DGDGFormInputComponent id="extension" disabled value={this.state.extension} onChange={this.onTextChange} cssName="dgdg-directory-employee-edit-extension-column" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </DGDGModalDialogComponent>;
    }
}