import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableColumnDrCrComponent } from "../common/dgdgtable/DGDGTableColumnDrCrComponent";
import { DGDGTableFooterComponent } from "../common/dgdgtable/DGDGTableFooterComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

export class ServiceStoreReportMonthAccountDetailComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            let totalAmount = 0;
            props.tableData.forEach((row) => {
                let value = CommonService.parseDecimal(row.amt);
                if (!isNaN(value)) {
                    totalAmount += value;
                }
            });

            let totalAmountDifference = totalAmount - CommonService.parseDecimal(props.totalAmount);
            if (totalAmountDifference > 1 || totalAmountDifference < -1) {
                newState.unAccountDetailRow = <tr className="dgdg-service-store-report-bg-total-diff font-weight-bold">
                    <td />
                    <td colSpan="3" className="overflow-wrap text-left font-weight-bold">Unaccounted Detail</td>
                    <td colSpan="1" className="text-left font-weight-bold">{CommonService.getAmountType(totalAmountDifference)}</td>
                    <td colSpan="1" className="text-right font-weight-bold">{CommonService.formatNumber_2(totalAmountDifference)}</td>
                    <td colSpan="5" />
                </tr>;
            }
            else {
                newState.unAccountDetailRow = null;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            copyText: "",
            unAccountDetailRow: null
        };

        this.onCopyClick = this.onCopyClick.bind(this);
    }

    pageTitle = "Service Store Report";

    onCopyClick(event) {
        try {
            let totalAmount = 0;
            let tableText = "Date\tAccount Number\tAccount Description\tType\tAmount\tDetail Description\tControl 1\tControl 2\tReference\r\n";
            this.props.tableData.forEach((row) => {
                totalAmount += CommonService.parseDecimal(row.amt);
                tableText += (row.dt ? row.dt : "") + "\t" + (row.an ? row.an : "") + "\t" + (row.ad ? row.ad : "") +
                    "\t" + (row.amt ? CommonService.getAmountType(row.amt) : "") + "\t" + (row.amt ? row.amt : "") +
                    "\t" + (row.dd ? row.dd : "") + "\t" + (row.c1 ? row.c1 : "") + "\t" + (row.c2 ? row.c2 : "") + "\t" + (row.re ? row.re : "") + "\r\n";
            });

            tableText += "Total\t\t\t" + CommonService.getAmountType(totalAmount) + "\t" + totalAmount + "\t\t\t\t";
            let totalAmountDifference = totalAmount - CommonService.parseDecimal(this.props.totalAmount);
            if (totalAmountDifference > 1 || totalAmountDifference < -1) {
                tableText += "\nUnaccounted Detail\t\t\t" + CommonService.getAmountType(totalAmountDifference) + "\t" + totalAmountDifference + "\t\t\t\t";
            }
            navigator.clipboard.writeText(tableText);
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("ServiceStoreReportMonthAccountDetailComponent", "onCopyClick", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        let allBlobs = [];
        if (this.props.allBlobs) {
            let blobNames = this.props.allBlobs.split(",");
            blobNames.forEach((blobName, index) => {
                if (index !== blobNames.length - 1) {
                    allBlobs.push(<div key={"blobName" + index} className="d-inline text-nowrap">{blobName.trim()},&nbsp;</div>);
                }
                else {
                    allBlobs.push(<div key={"blobName" + index} className="d-inline text-nowrap">{blobName.trim()}</div>);
                }
            });
        }

        return <DGDGModalDialogComponent applicationInsights={this.props.applicationInsights} showDialog={this.props.tableData.length > 0}
            title={this.props.tableTitle}
            cssName="dgdg-fsad-detail-dialog"
            onCancelClick={this.props.onCancelClick}
        >
            <DGDGModalDialogHeaderButtonComponent>
                <button className="btn btn-primary" onClick={(event) => this.onCopyClick(event)}>
                    Copy<span className="far fa-copy dgdg-icon" />
                </button>
            </DGDGModalDialogHeaderButtonComponent>
            <div className="dgdg-report-body">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} cardCssName="dgdg-full-width" cardBlockCssName="overflow-auto" tableData={this.props.tableData} paintTable={this.props.paintTable} showFooter>
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <DGDGTableColumnComponent headerText="Date" dataColumn="dt" sortColumn="dt" filterColumn="dt" dataType="date" footerText="Total" />
                        <DGDGTableColumnComponent headerText="Account Number" dataType="string" dataColumn="an" sortColumn="an" filterColumn="an" />
                        <DGDGTableColumnComponent headerText="Account Description" dataColumn="ad" sortColumn="ad" filterColumn="ad" />
                        <DGDGTableColumnDrCrComponent headerText="Type" dataColumn="amt" sortColumn="amt" footerFunction="sum" />
                        <DGDGTableColumnComponent headerText="Amount" dataColumn="display_amt" sortColumn="display_amt" filterColumn="display_amt" dataType="formatted_number_2" footerCssName="text-right" footerFunction="sum" columnHelp="Amounts are positive(+) for Sales and negative(-) for COS" />
                        <DGDGTableColumnComponent headerText="Detail Description" dataColumn="dd" sortColumn="dd" filterColumn="dd" />
                        <DGDGTableColumnComponent headerText="Control 1" dataColumn="c1" sortColumn="c1" filterColumn="c1" cssName="text-nowrap" />
                        <DGDGTableColumnComponent headerText="Control 2" dataColumn="c2" sortColumn="c2" filterColumn="c2" cssName="text-nowrap" />
                        <DGDGTableColumnComponent headerText="Reference" dataColumn="re" sortColumn="re" filterColumn="re" />
                    </DGDGTableRowComponent>
                    <DGDGTableFooterComponent position="PostFooter">
                        {this.state.unAccountDetailRow}
                    </DGDGTableFooterComponent>
                </DGDGTableV3Component>
            </div>
            {
                this.props.allBlobs
                    ? <div className="text-success">
                        {allBlobs}
                    </div>
                    : this.props.accountDetailBlobName
                        ? <div className="text-success">
                            {this.props.accountDetailBlobName}
                        </div>
                        : null
            }
        </DGDGModalDialogComponent>;
    }
}
