import React from "react";
import { Link } from "react-router-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom"

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";

import { PartsCustomerSummaryComponent } from "./PartsCustomerSummaryComponent";
import { PartsStoreSummaryComponent } from "./PartsStoreSummaryComponent";
import { PageNotFoundRouteComponent } from "../pagenotfound/PageNotFoundRouteComponent";

export class PartsReportRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        try {
            CommonService.clientAzureStorageLog("PartsReportRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Parts Report";

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-flex dgdg-flex-column">
            <BrowserRouter>
                <nav className="nav nav-pills dgdg-tab">
                    <Link to="/PartsReport/CustomerSummary" className={"nav-item nav-link" + (window.location.pathname.indexOf("/CustomerSummary") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Customer Summary</Link>
                    <Link to="/PartsReport/StoreSummary" className={"nav-item nav-link" + (window.location.pathname.indexOf("/StoreSummary") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Store Summary</Link>
                </nav>
                <div className="dgdg-flex-item dgdg-site-content">
                    <Switch>
                        <Route exact path="/PartsReport/CustomerSummary" render={(routeProps) => <PartsCustomerSummaryComponent {...this.props} {...routeProps} {...this.state} />} />
                        <Route exact path="/PartsReport/StoreSummary" render={(routeProps) => <PartsStoreSummaryComponent {...this.props} {...routeProps} {...this.state} />} />
                        <Route render={(routeProps) => <PageNotFoundRouteComponent {...this.props} {...routeProps} />} />
                    </Switch>
                </div>
            </BrowserRouter>
        </DGDGPageComponent>;
    }
}
