import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGWidgetComponent } from "../common/dgdgwidget/DGDGWidgetComponent";
import { DGDGChartLineComponent } from "../common/dgdgchart/DGDGChartLineComponent";

export class UsedReconditioningChartComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chartOption: {
                hAxis: {
                    title: "Date",
                    format: "M/d",
                    gridlines: {
                        color: 'transparent'
                    },
                    textStyle: {
                        fontSize: 10
                    },
                    titleTextStyle: {
                        fontSize: 12,
                        italic: false
                    }
                },
                vAxis: {
                    title: "Count",
                    textStyle: {
                        fontSize: 10
                    },
                    titleTextStyle: {
                        fontSize: 12,
                        italic: false
                    }
                },
                legend: {
                    textStyle: {
                        fontSize: 12
                    }
                }
            }
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        try {
            CommonService.clientAzureStorageLog("UsedReconditioningChartComponent", "shouldComponentUpdate", null, null, null, this.props.applicationInsights);
            return this.props.paintChart;
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGWidgetComponent title={this.props.headerText} restoredCss="dgdg-widget-maximized" isVisible>
            {
                this.props.chartData
                    ? <DGDGChartLineComponent chartData={this.props.chartData} chartOption={this.state.chartOption} />
                    : null
            }
        </DGDGWidgetComponent>;
    }
}
