import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormCalendarComponent } from "../common/dgdgcalendar/DGDGFormCalendarComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";


export class TechnicianEmployeePayEditComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showProgress: false,
            plan_date: "",
            plan_name: "",
            base_hourly_rate: "",
            flat_rate: "",
            training_labor_rate: "",
            planNames: ["B", "F", "H", "G", "G1", "D1", "M1", "L1", "R1"]
        };

        this.onDateChange = this.onDateChange.bind(this);
        this.onPlanNameChange = this.onPlanNameChange.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.employee
                && (!prevProps.employee || prevProps.employee.employee_number !== this.props.employee.employee_number)) {
                this.setState({
                    plan_date: this.props.employee.emp_plan_date ? moment(this.props.employee.emp_plan_date) : "",
                    plan_name: this.props.employee.emp_plan_name ? this.props.employee.emp_plan_name : "Select Plan",
                    base_hourly_rate: this.props.employee.emp_base_hourly_rate !== null ? CommonService.formatNumber_2(this.props.employee.emp_base_hourly_rate) : "",
                    flat_rate: this.props.employee.emp_flat_rate !== null ? CommonService.formatNumber_2(this.props.employee.emp_flat_rate) : "",
                    training_labor_rate: this.props.employee.emp_training_labor_rate !== null ? CommonService.formatNumber_2(this.props.employee.emp_training_labor_rate) : ""
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Technician Payroll";

    onDateChange(id, value) {
        try {
            this.setState({ [id]: moment(value) });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPlanNameChange(id, planName) {
        try {
            this.setState({ plan_name: planName });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event) {
        try {
            if (this.props.employee && CommonService.isDateValid(this.state.plan_date)) {
                this.setState({ showProgress: true });

                let inputData = {
                    employeeNumber: this.props.employee.employee_number,
                    planDate: this.state.plan_date ? CommonService.formatServerDateTime(this.state.plan_date.toString()) : null,
                    planName: this.state.plan_name === "Select Plan" ? null : this.state.plan_name,
                    baseHourlyRate: this.state.base_hourly_rate,
                    flatRate: this.state.flat_rate,
                    trainingLaborRate: this.state.training_labor_rate
                };

                this.props.applicationInsights.trackTrace({ message: "/TechnicianEmployeePayEditComponent/onSaveClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                fetch("/TechnicianPayroll/UpdateTechnicianEmployeePayPlan", {
                    method: "POST",
                    headers: {
                        'Content-Type': "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData)
                })
                    .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                    .then(parsedResponse => {
                        this.props.onSaveClick(event);
                        this.setState({ showProgress: false });
                    })
                    .catch(notOKResponse => {
                        this.setState({ showProgress: false });
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TeamMemberProfile/UpdateTechnicianEmployeePayPlan", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                    console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.devError(jsonParseError, null, this.props.applicationInsights);
                                });
                        }
                    });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                plan_date: "",
                plan_name: "",
                base_hourly_rate: "",
                flat_rate: "",
                training_labor_rate: ""
            });
            this.props.onCancelClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        let title = "";
        if (this.props.employee) {
            title = "Edit Employee Pay Plan - " + this.props.employee.employee_name + " (" + this.props.employee.employee_number + ") - " + this.props.employee.store_name;
        }

        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.props.employee}
            cssName="dgdg-technician-payroll-dialog"
            title={title}
            onCancelClick={this.onCancelClick}
        >
            <DGDGModalDialogHeaderButtonComponent>
                <button className="btn btn-primary" onClick={this.onSaveClick}>
                    Save <i className="fas fa-save dgdg-icon" />
                </button>
            </DGDGModalDialogHeaderButtonComponent>
            {
                this.props.employee
                    ? <div className="dgdg-report-body">
                        <div className="card">
                            <div className="card-block">
                                <div className="form-inline">
                                    <div className="form-row">
                                        <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} label="Plan Date" id="plan_date" value={this.state.plan_date} onDateChange={this.onDateChange} numberOfMonths={1} colSpan="col-4" labelSpan="col-4" controlSpan="col-8" />
                                        <DGDGFormDropdownComponent label="Plan Name" data={this.state.planNames} value={this.state.plan_name} onItemClick={this.onPlanNameChange} colSpan="col-4" labelSpan="col-4" controlSpan="col-8" />
                                        <DGDGFormInputComponent label="Base Hourly Rate" controlCssName="dgdg-money" id="base_hourly_rate" value={this.state.base_hourly_rate} onChange={this.onTextChange} inputType="money" colSpan="col-4" labelSpan="col-4" controlSpan="col-8" />
                                        <DGDGFormInputComponent label="Flat Rate" controlCssName="dgdg-money" id="flat_rate" value={this.state.flat_rate} onChange={this.onTextChange} inputType="money" colSpan="col-4" labelSpan="col-4" controlSpan="col-8" />
                                        <DGDGFormInputComponent label="Training Rate" controlCssName="dgdg-money" id="training_labor_rate" value={this.state.training_labor_rate} onChange={this.onTextChange} inputType="money" colSpan="col-4" labelSpan="col-4" controlSpan="col-8" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </DGDGModalDialogComponent>;
    }
}