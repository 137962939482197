import React, { Fragment } from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";

import { FrontEndGLReconciliationQueryComponent } from "./FrontEndGLReconciliationQueryComponent";
import { FrontEndGLReconciliationAccountDetailComponent } from "./FrontEndGLReconciliationAccountDetailComponent";
import { FrontEndGLReconciliationAdjustmentDetailComponent } from "./FrontEndGLReconciliationAdjustmentDetailComponent";

export class FrontEndGLReconciliationComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            selectedMonth: null,
            selectedStore: null,
            paintTable: false,
            tableData: [],
            response: [],
            tableTitle: "",
            legendText: null,

            detailTableData: [],
            detailTableTitle: "",
            paintDetailTable: false,

            adjustmentDetailTableData: [],
            adjustmentDetailTableTitle: "",
            paintAdjustmentDetailTable: false
        };

        this.onGetDetailsClick = this.onGetDetailsClick.bind(this);
        this.onGLTotalCellClick = this.onGLTotalCellClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onMonthClick = this.onMonthClick.bind(this);
        this.onCopyClick = this.onCopyClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onAdjustmentTotalCellClick = this.onAdjustmentTotalCellClick.bind(this);
        this.onAdjustmentDetailCancelClick = this.onAdjustmentDetailCancelClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    pageTitle = "Front-end Adjustments";

    componentDidMount() {
        try {
            this.getLegendText();
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/FrontEndAdjustments/GLReconciliation", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("GLReconciliationComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    componentWillUnmount() {
        this.props.onLastUpdatedDateChanged("");
    }

    getLegendText() {
        let legendText = <Fragment>
            <div className="d-inline-block dgdg-bad-debt-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-front-end-gl-bg-total-diff" />&nbsp;&nbsp;&nbsp;Adjustment and GL Totals Differ</div>
        </Fragment>
        this.setState({ legendText: legendText })
    }

    filterData(response, selectedStore) {
        if (response !== null) {
            if (selectedStore.display_name !== "All Stores") {
                response = response.filter(item => item.store_number === selectedStore.store_number);
            }
            return response;
        }
    }

    onGetLastUpdatedDate(selectedMonth) {
        try {
            let inputData = {
                month: CommonService.formatServerDateTime(selectedMonth)
            };

            this.props.applicationInsights.trackTrace({ message: "/FrontEndAdjustments/onGetLastUpdatedDate", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FrontEndAdjustments/GetLastUpdated", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (CommonService.isDateValid(parsedResponse)) {
                        this.props.onLastUpdatedDateChanged(moment.utc(parsedResponse));
                    }
                })
                .catch(notOKResponse => {
                    this.setState({ isDownLoading: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FrontEndAdjustments/GetLastUpdated", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", { message: "Failed to download" });
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onMonthClick(selectedMonth) {
        this.setState({
            selectedMonth: selectedMonth,
            response: []
        });
    }

    onGetDetailsClick(selectedMonth, selectedStore) {
        try {
            if (this.state.response.length <= 0) {
                this.setState({
                    isLoadingData: true,
                    selectedStore: selectedStore
                });
                let inputData = {
                    startDate: CommonService.formatServerDateTime(selectedMonth)
                };

                this.onGetLastUpdatedDate(selectedMonth);

                this.props.applicationInsights.trackTrace({ message: "/FrontEndAdjustments/onGetDetailsClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                fetch("/FrontEndAdjustments/GetFrontEndAdjustmentGLReconciliation", {
                    method: "POST",
                    headers: {
                        'Content-Type': "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData)
                })
                    .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                    .then(parsedResponse => {
                        parsedResponse.forEach((responseItem, index) => {
                            responseItem.total_amount_cellCssName = "";
                            responseItem.gl_amount_cellCssName = "";
                            if (Math.abs(responseItem.total_amount - responseItem.gl_amount) > 0) {
                                responseItem.total_amount_cellCssName += "dgdg-front-end-gl-bg-total-diff";
                                responseItem.gl_amount_cellCssName += "dgdg-front-end-gl-bg-total-diff";
                            }
                            if (responseItem.total_amount < 0)
                                responseItem.total_amount_cellCssName += " dgdg-font-red";
                            if (responseItem.gl_amount < 0) {
                                responseItem.gl_amount_cellCssName += " dgdg-font-red";
                            }
                        });

                        this.setState({
                            isLoadingData: false,
                            response: parsedResponse,
                            tableTitle: "GL Reconciliation - " + CommonService.formatMonthYear(moment(selectedMonth)),
                            tableData: CommonService.addOrdinalColumn(this.filterData(parsedResponse, selectedStore)),
                            paintTable: true
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                    })
                    .catch(notOKResponse => {
                        this.setState({
                            isLoadingData: false,
                            tableTitle: "",
                            tableData: [],
                            paintTable: true
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FrontEndAdjustments/GetGLReconciliation", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                    console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.devError(jsonParseError, null, this.props.applicationInsights);
                                });
                        }
                    });
            } else {
                let tableData = this.filterData(this.state.response, selectedStore);
                this.setState({
                    tableData: CommonService.addOrdinalColumn(tableData),
                    tableTitle: "GL Reconciliation - " + CommonService.formatMonthYear(moment(selectedMonth)),
                    paintTable: true
                }, () => {
                    this.setState({ paintTable: false });
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGLTotalCellClick(event, rowData) {
        try {
            let glAmountLabel = rowData.gl_amount < 0 ? "<span class='dgdg-font-red'>" + CommonService.formatCurrency_2(rowData.gl_amount) + "</span>" : CommonService.formatCurrency_2(rowData.gl_amount);

            let inputData = {
                startDate: CommonService.formatServerDateTime(this.state.selectedMonth),
                storeNumber: rowData.store_web_name !== null ? rowData.store_web_name.toString().split('|')[0].trim() : null,
                accountNumber: rowData.account_number
            };

            this.props.applicationInsights.trackTrace({ message: "/GLReconciliationComponent/onGLTotalCellClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FrontEndAdjustments/GetFrontEndGLReconciliationAccountDetails", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.forEach((responseItem, index) => {
                        responseItem.dt = CommonService.formatDate(responseItem.dt)
                    });
                    this.setState({
                        detailTableTitle: "Account Detail - " + CommonService.formatMonthYear(moment(this.state.selectedMonth)) + " - " + rowData.store_web_name + " - A/C " + rowData.account_number + ": " + glAmountLabel,
                        detailTableData: parsedResponse,
                        paintDetailTable: true
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        detailTableTitle: "",
                        detailTableData: [],
                        paintDetailTable: true
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FrontEndAdjustments/GetGLReconciliationAccountDetails", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAdjustmentTotalCellClick(event, rowData) {
        try {
            let totalAmountLabel = rowData.total_amount < 0 ? "<span class='dgdg-font-red'>" + CommonService.formatCurrency_2(rowData.total_amount) + "</span>" : CommonService.formatCurrency_2(rowData.total_amount);

            let inputData = {
                startDate: CommonService.formatServerDateTime(this.state.selectedMonth),
                storeNumber: rowData.store_web_name !== null ? rowData.store_web_name.toString().split('|')[0].trim() : null,
                accountNumber: rowData.account_number
            };

            this.props.applicationInsights.trackTrace({ message: "/GLReconciliationComponent/onAdjustmentTotalCellClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FrontEndAdjustments/GetFrontEndAdjustmentsDetails", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        adjustmentDetailTableTitle: "Adjustment Detail - " + CommonService.formatMonthYear(moment(this.state.selectedMonth)) + " - " + rowData.store_web_name + " - A/C " + rowData.account_number + ": " + totalAmountLabel,
                        adjustmentDetailTableData: this.filterData(parsedResponse.data, this.state.selectedStore),
                        paintAdjustmentDetailTable: true
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        adjustmentDetailTableTitle: "",
                        adjustmentDetailTableData: [],
                        paintAdjustmentDetailTable: true
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FrontEndAdjustments/GetGLReconciliationAdjustmentDetails", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                paintDetailTable: true,
                detailTableData: [],
                detailTableTitle: ""
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAdjustmentDetailCancelClick(event) {
        try {
            this.setState({
                adjustmentDetailTableData: [],
                adjustmentDetailTableTitle: "",
                paintAdjustmentDetailTable: true
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyClick(event) {
        try {
            let tableText = "Store Name\tAccount Number\tAccount Description\tAdjustment Total\tGL Total\r\n";
            let adjustmentTotal = 0, glTotal = 0;
            this.state.tableData.forEach((row) => {
                adjustmentTotal += CommonService.parseDecimal(row.total_amount);
                glTotal += CommonService.parseDecimal(row.gl_amount);
                tableText += (row.store_web_name ? row.store_web_name : "") + "\t" + (row.account_number ? row.account_number : "")
                    + "\t" + (row.account_description ? row.account_description : "") + "\t" + (row.total_amount ? row.total_amount : "") + "\t" + (row.gl_amount ? row.gl_amount : "") + "\r\n";
            });

            tableText += "\tTotal\t\t\t" + adjustmentTotal + "\t" + glTotal;

            navigator.clipboard.writeText(tableText);
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("GLReconciliationComponent", "onCopyClick", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("GLReconciliationComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <div className="dgdg-site-scrollable-content">
                <FrontEndGLReconciliationAccountDetailComponent
                    applicationInsights={this.props.applicationInsights}
                    tableData={this.state.detailTableData}
                    tableTitle={this.state.detailTableTitle}
                    paintTable={this.state.paintDetailTable}
                    onCancelClick={this.onCancelClick}
                    onShowAlert={this.props.onShowAlert}
                />
                <FrontEndGLReconciliationAdjustmentDetailComponent
                    applicationInsights={this.props.applicationInsights}
                    tableData={this.state.adjustmentDetailTableData}
                    tableTitle={this.state.adjustmentDetailTableTitle}
                    paintTable={this.state.paintAdjustmentDetailTable}
                    onCancelClick={this.onAdjustmentDetailCancelClick}
                    onShowAlert={this.props.onShowAlert}
                />
                <FrontEndGLReconciliationQueryComponent
                    applicationInsights={this.props.applicationInsights}
                    isLoadingData={this.state.isLoadingData}
                    stores={this.props.stores}
                    months={this.props.months}
                    onGetDetailsClick={this.onGetDetailsClick}
                    onMonthClick={this.onMonthClick}
                    onCopyClick={this.onCopyClick}
                />
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable} legendText={this.state.legendText} showFooter
                    copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_web_name" sortColumn="store_number" filterColumn="store_web_name" footerText="Total" />
                        <DGDGTableColumnComponent headerText="Account Number" dataColumn="account_number" sortColumn="account_number" filterColumn="account_number" />
                        <DGDGTableColumnComponent headerText="Account Description" dataColumn="account_description" sortColumn="account_description" filterColumn="account_description" />
                        <DGDGTableColumnComponent headerText="Adjustment Total" dataColumn="total_amount" sortColumn="total_amount" filterColumn="total_amount" dataType="money_2" onCellClick={this.onAdjustmentTotalCellClick} footerFunction="sum" footerCssName="text-right" />
                        <DGDGTableColumnComponent headerText="GL Total" dataColumn="gl_amount" sortColumn="gl_amount" filterColumn="gl_amount" dataType="money_2" onCellClick={this.onGLTotalCellClick} footerFunction="sum" footerCssName="text-right" />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
        </div>;
    }
}
