import React from "react";
import { Link } from "react-router-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom"

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";

import { EVOXImagesKBBEVOXMappingComponent } from "./EVOXImagesKBBEVOXMappingComponent";
import { EVOXImagesEVOXVehiclesComponent } from "./EVOXImagesEVOXVehiclesComponent";
import { PageNotFoundRouteComponent } from "../pagenotfound/PageNotFoundRouteComponent";

export class EVOXImagesRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    componentDidMount() {
        try {
            CommonService.clientAzureStorageLog("EVOXImagesRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "EVOX Images";

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-flex dgdg-flex-column">
            <BrowserRouter>
                <nav className="nav nav-pills dgdg-tab">
                    <Link to="/EVOXImages/KBBEVOXMapping" className={"nav-item nav-link" + (window.location.pathname.indexOf("/KBBEVOXMapping") !== -1 ? " btn btn-dark active" : " btn btn-link")}>KBB EVOX Mapping</Link>
                    <Link to="/EVOXImages/EVOXVehicles" className={"nav-item nav-link" + (window.location.pathname.indexOf("/EVOXVehicles") !== -1 ? " btn btn-dark active" : " btn btn-link")}>EVOX Vehicles</Link>
                </nav>
                <div className="dgdg-flex-item dgdg-site-content">
                    <Switch>
                        <Route exact path="/EVOXImages/KBBEVOXMapping/:yearId/:makeName" render={(routeProps) => <EVOXImagesKBBEVOXMappingComponent {...this.props} {...routeProps} />} onShowAlert={this.props.onShowAlert} />
                        <Route exact path="/EVOXImages/KBBEVOXMapping/:vehicleId" render={(routeProps) => <EVOXImagesKBBEVOXMappingComponent {...this.props} {...routeProps} />} onShowAlert={this.props.onShowAlert} />
                        <Route exact path="/EVOXImages/KBBEVOXMapping" render={(routeProps) => <EVOXImagesKBBEVOXMappingComponent {...this.props} {...routeProps} />} onShowAlert={this.props.onShowAlert} />
                        <Route exact path="/EVOXImages/EVOXVehicles/:vifNumber" render={(routeProps) => <EVOXImagesEVOXVehiclesComponent {...this.props} {...routeProps} />} onShowAlert={this.props.onShowAlert} />
                        <Route exact path="/EVOXImages/EVOXVehicles" render={(routeProps) => <EVOXImagesEVOXVehiclesComponent {...this.props} {...routeProps} />} onShowAlert={this.props.onShowAlert} />
                        <Route render={(routeProps) => <PageNotFoundRouteComponent {...this.props} {...routeProps} />} />
                    </Switch>
                </div>
            </BrowserRouter>
        </DGDGPageComponent>;
    }
}