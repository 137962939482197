import React, { Fragment } from "react";
import moment from "moment";
import $ from "jquery";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormTextAreaComponent } from "../common/dgdgtextarea/DGDGFormTextAreaComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableColumnCheckboxV2Component } from "../common/dgdgtable/DGDGTableColumnCheckboxV2Component";
import { DGDGTableColumnDropdownComponent } from "../common/dgdgtable/DGDGTableColumnDropdownComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

import { BadDebtDetailQueryComponent } from "./BadDebtDetailQueryComponent";
import { BadDebtDetailJournalEntryDetailsComponent } from "./BadDebtDetailJournalEntryDetailsComponent";

export class BadDebtDetailComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            isDownLoading: false,
            selectedStore: null,
            canEditIsHidden: false,
            tableTitle: "",
            tableData: [],
            paintTable: false,
            journalEntryDetailsTitle: "",
            journalEntryDetailsTableData: [],
            journalEntryDetailsPaintTable: false,
            showjournalEntryDetails: false,
            totalPostingAmount: 0,
            copyText: null,
            showHidden: false
        };

        this.onShowHiddenChange = this.onShowHiddenChange.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onCustomerNumberCellClick = this.onCustomerNumberCellClick.bind(this);
        this.onCustomerNumberCancelClick = this.onCustomerNumberCancelClick.bind(this);

        this.onAddCommentColumnCallback = this.onAddCommentColumnCallback.bind(this);
        this.onCommentTextChange = this.onCommentTextChange.bind(this);
        this.onCommentBlur = this.onCommentBlur.bind(this);
        this.onToggleViewComments = this.onToggleViewComments.bind(this);
        this.onToggleAddComments = this.onToggleAddComments.bind(this);

        this.onStatusChange = this.onStatusChange.bind(this);
        this.onSaveBadDebtCommentClick = this.onSaveBadDebtCommentClick.bind(this);
        this.onIsHiddenChange = this.onIsHiddenChange.bind(this);

        this.onDownloadClick = this.onDownloadClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    pageTitle = "Bad Debt";
    statusData = [
        { key: "High Pay Off", value: "High Pay Off" },
        { key: "Return Check", value: "Return Check" },
        { key: "Missing Down Payment", value: "Missing Down Payment" },
        { key: "First Payment", value: "First Payment" },
        { key: "DMV", value: "DMV" },
        { key: "Insurance Claim", value: "Insurance Claim" },
        { key: "Courtesy Delivery", value: "Courtesy Delivery" },
        { key: "Stop Payment", value: "Stop Payment" },
        { key: "Paid Value", value: "Paid Value" },
        { key: "Service NSF", value: "Service NSF" },
        { key: "Other", value: "Other" }
    ];
    legendText = <Fragment>
        <div className="d-inline-block dgdg-bad-debt-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-bad-debt-status-bg-paid-value" />&nbsp;&nbsp;&nbsp;Paid Value</div>
        <div className="d-inline-block dgdg-bad-debt-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-bad-debt-status-bg-other" />&nbsp;&nbsp;&nbsp;High Pay Off, DMV, Insurance Claim, Courtesy Delivery, Other</div>
        <div className="d-inline-block dgdg-bad-debt-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-bad-debt-status-bg-other-le-60" />&nbsp;&nbsp;&nbsp;Age &lt;= 60 days and  Return Check, Missing Down Payment, First Payment, Stop Payment</div>
        <div className="d-inline-block dgdg-bad-debt-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-bad-debt-status-bg-other-gt-60-legend" />&nbsp;&nbsp;&nbsp;Age &gt; 60 days and Return Check, Missing Down Payment, First Payment, Stop Payment</div>
        <div className="d-inline-block dgdg-bad-debt-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-bad-debt-status-bg-total-service-nsf" />&nbsp;&nbsp;&nbsp;Service NSF</div>
    </Fragment>;

    componentWillUnmount() {
        this.props.onLastUpdatedDateChanged("");
    }

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/BadDebt/Detail", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("BadDebtDetailComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onShowHiddenChange(isChecked) {
        this.setState({ showHidden: isChecked });
    }

    onGetReportClick(selectedStore, showHidden) {
        try {
            this.setState({
                isLoadingData: true,
                selectedStore: selectedStore
            });

            let inputData = {
                storeNumber: selectedStore ? selectedStore.store_number : null,
                showHidden: showHidden
            };

            this.onGetLastUpdatedDate();

            this.props.applicationInsights.trackTrace({ message: "/BadDebtDetailComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/BadDebt/GetReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let tableData = parsedResponse.table;
                    if (!showHidden) {
                        tableData = tableData.filter(rowData => !rowData.is_hidden);
                    }

                    tableData.forEach((responseItem, index) => {
                        responseItem.is_hidden_label = "Hide";
                        responseItem.original_comment = responseItem.comment;
                        responseItem.age_sort = responseItem.age;
                        responseItem.is_hidden_disabled = !responseItem.status;
                        responseItem.is_status_disabled = !responseItem.edit_status;
                        responseItem.age = responseItem.age ? responseItem.age + " Days" : null;
                        if (responseItem.balance < 0)
                            responseItem.balance_cellCssName += " dgdg-font-red";
                        this.setRowColor(responseItem);
                    });
                    this.setState({
                        isLoadingData: false,
                        tableTitle: selectedStore === null ? "All Stores" : selectedStore.display_name,
                        tableData: CommonService.addOrdinalColumn(tableData),
                        canEditIsHidden: parsedResponse.canEditIsHidden,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: "",
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/BadDebt/GetReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetLastUpdatedDate() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/BadDebtDetailComponent/onGetLastUpdatedDate", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/BadDebt/GetLastUpdated")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let lastUpdatedDate = "";
                    if (CommonService.isDateValid(parsedResponse))
                        lastUpdatedDate = moment.utc(parsedResponse);
                    this.props.onLastUpdatedDateChanged(lastUpdatedDate);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/BadDebtDetailComponent/GetLastUpdated", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("BadDebtDetailComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCustomerNumberCellClick(event, rowData) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                storeNumber: rowData.store_number,
                customerNumber: rowData.customer_number,
                contractDate: rowData.contract_date
            };

            this.props.applicationInsights.trackTrace({ message: "/BadDebtDetailComponent/onCustomerNumberCellClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/BadDebt/GetBadDebtJournalEntryDetails", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let totalPostingAmount = 0;
                    parsedResponse.forEach((responseItem, index) => {
                        totalPostingAmount = totalPostingAmount + responseItem.posting_amount
                    });
                    this.setState({
                        isLoadingData: false,
                        journalEntryDetailsTitle: "Journal Entry Detail for " + rowData.customer_number + " - Posting Amount: " + CommonService.formatCurrency_2(rowData.posting_amount),
                        journalEntryDetailsTableData: CommonService.addOrdinalColumn(parsedResponse),
                        showjournalEntryDetails: true,
                        journalEntryDetailsPaintTable: true,
                        totalPostingAmount: totalPostingAmount
                    }, () => {
                        this.setState({ journalEntryDetailsPaintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        journalEntryDetailsTitle: "Journal Entry Detail for " + rowData.customer_number ? rowData.customerNumber : " - Posting Amount: " + CommonService.formatCurrency(rowData.posting_amount),
                        journalEntryDetailsTableData: [],
                        showjournalEntryDetails: true,
                        journalEntryDetailsPaintTable: true
                    }, () => {
                        this.setState({ journalEntryDetailsPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/BadDebt/GetBadDebtJournalEntryDetails", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCustomerNumberCancelClick(event) {
        try {
            this.setState({
                journalEntryDetailsTitle: "",
                journalEntryDetailsTableData: [],
                journalEntryDetailsPaintTable: true,
                showjournalEntryDetails: false,
                totalPostingAmount: 0
            }, () => {
                this.setState({ journalEntryDetailsPaintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAddCommentColumnCallback(rowData) {
        let comments = this.getComments(rowData.comment);
        let disableAddComments = !rowData.edit_comment;
        return <Fragment>
            <button key="btnViewComments" type="button" className="btn btn-link" onClick={(event) => this.onToggleViewComments(event, rowData)}>{comments.length / 2} Comments
                {
                    rowData.showViewComments
                        ? <span className="far fa-sort-up dgdg-icon" />
                        : rowData.showViewComments === undefined
                            ? <span className="far fa-sort-up dgdg-icon" >{this.onToggleViewComments(null, rowData)}</span>
                            : <span className="far fa-sort-down dgdg-icon" />
                }
            </button>
            <button key="btnAddComment" type="button" className="btn btn-link dgdg-icon" disabled={disableAddComments} onClick={(event) => this.onToggleAddComments(event, rowData)}>Add Comments
                {
                    rowData.showAddComment
                        ? <span className="fas fa-times text-danger dgdg-icon" />
                        : <span className="fas fa-plus dgdg-icon" />
                }
            </button>
            <br />
            {
                rowData.showViewComments
                    ? comments
                    : null
            }
            {
                rowData.showAddComment
                    ? <div className="form-inline">
                        <DGDGFormTextAreaComponent id={"comment_" + rowData.customer_number} value={rowData["comment_" + rowData.customer_number]} rows="3" onChange={this.onCommentTextChange} colSpan="col-11" controlSpan="col-12" />
                        <button type="button" className="btn btn-link dgdg-font-size-18" disabled={disableAddComments} onClick={(event) => this.onSaveBadDebtCommentClick(event, rowData)}>
                            <span className="fas fa-save" />
                        </button>
                        <DGDGSpinnerComponent showSpinner={rowData.comment_showSpinner} />
                    </div>
                    : null
            }
        </Fragment>;
    }

    onToggleViewComments(event, rowData) {
        let tableData = this.state.tableData;
        rowData.showViewComments = rowData.showViewComments ? false : true;
        this.setState({
            tableData: tableData,
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
        });
    }

    onToggleAddComments(event, rowData) {
        let tableData = this.state.tableData;
        rowData.showAddComment = rowData.showAddComment ? false : true;
        this.setState({
            tableData: tableData,
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
            if (rowData.showAddComment) {
                let id = "#comment_" + rowData.customer_number;
                $(id).focus();
            }
        });
    }

    onCommentTextChange(id, value) {
        try {
            let tableData = this.state.tableData;
            let rowData = tableData.filter((row) => row.customer_number === id.split('_')[1])[0];
            rowData["comment_" + rowData.customer_number] = value;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCommentBlur(id, value, rowData) {
        if (rowData.original_comment !== value) {
            setTimeout(() => {
                this.saveServiceScheduleComment(rowData);
            }, 500);
        }
    }

    onSaveBadDebtCommentClick(event, rowData) {
        if (this.state.lastSavedRowData
            && this.state.lastSavedRowData.customer_number === rowData.customer_number
            && moment().diff(this.state.lastSavedRowData.last_updated_on) < 500) {

            return;
        }

        let tableData = this.state.tableData;
        try {
            rowData.comment_showSpinner = true;
            rowData.last_updated_on = moment();
            this.setState({
                lastSavedRowData: rowData,
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                storeNumber: rowData.store_number,
                customerNumber: rowData.customer_number,
                comment: rowData["comment_" + rowData.customer_number].replace(/\n|\r/g, " ")
            };
            this.props.applicationInsights.trackTrace({ message: "/BadDebtTDetailComponent/saveBadDebtComment", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/BadDebt/SaveBadDebtComment", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    rowData.comment = moment().format("MM/DD/YYYY hh:mm A") + ", " + this.props.remoteUpn + ": " + rowData["comment_" + rowData.customer_number] + (rowData.comment ? '\r' + rowData.comment : "");
                    rowData["comment_" + rowData.customer_number] = "";
                    rowData.last_updated_by = this.props.remoteUpn;
                    rowData.comment_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    })

                    this.props.onShowAlert("success", { message: "BadDebt Customer #" + rowData.customer_number + " Comment saved" }, 4000);
                })
                .catch(notOKResponse => {
                    rowData.comment_showSpinner = false;
                    this.setState({
                        isLoadingData: false,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/BadDebt/SaveBadDebtComment", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStatusChange(id, selectedItem, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.status = selectedItem.key;
            rowData.status_showSpinner = true;
            this.setRowColor(rowData);
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                storeNumber: rowData.store_number,
                customerNumber: rowData.customer_number,
                comment: rowData.comment,
                status: rowData.status
            };

            this.props.applicationInsights.trackTrace({ message: "/BadDebtDetailComponent/onStatusChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/BadDebt/SaveBadDebtStatus", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    rowData.last_updated_by = this.props.remoteUpn;
                    rowData.status_showSpinner = false;
                    this.setState({
                        isLoadingData: false,
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });

                    this.props.onShowAlert("success", { message: "BadDebt Customer #" + rowData.customer_number + " Status saved" }, 4000);
                })
                .catch(notOKResponse => {
                    rowData.status_showSpinner = false;
                    this.setState({
                        isLoadingData: false,
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/BadDebt/SaveBadDebtStatus", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onIsHiddenChange(id, isChecked, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.is_hidden = isChecked;
            rowData.is_hidden_showSpinner = true;
            this.setRowColor(rowData);
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                storeNumber: rowData.store_number,
                customerNumber: rowData.customer_number,
                isHidden: rowData.is_hidden
            };

            this.props.applicationInsights.trackTrace({ message: "/BadDebtDetailComponent/onIsHiddenChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/BadDebt/SaveBadDebtIsHidden", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (isChecked && !this.state.showHidden) {
                        tableData = tableData.filter(row => row.customer_number !== rowData.customer_number);
                    }
                    rowData.is_hidden_showSpinner = false;
                    this.setState({
                        isLoadingData: false,
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    })

                    this.props.onShowAlert("success", { message: "BadDebt Customer #" + rowData.customer_number + " Hide saved" }, 4000);
                })
                .catch(notOKResponse => {
                    rowData.is_hidden_showSpinner = false;
                    this.setState({
                        isLoadingData: false,
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/BadDebt/SaveBadDebtIsHidden", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    setRowColor(rowData) {
        switch (rowData.status) {
            case "Paid Value":
                rowData.rowCssName = "dgdg-bad-debt-status-bg-paid-value";
                break;
            case "Return Check":
            case "Missing Down Payment":
            case "First Payment":
            case "Stop Payment":
                if (rowData.age_sort) {
                    rowData.rowCssName = rowData.age_sort > 60 ? "dgdg-bad-debt-status-bg-other-gt-60" : "dgdg-bad-debt-status-bg-other-le-60";
                }
                break;
            case "Service NSF":
                rowData.rowCssName = "dgdg-bad-debt-status-bg-total-service-nsf";
                break;
            default:
                if (rowData.status) {
                    rowData.rowCssName = "dgdg-bad-debt-status-bg-default";
                }
                else {
                    rowData.rowCssName = null;
                }
        }
    }

    getComments(comments) {
        let htmlContent = [];
        if (comments !== null) {
            let commentParts = [];
            comments.split('\r').forEach(function (value) {
                commentParts.push(value.split(': '));
            });
            commentParts.forEach((commentPart) => {
                if (commentPart[1]) {
                    htmlContent.push(
                        <Fragment>
                            <span key="comment_part1" style={{ "color": "blue" }}>
                                {(commentPart[0] !== "" ? commentPart[0] + ": " : null)}
                            </span>
                            <span key="comment_part2">
                                {(commentPart[1])}
                            </span>
                        </Fragment>);
                    htmlContent.push(<br />);
                }
            });
        }

        return htmlContent;
    }

    onDownloadClick(selectedStore, showHidden) {
        try {
            this.setState({ isDownLoading: true });
            let inputData = {
                storeNumber: selectedStore ? selectedStore.store_number : null,
                showHidden: showHidden
            };

            this.props.applicationInsights.trackTrace({ message: "/BadDebtDetailComponent/onDownloadClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/BadDebt/Download", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response } else { throw response; } })
                .then(response => {
                    this.setState({ isDownLoading: false });
                    CommonService.downloadBlob(response);
                })
                .catch(notOKResponse => {
                    this.setState({ isDownLoading: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/BadDebt/onDownloadClick", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", { message: "Failed to download" });
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <BadDebtDetailJournalEntryDetailsComponent
                applicationInsights={this.props.applicationInsights}
                showDialog={this.state.showjournalEntryDetails}
                tableData={this.state.journalEntryDetailsTableData}
                tableTitle={this.state.journalEntryDetailsTitle}
                paintTable={this.state.journalEntryDetailsPaintTable}
                totalPostingAmount={this.state.totalPostingAmount}
                onCancelClick={this.onCustomerNumberCancelClick}
                onCopyTableCallBack={this.onCopyTableCallBack}
            />
            <BadDebtDetailQueryComponent {...this.props}
                applicationInsights={this.props.applicationInsights}
                stores={this.props.stores} isLoadingData={this.state.isLoadingData} isDownLoading={this.state.isDownLoading} canEditIsHidden={this.state.canEditIsHidden}
                onShowHiddenChange={this.onShowHiddenChange}
                onGetReportClick={this.onGetReportClick}
                onDownloadClick={this.onDownloadClick}
            />
            <div className="dgdg-site-scrollable-content">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable} legendText={this.legendText} stickyHeader
                    tablePageSize={CommonService.tablePageSize} copyTableConfig={{ "columns": [0, 7, 1, 2, 3, 4, 5, 6], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent hasHeader>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <DGDGTableColumnDropdownComponent headerText="Status" dataColumn="status" filterColumn="status" sortColumn="status" data={this.statusData} itemKey="value" disabledColumn="is_status_disabled" onItemClick={this.onStatusChange} headerCssName="dgdg-bad-debt-detail-status" showSpinner />
                        <DGDGTableColumnComponent headerText="Customer #" dataColumn="customer_number" sortColumn="customer_number" filterColumn="customer_number" onCellClick={this.onCustomerNumberCellClick} />
                        <DGDGTableColumnComponent headerText="Customer Name" dataColumn="customer_name" filterColumn="customer_name" sortColumn="customer_name" />
                        <DGDGTableColumnComponent headerText="Posted Date Time" dataColumn="posting_date_time" sortColumn="posting_date_time" filterColumn="posting_date_time" dataType="date" />
                        <DGDGTableColumnComponent headerText="Age (Days)" dataColumn="age" sortColumn="age_sort" filterColumn="age_sort" />
                        <DGDGTableColumnComponent headerText="Posting Amount" dataColumn="posting_amount" sortColumn="posting_amount" filterColumn="posting_amount" dataType="money_2" />
                        <DGDGTableColumnComponent headerText="Store" dataColumn="store_name" isHidden />
                    </DGDGTableRowComponent>
                    {
                        this.state.canEditIsHidden
                            ? <DGDGTableRowComponent cssName="dgdg-no-border-top">
                                <DGDGTableColumnComponent dataText="" />
                                <DGDGTableColumnCheckboxV2Component headerText="Hide" dataColumn="is_hidden" labelColumn="is_hidden_label" dataType="bool" disabledColumn="is_hidden_disabled" onChange={this.onIsHiddenChange} showSpinner />
                                <DGDGTableColumnComponent colSpan="4" dataColumnCallback={this.onAddCommentColumnCallback} />
                                <DGDGTableColumnComponent colSpan="1" headerText="Last Updated By" dataColumn="last_updated_by" cssName="dgdg-no-border" showHeader />
                            </DGDGTableRowComponent>
                            : <DGDGTableRowComponent cssName="dgdg-no-border-top">
                                <DGDGTableColumnComponent colSpan="2 " dataText="" />
                                <DGDGTableColumnComponent colSpan="4" dataColumnCallback={this.onAddCommentColumnCallback} />
                                <DGDGTableColumnComponent colSpan="1" headerText="Last Updated By" dataColumn="last_updated_by" cssName="dgdg-no-border" showHeader />
                            </DGDGTableRowComponent>
                    }
                </DGDGTableV3Component>
            </div>
        </div>;
    }
}
