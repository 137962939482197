import React from "react";
import { Link } from "react-router-dom";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableHeaderComponent } from "../common/dgdgtable/DGDGTableHeaderComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";

import { MissingTitlesSummaryQueryComponent } from "./MissingTitlesSummaryQueryComponent";

export class MissingTitlesSummaryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            tableData: [],
            paintTable: false,
            days30PlusContractCount: null,
            days2130ContractCount: null,
            days1120ContractCount: null,
            days510ContractCount: null,
            totalContractCount: null,
            totalFundingAmount: null,
            agedPct: null,
            total1Text: null,
            total2Text: null,
            total3Text: null,
            total4Text: null,
            totalDealCount: null
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onTotalColumnCallback = this.onTotalColumnCallback.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    pageTitle = "Missing Titles"

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/MissingTitles/Summary", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("MissingTitlesSummaryComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick() {
        try {
            this.setState({
                isLoadingData: true
            });

            this.props.applicationInsights.trackTrace({ message: "/MissingTitlesSummaryComponent/onGetReportClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/MissingTitles/GetSummaryReport", {
                method: "GET",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                }
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let tableData = []
                    let tableRow = null;
                    parsedResponse.forEach((responseItem, index) => {
                        tableRow = tableData.filter(rowData => rowData.store_number === responseItem.store_number);
                        let missingTitleRow = this.getAgeGroups(tableRow, responseItem);
                        if (tableRow.length === 0) {
                            tableData.push(missingTitleRow)
                        }
                    });

                    this.setState({
                        isLoadingData: false,
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/MissingTitles/GetSummaryReport", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getAgeGroups(tableRow, responseItem) {
        if (tableRow.length === 0) {
            tableRow.store_number = responseItem.store_number;
            tableRow.store_web_name = responseItem.store_web_name;
            tableRow.days_total_count = tableRow.deal_number_total_count = 0;
        }
        else {
            tableRow = tableRow[0];
        }
        if (responseItem.age_group >= 0 && responseItem.age_group <= 10) {
            tableRow.days_0_10_count = responseItem.count;
            tableRow.days_0_10_total_count = tableRow.length === 0 ? responseItem.count : tableRow.days_0_10_total_count + responseItem.count;
        }
        else if (responseItem.age_group >= 11 && responseItem.age_group <= 20) {
            tableRow.days_11_20_count = responseItem.count;
            tableRow.days_11_20_total_count = tableRow.length === 0 ? responseItem.count : tableRow.days_11_20_total_count + responseItem.count;
        }
        else if (responseItem.age_group >= 21 && responseItem.age_group <= 30) {
            tableRow.days_21_30_count = responseItem.count;
            tableRow.days_21_30_total_count = tableRow.length === 0 ? responseItem.count : tableRow.days_21_30_total_count + responseItem.count;
        }
        else if (responseItem.age_group > 30) {
            tableRow.days_30_plus_count = responseItem.count;
            tableRow.days_30_plus_total_count = tableRow.length === 0 ? responseItem.count : tableRow.days_30_plus_total_count + responseItem.count;
        }
        tableRow.days_total_count += responseItem.count;
        tableRow.deal_number_total_count += responseItem.deal_number_count;
        return tableRow;
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("MissingTitlesSummaryComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTotalColumnCallback(rowData, columnName) {
        return <Link to={"/MissingTitles/Detail/" + rowData.store_number}> {rowData[columnName]}</Link>;
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <MissingTitlesSummaryQueryComponent applicationInsights={this.props.applicationInsights} isLoadingData={this.state.isLoadingData} onGetReportClick={this.onGetReportClick} />
            <div className="dgdg-site-scrollable-content">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText="Summary" tableData={this.state.tableData} paintTable={this.state.paintTable} showFooter
                    copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableHeaderComponent position="PreHeader">
                        <tr className="dgdg-table-v3-header">
                            <td />
                            <td colSpan="1" className="text-center font-weight-bold dgdg-missing-title-summary-gt-30">30+ Days</td>
                            <td colSpan="1" className="text-center font-weight-bold dgdg-missing-title-summary-gt-20">21-30 Days</td>
                            <td colSpan="1" className="text-center font-weight-bold dgdg-missing-title-summary-gt-10">11-20 Days</td>
                            <td colSpan="1" className="text-center font-weight-bold">0-10 Days</td>
                            <td colSpan="2" className="text-center font-weight-bold dgdg-missing-title-summary-total">Total</td>
                        </tr>
                    </DGDGTableHeaderComponent>
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="Store" dataColumn="store_web_name" sortColumn="store_number" filterColumn="store_web_name" footerText="Total" />
                        <DGDGTableColumnComponent headerText="Missing Titles" dataColumn="days_30_plus_count" filterColumn="days_30_plus_count" sortColumn="days_30_plus_count" footerCssName="dgdg-missing-title-summary-number-column" cssName="dgdg-missing-title-summary-number-column" footerFunction="sum" />
                        <DGDGTableColumnComponent headerText="Missing Titles" dataColumn="days_21_30_count" sortColumn="days_21_30_count" filterColumn="days_21_30_count" footerFunction="sum" footerCssName="dgdg-missing-title-summary-number-column" cssName="dgdg-missing-title-summary-number-column" />
                        <DGDGTableColumnComponent headerText="Missing Titles" dataColumn="days_11_20_count" sortColumn="days_11_20_count" filterColumn="days_11_20_count" footerFunction="sum" footerCssName="dgdg-missing-title-summary-number-column" cssName="dgdg-missing-title-summary-number-column" />
                        <DGDGTableColumnComponent headerText="Missing Titles" dataColumn="days_0_10_count" sortColumn="days_0_10_count" filterColumn="days_0_10_count" footerFunction="sum" footerCssName="dgdg-missing-title-summary-number-column" cssName="dgdg-missing-title-summary-number-column" />
                        <DGDGTableColumnComponent headerText="Missing Titles" dataColumn="days_total_count" sortColumn="days_total_count" filterColumn="days_total_count" dataType="number" footerFunction="sum" footerCssName="dgdg-missing-title-summary-number-column" cssName="dgdg-missing-title-summary-number-column" dataColumnCallback={this.onTotalColumnCallback} />
                        <DGDGTableColumnComponent headerText="Sold Without Title" dataColumn="deal_number_total_count" sortColumn="deal_number_total_count" filterColumn="deal_number_total_count" dataType="number" footerFunction="sum" footerCssName="dgdg-missing-title-summary-number-column" cssName="dgdg-missing-title-summary-number-column" dataColumnCallback={this.onTotalColumnCallback} />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
        </div>;
    }
}
