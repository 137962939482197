import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { SalesStoreReportWeekendSummaryHeaderRowComponent } from "./SalesStoreReportWeekendSummaryHeaderRowComponent";
import { SalesStoreReportWeekendSummaryRowComponent } from "./SalesStoreReportWeekendSummaryRowComponent";
import { SalesStoreReportWeekendRowComponent } from "./SalesStoreReportWeekendRowComponent";
import { SalesStoreReportWeekendFooterRowComponent } from "./SalesStoreReportWeekendFooterRowComponent";
import { SalesStoreReportWeekendQueryComponent } from "./SalesStoreReportWeekendQueryComponent";
import { SalesStoreReportWeekendTitleHeaderRowComponent } from "./SalesStoreReportWeekendTitleHeaderRowComponent";

export class SalesStoreReportWeekendComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            isLoadingData: false,
            weekendSummaryData: null,
            lastFridayDate: null,
            lastSaturdayDate: null,
            lastSundayDate: null,
            nwSales: null,
            usedSales: null,
            totalSales: null,
            nwSalesSummaryData: null,
            usedSalesSummaryData: null,
            totalSalesSummaryData: null
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onCopyClick = this.onCopyClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    pageTitle = "Sales Store Report";

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/SalesStoreReport2/Weekend", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("SalesStoreReportWeekendComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getLastUpdatedDate(startDate) {
        try {
            let inputData = {
                startDate: CommonService.formatServerDateTime(startDate)
            };
            this.props.applicationInsights.trackTrace({ message: "/SalesStoreReportWeekendComponent/getLastUpdatedDate", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SalesStoreReport2/GetLastUpdatedWeekendReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (CommonService.isDateValid(parsedResponse)) {
                        this.props.onLastUpdatedDateChanged(moment.utc(parsedResponse));
                    }
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SalesStoreReport2/GetLastUpdatedWeekendReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(startDate) {
        try {
            this.setState({ isLoadingData: true });
            this.getLastUpdatedDate(startDate);
            let inputData = {
                startDate: CommonService.formatServerDateTime(startDate)
            };

            this.props.applicationInsights.trackTrace({ message: "/SalesStoreReportWeekendComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SalesStoreReport2/GetSalesWeekendReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let weekendSummaryData = parsedResponse;
                    let nwSales = parsedResponse.filter(row => row['stat'] === 'New' && row['store_number'] !== 99);
                    let nwSalesSummaryData = parsedResponse.filter(row => row['stat'] === 'New' && row['store_number'] === 99);
                    let usedSales = parsedResponse.filter(row => row['stat'] === 'Used' && row['store_number'] !== 99)
                    let usedSalesSummaryData = parsedResponse.filter(row => row['stat'] === 'Used' && row['store_number'] === 99);
                    let totalSales = parsedResponse.filter(row => row['stat'] === 'NUT' && row['store_number'] !== 99);
                    let totalSalesSummaryData = parsedResponse.filter(row => row['stat'] === 'NUT' && row['store_number'] === 99);
                    let lastFridayDate = moment(startDate);
                    let lastSaturdayDate = moment(lastFridayDate).add(1, "days");
                    let lastSundayDate = moment(lastFridayDate).add(2, "days");
                    this.setState({
                        isLoadingData: false,
                        weekendSummaryData: weekendSummaryData,
                        nwSales: nwSales,
                        nwSalesSummaryData: nwSalesSummaryData,
                        usedSales: usedSales,
                        usedSalesSummaryData: usedSalesSummaryData,
                        totalSales: totalSales,
                        totalSalesSummaryData: totalSalesSummaryData,
                        lastFridayDate: CommonService.formatDate(lastFridayDate),
                        lastSaturdayDate: CommonService.formatDate(lastSaturdayDate),
                        lastSundayDate: CommonService.formatDate(lastSundayDate)
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        weekendSummaryData: null,
                        lastFridayDate: null,
                        lastSaturdayDate: null,
                        lastSundayDate: null,
                        nwSalesSummaryData: null,
                        usedSalesSummaryData: null,
                        totalSalesSummaryData: null
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SalesStoreReport2/onGetReportClick", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyClick(event) {
        try {
            let tableText = "\t\tTotal Sales\t\t\t\t\t\t\t\t\t\tVOLUME\t\tGROSS\t\tPVR\r\n";
            tableText += "Store #\tStore Name\tFRI Volume\tFRI Gross\tSAT Volume\tSAT Gross\tSUN Volume\tSUN Gross\tWKD Volume\tWKD PVR\tWKD Gross\t\tWOW %▲\tYOY %▲\tWOW %▲\tYOY %▲\tWOW $▲\tYOY $▲\r\n";

            let totalSales = this.state.totalSales;
            if (totalSales)
                for (let i = 0; i < totalSales.length - 1; i = i + 4) {
                    tableText += (totalSales[i].store_number ? totalSales[i].store_number : "") + "\t" + (totalSales[i].store_name ? totalSales[i].store_name.split("|")[1] : "") +
                        + "\t" + (totalSales[i].volume ? CommonService.formatNumber(totalSales[i].volume) : "") + "\t" + (totalSales[i].total_gross ? CommonService.formatCurrency(totalSales[i].total_gross) : "")
                        + "\t" + (totalSales[i + 1].volume ? CommonService.formatNumber(totalSales[i + 1].volume) : "") + "\t" + (totalSales[i + 1].total_gross ? CommonService.formatCurrency(totalSales[i + 1].total_gross) : "")
                        + "\t" + (totalSales[i + 2].volume ? CommonService.formatNumber(totalSales[i + 2].volume) : "") + "\t" + (totalSales[i + 2].total_gross ? CommonService.formatCurrency(totalSales[i + 2].total_gross) : "")
                        + "\t" + (totalSales[i + 3].volume ? CommonService.formatNumber(totalSales[i + 3].volume) : "") + "\t" + (totalSales[i + 3].total_pvr ? CommonService.formatCurrency(totalSales[i + 3].total_pvr) : "")
                        + "\t" + (totalSales[i + 3].total_gross ? CommonService.formatCurrency(totalSales[i + 3].total_gross) : "")
                        + "\t" + (totalSales[i + 3].store_code ? totalSales[i + 3].store_code : "")
                        + "\t" + (totalSales[i + 3].wow_volume_delta_pct ? CommonService.formatPercentage(totalSales[i + 3].wow_volume_delta_pct) : "") + "\t" + (totalSales[i + 3].yoy_volume_delta_pct ? CommonService.formatPercentage(totalSales[i + 3].yoy_volume_delta_pct) : "")
                        + "\t" + (totalSales[i + 3].wow_total_gross_delta_pct ? CommonService.formatPercentage(totalSales[i + 3].wow_total_gross_delta_pct) : "") + "\t" + (totalSales[i + 3].yoy_total_gross_delta_pct ? CommonService.formatPercentage(totalSales[i + 3].yoy_total_gross_delta_pct) : "")
                        + "\t" + (totalSales[i + 3].wow_total_pvr_delta ? CommonService.formatCurrency(totalSales[i + 3].wow_total_pvr_delta) : "") + "\t" + (totalSales[i + 3].yoy_total_pvr_delta ? CommonService.formatCurrency(totalSales[i + 3].yoy_total_pvr_delta) : "")


                        + "\r\n";
                }

            let totalSalesSummaryData = this.state.totalSalesSummaryData;
            if (totalSalesSummaryData)
                for (let i = 0; i < totalSalesSummaryData.length - 1; i = i + 4) {
                    tableText += (totalSalesSummaryData[i].store_name ? totalSalesSummaryData[i].store_name.split("|")[0] : "") + "\t" + (totalSalesSummaryData[i].store_name ? totalSalesSummaryData[i].store_name.split("|")[1] : "")
                        + "\t" + (totalSalesSummaryData[i].volume ? CommonService.formatNumber(totalSalesSummaryData[i].volume) : "") + "\t" + (totalSalesSummaryData[i].total_gross ? CommonService.formatCurrency(totalSalesSummaryData[i].total_gross) : "")
                        + "\t" + (totalSalesSummaryData[i + 1].volume ? CommonService.formatNumber(totalSalesSummaryData[i + 1].volume) : "") + "\t" + (totalSalesSummaryData[i + 1].total_gross ? CommonService.formatCurrency(totalSalesSummaryData[i + 1].total_gross) : "")
                        + "\t" + (totalSalesSummaryData[i + 2].volume ? CommonService.formatNumber(totalSalesSummaryData[i + 2].volume) : "") + "\t" + (totalSalesSummaryData[i + 2].total_gross ? CommonService.formatCurrency(totalSalesSummaryData[i + 2].total_gross) : "")
                        + "\t" + (totalSalesSummaryData[i + 3].volume ? CommonService.formatNumber(totalSalesSummaryData[i + 3].volume) : "") + "\t" + (totalSalesSummaryData[i + 3].total_pvr ? CommonService.formatCurrency(totalSalesSummaryData[i + 3].total_pvr) : "")
                        + "\t" + (totalSalesSummaryData[i + 3].total_gross ? CommonService.formatCurrency(totalSalesSummaryData[i + 3].total_gross) : "")
                        + "\t" + (totalSalesSummaryData[i + 3].store_code ? totalSalesSummaryData[i + 3].store_code : "")
                        + "\t" + (totalSalesSummaryData[i + 3].wow_volume_delta_pct ? CommonService.formatPercentage(totalSalesSummaryData[i + 3].wow_volume_delta_pct) : "") + "\t" + (totalSalesSummaryData[i + 3].yoy_volume_delta_pct ? CommonService.formatPercentage(totalSalesSummaryData[i + 3].yoy_volume_delta_pct) : "")
                        + "\t" + (totalSalesSummaryData[i + 3].wow_total_gross_delta_pct ? CommonService.formatPercentage(totalSalesSummaryData[i + 3].wow_total_gross_delta_pct) : "") + "\t" + (totalSalesSummaryData[i + 3].yoy_total_gross_delta_pct ? CommonService.formatPercentage(totalSalesSummaryData[i + 3].yoy_total_gross_delta_pct) : "")
                        + "\t" + (totalSalesSummaryData[i + 3].wow_total_pvr_delta ? CommonService.formatCurrency(totalSalesSummaryData[i + 3].wow_total_pvr_delta) : "") + "\t" + (totalSalesSummaryData[i + 3].yoy_total_pvr_delta ? CommonService.formatCurrency(totalSalesSummaryData[i + 3].yoy_total_pvr_delta) : "")
                }


            tableText += "\n\n\tNew Sales\t\t\t\t\t\t\t\t\t\tVOLUME\t\tGROSS\t\tPVR\r\n";
            tableText += "Store #\tStore Name\tFRI Volume\tFRI Gross\tSAT Volume\tSAT Gross\tSUN Volume\tSUN Gross\tWKD Volume\tWKD PVR\tWKD Gross\t\tWOW %▲\tYOY %▲\tWOW %▲\tYOY %▲\tWOW $▲\tYOY $▲\r\n";

            let newSales = this.state.nwSales;
            if (newSales)
                for (let i = 0; i < newSales.length - 1; i = i + 4) {
                    tableText += (newSales[i].store_number ? newSales[i].store_number : "") + "\t" + (newSales[i].store_name ? newSales[i].store_name.split("|")[1] : "") +
                        + "\t" + (newSales[i].volume ? CommonService.formatNumber(newSales[i].volume) : "") + "\t" + (newSales[i].total_gross ? CommonService.formatCurrency(newSales[i].total_gross) : "")
                        + "\t" + (newSales[i + 1].volume ? CommonService.formatNumber(newSales[i + 1].volume) : "") + "\t" + (newSales[i + 1].total_gross ? CommonService.formatCurrency(newSales[i + 1].total_gross) : "")
                        + "\t" + (newSales[i + 2].volume ? CommonService.formatNumber(newSales[i + 2].volume) : "") + "\t" + (newSales[i + 2].total_gross ? CommonService.formatCurrency(newSales[i + 2].total_gross) : "")
                        + "\t" + (newSales[i + 3].volume ? CommonService.formatNumber(newSales[i + 3].volume) : "") + "\t" + (newSales[i + 3].total_pvr ? CommonService.formatCurrency(newSales[i + 3].total_pvr) : "")
                        + "\t" + (newSales[i + 3].total_gross ? CommonService.formatCurrency(newSales[i + 3].total_gross) : "")
                        + "\t" + (newSales[i + 3].store_code ? newSales[i + 3].store_code : "")
                        + "\t" + (newSales[i + 3].wow_volume_delta_pct ? CommonService.formatPercentage(newSales[i + 3].wow_volume_delta_pct) : "") + "\t" + (newSales[i + 3].yoy_volume_delta_pct ? CommonService.formatPercentage(newSales[i + 3].yoy_volume_delta_pct) : "")
                        + "\t" + (newSales[i + 3].wow_total_gross_delta_pct ? CommonService.formatPercentage(newSales[i + 3].wow_total_gross_delta_pct) : "") + "\t" + (newSales[i + 3].yoy_total_gross_delta_pct ? CommonService.formatPercentage(newSales[i + 3].yoy_total_gross_delta_pct) : "")
                        + "\t" + (newSales[i + 3].wow_total_pvr_delta ? CommonService.formatCurrency(newSales[i + 3].wow_total_pvr_delta) : "") + "\t" + (newSales[i + 3].yoy_total_pvr_delta ? CommonService.formatCurrency(newSales[i + 3].yoy_total_pvr_delta) : "")

                        + "\r\n";
                }

            let newSalesSummaryData = this.state.nwSalesSummaryData;
            if (newSalesSummaryData)
                for (let i = 0; i < newSalesSummaryData.length - 1; i = i + 4) {
                    tableText += (newSalesSummaryData[i].store_name ? newSalesSummaryData[i].store_name.split("|")[0] : "") + "\t" + (newSalesSummaryData[i].store_name ? newSalesSummaryData[i].store_name.split("|")[1] : "")
                        + "\t" + (newSalesSummaryData[i].volume ? CommonService.formatNumber(newSalesSummaryData[i].volume) : "") + "\t" + (newSalesSummaryData[i].total_gross ? CommonService.formatCurrency(newSalesSummaryData[i].total_gross) : "")
                        + "\t" + (newSalesSummaryData[i + 1].volume ? CommonService.formatNumber(newSalesSummaryData[i + 1].volume) : "") + "\t" + (newSalesSummaryData[i + 1].total_gross ? CommonService.formatCurrency(newSalesSummaryData[i + 1].total_gross) : "")
                        + "\t" + (newSalesSummaryData[i + 2].volume ? CommonService.formatNumber(newSalesSummaryData[i + 2].volume) : "") + "\t" + (newSalesSummaryData[i + 2].total_gross ? CommonService.formatCurrency(newSalesSummaryData[i + 2].total_gross) : "")
                        + "\t" + (newSalesSummaryData[i + 3].volume ? CommonService.formatNumber(newSalesSummaryData[i + 3].volume) : "") + "\t" + (newSalesSummaryData[i + 3].total_pvr ? CommonService.formatCurrency(newSalesSummaryData[i + 3].total_pvr) : "")
                        + "\t" + (newSalesSummaryData[i + 3].total_gross ? CommonService.formatCurrency(newSalesSummaryData[i + 3].total_gross) : "")
                        + "\t" + (newSalesSummaryData[i + 3].store_code ? newSalesSummaryData[i + 3].store_code : "")
                        + "\t" + (newSalesSummaryData[i + 3].wow_volume_delta_pct ? CommonService.formatPercentage(newSalesSummaryData[i + 3].wow_volume_delta_pct) : "") + "\t" + (newSalesSummaryData[i + 3].yoy_volume_delta_pct ? CommonService.formatPercentage(newSalesSummaryData[i + 3].yoy_volume_delta_pct) : "")
                        + "\t" + (newSalesSummaryData[i + 3].wow_total_gross_delta_pct ? CommonService.formatPercentage(newSalesSummaryData[i + 3].wow_total_gross_delta_pct) : "") + "\t" + (newSalesSummaryData[i + 3].yoy_total_gross_delta_pct ? CommonService.formatPercentage(newSalesSummaryData[i + 3].yoy_total_gross_delta_pct) : "")
                        + "\t" + (newSalesSummaryData[i + 3].wow_total_pvr_delta ? CommonService.formatCurrency(newSalesSummaryData[i + 3].wow_total_pvr_delta) : "") + "\t" + (newSalesSummaryData[i + 3].yoy_total_pvr_delta ? CommonService.formatCurrency(newSalesSummaryData[i + 3].yoy_total_pvr_delta) : "")
                }

            tableText += "\n\n\tUsed Sales\t\t\t\t\t\t\t\t\t\tVOLUME\t\tGROSS\t\tPVR\r\n";
            tableText += "Store #\tStore Name\tFRI Volume\tFRI Gross\tSAT Volume\tSAT Gross\tSUN Volume\tSUN Gross\tWKD Volume\tWKD PVR\tWKD Gross\t\tWOW %▲\tYOY %▲\tWOW %▲\tYOY %▲\tWOW $▲\tYOY $▲\r\n";

            let usedSales = this.state.usedSales;
            if (usedSales)
                for (let i = 0; i < usedSales.length - 1; i = i + 4) {
                    tableText += (usedSales[i].store_number ? usedSales[i].store_number : "") + "\t" + (usedSales[i].store_name ? usedSales[i].store_name.split("|")[1] : "") +
                        + "\t" + (usedSales[i].volume ? CommonService.formatNumber(usedSales[i].volume) : "") + "\t" + (usedSales[i].total_gross ? CommonService.formatCurrency(usedSales[i].total_gross) : "")
                        + "\t" + (usedSales[i + 1].volume ? CommonService.formatNumber(usedSales[i + 1].volume) : "") + "\t" + (usedSales[i + 1].total_gross ? CommonService.formatCurrency(usedSales[i + 1].total_gross) : "")
                        + "\t" + (usedSales[i + 2].volume ? CommonService.formatNumber(usedSales[i + 2].volume) : "") + "\t" + (usedSales[i + 2].total_gross ? CommonService.formatCurrency(usedSales[i + 2].total_gross) : "")
                        + "\t" + (usedSales[i + 3].volume ? CommonService.formatNumber(usedSales[i + 3].volume) : "") + "\t" + (usedSales[i + 3].total_pvr ? CommonService.formatCurrency(usedSales[i + 3].total_pvr) : "")
                        + "\t" + (usedSales[i + 3].total_gross ? CommonService.formatCurrency(usedSales[i + 3].total_gross) : "")
                        + "\t" + (usedSales[i + 3].store_code ? usedSales[i + 3].store_code : "")
                        + "\t" + (usedSales[i + 3].wow_volume_delta_pct ? CommonService.formatPercentage(usedSales[i + 3].wow_volume_delta_pct) : "") + "\t" + (usedSales[i + 3].yoy_volume_delta_pct ? CommonService.formatPercentage(usedSales[i + 3].yoy_volume_delta_pct) : "")
                        + "\t" + (usedSales[i + 3].wow_total_gross_delta_pct ? CommonService.formatPercentage(usedSales[i + 3].wow_total_gross_delta_pct) : "") + "\t" + (usedSales[i + 3].yoy_total_gross_delta_pct ? CommonService.formatPercentage(usedSales[i + 3].yoy_total_gross_delta_pct) : "")
                        + "\t" + (usedSales[i + 3].wow_total_pvr_delta ? CommonService.formatCurrency(usedSales[i + 3].wow_total_pvr_delta) : "") + "\t" + (usedSales[i + 3].yoy_total_pvr_delta ? CommonService.formatCurrency(usedSales[i + 3].yoy_total_pvr_delta) : "")

                        + "\r\n";
                }

            let usedSalesSummaryData = this.state.usedSalesSummaryData;
            if (usedSalesSummaryData)
                for (let i = 0; i < usedSalesSummaryData.length - 1; i = i + 4) {
                    tableText += (usedSalesSummaryData[i].store_name ? usedSalesSummaryData[i].store_name.split("|")[0] : "") + "\t" + (usedSalesSummaryData[i].store_name ? usedSalesSummaryData[i].store_name.split("|")[1] : "")
                        + "\t" + (usedSalesSummaryData[i].volume ? CommonService.formatNumber(usedSalesSummaryData[i].volume) : "") + "\t" + (usedSalesSummaryData[i].total_gross ? CommonService.formatCurrency(usedSalesSummaryData[i].total_gross) : "")
                        + "\t" + (usedSalesSummaryData[i + 1].volume ? CommonService.formatNumber(usedSalesSummaryData[i + 1].volume) : "") + "\t" + (usedSalesSummaryData[i + 1].total_gross ? CommonService.formatCurrency(usedSalesSummaryData[i + 1].total_gross) : "")
                        + "\t" + (usedSalesSummaryData[i + 2].volume ? CommonService.formatNumber(usedSalesSummaryData[i + 2].volume) : "") + "\t" + (usedSalesSummaryData[i + 2].total_gross ? CommonService.formatCurrency(usedSalesSummaryData[i + 2].total_gross) : "")
                        + "\t" + (usedSalesSummaryData[i + 3].volume ? CommonService.formatNumber(usedSalesSummaryData[i + 3].volume) : "") + "\t" + (usedSalesSummaryData[i + 3].total_pvr ? CommonService.formatCurrency(usedSalesSummaryData[i + 3].total_pvr) : "")
                        + "\t" + (usedSalesSummaryData[i + 3].total_gross ? CommonService.formatCurrency(usedSalesSummaryData[i + 3].total_gross) : "")
                        + "\t" + (usedSalesSummaryData[i + 3].store_code ? usedSalesSummaryData[i + 3].store_code : "")
                        + "\t" + (usedSalesSummaryData[i + 3].wow_volume_delta_pct ? CommonService.formatPercentage(usedSalesSummaryData[i + 3].wow_volume_delta_pct) : "") + "\t" + (usedSalesSummaryData[i + 3].yoy_volume_delta_pct ? CommonService.formatPercentage(usedSalesSummaryData[i + 3].yoy_volume_delta_pct) : "")
                        + "\t" + (usedSalesSummaryData[i + 3].wow_total_gross_delta_pct ? CommonService.formatPercentage(usedSalesSummaryData[i + 3].wow_total_gross_delta_pct) : "") + "\t" + (usedSalesSummaryData[i + 3].yoy_total_gross_delta_pct ? CommonService.formatPercentage(usedSalesSummaryData[i + 3].yoy_total_gross_delta_pct) : "")
                        + "\t" + (usedSalesSummaryData[i + 3].wow_total_pvr_delta ? CommonService.formatCurrency(usedSalesSummaryData[i + 3].wow_total_pvr_delta) : "") + "\t" + (usedSalesSummaryData[i + 3].yoy_total_pvr_delta ? CommonService.formatCurrency(usedSalesSummaryData[i + 3].yoy_total_pvr_delta) : "")
                }
            navigator.clipboard.writeText(tableText);
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("SalesStoreReportWeekendComponent", "onCopyClick", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        let totalSalesSummaryRow = [];
        let totalSalesHeaderRow = [];
        let newSalesSummaryRow = [];
        let newSalesHeaderRow = [];
        let usedSalesSummaryRow = [];
        let usedSalesHeaderRow = [];

        let newSalesFooterRow = [];
        let usedSalesFooterRow = [];
        let totalSalesFooterRow = [];

        if (this.state.weekendSummaryData) {
            let nwSales = this.state.weekendSummaryData.filter(row => row['stat'] === 'New');
            let usedSales = this.state.weekendSummaryData.filter(row => row['stat'] === 'Used');
            let totalSales = this.state.weekendSummaryData.filter(row => row['stat'] === 'NUT');
            let rowIndex = 0;

            if (nwSales) {
                for (let i = 0; i < nwSales.length - 1; i = i + 4) {
                    let currentStoreData = nwSales.filter(row => row['store_number'] === nwSales[i].store_number);
                    if (nwSales[i].store_number === 99) {
                        newSalesFooterRow.push(<SalesStoreReportWeekendFooterRowComponent key={"NewFooter" + rowIndex} rowIndex={rowIndex} salesData={currentStoreData} />);
                        newSalesHeaderRow.push(<SalesStoreReportWeekendSummaryRowComponent key={"New" + rowIndex} salesData={currentStoreData} title="NEW" />);
                    }
                    else {
                        newSalesSummaryRow.push(<SalesStoreReportWeekendRowComponent key={"NewRow" + rowIndex} rowIndex={rowIndex} salesData={currentStoreData} />);
                    }
                    rowIndex++;
                }
            }

            rowIndex = 0;
            if (usedSales) {
                for (let i = 0; i < usedSales.length - 1; i = i + 4) {
                    let currentStoreData = usedSales.filter(row => row['store_number'] === usedSales[i].store_number);
                    if (usedSales[i].store_number === 99) {
                        usedSalesFooterRow.push(<SalesStoreReportWeekendFooterRowComponent key={"UsedFooter" + rowIndex} rowIndex={rowIndex} salesData={currentStoreData} />);
                        usedSalesHeaderRow.push(<SalesStoreReportWeekendSummaryRowComponent key={"Used" + rowIndex} salesData={currentStoreData} title="USED" />);
                    }
                    else {
                        usedSalesSummaryRow.push(<SalesStoreReportWeekendRowComponent key={"UsedRow" + rowIndex} rowIndex={rowIndex} salesData={currentStoreData} />);
                    }
                    rowIndex++;
                }
            }

            rowIndex = 0;
            if (totalSales) {
                for (let i = 0; i < totalSales.length - 1; i = i + 4) {
                    let currentStoreData = totalSales.filter(row => row['store_number'] === totalSales[i].store_number);
                    if (totalSales[i].store_number === 99) {
                        totalSalesFooterRow.push(<SalesStoreReportWeekendFooterRowComponent key={"TotalFooter" + rowIndex} rowIndex={rowIndex} salesData={currentStoreData} />);
                        totalSalesHeaderRow.push(<SalesStoreReportWeekendSummaryRowComponent key={"Total" + rowIndex} salesData={currentStoreData} title="TOTAL" />);
                    }
                    else {
                        totalSalesSummaryRow.push(<SalesStoreReportWeekendRowComponent key={"TotalRow" + rowIndex} rowIndex={rowIndex} salesData={currentStoreData} />);
                    }
                    rowIndex++;
                }
            }
        }

        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <SalesStoreReportWeekendQueryComponent
                applicationInsights={this.props.applicationInsights}
                isLoadingData={this.state.isLoadingData}
                onGetReportClick={this.onGetReportClick}
                onShowAlert={this.props.onShowAlert}
                onCopyClick={this.onCopyClick}
            />
            <div className="dgdg-sales-store dgdg-site-scrollable-content">
                <table className="dgdg-table-v3 text-center">
                    <tbody>
                        <SalesStoreReportWeekendSummaryHeaderRowComponent />
                        {totalSalesHeaderRow}
                        {newSalesHeaderRow}
                        {usedSalesHeaderRow}
                        <tr>
                            <td><div className="dgdg-card-gutter" /></td>
                        </tr>
                        <tr className="dgdg-table-v3-header">
                            <td className="dgdg-sales-store-bg dgdg-no-border-top" />
                            <td className="text-center dgdg-sales-store-bg-row-alt dgdg-font-blue" colSpan="2">FRIDAY</td>
                            <td className="text-center dgdg-sales-store-bg-row-alt dgdg-font-blue" colSpan="2">SATURDAY</td>
                            <td className="text-center dgdg-sales-store-bg-row-alt dgdg-font-blue" colSpan="2">SUNDAY</td>
                            <td colSpan="11" className="dgdg-sales-store-bg dgdg-no-border-top" />
                        </tr>
                        <tr className="dgdg-table-v3-header">
                            <td className="dgdg-sales-store-bg dgdg-no-border-top" />
                            <td className="text-center dgdg-sales-store-bg-row-alt dgdg-font-blue" colSpan="2">{this.state.lastFridayDate}</td>
                            <td className="text-center dgdg-sales-store-bg-row-alt dgdg-font-blue" colSpan="2">{this.state.lastSaturdayDate}</td>
                            <td className="text-center dgdg-sales-store-bg-row-alt dgdg-font-blue" colSpan="2">{this.state.lastSundayDate}</td>
                            <td colSpan="11" className="dgdg-sales-store-bg dgdg-no-border-top" />
                        </tr>
                        <tr>
                            <td colSpan="19"><div className="dgdg-card-gutter" /></td>
                        </tr>
                        <SalesStoreReportWeekendTitleHeaderRowComponent title="Total Sales" />
                        {totalSalesSummaryRow}
                        <tr>
                            <td colSpan="19" />
                        </tr>
                        {totalSalesFooterRow}
                        <tr>
                            <td><div className="dgdg-card-gutter" /></td>
                        </tr>
                        <SalesStoreReportWeekendTitleHeaderRowComponent title="New Sales" />
                        {newSalesSummaryRow}
                        <tr>
                            <td colSpan="19" />
                        </tr>
                        {newSalesFooterRow}
                        <tr>
                            <td><div className="dgdg-card-gutter" /></td>
                        </tr>
                        <SalesStoreReportWeekendTitleHeaderRowComponent title="Used Sales" />
                        {usedSalesSummaryRow}
                        <tr>
                            <td colSpan="19" />
                        </tr>
                        {usedSalesFooterRow}
                    </tbody>
                </table>
            </div>
        </div>;
    }
}
