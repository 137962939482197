import React from "react";

import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGQueryComponent } from "../common/dgdgquery/DGDGQueryComponent";

export class SalesStoreReportYTDQueryComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            if (state.showSpinner) {
                newState.showSpinner = props.isLoadingData;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedYear: null,
            showSpinner: false
        };


        this.onYearClick = this.onYearClick.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.years && this.state.selectedYear === null) {
                this.setState({
                    selectedYear: this.props.years[0]
                }, this.onGetReportClick);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onYearClick(id, year) {
        try {
            this.setState({ selectedYear: year });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event) {
        try {
            this.setState({ showSpinner: true });
            this.props.onGetReportClick(this.state.selectedYear);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGetReportClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGQueryComponent cssName="dgdg-card-gutter">
            <DGDGFormDropdownComponent label="Report Year" data={this.props.years} displayFormat="Year" value={this.state.selectedYear} onItemClick={this.onYearClick} onKeyDown={this.onKeyDown} />
            <button disabled={this.state.showSpinner || this.state.selectedYear === null} className="btn btn-primary dgdg-icon" onClick={(event) => this.onGetReportClick(event)}>
                Get Report <span className="fas fa-redo-alt dgdg-icon" />
            </button>
            <button className="btn btn-primary dgdg-copy-button" onClick={(event) => this.props.onCopyClick(event)}>
                Copy<span className="far fa-copy dgdg-icon" />
            </button>
            <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
        </DGDGQueryComponent>;
    }
}
