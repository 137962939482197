import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { PSOPSSalesQueryComponent } from "./PSOPSSalesQueryComponent";

export class PSOPSSalesRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            stores: null,
            showSpinner: false,
            tableTitle: "",
            paintTable: false,
            onlineProductSpecTableTitle: "",
            vipExpDirTableTitle: "",
            tableData: [],
            vipExpDirTableData: [],
            onlineProductSpecTableData: [],
            tableColumns: [],
            showScrollToTop: false,
            response: [],
            lastUpdatedDate: null,
            columnsToBeCopied: []
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onScrollClick = this.onScrollClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/PSOPSSales", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getStores();
            CommonService.clientAzureStorageLog("PSOPSSalesRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "PS/OPS Sales";

    filterData(response, selectedStore, selectedNewUsed, positionCode) {
        if (response !== null) {
            return response.filter(item => (selectedNewUsed === null || item.new_used === selectedNewUsed.value)
                && (selectedStore === null || item.store_number === selectedStore.store_number)
                && item.position_code === positionCode);
        }
    }

    onGetLastUpdatedDate() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/PSOPSSalesRouteComponent/onGetLastUpdatedDate", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/PSOPSSales/GetLastUpdated")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let lastUpdatedDate = "";
                    if (CommonService.isDateValid(parsedResponse))
                        lastUpdatedDate = moment.utc(parsedResponse)
                    this.setState({ lastUpdatedDate: lastUpdatedDate });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/PSOPSSales/GetLastUpdated", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getStores() {
        this.props.applicationInsights.trackTrace({ message: "/PSOPSSalesRouteComponent/getStores", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/PSOPSSales/GetStores")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({
                    stores: parsedResponse
                });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/PSOPSSales/GetStores", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    onGetReportClick(selectedStore, selectedNewUsed) {
        try {
            if (this.state.tableData.length <= 0) {
                this.setState({ isLoadingData: true });

                this.onGetLastUpdatedDate();

                this.props.applicationInsights.trackTrace({ message: "/PSOPSSalesRouteComponent/onGetReportClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                fetch("/PSOPSSales/GetReport")
                    .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                    .then(parsedResponse => {
                        let tableData = this.filterData(parsedResponse, selectedStore, selectedNewUsed, "Product Specialist");
                        let onlineProductSpecTableData = this.filterData(parsedResponse, selectedStore, selectedNewUsed, "Online Product Spec");
                        let vipExpDirTableData = this.filterData(parsedResponse, selectedStore, selectedNewUsed, "VIP Experience Dir");
                        let tableColumns = [];
                        tableColumns.push(<DGDGTableColumnComponent key="ordinal" headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />);
                        tableColumns.push(<DGDGTableColumnComponent key="sales_rep_name" headerText="Product Specialist" cssName="text-nowrap" dataColumn="sales_rep_name" sortColumn="sales_rep_name" filterColumn="sales_rep_name" />);
                        if (!selectedStore || selectedStore.store_number === 99) {
                            tableColumns.push(<DGDGTableColumnComponent key="store_name_web" headerText="Store" cssName="text-nowrap" dataColumn="store_name_web" sortColumn="store_number" filterColumn="store_name_web" />);
                        }

                        let currentDate = moment();
                        let currentYear = moment().year();
                        let previousYear = currentYear - 3;
                        let cssYearBorderRight = "";
                        while (previousYear <= currentYear - 1) {
                            cssYearBorderRight = previousYear === currentYear - 1 ? "dgdg-border-right" : "";
                            tableColumns.push(<DGDGTableColumnComponent key={previousYear.toString()} headerText={previousYear + "<br />Average"} dataColumn={previousYear.toString()} sortColumn={previousYear.toString()} filterColumn={previousYear.toString()} dataType="number_1" cssName={"dgdg-psops-bg-year-col " + cssYearBorderRight} headerCssName={cssYearBorderRight} />);
                            previousYear++;
                        }

                        let reportDate = moment().startOf("month");
                        reportDate.set("month", reportDate.month() - 12);
                        while (reportDate < currentDate) {
                            let dataColumn = reportDate.year() + "-" + (reportDate.month() + 1).toString().padStart(2, "0") + "-01";
                            let headerText = CommonService.formatMonth(reportDate).replace(" ", "<br />") + "<br />" + reportDate.year() + "<br />Final";
                            tableColumns.push(<DGDGTableColumnComponent key={"month-" + reportDate.month()} headerText={headerText} dataColumn={dataColumn} sortColumn={dataColumn} filterColumn={dataColumn} dataType="number_1" cssName="dgdg-psops-bg-other-col" backgroundColor={dataColumn + "_bg_color"} />);
                            reportDate.set("month", reportDate.month() + 1);
                        }

                        let columnsToBeCopied = []
                        for (let index = 0; index < tableColumns.length; index++) {
                            if (index !== 2)
                                columnsToBeCopied.push(index);
                        }

                        this.setState({
                            isLoadingData: false,
                            response: parsedResponse,
                            selectedStore: this.state.selectedStore,
                            tableColumns: tableColumns,
                            columnsToBeCopied: columnsToBeCopied,
                            tableData: CommonService.addOrdinalColumn(tableData),
                            onlineProductSpecTableData: CommonService.addOrdinalColumn(onlineProductSpecTableData),
                            vipExpDirTableData: CommonService.addOrdinalColumn(vipExpDirTableData),
                            tableTitle: "Product Specialist - " + (selectedStore === null ? "All Store" : selectedStore.display_name) +
                                " - " + selectedNewUsed.key,
                            onlineProductSpecTableTitle: "Online Product Spec - " + (selectedStore === null ? "All Store" : selectedStore.display_name) +
                                " - " + selectedNewUsed.key,
                            vipExpDirTableTitle: "VIP Experience Dir - " + (selectedStore === null ? "All Store" : selectedStore.display_name) +
                                " - " + selectedNewUsed.key,
                            paintTable: true
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                    })
                    .catch(notOKResponse => {
                        this.setState({
                            isLoadingData: false,
                            tableTitle: "",
                            tableData: [],
                            paintTable: true
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/PSOPSSales/GetReport", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                    console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.devError(jsonParseError, null, this.props.applicationInsights);
                                });
                        }
                    });
            }
            else {
                this.setState({ isLoadingData: false });
                let tableData = this.filterData(this.state.response, selectedStore, selectedNewUsed, "Product Specialist");
                let onlineProductSpecTableData = this.filterData(this.state.response, selectedStore, selectedNewUsed, "Online Product Spec");
                let vipExpDirTableData = this.filterData(this.state.response, selectedStore, selectedNewUsed, "VIP Experience Dir");

                this.setState({
                    tableData: CommonService.addOrdinalColumn(tableData),
                    onlineProductSpecTableData: CommonService.addOrdinalColumn(onlineProductSpecTableData),
                    vipExpDirTableData: CommonService.addOrdinalColumn(vipExpDirTableData),
                    tableTitle: "Product Specialist - " + (selectedStore === null ? "All Store" : selectedStore.display_name) +
                        " - " + selectedNewUsed.key,
                    onlineProductSpecTableTitle: "Online Product Spec - " + (selectedStore === null ? "All Store" : selectedStore.display_name) +
                        " - " + selectedNewUsed.key,
                    vipExpDirTableTitle: "VIP Experience Dir - " + (selectedStore === null ? "All Store" : selectedStore.display_name) +
                        " - " + selectedNewUsed.key,
                    paintTable: true
                }, () => {
                    this.setState({ paintTable: false });
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onScrollClick(event, selector) {
        try {
            CommonService.smoothScrollTo(".dgdg-psops", selector);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("PSOPSSalesRouteComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-responsive-page dgdg-flex dgdg-flex-column">
            <div className="mt-auto">
                <span className="float-right badge badge-primary dgdg-badge">
                    Last Updated Date: {this.state.lastUpdatedDate ? CommonService.formatDateTimeZone(this.state.lastUpdatedDate) : ""}
                </span>
            </div>
            <div className="dgdg-flex-item dgdg-site-content dgdg-psops-content">
                <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
                    <PSOPSSalesQueryComponent
                        applicationInsights={this.props.applicationInsights}
                        stores={this.state.stores}
                        isLoadingData={this.state.isLoadingData}
                        onGetReportClick={this.onGetReportClick}
                        onShowAlert={this.props.onShowAlert}
                    />
                    <nav className="nav nav-pills dgdg-nav">
                        <button type="button" className="btn btn-link nav-item nav-link dgdg-quick-link dgdg-quick-link-bg" onClick={(event) => this.onScrollClick(event, "#ProductSpecialist")}>Product Specialist</button>
                        <button type="button" className="btn btn-link nav-item nav-link dgdg-quick-link dgdg-quick-link-bg" onClick={(event) => this.onScrollClick(event, "#OnlineProductSpec")}>Online Product Specialist</button>
                        {this.state.vipExpDirTableData.length > 0
                            ? <button type="button" className="btn btn-link nav-item nav-link dgdg-quick-link dgdg-quick-link-bg" onClick={(event) => this.onScrollClick(event, "#VIPExperienceDir")}>VIP Experience Dir</button>
                            : null}
                    </nav>
                    <div className="dgdg-psops dgdg-site-scrollable-content">
                        <div className="dgdg-psops-products">
                            <div id="ProductSpecialist" className="dgdg-card-gutter">
                                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable}
                                    copyTableConfig={{ "columns": this.state.columnsToBeCopied, onCopyTableCallBack: this.onCopyTableCallBack }}
                                >
                                    <DGDGTableRowComponent>
                                        {this.state.tableColumns}
                                    </DGDGTableRowComponent>
                                </DGDGTableV3Component>
                            </div>
                            <div id="OnlineProductSpec" className={this.state.vipExpDirTableData.length > 0 ? "dgdg-card-gutter" : ""}>
                                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.onlineProductSpecTableTitle} tableData={this.state.onlineProductSpecTableData} paintTable={this.state.paintTable}
                                    copyTableConfig={{ "columns": this.state.columnsToBeCopied, onCopyTableCallBack: this.onCopyTableCallBack }}
                                >
                                    <DGDGTableRowComponent>
                                        {this.state.tableColumns}
                                    </DGDGTableRowComponent>
                                </DGDGTableV3Component>
                            </div>
                            {
                                this.state.vipExpDirTableData.length > 0
                                    ? <div id="VIPExperienceDir">
                                        <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.vipExpDirTableTitle} tableData={this.state.vipExpDirTableData} paintTable={this.state.paintTable}
                                            copyTableConfig={{ "columns": this.state.columnsToBeCopied, onCopyTableCallBack: this.onCopyTableCallBack }}
                                        >
                                            <DGDGTableRowComponent>
                                                {this.state.tableColumns}
                                            </DGDGTableRowComponent>
                                        </DGDGTableV3Component>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}
