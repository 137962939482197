import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGQueryComponent } from "../common/dgdgquery/DGDGQueryComponent";
import { ServiceScheduleSunbitQueryComponent } from "./ServiceScheduleSunbitQueryComponent";

export class ServiceScheduleFactoryWarrantyQueryComponent extends React.Component {
    static StatusCookie = "ServiceScheduleFactoryWarrantyStatusCookie";
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            if (state.showSpinner) {
                state.showSpinner = props.isLoadingData;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);

        this.state = {
            showSpinner: false,
            selectedStore: null,
            selectedStatus: null
        };

        this.onStoreClick = this.onStoreClick.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    statusList = [
        { key: "All", value: "All" },
        { key: "Needs Additional Documentation", value: "Needs Additional Documentation" },
        { key: "Awaiting Assistance from OEM", value: "Awaiting Assistance from OEM" },
        { key: "Pending - Factory Review", value: "Pending - Factory Review" },
        { key: "Paid Short/Over - Adjust", value: "Paid Short/Over - Adjust" },
        { key: "Paid in Full", value: "Paid in Full" }
    ];

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.stores && this.state.selectedStore === null) {
                let selectedStore = this.props.stores[0];
                let selectedStatus = null;
                let cookieStoreNumber = CommonService.getCookie(ServiceScheduleSunbitQueryComponent.StoreNumberCookie);
                if (this.props.match.params.StoreNumber) {
                    let storeNumber = Number(this.props.match.params.StoreNumber);
                    if (storeNumber && storeNumber > 0) {
                        let filterStore = this.props.stores.filter(store => storeNumber === store.store_number);
                        if (filterStore.length > 0) {
                            selectedStore = filterStore[0];
                        }
                    }
                }
                else if (cookieStoreNumber) {
                    cookieStoreNumber = parseInt(cookieStoreNumber);
                    let filterStore = this.props.stores.filter(store => store.store_number === cookieStoreNumber);
                    if (filterStore.length === 1) {
                        selectedStore = filterStore[0];
                    }
                }

                if (this.props.match.params.Status) {
                    let filterStatus = this.statusList.filter(issue => issue.key === this.props.match.params.Status);
                    if (filterStatus.length === 1) {
                        selectedStatus = filterStatus[0];
                    }
                }
                else {
                    let cookieStatus = CommonService.getCookie(ServiceScheduleFactoryWarrantyQueryComponent.StatusCookie);
                    if (cookieStatus) {
                        let filterStatus = this.statusList.filter(issue => issue.key === cookieStatus);
                        if (filterStatus.length === 1) {
                            selectedStatus = filterStatus[0];
                        }
                    }
                }

                this.setState({
                    selectedStore: selectedStore,
                    selectedStatus: selectedStatus
                }, () => {
                    if (this.props.match.params.StoreNumber
                        || this.props.match.params.Status) {
                        this.props.onGetReportClick(this.state.selectedStore, this.state.selectedStatus);
                    }
                    else {
                        this.onGetReportClick(null);
                    }
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStoreClick(event, store) {
        try {
            this.setState({ selectedStore: store });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event) {
        try {
            window.history.replaceState(null, this.pageTitle, "/ServiceSchedule/FactoryWarranty");
            CommonService.setCookie(ServiceScheduleSunbitQueryComponent.StoreNumberCookie, this.state.selectedStore.store_number, 30);
            if (this.state.selectedStatus) {
                CommonService.setCookie(ServiceScheduleFactoryWarrantyQueryComponent.StatusCookie, this.state.selectedStatus.key, 30);
            }
            this.setState({ showSpinner: true }, () => {
                this.props.onGetReportClick(this.state.selectedStore, this.state.selectedStatus);
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStatusChange(id, status) {
        try {
            this.setState({ selectedStatus: status })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGetReportClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGQueryComponent cssName="dgdg-card-gutter">
            <DGDGFormDropdownComponent label="Store #" itemKey="display_name" data={this.props.stores} value={this.state.selectedStore ? this.state.selectedStore.display_name : "Select Store"} onItemClick={this.onStoreClick} onKeyDown={this.onKeyDown} />
            <DGDGFormDropdownComponent label="Status" itemKey="value" data={this.statusList} value={this.state.selectedStatus ? this.state.selectedStatus.value : "Select Status"} onItemClick={this.onStatusChange} inputCssName="dgdg-service-schedule-query-status" />
            <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-button-width dgdg-icon" onClick={this.onGetReportClick}>
                Get Report<i className="fas fa-redo-alt dgdg-icon" />
            </button>
            <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
        </DGDGQueryComponent>;
    }
}
