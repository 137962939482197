import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableHeaderComponent } from "../common/dgdgtable/DGDGTableHeaderComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";

import { FIBankPartnershipReportQueryComponent } from "./FIBankPartnershipReportQueryComponent";

export class FIBankPartnershipReportRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            isDownLoading: false,
            tableData: [],
            tableTitle: "",
            paintTable: false
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/FIBankPartnershipReport", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("FIBankPartnershipReportRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Bank Partnership Report";

    onGetReportClick(startDate, endDate) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                startDate: startDate,
                endDate: endDate
            };

            this.props.applicationInsights.trackTrace({ message: "/FILenderReportRouteComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FIBankPartnershipReport/GetBankPartnershipReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let bankPartnershipReport = CommonService.addOrdinalColumn(parsedResponse.table);
                    this.setState({
                        isLoadingData: false,
                        tableTitle: CommonService.formatDate(moment(startDate)) + " - " +
                            CommonService.formatDate(moment(endDate)),
                        tableData: bankPartnershipReport.filter((row) => {
                            return row.store_number !== 99;
                        }),
                        paintTable: true,
                        footerRow: bankPartnershipReport.filter((row) => {
                            return row.store_number === 99;
                        })[0]
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: "",
                        tableData: [],
                        paintTable: true,
                        footerRow: null
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        this.setState({ isLoadingData: false });
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FIBankPartnershipReport/GetBankPartnershipReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDownloadClick(startDate, endDate) {
        try {
            this.setState({ isDownLoading: true });
            let inputData = {
                startDate: CommonService.formatServerDateTime(startDate),
                endDate: CommonService.formatServerDateTime(endDate)
            };

            this.props.applicationInsights.trackTrace({ message: "/FILenderReportRouteComponent/onDownloadClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FIBankPartnershipReport/Download", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response } else { throw response; } })
                .then(response => {
                    this.setState({ isDownLoading: false });
                    CommonService.downloadBlob(response);
                })
                .catch(notOKResponse => {
                    this.setState({ isDownLoading: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FIBankPartnershipReport/Download", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", { message: "Failed to download" });
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("FIBankPartnershipReportRouteComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-flex dgdg-flex-column">
            <div className="dgdg-report-body dgdg-flex dgdg-flex-column dgdg-flex-item dgdg-site-content">
                <FIBankPartnershipReportQueryComponent
                    isLoadingData={this.state.isLoadingData} isDownLoading={this.state.isDownLoading}
                    onGetReportClick={this.onGetReportClick} onDownloadClick={this.onDownloadClick}
                    onShowAlert={this.props.onShowAlert}
                    applicationInsights={this.props.applicationInsights}
                />
                <div className="dgdg-site-scrollable-content">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} footerRow={this.state.footerRow} paintTable={this.state.paintTable} showFooter
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableHeaderComponent position="PreHeader">
                            <tr className="dgdg-table-v3-header">
                                <td colSpan="6" style={{ "borderBottom": "1px solid #DCDCDC" }} />
                                <td colSpan="2" className="text-center dgdg-fi-bank-partner-bg-head font-weight-bold">Ally</td>
                                <td colSpan="2" className="text-center dgdg-fi-bank-partner-bg-head font-weight-bold">TD Auto</td>
                                <td colSpan="2" className="text-center dgdg-fi-bank-partner-bg-head font-weight-bold">Chase</td>
                                <td colSpan="2" className="text-center dgdg-fi-bank-partner-bg-head font-weight-bold">Upstart</td>
                                <td colSpan="2" className="text-center dgdg-fi-bank-partner-bg-head font-weight-bold">Cap One</td>
                                <td colSpan="2" className="text-center dgdg-fi-bank-partner-bg-head font-weight-bold">Wells</td>
                                <td colSpan="2" className="text-center dgdg-fi-bank-partner-bg-head font-weight-bold">Credit Unions</td>
                                <td colSpan="2" className="text-center dgdg-fi-bank-partner-bg-head font-weight-bold">BOA</td>
                                <td colSpan="2" className="text-center dgdg-fi-bank-partner-bg-head font-weight-bold">Unknown</td>
                            </tr>
                        </DGDGTableHeaderComponent>
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_name_web" sortColumn="store_number" filterColumn="store_name_web" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Total Deal" dataType="number" dataColumn="total_deal_count" sortColumn="total_deal_count" filterColumn="total_deal_count" footerCssName="text-right" />
                            <DGDGTableColumnComponent headerText="Financed Leased" dataType="number" dataColumn="financed_leased_count" sortColumn="financed_leased_count" filterColumn="financed_leased_count" footerCssName="text-right" />
                            <DGDGTableColumnComponent headerText="Captive" dataType="number" dataColumn="captive_count" sortColumn="captive_count" filterColumn="captive_count" footerCssName="text-right" />
                            <DGDGTableColumnComponent headerText="Captive % of Financed Leased" dataType="percent" dataColumn="captive_pct" sortColumn="captive_pct" filterColumn="captive_pct" footerCssName="text-right" />
                            <DGDGTableColumnComponent headerText="Financed minus Captive (Non-captive)" dataType="number" dataColumn="financed_minus_captive" sortColumn="financed_minus_captive" filterColumn="financed_minus_captive" footerCssName="text-right" />
                            <DGDGTableColumnComponent headerText="Ally" dataType="number" dataColumn="ally_count" sortColumn="ally_count" filterColumn="ally_count" footerCssName="text-right" />
                            <DGDGTableColumnComponent headerText="Ally % of Non-captive" dataType="percent" dataColumn="ally_pct" sortColumn="ally_pct" filterColumn="ally_pct" footerCssName="text-right" />
                            <DGDGTableColumnComponent headerText="TD Auto" dataType="number" dataColumn="td_auto_count" sortColumn="td_auto_count" filterColumn="td_auto_count" footerCssName="text-right" />
                            <DGDGTableColumnComponent headerText="TD Auto % of Non-captive" dataType="percent" dataColumn="td_auto_pct" sortColumn="td_auto_pct" filterColumn="td_auto_pct" footerCssName="text-right" />
                            <DGDGTableColumnComponent headerText="Chase" dataType="number" dataColumn="chase_count" sortColumn="chase_count" filterColumn="chase_count" footerCssName="text-right" />
                            <DGDGTableColumnComponent headerText="Chase % of Non-captive" dataType="percent" dataColumn="chase_pct" sortColumn="chase_pct" filterColumn="chase_pct" footerCssName="text-right" />
                            <DGDGTableColumnComponent headerText="Upstart" dataType="number" dataColumn="upstart_count" sortColumn="upstart_count" filterColumn="upstart_count" footerCssName="text-right" />
                            <DGDGTableColumnComponent headerText="Upstart % of Non-captive" dataType="percent" dataColumn="upstart_pct" sortColumn="upstart_pct" filterColumn="upstart_pct" footerCssName="text-right" />
                            <DGDGTableColumnComponent headerText="Cap One" dataType="number" dataColumn="cap_one_count" sortColumn="cap_one_count" filterColumn="cap_one_count" footerCssName="text-right" />
                            <DGDGTableColumnComponent headerText="Cap One % of Non-captive" dataType="percent" dataColumn="cap_one_pct" sortColumn="cap_one_pct" filterColumn="cap_one_pct" footerCssName="text-right" />
                            <DGDGTableColumnComponent headerText="Wells" dataType="number" dataColumn="wells_count" sortColumn="wells_count" filterColumn="wells_count" footerCssName="text-right" />
                            <DGDGTableColumnComponent headerText="Wells % of Non-captive" dataType="percent" dataColumn="wells_pct" sortColumn="wells_pct" filterColumn="wells_pct" footerCssName="text-right" />
                            <DGDGTableColumnComponent headerText="Credit Unions" dataType="number" dataColumn="credit_unions_count" sortColumn="credit_unions_count" filterColumn="credit_unions_count" footerCssName="text-right" />
                            <DGDGTableColumnComponent headerText="Credit Unions % of Non-captive" dataType="percent" dataColumn="credit_unions_pct" sortColumn="credit_unions_pct" filterColumn="credit_unions_pct" footerCssName="text-right" />
                            <DGDGTableColumnComponent headerText="BOA" dataType="number" dataColumn="boa_count" sortColumn="boa_count" filterColumn="boa_count" footerCssName="text-right" />
                            <DGDGTableColumnComponent headerText="BOA % of Non-captive" dataType="percent" dataColumn="boa_pct" sortColumn="boa_pct" filterColumn="boa_pct" footerCssName="text-right" />
                            <DGDGTableColumnComponent headerText="Unknown" dataType="number" dataColumn="unknown_count" sortColumn="unknown_count" filterColumn="unknown_count" footerCssName="text-right" />
                            <DGDGTableColumnComponent headerText="Unknown % of Non-captive" dataType="percent" dataColumn="unknown_pct" sortColumn="unknown_pct" filterColumn="unknown_pct" footerCssName="text-right" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}
