import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";
//import { DGDGEventSourceComponent } from "../js_modules/dgdgjs/DGDGEventSourceComponent";

//import { DGDGChartLineComponent } from "../common/dgdgchart/DGDGChartLineComponent";
import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGWidgetComponent } from "../common/dgdgwidget/DGDGWidgetComponent";

import { DemoChartAzureMapComponent } from "./DemoChartAzureMapComponent";
import { DemoChartDualYAxisBarComponent } from "./DemoChartDualYAxisBarComponent";
import { DemoChartLineComponent } from "./DemoChartLineComponent";
import { DemoChartStackedAreaComponent } from "./DemoChartStackedAreaComponent";
//import { DemoChartSunburstComponent } from "./DemoChartSunburstComponent";

export class DemoChartRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            demoChartItems: [],
            azureSubscriptionKey: null,
            randomNumberLineChartData: [["counter", "Line 1"], [0, 0]],
            randomNumberGraphOption: {
                hAxis: {
                    title: "Counter",
                    gridlines: {
                        color: 'transparent'
                    },
                    textStyle: {
                        fontSize: 10
                    },
                    titleTextStyle: {
                        fontSize: 12,
                        italic: false
                    }
                },
                vAxes: {
                    0: {
                        title: "Random Number",
                        textStyle: {
                            fontSize: 10
                        },
                        titleTextStyle: {
                            fontSize: 12,
                            italic: false
                        }
                    }
                },
                legend: {
                    textStyle: {
                        fontSize: 12
                    }
                }
            },
            nodeLevels: [],
            selectedNodeLevel: null,
            maximizedChart: null,
            azureGetAddressUrl: 'https://atlas.microsoft.com/search/address/json?subscription-key=',
        };

        this.onRestoreClick = this.onRestoreClick.bind(this);
        this.onMaximizeClick = this.onMaximizeClick.bind(this);
        this.onLineChartUpdatedDateESMessageCallback = this.onLineChartUpdatedDateESMessageCallback.bind(this);
        this.onSalesByVolumeAndGrossClick = this.onSalesByVolumeAndGrossClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/DemoChart", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getDevAzureSubscriptionKey();
            this.getDemoChartItems();
            //this.lineChartUpdateCounter = 1;
            //this.lastUpdatedEventSource = new DGDGEventSourceComponent();
            //this.lastUpdatedEventSource.addEventSource("/DemoChart/RandomNumberEventSource", "data", this.onLineChartUpdatedDateESMessageCallback);
            CommonService.clientAzureStorageLog("DemoChartRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

    }

    //componentWillUnmount() {
    //    try {
    //        this.lastUpdatedEventSource.closeEventSource();
    //        CommonService.clientAzureStorageLog("DemoChartRouteComponent", "componentWillUnmount", null, null, null, this.props.applicationInsights);
    //    }
    //    catch (error) {
    //        console.devError(error, null, this.props.applicationInsights);
    //    }
    //}

    pageTitle = "Demo Chart";
    //lineChartUpdateCounter;
    //lastUpdatedEventSource;

    getDemoChartItems() {

        this.props.applicationInsights.trackTrace({ message: "/DemoChartRouteComponent/getDemoChartItems", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/DemoChart/GetDemoChartItems")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {

                let orgChartDashboardItem = parsedResponse.filter((dashboardItem) => {
                    return dashboardItem.id === "OrgChart";
                });
                orgChartDashboardItem = orgChartDashboardItem.length === 1 ? orgChartDashboardItem[0] : null;

                let nodeLevels = [];
                if (orgChartDashboardItem) {
                    for (let index = 1; index <= orgChartDashboardItem.data.treeHeight; index++) {
                        nodeLevels.push("Level-" + index);
                    }
                }

                this.setState({
                    demoChartItems: parsedResponse,
                    nodeLevels: nodeLevels,
                    selectedNodeLevel: "Level-3"
                });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackTrace({ message: "/DemoChart/GetDemoChartItems", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    getDevAzureSubscriptionKey() {
        this.props.applicationInsights.trackTrace({ message: "/DemoChartRouteComponent/getDevAzureSubscriptionKey", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/DemoChart/GetDevAzureSubscriptionKey")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({ azureSubscriptionKey: parsedResponse });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackTrace({ message: "/DemoChart/GetDevAzureSubscriptionKey", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    refreshLineChart() {
        this.props.applicationInsights.trackTrace({ message: "/DemoChartRouteComponent/refreshLineChart", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/DemoChart/GetRandomNumber")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setLineChartData(parsedResponse);
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackTrace({ message: "/DemoChart/GetRandomNumber", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    setLineChartData(lineChartData) {
        let randomNumberData = this.state.randomNumberLineChartData.slice(0);
        randomNumberData.push([this.lineChartUpdateCounter++, lineChartData]);
        if (randomNumberData.length > 10) {
            randomNumberData.splice(1, 1);
        }
        this.setState({
            randomNumberLineChartData: randomNumberData
        });
    }

    onMaximizeClick(event, chartName) {
        try {
            this.setState({ maximizedChart: chartName });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onRestoreClick(event) {
        try {
            this.setState({ maximizedChart: null });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onLineChartUpdatedDateESMessageCallback(eventData) {
        try {
            if (eventData === undefined) {
                this.refreshLineChart();
            }
            else {
                this.setLineChartData(eventData);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSalesByVolumeAndGrossClick(event, clickedRow) {
        // let salesByVolumeAndGrossDashboardItem = this.state.demoChartItems.filter((dashboardItem) => {
        //     return dashboardItem.id === "SalesByVolumeAndGross";
        // });

        // salesByVolumeAndGrossDashboardItem = salesByVolumeAndGrossDashboardItem.length === 1 ? salesByVolumeAndGrossDashboardItem[0].data : null;
    }

    render() {
        let salesByVolumeDashboardItem = null,
            salesByVolumeAndGrossDashboardItem = null,
            mapChartDashboardItem = null,
            stackedAreaChartDashboardItem = null,
            //employeeSunburstChartDashboardItem = null,
            entityTypes = [],
            clusterProperties = null,
            bubbleLayerProperties = null,
            azureMapTextOptions = null,
            azureMapFilterOptions = null;

        if (this.state.demoChartItems) {
            salesByVolumeDashboardItem = this.state.demoChartItems.filter((dashboardItem) => {
                return dashboardItem.id === "SalesByVolume";
            });
            salesByVolumeDashboardItem = salesByVolumeDashboardItem.length === 1 ? salesByVolumeDashboardItem[0] : null;
            salesByVolumeAndGrossDashboardItem = this.state.demoChartItems.filter((dashboardItem) => {
                return dashboardItem.id === "SalesByVolumeAndGross";
            });
            salesByVolumeAndGrossDashboardItem = salesByVolumeAndGrossDashboardItem.length === 1 ? salesByVolumeAndGrossDashboardItem[0] : null;
            mapChartDashboardItem = this.state.demoChartItems.filter((dashboardItem) => {
                return dashboardItem.id === "MapChart";
            });
            mapChartDashboardItem = mapChartDashboardItem.length === 1 ? mapChartDashboardItem[0] : null;
            stackedAreaChartDashboardItem = this.state.demoChartItems.filter((dashboardItem) => {
                return dashboardItem.id === "StackedAreaChart";
            });
            stackedAreaChartDashboardItem = stackedAreaChartDashboardItem.length === 1 ? stackedAreaChartDashboardItem[0] : null;
            //employeeSunburstChartDashboardItem = this.state.demoChartItems.filter((dashboardItem) => {
            //    return dashboardItem.id === "EmployeeSunburstChart";
            //});
            //employeeSunburstChartDashboardItem = employeeSunburstChartDashboardItem.length === 1 ? employeeSunburstChartDashboardItem[0] : null;
            entityTypes = ['Gas Station', 'Grocery Store', 'Restaurant', 'School'];
            clusterProperties = {
                'Gas Station': ['+', ['case', ['==', ['get', 'EntityType'], 'Gas Station'], 1, 0]],
                'Grocery Store': ['+', ['case', ['==', ['get', 'EntityType'], 'Grocery Store'], 1, 0]],
                'Restaurant': ['+', ['case', ['==', ['get', 'EntityType'], 'Restaurant'], 1, 0]],
                'School': ['+', ['case', ['==', ['get', 'EntityType'], 'School'], 1, 0]]
            };
            bubbleLayerProperties = {
                radius: 20,
                color: 'purple',
                strokeWidth: 0,
                filter: ['has', 'point_count']
            };
            azureMapTextOptions = {
                textField: ['get', 'point_count_abbreviated'],
                offset: [0, 0.4],
                color: 'white'
            };
            azureMapFilterOptions = ['!', ['has', 'point_count']]
        }

        //let items = [];
        let legendData = [
            {
                color: "#bd5e75",
                text: "Driver1"
            },
            {
                color: "#006400",
                text: "Driver2"
            },
            {
                color: "#3F33BD",
                text: "Driver3"
            }
        ];

        //let azureMapClusterAggregateSampleData = "'Gas Station': ['+', ['case', ['==', ['get', 'EntityType'], 'Gas Station'], 1, 0]],'Grocery Store': ['+', ['case', ['==', ['get', 'EntityType'], 'Grocery Store'], 1, 0]],'Restaurant': ['+', ['case', ['==', ['get', 'EntityType'], 'Restaurant'], 1, 0]],'School': ['+', ['case', ['==', ['get', 'EntityType'], 'School'], 1, 0]]";

        //<DGDGWidgetComponent
        //    title="Event Source Demo"
        //    maximizedCss="dgdg-widget-maximized" restoredCss="col-4 dgdg-widget-restored"
        //    isMaximized={this.state.maximizedChart === "ChartLine" ? true : false} isVisible={this.state.maximizedChart === "ChartLine" || this.state.maximizedChart === null}
        //    onMaximizeClick={(event) => this.onMaximizeClick(event, "ChartLine")} onRestoreClick={(event) => this.onRestoreClick(event)}
        //>
        //    <DGDGChartLineComponent chartData={this.state.randomNumberLineChartData} chartOption={this.state.randomNumberGraphOption} />
        //</DGDGWidgetComponent>

        return <DGDGPageComponent>
            <div className="dgdg-site-scrollable-content">
                <div className="row" style={{ height: "100%", "margin": "-10px 0px" }}>
                    <DGDGWidgetComponent
                        title="Sales by Month - Volume & Total Gross"
                        maximizedCss="dgdg-widget-maximized" restoredCss="col-4 dgdg-widget-restored"
                        isMaximized={this.state.maximizedChart === "SalesByVolume" ? true : false} isVisible={this.state.maximizedChart === "SalesByVolume" || this.state.maximizedChart === null}
                        onMaximizeClick={(event) => this.onMaximizeClick(event, "SalesByVolume")} onRestoreClick={(event) => this.onRestoreClick(event)}
                    >
                        <DemoChartLineComponent chartData={salesByVolumeDashboardItem} />
                    </DGDGWidgetComponent>
                    <DGDGWidgetComponent
                        title="Sales by Month - Volume & Total Gross"
                        maximizedCss="dgdg-widget-maximized" restoredCss="col-4 dgdg-widget-restored"
                        isMaximized={this.state.maximizedChart === "SalesByVolumeAndGross" ? true : false} isVisible={this.state.maximizedChart === "SalesByVolumeAndGross" || this.state.maximizedChart === null}
                        onMaximizeClick={(event) => this.onMaximizeClick(event, "SalesByVolumeAndGross")} onRestoreClick={(event) => this.onRestoreClick(event)}
                    >
                        <DemoChartDualYAxisBarComponent dashboardItem={salesByVolumeAndGrossDashboardItem} isMaximized={this.state.maximizedChart === "SalesByVolumeAndGross"} onColumnClicked={this.onSalesByVolumeAndGrossClick} />
                    </DGDGWidgetComponent>
                    <DGDGWidgetComponent
                        title="Stacked Area Chart"
                        maximizedCss="dgdg-widget-maximized" restoredCss="col-4 dgdg-widget-restored"
                        isMaximized={this.state.maximizedChart === "StackedAreaChart" ? true : false} isVisible={this.state.maximizedChart === "StackedAreaChart" || this.state.maximizedChart === null}
                        onMaximizeClick={(event) => this.onMaximizeClick(event, "StackedAreaChart")} onRestoreClick={(event) => this.onRestoreClick(event)}
                    >
                        <DemoChartStackedAreaComponent dashboardItem={stackedAreaChartDashboardItem} isMaximized={this.state.maximizedChart === "StackedAreaChart"} />
                    </DGDGWidgetComponent>
                    <DGDGWidgetComponent
                        title="Azure Maps"
                        maximizedCss="dgdg-widget-maximized" restoredCss="col-4 dgdg-widget-restored"
                        isMaximized={this.state.maximizedChart === "AzureMaps" ? true : false} isVisible={this.state.maximizedChart === "AzureMaps" || this.state.maximizedChart === null}
                        onMaximizeClick={(event) => this.onMaximizeClick(event, "AzureMaps")} onRestoreClick={(event) => this.onRestoreClick(event)}
                    >
                        <DemoChartAzureMapComponent dashboardItem={mapChartDashboardItem} azureSubscriptionKey={this.state.azureSubscriptionKey} isMaximized={this.state.maximizedChart === "AzureMaps"} popuUpDisplay="onClick" chartType="basicChart" azureGetAddressUrl={this.state.azureGetAddressUrl} />
                    </DGDGWidgetComponent>
                    <DGDGWidgetComponent
                        title="Azure Maps Cluster Aggregates"
                        maximizedCss="dgdg-widget-maximized" restoredCss="col-4 dgdg-widget-restored"
                        isMaximized={this.state.maximizedChart === "AzureMapsClusteredAggregates" ? true : false} isVisible={this.state.maximizedChart === "AzureMapsClusteredAggregates" || this.state.maximizedChart === null}
                        onMaximizeClick={(event) => this.onMaximizeClick(event, "AzureMapsClusteredAggregates")} onRestoreClick={(event) => this.onRestoreClick(event)}
                    >
                        <DemoChartAzureMapComponent dashboardItem={mapChartDashboardItem} azureSubscriptionKey={this.state.azureSubscriptionKey} isMaximized={this.state.maximizedChart === "AzureMapsClusteredAggregates"} popuUpDisplay="onClick" chartType="clusterAggregatesChart" azureGetAddressUrl={this.state.azureGetAddressUrl}
                            entityTypes={entityTypes} clusterProperties={clusterProperties} bubbleLayerProperties={bubbleLayerProperties} azureMapTextOptions={azureMapTextOptions} azureMapFilterOptions={azureMapFilterOptions} />
                    </DGDGWidgetComponent>
                    <DGDGWidgetComponent
                        title="Azure Legend Maps"
                        maximizedCss="dgdg-widget-maximized" restoredCss="col-4 dgdg-widget-restored"
                        isMaximized={this.state.maximizedChart === "AzureLegendMaps" ? true : false} isVisible={this.state.maximizedChart === "AzureLegendMaps" || this.state.maximizedChart === null}
                        onMaximizeClick={(event) => this.onMaximizeClick(event, "AzureLegendMaps")} onRestoreClick={(event) => this.onRestoreClick(event)}
                    >
                        <DemoChartAzureMapComponent dashboardItem={mapChartDashboardItem} azureSubscriptionKey={this.state.azureSubscriptionKey} isMaximized={this.state.maximizedChart === "AzureLegendMaps"} popuUpDisplay="onClick" chartType="legendChart" azureGetAddressUrl={this.state.azureGetAddressUrl} legendData={legendData} />
                    </DGDGWidgetComponent>
                </div>
            </div>
        </DGDGPageComponent>;

        //<DGDGWidgetComponent
        //    title="Employee Sunburst Chart"
        //    maximizedCss="dgdg-widget-maximized" restoredCss="col-4 dgdg-widget-restored"
        //    isMaximized={this.state.maximizedChart === "EmployeeSunburstChart" ? true : false} isVisible={this.state.maximizedChart === "EmployeeSunburstChart" || this.state.maximizedChart === null}
        //    onMaximizeClick={(event) => this.onMaximizeClick(event, "EmployeeSunburstChart")} onRestoreClick={(event) => this.onRestoreClick(event)}
        //>
        //    <DemoChartSunburstComponent dashboardItem={employeeSunburstChartDashboardItem} isMaximized={this.state.maximizedChart === "EmployeeSunburstChart"} />
        //</DGDGWidgetComponent>
    }
}
