import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

export class StoreQueryComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedMonth: null
        };

        this.onRefreshClick = this.onRefreshClick.bind(this);
    }

    componentDidMount(prevProps, prevState) {
        try {
            this.props.onRefreshClick();
            CommonService.clientAzureStorageLog("StoreQueryComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onRefreshClick() {
        try {
            this.props.onRefreshClick();
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="card dgdg-card-gutter">
            <div className="card-block">
                <div className="form-inline">
                    <button disabled={this.props.isLoadingData} className="btn btn-primary dgdg-icon" onClick={(event) => this.onRefreshClick()}>
                        Refresh <i className="far fa-sync-alt dgdg-icon" />
                    </button>
                    <DGDGSpinnerComponent showSpinner={this.props.isLoadingData} />
                </div>
            </div>
        </div>;
    }
}
