import React from "react";
import { Link } from "react-router-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom"

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";

import { SellToDGDGCalculateOfferComponent } from "./SellToDGDGCalculateOfferComponent";
import { SellToDGDGViewOffersComponent } from "./SellToDGDGViewOffersComponent";
import { PageNotFoundRouteComponent } from "../pagenotfound/PageNotFoundRouteComponent";

export class SellToDGDGRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    componentDidMount() {
        try {
            CommonService.clientAzureStorageLog("SellToDGDGRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Sell To DGDG";

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-flex dgdg-flex-column">
            <BrowserRouter>
                <nav className="nav nav-pills dgdg-tab">
                    <Link to="/SellToDGDG/CalculateOffer" className={"nav-item nav-link" + (window.location.pathname.indexOf("/CalculateOffer") !== -1 ? " btn btn-dark active" : " btn btn-link")}>Calculate Offer</Link>
                    <Link to="/SellToDGDG/ViewOffers" className={"nav-item nav-link" + (window.location.pathname.indexOf("/ViewOffers") !== -1 && window.location.pathname.indexOf("/ViewOffersLegacy") === -1 ? " btn btn-dark active" : " btn btn-link")}>View Offers</Link>
                </nav>
                <div className="dgdg-flex-item dgdg-site-content">
                    <Switch>
                        <Route exact path="/SellToDGDG/CalculateOffer" render={(routeProps) => <SellToDGDGCalculateOfferComponent {...this.props} {...routeProps} onShowAlert={this.props.onShowAlert} />} />
                        <Route exact path="/SellToDGDG/ViewOffers" render={(routeProps) => <SellToDGDGViewOffersComponent {...this.props} {...routeProps} />} onShowAlert={this.props.onShowAlert} />
                        <Route render={(routeProps) => <PageNotFoundRouteComponent {...this.props} {...routeProps} />} />
                    </Switch>
                </div>
            </BrowserRouter>
        </DGDGPageComponent >;
    }
}