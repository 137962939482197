import React from "react";

import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGFormCheckboxComponent } from "../common/dgdgcheckbox/DGDGFormCheckboxComponent";

export class DirectoryEmployeeQueryComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            if (state.showSpinner) {
                newState.showSpinner = props.isLoadingData;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            showSpinner: true,
            filterValue: null,
            showErrorsOnly: false
        };

        this.onTextChange = this.onTextChange.bind(this);
        this.onLookupClick = this.onLookupClick.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this);
        this.onShowErrorsOnlyChange = this.onShowErrorsOnlyChange.bind(this);
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
            this.props.onLookupClick(value);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onLookupClick(id, value) {
        try {
            this.setState({
                filterValue: value,
                showSpinner: true
            });
            this.props.onLookupClick(value);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onShowErrorsOnlyChange(id, isChecked) {
        try {
            this.setState({ [id]: isChecked });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSearchClick(event) {
        this.onGetReportClick(this.state.showErrorsOnly);
    }

    render() {
        return <div className="card dgdg-card-gutter">
            <div className="card-block">
                <div className="form-inline">
                    <DGDGFormInputComponent id="filterValue" label="Search" value={this.state.filterValue} onChange={this.onTextChange} onLookupClick={this.onLookupClick} />
                    <DGDGFormCheckboxComponent id="showErrorsOnly" label="Show Errors Only" value={this.state.showErrorsOnly} onChange={this.onShowErrorsOnlyChange} labelCssName="dgdg-new-pdi-show-done-pdi" />
                    <button className="btn btn-primary dgdg-icon" onClick={(event) => this.onSearchClick(event)}>
                        Search<i className="far fa-search dgdg-icon" />
                    </button>
                    <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-icon" onClick={(event) => this.props.onAddClick(event)}>
                        Add <span className="fas fa-plus dgdg-icon" />
                    </button>
                    <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
                </div>
            </div>
        </div>;
    }
}
