import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormCalendarComponent } from "../common/dgdgcalendar/DGDGFormCalendarComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";

export class FIEmployeePayEditComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            if (state.showProgress) {
                newState.showProgress = !props.isSaveComplete;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            showProgress: false,
            planDate: "",
            selectedPlanName: "",
            selectedDirectorPlanName: "",
            selectedPlanName2: "",
            selectedDirectorPlanName2: "",
            wage: "",
            salary: "",
            midMonthAdvance: "",
            monthlyGuarantee: "",
            directorMonthlyGuarantee: ""
        };

        this.onDateChange = this.onDateChange.bind(this);
        this.onPlanNameChange = this.onPlanNameChange.bind(this);
        this.onDirectorPlanNameChange = this.onDirectorPlanNameChange.bind(this);
        this.onPlanName2Change = this.onPlanName2Change.bind(this);
        this.onDirectorPlanName2Change = this.onDirectorPlanName2Change.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.employee
                && (!prevProps.employee || prevProps.employee !== this.props.employee)) {
                this.setState({
                    planDate: this.props.employee.plan_date ? moment(this.props.employee.plan_date) : "",
                    selectedPlanName: this.props.employee.plan_name ? this.props.employee.plan_name : "Select Plan",
                    selectedDirectorPlanName: this.props.employee.director_plan_name ? this.props.employee.director_plan_name : "Select Plan",
                    selectedPlanName2: this.props.employee.plan_name_2 ? this.props.employee.plan_name_2 : "Select Plan 2",
                    selectedDirectorPlanName2: this.props.employee.director_plan_name_2 ? this.props.employee.director_plan_name_2 : "Select  Plan 2",
                    wage: this.props.employee.wage ? this.props.employee.wage : "",
                    salary: this.props.employee.salary ? this.props.employee.salary : "",
                    midMonthAdvance: this.props.employee.mid_month_advance ? this.props.employee.mid_month_advance : "",
                    monthlyGuarantee: this.props.employee.monthly_guarantee ? this.props.employee.monthly_guarantee : "",
                    directorMonthlyGuarantee: this.props.employee.director_monthly_guarantee ? this.props.employee.director_monthly_guarantee : ""
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "FI Payroll";
    planNames = ["Pay Plan 1", "Pay Plan 2", "Pay Plan 3", "Pay Plan 4", "Pay Plan 5", "Pay Plan 6", "Pay Plan 7", "Pay Plan 8", "Pay Plan 9", "Pay Plan 10",
        "Pay Plan 11", "Pay Plan 12", "Pay Plan 13", "Pay Plan 14", "Pay Plan 15", "Pay Plan 16", "Pay Plan 17", "Pay Plan 18", "Pay Plan 19", "Pay Plan 20"];
    directorPlanNames = ["None", "Dir Plan 1", "Dir Plan 2", "Dir Plan 3", "Dir Plan 4", "Dir Plan 5", "Dir Plan 6", "Dir Plan 7", "Dir Plan 8", "Dir Plan 9", "Dir Plan 10", "Dir Plan 11", "Dir Plan 12", "Dir Plan 13", "Dir Plan 14", "Dir Plan 15"];
    planNames2 = ["Mgr Plan 1", "Mgr Plan 2", "Mgr Plan 3", "Mgr Plan 4", "Mgr Plan 5", "Mgr Plan 6", "Mgr Plan 7", "Mgr Plan 8", "Mgr Plan 9", "Mgr Plan 10",
        "Mgr Plan 11", "Mgr Plan 12", "Mgr Plan 13", "Mgr Plan 14", "Mgr Plan 15", "Mgr Plan 16", "Mgr Plan 17", "Mgr Plan 18", "Mgr Plan 19", "Mgr Plan 20"];
    directorPlanNames2 = ["None", "Dir Plan 1", "Dir Plan 2", "Dir Plan 3", "Dir Plan 4", "Dir Plan 5", "Dir Plan 6", "Dir Plan 7", "Dir Plan 8", "Dir Plan 9", "Dir Plan 10"];

    onDateChange(id, value) {
        try {
            this.setState({ [id]: moment(value) });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPlanNameChange(id, planName) {
        try {
            this.setState({ selectedPlanName: planName });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDirectorPlanNameChange(id, directorPlanName) {
        try {
            this.setState({ selectedDirectorPlanName: directorPlanName });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPlanName2Change(id, planName2) {
        try {
            this.setState({ selectedPlanName2: planName2 });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDirectorPlanName2Change(id, directorPlanName2) {
        try {
            this.setState({ selectedDirectorPlanName2: directorPlanName2 });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event) {
        try {
            if (this.props.employee !== null && CommonService.isDateValid(this.state.planDate)) {
                this.setState({ showProgress: true });

                let inputData = {
                    employeeNumber: this.props.employee.employee_number,
                    planDate: this.state.planDate ? CommonService.formatServerDateTime(this.state.planDate) : null,
                    planName: this.state.selectedPlanName === "Select Plan" ? null : this.state.selectedPlanName,
                    directorPlanName: this.state.selectedDirectorPlanName === "Select Plan"
                        || this.state.selectedDirectorPlanName === "None" ? null : this.state.selectedDirectorPlanName,
                    planName2: this.state.selectedPlanName2 === "Select Plan 2" ? null : this.state.selectedPlanName2,
                    directorPlanName2: this.state.selectedDirectorPlanName2 === "Select Plan 2"
                        || this.state.selectedDirectorPlanName2 === "None" ? null : this.state.selectedDirectorPlanName2,
                    wage: this.state.wage,
                    salary: this.state.salary,
                    midMonthAdvance: this.state.midMonthAdvance,
                    monthlyGuarantee: this.state.monthlyGuarantee,
                    directorMonthlyGuarantee: this.state.directorMonthlyGuarantee
                };

                this.props.onSaveClick(event, inputData);
            }
        }
        catch (error) {
            this.setState({ showProgress: false });
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                planDate: "",
                selectedPlanName: "",
                selectedDirectorPlanName: "",
                selectedPlanName2: "",
                selectedDirectorPlanName2: "",
                wage: "",
                midMonthAdvance: "",
                monthlyGuarantee: "",
                directorMonthlyGuarantee: ""
            });
            this.props.onCancelClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        let title = "";
        if (this.props.employee) {
            title = "Edit Employee Pay Plan - " + this.props.employee.employee_name + " (" + this.props.employee.employee_number + ") - " + this.props.employee.store_name;
        }

        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.props.employee !== null}
            cssName="dgdg-fi-payroll-dialog"
            title={title}
            onCancelClick={this.onCancelClick}>
            <DGDGModalDialogHeaderButtonComponent>
                <button className="btn btn-primary" onClick={this.onSaveClick}>
                    Save <i className="fas fa-save dgdg-icon" />
                </button>
            </DGDGModalDialogHeaderButtonComponent>
            {
                (this.props.employee)
                    ? <div className="dgdg-report-body">
                        <div className="card">
                            <div className="card-block">
                                <div className="form-inline">
                                    <div className="form-row">
                                        <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} label="Plan Date" id="planDate" value={this.state.planDate} onDateChange={this.onDateChange} numberOfMonths={1} colSpan="col-4" labelSpan="col-5" controlSpan="col-7" />
                                        <DGDGFormDropdownComponent label="Old Mgr Plan" data={this.planNames} value={this.state.selectedPlanName} onItemClick={this.onPlanNameChange} colSpan="col-4" labelSpan="col-5" controlSpan="col-7" />
                                        <DGDGFormDropdownComponent label="Old Dir Plan" data={this.directorPlanNames} value={this.state.selectedDirectorPlanName} onItemClick={this.onDirectorPlanNameChange} colSpan="col-4" labelSpan="col-5" controlSpan="col-7" />
                                        <DGDGFormDropdownComponent label="New Mgr Plan" data={this.planNames2} value={this.state.selectedPlanName2} onItemClick={this.onPlanName2Change} colSpan="col-4" labelSpan="col-5" controlSpan="col-7" />
                                        <DGDGFormDropdownComponent label="New Dir Plan" data={this.directorPlanNames2} value={this.state.selectedDirectorPlanName2} onItemClick={this.onDirectorPlanName2Change} colSpan="col-4" labelSpan="col-5" controlSpan="col-7" />
                                        <DGDGFormInputComponent label="Wage" controlCssName="dgdg-money" id="wage" value={this.state.wage} onChange={this.onTextChange} inputType="money" colSpan="col-4" labelSpan="col-5" controlSpan="col-7" />
                                        <DGDGFormInputComponent label="Salary" controlCssName="dgdg-money" id="salary" value={this.state.salary} onChange={this.onTextChange} inputType="money" colSpan="col-4" labelSpan="col-5" controlSpan="col-7" />
                                        <DGDGFormInputComponent label="Mid Month Advance" controlCssName="dgdg-money" id="midMonthAdvance" value={this.state.midMonthAdvance} onChange={this.onTextChange} inputType="money" colSpan="col-4" labelSpan="col-5" controlSpan="col-7" />
                                        <DGDGFormInputComponent label="Monthly Guarantee" controlCssName="dgdg-money" id="monthlyGuarantee" value={this.state.monthlyGuarantee} onChange={this.onTextChange} inputType="money" colSpan="col-4" labelSpan="col-5" controlSpan="col-7" />
                                        <DGDGFormInputComponent label="Director Monthly Guarantee" controlCssName="dgdg-money" id="directorMonthlyGuarantee" value={this.state.directorMonthlyGuarantee} onChange={this.onTextChange} inputType="money" colSpan="col-4" labelSpan="col-5" controlSpan="col-7" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </DGDGModalDialogComponent>;
    }
}
