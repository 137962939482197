import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGModalConfirmationComponent } from "../common/dgdgmodalconfirmation/DGDGModalConfirmationComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { ManualCheckQueryComponent } from "./ManualCheckQueryComponent";
import { ManualCheckAddComponent } from "./ManualCheckAddComponent";

export class ManualCheckRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            paintTable: false,
            showAddDialog: false,
            deleteEmployeeCheck: null,
            stores: null,
            tableData: [],
            years: CommonService.getYears("01/01/2019"),
            selectedYear: null
        };

        this.onGetEmployeeChecksClick = this.onGetEmployeeChecksClick.bind(this);
        this.onActionDataColumnCallBack = this.onActionDataColumnCallBack.bind(this);
        this.onAddEmployeeCheckClick = this.onAddEmployeeCheckClick.bind(this);
        this.onAddEmployeeCheckSaveClick = this.onAddEmployeeCheckSaveClick.bind(this);
        this.onAddEmployeeCheckCancelClick = this.onAddEmployeeCheckCancelClick.bind(this);
        this.onDeleteEmployeeCheckClick = this.onDeleteEmployeeCheckClick.bind(this);
        this.onDeleteEmployeeCheckOkClick = this.onDeleteEmployeeCheckOkClick.bind(this);
        this.onDeleteEmployeeCheckCancelClick = this.onDeleteEmployeeCheckCancelClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/ManualCheck", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getStores();
            CommonService.clientAzureStorageLog("ManualCheckRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Manual Check";

    getStores() {
        this.props.applicationInsights.trackTrace({ message: "/ManualCheckRouteComponent/getStores", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/ManualCheck/GetStores")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({ stores: parsedResponse });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ManualCheck/getStores", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    onActionDataColumnCallBack(rowData) {
        return rowData.status === "Active" ? <span className='text-danger fas fa-times' /> : null;
    }

    onGetEmployeeChecksClick(selectedYear) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = { year: CommonService.formatServerDateTime(selectedYear) };
            this.props.applicationInsights.trackTrace({ message: "/ManualCheckRouteComponent/onGetEmployeeChecksClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ManualCheck/GetEmployeeChecks", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false,
                        selectedYear: selectedYear,
                        tableData: CommonService.addOrdinalColumn(parsedResponse.table),
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ManualCheck/GetEmployeeChecks", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAddEmployeeCheckClick(event) {
        try {
            this.setState({ showAddDialog: true });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAddEmployeeCheckSaveClick(event, employeeCheck) {
        try {
            let inputData = employeeCheck;
            this.props.applicationInsights.trackTrace({ message: "/ManualCheckRouteComponent/onAddEmployeeCheckSaveClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ManualCheck/Save", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({ isLoadingData: false });
                    this.onGetEmployeeChecksClick(this.state.selectedYear);
                    this.props.onShowAlert("success", { message: "Employee Check saved" }, 4000);
                })
                .catch(notOKResponse => {
                    this.setState({ isLoadingData: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ManualCheck/Save", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAddEmployeeCheckCancelClick(event) {
        try {
            this.setState({ showAddDialog: false });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDeleteEmployeeCheckClick(event, rowData) {
        try {
            this.setState({ deleteEmployeeCheck: rowData });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDeleteEmployeeCheckOkClick(event) {
        try {
            let inputData = this.state.deleteEmployeeCheck;
            this.props.applicationInsights.trackTrace({ message: "/ManualCheckRouteComponent/onDeleteEmployeeCheckOkClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ManualCheck/Delete", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({ isLoadingData: false });
                    this.onGetEmployeeChecksClick(this.state.selectedYear);
                    this.onDeleteEmployeeCheckCancelClick(event);
                })
                .catch(notOKResponse => {
                    this.setState({ isLoadingData: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ManualCheck/Delete", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDeleteEmployeeCheckCancelClick(event) {
        try {
            this.setState({ deleteEmployeeCheck: null });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("ManualCheckRouteComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent>
            <ManualCheckAddComponent
                applicationInsights={this.props.applicationInsights}
                showDialog={this.state.showAddDialog}
                stores={this.state.stores}
                onSaveClick={this.onAddEmployeeCheckSaveClick}
                onCancelClick={this.onAddEmployeeCheckCancelClick}
                onShowAlert={this.props.onShowAlert}
            />
            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText={"Delete Check #: " + (this.state.deleteEmployeeCheck !== null ? this.state.deleteEmployeeCheck.check_number : "")}
                bodyText="Are you sure?"
                showConfirmation={this.state.deleteEmployeeCheck !== null}
                onOkClick={this.onDeleteEmployeeCheckOkClick}
                onCancelClick={this.onDeleteEmployeeCheckCancelClick}
            />
            <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
                <ManualCheckQueryComponent
                    applicationInsights={this.props.applicationInsights}
                    isLoadingData={this.state.isLoadingData}
                    onGetClick={this.onGetEmployeeChecksClick}
                    onAddClick={this.onAddEmployeeCheckClick}
                    years={this.state.years}
                />
                <div className="dgdg-site-scrollable-content">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText="Employee Checks" tableData={this.state.tableData} paintTable={this.state.paintTable}
                        copyTableConfig={{ "columns": [0, 2, 3, 4, 5, 6, 7, 8, 9, 10], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                            <DGDGTableColumnComponent headerText="Action" cssName="text-center" dataColumnCallback={this.onActionDataColumnCallBack} onCellClick={this.onDeleteEmployeeCheckClick} />
                            <DGDGTableColumnComponent headerText="Created Date" dataColumn="created_date" sortColumn="created_date" filterColumn="created_date" dataType="date" />
                            <DGDGTableColumnComponent headerText="Check Date" dataColumn="check_date" sortColumn="check_date" filterColumn="check_date" dataType="date" />
                            <DGDGTableColumnComponent headerText="Store Number" dataColumn="store_number" sortColumn="store_number" filterColumn="store_number" />
                            <DGDGTableColumnComponent headerText="Employee Number" dataColumn="employee_number" sortColumn="employee_number" filterColumn="employee_number" />
                            <DGDGTableColumnComponent headerText="Employee Name" dataColumn="employee_name" sortColumn="employee_name" filterColumn="employee_name" />
                            <DGDGTableColumnComponent headerText="Check Number" dataColumn="check_number" sortColumn="check_number" filterColumn="check_number" />
                            <DGDGTableColumnComponent headerText="Check Amount" dataColumn="check_amount" sortColumn="check_amount" filterColumn="check_amount" dataType="money_2" />
                            <DGDGTableColumnComponent headerText="Status" dataColumn="status" sortColumn="status" filterColumn="status" />
                            <DGDGTableColumnComponent headerText="Last Updated By" dataColumn="last_updated_by" sortColumn="last_updated_by" filterColumn="last_updated_by" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}
