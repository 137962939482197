import React from "react";
import moment from "moment";
import $ from "jquery";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { FrontEndAdjustmentReportQueryComponent } from "./FrontEndAdjustmentReportQueryComponent";

export class FrontEndAdjustmentReportComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            paintTable: false,
            tableData: [],
            tableTitle: "",

            detailTableData: [],
            detailTableTitle: "",
            detailPaintTable: false,

            months: CommonService.getMonths(),
            selectedMonth: null,

            newAccountDetailTableData: [],
            usedAccountDetailTableData: [],
            wholesaleAccountDetailTableData: [],

            newAccountDetailTableTitle: "",
            usedAccountDetailTableTitle: "",
            wholesaleAccountDetailTableTitle: "",

            newAccountSummaryTableData: [],
            usedAccountSummaryTableData: [],
            wholesaleAccountSummaryTableData: [],
            allAccountSummaryTableData: [],

            newAccountSummaryTableTitle: "",
            usedAccountSummaryTableTitle: "",
            wholesaleAccountSummaryTableTitle: "",
            allAccountSummaryTableTitle: "",
        };

        this.onGetDetailsClick = this.onGetDetailsClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onPrintClick = this.onPrintClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    componentWillUnmount() {
        this.props.onLastUpdatedDateChanged("");
    }

    pageTitle = "Front-end Adjustments";

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/FrontEndAdjustments/AdjustmentReport", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("FrontEndAdjustmentReportComponent", "FrontEndAdjustmentComponent", "componentDidMount", null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetLastUpdatedDate(selectedMonth) {
        try {
            let inputData = { month: CommonService.formatServerDateTime(selectedMonth) };
            this.props.applicationInsights.trackTrace({ message: "/FrontEndAdjustmentReportComponent/onGetLastUpdatedDate", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FrontEndAdjustments/GetLastUpdated", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (CommonService.isDateValid(parsedResponse)) {
                        this.props.onLastUpdatedDateChanged(moment.utc(parsedResponse));
                    }
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FrontEndAdjustments/GetLastUpdated", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetDetailsClick(selectedMonth, selectedStore) {
        try {
            this.setState({
                isLoadingData: true,
                selectedMonth: selectedMonth
            });

            let inputData = {
                startDate: CommonService.formatServerDateTime(selectedMonth),
                storeNumber: selectedStore.number
            };
            this.onGetLastUpdatedDate(selectedMonth);

            this.props.applicationInsights.trackTrace({ message: "/FrontEndAdjustmentReportComponent/onGetDetailsClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FrontEndAdjustments/GetFrontEndAdjustmentReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let totalNewAccountSummary = 0, totalUsedAccountSummary = 0, totalOtherAccountSummary = 0, totalAdjustmentAmount = 0;

                    let newAccountDetailTableData = parsedResponse.table.filter(item => item.new_used_wholesale === "New");
                    let usedAccountDetailTableData = parsedResponse.table.filter(item => item.new_used_wholesale === "Used");
                    let wholesaleAccountDetailTableData = parsedResponse.table.filter(item => item.new_used_wholesale === "Wholesale");

                    let newAccountSummaryTableData = parsedResponse.table1.filter(item => item.new_used_wholesale === "New");
                    newAccountSummaryTableData.forEach((newAccountSummaryItem, index) => {
                        totalNewAccountSummary += newAccountSummaryItem.amount;
                    });

                    let usedAccountSummaryTableData = parsedResponse.table1.filter(item => item.new_used_wholesale === "Used");
                    usedAccountSummaryTableData.forEach((usedAccountSummaryItem, index) => {
                        totalUsedAccountSummary += usedAccountSummaryItem.amount;
                    });

                    let wholesaleAccountSummaryTableData = parsedResponse.table1.filter(item => item.new_used_wholesale === "Wholesale");
                    parsedResponse.table1.filter(item => item.new_used_wholesale !== "New" && item.new_used_wholesale !== "Used").forEach((otherAccountSummaryItem, index) => {
                        totalOtherAccountSummary += otherAccountSummaryItem.amount;
                    });

                    parsedResponse.table.forEach((responseItem, index) => {
                        totalAdjustmentAmount += responseItem.service_cb + responseItem.maintenance_cb + responseItem.gap_cb + responseItem.other_cb + responseItem.service_adj
                            + responseItem.maintenance_adj + responseItem.gap_adj + responseItem.other_adj + responseItem.other;
                    });

                    let allAccountSummaryTableData = [];
                    allAccountSummaryTableData.push({ display_name: "New", amount: totalNewAccountSummary });
                    allAccountSummaryTableData.push({ display_name: "Used", amount: totalUsedAccountSummary });
                    allAccountSummaryTableData.push({ display_name: "Wholesale", amount: totalOtherAccountSummary });

                    this.setState({
                        isLoadingData: false,
                        paintTable: true,
                        tableData: CommonService.addOrdinalColumn(parsedResponse.table),
                        totalAdjustmentAmount: totalAdjustmentAmount,
                        newAccountDetailTableData: CommonService.addOrdinalColumn(newAccountDetailTableData),
                        usedAccountDetailTableData: CommonService.addOrdinalColumn(usedAccountDetailTableData),
                        wholesaleAccountDetailTableData: CommonService.addOrdinalColumn(wholesaleAccountDetailTableData),
                        newAccountDetailTableTitle: "New Detail - " + selectedStore.display_name + " - " + CommonService.formatMonthYear(new Date(selectedMonth)),
                        usedAccountDetailTableTitle: "Used Detail - " + selectedStore.display_name + " - " + CommonService.formatMonthYear(new Date(selectedMonth)),
                        wholesaleAccountDetailTableTitle: "Wholesale Detail - " + selectedStore.display_name + " - " + CommonService.formatMonthYear(new Date(selectedMonth)),
                        newAccountSummaryTableData: CommonService.addOrdinalColumn(newAccountSummaryTableData),
                        usedAccountSummaryTableData: CommonService.addOrdinalColumn(usedAccountSummaryTableData),
                        wholesaleAccountSummaryTableData: CommonService.addOrdinalColumn(wholesaleAccountSummaryTableData),
                        allAccountSummaryTableData: CommonService.addOrdinalColumn(allAccountSummaryTableData),
                        tableTitle: "Front-end Adjustment Detail - " + selectedStore.display_name + " - " + CommonService.formatMonthYear(new Date(selectedMonth)),
                        newAccountSummaryTableTitle: "New Account Summary - " + selectedStore.display_name + " - " + CommonService.formatMonthYear(new Date(selectedMonth)),
                        usedAccountSummaryTableTitle: "Used Account Summary - " + selectedStore.display_name + " - " + CommonService.formatMonthYear(new Date(selectedMonth)),
                        wholesaleAccountSummaryTableTitle: "Wholesale Account Summary - " + selectedStore.display_name + " - " + CommonService.formatMonthYear(new Date(selectedMonth)),
                        allAccountSummaryTableTitle: "Account Summary - " + CommonService.formatMonthYear(new Date(selectedMonth))
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        paintTable: true,
                        tableData: [],
                        newAccountDetailTableData: [],
                        usedAccountDetailTableData: [],
                        wholesaleAccountDetailTableData: [],
                        newAccountDetailTableTitle: "",
                        usedAccountDetailTableTitle: "",
                        wholesaleAccountDetailTableTitle: "",
                        newAccountSummaryTableData: [],
                        usedAccountSummaryTableData: [],
                        wholesaleAccountSummaryTableData: [],
                        allAccountSummaryTableData: [],
                        tableTitle: "",
                        newAccountSummaryTableTitle: "",
                        usedAccountSummaryTableTitle: "",
                        wholesaleAccountSummaryTableTitle: "",
                        allAccountSummaryTableTitle: ""
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FrontEndAdjustments/GetFrontEndAdjustmentReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPrintClick(event) {
        try {
            let inputData = {
                tabName: "FrontEndAdjustmentReport"
            };
            this.props.applicationInsights.trackTrace({ message: "/FrontEndAdjustmentReportComponent/onPrintClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FrontEndAdjustments/PrintClick", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let printContent = '<div class="table-title">' + this.state.newAccountDetailTableTitle + '</div>'
                        + '<table class="table table-bordered">' + $(".dgdg-front-end-print-content-new-detail .dgdg-main-table").html() + '</table>'
                        + '<div class="dgdg-page-break" /><div class="table-title">' + this.state.usedAccountDetailTableTitle + '</div>'
                        + '<table class="table table-bordered">' + $(".dgdg-front-end-print-content-used-detail  .dgdg-main-table").html() + '</table>'
                        + '<div class="dgdg-page-break" /><div class="table-title">' + this.state.wholesaleAccountDetailTableTitle + '</div>'
                        + '<table class="table table-bordered">' + $(".dgdg-front-end-print-content-wholesale-detail .dgdg-main-table").html() + '</table>'
                        + '<div class="dgdg-page-break" /><div class="table-title">' + this.state.newAccountSummaryTableTitle + '</div>'
                        + '<table class="table table-bordered">' + $(".dgdg-front-end-print-content-new-summary  .dgdg-main-table").html() + '</table>'
                        + '<div class="dgdg-page-break" /><div class="table-title">' + this.state.usedAccountSummaryTableTitle + '</div>'
                        + '<table class="table table-bordered">' + $(".dgdg-front-end-print-content-used-summary  .dgdg-main-table").html() + '</table>'
                        + '<div class="dgdg-page-break" /><div class="table-title">' + this.state.wholesaleAccountSummaryTableTitle + '</div>'
                        + '<table class="table table-bordered">' + $(".dgdg-front-end-print-content-wholesale-summary  .dgdg-main-table").html() + '</table>'
                        + '<div class="dgdg-page-break" /><div class="table-title">' + this.state.allAccountSummaryTableTitle + '</div>'
                        + '<table class="table table-bordered">' + $(".dgdg-front-end-print-content-all-summary  .dgdg-main-table").html() + '</table>';
                    CommonService.showPrint(this.state.tableTitle, printContent);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FrontEndAdjustments/PrintClick", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("FrontEndAdjustmentReportComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <FrontEndAdjustmentReportQueryComponent
                applicationInsights={this.props.applicationInsights}
                isLoadingData={this.state.isLoadingData}
                stores={this.props.stores} months={this.props.months}
                onGetDetailsClick={this.onGetDetailsClick} onPrintClick={this.onPrintClick}
            />
            <div className="dgdg-site-scrollable-content">
                <div className="dgdg-front-end-print-content dgdg-card-gutter">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable}
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} rowSpan="2" />
                            <DGDGTableColumnComponent headerText="Deal Date" dataColumn="deal_date" sortColumn="deal_date" filterColumn="deal_date" dataType="date" rowSpan="2" />
                            <DGDGTableColumnComponent headerText="Customer Number" dataColumn="customer_number" sortColumn="customer_number" filterColumn="customer_number" rowSpan="2" />
                            <DGDGTableColumnComponent headerText="Customer Name" dataColumn="customer_name" sortColumn="customer_name" filterColumn="customer_name" rowSpan="2" />
                            <DGDGTableColumnComponent headerText="Stock Number" dataColumn="stock_number" sortColumn="stock_number" filterColumn="stock_number" rowSpan="2" />
                            <DGDGTableColumnComponent headerText="New/Used/Wholesale" dataColumn="new_used_wholesale" sortColumn="new_used_wholesale" filterColumn="new_used_wholesale" />
                            <DGDGTableColumnComponent headerText="Account Number" dataColumn="account_number" sortColumn="account_number" filterColumn="account_number" />
                            <DGDGTableColumnComponent headerText="Amount" dataColumn="amount" sortColumn="amount" filterColumn="amount" dataType="money_2" />
                            <DGDGTableColumnComponent headerText="Adjust Type" dataColumn="type" sortColumn="type" filterColumn="type" />
                            <DGDGTableColumnComponent headerText="Last Updated By" dataColumn="last_updated_by" sortColumn="last_updated_by" filterColumn="last_updated_by" />
                        </DGDGTableRowComponent>
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="Reason" dataColumn="reason" sortColumn="reason" filterColumn="reason" showHeader />
                            <DGDGTableColumnComponent headerText="Comments" dataColumn="comments" sortColumn="comments" filterColumn="comments" colSpan="3" showHeader />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
                <div className="dgdg-flex">
                    <div className="dgdg-front-end-print-content-new-detail" style={{ "display": "none" }}>
                        <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.newAccountDetailTableTitle} tableData={this.state.newAccountDetailTableData} paintTable={this.state.paintTable}
                        >
                            <DGDGTableRowComponent>
                                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} rowSpan="2" />
                                <DGDGTableColumnComponent headerText="Deal Date" dataColumn="deal_date" sortColumn="deal_date" filterColumn="deal_date" dataType="date" rowSpan="2" />
                                <DGDGTableColumnComponent headerText="Customer Number" dataColumn="customer_number" sortColumn="customer_number" filterColumn="customer_number" rowSpan="2" />
                                <DGDGTableColumnComponent headerText="Customer Name" dataColumn="customer_name" sortColumn="customer_name" filterColumn="customer_name" rowSpan="2" />
                                <DGDGTableColumnComponent headerText="Stock Number" dataColumn="stock_number" sortColumn="stock_number" filterColumn="stock_number" rowSpan="2" />
                                <DGDGTableColumnComponent headerText="New/Used/Wholesale" dataColumn="new_used_wholesale" sortColumn="new_used_wholesale" filterColumn="new_used_wholesale" />
                                <DGDGTableColumnComponent headerText="Account Number" dataColumn="account_number" sortColumn="account_number" filterColumn="account_number" />
                                <DGDGTableColumnComponent headerText="Amount" dataColumn="amount" sortColumn="amount" filterColumn="amount" dataType="money_2" />
                                <DGDGTableColumnComponent headerText="Adjust Type" dataColumn="type" sortColumn="type" filterColumn="type" />
                                <DGDGTableColumnComponent headerText="Last Updated By" dataColumn="last_updated_by" sortColumn="last_updated_by" filterColumn="last_updated_by" />
                            </DGDGTableRowComponent>
                            <DGDGTableRowComponent>
                                <DGDGTableColumnComponent headerText="Reason" dataColumn="reason" sortColumn="reason" filterColumn="reason" showHeader />
                                <DGDGTableColumnComponent headerText="Comments" dataColumn="comments" sortColumn="comments" filterColumn="comments" colSpan="3" showHeader />
                            </DGDGTableRowComponent>
                        </DGDGTableV3Component>
                    </div>
                    <div className="dgdg-front-end-print-content-used-detail" style={{ "display": "none" }}>
                        <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.usedAccountDetailTableTitle} tableData={this.state.usedAccountDetailTableData} paintTable={this.state.paintTable}
                        >
                            <DGDGTableRowComponent>
                                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} rowSpan="2" />
                                <DGDGTableColumnComponent headerText="Deal Date" dataColumn="deal_date" sortColumn="deal_date" filterColumn="deal_date" dataType="date" rowSpan="2" />
                                <DGDGTableColumnComponent headerText="Customer Number" dataColumn="customer_number" sortColumn="customer_number" filterColumn="customer_number" rowSpan="2" />
                                <DGDGTableColumnComponent headerText="Customer Name" dataColumn="customer_name" sortColumn="customer_name" filterColumn="customer_name" rowSpan="2" />
                                <DGDGTableColumnComponent headerText="Stock Number" dataColumn="stock_number" sortColumn="stock_number" filterColumn="stock_number" rowSpan="2" />
                                <DGDGTableColumnComponent headerText="New/Used/Wholesale" dataColumn="new_used_wholesale" sortColumn="new_used_wholesale" filterColumn="new_used_wholesale" />
                                <DGDGTableColumnComponent headerText="Account Number" dataColumn="account_number" sortColumn="account_number" filterColumn="account_number" />
                                <DGDGTableColumnComponent headerText="Amount" dataColumn="amount" sortColumn="amount" filterColumn="amount" dataType="money_2" />
                                <DGDGTableColumnComponent headerText="Adjust Type" dataColumn="type" sortColumn="type" filterColumn="type" />
                                <DGDGTableColumnComponent headerText="Last Updated By" dataColumn="last_updated_by" sortColumn="last_updated_by" filterColumn="last_updated_by" />
                            </DGDGTableRowComponent>
                            <DGDGTableRowComponent>
                                <DGDGTableColumnComponent headerText="Reason" dataColumn="reason" sortColumn="reason" filterColumn="reason" showHeader />
                                <DGDGTableColumnComponent headerText="Comments" dataColumn="comments" sortColumn="comments" filterColumn="comments" colSpan="3" showHeader />
                            </DGDGTableRowComponent>
                        </DGDGTableV3Component>
                    </div>
                    <div className="dgdg-front-end-print-content-wholesale-detail" style={{ "display": "none" }}>
                        <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.wholesaleAccountDetailTableTitle} tableData={this.state.wholesaleAccountDetailTableData} paintTable={this.state.paintTable}
                        >
                            <DGDGTableRowComponent>
                                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} rowSpan="2" />
                                <DGDGTableColumnComponent headerText="Deal Date" dataColumn="deal_date" sortColumn="deal_date" filterColumn="deal_date" dataType="date" rowSpan="2" />
                                <DGDGTableColumnComponent headerText="Customer Number" dataColumn="customer_number" sortColumn="customer_number" filterColumn="customer_number" rowSpan="2" />
                                <DGDGTableColumnComponent headerText="Customer Name" dataColumn="customer_name" sortColumn="customer_name" filterColumn="customer_name" rowSpan="2" />
                                <DGDGTableColumnComponent headerText="Stock Number" dataColumn="stock_number" sortColumn="stock_number" filterColumn="stock_number" rowSpan="2" />
                                <DGDGTableColumnComponent headerText="New/Used/Wholesale" dataColumn="new_used_wholesale" sortColumn="new_used_wholesale" filterColumn="new_used_wholesale" />
                                <DGDGTableColumnComponent headerText="Account Number" dataColumn="account_number" sortColumn="account_number" filterColumn="account_number" />
                                <DGDGTableColumnComponent headerText="Amount" dataColumn="amount" sortColumn="amount" filterColumn="amount" dataType="money_2" />
                                <DGDGTableColumnComponent headerText="Adjust Type" dataColumn="type" sortColumn="type" filterColumn="type" />
                                <DGDGTableColumnComponent headerText="Last Updated By" dataColumn="last_updated_by" sortColumn="last_updated_by" filterColumn="last_updated_by" />
                            </DGDGTableRowComponent>
                            <DGDGTableRowComponent>
                                <DGDGTableColumnComponent headerText="Reason" dataColumn="reason" sortColumn="reason" filterColumn="reason" showHeader />
                                <DGDGTableColumnComponent headerText="Comments" dataColumn="comments" sortColumn="comments" filterColumn="comments" colSpan="3" showHeader />
                            </DGDGTableRowComponent>
                        </DGDGTableV3Component>
                    </div>
                    <div className="dgdg-front-end-print-content-new-summary  dgdg-card-gutter">
                        <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.newAccountSummaryTableTitle} tableData={this.state.newAccountSummaryTableData} paintTable={this.state.paintTable} showFooter
                            copyTableConfig={{ "columns": [0, 1, 2], onCopyTacomments_2bleCallBack: this.onCopyTableCallBack }}
                        >
                            <DGDGTableRowComponent>
                                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                <DGDGTableColumnComponent headerText="Account Description" dataColumn="display_name" sortColumn="display_name" filterColumn="display_name" footerText="Total" />
                                <DGDGTableColumnComponent headerText="Amount" dataColumn="amount" sortColumn="amount" filterColumn="amount" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            </DGDGTableRowComponent>
                        </DGDGTableV3Component>
                    </div>
                    <div className="dgdg-front-end-print-content-used-summary dgdg-icon">
                        <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.usedAccountSummaryTableTitle} tableData={this.state.usedAccountSummaryTableData} paintTable={this.state.paintTable} showFooter
                            copyTableConfig={{ "columns": [0, 1, 2], onCopyTableCallBack: this.onCopyTableCallBack }}
                        >
                            <DGDGTableRowComponent>
                                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                <DGDGTableColumnComponent headerText="Account Description" dataColumn="display_name" sortColumn="display_name" filterColumn="display_name" footerText="Total" />
                                <DGDGTableColumnComponent headerText="Amount" dataColumn="amount" sortColumn="amount" filterColumn="amount" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            </DGDGTableRowComponent>
                        </DGDGTableV3Component>
                    </div>
                    <div className="dgdg-front-end-print-content-wholesale-summary dgdg-icon">
                        <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.wholesaleAccountSummaryTableTitle} tableData={this.state.wholesaleAccountSummaryTableData} paintTable={this.state.paintTable} showFooter
                            copyTableConfig={{ "columns": [0, 1, 2], onCopyTableCallBack: this.onCopyTableCallBack }}
                        >
                            <DGDGTableRowComponent>
                                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                <DGDGTableColumnComponent headerText="Account Description" dataColumn="display_name" sortColumn="display_name" filterColumn="display_name" footerText="Total" />
                                <DGDGTableColumnComponent headerText="Amount" dataColumn="amount" sortColumn="amount" filterColumn="amount" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            </DGDGTableRowComponent>
                        </DGDGTableV3Component>
                    </div>
                </div>
                <div className="dgdg-flex">
                    <div className="dgdg-front-end-print-content-all-summary">
                        <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.allAccountSummaryTableTitle} tableData={this.state.allAccountSummaryTableData} paintTable={this.state.paintTable} showFooter
                            copyTableConfig={{ "columns": [0, 1, 2], onCopyTableCallBack: this.onCopyTableCallBack }}
                        >
                            <DGDGTableRowComponent>
                                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                <DGDGTableColumnComponent headerText="Description" dataColumn="display_name" sortColumn="display_name" filterColumn="display_name" footerText="Grand Total" />
                                <DGDGTableColumnComponent headerText="Amount" dataColumn="amount" sortColumn="amount" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            </DGDGTableRowComponent>
                        </DGDGTableV3Component>
                    </div>
                </div>
            </div>
        </div>
    }
}
