import React from "react";
import moment from "moment";
import $ from "jquery";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";

import { DemoPageView2QueryComponent } from "./DemoPageView2QueryComponent";

export class DemoPageView2Component extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            paintTable: false,
            isLoadingData: false
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onPrintClick = this.onPrintClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/DemoPage/View2", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getData();
            CommonService.clientAzureStorageLog("DemoPageRouteComponent", "DemoPageView2Component", "componentDidMount", null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.state.paintTable) {
                this.setState({ paintTable: false });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Demo Page";

    getData() {
        this.setState({
            isLoadingData: true,
            paintTable: true
        });

        // Get data asynchronously
        let tableData = [];
        for (let index = 0; index < 100; index++) {
            let dataRow = {
                number_col: index,
                formatted_number_2_col: index,
                date_col: moment(),
                date_time_col: moment(),
                short_month_col: index % 12,
                time_col: moment(),
                bool_col: index % 2
            };

            tableData.push(dataRow);
        }

        // Set the state in done
        tableData = CommonService.addOrdinalColumn(tableData);

        this.setState({
            isLoadingData: false,
            paintTable: true,
            tableData: tableData
        });
    }

    onGetReportClick(event, queryObject) {
        try {
            this.getData();
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPrintClick(event) {

        try {
            this.props.applicationInsights.trackTrace({ message: "/DemoPageView2Component/onPrintClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/DemoPage/PrintClick")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let printContent = '<table class="table">' + $(".printcontent .dgdg-main-table").html() + '</table>';
                    CommonService.showPrint('Table Title - 2', printContent);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/DemoPage/PrintClick", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <DemoPageView2QueryComponent applicationInsights={this.props.applicationInsights} isLoadingData={this.state.isLoadingData} onGetReportClick={this.onGetReportClick} onPrintClick={this.onPrintClick} onShowAlert={this.props.onShowAlert} />
            <div className="dgdg-site-scrollable-content printcontent">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText="Table Title - 2" tableData={this.state.tableData} paintTable={this.state.paintTable}>
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="Number" dataColumn="number_col" sortColumn="number_col" filterColumn="number_col" dataType="number" footerText="Total" />
                        <DGDGTableColumnComponent headerText="Number 2" dataColumn="formatted_number_2_col" sortColumn="formatted_number_2_col" filterColumn="formatted_number_2_col" dataType="formatted_number_2" />
                        <DGDGTableColumnComponent headerText="Date" dataColumn="date_col" sortColumn="date_col" dataType="date" columnBand1="dgdg-column-band-1" columnBand2="dgdg-column-band-2" />
                        <DGDGTableColumnComponent headerText="Date Time" dataColumn="date_time_col" sortColumn="date_time_col" dataType="dateTime" headerCssName="dgdg-border-right" footerCssName="dgdg-border-right" cssName="dgdg-border-right" />
                        <DGDGTableColumnComponent headerText="Short Month" dataColumn="short_month_col" sortColumn="short_month_col" filterColumn="short_month_col" dataType="shortMonth" />
                        <DGDGTableColumnComponent headerText="Time" dataColumn="time_col" sortColumn="time_col" filterColumn="time_col" dataType="time" color="percent_col_background_color" cssName="text-nowrap" />
                        <DGDGTableColumnComponent headerText="Boolean" dataColumn="bool_col" sortColumn="bool_col" filterColumn="bool_col" dataType="bool" backgroundColor="percent_col_background_color" />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
        </div>;
    }
}
