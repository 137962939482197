import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";
import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGFormCheckboxComponent } from "../common/dgdgcheckbox/DGDGFormCheckboxComponent";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";

export class SalesPayrollDetailEditComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            csi: "",
            subaru_integrity_score: "",
            pay_1_payroll_bonus: "",
            pay_1_guaranteed_work_days: "",
            pay_1_sick_labor_rate: "",
            pay_1_pto_labor_rate: "",
            pay_1_bereavement_labor_rate: "",
            pay_1_holiday_labor_rate: "",
            pay_1_non_commission_flat_sum_bonus_spiff: "",
            pay_1_mealtime_premium_hours: "",
            pay_1_comments: "",
            pay_2_payroll_bonus: "",
            pay_2_guaranteed_work_days: "",
            pay_2_sick_labor_rate: "",
            pay_2_pto_labor_rate: "",
            pay_2_bereavement_labor_rate: "",
            pay_2_holiday_labor_rate: "",
            pay_2_non_commission_flat_sum_bonus_spiff: "",
            pay_2_mealtime_premium_hours: "",
            pay_2_comments: ""
        };

        this.onTextChange = this.onTextChange.bind(this);
        this.onCheckChanged = this.onCheckChanged.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.payrollDetail
                && (!prevProps.payrollDetail || prevProps.payrollDetail !== this.props.payrollDetail)) {
                this.setState({
                    csi: this.props.payrollDetail.csi,
                    subaru_integrity_score: this.props.payrollDetail.subaru_integrity_score,
                    pay_1_payroll_bonus: this.props.payrollDetail.pay_1_payroll_bonus !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_payroll_bonus) : "",
                    pay_1_guaranteed_work_days: this.props.payrollDetail.pay_1_guaranteed_work_days,
                    pay_1_sick_labor_rate: this.props.payrollDetail.pay_1_sick_labor_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_sick_labor_rate) : "",
                    pay_1_pto_labor_rate: this.props.payrollDetail.pay_1_pto_labor_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_pto_labor_rate) : "",
                    pay_1_bereavement_labor_rate: this.props.payrollDetail.pay_1_bereavement_labor_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_bereavement_labor_rate) : "",
                    pay_1_holiday_labor_rate: this.props.payrollDetail.pay_1_holiday_labor_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_holiday_labor_rate) : "",
                    pay_1_non_commission_flat_sum_bonus_spiff: this.props.payrollDetail.pay_1_non_commission_flat_sum_bonus_spiff !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_non_commission_flat_sum_bonus_spiff) : "",
                    pay_1_mealtime_premium_hours: this.props.payrollDetail.pay_1_mealtime_premium_hours !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_1_mealtime_premium_hours) : "",
                    pay_1_comments: this.props.payrollDetail.pay_1_comments,
                    pay_2_payroll_bonus: this.props.payrollDetail.pay_2_payroll_bonus !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_payroll_bonus) : "",
                    pay_2_guaranteed_work_days: this.props.payrollDetail.pay_2_guaranteed_work_days,
                    pay_2_sick_labor_rate: this.props.payrollDetail.pay_2_sick_labor_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_sick_labor_rate) : "",
                    pay_2_pto_labor_rate: this.props.payrollDetail.pay_2_pto_labor_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_pto_labor_rate) : "",
                    pay_2_bereavement_labor_rate: this.props.payrollDetail.pay_2_bereavement_labor_rate !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_bereavement_labor_rate) : "",
                    pay_2_holiday_labor_rate: this.props.payrollDetail.pay_2_holiday_labor_rate !== null ? CommonService.format_2(this.props.payrollDetail.pay_2_holiday_labor_rate) : "",
                    pay_2_non_commission_flat_sum_bonus_spiff: this.props.payrollDetail.pay_2_non_commission_flat_sum_bonus_spiff !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_non_commission_flat_sum_bonus_spiff) : "",
                    pay_2_mealtime_premium_hours: this.props.payrollDetail.pay_2_mealtime_premium_hours !== null ? CommonService.formatNumber_2(this.props.payrollDetail.pay_2_mealtime_premium_hours) : "",
                    pay_2_comments: this.props.payrollDetail.pay_2_comments
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Sales Payroll";

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCheckChanged(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event) {
        try {
            this.setState({ showProgress: true });
            let inputData = {
                payrollMonth: CommonService.formatServerDateTime(this.props.payrollMonth),
                storeNumber: this.props.payrollDetail.store_number,
                employeeNumber: this.props.payrollDetail.employee_number,
                csi: this.state.csi,
                subaruIntegrityScore: this.state.subaru_integrity_score,

                pay1PayrollBonus: this.state.pay_1_payroll_bonus,
                pay1GuaranteedWorkDays: this.state.pay_1_guaranteed_work_days,
                pay1SickLaborRate: this.state.pay_1_sick_labor_rate,
                pay1PtoLaborRate: this.state.pay_1_pto_labor_rate,
                pay1BereavementLaborRate: this.state.pay_1_bereavement_labor_rate,
                pay1HolidayLaborRate: this.state.pay_1_holiday_labor_rate,
                pay1NonCommissionFlatSumBonusSpiff: this.state.pay_1_non_commission_flat_sum_bonus_spiff,
                pay1MealtimePremiumHours: this.state.pay_1_mealtime_premium_hours,
                pay1Comments: this.state.pay_1_comments,

                pay2PayrollBonus: this.state.pay_2_payroll_bonus,
                pay2GuaranteedWorkDays: this.state.pay_2_guaranteed_work_days,
                pay2SickLaborRate: this.state.pay_2_sick_labor_rate,
                pay2PtoLaborRate: this.state.pay_2_pto_labor_rate,
                pay2BereavementLaborRate: this.state.pay_2_bereavement_labor_rate,
                pay2HolidayLaborRate: this.state.pay_2_holiday_labor_rate,
                pay2NonCommissionFlatSumBonusSpiff: this.state.pay_2_non_commission_flat_sum_bonus_spiff,
                pay2MealtimePremiumHours: this.state.pay_2_mealtime_premium_hours,
                pay2Comments: this.state.pay_2_comments
            };

            this.props.applicationInsights.trackTrace({ message: "/SalesPayrollDetailEditComponent/onSaveClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SalesPayroll/UpdateSalesPayroll", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.props.onSaveClick(event);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SalesPayroll/UpdateSalesPayroll", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
                .finally(() => {
                    this.setState({ showProgress: false });
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                csi: "",
                subaru_integrity_score: "",
                pay_1_payroll_bonus: "",
                pay_1_guaranteed_work_days: "",
                pay_1_sick_labor_rate: "",
                pay_1_pto_labor_rate: "",
                pay_1_bereavement_labor_rate: "",
                pay_1_holiday_labor_rate: "",
                pay_1_non_commission_flat_sum_bonus_spiff: "",
                pay_1_mealtime_premium_hours: "",
                pay_1_comments: "",
                pay_2_payroll_bonus: "",
                pay_2_guaranteed_work_days: "",
                pay_2_sick_labor_rate: "",
                pay_2_pto_labor_rate: "",
                pay_2_bereavement_labor_rate: "",
                pay_2_holiday_labor_rate: "",
                pay_2_non_commission_flat_sum_bonus_spiff: "",
                pay_2_mealtime_premium_hours: "",
                pay_2_comments: ""
            });
            this.props.onCancelClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        let title = "";
        if (this.props.payrollDetail) {
            title = "Edit Employee Pay Month & Pay Periods - " + this.props.payrollDetail.employee_name + " (" + this.props.payrollDetail.employee_number + ") - " + this.props.payrollDetail.store_name + " - " + CommonService.formatMonthYear(moment(this.props.payrollMonth));
        }

        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.props.payrollDetail !== null}
            cssName="dgdg-sales-payroll-detail-dialog"
            title={title}
            onCancelClick={this.onCancelClick}
        >
            <DGDGModalDialogHeaderButtonComponent>
                <button className="btn btn-primary" onClick={this.onSaveClick}>
                    Save <i className="fas fa-save dgdg-icon" />
                </button>
            </DGDGModalDialogHeaderButtonComponent>
            {
                this.props.payrollDetail
                    ? <div className="dgdg-payroll">
                        <div className="form-inline">
                            &nbsp;Pay Month: {CommonService.formatMonthYear(moment(this.props.payrollMonth))}
                            <span style={{ "paddingLeft": "30px" }}>New Units: {this.props.payrollDetail.new_unit_count}</span>
                            <span style={{ "paddingLeft": "30px" }}>Used Units: {this.props.payrollDetail.used_unit_count}</span>
                            <span style={{ "paddingLeft": "30px" }}>Total Units: {this.props.payrollDetail.new_unit_count + this.props.payrollDetail.used_unit_count}</span>
                            <span style={{ "paddingLeft": "30px" }}>
                                <DGDGFormCheckboxComponent label="CSI" id="csi" value={this.state.csi} onChange={this.onCheckChanged} />
                            </span>
                            {
                                this.props.payrollDetail.store_number === 3
                                    ? <span style={{ "paddingLeft": "30px" }}>
                                        <DGDGFormCheckboxComponent label="Subaru Integrity Score" id="subaru_integrity_score" value={this.state.subaru_integrity_score} onChange={this.onCheckChanged} />
                                    </span>
                                    : null
                            }
                        </div>
                        <table className="dgdg-payroll-period-edit">
                            <tbody>
                                <tr>
                                    <td rowSpan="2" className="dgdg-no-padding-bottom">Pay Period: 1</td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Pay Bonus" controlCssName="dgdg-money" id="pay_1_payroll_bonus" inputType="money" value={this.state.pay_1_payroll_bonus} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="GWD" controlCssName="dgdg-money" id="pay_1_guaranteed_work_days" inputType="decimal" value={this.state.pay_1_guaranteed_work_days} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Sick Rate" controlCssName="dgdg-money" id="pay_1_sick_labor_rate" inputType="money" value={this.state.pay_1_sick_labor_rate} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="PTO Rate" controlCssName="dgdg-money" id="pay_1_pto_labor_rate" inputType="money" value={this.state.pay_1_pto_labor_rate} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Berv Rate" controlCssName="dgdg-money" id="pay_1_bereavement_labor_rate" inputType="money" value={this.state.pay_1_bereavement_labor_rate} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Holiday Rate" controlCssName="dgdg-money" id="pay_1_holiday_labor_rate" inputType="money" value={this.state.pay_1_holiday_labor_rate} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Non Commission Spiff" controlCssName="dgdg-money" id="pay_1_non_commission_flat_sum_bonus_spiff" inputType="money" value={this.state.pay_1_non_commission_flat_sum_bonus_spiff} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Mealtime Prem Hours" labelCssName="dgdg-payroll-width" id="pay_1_mealtime_premium_hours" inputType="decimal" value={this.state.pay_1_mealtime_premium_hours} onChange={this.onTextChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dgdg-no-border-top text-right">Comments:</td>
                                    <td colSpan="7" className="dgdg-no-border-top">
                                        <DGDGFormInputComponent id="pay_1_comments" value={this.state.pay_1_comments} onChange={this.onTextChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan="2" className="dgdg-no-padding-bottom">Pay Period: 2</td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Pay Bonus" controlCssName="dgdg-money" id="pay_2_payroll_bonus" inputType="money" value={this.state.pay_2_payroll_bonus} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="GWD" controlCssName="dgdg-money" id="pay_2_guaranteed_work_days" inputType="decimal" value={this.state.pay_2_guaranteed_work_days} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Sick Rate" controlCssName="dgdg-money" id="pay_2_sick_labor_rate" inputType="money" value={this.state.pay_2_sick_labor_rate} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="PTO Rate" controlCssName="dgdg-money" id="pay_2_pto_labor_rate" inputType="money" value={this.state.pay_2_pto_labor_rate} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Berv Rate" controlCssName="dgdg-money" id="pay_2_bereavement_labor_rate" inputType="money" value={this.state.pay_2_bereavement_labor_rate} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Holiday Rate" controlCssName="dgdg-money" id="pay_2_holiday_labor_rate" inputType="money" value={this.state.pay_2_holiday_labor_rate} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Non Commission Spiff" controlCssName="dgdg-money" id="pay_2_non_commission_flat_sum_bonus_spiff" inputType="money" value={this.state.pay_2_non_commission_flat_sum_bonus_spiff} onChange={this.onTextChange} />
                                    </td>
                                    <td className="dgdg-no-padding-bottom">
                                        <DGDGFormInputComponent label="Mealtime Prem Hours" labelCssName="dgdg-payroll-width" id="pay_2_mealtime_premium_hours" inputType="decimal" value={this.state.pay_2_mealtime_premium_hours} onChange={this.onTextChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dgdg-no-border-top text-right">Comments:</td>
                                    <td colSpan="7" className="dgdg-no-border-top">
                                        <DGDGFormInputComponent id="pay_2_comments" value={this.state.pay_2_comments} onChange={this.onTextChange} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    : null
            }
        </DGDGModalDialogComponent>;
    }
}
