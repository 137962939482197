import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

export class FIPayrollPlanDetailComponent extends React.Component {
    pageTitle = "FI Payroll";

    render() {
        return <div style={{ "marginLeft": "120px" }}>
            <table className="dgdg-fi-payroll-plan-detail-table" style={{ "textAlign": "center" }}>
                <tbody>
                    <tr className="dgdg-fi-payroll-plan-font">
                        <td />
                        <td colSpan="2" className="dgdg-fi-payroll-plan-td-border">
                            PPR Income
                        </td>
                        <td colSpan="2" className="dgdg-fi-payroll-plan-td-border">
                            PVR Income
                        </td>
                        <td colSpan="2" className="dgdg-fi-payroll-plan-td-border">
                            Warranty Income
                        </td>
                        <td colSpan="2">
                            Maintenance
                        </td>
                        <td />
                    </tr>
                    <tr className="dgdg-fi-payroll-plan-table-td-width dgdg-fi-payroll-plan-font">
                        <td />
                        <td>
                            Range
                        </td>
                        <td className="dgdg-fi-payroll-plan-td-border">
                            Percentage
                        </td>
                        <td>
                            Range
                        </td>
                        <td className="dgdg-fi-payroll-plan-td-border">
                            Percentage
                        </td>
                        <td>
                            Range
                        </td>
                        <td className="dgdg-fi-payroll-plan-td-border">
                            Percentage
                        </td>
                        <td>
                            Range
                        </td>
                        <td>
                            Percentage
                        </td>
                        <td />
                    </tr>
                    <tr>
                        <td className="dgdg-fi-payroll-plan-table-td-width">
                            Tier 1
                        </td>
                        <td>
                            {this.props.rowData.tier1PPRRange}
                        </td>
                        <td className="dgdg-fi-payroll-plan-td-border">
                            {CommonService.formatPercentage_2(this.props.rowData.tier1PPRPercentage)}
                        </td>
                        <td>
                            {this.props.rowData.tier1PVRRange}
                        </td>
                        <td className="dgdg-fi-payroll-plan-td-border">
                            {CommonService.formatPercentage_2(this.props.rowData.tier1PVRPercentage)}
                        </td>
                        <td>
                            {this.props.rowData.tier1WarrantyPenPercentage}
                        </td>
                        <td className="dgdg-fi-payroll-plan-td-border">
                            {CommonService.formatPercentage_2(this.props.rowData.tier1WarrantyPercentage)}
                        </td>
                        <td>
                            {this.props.rowData.tier1MaintenancePenPercentage}
                        </td>
                        <td>
                            {CommonService.formatPercentage_2(this.props.rowData.tier1MaintenancePercentage)}
                        </td>
                        <td />
                    </tr>
                    <tr>
                        <td className="dgdg-fi-payroll-plan-table-td-width">
                            Tier 2
                        </td>
                        <td>
                            {this.props.rowData.tier2PPRRange}
                        </td>
                        <td className="dgdg-fi-payroll-plan-td-border">
                            {CommonService.formatPercentage_2(this.props.rowData.tier2PPRPercentage)}
                        </td>
                        <td>
                            {this.props.rowData.tier2PVRRange}
                        </td>
                        <td className="dgdg-fi-payroll-plan-td-border">
                            {CommonService.formatPercentage_2(this.props.rowData.tier2PVRPercentage)}
                        </td>
                        <td>
                            {this.props.rowData.tier2WarrantyPenPercentage}
                        </td>
                        <td className="dgdg-fi-payroll-plan-td-border">
                            {CommonService.formatPercentage_2(this.props.rowData.tier2WarrantyPercentage)}
                        </td>
                        <td>
                            {this.props.rowData.tier2MaintenancePenPercentage}
                        </td>
                        <td>
                            {CommonService.formatPercentage_2(this.props.rowData.tier2MaintenancePercentage)}
                        </td>
                        <td />
                    </tr>
                    <tr>
                        <td className="dgdg-fi-payroll-plan-table-td-width">
                            Tier 3
                        </td>
                        <td>
                            {this.props.rowData.tier3PPRRange}
                        </td>
                        <td className="dgdg-fi-payroll-plan-td-border">
                            {CommonService.formatPercentage_2(this.props.rowData.tier3PPRPercentage)}
                        </td>
                        <td>
                            {this.props.rowData.tier3PVRRange}
                        </td>
                        <td className="dgdg-fi-payroll-plan-td-border">
                            {CommonService.formatPercentage_2(this.props.rowData.tier3PVRPercentage)}
                        </td>
                        <td>
                            {this.props.rowData.tier3WarrantyPenPercentage}
                        </td>
                        <td className="dgdg-fi-payroll-plan-td-border">
                            {CommonService.formatPercentage_2(this.props.rowData.tier3WarrantyPercentage)}
                        </td>
                        <td>
                            {this.props.rowData.tier3MaintenancePenPercentage}
                        </td>
                        <td>
                            {CommonService.formatPercentage_2(this.props.rowData.tier3MaintenancePercentage)}
                        </td>
                        <td />
                    </tr>
                    <tr>
                        <td className="dgdg-fi-payroll-plan-table-td-width">
                            Tier 4
                        </td>
                        <td>
                            {this.props.rowData.tier4PPRRange}
                        </td>
                        <td className="dgdg-fi-payroll-plan-td-border">
                            {CommonService.formatPercentage_2(this.props.rowData.tier4PPRPercentage)}
                        </td>
                        <td>
                            {this.props.rowData.tier4PVRRange}
                        </td>
                        <td className="dgdg-fi-payroll-plan-td-border">
                            {CommonService.formatPercentage_2(this.props.rowData.tier4PVRPercentage)}
                        </td>
                        <td>
                            {this.props.rowData.tier4WarrantyPenPercentage}
                        </td>
                        <td className="dgdg-fi-payroll-plan-td-border">
                            {CommonService.formatPercentage_2(this.props.rowData.tier4WarrantyPercentage)}
                        </td>
                        <td />
                        <td />
                        <td />
                    </tr>
                    <tr>
                        <td className="dgdg-fi-payroll-plan-table-td-width">
                            Tier 5
                        </td>
                        <td />
                        <td className="dgdg-fi-payroll-plan-td-border">
                        </td>
                        <td />
                        <td className="dgdg-fi-payroll-plan-td-border">
                        </td>
                        <td>
                            {this.props.rowData.tier5WarrantyPenPercentage}
                        </td>
                        <td className="dgdg-fi-payroll-plan-td-border">
                            {CommonService.formatPercentage_2(this.props.rowData.tier5WarrantyPercentage)}
                        </td>
                        <td />
                        <td />
                        <td />
                    </tr>
                    <br />
                    <tr className="dgdg-fi-payroll-plan-table-td-width dgdg-fi-payroll-plan-font" colSpan="2">
                        <td className="dgdg-fi-payroll-plan-td-border" colSpan="2">
                            Ally
                        </td>
                        <td className="dgdg-fi-payroll-plan-td-border" colSpan="2">
                            CSI
                        </td>
                        <td className="dgdg-fi-payroll-plan-td-border" colSpan="2">
                            CIT
                        </td>
                        <td className="dgdg-fi-payroll-plan-td-border" colSpan="2">
                            EV
                        </td>
                        <td colSpan="2">
                            Hourly
                        </td>
                    </tr>
                    <tr>
                        <td className="dgdg-fi-payroll-plan-table-td-width">
                            Yes
                        </td>
                        <td className="dgdg-fi-payroll-plan-td-border">
                            {CommonService.formatCurrency_2(this.props.rowData.allyYes)}
                        </td>
                        <td className="dgdg-fi-payroll-plan-table-td-width">
                            Yes
                        </td>
                        <td className="dgdg-fi-payroll-plan-td-border">
                            {this.props.rowData.csiYes}
                        </td>
                        <td className="dgdg-fi-payroll-plan-table-td-width">
                            Yes
                        </td>
                        <td className="dgdg-fi-payroll-plan-td-border">
                            {CommonService.formatPercentage_2(this.props.rowData.citYesPenPercentage)}
                        </td>
                        <td style={{ "width": "100px", "paddingLeft": "15px" }}>
                            Product Bonus
                        </td>
                        <td className="dgdg-fi-payroll-plan-td-border">
                            {CommonService.formatCurrency_2(this.props.rowData.productBonus)}
                        </td>
                        <td style={{ "width": "60px" }}>
                            Hourly
                        </td>
                        <td className="dgdg-fi-payroll-plan-table-td-width">
                            {this.props.rowData.hourly}
                        </td>
                    </tr>
                    <tr>
                        <td className="dgdg-fi-payroll-plan-table-td-width">
                            No
                        </td>
                        <td className="dgdg-fi-payroll-plan-td-border">
                            {CommonService.formatCurrency_2(this.props.rowData.allyNo)}
                        </td>
                        <td className="dgdg-fi-payroll-plan-table-td-width">
                            No
                        </td>
                        <td className="dgdg-fi-payroll-plan-td-border">
                            {this.props.rowData.csiNo}
                        </td>
                        <td className="dgdg-fi-payroll-plan-table-td-width">
                            No
                        </td>
                        <td className="dgdg-fi-payroll-plan-td-border">
                            {CommonService.formatPercentage_2(this.props.rowData.citNoPenPercentage)}
                        </td>
                        <td style={{ "width": "95px" }}>
                            Gross Bonus
                        </td>
                        <td className="dgdg-fi-payroll-plan-td-border">
                            {CommonService.formatCurrency_2(this.props.rowData.grossBonus)}
                        </td>
                        <td style={{ "width": "60px" }}>
                        </td>
                        <td />
                    </tr>
                </tbody>
            </table>
        </div>
    }
}
