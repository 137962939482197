import React from "react";
import moment from "moment";

import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGFormCalendarComponent } from "../common/dgdgcalendar/DGDGFormCalendarComponent";
import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";

export class DemoPageView2QueryComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            if (props.isLoadingData) {
                newState.showSpinner = true;
            }
            else if (state.showSpinner) {
                newState.showSpinner = props.isLoadingData;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            decimalInput: "",
            dateInput: null,
            showSpinner: false
        };

        this.onTextChange = this.onTextChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDateChange(id, value) {
        try {
            this.setState({ [id]: moment(value) });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event) {
        try {
            this.setState({ showSpinner: true });
            this.props.onGetReportClick(event, {
                decimalQuery: this.state.decimalInput,
                dateQuery: this.state.dateQuery
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPrintClick(event) {
        try {
            this.props.onPrintClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="card dgdg-card-gutter">
            <div className="card-block">
                <div className="form-inline">
                    <DGDGFormInputComponent id="decimalInput2" label="Decimal Input" value={this.state.decimalInput} onChange={this.onTextChange} inputType="decimal" />
                    <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="dateInput2" label="Date Input" value={this.state.dateInput} onDateChange={this.onDateChange} numberOfMonths={2} showDays={[3]} />
                    <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-icon" onClick={(event) => this.onGetReportClick(event)}>
                        Get Report <span className="fas fa-redo-alt dgdg-icon" />
                    </button>
                    <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-icon" onClick={(event) => this.onPrintClick(event)}>
                        Print<i className="far fa-print dgdg-icon" />
                    </button>
                    <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
                </div>
            </div>
        </div>;
    }
}
