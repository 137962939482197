import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormTextAreaComponent } from "../common/dgdgtextarea/DGDGFormTextAreaComponent";
import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableColumnDrCrComponent } from "../common/dgdgtable/DGDGTableColumnDrCrComponent";

import { InterCompanyBalancesQueryComponent } from "./InterCompanyBalancesQueryComponent";

export class InterCompanyBalancesRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            months: [],
            tableData: [],
            paintTable: false,
            isLoadingData: false,
            cdkConcatenateViewText: null,
            dataTitle: "",
            rowCount: 0,
            lastUpdatedDate: null,
        };

        this.onGenerateInterCompanyBalancesClick = this.onGenerateInterCompanyBalancesClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onCopyClick = this.onCopyClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    componentDidMount() {
        this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/InterCompanyBalances", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        this.getStores();
        this.getMonths();
        CommonService.clientAzureStorageLog("InterCompanyBalancesRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
    }

    pageTitle = "Inter-company Balances";

    getStores() {
        this.props.applicationInsights.trackTrace({ message: "/InterCompanyBalancesRouteComponent/getStores", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/InterCompanyBalances/GetStores")
            .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
            .then(parsedResponse => {
                this.setState({
                    stores: parsedResponse
                });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/InterCompanyBalances/GetStores", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    getMonths() {
        let startDate = moment().startOf("month").subtract(26, "month");
        let dateCounter = moment().startOf("month");
        let endDate = moment("20221001", "YYYYMMDD").startOf("month")
        let months = [];
        while (dateCounter >= endDate && dateCounter > startDate) {
            months.push(moment(dateCounter));
            dateCounter = dateCounter.subtract(1, "month");
        }

        this.setState({ months: months });
    }

    onGenerateInterCompanyBalancesClick(selectedStore, selectedMonth) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                storeNumber: selectedStore ? selectedStore.store_number : null,
                startDate: CommonService.formatServerDateTime(selectedMonth),
            };

            this.onGetLastUpdatedDate(selectedMonth);

            this.props.applicationInsights.trackTrace({ message: "/InterCompanyBalancesRouteComponent/onGenerateInterCompanyBalancesClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/InterCompanyBalances/GenerateInterCompanyBalances", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let tableData = parsedResponse.table;
                    tableData.forEach((responseItem, index) => {
                        responseItem.bal_drcr = responseItem.bal;
                        responseItem.bal_drcr_cellCssName = responseItem.bal < 0 ? "dgdg-inter-company-balances-table-cr-cell" : "dgdg-inter-company-balances-table-dr-cell";
                    });
                    this.setState({
                        isLoadingData: false,
                        tableTitle: selectedStore === null ? "All Stores" : selectedStore.display_name + "  - " + CommonService.formatMonthYear(moment(selectedMonth)),
                        tableData: CommonService.addOrdinalColumn(tableData),
                        cdkConcatenateViewText: parsedResponse.cdkConcatenateData,
                        dataTitle: "CDK Concatenate View - " + selectedStore === null ? "All Stores" : selectedStore.display_name + "  - " + CommonService.formatMonthYear(moment(selectedMonth)),
                        rowCount: tableData.length + 1,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: "",
                        dataTitle: "",
                        tableData: [],
                        cdkConcatenateViewText: null,
                        rowCount: 0,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/InterCompanyBalances/GetStores", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetLastUpdatedDate(selectedMonth) {
        try {
            let inputData = {
                startDate: CommonService.formatServerDateTime(selectedMonth)
            };
            this.props.applicationInsights.trackTrace({ message: "/InterCompanyBalancesRouteComponent/onGetLastUpdatedDate", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/InterCompanyBalances/GetLastUpdated", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let lastUpdatedDate = "";
                    if (CommonService.isDateValid(parsedResponse))
                        lastUpdatedDate = moment.utc(parsedResponse);
                    this.setState({ lastUpdatedDate: lastUpdatedDate });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/InterCompanyBalances/GetLastUpdated", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("InterCompanyBalancesRouteComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyClick(event) {
        try {
            navigator.clipboard.writeText(this.state.cdkConcatenateViewText);
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("GenerateInterCompanyBalances", "onCopyClick", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        let cdkConcatenateViewText = "";
        if (this.state.cdkConcatenateViewText) {
            this.state.cdkConcatenateViewText.split('\n').forEach((cdkConcatenateViewItem, index) => {
                if (cdkConcatenateViewItem) {
                    cdkConcatenateViewText += (index + 1) + "\t" + cdkConcatenateViewItem + "\n";
                }
            });
        }

        return <DGDGPageComponent bodyCssName="dgdg-flex dgdg-flex-column">
            <div className="mt-auto">
                <span className="float-right badge badge-primary dgdg-badge">
                    Last Updated Date: {this.state.lastUpdatedDate ? CommonService.formatDateTimeZone(this.state.lastUpdatedDate) : ""}
                </span>
            </div>
            <div className="dgdg-flex-item dgdg-site-content">
                <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
                    <InterCompanyBalancesQueryComponent
                        applicationInsights={this.props.applicationInsights}
                        stores={this.state.stores}
                        months={this.state.months}
                        isLoadingData={this.state.isLoadingData}
                        onGenerateInterCompanyBalancesClick={this.onGenerateInterCompanyBalancesClick}
                        onShowAlert={this.props.onShowAlert}
                    />
                    <div className="dgdg-site-scrollable-content">
                        <div className="dgdg-flex dgdg-flex-row">
                            <DGDGTableV3Component applicationInsights={this.props.applicationInsights} tableData={this.state.tableData} headerText={this.state.tableTitle} defaultSortColumn={CommonService.ordinalColumnName} defaultSortOrder="Asc" paintTable={this.state.paintTable} showFooter
                                copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8], onCopyTableCallBack: this.onCopyTableCallBack }}
                            >
                                <DGDGTableRowComponent>
                                    <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                    <DGDGTableColumnComponent headerText="Store" dataColumn="display_name" filterColumn="display_name" sortColumn="display_name" />
                                    <DGDGTableColumnComponent headerText="Acct No" dataColumn="an" sortColumn="an" filterColumn="an" />
                                    <DGDGTableColumnComponent headerText="Inter-company Store" dataColumn="inter_company_store" sortColumn="inter_company_store" filterColumn="inter_company_store" />
                                    <DGDGTableColumnDrCrComponent headerText="DR/CR" dataColumn="bal_drcr" sortColumn="bal_drcr" />
                                    <DGDGTableColumnComponent headerText="Amount" dataColumn="bal" sortColumn="bal" filterColumn="bal" dataType="money_2" footerFunction="sum" footerCssName="text-right" />
                                    <DGDGTableColumnComponent headerText="Control 1" dataColumn="control_1" sortColumn="control_1" filterColumn="control_1" />
                                    <DGDGTableColumnComponent headerText="Control 2" dataColumn="control_2" sortColumn="control_2" filterColumn="control_2" />
                                    <DGDGTableColumnComponent headerText="Description" dataColumn="description" sortColumn="description" filterColumn="description" />
                                </DGDGTableRowComponent>
                            </DGDGTableV3Component>
                            <div className="card dgdg-box-shadow dgdg-inter-company-balances-help-card">
                                <div className="card-header">
                                    <span dangerouslySetInnerHTML={{ __html: this.state.dataTitle }} className="float-left" />
                                    <button className="btn btn-primary float-right" onClick={(event) => this.onCopyClick(event)}>
                                        Copy<span className="far fa-copy dgdg-icon" />
                                    </button>
                                </div>
                                <div className="card-block">
                                    <DGDGFormTextAreaComponent value={this.state.cdkConcatenateViewText} cssName="dgdg-copy-textbox" readOnly />
                                    <DGDGFormTextAreaComponent value={cdkConcatenateViewText} rows={this.state.rowCount} colSpan="col-12" controlSpan="col-12" readOnly />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}
