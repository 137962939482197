import React from "react";

import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGQueryComponent } from "../common/dgdgquery/DGDGQueryComponent";

export class ManageReportsQueryComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        return <DGDGQueryComponent cssName="dgdg-card-gutter">
            <div className="card-block">
                <div className="form-inline">
                    <button className="btn btn-primary dgdg-icon" onClick={(event) => this.props.onViewMetadataClick(event)} disabled={this.props.showSpinner}>
                        View Metadata
                    </button>
                    <button className="btn btn-primary dgdg-icon" onClick={(event) => this.props.onRefreshMetadataClick(event)} disabled={this.props.showSpinner}>
                        Refresh Metadata
                    </button>
                    <DGDGSpinnerComponent showSpinner={this.props.showSpinner} />
                </div>
            </div>
        </DGDGQueryComponent>;
    }
}
