import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableHeaderComponent } from "../common/dgdgtable/DGDGTableHeaderComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";

import { FILenderReportQueryComponent } from "./FILenderReportQueryComponent";

export class FILenderReportRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            isDownLoading: false,
            tableData: [],
            tableTitle: "",
            paintTable: false
        };

        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/FILenderReport", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("FILenderReportRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "F&I Lender Report";

    onGetReportClick(startDate, endDate) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                startDate: CommonService.formatServerDateTime(startDate.toString()),
                endDate: CommonService.formatServerDateTime(endDate.toString())
            };

            this.props.applicationInsights.trackTrace({ message: "/FILenderReportRouteComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FILenderReport/GetReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: CommonService.formatDate(moment(startDate)) + " - " + CommonService.formatDate(moment(endDate)),
                        tableData: CommonService.addOrdinalColumn(parsedResponse.table),
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: "",
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FILenderReport/GetReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDownloadClick(startDate, endDate) {
        try {
            this.setState({ isDownLoading: true });
            let inputData = {
                startDate: CommonService.formatServerDateTime(startDate),
                endDate: CommonService.formatServerDateTime(endDate)
            };

            this.props.applicationInsights.trackTrace({ message: "/FILenderReportRouteComponent/onDownloadClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FILenderReport/Download", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response } else { throw response; } })
                .then(response => {
                    this.setState({ isDownLoading: false });
                    CommonService.downloadBlob(response);
                })
                .catch(notOKResponse => {
                    this.setState({ isDownLoading: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FILenderReport/Download", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", { message: "Failed to download" });
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("FILenderReportRouteComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-flex dgdg-flex-column">
            <div className="dgdg-report-body dgdg-flex dgdg-flex-column dgdg-flex-item dgdg-site-content">
                <FILenderReportQueryComponent
                    isLoadingData={this.state.isLoadingData} isDownLoading={this.state.isDownLoading}
                    onGetReportClick={this.onGetReportClick} onDownloadClick={this.onDownloadClick}
                    onShowAlert={this.props.onShowAlert}
                    applicationInsights={this.props.applicationInsights}
                />
                <div className="dgdg-site-scrollable-content">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable}
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20], onCopyTableCallBack: this.onCopyTableCallBack }} stickyHeader
                    >
                        <DGDGTableHeaderComponent position="PreHeader">
                            <tr className="dgdg-table-v3-header" style={{ "borderBottom": "1px solid #DCDCDC" }}>
                                <td colSpan="3" />
                                <td colSpan="6" className="text-center font-weight-bold dgdg-lender-report-new-color">New</td>
                                <td colSpan="6" className="text-center font-weight-bold dgdg-lender-report-used-color">Used</td>
                                <td colSpan="6" className="text-center font-weight-bold dgdg-lender-report-total-color">Total</td>
                            </tr>
                        </DGDGTableHeaderComponent>
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                            <DGDGTableColumnComponent headerText="Store Name" cssName="text-nowrap" dataColumn="store_name" sortColumn="store_name" filterColumn="store_name" />
                            <DGDGTableColumnComponent headerText="Bank Name" dataColumn="finance_source" sortColumn="finance_source" filterColumn="finance_source" />
                            <DGDGTableColumnComponent headerText="Deals" dataColumn="new_deal_count" sortColumn="new_deal_count" filterColumn="new_deal_count" dataType="number" cssName="dgdg-lender-report-new-color" />
                            <DGDGTableColumnComponent headerText="Deal Pct" dataColumn="new_deal_pct" sortColumn="new_deal_pct" filterColumn="new_deal_pct" dataType="percent_2" cssName="dgdg-lender-report-new-color" />
                            <DGDGTableColumnComponent headerText="Average Reserve Paid" dataColumn="new_average_reserve" sortColumn="new_average_reserve" filterColumn="new_average_reserve" dataType="money" cssName="dgdg-lender-report-new-color" />
                            <DGDGTableColumnComponent headerText="Average Rate Spread" dataColumn="new_average_rate_spread" sortColumn="new_average_rate_spread" filterColumn="new_average_rate_spread" dataType="percent_2" cssName="dgdg-lender-report-new-color" />
                            <DGDGTableColumnComponent headerText="Average Finance Gross" dataColumn="new_average_back_gross" sortColumn="new_average_back_gross" filterColumn="new_average_back_gross" dataType="money" cssName="dgdg-lender-report-new-color" />
                            <DGDGTableColumnComponent headerText="Total Financed Amount" dataColumn="new_sum_finance_amt" sortColumn="new_sum_finance_amt" filterColumn="new_sum_finance_amt" dataType="money" cssName="dgdg-lender-report-new-color" />
                            <DGDGTableColumnComponent headerText="Deals" dataColumn="used_deal_count" sortColumn="used_deal_count" filterColumn="used_deal_count" dataType="number" cssName="dgdg-lender-report-used-color" />
                            <DGDGTableColumnComponent headerText="Deal Pct" dataColumn="used_deal_pct" sortColumn="used_deal_pct" filterColumn="used_deal_pct" dataType="percent_2" cssName="dgdg-lender-report-used-color" />
                            <DGDGTableColumnComponent headerText="Average Reserve Paid" dataColumn="used_average_reserve" sortColumn="used_average_reserve" filterColumn="used_average_reserve" dataType="money" cssName="dgdg-lender-report-used-color" />
                            <DGDGTableColumnComponent headerText="Average Rate Spread" dataColumn="used_average_rate_spread" sortColumn="used_average_rate_spread" filterColumn="used_average_rate_spread" dataType="percent_2" cssName="dgdg-lender-report-used-color" />
                            <DGDGTableColumnComponent headerText="Average Finance Gross" dataColumn="used_average_back_gross" sortColumn="used_average_back_gross" filterColumn="used_average_back_gross" dataType="money" cssName="dgdg-lender-report-used-color" />
                            <DGDGTableColumnComponent headerText="Financed Amount" dataColumn="used_sum_finance_amt" sortColumn="used_sum_finance_amt" filterColumn="used_sum_finance_amt" dataType="money" cssName="dgdg-lender-report-used-color" />
                            <DGDGTableColumnComponent headerText="Deals" dataColumn="total_deal_count" sortColumn="total_deal_count" filterColumn="total_deal_count" dataType="number" cssName="dgdg-lender-report-total-color" />
                            <DGDGTableColumnComponent headerText="Deal Pct" dataColumn="total_deal_pct" sortColumn="total_deal_pct" filterColumn="total_deal_pct" dataType="percent_2" cssName="dgdg-lender-report-total-color" />
                            <DGDGTableColumnComponent headerText="Average Reserve Paid" dataColumn="total_average_reserve" sortColumn="total_average_reserve" filterColumn="total_average_reserve" dataType="money" cssName="dgdg-lender-report-total-color" />
                            <DGDGTableColumnComponent headerText="Average Rate Spread" dataColumn="total_average_rate_spread" sortColumn="total_average_rate_spread" filterColumn="total_average_rate_spread" dataType="percent_2" cssName="dgdg-lender-report-total-color" />
                            <DGDGTableColumnComponent headerText="Average Finance Gross" dataColumn="total_average_back_gross" sortColumn="total_average_back_gross" filterColumn="total_average_back_gross" dataType="money" cssName="dgdg-lender-report-total-color" />
                            <DGDGTableColumnComponent headerText="Finances Amount" dataColumn="total_sum_finance_amt" sortColumn="total_sum_finance_amt" filterColumn="total_sum_finance_amt" dataType="money" cssName="dgdg-lender-report-total-color" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}
