import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormComboBoxComponent } from "../common/dgdgcombobox/DGDGFormComboBoxComponent";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";
import { DGDGTableHeaderComponent } from "../common/dgdgtable/DGDGTableHeaderComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { EVOXImagesKBBEVOXMappingQueryComponent } from "./EVOXImagesKBBEVOXMappingQueryComponent";
import { EVOXImagesComponent } from "./EVOXImagesComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

export class EVOXImagesKBBEVOXMappingComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showConfirmation: false,
            saveTableData: [],
            savePaintTable: false,
            saveSelectedYear: null,
            tableData: [],
            tableTitle: "",
            paintTable: false,
            vifData: null,
            vifDict: null,
            isLoadingData: false,
            imageType: "Front",
            evoxPhotoRowData: null,
            evoxVehicleOEMColors: null,
            selectedEVOXVehicleOEMColors: null,
            kbbPhotoRowData: null,
            kbbVehicleOEMColors: null,
            selectedKBBVehicleOEMColors: null
        };

        this.onVIFDataColumnCallback = this.onVIFDataColumnCallback.bind(this);
        this.onActionColumnCallback = this.onActionColumnCallback.bind(this);
        this.onMappedByColumnCallback = this.onMappedByColumnCallback.bind(this);
        this.onPhotoColumnCallback = this.onPhotoColumnCallback.bind(this);
        this.onMappingChange = this.onMappingChange.bind(this);
        this.onMappingClick = this.onMappingClick.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onSaveAllClick = this.onSaveAllClick.bind(this);
        this.onConfirmSaveAllClick = this.onConfirmSaveAllClick.bind(this);
        this.onCancelSaveAllClick = this.onCancelSaveAllClick.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onUndoClick = this.onUndoClick.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.onCommentTextChange = this.onCommentTextChange.bind(this);
        this.onShowEVOXPhotosClick = this.onShowEVOXPhotosClick.bind(this);
        this.onCancelEVOXPhotosClick = this.onCancelEVOXPhotosClick.bind(this);
        this.onShowKBBPhotosClick = this.onShowKBBPhotosClick.bind(this);
        this.onCancelKBBPhotosClick = this.onCancelKBBPhotosClick.bind(this);
        this.onRowKeyDown = this.onRowKeyDown.bind(this);
        this.onVifNumberColumnCallback = this.onVifNumberColumnCallback.bind(this);
        this.getVifList = this.getVifList.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    pageTitle = "EVOX Images";
    evoxImageBaseUrl = "/EVOXImages/EVOXImage/";
    kbbImageBaseUrl = "https://cdn.dgdg.com/image/EVOX/";

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/EVOXImages/KBBEVOXMapping", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getVifList();
            CommonService.clientAzureStorageLog("EVOXImagesKBBEVOXMappingComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getVifList() {
        this.props.applicationInsights.trackTrace({ message: "/EVOXImagesKBBEVOXMappingComponent/getVifList", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/EVOXImages/GetVIFList")
            .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
            .then(parsedResponse => {
                let vifData = parsedResponse.table;
                let vifDict = {};
                vifData.forEach((responseItem, index) => {
                    vifDict[responseItem.vif_number.toString()] = responseItem;
                });
                this.setState({
                    vifData: vifData,
                    vifDict: vifDict
                });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/EVOXImages/GetVIFList", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    onVIFDataColumnCallback(rowData) {
        try {
            let rowId = "vif_" + rowData.vehicle_id;
            let vifList = rowData.showMapping ? (rowData.vifList ? rowData.vifList : this.state.vifData.filter(data => data.year.toString() === rowData.year_id.toString())) : null;

            return <DGDGFormComboBoxComponent
                id={rowId} data={vifList} additionalData={rowData} itemKey="display_name" placeHolder="Type to filter vehicles" value={rowData.evox_display_name} colSpan="col-12" autoDrop
                onItemChange={this.onMappingChange} onItemClick={this.onMappingClick} onKeyDown={this.onRowKeyDown} />
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onActionColumnCallback(rowData) {
        try {
            return <div className="form-inline">
                <button className="btn btn-link tooltip" onClick={(event) => this.onSaveClick(event, rowData)} style={{ "padding": "8px 10px" }} tabIndex="-1" disabled={rowData.disabledSave}>
                    <i className="fas fa-save" />
                    <div className="content" data-display="static">
                        Save (Ctrl+S)
                    </div>
                </button>
                <button className="btn btn-link tooltip" disabled={rowData.originalVIFNumber === rowData.vif_number} onClick={(event) => this.onUndoClick(event, rowData)} style={{ "padding": "8px 10px" }} tabIndex="-1">
                    <i className="fas fa-undo" />
                    <div className="content" data-display="static">
                        Undo (Ctrl+U)
                    </div>
                </button>
                <button className="btn btn-link tooltip" disabled={!rowData.vif_number} onClick={(event) => this.onDeleteClick(event, rowData)} style={{ "padding": "8px 10px" }} tabIndex="-1">
                    <i className="fas fa-times text-danger" />
                    <div className="content" data-display="static">
                        Remove (Ctrl+R)
                    </div>
                </button>
                <DGDGSpinnerComponent showSpinner={rowData.action_showSpinner} />
            </div>;
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onMappedByColumnCallback(rowData) {
        try {
            if (rowData.vif_mapped_by) {
                return <div>
                    {rowData.vif_mapped_by}<br />
                    <span className="text-nowrap">{rowData.vif_mapped_on ? "(" + CommonService.formatDateTime_2(moment(rowData.vif_mapped_on)) + ")" : ''}</span>
                </div>;
            }

            return "";
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPhotoColumnCallback(rowData) {
        return <Fragment>
            {
                rowData.vif_number
                    ? <button type="button" className="btn btn-link" onClick={(event) => this.onShowEVOXPhotosClick(event, rowData)} tabIndex="-1">
                        EVOX
                    </button>
                    : <button type="button" className="btn btn-link" disabled>
                        EVOX
                    </button>
            }
            &nbsp;|&nbsp;
            {
                rowData.image_copied_date
                    ? <button type="button" className="btn btn-link" onClick={(event) => this.onShowKBBPhotosClick(event, rowData)} tabIndex="-1">
                        CDN
                    </button>
                    : <button type="button" className="btn btn-link" disabled>
                        CDN
                    </button>
            }
        </Fragment>;
    }

    onMappingChange(id, filterValue) {
        try {
            let tableData = this.state.tableData;
            let vehicleId = parseInt(id.split('_')[1]);
            let rowData = tableData.filter((row) => row.vehicle_id === vehicleId)[0];
            let filteredData = [];
            if (this.state.vifData) {
                filteredData = this.state.vifData.filter(vifRow => {
                    return vifRow.display_name.toLowerCase().includes(filterValue.toLowerCase());
                });
            }

            rowData.vifList = filteredData;
            rowData.evox_display_name = filterValue;
            rowData.showMapping = true;
            if (!filterValue && rowData.originalVIFNumber) {
                rowData.rowCssName = "evox-images-bg-modified";
                rowData.vif_number = "";
            }

            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onMappingClick(id, selectedItem) {
        try {
            let tableData = this.state.tableData;
            let vehicleId = parseInt(id.split('_')[1]);
            let rowData = tableData.filter((row) => row.vehicle_id === vehicleId)[0];

            rowData.vifList = null;
            rowData.vif_number = selectedItem.vif_number;
            rowData.showMapping = false;
            rowData.disabledSave = true;
            if (rowData.vif_number) {
                let matchedVIF = this.state.vifDict[rowData.vif_number.toString()];
                if (matchedVIF) {
                    rowData.disabledSave = false;
                    rowData.evox_display_name = matchedVIF.display_name;
                }
            }

            if (rowData.originalVIFNumber !== rowData.vif_number) {
                rowData.rowCssName = "evox-images-bg-modified";
            }

            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(selectedYear, selectedMake, selectedView, vehcileId, vifNumber) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                year: selectedYear ? CommonService.formatYear(selectedYear) : null,
                makeId: selectedMake ? selectedMake.makeId : "",
                view: selectedView ? selectedView.key : null,
                vehicleId: vehcileId,
                vifNumber: vifNumber
            };
            this.props.applicationInsights.trackTrace({ message: "/EVOXImagesKBBEVOXMappingComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/EVOXImages/GetKBBEVOXMappings", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let tableData = parsedResponse.table;
                    tableData.forEach((rowData) => {
                        rowData.originalVIFNumber = rowData.vif_number;
                        rowData.disabledSave = true;
                        if (!rowData.vif_number) {
                            rowData.evox_display_name = rowData.year_id + " " + rowData.make_name + " " + rowData.model_name;
                        }

                        if (rowData.status === "Inactive") {
                            rowData.rowCssName = "evox-images-inactive-mapping";
                        }
                        else if (rowData.status === "Hidden") {
                            rowData.rowCssName = "evox-images-hidden";
                        }
                    });

                    this.setState({
                        isLoadingData: false,
                        tableData: CommonService.addOrdinalColumn(tableData),
                        tableTitle: "KBB EVOX Mapping - " + (selectedYear ? CommonService.formatYear(selectedYear) : "All"),
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({ isLoadingData: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/EVOXImages/GetKBBEVOXMappings", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveAllClick(selectedYear) {
        try {
            let saveTableData = [];
            saveTableData = this.state.tableData.filter(item => item.rowCssName === "evox-images-bg-modified");
            this.setState({
                showConfirmation: true,
                saveTableData: saveTableData,
                savePaintTable: true,
                saveSelectedYear: selectedYear
            }, () => {
                this.setState({ savePaintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onConfirmSaveAllClick(event) {
        try {
            let saveData = [];
            this.state.saveTableData.forEach((rowData) => {
                rowData.action_showSpinner = true;
                saveData.push({
                    vehicleId: rowData.vehicle_id,
                    vifNumber: rowData.vif_number,
                    comment: rowData.comment
                });
            });

            if (saveData.length > 0) {
                this.setState({
                    isLoadingData: true,
                    paintTable: true
                });

                let inputData = saveData;
                this.props.applicationInsights.trackTrace({ message: "/EVOXImagesKBBEVOXMappingComponent/onConfirmSaveAllClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                fetch("/EVOXImages/SaveKBBEVOXMappings", {
                    method: "POST",
                    headers: {
                        'Content-Type': "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData)
                })
                    .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                    .then(parsedResponse => {
                        this.state.saveTableData.forEach((saveTableRow) => {
                            saveTableRow.showUndo = saveTableRow.originalVIFNumber !== saveTableRow.vif_number;
                            saveTableRow.action_showSpinner = false;
                            saveTableRow.rowCssName = null;
                            saveTableRow.vif_mapped_by = saveTableRow.vif_number ? this.props.remoteUpn : null;
                            saveTableRow.vif_mapped_on = saveTableRow.vif_number ? moment() : null;
                        });

                        this.setState({
                            isLoadingData: false,
                            paintTable: true
                        }, () => {
                            this.setState({ paintTable: false })
                        });
                        this.onCancelSaveAllClick(event);
                    })
                    .catch(notOKResponse => {
                        this.state.saveTableData.forEach((saveTableRow) => {
                            saveTableRow.action_showSpinner = false;
                        });

                        this.setState({
                            isLoadingData: false,
                            paintTable: true
                        }, () => {
                            this.setState({ paintTable: false })
                        });
                        this.onCancelSaveAllClick(event);
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/EVOXImages/onConfirmSaveAllClick", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                    console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.devError(jsonParseError, null, this.props.applicationInsights);
                                });
                        }
                    });
            }
            else {
                this.setState({
                    paintTable: true
                }, () => {
                    this.setState({ paintTable: false })
                });
            }
        }
        catch (error) {
            this.setState({ isLoadingData: false });
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelSaveAllClick(event) {
        try {
            this.setState({
                showConfirmation: false,
                saveTableData: [],
                savePaintTable: true,
                saveSelectedYear: null
            }, () => {
                this.setState({ savePaintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event, rowData) {
        try {
            this.setState({
                saveTableData: [rowData]
            }, this.onConfirmSaveAllClick);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onUndoClick(event, rowData) {
        try {
            rowData.rowCssName = null;
            rowData.vif_number = rowData.originalVIFNumber;
            if (rowData.vif_number) {
                let matchedVIF = this.state.vifDict[rowData.vif_number.toString()];
                if (matchedVIF) {
                    rowData.evox_display_name = matchedVIF.display_name;
                }
            }
            else {
                rowData.evox_display_name = rowData.year_id + " " + rowData.make_name + " " + rowData.model_name;
            }

            this.setState({
                saveTableData: [rowData]
            }, this.onConfirmSaveAllClick);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDeleteClick(event, rowData) {
        try {
            rowData.originalVIFNumber = rowData.vif_number;
            rowData.action_showSpinner = true;
            rowData.vif_number = "";
            rowData.evox_display_name = rowData.year_id + " " + rowData.make_name + " " + rowData.model_name;
            rowData.vif_mapped_by = null;
            rowData.vif_mapped_on = null;

            this.setState({
                saveTableData: [rowData]
            }, this.onConfirmSaveAllClick);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCommentTextChange(id, text, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.comment = text;
            rowData.rowCssName = "evox-images-bg-modified";
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onShowEVOXPhotosClick(event, rowData) {
        try {
            this.setState({
                evoxPhotoRowData: rowData,
                imageType: "Front"
            });
            this.props.applicationInsights.trackTrace({ message: "/EVOXImagesKBBEVOXMappingComponent/onShowEVOXPhotosClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/EVOXImages/GetEVOXVehicleOEMColors/" + rowData.vif_number)
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse.status) {
                        this.setState({
                            evoxVehicleOEMColors: parsedResponse,
                            selectedEVOXVehicleOEMColors: null
                        });
                    }
                    else {
                        let sortedOEMColors = CommonService.sortJsonData(parsedResponse, "whiteness", "desc");
                        this.setState({
                            evoxVehicleOEMColors: sortedOEMColors,
                            selectedEVOXVehicleOEMColors: sortedOEMColors[0]
                        });
                    }
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/EVOXImages/GetEVOXVehicleOEMColors", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelEVOXPhotosClick(event) {
        this.setState({
            evoxPhotoRowData: null,
            evoxVehicleOEMColors: null
        });
    }

    onShowKBBPhotosClick(event, rowData) {
        try {
            this.setState({
                kbbPhotoRowData: rowData,
                imageType: "Front"
            });
            this.props.applicationInsights.trackTrace({ message: "/EVOXImagesKBBEVOXMappingComponent/onShowKBBPhotosClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/EVOXImages/GetKBBVehicleOEMColors/" + rowData.vehicle_id)
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse.status) {
                        this.setState({
                            kbbVehicleOEMColors: parsedResponse,
                            selectedKBBVehicleOEMColors: null
                        });
                    }
                    else {
                        let sortedOEMColors = CommonService.sortJsonData(parsedResponse, "whiteness", "desc");
                        this.setState({
                            kbbVehicleOEMColors: sortedOEMColors,
                            selectedKBBVehicleOEMColors: sortedOEMColors[0]
                        });
                    }
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/EVOXImages/GetKBBVehicleOEMColors", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelKBBPhotosClick(event) {
        this.setState({
            kbbPhotoRowData: null,
            kbbVehicleOEMColors: null
        });
    }

    onRowKeyDown(event, rowData) {
        if (event.ctrlKey) {
            let charCode = String.fromCharCode(event.which).toLowerCase();
            switch (charCode) {
                case 's':
                    event.preventDefault();
                    this.onSaveClick(event, rowData);
                    break;

                case 'u':
                    if (rowData.vif_number !== rowData.originalVIFNumber) {
                        event.preventDefault();
                        this.onUndoClick(event, rowData);
                    }
                    break;

                case 'r':
                    if (rowData.vif_number) {
                        event.preventDefault();
                        this.onDeleteClick(event, rowData);
                    }
                    break;

                default:
                    break;
            }
        }
    }

    onVifNumberColumnCallback(rowData) {
        if (rowData.vif_number)
            return <Link to={"/EVOXImages/EVOXVehicles/" + rowData.vif_number}>{rowData.vif_number}</Link>;
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <EVOXImagesComponent
                applicationInsights={this.props.applicationInsights}
                vehicleOEMColors={this.state.evoxVehicleOEMColors}
                photoRowData={this.state.evoxPhotoRowData}
                imageBaseUrl={this.evoxImageBaseUrl}
                vehicleIdPropertyName="vif_number"
                imgUrlSeparator="/"
                imageExtension=""
                imageAlt="EVOX"
                imageContainer="download"
                imageBlobPrefix="EVOX/Image"
                titlePrefix="EVOX Photos"
                onCancelClick={this.onCancelEVOXPhotosClick}
                logPart2="EVOX image not found"
                onShowAlert={this.props.onShowAlert}
            />
            <EVOXImagesComponent
                applicationInsights={this.props.applicationInsights}
                vehicleOEMColors={this.state.kbbVehicleOEMColors}
                photoRowData={this.state.kbbPhotoRowData}
                imageBaseUrl={this.kbbImageBaseUrl}
                vehicleIdPropertyName="vehicle_id"
                imgUrlSeparator="."
                imageExtension=".png"
                imageAlt="CDN"
                imageContainer="image"
                imageBlobPrefix="EVOX"
                titlePrefix="CDN Photos"
                onCancelClick={this.onCancelKBBPhotosClick}
                logPart2="CDN image not found"
                onShowAlert={this.props.onShowAlert}
            />
            <DGDGModalDialogComponent
                applicationInsights={this.props.applicationInsights}
                showDialog={this.state.showConfirmation}
                cssName="dgdg-sell-trade-kbb-evox-save-dialog"
                title="Save KBB EVOX Mappings"
                onCancelClick={this.onCancelSaveAllClick}
            >
                <DGDGModalDialogHeaderButtonComponent>
                    <button className="btn btn-primary" onClick={this.onConfirmSaveAllClick}>
                        Confirm <span className="fas fa-save dgdg-icon" />
                    </button>
                </DGDGModalDialogHeaderButtonComponent>
                <div className="dgdg-report-body">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} cardCssName="dgdg-full-width" cardBlockCssName="overflow-auto" headerText={this.state.tableTitle} tableData={this.state.saveTableData} paintTable={this.state.savePaintTable}>
                        <DGDGTableHeaderComponent position="PreHeader">
                            <tr className="dgdg-table-v3-header" style={{ "borderBottom": "1px solid #DCDCDC" }}>
                                <td colSpan="2" className="text-center font-weight-bold">KBB</td>
                                <td colSpan="6" className="text-center font-weight-bold">EVOX</td>
                            </tr>
                        </DGDGTableHeaderComponent>
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="Id" dataColumn="vehicle_id" sortColumn="vehicle_id" filterColumn="vehicle_id" headerCssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="YMMT" dataColumn="ymmt" sortColumn="ymmt" filterColumn="ymmt" headerCssName="dgdg-sell-trade-kbb-ymmt-col" />
                            <DGDGTableColumnComponent headerText="YMMT" dataColumn="evox_display_name" sortColumn="evox_display_name" filterColumn="evox_display_name" headerCssName="dgdg-sell-trade-evox-ymmt-col" />
                            <DGDGTableColumnComponent headerText="Comment" dataColumn="comment" sortColumn="comment" filterColumn="comment" />
                            <DGDGTableColumnComponent headerText="VIF" dataColumn="vif_number" sortColumn="vif_number" filterColumn="vif_number" headerCssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Mapped By" sortColumn="vif_mapped_by" filterColumn="vif_mapped_by" headerCssName="text-nowrap" dataColumnCallback={this.onMappedByColumnCallback} />
                            <DGDGTableColumnComponent headerText="Copied Date" dataColumn="image_copied_date" sortColumn="image_copied_date" filterColumn="image_copied_date" headerCssName="text-nowrap" dataType="date" />
                            <DGDGTableColumnComponent headerText="Image Product" dataColumn="image_product_downloaded" sortColumn="image_product_downloaded" filterColumn="image_product_downloaded" headerCssName="text-nowrap" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
            </DGDGModalDialogComponent>
            <EVOXImagesKBBEVOXMappingQueryComponent
                applicationInsights={this.props.applicationInsights}
                isLoadingData={this.state.isLoadingData} {...this.props}
                onGetReportClick={this.onGetReportClick} onSaveAllClick={this.onSaveAllClick} onShowAlert={this.props.onShowAlert}
            />
            <div className="dgdg-site-scrollable-content">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable}
                    tablePageSize={CommonService.tablePageSize} stickyHeader>
                    <DGDGTableHeaderComponent position="PreHeader">
                        <tr className="dgdg-table-v3-header" style={{ "borderBottom": "1px solid #DCDCDC" }}>
                            <td />
                            <td colSpan="2" className="text-center font-weight-bold">KBB</td>
                            <td colSpan="8" className="text-center font-weight-bold">EVOX</td>
                        </tr>
                    </DGDGTableHeaderComponent>
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <DGDGTableColumnComponent headerText="Vehicle Id" dataColumn="vehicle_id" sortColumn="vehicle_id" filterColumn="vehicle_id" headerCssName="text-nowrap" />
                        <DGDGTableColumnComponent headerText="YMMT" dataColumn="ymmt" sortColumn="ymmt" filterColumn="ymmt" headerCssName="dgdg-sell-trade-kbb-ymmt-col" />
                        <DGDGTableColumnComponent headerText="YMMT" dataColumn="evox_display_name" sortColumn="evox_display_name" filterColumn="evox_display_name" dataColumnCallback={this.onVIFDataColumnCallback} headerCssName="dgdg-sell-trade-evox-ymmt-col" />
                        <DGDGTableColumnComponent headerText="Action" headerCssName="dgdg-sell-trade-kbb-evox-action-col" dataColumnCallback={this.onActionColumnCallback} />
                        <DGDGTableColumnComponent headerText="VIF" dataColumnCallback={this.onVifNumberColumnCallback} dataColumn="vif_number" sortColumn="vif_number" filterColumn="vif_number" headerCssName="text-nowrap" />
                        <DGDGTableColumnComponent headerText="Date Delivered" dataColumn="date_delivered" sortColumn="date_delivered" filterColumn="date_delivered" headerCssName="text-nowrap" dataType="date" />
                        <DGDGTableColumnComponent headerText="Mapped By" dataColumn="vif_mapped_by" sortColumn="vif_mapped_by" filterColumn="vif_mapped_by" headerCssName="text-nowrap" dataColumnCallback={this.onMappedByColumnCallback} />
                        <DGDGTableColumnComponent headerText="Copied Date" dataColumn="image_copied_date" sortColumn="image_copied_date" filterColumn="image_copied_date" headerCssName="text-nowrap" dataType="date" />
                        <DGDGTableColumnComponent headerText="Image Product" dataColumn="image_product_downloaded" sortColumn="image_product_downloaded" filterColumn="image_product_downloaded" headerCssName="text-nowrap" />
                        <DGDGTableColumnComponent headerText="Images" cssName="text-nowrap" dataColumnCallback={this.onPhotoColumnCallback} />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
        </div>;
    }
}