import React from "react";

import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGChartAreaComponent } from "../common/dgdgchart/DGDGChartAreaComponent";

export class DashboardSalesByVolumeComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            chartOption: {
                isStacked: true,
                hAxis: {
                    title: "Month",
                    format: "MM/y",
                    gridlines: {
                        color: 'transparent'
                    },
                    textStyle: {
                        fontSize: 10
                    },
                    titleTextStyle: {
                        fontSize: 12,
                        italic: false
                    }
                },
                vAxis: {
                    title: "Total Volume",
                    format: "#,###",
                    textStyle: {
                        fontSize: 10
                    },
                    titleTextStyle: {
                        fontSize: 12,
                        italic: false
                    }
                },
                legend: {
                    position: "top",
                    textStyle: {
                        fontSize: 12
                    }
                }
            }
        };
    }

    getDashboardData() {
        let filteredData = this.props.dashboardItem ? this.props.dashboardItem.data : null;

        if (filteredData && this.props.selectedStore && this.props.selectedStore.display_name !== "All") {

            filteredData = [];
            let filteredHeaderData = [];
            let selectedStoreIndex = 0;

            filteredHeaderData.push(this.props.dashboardItem.data[0][0]);

            this.props.dashboardItem.data[0].forEach((storeName, index) => {
                if (storeName === this.props.selectedStore.display_name) {
                    selectedStoreIndex = index;
                    filteredHeaderData.push(storeName);
                }
            });

            filteredData.push(filteredHeaderData);

            this.props.dashboardItem.data.slice(1).forEach((dataRow, index) => {
                let filteredRowData = [];
                filteredRowData.push(dataRow[0]);
                filteredRowData = filteredRowData.concat(dataRow.slice(selectedStoreIndex, selectedStoreIndex + 1));
                filteredData.push(filteredRowData);
            });
        }

        return filteredData;
    }

    render() {
        return <div className="dgdg-widget-item">
            <div className="d-flex">
                <DGDGFormDropdownComponent data={this.props.stores} itemKey="display_name" value={this.props.selectedStore ? this.props.selectedStore.display_name : "All"} onItemClick={this.props.onStoreClick} />
            </div>
            <div style={{ "height": "calc(100% - 55px)" }}>
                <DGDGChartAreaComponent chartData={this.getDashboardData()} chartOption={this.state.chartOption} isMaximized={this.props.isMaximized} />
            </div>
        </div>;
    }
}
