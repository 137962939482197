import React from "react";

import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

export class CPOInspectionQueryComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            if (state.showSpinner) {
                newState.showSpinner = props.isLoadingData || props.isDownLoading;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            vin: "",
            showSpinner: false
        };

        this.onTextChange = this.onTextChange.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
    }

    //componentDidMount() {
    //    try {
    //        if (this.props.vin) {
    //            this.setState({ vin: this.props.vin }, this.onViewClick);
    //        }

    //        CommonService.clientAzureStorageLog("CPOInspectionQueryComponent", "componentDidMount");
    //    }
    //    catch (error) {
    //        console.devError(error, null, this.props.applicationInsights);
    //    }
    //}

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event) {
        try {
            this.setState({ showSpinner: true });
            this.props.onGetReportClick(event, this.state.vin);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="card dgdg-card-gutter">
            <div className="card-block">
                <div className="form-inline">
                    <DGDGFormInputComponent id="vin" label="VIN" value={this.state.vin} onChange={this.onTextChange} onLookupClick={(event) => this.onGetReportClick(event)} />
                    <button disabled={this.state.showSpinner || !this.state.vin} className="btn btn-primary" onClick={(event) => this.onGetReportClick(event)}>
                        Get Report <span className="fas fa-redo-alt dgdg-icon" />
                    </button>
                    <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
                </div>
            </div>
        </div>;
    }
}
