import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { SalesStoreReportSummaryHeaderRowComponent } from "./SalesStoreReportSummaryHeaderRowComponent";
import { SalesStoreReportSummaryRowComponent } from "./SalesStoreReportSummaryRowComponent";
import { SalesStoreReportTitleHeaderRowComponent } from "./SalesStoreReportTitleHeaderRowComponent";
import { SalesStoreReportRowComponent } from "./SalesStoreReportRowComponent";
import { SalesStoreReportFooterRowComponent } from "./SalesStoreReportFooterRowComponent";
import { SalesStoreReportYTDQueryComponent } from "./SalesStoreReportYTDQueryComponent";

export class SalesStoreReportYTDComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            currentReportYear: null,
            totalStoreYTDData: null,
            newStoreYTDData: null,
            usedStoreYTDData: null,
            totalStoreYTDSummary: null,
            newStoreYTDSummary: null,
            usedStoreYTDSummary: null
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onCopyClick = this.onCopyClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    pageTitle = "Sales Store Report";

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/SalesStoreReport2/YTD", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("SalesStoreReportYTDComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getLastUpdatedDate(selectedYear) {
        try {
            let inputData = {
                startDate: CommonService.formatServerDateTime(selectedYear)
            };

            this.props.applicationInsights.trackTrace({ message: "/SalesStoreReportYTDComponent/getLastUpdatedDate", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SalesStoreReport2/GetLastUpdatedYTDReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (CommonService.isDateValid(parsedResponse)) {
                        this.props.onLastUpdatedDateChanged(moment.utc(parsedResponse));
                    }
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SalesStoreReport2/GetLastUpdatedYTDReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(selectedYear) {
        try {
            this.setState({ isLoadingData: true });
            this.getLastUpdatedDate(selectedYear);
            let inputData = { startDate: CommonService.formatServerDateTime(selectedYear) };

            this.props.applicationInsights.trackTrace({ message: "/SalesStoreReportYTDComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SalesStoreReport2/GetYTDReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let ttlStoreYTDData = parsedResponse.data.filter(row => row['stat'] === 'NUT' && row['store_number'] !== 99);
                    let ttlStoreYTDSummary = parsedResponse.data.filter(row => row['stat'] === 'NUT' && row['store_number'] === 99)[0];
                    let nwStoreYTDData = parsedResponse.data.filter(row => row['stat'] === 'New' && row['store_number'] !== 99);
                    let nwStoreYTDSummary = parsedResponse.data.filter(row => row['stat'] === 'New' && row['store_number'] === 99)[0];
                    let usdStoreYTDData = parsedResponse.data.filter(row => row['stat'] === 'Used' && row['store_number'] !== 99);
                    let usdStoreYTDSummary = parsedResponse.data.filter(row => row['stat'] === 'Used' && row['store_number'] === 99)[0];

                    this.setState({
                        isLoadingData: false,
                        currentReportYear: moment(parsedResponse.reportDate),
                        totalStoreYTDData: ttlStoreYTDData,
                        totalStoreYTDSummary: ttlStoreYTDSummary,
                        newStoreYTDData: nwStoreYTDData,
                        newStoreYTDSummary: nwStoreYTDSummary,
                        usedStoreYTDData: usdStoreYTDData,
                        usedStoreYTDSummary: usdStoreYTDSummary
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        currentReportYear: null,
                        totalStoreYTDData: null,
                        newStoreYTDData: null,
                        usedStoreYTDData: null,
                        totalStoreYTDSummary: null,
                        newStoreYTDSummary: null,
                        usedStoreYTDSummary: null
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SalesStoreReport2/GetYTDReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyClick(event) {
        try {
            let tableText = "\t\tTotal Sales\t\t\t\t\t\t\t\tTotal Sales Tracking Totals\t\t\tTotal Sales Tracking Year-Over-Year\r\n";
            tableText += "Store #\tStore Name\tVolume\tFront PVR\tTotal Front\tBack PVR\tTotal Back\tTotal PVR\tTotal Gross\t\tVolume Track\tGross Track\t\tVolume %▲\tTotal Gross %▲\tFront PVR $▲\tBack PVR $▲\r\n";

            if (this.state.totalStoreYTDData) {
                this.state.totalStoreYTDData.forEach((row) => {
                    tableText += (row.store_number ? row.store_number : "") + "\t" + (row.store_name ? row.store_name : "") + "\t" +
                        (row.volume ? row.volume : "") + "\t" + (row.front_pvr ? CommonService.formatCurrency(row.front_pvr) : "") + "\t" + (row.front_gross ? CommonService.formatCurrency(row.front_gross) : "") + "\t" +
                        (row.back_pvr ? CommonService.formatCurrency(row.back_pvr) : "") + "\t" + (row.back_gross ? CommonService.formatCurrency(row.back_gross) : "") + "\t" +
                        (row.total_pvr ? CommonService.formatCurrency(row.total_pvr) : "") + "\t" + (row.total_gross ? CommonService.formatCurrency(row.total_gross) : "") + "\t\t" +
                        (row.volume_track ? CommonService.formatNumber(row.volume_track) : "") + "\t" + (row.total_gross_track ? CommonService.formatCurrency(row.total_gross_track) : "") + "\t" +
                        (row.store_code ? row.store_code : "") + "\t" + (row.yoy_volume_delta_pct ? CommonService.formatPercentage(row.yoy_volume_delta_pct) : "") + "\t" +
                        (row.yoy_total_gross_delta_pct ? CommonService.formatPercentage(row.yoy_total_gross_delta_pct) : "") + "\t" + (row.yoy_front_pvr_delta ? CommonService.formatCurrency(row.yoy_front_pvr_delta) : "") + "\t" +
                        (row.yoy_back_pvr_delta ? CommonService.formatCurrency(row.yoy_back_pvr_delta) : "") + "\r\n";
                });
            }

            if (this.state.totalStoreYTDSummary) {
                tableText += (this.state.totalStoreYTDSummary.store_number ? this.state.totalStoreYTDSummary.store_number : "") + "\t" + (this.state.totalStoreYTDSummary.store_name ? this.state.totalStoreYTDSummary.store_name : "") +
                    "\t" + (this.state.totalStoreYTDSummary.volume ? this.state.totalStoreYTDSummary.volume : "")
                    + "\t" + (this.state.totalStoreYTDSummary.front_pvr ? CommonService.formatCurrency(this.state.totalStoreYTDSummary.front_pvr) : "")
                    + "\t" + (this.state.totalStoreYTDSummary.front_pvr ? CommonService.formatCurrency(this.state.totalStoreYTDSummary.front_gross) : "")
                    + "\t" + (this.state.totalStoreYTDSummary.back_pvr ? CommonService.formatCurrency(this.state.totalStoreYTDSummary.back_pvr) : "")
                    + "\t" + (this.state.totalStoreYTDSummary.back_gross ? CommonService.formatCurrency(this.state.totalStoreYTDSummary.back_gross) : "")
                    + "\t" + (this.state.totalStoreYTDSummary.total_pvr ? CommonService.formatCurrency(this.state.totalStoreYTDSummary.total_pvr) : "")
                    + "\t" + (this.state.totalStoreYTDSummary.total_gross ? CommonService.formatCurrency(this.state.totalStoreYTDSummary.total_gross) : "")
                    + "\t\t" + (this.state.totalStoreYTDSummary.volume_track ? CommonService.formatNumber(this.state.totalStoreYTDSummary.volume_track) : "")
                    + "\t" + (this.state.totalStoreYTDSummary.total_gross_track ? CommonService.formatCurrency(this.state.totalStoreYTDSummary.total_gross_track) : "")
                    + "\t\t" + (this.state.totalStoreYTDSummary.yoy_volume_delta_pct ? CommonService.formatPercentage(this.state.totalStoreYTDSummary.yoy_volume_delta_pct) : "")
                    + "\t" + (this.state.totalStoreYTDSummary.yoy_total_gross_delta_pct ? CommonService.formatPercentage(this.state.totalStoreYTDSummary.yoy_total_gross_delta_pct) : "")
                    + "\t" + (this.state.totalStoreYTDSummary.yoy_front_pvr_delta ? CommonService.formatCurrency(this.state.totalStoreYTDSummary.yoy_front_pvr_delta) : "")
                    + "\t" + (this.state.totalStoreYTDSummary.yoy_back_pvr_delta ? CommonService.formatCurrency(this.state.totalStoreYTDSummary.yoy_back_pvr_delta) : "");
            }

            tableText += "\n\n\t\tNew Sales\t\t\t\t\t\t\tTotal Sales Tracking Totals\t\t\t\tTotal Sales Tracking Year-Over-Year\r\n";
            tableText += "Store #\tStore Name\tVolume\tFront PVR\tTotal Front\tBack PVR\tTotal Back\tTotal PVR\tTotal Gross\tBudget Goal\tVolume Track\tGross Track\t\tVolume %▲\tTotal Gross %▲\tFront PVR $▲\tBack PVR $▲\r\n";
            if (this.state.newStoreYTDData) {
                this.state.newStoreYTDData.forEach((row) => {
                    tableText += (row.store_number ? row.store_number : "") + "\t" + (row.store_name ? row.store_name : "") + "\t" + (row.volume ? row.volume : "") + "\t" + (row.front_pvr ? CommonService.formatCurrency(row.front_pvr) : "") + "\t" + (row.front_gross ? CommonService.formatCurrency(row.front_gross) : "") + "\t" +
                        (row.back_pvr ? CommonService.formatCurrency(row.back_pvr) : "") + "\t" + (row.back_gross ? CommonService.formatCurrency(row.back_gross) : "") + "\t" +
                        (row.total_pvr ? CommonService.formatCurrency(row.total_pvr) : "") + "\t" + (row.total_gross ? CommonService.formatCurrency(row.total_gross) : "") + "\t" +
                        (row.volume_goal ? row.volume_goal : "") + "\t" + (row.volume_track ? CommonService.formatNumber(row.volume_track) : "") + "\t" +
                        (row.total_gross_track ? CommonService.formatCurrency(row.total_gross_track) : "") + "\t" + (row.store_code ? row.store_code : "") + "\t" +
                        (row.yoy_volume_delta_pct ? CommonService.formatPercentage(row.yoy_volume_delta_pct) : "") + "\t" + (row.yoy_total_gross_delta_pct ? CommonService.formatPercentage(row.yoy_total_gross_delta_pct) : "") + "\t" +
                        (row.yoy_front_pvr_delta ? CommonService.formatCurrency(row.yoy_front_pvr_delta) : "") + "\t" + (row.yoy_back_pvr_delta ? CommonService.formatCurrency(row.yoy_back_pvr_delta) : "") + "\r\n";
                });
            }

            if (this.state.newStoreYTDSummary) {
                tableText += (this.state.newStoreYTDSummary.store_number ? this.state.newStoreYTDSummary.store_number : "") + "\t" + (this.state.newStoreYTDSummary.store_name ? this.state.newStoreYTDSummary.store_name : "")
                    + "\t" + (this.state.newStoreYTDSummary.volume ? this.state.newStoreYTDSummary.volume : "")
                    + "\t" + (this.state.newStoreYTDSummary.front_pvr ? CommonService.formatCurrency(this.state.newStoreYTDSummary.front_pvr) : "")
                    + "\t" + (this.state.newStoreYTDSummary.front_pvr ? CommonService.formatCurrency(this.state.newStoreYTDSummary.front_gross) : "")
                    + "\t" + (this.state.newStoreYTDSummary.back_pvr ? CommonService.formatCurrency(this.state.newStoreYTDSummary.back_pvr) : "")
                    + "\t" + (this.state.newStoreYTDSummary.back_gross ? CommonService.formatCurrency(this.state.newStoreYTDSummary.back_gross) : "")
                    + "\t" + (this.state.newStoreYTDSummary.total_pvr ? CommonService.formatCurrency(this.state.newStoreYTDSummary.total_pvr) : "")
                    + "\t" + (this.state.newStoreYTDSummary.total_gross ? CommonService.formatCurrency(this.state.newStoreYTDSummary.total_gross) : "")
                    + "\t" + (this.state.newStoreYTDSummary.volume_goal ? this.state.newStoreYTDSummary.volume_goal : "")
                    + "\t" + (this.state.newStoreYTDSummary.volume_track ? CommonService.formatNumber(this.state.newStoreYTDSummary.volume_track) : "")
                    + "\t" + (this.state.newStoreYTDSummary.total_gross_track ? CommonService.formatCurrency(this.state.newStoreYTDSummary.total_gross_track) : "")
                    + "\t\t" + (this.state.newStoreYTDSummary.yoy_volume_delta_pct ? CommonService.formatPercentage(this.state.newStoreYTDSummary.yoy_volume_delta_pct) : "")
                    + "\t" + (this.state.newStoreYTDSummary.yoy_total_gross_delta_pct ? CommonService.formatPercentage(this.state.newStoreYTDSummary.yoy_total_gross_delta_pct) : "")
                    + "\t" + (this.state.newStoreYTDSummary.yoy_front_pvr_delta ? CommonService.formatCurrency(this.state.newStoreYTDSummary.yoy_front_pvr_delta) : "")
                    + "\t" + (this.state.newStoreYTDSummary.yoy_back_pvr_delta ? CommonService.formatCurrency(this.state.newStoreYTDSummary.yoy_back_pvr_delta) : "");
            }

            tableText += "\n\n\t\tUsed Sales\t\t\t\t\t\t\tTotal Sales Tracking Totals\t\t\t\tTotal Sales Tracking Year-Over-Year\r\n";
            tableText += "Store #\tStore Name\tVolume\tFront PVR\tTotal Front\tBack PVR\tTotal Back\tTotal PVR\tTotal Gross\tBudget Goal\tVolume Track\tGross Track\t\tVolume %▲\tTotal Gross %▲\tFront PVR $▲\tBack PVR $▲\r\n";
            if (this.state.usedStoreYTDData) {
                this.state.usedStoreYTDData.forEach((row) => {
                    tableText += (row.store_number ? row.store_number : "") + "\t" + (row.store_name ? row.store_name : "") + "\t" + (row.volume ? row.volume : "") + "\t" + (row.front_pvr ? CommonService.formatCurrency(row.front_pvr) : "") + "\t" + (row.front_gross ? CommonService.formatCurrency(row.front_gross) : "") + "\t" +
                        (row.back_pvr ? CommonService.formatCurrency(row.back_pvr) : "") + "\t" + (row.back_gross ? CommonService.formatCurrency(row.back_gross) : "") + "\t" +
                        (row.total_pvr ? CommonService.formatCurrency(row.total_pvr) : "") + "\t" + (row.total_gross ? CommonService.formatCurrency(row.total_gross) : "") + "\t" +
                        (row.volume_goal ? row.volume_goal : "") + "\t" + (row.volume_track ? CommonService.formatNumber(row.volume_track) : "") + "\t" +
                        (row.total_gross_track ? CommonService.formatCurrency(row.total_gross_track) : "") + "\t" + (row.store_code ? row.store_code : "") + "\t" +
                        (row.yoy_volume_delta_pct ? CommonService.formatPercentage(row.yoy_volume_delta_pct) : "") + "\t" + (row.yoy_total_gross_delta_pct ? CommonService.formatPercentage(row.yoy_total_gross_delta_pct) : "") + "\t" +
                        (row.yoy_front_pvr_delta ? CommonService.formatCurrency(row.yoy_front_pvr_delta) : "") + "\t" + (row.yoy_back_pvr_delta ? CommonService.formatCurrency(row.yoy_back_pvr_delta) : "") + "\r\n";
                });
            }

            if (this.state.usedStoreYTDSummary) {
                tableText += (this.state.usedStoreYTDSummary.store_number ? this.state.usedStoreYTDSummary.store_number : "") + "\t" + (this.state.usedStoreYTDSummary.store_name ? this.state.usedStoreYTDSummary.store_name : "")
                    + "\t" + (this.state.usedStoreYTDSummary.volume ? this.state.usedStoreYTDSummary.volume : "")
                    + "\t" + (this.state.usedStoreYTDSummary.front_pvr ? CommonService.formatCurrency(this.state.usedStoreYTDSummary.front_pvr) : "")
                    + "\t" + (this.state.usedStoreYTDSummary.front_pvr ? CommonService.formatCurrency(this.state.usedStoreYTDSummary.front_gross) : "")
                    + "\t" + (this.state.usedStoreYTDSummary.back_pvr ? CommonService.formatCurrency(this.state.usedStoreYTDSummary.back_pvr) : "")
                    + "\t" + (this.state.usedStoreYTDSummary.back_gross ? CommonService.formatCurrency(this.state.usedStoreYTDSummary.back_gross) : "")
                    + "\t" + (this.state.usedStoreYTDSummary.total_pvr ? CommonService.formatCurrency(this.state.usedStoreYTDSummary.total_pvr) : "")
                    + "\t" + (this.state.usedStoreYTDSummary.total_gross ? CommonService.formatCurrency(this.state.usedStoreYTDSummary.total_gross) : "")
                    + "\t" + (this.state.usedStoreYTDSummary.volume_goal ? this.state.usedStoreYTDSummary.volume_goal : "")
                    + "\t" + (this.state.usedStoreYTDSummary.volume_track ? CommonService.formatNumber(this.state.usedStoreYTDSummary.volume_track) : "")
                    + "\t" + (this.state.usedStoreYTDSummary.total_gross_track ? CommonService.formatCurrency(this.state.usedStoreYTDSummary.total_gross_track) : "")
                    + "\t\t" + (this.state.usedStoreYTDSummary.yoy_volume_delta_pct ? CommonService.formatPercentage(this.state.usedStoreYTDSummary.yoy_volume_delta_pct) : "")
                    + "\t" + (this.state.usedStoreYTDSummary.yoy_total_gross_delta_pct ? CommonService.formatPercentage(this.state.usedStoreYTDSummary.yoy_total_gross_delta_pct) : "")
                    + "\t" + (this.state.usedStoreYTDSummary.yoy_front_pvr_delta ? CommonService.formatCurrency(this.state.usedStoreYTDSummary.yoy_front_pvr_delta) : "")
                    + "\t" + (this.state.usedStoreYTDSummary.yoy_back_pvr_delta ? CommonService.formatCurrency(this.state.usedStoreYTDSummary.yoy_back_pvr_delta) : "");
            }
            navigator.clipboard.writeText(tableText);
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("SalesStoreReportYTDComponent", "onCopyClick", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        let totalStoreYTDRow = [];
        if (this.state.totalStoreYTDData) {
            this.state.totalStoreYTDData.forEach((data, index) => {
                totalStoreYTDRow.push(<SalesStoreReportRowComponent key={"totalStoreYTDRow" + index} title="Total Sales" rowIndex={index} rowData={data} />);
            });
        }

        let newStoreYTDRow = [];
        if (this.state.newStoreYTDData) {
            this.state.newStoreYTDData.forEach((data, index) => {
                newStoreYTDRow.push(<SalesStoreReportRowComponent key={"newStoreYTDRow" + index} title="New Sales" rowIndex={index} rowData={data} />);
            });
        }

        let usedStoreYTDRow = [];
        if (this.state.usedStoreYTDData) {
            this.state.usedStoreYTDData.forEach((data, index) => {
                usedStoreYTDRow.push(<SalesStoreReportRowComponent key={"usedStoreYTDRow" + index} title="Used Sales" rowIndex={index} rowData={data} />);
            });
        }

        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <SalesStoreReportYTDQueryComponent
                applicationInsights={this.props.applicationInsights}
                years={this.props.years}
                isLoadingData={this.state.isLoadingData}
                onGetReportClick={this.onGetReportClick}
                onCopyClick={this.onCopyClick}
            />
            <div className="dgdg-sales-store dgdg-site-scrollable-content">
                <table className="dgdg-table-v3 text-center">
                    <tbody>
                        <SalesStoreReportSummaryHeaderRowComponent reportMonth={this.state.currentReportYear} />
                        <SalesStoreReportSummaryRowComponent title="TOTAL" summary={this.state.totalStoreYTDSummary} />
                        <SalesStoreReportSummaryRowComponent title="NEW" summary={this.state.newStoreYTDSummary} />
                        <SalesStoreReportSummaryRowComponent title="USED" summary={this.state.usedStoreYTDSummary} />
                        <tr>
                            <td><div className="dgdg-card-gutter" /></td>
                        </tr>
                        <SalesStoreReportTitleHeaderRowComponent title="Total Sales" />
                        {totalStoreYTDRow}
                        <tr>
                            <td />
                        </tr>
                        <SalesStoreReportFooterRowComponent title="TOTAL" summary={this.state.totalStoreYTDSummary} />
                        <tr>
                            <td><div className="dgdg-card-gutter" /></td>
                        </tr>
                        <SalesStoreReportTitleHeaderRowComponent title="New Sales" />
                        {newStoreYTDRow}
                        <tr>
                            <td />
                        </tr>
                        <SalesStoreReportFooterRowComponent title="NEW" summary={this.state.newStoreYTDSummary} />
                        <tr>
                            <td><div className="dgdg-card-gutter" /></td>
                        </tr>
                        <SalesStoreReportTitleHeaderRowComponent title="Used Sales" />
                        {usedStoreYTDRow}
                        <tr>
                            <td />
                        </tr>
                        <SalesStoreReportFooterRowComponent title="USED" summary={this.state.usedStoreYTDSummary} />
                    </tbody>
                </table>
            </div>
        </div>;
    }
}
