import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";

import { BuyCenterManheimComponent } from "./BuyCenterManheimComponent";

export class BuyCenterRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visibleTab: "Manheim"
        };

        this.onChangeTab = this.onChangeTab.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/BuyCenter", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("BuyCenterRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Buy Center";

    onChangeTab(event, tabName) {
        try {
            this.setState({ visibleTab: tabName });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-flex dgdg-flex-column">
            <nav className="nav nav-pills dgdg-tab">
                <button type="button" className={"nav-item nav-link" + (this.state.visibleTab === "Manheim" ? " btn btn-dark active" : "")} onClick={(event) => this.onChangeTab(event, "Manheim")}>Manheim</button>
            </nav>
            <div className="dgdg-flex-item dgdg-site-content" style={{ display: this.state.visibleTab === "Manheim" ? "block" : "none" }}>
                <BuyCenterManheimComponent onShowAlert={this.props.onShowAlert} {...this.props} />
            </div>
        </DGDGPageComponent>;
    }
}
