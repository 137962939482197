import React from "react";

import { DGDGFormComboBoxComponent } from "../common/dgdgcombobox/DGDGFormComboBoxComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";

export class FIPayrollEmployeeAddComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSpinner: false,
            isSaveEnabled: false,
            id: null,
            employee: null,
            store: null,
            employees: null,
            employeeList: null,
            storeList: null,
            selectedEmployee: null,
            selectedStore: null,
            selectedPositionCode: null
        };

        this.onEmployeeChange = this.onEmployeeChange.bind(this);
        this.onEmployeeClick = this.onEmployeeClick.bind(this);
        this.onStoreChange = this.onStoreChange.bind(this);
        this.onStoreClick = this.onStoreClick.bind(this);
        this.onPositionCodeClick = this.onPositionCodeClick.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.employeeList && this.state.employeeList === null && this.props.stores && this.state.storeList === null) {
                this.setState({
                    employeeList: this.props.employeeList,
                    storeList: this.props.stores
                });
            }

            if (this.props.rowData != null && this.props.rowData !== prevProps.rowData && this.props.employeeList && this.props.stores) {
                let selectedPositionCode = this.positionoCodes.filter(positionCodeRow => {
                    return positionCodeRow.value === this.props.rowData.override_position_code;
                });

                let selectedEmployee = this.props.employeeList.filter(employeeRow => {
                    return employeeRow.number === this.props.rowData.number;
                });

                let selectedStore = this.props.stores.filter(storeRow => {
                    return storeRow.number === this.props.rowData.override_store_number;
                });

                this.setState({
                    id: this.props.rowData.id,
                    employee: selectedEmployee[0].employee,
                    selectedEmployee: selectedEmployee[0],
                    selectedPositionCode: selectedPositionCode[0],
                    store: selectedStore[0].display_name,
                    selectedStore: selectedStore[0]
                });
            }
        }

        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Add Custom F&I Payroll Employee";

    positionoCodes = [
        { key: "FIDirector", value: "F&I Director" },
        { key: "FIManager", value: " F&I Manager" }
    ];

    onEmployeeChange(id, filterValue) {
        try {
            let filteredData = [];
            if (this.props.employeeList) {
                filteredData = this.props.employeeList.filter(employeeRow => {
                    return employeeRow.employee.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0
                });
            }

            this.setState({
                employee: filterValue,
                employeeList: filteredData
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onEmployeeClick(id, selectedEmployee) {
        try {
            this.setState({
                selectedEmployee: selectedEmployee,
                employee: selectedEmployee.employee
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStoreChange(id, filterValue) {
        try {
            let filteredData = [];
            if (this.props.stores) {
                filteredData = this.props.stores.filter(storeRow => {
                    return storeRow.display_name.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0
                });
            }

            this.setState({
                store: filterValue,
                storeList: filteredData
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStoreClick(id, selectedStore) {
        try {
            this.setState({
                selectedStore: selectedStore,
                store: selectedStore.display_name
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPositionCodeClick(id, positionCode) {
        try {
            this.setState({
                selectedPositionCode: positionCode,
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event) {
        try {
            this.props.onSaveClick(event, this.state.id, this.state.selectedEmployee.number, this.state.selectedStore.number, this.state.selectedPositionCode.value)

            this.onCancelClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                id: null,
                employee: null,
                selectedEmployee: null,
                store: null,
                selectedStore: null,
                selectedPositionCode: null
            });

            this.props.onCancelClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.props.showDialog}
            cssName="dgdg-fi-payroll-employee-add-dialog"
            title={this.props.title}
            onCancelClick={this.onCancelClick}
        >
            <DGDGModalDialogHeaderButtonComponent>
                <button className="btn btn-primary" onClick={this.onSaveClick} disabled={this.state.selectedPositionCode === null || !this.state.employee || !this.state.store} style={{ "marginLeft": "15px" }}>
                    Save  <i className="fas fa-save dgdg-icon" />
                </button>
            </DGDGModalDialogHeaderButtonComponent>
            <div className="dgdg-report-body">
                <div className="card">
                    <div className="card-block">
                        <table className="text-nowrap">
                            <tbody>
                                <tr>
                                    <td className="dgdg-font-blue">
                                        Employee:<span className="text-danger">*</span>
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td>
                                        <DGDGFormComboBoxComponent id="ddpEmployee" data={this.state.employeeList} itemKey="employee" value={this.state.employee} onItemClick={this.onEmployeeClick} onItemChange={this.onEmployeeChange} autoDrop cssName="dgdg-fi-payroll-add-employee-column" />
                                    </td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        Override Store Number:<span className="text-danger">*</span>
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td>
                                        <DGDGFormComboBoxComponent id="ddpStore" data={this.state.storeList} itemKey="display_name" value={this.state.store} onItemClick={this.onStoreClick} onItemChange={this.onStoreChange} autoDrop cssName="dgdg-fi-payroll-add-store-column" />
                                    </td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        Override Position Code:<span className="text-danger">*</span>
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td>
                                        <DGDGFormDropdownComponent data={this.positionoCodes} itemKey="value" value={this.state.selectedPositionCode ? this.state.selectedPositionCode.value : "Select Position Code"} onItemClick={this.onPositionCodeClick} cssName="dgdg-fi-payroll-add-employee-position-code-column" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </DGDGModalDialogComponent>;
    }
}
