import React, { Fragment } from "react";
import preval from "preval.macro"
import moment from "moment";

import { CommonService } from "../../js_modules/dgdgjs/CommonService";

const buildDate = preval`
module.exports = (new Date()).toUTCString();
`;

export class DGDGFooterComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            paintTable: false
        };
    }

    render() {
        return <Fragment>
            <div className="fixed-bottom dgdg-footer-container">
                <div className="float-left">
                    <div className="d-inline-block" style={{ "lineHeight": "24px", "marginLeft": "5px" }}>
                        &copy; {moment().year()} - Del Grande Dealer Group
                    </div>
                </div>
                <div className="float-right">
                    {
                        this.props.canShowDevOptions
                            ? <div className="d-inline-block dgdg-desktop">
                                Instance: {this.props.instanceId}
                            </div>
                            : null
                    }
                    <div className="d-inline-block dgdg-icon">Built: {CommonService.formatDateTimeZone(buildDate)}</div>
                </div>
            </div>
        </Fragment>;
    }
}