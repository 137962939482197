import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGFormCalendarComponent } from "../common/dgdgcalendar/DGDGFormCalendarComponent";
import { TechnicianScoreCardRouteComponent } from "./TechnicianScoreCardRouteComponent";
import { DGDGQueryComponent } from "../common/dgdgquery/DGDGQueryComponent";
export class TechnicianScoreCardDayQueryComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedStore: null,
            startDate: null
        };

        this.onStoreClick = this.onStoreClick.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.stores && this.state.selectedStore === null) {
                let selectedStore = this.props.stores[0];
                let cookieStoreNumber = CommonService.getCookie(TechnicianScoreCardRouteComponent.StoreNumberCookie);
                if (cookieStoreNumber) {
                    cookieStoreNumber = parseInt(cookieStoreNumber);
                    let filterStore = this.props.stores.filter(store => store.store_number === cookieStoreNumber);
                    if (filterStore.length === 1) {
                        selectedStore = filterStore[0];
                    }
                }
                this.setState({
                    selectedStore: selectedStore,
                    startDate: moment().subtract(1, "days")
                }, this.onGetReportClick);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStoreClick(event, store) {
        try {
            this.setState({ selectedStore: store });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDateChange(id, value) {
        try {
            this.setState({ [id]: moment(value) });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event) {
        try {
            if (CommonService.isDateValid(this.state.startDate)) {
                CommonService.setCookie(TechnicianScoreCardRouteComponent.StoreNumberCookie, this.state.selectedStore.store_number, 30);
                this.props.onGetReportClick(this.state.startDate, this.state.selectedStore);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGetReportClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGQueryComponent cssName="dgdg-card-gutter">
            <DGDGFormDropdownComponent label="Store #" itemKey="display_name" data={this.props.stores} value={this.state.selectedStore ? this.state.selectedStore.display_name : "Select Store"} onItemClick={this.onStoreClick} onKeyDown={this.onKeyDown} />
            <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="startDate" label="Date" value={this.state.startDate} onDateChange={this.onDateChange} maxDate={moment().subtract(1, "days").format("MM/DD/YYYY")} onKeyDown={this.onKeyDown} />
            <button disabled={this.props.isLoadingData} className="btn btn-primary dgdg-icon" onClick={(event) => this.onGetReportClick(event)}>
                Get Report <i className="fas fa-redo-alt dgdg-icon" />
            </button>
            <DGDGSpinnerComponent showSpinner={this.props.isLoadingData} />
        </DGDGQueryComponent>;
    }
}
