import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGTableV2Component } from "../common/dgdgtable/DGDGTableV2Component";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableCustomComponent } from "../common/dgdgtable/DGDGTableCustomComponent";

import { TechnicianEmployeePayEditComponent } from "./TechnicianEmployeePayEditComponent";
import { TechnicianPayrollDetailComponent } from "./TechnicianPayrollDetailComponent";
import { TechnicianPayrollDetailEditComponent } from "./TechnicianPayrollDetailEditComponent";
import { TechnicianPayrollQueryComponent } from "./TechnicianPayrollQueryComponent";

export class TechnicianPayrollRouteComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoadingData: false,
            isDownLoading: false,
            isProcessingAdjustments: false,
            lastUpdatedDate: null,
            tableData: null,
            months: CommonService.getMonths(),
            stores: null,
            selectedSetupMonth: null,
            selectedSetupStore: null,
            selectedEmployee: null,
            selectedPayPeriod: 1,
            payrollDetail: null,
            isPayrollEditEnabled: true,
            paintTable: false,
            onLoadMessage: "Select Store, Month and click Get Payroll",
            canProcess: false
        };

        this.onPayPlanEditCellClick = this.onPayPlanEditCellClick.bind(this);
        this.onPayPlanSaveClick = this.onPayPlanSaveClick.bind(this);
        this.onPayPlanCancelClick = this.onPayPlanCancelClick.bind(this);
        this.onPayrollDetailEditCellClick = this.onPayrollDetailEditCellClick.bind(this);
        this.onPayrollDetailSaveClick = this.onPayrollDetailSaveClick.bind(this);
        this.onPayrollDetailCancelClick = this.onPayrollDetailCancelClick.bind(this);
        this.onGetPayrollClick = this.onGetPayrollClick.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onProcessPayrollDataClick = this.onProcessPayrollDataClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/TechnicianPayroll", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getStores();
            this.getLastUpdatedDate();
            CommonService.clientAzureStorageLog("TechnicianPayrollRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Technician Payroll";

    getStores() {
        this.props.applicationInsights.trackTrace({ message: "/TechnicianPayrollRouteComponent/getStores", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/TechnicianPayroll/GetStores")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({
                    stores: parsedResponse
                });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TeamMemberProfile/GetStores", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    getLastUpdatedDate() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/TechnicianPayrollRouteComponent/getLastUpdatedDate", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/TechnicianPayroll/GetLastUpdated")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let lastUpdatedDate = "";
                    if (CommonService.isDateValid(parsedResponse))
                        lastUpdatedDate = moment.utc(parsedResponse)
                    this.setState({ lastUpdatedDate: lastUpdatedDate });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TeamMemberProfile/GetLastUpdated", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPayPlanEditCellClick(event, rowData) {
        try {
            if (event.stopPropagation) {
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
            }
            else if (window.event) {
                window.event.cancelBubble = true;
            }

            this.setState({ selectedEmployee: rowData });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPayPlanSaveClick(event) {
        try {
            this.onPayPlanCancelClick(event);
            this.onGetPayrollClick(this.state.selectedSetupMonth, this.state.selectedSetupStore);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPayPlanCancelClick(event) {
        try {
            this.setState({ selectedEmployee: null });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPayrollDetailEditCellClick(event, rowData) {
        if (event.stopPropagation) {
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
        }
        else if (window.event) {
            window.event.cancelBubble = true;
        }

        this.setState({
            payrollDetail: rowData
        });
    }

    onPayrollDetailSaveClick(event) {
        this.onPayrollDetailCancelClick(event);
        this.onGetPayrollClick(this.state.selectedSetupMonth, this.state.selectedSetupStore, this.state.selectedPayPeriod);
    }

    onPayrollDetailCancelClick(event) {
        this.setState({
            payrollDetail: null
        });
    }

    onGetPayrollClick(selectedMonth, selectedStore, selectedPayPeriod) {
        if (selectedMonth !== null && selectedStore !== null) {
            this.setState({
                onLoadMessage: null,
                isLoadingData: true,
                selectedPayPeriod: selectedPayPeriod,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            try {
                let inputData = {
                    month: CommonService.formatServerDateTime(selectedMonth),
                    storeNumber: selectedStore.store_number
                };

                this.props.applicationInsights.trackTrace({ message: "/TechnicianPayrollRouteComponent/onGetPayrollClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                fetch("/TechnicianPayroll/GetTechnicianPayroll", {
                    method: "POST",
                    headers: {
                        'Content-Type': "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData)
                })
                    .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                    .then(parsedResponse => {
                        let tableData = CommonService.addOrdinalColumn(parsedResponse.data);
                        tableData.forEach((rowData) => {
                            rowData.disable_edit = !rowData.can_edit;
                        });
                        this.setState({
                            isLoadingData: false,
                            tableData: tableData,
                            paintTable: true,
                            selectedSetupMonth: selectedMonth,
                            selectedSetupStore: selectedStore,
                            isPayrollEditEnabled: moment(selectedMonth) > moment().startOf("month").subtract(2, "month"),
                            canProcess: parsedResponse.canProcess
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                    })
                    .catch(notOKResponse => {
                        this.setState({
                            isLoadingData: false,
                            tableData: [],
                            paintTable: true
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TeamMemberProfile/GetTechnicianPayroll", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                    console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.devError(jsonParseError, null, this.props.applicationInsights);
                                });
                        }
                    });
            }
            catch (error) {
                console.devError(error, null, this.props.applicationInsights);
            }
        }
    }

    onTextChange(id, value) {
        this.setState({ [id]: value });
    }

    onDownloadClick(selectedMonth, selectedStore, selectedPayPeriod) {
        try {
            this.setState({ isDownLoading: true });
            let inputData = {
                storeNumber: selectedStore.store_number,
                month: CommonService.formatServerDateTime(selectedMonth),
                payPeriod: selectedPayPeriod
            };

            this.props.applicationInsights.trackTrace({ message: "/TechnicianPayrollRouteComponent/onDownloadClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/TechnicianPayroll/Download", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response; } else { throw response; } })
                .then(response => {
                    CommonService.downloadBlob(response);
                    this.setState({ isDownLoading: false });
                })
                .catch(notOKResponse => {
                    this.setState({ isDownLoading: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TeamMemberProfile/Download", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onProcessPayrollDataClick(event, selectedMonth, selectedPayPeriod) {
        try {
            this.setState({ isProcessingAdjustments: true });
            let inputData = {
                month: CommonService.formatServerDateTime(selectedMonth),
                payPeriod: selectedPayPeriod
            };
            this.props.applicationInsights.trackTrace({ message: "/TechnicianPayrollRouteComponent/onProcessPayrollDataClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/TechnicianPayroll/ProcessPayrollData", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({ isProcessingAdjustments: false });
                    this.props.onShowAlert("success", { message: "Payroll processed" });
                })
                .catch(notOKResponse => {
                    this.setState({ isProcessingAdjustments: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TeamMemberProfile/ProcessPayrollData", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-flex dgdg-flex-column">
            <TechnicianEmployeePayEditComponent
                applicationInsights={this.props.applicationInsights}
                employee={this.state.selectedEmployee}
                onSaveClick={this.onPayPlanSaveClick}
                onCancelClick={this.onPayPlanCancelClick}
                onShowAlert={this.props.onShowAlert}
            />
            <TechnicianPayrollDetailEditComponent
                applicationInsights={this.props.applicationInsights}
                payPeriod={this.state.selectedPayPeriod}
                payrollDetail={this.state.payrollDetail}
                payrollMonth={this.state.selectedSetupMonth}
                onSaveClick={this.onPayrollDetailSaveClick}
                onCancelClick={this.onPayrollDetailCancelClick}
                onShowAlert={this.props.onShowAlert}
            />
            <div className="mt-auto">
                <span className="float-right badge badge-primary dgdg-badge">
                    Last Updated Date: {this.state.lastUpdatedDate ? CommonService.formatDateTimeZone(this.state.lastUpdatedDate) : ""}
                </span>
            </div>
            <div className="dgdg-flex-item dgdg-site-content">
                <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
                    <TechnicianPayrollQueryComponent
                        applicationInsights={this.props.applicationInsights}
                        canProcess={this.state.canProcess}
                        stores={this.state.stores}
                        months={this.state.months}
                        onGetPayrollClick={this.onGetPayrollClick}
                        isProcessingAdjustments={this.state.isProcessingAdjustments}
                        onProcessPayrollDataClick={this.onProcessPayrollDataClick}
                        isDownLoading={this.state.isDownLoading}
                        onDownloadClick={this.onDownloadClick}
                    />
                    <div className="dgdg-site-scrollable-content">
                        <div className="card">
                            <div className="card-header">Technician Payroll Setup</div>
                            <div className="card-block">
                                {
                                    (this.state.onLoadMessage)
                                        ? <div className="card card-header dgdg-payroll-bg-no-load text-center font-weight-bold">
                                            {this.state.onLoadMessage}
                                        </div>
                                        : <DGDGTableV2Component applicationInsights={this.props.applicationInsights} tableData={this.state.tableData} defaultSortColumn={CommonService.ordinalColumnName} defaultSortOrder="Asc" showSpinner={this.state.isLoadingData} paintTable={this.state.paintTable}>
                                            <DGDGTableRowComponent>
                                                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                                <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_name_web" sortColumn="store_number" filterColumn="store_name_web" cssName="text-nowrap" />
                                                <DGDGTableColumnComponent headerText="Employee Number" dataColumn="employee_number" sortColumn="employee_number" filterColumn="employee_number" />
                                                <DGDGTableColumnComponent headerText="Employee Name" dataColumn="employee_name" sortColumn="employee_name" filterColumn="employee_name" />
                                                <DGDGTableColumnComponent headerText="Job Title" dataColumn="job_title" sortColumn="job_title" filterColumn="job_title" cssName="text-nowrap" />
                                                <DGDGTableColumnComponent headerText="Default Plan Date" dataColumn="emp_plan_date" sortColumn="emp_plan_date" filterColumn="emp_plan_date" dataType="date" />
                                                <DGDGTableColumnComponent headerText="Default Plan Name" dataColumn="emp_plan_name" sortColumn="emp_plan_name" filterColumn="emp_plan_name" cssName="text-nowrap" />
                                                <DGDGTableColumnComponent headerText="Default Base Hourly Rate" dataColumn="emp_base_hourly_rate" sortColumn="emp_base_hourly_rate" filterColumn="emp_base_hourly_rate" dataType="rate" />
                                                <DGDGTableColumnComponent headerText="Default Flat Rate" dataColumn="emp_flat_rate" sortColumn="emp_flat_rate" filterColumn="emp_flat_rate" dataType="rate" />
                                                <DGDGTableColumnComponent headerText="Default Training Rate" dataColumn="emp_training_labor_rate" sortColumn="emp_training_labor_rate" filterColumn="emp_training_labor_rate" dataType="rate" />
                                                <DGDGTableColumnComponent headerText="Edit" cssName="text-right" dataText="<span class='fas fa-edit' />" disabledColumn="disable_edit" onCellClick={this.onPayPlanEditCellClick} />
                                            </DGDGTableRowComponent>
                                            <DGDGTableCustomComponent>
                                                <TechnicianPayrollDetailComponent
                                                    payPeriod={this.state.selectedPayPeriod}
                                                    payrollMonth={this.state.selectedSetupMonth}
                                                    isEditEnabled={this.state.isPayrollEditEnabled}
                                                    onEditClick={this.onPayrollDetailEditCellClick}
                                                    onShowAlert={this.props.onShowAlert} />
                                            </DGDGTableCustomComponent>
                                        </DGDGTableV2Component>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}
