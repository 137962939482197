import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableCustomRowComponent } from "../common/dgdgtable/DGDGTableCustomRowComponent";

import { FIPayrollPlan2EditComponent } from "./FIPayrollPlan2EditComponent";

export class FIPayrollPlan2Component extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            managerPlanTableData: [],
            directorPlanTableData: [],
            isLoadingData: false,
            paintTable: false,
            plans: null,
            selectedPlan: null,
            planType: "",
            showPlanDialog: false,
            isSaveComplete: false
        };

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        this.onScrollClick = this.onScrollClick.bind(this);
        this.onPlanDetailRowCallback = this.onPlanDetailRowCallback.bind(this);
        this.onManagerPlanEditCellClick = this.onManagerPlanEditCellClick.bind(this);
        this.onDirectorPlanEditCellClick = this.onDirectorPlanEditCellClick.bind(this);
        this.onPlanCancelClick = this.onPlanCancelClick.bind(this);
        this.onPlanSaveClick = this.onPlanSaveClick.bind(this);
    }

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/FIPayroll/Plans", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getPlans();
            CommonService.clientAzureStorageLog("FIPayrollPlan2Component", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onScrollClick(event, selector) {
        try {
            CommonService.smoothScrollTo(".dgdg-fi-payroll-plan2", selector);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPlanDetailRowCallback(rowComponent, rowData, rowIndex) {
        let rows = [];
        rows.push(<tr key={"plan-header" + rowIndex} className="text-center dgdg-fi-payroll-plan-font">
            <td />
            <td>
                Tier
            </td>
            <td>
                Base Bonus
            </td>
            <td>
                Bonus with DMV
            </td>
            <td>
                PVR
            </td>
            <td>
                PPR
            </td>
            <td>
                VSC
            </td>
            <td>
                Maintenance
            </td>
            <td>
                CSI
            </td>
            <td>
                CIT
            </td>
            <td>
                EV Product Increment
            </td>
            <td>
                EV Gross Increment
            </td>
            <td>
                Hourly
            </td>
        </tr>);

        rowData.plan.forEach((row, index) => {
            row.addtionalColumn = index === 0 ? true : false
            rows.push(<tr key={"plan-row" + index} className="text-center">
                <td />
                <td>
                    {row.tier}
                </td>
                <td>
                    {CommonService.formatPercentage_2(row.base_bonus)}
                </td>
                <td>
                    {CommonService.formatPercentage_2(row.bonus_with_dmv)}
                </td>
                <td>
                    {CommonService.formatCurrency_2(row.pvr)}
                </td>
                <td>
                    {CommonService.formatNumber_2(row.ppr)}
                </td>
                <td>
                    {CommonService.formatPercentage_2(row.vsc)}
                </td>
                <td>
                    {CommonService.formatPercentage_2(row.maintenance)}
                </td>
                <td>
                    {row.addtionalColumn ? CommonService.formatPercentage_2(row.csi) : null}
                </td>
                <td>
                    {row.addtionalColumn ? CommonService.formatPercentage_2(row.cit) : null}
                </td>
                <td>
                    {row.addtionalColumn ? CommonService.formatNumber_2(row.ev_product_increment) : null}
                </td>
                <td>
                    {row.addtionalColumn ? CommonService.formatCurrency_2(row.ev_gross_increment) : null}
                </td>
                <td>
                    {row.addtionalColumn ? row.hourly : null}
                </td>
            </tr>)
        })

        return rows;
    }

    onManagerPlanEditCellClick(event, rowData) {
        try {
            if (event.stopPropagation) {
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
            }
            else if (window.event) {
                window.event.cancelBubble = true;
            }

            this.setState({
                planType: "Manager Plan",
                selectedPlan: rowData,
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDirectorPlanEditCellClick(event, rowData) {
        try {
            if (event.stopPropagation) {
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
            }
            else if (window.event) {
                window.event.cancelBubble = true;
            }

            this.setState({
                planType: "Director Plan",
                selectedPlan: rowData
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPlanSaveClick(event) {
        try {
            this.setState({ isSaveComplete: false });
            this.props.applicationInsights.trackTrace({ message: "FIPayrollPlan2Component/onPlanSaveClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FIPayroll/SaveFIPayrollPayPlan2", {
                method: "POST",
                headers: {
                    'Accept': "application/json",
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: "'" + JSON.stringify(this.state.plans) + "'"
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isSaveComplete: true,
                        selectedPlan: null
                    }, () => {
                        this.getPlans();
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FIPayroll/onPlanSaveClick", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPlanCancelClick(event) {
        try {
            this.setState({ selectedPlan: null });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getPlans() {
        this.setState({
            isLoadingData: true,
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
        });
        try {
            this.props.applicationInsights.trackTrace({ message: "FIPayrollPlan2Component/getPlans", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FIPayroll/GetFIPayrollPayPlan2", {
                method: "GET",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false,
                        plans: parsedResponse,
                        managerPlanTableData: parsedResponse.manager_plans,
                        directorPlanTableData: parsedResponse.director_plans,
                        paintTable: true,
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        managerPlanTableData: [],
                        directorPlanTableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FIPayroll/GetFIPayrollPayPlan2", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "F&I Payroll";

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <FIPayrollPlan2EditComponent
                applicationInsights={this.props.applicationInsights}
                planDetail={this.state.selectedPlan}
                planType={this.state.planType}
                isSaveComplete={this.state.isSaveComplete}
                onSaveClick={this.onPlanSaveClick}
                onCancelClick={this.onPlanCancelClick}
                onShowAlert={this.props.onShowAlert}
            />
            <nav className="nav nav-pills dgdg-nav">
                <button type="button" className="btn btn-link nav-item nav-link dgdg-quick-link dgdg-quick-link-bg" onClick={(event) => this.onScrollClick(event, "#manager_plans")}>Manager Plan</button>
                <button type="button" className="btn btn-link nav-item nav-link dgdg-quick-link dgdg-quick-link-bg" onClick={(event) => this.onScrollClick(event, "#director_plans")}>Director Plan</button>
            </nav>
            <div className="dgdg-fi-payroll-plan2 dgdg-site-scrollable-content">
                <div id="manager_plans" className="card dgdg-card-gutter">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} tableData={this.state.managerPlanTableData} showSpinner={this.state.isLoadingData} paintTable={this.state.paintTable} headerText="Manager Plan">
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="Plan Name" dataColumn="plan_name" sortColumn="plan_name" filterColumn="plan_name" headerCssName="dgdg-fi-payroll-plan-table-textbox-width" cssName=" dgdg-fi-payroll-plan-font" />
                            <DGDGTableColumnComponent headerText="Plan Detail" cssName="text-right" dataText="<span class='fas fa-edit' />" onCellClick={this.onManagerPlanEditCellClick} colSpan="12" headerColSpan="12" />
                        </DGDGTableRowComponent>
                        <DGDGTableCustomRowComponent onGetCustomRowsCallback={this.onPlanDetailRowCallback} />
                    </DGDGTableV3Component>
                </div>
                <div id="director_plans" className="card">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} tableData={this.state.directorPlanTableData} showSpinner={this.state.isLoadingData} paintTable={this.state.paintTable} headerText="Director Plan">
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="Plan Name" dataColumn="plan_name" sortColumn="plan_name" filterColumn="plan_name" headerCssName="dgdg-fi-payroll-plan-table-textbox-width" cssName=" dgdg-fi-payroll-plan-font" />
                            <DGDGTableColumnComponent headerText="Plan Detail" cssName="text-right" dataText="<span class='fas fa-edit' />" onCellClick={this.onManagerPlanEditCellClick} colSpan="12" headerColSpan="12" />
                        </DGDGTableRowComponent>
                        <DGDGTableCustomRowComponent onGetCustomRowsCallback={this.onPlanDetailRowCallback} />
                    </DGDGTableV3Component>
                </div>
            </div>
        </div>;
    }
}
