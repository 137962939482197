import React from "react";

import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

export class OnboardingQueryComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            if (state.showSpinner) {
                newState.showSpinner = props.isLoadingData;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            filterValue: ""
        };

        this.onTextChange = this.onTextChange.bind(this);
        this.onLookupClick = this.onLookupClick.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this);
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onLookupClick(id, value) {
        try {
            this.setState({
                filterValue: value,
                showSpinner: true
            });
            this.props.onLookupClick(id, value);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSearchClick(event) {
        this.onLookupClick(event, this.state.filterValue);
    }

    render() {
        return <div className="card dgdg-card-gutter">
            <div className="card-block">
                <div className="form-inline">
                    <DGDGFormInputComponent id="filterValue" label="User Principal Name" value={this.state.filterValue} onChange={this.onTextChange} onLookupClick={this.onLookupClick} />
                    <button className="btn btn-primary dgdg-icon" onClick={(event) => this.onSearchClick(event)}>
                        Search<i className="far fa-search dgdg-icon" />
                    </button>
                    <button disabled={this.state.showSpinner} className="btn btn-primary dgdg-icon" onClick={(event) => this.props.onAddUserClick(event)}>
                        Add User <span className="fas fa-plus dgdg-icon" />
                    </button>
                    <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
                </div>
            </div>
        </div>
    }
}
