import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";

export class MissingTitlesEditComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSpinner: false,
            id: null,
            issue: "",
            dialogTitle: "Edit Missing Title"
        };

        this.onTextChange = this.onTextChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.rowData !== null && this.props.rowData !== prevProps.rowData) {
                let dialogTitle = "Edit Missing Titke - " + this.props.rowData.store_web_name + ", " + this.props.rowData.stock_number;
                this.setState({
                    id: this.props.rowData.id,
                    issue: this.props.rowData.issue,
                    dialogTitle: dialogTitle
                });

            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Edit Missing Titles";

    onTextChange(id, value) {
        try {
            this.setState({
                [id]: value
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event) {
        try {
            this.props.onSaveClick(event, {
                storeNumber: this.props.rowData.store_number,
                stockNumber: this.props.rowData.stock_number,
                issue: this.state.issue
            });
            this.onCancelClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                issue: "",
                dialogTitle: "Edit Missing Title",
            });

            this.props.onCancelClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.props.showDialog}
            cssName="dgdg-missing-title-edit-dialog"
            title={this.state.dialogTitle}
            onCancelClick={this.onCancelClick}
        >
            <DGDGModalDialogHeaderButtonComponent>
                <button className="btn btn-primary" onClick={this.onSaveClick} >
                    Save <i className="fas fa-save dgdg-icon" />
                </button>
            </DGDGModalDialogHeaderButtonComponent>
            <div className="dgdg-report-body">
                <div className="card">
                    <div className="card-block">
                        <table className="text-nowrap">
                            <tbody>
                                <tr>
                                    <td className="dgdg-font-blue">
                                        Store Name:<span className="text-danger">*</span>
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormDropdownComponent data={this.props.stores} disabled itemKey="display_name" value={this.props.rowData?.store_web_name} />
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        Stock #:<span className="text-danger">*</span>
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent id="stockNumber" value={this.props.rowData?.stock_number} disabled />
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        VIN:
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent id="vin" value={this.props.rowData?.vin} disabled />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dgdg-font-blue">
                                        YMM:
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent id="ymm" value={this.props.rowData?.ymm} disabled />
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        Entry Date:
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent id="ymm" value={this.props.rowData?.entry_date ? CommonService.formatDate(moment(this.props.rowData.entry_date)) : ""} disabled />
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        Age:
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent id="ymm" value={this.props.rowData?.age} disabled />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dgdg-font-blue">
                                        Inventory Status:
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent id="inventory_status" value={this.props.rowData?.cdk_status} disabled />
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        Missing Title Status:
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent id="missingTitleStatus" value={this.props.rowData?.missing_title_status} disabled />
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        Deal Number:
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent id="deal_number_date" value={this.props.rowData?.deal_number_date} disabled />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dgdg-font-blue">
                                        Issue:
                                    </td>
                                    <td />
                                    <td colSpan="5" >
                                        <DGDGFormInputComponent id="issue" value={this.state.issue} onChange={this.onTextChange} />
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        Last Updated By:
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent id="lastUpdatedBy" value={this.props.rowData?.last_updated_by} readOnly />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div >
        </DGDGModalDialogComponent >;
    }
}
