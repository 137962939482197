import React from "react";

import { DGDGChartColumnComponent } from "../common/dgdgchart/DGDGChartColumnComponent";

export class OpenROsSummaryStoreStatusChartComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chartOption: {
                series: {
                    0: {
                        targetAxisIndex: 0,
                        color: '#5184ea'
                    },
                    1: {
                        targetAxisIndex: 0,
                        color: '#ff5c35'
                    },
                    2: {
                        targetAxisIndex: 0,
                        color: '#ffc51e'
                    },
                    3: {
                        targetAxisIndex: 0,
                        color: '#43e44c'
                    }
                },
                hAxis: {
                    title: "Store",
                    gridlines: {
                        color: 'transparent'
                    },
                    textStyle: {
                        fontSize: 10
                    },
                    titleTextStyle: {
                        fontSize: 12,
                        italic: false
                    },
                    textPosition: "out",
                    slantedText: false
                },
                vAxes: {
                    0: {
                        title: "Count",
                        textStyle: {
                            fontSize: 10
                        },
                        titleTextStyle: {
                            fontSize: 12,
                            italic: false
                        },
                        format: "#"
                    }
                },
                legend: {
                    textStyle: {
                        fontSize: 12
                    },
                    position: "top"
                },
                tooltip: {
                    textStyle: {
                        fontSize: 10
                    },
                    isHtml: true
                },
                intervals: {
                    style: 'boxes',
                    color: 'black',
                    boxWidth: 0.8,
                    lineWidth: 0.2,
                    fillOpacity: 0.4
                }
            }
        };
    }

    getChartData() {
        if (!this.props.chartData || !this.props.stores)
            return null;

        let chartData = [];
        let headerRow = [
            "Store Name",
            "Holding Parts", { type: 'number', role: 'interval' }, { type: 'number', role: 'interval' }, { type: 'string', role: 'tooltip', p: { html: true } },
            "Holding Authorization", { type: 'number', role: 'interval' }, { type: 'number', role: 'interval' }, { type: 'string', role: 'tooltip', p: { html: true } },
            "In Progress", { type: 'number', role: 'interval' }, { type: 'number', role: 'interval' }, { type: 'string', role: 'tooltip', p: { html: true } },
            "Other", { type: 'number', role: 'interval' }, { type: 'number', role: 'interval' }, { type: 'string', role: 'tooltip', p: { html: true } }
        ];
        chartData.push(headerRow);

        this.props.stores.forEach(store => {
            let holdingPartsItem = this.props.chartData.filter(item => (item.store_number === store.store_number && item.status === "Holding Parts"));
            let holdingPartsCount = holdingPartsItem.length === 0 ? 0 : holdingPartsItem[0].count;
            let holdingPartsCount_est_doc_gt_5 = holdingPartsItem.length === 0 ? 0 : holdingPartsItem[0].over_5_days_est_completion_date_count;
            let holdingPartsTooltipHtml = this.getTooltipHtml(holdingPartsItem.length > 0 ? holdingPartsItem[0].store_web_name : "", "Holding Parts", holdingPartsCount, holdingPartsCount_est_doc_gt_5);

            let holdingAuthorizationItem = this.props.chartData.filter(item => (item.store_number === store.store_number && item.status === "Holding Authorization"));
            let holdingAuthorizationCount = holdingAuthorizationItem.length === 0 ? 0 : holdingAuthorizationItem[0].count;
            let holdingAuthorizationCount_est_doc_gt_5 = holdingAuthorizationItem.length === 0 ? 0 : holdingAuthorizationItem[0].over_5_days_est_completion_date_count;
            let holdingAuthorizationTooltipHtml = this.getTooltipHtml(holdingAuthorizationItem.length > 0 ? holdingAuthorizationItem[0].store_web_name : "", "Holding Authorization", holdingAuthorizationCount, holdingAuthorizationCount_est_doc_gt_5);

            let inProgressItem = this.props.chartData.filter(item => (item.store_number === store.store_number && item.status === "In Progress"));
            let inProgressCount = inProgressItem.length === 0 ? 0 : inProgressItem[0].count;
            let inProgressCount_est_doc_gt_5 = inProgressItem.length === 0 ? 0 : inProgressItem[0].over_5_days_est_completion_date_count;
            let inProgressTooltipHtml = this.getTooltipHtml(inProgressItem.length > 0 ? inProgressItem[0].store_web_name : "", "In Progress", inProgressCount, inProgressCount_est_doc_gt_5);

            let otherItem = this.props.chartData.filter(item => (item.store_number === store.store_number && item.status === "Other"));
            let otherCount = otherItem.length === 0 ? 0 : otherItem[0].count;
            let otherCount_est_doc_gt_5 = otherItem.length === 0 ? 0 : otherItem[0].over_5_days_est_completion_date_count;
            let otherTooltipHtml = this.getTooltipHtml(otherItem.length > 0 ? otherItem[0].store_web_name : "", "Other", otherCount, otherCount_est_doc_gt_5);

            let rowData = [
                store.number + " | " + store.code,
                holdingPartsCount, (holdingPartsCount - holdingPartsCount_est_doc_gt_5), holdingPartsCount, holdingPartsTooltipHtml,
                holdingAuthorizationCount, (holdingAuthorizationCount - holdingAuthorizationCount_est_doc_gt_5), holdingAuthorizationCount, holdingAuthorizationTooltipHtml,
                inProgressCount, (inProgressCount - inProgressCount_est_doc_gt_5), inProgressCount, inProgressTooltipHtml,
                otherCount, (otherCount - otherCount_est_doc_gt_5), otherCount, otherTooltipHtml
            ]

            chartData.push(rowData);
        })

        return chartData;
    }

    getTooltipHtml(storeName, status, count, est_doc_gt_5_count) {
        return "<div style='padding:5px; min-width:150px;'><span style='font-size:12px'><b>" + storeName + "</b></span><br />" + status + ": <b>" + count + "</b><br />Estimated DOC > 5 days: <b>" + est_doc_gt_5_count + "</b></div>";
    }

    render() {
        return <div className="dgdg-widget-item">
            <div style={{ "height": "calc(100% - 20px)" }}>
                <DGDGChartColumnComponent chartData={this.getChartData()} chartOption={this.state.chartOption} isMaximized={this.props.isMaximized} />
            </div>
        </div>;
    }
}
