import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";

export class WorkFlowRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };

        this.onTextChange = this.onTextChange.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Work Flow";

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/Workflow", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("WorkFlowRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

    }

    render() {
        return <DGDGPageComponent>
            <div className="dgdg-report-body">
                <div className="dgdg-site-scrollable-content">
                    <div className="card">
                        <div className="card-block">
                            <div className="form-inline">
                                <a className="btn btn-primary" href={"https://apps.powerapps.com/play/dad6020c-9c9b-4b6b-9b7a-8ce896d1602b?tenantId=5130a840-e60d-4236-a32f-19de6ccb8e2e&source=portal&screenColor=rgba(0%2C%20176%2C%20240%2C%201)&hidenavbar=true&source=application&emailId=" + this.state.emailId} rel="noreferrer" target="_blank">Send Email</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}
