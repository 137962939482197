import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

export class USWarrantyReconciliationSalesDealDetailsComponent extends React.Component {
    render() {
        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.props.showDialog} title={this.props.tableTitle}
            cssName="dgdg-us-warranty-reconciliation-sales-deal-dialog"
            onCancelClick={this.props.onCancelClick}
        >
            <div className="dgdg-report-body">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} cardCssName="dgdg-full-width" cardBlockCssName="overflow-auto" tableData={this.props.tableData} tableTitl={this.props.tableTitle} defaultSortColumn={CommonService.ordinalColumnName} defaultSortOrder="Asc" paintTable={this.props.paintTable}
                    copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], onCopyTableCallBack: this.props.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <DGDGTableColumnComponent headerText="Store" dataColumn="store_web_name" filterColumn="store_web_name" sortColumn="store_number" />
                        <DGDGTableColumnComponent headerText="Deal Number" dataColumn="deal_number" sortColumn="deal_number" filterColumn="deal_number" />
                        <DGDGTableColumnComponent headerText="Customer Number" dataColumn="customer_number" sortColumn="customer_number" filterColumn="customer_number" headerCssName="dgdg-us-warranty-reconciliation-posting-date-col" />
                        <DGDGTableColumnComponent headerText="Customer Name" dataColumn="customer_name" sortColumn="customer_name" filterColumn="customer_name" />
                        <DGDGTableColumnComponent headerText="Stock Number" dataColumn="stock_number" sortColumn="stock_number" filterColumn="stock_number" />
                        <DGDGTableColumnComponent headerText="VIN" dataColumn="vin" sortColumn="vin" filterColumn="vin" />
                        <DGDGTableColumnComponent headerText="VSC Name" dataColumn="vsc_name" sortColumn="vsc_name" filterColumn="vsc_name" />
                        <DGDGTableColumnComponent headerText="VSC Sale Amount" dataColumn="vsc_sale_amount" sortColumn="vsc_sale_amount" filterColumn="vsc_sale_amount" dataType="money_2" />
                        <DGDGTableColumnComponent headerText="Gap Sale Amount" dataColumn="gap_sale_amount" sortColumn="gap_sale_amount" filterColumn="gap_sale_amount" dataType="money_2" />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
        </DGDGModalDialogComponent>;
    }
}
