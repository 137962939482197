import React, { Fragment } from "react";
import moment from "moment";
import $ from "jquery";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { UsedReconditioningAuditQueryComponent } from "./UsedReconditioningAuditQueryComponent";
import { DGDGFormCalendarComponent } from "../common/dgdgcalendar/DGDGFormCalendarComponent";
import { DGDGModalConfirmationComponent } from "../common/dgdgmodalconfirmation/DGDGModalConfirmationComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

export class UsedReconditioningAuditComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            tableTitle: "",
            tableData: [],
            serviceInDate: null,
            bodyInDate: null,
            detailInDate: null,
            reconInDate: null,
            photoInDate: null,
            serviceOutDate: null,
            bodyOutDate: null,
            detailOutDate: null,
            reconOutDate: null,
            photoOutDate: null,
            isInEditRole: false,

            undoDetail: null,
            actionName: null,
            metricName: null
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onSaveInventoryReconInDateClick = this.onSaveInventoryReconInDateClick.bind(this);
        this.onSaveInventoryReconOutDateClick = this.onSaveInventoryReconOutDateClick.bind(this);
        this.onShowDateEditClick = this.onShowDateEditClick.bind(this);
        this.HideEditDate = this.HideEditDate.bind(this);

        this.onUndoInventoryClick = this.onUndoInventoryClick.bind(this);
        this.onUndoOkClick = this.onUndoOkClick.bind(this);
        this.onCancelUndoClick = this.onCancelUndoClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    pageTitle = "Used Car Prep";

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/UsedReconditioningAuditComponent/Audit", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("UsedReconditioningAuditComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getComments(comments) {
        let htmlContent = [];
        if (comments !== null) {
            let commentParts = [];
            comments.split('\r').forEach(function (value) {
                commentParts.push(value.split(': '));
            });

            commentParts.forEach((commentPart) => {
                if (commentPart[1]) {
                    htmlContent.push(
                        <Fragment>
                            <span key="comment_part1" style={{ "color": "blue" }}>
                                {(commentPart[0] !== "" ? commentPart[0] + ": " : null)}
                            </span>
                            <span key="comment_part2">
                                {(commentPart[1])}
                            </span>
                        </Fragment>);
                    htmlContent.push(<br />);
                }
            });
        }

        return htmlContent;
    }

    onGetReportClick(vin, stockNumber) {
        try {
            vin = vin === "" || vin === undefined ? null : vin;
            stockNumber = stockNumber === "" || stockNumber === undefined ? null : stockNumber;
            this.setState({
                isLoadingData: true
            });

            let inputData = {
                vin: vin,
                stockNumber: stockNumber
            };
            this.props.applicationInsights.trackTrace({ message: "/UsedReconditioningAuditComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/UsedReconditioning/GetAuditReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse.result.length !== 0) {
                        parsedResponse.result[0].is_wholesale = parsedResponse.result[0].is_wholesale !== null ? parsedResponse.result[0].is_wholesale === true ? "Yes" : "No" : null;
                        parsedResponse.result[0].is_cpo = parsedResponse.result[0].is_cpo !== null ? parsedResponse.result[0].is_cpo === true ? "Yes" : "No" : null;
                        parsedResponse.result[0].recon_interior_only = parsedResponse.result[0].recon_interior_only !== null ? parsedResponse.result[0].recon_interior_only === true ? "True" : "False" : null;
                        parsedResponse.result[0].comments = this.getComments(parsedResponse.result[0].comments);
                        parsedResponse.result[0].entrydate = parsedResponse.result[0].entrydate !== null ? CommonService.formatDate(parsedResponse.result[0].entrydate) : null;
                        parsedResponse.result[0].smog_in_date_time = parsedResponse.result[0].smog_in_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].smog_in_date_time) : null;
                        parsedResponse.result[0].smog_out_date_time = parsedResponse.result[0].smog_out_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].smog_out_date_time) : null;
                        parsedResponse.result[0].vin_in_date_time = parsedResponse.result[0].vin_in_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].vin_in_date_time) : null;
                        parsedResponse.result[0].vin_out_date_time = parsedResponse.result[0].vin_out_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].vin_out_date_time) : null;
                        parsedResponse.result[0].weight_in_date_time = parsedResponse.result[0].weight_in_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].weight_in_date_time) : null;
                        parsedResponse.result[0].weight_out_date_time = parsedResponse.result[0].weight_out_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].weight_out_date_time) : null;
                        parsedResponse.result[0].d_in_date_time = parsedResponse.result[0].d_in_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].d_in_date_time) : null;
                        parsedResponse.result[0].d_out_date_time = parsedResponse.result[0].d_out_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].d_out_date_time) : null;
                        parsedResponse.result[0].prav_in_date_time = parsedResponse.result[0].prav_in_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].prav_in_date_time) : null;
                        parsedResponse.result[0].prav_out_date_time = parsedResponse.result[0].prav_out_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].prav_out_date_time) : null;
                        parsedResponse.result[0].prtord_in_date_time = parsedResponse.result[0].prtord_in_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].prtord_in_date_time) : null;
                        parsedResponse.result[0].prtord_out_date_time = parsedResponse.result[0].prtord_out_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].prtord_out_date_time) : null;
                        parsedResponse.result[0].pa_in_date_time = parsedResponse.result[0].pa_in_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].pa_in_date_time) : null;
                        parsedResponse.result[0].pa_out_date_time = parsedResponse.result[0].pa_out_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].pa_out_date_time) : null;
                        parsedResponse.result[0].nh_in_date_time = parsedResponse.result[0].nh_in_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].nh_in_date_time) : null;
                        parsedResponse.result[0].nh_out_date_time = parsedResponse.result[0].nh_out_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].nh_out_date_time) : null;
                        parsedResponse.result[0].parts_in_date_time = parsedResponse.result[0].parts_in_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].parts_in_date_time) : null;
                        parsedResponse.result[0].parts_out_date_time = parsedResponse.result[0].parts_out_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].parts_out_date_time) : null;
                        parsedResponse.result[0].bo_in_date_time = parsedResponse.result[0].bo_in_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].bo_in_date_time) : null;
                        parsedResponse.result[0].bo_out_date_time = parsedResponse.result[0].bo_out_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].bo_out_date_time) : null;
                        parsedResponse.result[0].monitor_in_date_time = parsedResponse.result[0].monitor_in_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].monitor_in_date_time) : null;
                        parsedResponse.result[0].monitor_out_date_time = parsedResponse.result[0].monitor_out_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].monitor_out_date_time) : null;
                        parsedResponse.result[0].dr_in_date_time = parsedResponse.result[0].dr_in_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].dr_in_date_time) : null;
                        parsedResponse.result[0].dr_out_date_time = parsedResponse.result[0].dr_out_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].dr_out_date_time) : null;
                        parsedResponse.result[0].vendor_in_date_time = parsedResponse.result[0].vendor_in_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].vendor_in_date_time) : null;
                        parsedResponse.result[0].vendor_out_date_time = parsedResponse.result[0].vendor_out_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].vendor_out_date_time) : null;
                        parsedResponse.result[0].auth_in_date_time = parsedResponse.result[0].auth_in_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].auth_in_date_time) : null;
                        parsedResponse.result[0].auth_out_date_time = parsedResponse.result[0].auth_out_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].auth_out_date_time) : null;
                        parsedResponse.result[0].pt_in_date_time = parsedResponse.result[0].pt_in_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].pt_in_date_time) : null;
                        parsedResponse.result[0].pt_out_date_time = parsedResponse.result[0].pt_out_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].pt_out_date_time) : null;
                        parsedResponse.result[0].other_in_date_time = parsedResponse.result[0].other_in_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].other_in_date_time) : null;
                        parsedResponse.result[0].other_out_date_time = parsedResponse.result[0].other_out_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].other_out_date_time) : null;
                        parsedResponse.result[0].service_in_date_time = parsedResponse.result[0].service_in_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].service_in_date_time) : null;
                        parsedResponse.result[0].service_out_date_time = parsedResponse.result[0].service_out_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].service_out_date_time) : null;
                        parsedResponse.result[0].body_in_date_time = parsedResponse.result[0].body_in_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].body_in_date_time) : null;
                        parsedResponse.result[0].body_out_date_time = parsedResponse.result[0].body_out_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].body_out_date_time) : null;
                        parsedResponse.result[0].paint_in_date_time = parsedResponse.result[0].paint_in_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].paint_in_date_time) : null;
                        parsedResponse.result[0].paint_out_date_time = parsedResponse.result[0].paint_out_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].paint_out_date_time) : null;
                        parsedResponse.result[0].detail_in_date_time = parsedResponse.result[0].detail_in_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].detail_in_date_time) : null;
                        parsedResponse.result[0].detail_out_date_time = parsedResponse.result[0].detail_out_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].detail_out_date_time) : null;
                        parsedResponse.result[0].recon_in_date_time = parsedResponse.result[0].recon_in_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].recon_in_date_time) : null;
                        parsedResponse.result[0].recon_out_date_time = parsedResponse.result[0].recon_out_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].recon_out_date_time) : null;
                        parsedResponse.result[0].photo_in_date_time = parsedResponse.result[0].photo_in_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].photo_in_date_time) : null;
                        parsedResponse.result[0].photo_out_date_time = parsedResponse.result[0].photo_out_date_time !== null ? CommonService.formatDateTime(parsedResponse.result[0].photo_out_date_time) : null;
                        parsedResponse.result[0].can_undo_photo_out_date_time = parsedResponse.result[0].photo_out_date_time !== null ? true : false;
                        parsedResponse.result[0].can_undo_recon_out_date_time = !parsedResponse.result[0].can_undo_photo_out_date_time && parsedResponse.result[0].recon_out_date_time !== null ? true : false;
                        parsedResponse.result[0].can_undo_body_out_date_time = !parsedResponse.result[0].can_undo_photo_out_date_time && !parsedResponse.result[0].can_undo_recon_out_date_time && parsedResponse.result[0].body_out_date_time !== null ? true : false;
                        parsedResponse.result[0].can_undo_detail_out_date_time = !parsedResponse.result[0].can_undo_photo_out_date_time && !parsedResponse.result[0].can_undo_recon_out_date_time && !parsedResponse.result[0].can_undo_body_out_date_time && parsedResponse.result[0].detail_out_date_time !== null ? true : false;
                        parsedResponse.result[0].can_undo_service_out_date_time = !parsedResponse.result[0].can_undo_photo_out_date_time && !parsedResponse.result[0].can_undo_recon_out_date_time && !parsedResponse.result[0].can_undo_body_out_date_time && !parsedResponse.result[0].can_undo_detail_out_date_time && parsedResponse.result[0].service_out_date_time !== null ? true : false;
                    }
                    this.setState({
                        tableTitle: parsedResponse.length !== 0 ? "Stock Number: " + parsedResponse.result[0].stock_number + " - VIN: " + parsedResponse.result[0].vin : null,
                        tableData: CommonService.addOrdinalColumn(parsedResponse.result),
                        isInEditRole: parsedResponse.isInEditRole,
                        isLoadingData: false
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: "",
                        tableData: []
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedReconditioning/GetAuditReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDateChange(id, value) {
        try {
            this.setState({ [id]: moment(value) });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveInventoryReconInDateClick(value, metricName) {
        try {
            let inputData = {
                storeNumber: this.state.tableData[0].store_number,
                stockNumber: this.state.tableData[0].stock_number,
                vin: this.state.tableData[0].vin,
                year: this.state.tableData[0].year,
                make: this.state.tableData[0].make,
                model: this.state.tableData[0].model,
                metricName: metricName,
                dateTime: value
            }
            this.props.applicationInsights.trackTrace({ message: "/UsedReconditioningAuditComponent/onSaveInventoryReconInDateClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/UsedReconditioning/SaveInventoryReconAuditInDate", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.props.onShowAlert("success", { message: metricName + " In Date saved" }, 4000);
                    this.onGetReportClick(this.state.tableData[0].vin, this.state.tableData[0].stockNumber)
                    this.HideEditDate(metricName + "InEdit")
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedReconditioning/SaveInventoryReconAuditInDate", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveInventoryReconOutDateClick(value, metricName) {
        try {
            let inputData = {
                storeNumber: this.state.tableData[0].store_number,
                stockNumber: this.state.tableData[0].stock_number,
                vin: this.state.tableData[0].vin,
                year: this.state.tableData[0].year,
                make: this.state.tableData[0].make,
                model: this.state.tableData[0].model,
                metricName: metricName,
                dateTime: value
            }
            this.props.applicationInsights.trackTrace({ message: "/UsedReconditioningAuditComponent/onSaveInventoryReconOutDateClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/UsedReconditioning/SaveInventoryReconAuditOutDate", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.props.onShowAlert("success", { message: metricName + " Out Date saved" }, 4000);
                    this.onGetReportClick(this.state.tableData[0].vin, this.state.tableData[0].stockNumber)
                    this.HideEditDate(metricName + "OutEdit")
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedReconditioning/SaveInventoryReconAuditOutDate", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onShowDateEditClick(event, id) {
        $("#" + id).addClass("d-inline-flex");
    }

    HideEditDate(id) {
        $("#" + id).removeClass("d-inline-flex");
        $("#" + id).css("display", "none");
    }

    onUndoInventoryClick(event, rowData, actionName, metricName) {
        try {
            rowData[metricName.toLowerCase() + "_showSpinner"] = true;
            this.setState({
                undoDetail: rowData,
                actionName: actionName,
                metricName: metricName
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onUndoOkClick(event) {
        let inputData = {
            storeNumber: this.state.undoDetail.store_number,
            stockNumber: this.state.undoDetail.stock_number,
            vin: this.state.undoDetail.vin,
            year: this.state.undoDetail.year,
            make: this.state.undoDetail.make,
            model: this.state.undoDetail.model,
            inDateTime: this.state.metricName.toLowerCase() === "service" ? null : this.state.undoDetail[this.state.metricName.toLowerCase() + "_in_date_time"],
            outDateTime: this.state.undoDetail[this.state.metricName.toLowerCase() + "_out_date_time"],
            outBy: this.state.undoDetail[this.state.metricName.toLowerCase() + "_out_by"]
        }
        switch (this.state.actionName) {
            case "Service Out":
                this.saveService(inputData, this.state.undoDetail);
                break;
            case "Detail Out":
                inputData.outMetricName = this.state.undoDetail.body_in_date_time == null ? "Recon" : "Body";
                this.saveDetail(inputData, this.state.undoDetail);
                break;
            case "Body Out":
                this.saveBody(inputData, this.state.undoDetail);
                break;
            case "Recon Out":
                this.saveRecon(inputData, this.state.undoDetail);
                break;
            case "Photo Out":
                this.savePhoto(inputData, this.state.undoDetail);
                break;
            default:
                this.onCancelUndoClick();
                break;
        }
        this.onCancelUndoClick();
    }

    onCancelUndoClick(event) {
        try {
            this.setState({
                undoDetail: null,
                actionName: null,
                metricName: null
            })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    saveService(inputData, rowData) {
        let tableData = this.state.tableData;
        try {
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            this.props.applicationInsights.trackTrace({ message: "/UsedReconditioningInventoryComponent/saveService", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            return fetch("/UsedReconditioning/UndoInventoryReconServiceOut", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    rowData.service_showSpinner = false;
                    rowData.detail_in_date_time = inputData.dateTime;
                    rowData.detail_in_by = inputData.dateTime ? this.props.remoteUpn : null;
                    rowData.service_in_date_time = inputData.inDateTime;
                    rowData.service_out_date_time = inputData.dateTime;
                    rowData.service_out_by = inputData.dateTime ? this.props.remoteUpn : null;
                    rowData.can_undo_service_out_date_time = inputData.dateTime ? true : false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedReconditioning/UndoInventoryReconServiceOut", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    saveDetail(inputData, rowData) {
        let tableData = this.state.tableData;
        try {
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            this.props.applicationInsights.trackTrace({ message: "/UsedReconditioningInventoryComponent/saveDetail", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            return fetch("/UsedReconditioning/UndoInventoryReconDetailOut", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    if (inputData.outMetricName === "Body") {
                        rowData.body_in_date_time = inputData.dateTime;
                        rowData.body_in_by = inputData.dateTime ? this.props.remoteUpn : null;
                    }
                    else {
                        rowData.recon_in_date_time = inputData.dateTime;
                        rowData.recon_in_by = inputData.dateTime ? this.props.remoteUpn : null;
                    }
                    rowData.detail_showSpinner = false;
                    rowData.detail_out_date_time = inputData.dateTime;
                    rowData.detail_out_by = inputData.dateTime ? this.props.remoteUpn : null;
                    rowData.can_undo_service_out_date_time = inputData.dateTime ? false : true;;
                    rowData.can_undo_detail_out_date_time = inputData.dateTime ? true : false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedReconditioning/UndoInventoryReconDetailOut", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    saveBody(inputData, rowData) {
        let tableData = this.state.tableData;
        try {
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            this.props.applicationInsights.trackTrace({ message: "/UsedReconditioningInventoryComponent/saveBody", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            return fetch("/UsedReconditioning/UndoInventoryReconBodyOut", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    rowData.body_showSpinner = false;
                    rowData.body_out_date_time = inputData.dateTime;
                    rowData.body_out_by = inputData.dateTime ? this.props.remoteUpn : null;
                    rowData.can_undo_body_out_date_time = inputData.dateTime ? true : false
                    rowData.can_undo_detail_out_date_time = inputData.dateTime ? false : true;
                    rowData.recon_in_date_time = inputData.dateTime;
                    rowData.recon_in_by = inputData.dateTime ? this.props.remoteUpn : null;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedReconditioning/UndoInventoryReconBodyOut", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    saveRecon(inputData, rowData) {
        let tableData = this.state.tableData;
        try {
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            this.props.applicationInsights.trackTrace({ message: "/UsedReconditioningInventoryComponent/saveRecon", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            return fetch("/UsedReconditioning/UndoInventoryReconReconOut", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    rowData.recon_showSpinner = false;
                    rowData.recon_out_date_time = inputData.dateTime;
                    rowData.recon_out_by = inputData.dateTime ? this.props.remoteUpn : null;
                    rowData.can_undo_service_out_date_time = false;
                    rowData.can_undo_detail_out_date_time = !rowData.body_in_date_time && !inputData.dateTime ? true : false;
                    rowData.can_undo_body_out_date_time = inputData.dateTime ? false : true
                    rowData.can_undo_recon_out_date_time = inputData.dateTime ? true : false;
                    rowData.photo_in_date_time = inputData.dateTime;
                    rowData.photo_in_by = inputData.dateTime ? this.props.remoteUpn : null;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedReconditioning/UndoInventoryReconReconOut", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    savePhoto(inputData, rowData) {
        let tableData = this.state.tableData;
        try {
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            this.props.applicationInsights.trackTrace({ message: "/UsedReconditioningInventoryComponent/savePhoto", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            return fetch("/UsedReconditioning/UndoInventoryReconPhotoOut", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    rowData.photo_showSpinner = false;
                    rowData.photo_out_date_time = inputData.dateTime;
                    rowData.photo_out_by = inputData.dateTime ? this.props.remoteUpn : null;
                    rowData.can_undo_service_out_date_time = false;
                    rowData.can_undo_detail_out_date_time = false;
                    rowData.can_undo_recon_out_date_time = inputData.dateTime ? false : true;
                    rowData.can_undo_photo_out_date_time = inputData.dateTime ? true : false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedReconditioning/UndoInventoryReconPhotoOut", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <UsedReconditioningAuditQueryComponent
                applicationInsights={this.props.applicationInsights}
                isLoadingData={this.state.isLoadingData}
                onGetReportClick={this.onGetReportClick}
            />

            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText={"Undo changes to Stock #: " + (this.state.undoDetail !== null ? this.state.undoDetail.stock_number : "")}
                bodyText={this.state.undoDetail ? "Confirm rollback of checkout of " + this.state.metricName + " by " + this.state.undoDetail[this.state.metricName.toLowerCase() + "_out_by"] + " on " + this.state.undoDetail[this.state.metricName.toLowerCase() + "_out_date_time"] + "?" : ""}
                showConfirmation={this.state.undoDetail !== null}
                onOkClick={this.onUndoOkClick}
                onCancelClick={this.onCancelUndoClick}
            />
            <div className="dgdg-site-scrollable-content">
                <div className="card d-inline-block">
                    <div className="card-header ui-draggable-handle">
                        <div className="d-inline-block align-middle"><span>{this.state.tableTitle}</span></div>
                    </div>
                    <div className="card-block">
                        <div className="form-inline">
                            <table className="dgdg-used-reconditioning-audit-table">
                                <tbody>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1"> Store:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 ? "" : this.state.tableData[0].store_web_name}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Stock Number:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 ? "" : this.state.tableData[0].stock_number}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3">VIN:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 ? "" : this.state.tableData[0].vin}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4 ">YMMT:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 ? "" : this.state.tableData[0].ymmt}</td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Is WholeSale:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 ? "" : this.state.tableData[0].is_wholesale}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Stock Type:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 ? "" : this.state.tableData[0].stocktype}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3">CDK Wholesale:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 ? "" : this.state.tableData[0].cdk_wholesale}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4">Entry Date:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 ? "" : this.state.tableData[0].entrydate}</td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Status:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 ? "" : this.state.tableData[0].status}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">License No:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 ? "" : this.state.tableData[0].licenseno}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3">Licence State:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 ? "" : this.state.tableData[0].licensestate}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4">Fuel Type:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 ? "" : this.state.tableData[0].fueltype}</td>
                                    </tr>
                                    <tr className="dgdg-used-reconditioning-audit-table-row-separator">
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Is CPO:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 ? "" : this.state.tableData[0].is_cpo}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Smog In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].smog_in_date_time ? "" : this.state.tableData[0].smog_in_date_time + " (" + this.state.tableData[0].smog_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Smog Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].smog_out_date_time ? "" : this.state.tableData[0].smog_out_date_time + " (" + this.state.tableData[0].smog_out_by + ", " + this.state.tableData[0].smog_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">VIN In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].vin_in_date_time ? "" : this.state.tableData[0].vin_in_date_time + " (" + this.state.tableData[0].vin_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Vin Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].vin_out_date_time ? "" : this.state.tableData[0].vin_out_date_time + " (" + this.state.tableData[0].vin_out_by + ", " + this.state.tableData[0].vin_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Weight In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].weight_in_date_time ? "" : this.state.tableData[0].weight_in_date_time + " (" + this.state.tableData[0].weight_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Weight Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].weight_out_date_time ? "" : this.state.tableData[0].weight_out_date_time + " (" + this.state.tableData[0].weight_out_by + ", " + this.state.tableData[0].weight_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Pending Arrival In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].pa_in_date_time ? "" : this.state.tableData[0].pa_in_date_time + " (" + this.state.tableData[0].pa_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Pending Arrival Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].pa_out_date_time ? "" : this.state.tableData[0].pa_out_date_time + " (" + this.state.tableData[0].pa_out_by + ", " + this.state.tableData[0].pa_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Holding Dispatch In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].d_in_date_time ? "" : this.state.tableData[0].d_in_date_time + " (" + this.state.tableData[0].d_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Holding Dispatch Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].d_out_date_time ? "" : this.state.tableData[0].d_out_date_time + " (" + this.state.tableData[0].d_out_by + ", " + this.state.tableData[0].d_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Pricing Availability Shop In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].prav_in_date_time ? "" : this.state.tableData[0].prav_in_date_time + " (" + this.state.tableData[0].prav_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Pricing Availability Shop Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].prav_out_date_time ? "" : this.state.tableData[0].prav_out_date_time + " (" + this.state.tableData[0].prav_out_by + ", " + this.state.tableData[0].prav_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Parts Order In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].prtord_in_date_time ? "" : this.state.tableData[0].prtord_in_date_time + " (" + this.state.tableData[0].prtord_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Pending Arrival Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].prtord_out_date_time ? "" : this.state.tableData[0].prtord_out_date_time + " (" + this.state.tableData[0].prtord_out_by + ", " + this.state.tableData[0].prtord_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Holding Parts Arrival In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].parts_in_date_time ? "" : this.state.tableData[0].parts_in_date_time + " (" + this.state.tableData[0].parts_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Holding Parts Arrival Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].parts_out_date_time ? "" : this.state.tableData[0].parts_out_date_time + " (" + this.state.tableData[0].parts_out_by + ", " + this.state.tableData[0].parts_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Holding Back Order Parts In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].bo_in_date_time ? "" : this.state.tableData[0].bo_in_date_time + " (" + this.state.tableData[0].bo_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Holding 4Back Order Parts Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].bo_out_date_time ? "" : this.state.tableData[0].bo_out_date_time + " (" + this.state.tableData[0].bo_out_by + ", " + this.state.tableData[0].bo_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Monitors In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].monitor_in_date_time ? "" : this.state.tableData[0].monitor_in_date_time + " (" + this.state.tableData[0].monitor_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Monitors Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].monitor_out_date_time ? "" : this.state.tableData[0].monitor_out_date_time + " (" + this.state.tableData[0].monitor_out_by + ", " + this.state.tableData[0].monitor_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">At OEM Dealer In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].dr_in_date_time ? "" : this.state.tableData[0].dr_in_date_time + " (" + this.state.tableData[0].dr_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">At OEM Dealer Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].dr_out_date_time ? "" : this.state.tableData[0].dr_out_date_time + " (" + this.state.tableData[0].dr_out_by + ", " + this.state.tableData[0].dr_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Holding Vendor In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].vendor_in_date_time ? "" : this.state.tableData[0].vendor_in_date_time + " (" + this.state.tableData[0].vendor_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Holding Vendor Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].vendor_out_date_time ? "" : this.state.tableData[0].vendor_out_date_time + " (" + this.state.tableData[0].vendor_out_by + ", " + this.state.tableData[0].vendor_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Holding Authorization In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].auth_in_date_time ? "" : this.state.tableData[0].auth_in_date_time + " (" + this.state.tableData[0].auth_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Holding Authorization" Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].auth_out_date_time ? "" : this.state.tableData[0].auth_out_date_time + " (" + this.state.tableData[0].auth_out_by + ", " + this.state.tableData[0].auth_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Pending Transport In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].pt_in_date_time ? "" : this.state.tableData[0].pt_in_date_time + " (" + this.state.tableData[0].pt_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Pending Transport Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].pt_out_date_time ? "" : this.state.tableData[0].pt_out_date_time + " (" + this.state.tableData[0].pt_out_by + ", " + this.state.tableData[0].pt_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Other In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].other_in_date_time ? "" : this.state.tableData[0].other_in_date_time + " (" + this.state.tableData[0].other_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Other Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].other_out_date_time ? "" : this.state.tableData[0].other_out_date_time + " (" + this.state.tableData[0].other_out_by + ", " + this.state.tableData[0].other_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">No Holds In Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].nh_in_date_time ? "" : this.state.tableData[0].nh_in_date_time + " (" + this.state.tableData[0].nh_in_by + ")"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">No Holds Out Date Time:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 || !this.state.tableData[0].nh_out_date_time ? "" : this.state.tableData[0].nh_out_date_time + " (" + this.state.tableData[0].nh_out_by + ", " + this.state.tableData[0].nh_out_age + " days)"}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Service In Date Time:</td>
                                        {
                                            this.state.tableData.length === 0 || !this.state.tableData[0].service_in_date_time
                                                ? <td className="dgdg-used-reconditioning-audit-table-data-result"></td>
                                                : !this.state.isInEditRole
                                                    ? <td className="dgdg-used-reconditioning-audit-table-data-result">{CommonService.formatDateTime(this.state.tableData[0].service_in_date_time) + " (" + this.state.tableData[0].service_in_by + ")"}</td>
                                                    : <td className="dgdg-used-reconditioning-audit-table-data-result d-inline-flex">{CommonService.formatDateTime(this.state.tableData[0].service_in_date_time) + " (" + this.state.tableData[0].service_in_by + ")"}
                                                        <button type="button" className="btn btn-link dgdg-used-reconditioning-audit-edit-date-button" onClick={(event) => this.onShowDateEditClick(event, "serviceInEdit")}>
                                                            <span className="fas fa-edit" />
                                                        </button>
                                                        &nbsp;&nbsp;
                                                        <div id="serviceInEdit" style={{ "display": "none" }}>
                                                            <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="serviceInDate" controlCssName="dgdg-used-reconditioning-audit-edit-date" value={this.state.tableData[0].service_in_date_time} onDateChange={this.onDateChange} saveDisabled={!CommonService.isDateValid(this.state.serviceInDate)} onSaveDateClick={(event) => this.onSaveInventoryReconInDateClick(this.state.serviceInDate, "Service")} onCancelDateClick={(event) => this.HideEditDate("serviceInEdit")} onClicknumberOfMonths={1} />
                                                        </div>
                                                    </td>
                                        }
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Service Out Date Time:</td>
                                        {
                                            this.state.tableData.length === 0 || !this.state.tableData[0].service_out_date_time
                                                ? <td className="dgdg-used-reconditioning-audit-table-data-result"></td>
                                                : !this.state.isInEditRole
                                                    ? <td className="dgdg-used-reconditioning-audit-table-data-result">
                                                        {CommonService.formatDateTime(this.state.tableData[0].service_out_date_time) + " (" + this.state.tableData[0].service_out_by + ", " + this.state.tableData[0].service_out_age + " days)"}</td>
                                                    : <td className="dgdg-used-reconditioning-audit-table-data-result d-inline-flex">
                                                        {
                                                            this.state.tableData[0].can_undo_service_out_date_time
                                                                ? <div className="tooltip" data-display="static">
                                                                    {
                                                                        this.state.tableData[0].service_showSpinner
                                                                            ? <DGDGSpinnerComponent showSpinner={this.state.tableData[0].service_showSpinner} />
                                                                            : <Fragment>
                                                                                <button type="button" className="btn btn-link dgdg-used-reconditioning-audit-undo-button" onClick={(event) => { if (this.state.tableData[0].is_wholesale === "Yes") return false; this.onUndoInventoryClick(event, this.state.tableData[0], "Service Out", "Service"); }}>
                                                                                    <span className="fa-solid fa-delete-left" />
                                                                                </button>
                                                                                <div className="content dgdg-used-reconditioning-audit-tooltip" data-display="static">
                                                                                    Rollback checkout of Service by {this.state.tableData[0].service_out_by} on {CommonService.formatDateTime(this.state.tableData[0].service_out_date_time)}
                                                                                </div>
                                                                            </Fragment>
                                                                    }
                                                                </div>
                                                                : null
                                                        }
                                                        {CommonService.formatDateTime(this.state.tableData[0].service_out_date_time) + " (" + this.state.tableData[0].service_out_by + ", " + this.state.tableData[0].service_out_age + " days)"}
                                                        <button type="button" className="btn btn-link dgdg-used-reconditioning-audit-edit-date-button" onClick={(event) => this.onShowDateEditClick(event, "serviceOutEdit")}>
                                                            <span className="fas fa-edit" />
                                                        </button>
                                                        &nbsp;&nbsp;
                                                        <div id="serviceOutEdit" style={{ "display": "none" }}>
                                                            <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="serviceOutDate" controlCssName="dgdg-used-reconditioning-audit-edit-date" value={this.state.tableData[0].service_out_date_time} onDateChange={this.onDateChange} saveDisabled={!CommonService.isDateValid(this.state.serviceOutDate)} onSaveDateClick={(event) => this.onSaveInventoryReconOutDateClick(this.state.serviceOutDate, "Service")} onCancelDateClick={(event) => this.HideEditDate("serviceOutEdit")} onClicknumberOfMonths={1} />
                                                        </div>
                                                    </td>
                                        }
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Detail In Date Time:</td>
                                        {
                                            this.state.tableData.length === 0 || !this.state.tableData[0].detail_in_date_time
                                                ? <td className="dgdg-used-reconditioning-audit-table-data-result"></td>
                                                : !this.state.isInEditRole
                                                    ? <td className="dgdg-used-reconditioning-audit-table-data-result">{CommonService.formatDateTime(this.state.tableData[0].detail_in_date_time) + " (" + this.state.tableData[0].detail_in_by + ")"}</td>
                                                    : <td className="dgdg-used-reconditioning-audit-table-data-result d-inline-flex"> {CommonService.formatDateTime(this.state.tableData[0].detail_in_date_time) + " (" + this.state.tableData[0].detail_in_by + ")"}
                                                        <button type="button" className="btn btn-link dgdg-used-reconditioning-audit-edit-date-button" onClick={(event) => this.onShowDateEditClick(event, "detailInEdit")}>
                                                            <span className="fas fa-edit" />
                                                        </button>
                                                        &nbsp;&nbsp;
                                                        <div id="detailInEdit" style={{ "display": "none" }}>
                                                            <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="detailInDate" controlCssName="dgdg-used-reconditioning-audit-edit-date" value={this.state.tableData[0].detail_in_date_time} onDateChange={this.onDateChange} saveDisabled={!CommonService.isDateValid(this.state.detailInDate)} onSaveDateClick={(event) => this.onSaveInventoryReconInDateClick(this.state.detailInDate, "Detail")} onCancelDateClick={(event) => this.HideEditDate("detailInEdit")} onClicknumberOfMonths={1} />
                                                        </div>
                                                    </td>
                                        }
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Detail Out Date Time:</td>
                                        {
                                            this.state.tableData.length === 0 || !this.state.tableData[0].detail_out_date_time
                                                ? <td className="dgdg-used-reconditioning-audit-table-data-result"></td>
                                                : !this.state.isInEditRole
                                                    ? <td className="dgdg-used-reconditioning-audit-table-data-result">
                                                        {CommonService.formatDateTime(this.state.tableData[0].detail_out_date_time) + " (" + this.state.tableData[0].detail_out_by + ", " + this.state.tableData[0].detail_out_age + " days)"}</td>
                                                    : <td className="dgdg-used-reconditioning-audit-table-data-result d-inline-flex">
                                                        {
                                                            this.state.tableData[0].can_undo_detail_out_date_time
                                                                ? <div className="tooltip" data-display="static">
                                                                    {
                                                                        this.state.tableData[0].detail_showSpinner
                                                                            ? <DGDGSpinnerComponent showSpinner={this.state.tableData[0].detail_showSpinner} />
                                                                            : <Fragment>
                                                                                <button type="button" className="btn btn-link dgdg-used-reconditioning-audit-undo-button" onClick={(event) => { if (this.state.tableData[0].is_wholesale === "Yes") return false; this.onUndoInventoryClick(event, this.state.tableData[0], "Detail Out", "Detail"); }}>
                                                                                    <span className="fa-solid fa-delete-left" />
                                                                                </button>
                                                                                <div className="content dgdg-used-reconditioning-audit-tooltip" data-display="static">
                                                                                    Rollback checkout of Detail by {this.state.tableData[0].detail_out_by} on {CommonService.formatDateTime(this.state.tableData[0].detail_out_date_time)}
                                                                                </div>
                                                                            </Fragment>
                                                                    }
                                                                </div>
                                                                : null
                                                        }
                                                        {CommonService.formatDateTime(this.state.tableData[0].detail_out_date_time) + " (" + this.state.tableData[0].detail_out_by + ", " + this.state.tableData[0].detail_out_age + " days)"}
                                                        <button type="button" className="btn btn-link dgdg-used-reconditioning-audit-edit-date-button" onClick={(event) => this.onShowDateEditClick(event, "detailOutEdit")}>
                                                            <span className="fas fa-edit" />
                                                        </button>
                                                        &nbsp;&nbsp;
                                                        <div id="detailOutEdit" style={{ "display": "none" }}>
                                                            <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="detailOutDate" controlCssName="dgdg-used-reconditioning-audit-edit-date" value={this.state.tableData[0].detail_out_date_time} onDateChange={this.onDateChange} saveDisabled={!CommonService.isDateValid(this.state.detailOutDate)} onSaveDateClick={(event) => this.onSaveInventoryReconOutDateClick(this.state.detailOutDate, "Detail")} onCancelDateClick={(event) => this.HideEditDate("detailOutEdit")} onClicknumberOfMonths={1} />
                                                        </div>
                                                    </td>
                                        }
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Body In Date Time:</td>
                                        {
                                            this.state.tableData.length === 0 || !this.state.tableData[0].body_in_date_time
                                                ? <td className="dgdg-used-reconditioning-audit-table-data-result"></td>
                                                : !this.state.isInEditRole
                                                    ? <td className="dgdg-used-reconditioning-audit-table-data-result">{CommonService.formatDateTime(this.state.tableData[0].body_in_date_time) + " (" + this.state.tableData[0].body_in_by + ")"}</td>
                                                    : <td className="dgdg-used-reconditioning-audit-table-data-result d-inline-flex">{CommonService.formatDateTime(this.state.tableData[0].body_in_date_time) + " (" + this.state.tableData[0].body_in_by + ")"}
                                                        <button type="button" className="btn btn-link dgdg-used-reconditioning-audit-edit-date-button" onClick={(event) => this.onShowDateEditClick(event, "bodyInEdit")}>
                                                            <span className="fas fa-edit" />
                                                        </button>
                                                        &nbsp;&nbsp;
                                                        <div id="bodyInEdit" style={{ "display": "none" }}>
                                                            <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="bodyInDate" controlCssName="dgdg-used-reconditioning-audit-edit-date" value={this.state.tableData[0].body_in_date_time} onDateChange={this.onDateChange} saveDisabled={!CommonService.isDateValid(this.state.bodyInDate)} onSaveDateClick={(event) => this.onSaveInventoryReconInDateClick(this.state.bodyInDate, "Body")} onCancelDateClick={(event) => this.HideEditDate("bodyInEdit")} onClicknumberOfMonths={1} />
                                                        </div>
                                                    </td>
                                        }
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Body Out Date Time:</td>
                                        {
                                            this.state.tableData.length === 0 || !this.state.tableData[0].body_out_date_time
                                                ? <td className="dgdg-used-reconditioning-audit-table-data-result"></td>
                                                : !this.state.isInEditRole
                                                    ? <td className="dgdg-used-reconditioning-audit-table-data-result">{CommonService.formatDateTime(this.state.tableData[0].body_out_date_time) + " (" + this.state.tableData[0].body_out_by + ", " + this.state.tableData[0].body_out_age + " days)"}</td>
                                                    : <td className="dgdg-used-reconditioning-audit-table-data-result d-inline-flex">
                                                        {
                                                            this.state.tableData[0].can_undo_body_out_date_time
                                                                ? <div className="tooltip" data-display="static">
                                                                    {
                                                                        this.state.tableData[0].body_showSpinner
                                                                            ? <DGDGSpinnerComponent showSpinner={this.state.tableData[0].body_showSpinner} />
                                                                            : <Fragment>
                                                                                <button type="button" className="btn btn-link dgdg-used-reconditioning-audit-undo-button" onClick={(event) => { if (this.state.tableData[0].is_wholesale === "Yes") return false; this.onUndoInventoryClick(event, this.state.tableData[0], "Body Out", "Body"); }}>
                                                                                    <span className="fa-solid fa-delete-left" />
                                                                                </button>
                                                                                <div className="content dgdg-used-reconditioning-audit-tooltip" data-display="static">
                                                                                    Rollback checkout of Body by {this.state.tableData[0].body_out_by} on {CommonService.formatDateTime(this.state.tableData[0].body_out_date_time)}
                                                                                </div>
                                                                            </Fragment>
                                                                    }
                                                                </div>
                                                                : null
                                                        }
                                                        {CommonService.formatDateTime(this.state.tableData[0].body_out_date_time) + " (" + this.state.tableData[0].body_out_by + ", " + this.state.tableData[0].body_out_age + " days)"}
                                                        <button type="button" className="btn btn-link dgdg-used-reconditioning-audit-edit-date-button" onClick={(event) => this.onShowDateEditClick(event, "bodyOutEdit")}>
                                                            <span className="fas fa-edit" />
                                                        </button>
                                                        &nbsp;&nbsp;
                                                        <div id="bodyOutEdit" style={{ "display": "none" }}>
                                                            <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="bodyOutDate" controlCssName="dgdg-used-reconditioning-audit-edit-date" value={this.state.tableData[0].body_out_date_time} onDateChange={this.onDateChange} saveDisabled={!CommonService.isDateValid(this.state.bodyOutDate)} onSaveDateClick={(event) => this.onSaveInventoryReconOutDateClick(this.state.bodyOutDate, "Body")} onCancelDateClick={(event) => this.HideEditDate("bodyOutEdit")} onClicknumberOfMonths={1} />
                                                        </div>
                                                    </td>
                                        }
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>

                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Recon In Date Time:</td>
                                        {
                                            this.state.tableData.length === 0 || !this.state.tableData[0].recon_in_date_time
                                                ? <td className="dgdg-used-reconditioning-audit-table-data-result"></td>
                                                : !this.state.isInEditRole
                                                    ? <td className="dgdg-used-reconditioning-audit-table-data-result">{CommonService.formatDateTime(this.state.tableData[0].recon_in_date_time) + " (" + this.state.tableData[0].recon_in_by + ")"}</td>
                                                    : <td className="dgdg-used-reconditioning-audit-table-data-result d-inline-flex">{CommonService.formatDateTime(this.state.tableData[0].recon_in_date_time) + " (" + this.state.tableData[0].recon_in_by + ")"}
                                                        <button type="button" className="btn btn-link dgdg-used-reconditioning-audit-edit-date-button" onClick={(event) => this.onShowDateEditClick(event, "reconInEdit")}>
                                                            <span className="fas fa-edit" />
                                                        </button>
                                                        &nbsp;&nbsp;
                                                        <div id="reconInEdit" style={{ "display": "none" }}>
                                                            <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="reconInDate" controlCssName="dgdg-used-reconditioning-audit-edit-date" value={this.state.tableData[0].recon_in_date_time} onDateChange={this.onDateChange} saveDisabled={!CommonService.isDateValid(this.state.reconInDate)} onSaveDateClick={(event) => this.onSaveInventoryReconInDateClick(this.state.reconInDate, "Recon")} onCancelDateClick={(event) => this.HideEditDate("reconInEdit")} onClicknumberOfMonths={1} />
                                                        </div>
                                                    </td>
                                        }
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Recon Out Date Time:</td>
                                        {
                                            this.state.tableData.length === 0 || !this.state.tableData[0].recon_out_date_time
                                                ? <td className="dgdg-used-reconditioning-audit-table-data-result"></td>
                                                : !this.state.isInEditRole
                                                    ? <td className="dgdg-used-reconditioning-audit-table-data-result">{CommonService.formatDateTime(this.state.tableData[0].recon_out_date_time) + " (" + this.state.tableData[0].recon_out_by + ", " + this.state.tableData[0].recon_out_age + " days)"}</td>
                                                    : <td className="dgdg-used-reconditioning-audit-table-data-result d-inline-flex">
                                                        {
                                                            this.state.tableData[0].can_undo_recon_out_date_time
                                                                ? <div className="tooltip" data-display="static">
                                                                    {
                                                                        this.state.tableData[0].recon_showSpinner
                                                                            ? <DGDGSpinnerComponent showSpinner={this.state.tableData[0].recon_showSpinner} />
                                                                            : <Fragment>
                                                                                <button type="button" className="btn btn-link dgdg-used-reconditioning-audit-undo-button" onClick={(event) => { if (this.state.tableData[0].is_wholesale === "Yes") return false; this.onUndoInventoryClick(event, this.state.tableData[0], "Recon Out", "Recon"); }}>
                                                                                    <span className="fa-solid fa-delete-left" />
                                                                                </button>

                                                                                <div className="content dgdg-used-reconditioning-audit-tooltip" data-display="static">
                                                                                    Rollback checkout of Recon by {this.state.tableData[0].recon_out_by} on {CommonService.formatDateTime(this.state.tableData[0].recon_out_date_time)}
                                                                                </div>
                                                                            </Fragment>
                                                                    }
                                                                </div>
                                                                : null
                                                        }
                                                        {CommonService.formatDateTime(this.state.tableData[0].recon_out_date_time) + " (" + this.state.tableData[0].recon_out_by + ", " + this.state.tableData[0].recon_out_age + " days)"}
                                                        <button type="button" className="btn btn-link dgdg-used-reconditioning-audit-edit-date-button" onClick={(event) => this.onShowDateEditClick(event, "reconOutEdit")}>
                                                            <span className="fas fa-edit" />
                                                        </button>
                                                        &nbsp;&nbsp;
                                                        <div id="reconOutEdit" style={{ "display": "none" }}>
                                                            <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="reconOutDate" controlCssName="dgdg-used-reconditioning-audit-edit-date" value={this.state.tableData[0].recon_out_date_time} onDateChange={this.onDateChange} saveDisabled={!CommonService.isDateValid(this.state.reconOutDate)} onSaveDateClick={(event) => this.onSaveInventoryReconOutDateClick(this.state.reconOutDate, "Recon")} onCancelDateClick={(event) => this.HideEditDate("reconOutEdit")} onClicknumberOfMonths={1} />
                                                        </div>
                                                    </td>
                                        }
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3">Recon Interior Only:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-result">{this.state.tableData.length === 0 ? "" : this.state.tableData[0].recon_interior_only}</td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>

                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-1">Photo In Date Time:</td>
                                        {
                                            this.state.tableData.length === 0 || !this.state.tableData[0].photo_in_date_time
                                                ? <td className="dgdg-used-reconditioning-audit-table-data-result"></td>
                                                : !this.state.isInEditRole
                                                    ? <td className="dgdg-used-reconditioning-audit-table-data-result">{CommonService.formatDateTime(this.state.tableData[0].photo_in_date_time) + " (" + this.state.tableData[0].photo_in_by + ")"}</td>
                                                    : <td className="dgdg-used-reconditioning-audit-table-data-result d-inline-flex">{CommonService.formatDateTime(this.state.tableData[0].photo_in_date_time) + " (" + this.state.tableData[0].photo_in_by + ")"}
                                                        <button type="button" className="btn btn-link dgdg-used-reconditioning-audit-edit-date-button" onClick={(event) => this.onShowDateEditClick(event, "photoInEdit")}>
                                                            <span className="fas fa-edit" />
                                                        </button>
                                                        &nbsp;&nbsp;
                                                        <div id="photoInEdit" style={{ "display": "none" }}>
                                                            <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="photoInDate" controlCssName="dgdg-used-reconditioning-audit-edit-date" value={this.state.tableData[0].photo_in_date_time} onDateChange={this.onDateChange} saveDisabled={!CommonService.isDateValid(this.state.photoInDate)} onSaveDateClick={(event) => this.onSaveInventoryReconInDateClick(this.state.photoInDate, "Photo")} onCancelDateClick={(event) => this.HideEditDate("photoInEdit")} onClicknumberOfMonths={1} />
                                                        </div>
                                                    </td>
                                        }
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-2">Photo Out Date Time:</td>
                                        {
                                            this.state.tableData.length === 0 || !this.state.tableData[0].photo_out_date_time
                                                ? <td className="dgdg-used-reconditioning-audit-table-data-result"></td>
                                                : !this.state.isInEditRole
                                                    ? <td className="dgdg-used-reconditioning-audit-table-data-result">{CommonService.formatDateTime(this.state.tableData[0].photo_out_date_time) + " (" + this.state.tableData[0].photo_out_by + ", " + this.state.tableData[0].photo_out_age + " days)"}</td>
                                                    : <td className="dgdg-used-reconditioning-audit-table-data-result d-inline-flex">
                                                        {
                                                            this.state.tableData[0].can_undo_photo_out_date_time
                                                                ? <div className="tooltip" data-display="static">
                                                                    {
                                                                        this.state.tableData[0].photo_showSpinner
                                                                            ? <DGDGSpinnerComponent showSpinner={this.state.tableData[0].photo_showSpinner} />
                                                                            : <Fragment>
                                                                                <button type="button" className="btn btn-link dgdg-used-reconditioning-audit-undo-button" onClick={(event) => { if (this.state.tableData[0].is_wholesale === "Yes") return false; this.onUndoInventoryClick(event, this.state.tableData[0], "Photo Out", "Photo"); }}>
                                                                                    <span className="fa-solid fa-delete-left" />
                                                                                </button>
                                                                                <div className="content dgdg-used-reconditioning-audit-tooltip" data-display="static">
                                                                                    Rollback checkout of Photo by {this.state.tableData[0].photo_out_by} on {CommonService.formatDateTime(this.state.tableData[0].photo_out_date_time)}
                                                                                </div>
                                                                            </Fragment>
                                                                    }
                                                                </div>
                                                                : null
                                                        }
                                                        {CommonService.formatDateTime(this.state.tableData[0].photo_out_date_time) + " (" + this.state.tableData[0].photo_out_by + ", " + this.state.tableData[0].photo_out_age + " days)"}
                                                        <button type="button" className="btn btn-link dgdg-used-reconditioning-audit-edit-date-button" onClick={(event) => this.onShowDateEditClick(event, "photoOutEdit")}>
                                                            <span className="fas fa-edit" />
                                                        </button>
                                                        &nbsp;&nbsp;
                                                        <div id="photoOutEdit" style={{ "display": "none" }}>
                                                            <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="photoOutDate" controlCssName="dgdg-used-reconditioning-audit-edit-date" value={this.state.tableData[0].photo_out_date_time} onDateChange={this.onDateChange} saveDisabled={!CommonService.isDateValid(this.state.photoOutDate)} onSaveDateClick={(event) => this.onSaveInventoryReconOutDateClick(this.state.photoOutDate, "Photo")} onCancelDateClick={(event) => this.HideEditDate("photoOutEdit")} onClicknumberOfMonths={1} />
                                                        </div>
                                                    </td>
                                        }
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-3"></td>
                                        <td></td>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title dgdg-used-reconditioning-audit-table-column-4"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="dgdg-used-reconditioning-audit-table-cell-title">Comments:</td>
                                        <td className="dgdg-used-reconditioning-audit-table-data-wrap" colSpan="7">{this.state.tableData.length === 0 ? "" : this.state.tableData[0].comments}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    }
}
