import React from "react";
import $ from "jquery";

import { DGDGFormComboBoxComponent } from "../common/dgdgcombobox/DGDGFormComboBoxComponent";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";

export class DirectoryKeypadComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dialNumber: "",
            userList: [],
            isCallDisabled: false
        };

        this.onNumberClick = this.onNumberClick.bind(this);
        this.onUserChange = this.onUserChange.bind(this);
        this.onUserClick = this.onUserClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onConfirmCallClick = this.onConfirmCallClick.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (!prevProps.showDialog && this.props.showDialog) {
                setTimeout(() => {
                    $("#ddpPhoneSearch").focus();
                }, 1000);
                this.setState({
                    userList: this.props.users,
                    dialNumber: this.props.calleeData ? this.props.calleeData.display_name : "",
                    isCallDisabled: this.props.calleeData && this.props.calleeData.PhoneNumber ? false : true
                });
            }
            else if (prevProps.calleeData && !this.props.calleeData) {
                this.setState({
                    dialNumber: ""
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onNumberClick(event, number) {
        let dialNumber = this.state.dialNumber + number;
        let phoneNumber = dialNumber.split(" ");
        this.setState({
            dialNumber: dialNumber,
            isCallDisabled: phoneNumber.length > 0 ? (phoneNumber[phoneNumber.length - 1].length < 10) : (phoneNumber.length < 10) ? true : false
        });
    }

    onUserChange(id, filterValue) {
        try {
            let filteredData = [];
            let inputValue = filterValue.split(" ");
            let phoneNumber = inputValue[inputValue.length - 1].replaceAll("-", "");
            let inputText;
            if ($.isNumeric(phoneNumber)) {
                inputText = filterValue.replace(inputValue[inputValue.length - 1], "");
                if (phoneNumber.length > 6) {
                    inputText += phoneNumber.substring(0, 3) + "-" + phoneNumber.substring(3, 6) + "-" + phoneNumber.substring(6, 10);
                }
                else if (phoneNumber.length > 3) {
                    inputText += phoneNumber.substring(0, 3) + "-" + phoneNumber.substring(3, 6);
                }
                else {
                    inputText += phoneNumber;
                }
            }
            filterValue = $.isNumeric(phoneNumber) ? inputText : filterValue;
            filteredData = this.props.users.filter(userRow => {
                return userRow.display_name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
            });

            this.setState({
                userList: filteredData,
                dialNumber: filterValue,
                isCallDisabled: ($.isNumeric(phoneNumber) && phoneNumber.length >= 10) ? false : true
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onUserClick(id, userRow) {
        try {
            this.setState({
                dialNumber: userRow.display_name,
                isCallDisabled: userRow.PhoneNumber ? false : true
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            let phoneNumber = this.state.dialNumber.split(" ");
            phoneNumber = phoneNumber[phoneNumber.length - 1].replaceAll("-", "")
            if (event.keyCode === 13 && $.isNumeric(phoneNumber) && phoneNumber.length === 10) {
                this.onConfirmCallClick();
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onConfirmCallClick(event) {
        try {
            this.props.onConfirmCallClick(this.state.dialNumber);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.props.showDialog}
            cssName="dgdg-directory-page-dialog"
            title="DGDG Dialer"
            onCancelClick={this.props.onCancelCallClick}
        >
            <div className="dgdg-report-body">
                <div className="card">
                    <div className="card-block">
                        <table className="dgdg-directory-dailpad-layout">
                            <tbody>
                                <tr>
                                    <td colSpan="3" className="dgdg-directory-caller">
                                        Caller ID:&nbsp;
                                        {this.props.callerData ? this.props.callerData.Name + " " + this.props.callerData.PhoneNumber[0] : ""}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="3" className="dgdg-directory-userSearch">
                                        <DGDGFormComboBoxComponent id="ddpPhoneSearch" data={this.state.userList} itemKey="display_name" value={this.state.dialNumber} onItemClick={this.onUserClick} onItemChange={this.onUserChange} onKeyDown={(event) => this.onKeyDown(event)} autoDrop />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dgdg-directory-dail">
                                        <button type="button" className="btn btn-light dgdg-directory-dail-number" onClick={(event) => this.onNumberClick(event, 1)}>1
                                            <p className="dgdg-directory-alphabet"></p>
                                        </button>
                                    </td>
                                    <td className="dgdg-directory-dail">
                                        <button type="button" className="btn btn-light dgdg-directory-dail-number" onClick={(event) => this.onNumberClick(event, 2)}>2
                                            <p className="dgdg-directory-alphabet">ABC</p>
                                        </button>
                                    </td>
                                    <td className="dgdg-directory-dail">
                                        <button type="button" className="btn btn-light dgdg-directory-dail-number" onClick={(event) => this.onNumberClick(event, 3)}>3
                                            <p className="dgdg-directory-alphabet">DEF</p>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <button type="button" className="btn btn-light dgdg-directory-dail-number" onClick={(event) => this.onNumberClick(event, 4)}>4
                                            <p className="dgdg-directory-alphabet">GHI</p>
                                        </button>
                                    </td>
                                    <td>
                                        <button type="button" className="btn btn-light dgdg-directory-dail-number" onClick={(event) => this.onNumberClick(event, 5)}>5
                                            <p className="dgdg-directory-alphabet">JKL</p>
                                        </button>
                                    </td>
                                    <td>
                                        <button type="button" className="btn btn-light dgdg-directory-dail-number" onClick={(event) => this.onNumberClick(event, 6)}>6
                                            <p className="dgdg-directory-alphabet">MNO</p>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <button type="button" className="btn btn-light dgdg-directory-dail-number" onClick={(event) => this.onNumberClick(event, 7)}>7
                                            <p className="dgdg-directory-alphabet">PQRS</p>
                                        </button>
                                    </td>
                                    <td>
                                        <button type="button" className="btn btn-light dgdg-directory-dail-number" onClick={(event) => this.onNumberClick(event, 8)}>8
                                            <p className="dgdg-directory-alphabet">TUV</p>
                                        </button>
                                    </td>
                                    <td>
                                        <button type="button" className="btn btn-light dgdg-directory-dail-number" onClick={(event) => this.onNumberClick(event, 9)}>9
                                            <p className="dgdg-directory-alphabet">WXYZ</p>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <button type="button" className="btn btn-light dgdg-directory-dail-number" onClick={(event) => this.onNumberClick(event, "*")}>*</button>
                                    </td>
                                    <td>
                                        <button type="button" className="btn btn-light dgdg-directory-dail-number" onClick={(event) => this.onNumberClick(event, 0)}>0</button>
                                    </td>
                                    <td>
                                        <button type="button" className="btn btn-light dgdg-directory-dail-number" onClick={(event) => this.onNumberClick(event, "#")}>#</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="dgdg-directory-call">
                            <button type="button" className="btn btn-primary btn-lg btn-block" disabled={this.state.isCallDisabled} onClick={(event) => this.onConfirmCallClick(event)}>Call</button>
                        </div>
                    </div>
                </div>
            </div>
        </DGDGModalDialogComponent>;
    }
}