import React from "react";
import { Redirect } from "react-router-dom";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGWidgetComponent } from "../common/dgdgwidget/DGDGWidgetComponent";

import { DashboardQuickLinksComponent } from "./DashboardQuickLinksComponent";
import { DashboardSalesByVolumeComponent } from "./DashboardSalesByVolumeComponent";
import { DashboardSalesByVolumeAndGrossComponent } from "./DashboardSalesByVolumeAndGrossComponent";

export class DashboardComponent extends React.Component {
    static SalesByVolumeAndGrossStoreCookie = "DashboardSalesByVolumeAndGrossStoreCookie";
    static SalesByVolumeStoreCookie = "DashboardSalesByVolumeStoreCookie";

    constructor(props) {
        super(props);
        this.state = {
            dashboardItems: [],
            stores: [],
            storesWithAll: [],
            selectedStoreForSalesByVolumeAndGrossStore: null,
            selectedStoreForSalesByVolume: null,
            maximizedChart: null
        };

        this.onStoreClickForSalesByVolumeAndGrossStore = this.onStoreClickForSalesByVolumeAndGrossStore.bind(this);
        this.onStoreClickForSalesByVolume = this.onStoreClickForSalesByVolume.bind(this);
        this.onRestoreClick = this.onRestoreClick.bind(this);
        this.onMaximizeClick = this.onMaximizeClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });
    }

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/Dashboard", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            if (this.props.menuItems.length > 0) {
                let dashboardMenu = this.props.menuItems[0].childMenuItems.filter(menuItem => menuItem.href === "/Dashboard");
                if (dashboardMenu.length === 1 && this.props.menuItems.length > 0) {
                    this.getDashboardItems();
                    this.getSalesByVolumeAndGrossStores();
                    this.getSalesByVolumeStores();
                }
            }

            CommonService.clientAzureStorageLog("DashboardRouteComponent", "DashboardComponent", "componentDidMount", null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.state.stores.length > 0 && this.state.selectedStoreForSalesByVolumeAndGrossStore === null) {
                let storeCookie = CommonService.getCookie(DashboardComponent.SalesByVolumeAndGrossStoreCookie);
                if (storeCookie) {
                    let selectedStore = this.state.stores.filter(store => storeCookie === store.display_name);
                    this.setState({
                        selectedStoreForSalesByVolumeAndGrossStore: selectedStore ? selectedStore[0] : this.state.stores[0]
                    });
                }
                else {
                    this.setState({
                        selectedStoreForSalesByVolumeAndGrossStore: this.state.stores[0]
                    });
                }
            }

            if (this.state.storesWithAll.length > 0 && this.state.selectedStoreForSalesByVolume === null) {
                let storeCookie = CommonService.getCookie(DashboardComponent.SalesByVolumeStoreCookie);
                if (storeCookie) {
                    let selectedStore = this.state.storesWithAll.filter(store => storeCookie === store.display_name);
                    this.setState({
                        selectedStoreForSalesByVolume: selectedStore ? selectedStore[0] : this.state.storesWithAll[0]
                    });
                }
                else {
                    this.setState({
                        selectedStoreForSalesByVolume: this.state.storesWithAll[0]
                    });
                }
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Dashboard";

    getDashboardItems() {
        this.props.applicationInsights.trackTrace({ message: "/DashboardComponent/getDashboardItems", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/Dashboard/GetDashboardItems")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({ dashboardItems: parsedResponse });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Dashboard/GetDashboardItems", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    getSalesByVolumeAndGrossStores() {
        this.props.applicationInsights.trackTrace({ message: "/DashboardComponent/getSalesByVolumeAndGrossStores", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/Dashboard/GetSalesByVolumeAndGrossStores")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({
                    stores: parsedResponse
                });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Dashboard/GetSalesByVolumeAndGrossStores", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    getSalesByVolumeStores() {
        this.props.applicationInsights.trackTrace({ message: "/DashboardComponent/getSalesByVolumeStores", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/Dashboard/GetSalesByVolumeStores")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({
                    storesWithAll: parsedResponse
                });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Dashboard/GetDashboardItems", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    onStoreClickForSalesByVolumeAndGrossStore(id, store) {
        try {
            CommonService.setCookie(DashboardComponent.SalesByVolumeAndGrossStoreCookie, store.display_name, 30)
            this.setState({ selectedStoreForSalesByVolumeAndGrossStore: store });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStoreClickForSalesByVolume(id, store) {
        try {
            CommonService.setCookie(DashboardComponent.SalesByVolumeStoreCookie, store.display_name, 30)
            this.setState({ selectedStoreForSalesByVolume: store });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onMaximizeClick(event, chartName) {
        try {
            this.setState({ maximizedChart: chartName });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onRestoreClick(event) {
        try {
            this.setState({ maximizedChart: null });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        if (this.props.menuItems.length > 0) {
            let dashboardMenu = this.props.menuItems[0].childMenuItems.filter(menuItem => menuItem.href === "/Dashboard");
            if (dashboardMenu.length === 0) {
                let redirectTo = this.props.menuItems[0].href;
                if (!redirectTo) {
                    redirectTo = this.props.menuItems[0].childMenuItems[0].href;
                }

                return <Redirect to={redirectTo} />;
            }
        }

        let dashboardItems = [];
        this.state.dashboardItems.forEach((dashboardItem, index) => {
            if (dashboardItem.id === "SalesByVolume") {
                dashboardItems.push(
                    <DGDGWidgetComponent key={dashboardItem.id}
                        title="Sales by Month - Volume"
                        maximizedCss="dgdg-widget-maximized" restoredCss="col-4 dgdg-widget-restored"
                        isMaximized={this.state.maximizedChart === "SalesByVolume" ? true : false} isVisible={this.state.maximizedChart === "SalesByVolume" || this.state.maximizedChart === null}
                        onMaximizeClick={(event) => this.onMaximizeClick(event, "SalesByVolume")} onRestoreClick={(event) => this.onRestoreClick(event)}
                    >
                        <DashboardSalesByVolumeComponent
                            dashboardItem={dashboardItem} stores={this.state.storesWithAll} isMaximized={this.state.maximizedChart === "SalesByVolume"}
                            onStoreClick={this.onStoreClickForSalesByVolume} selectedStore={this.state.selectedStoreForSalesByVolume}
                        />
                    </DGDGWidgetComponent>
                );
            }

            if (dashboardItem.id === "SalesByVolumeAndGross") {
                dashboardItems.push(
                    <DGDGWidgetComponent key={dashboardItem.id}
                        title="Sales by Month - Volume & Total Gross"
                        maximizedCss="dgdg-widget-maximized" restoredCss="col-4 dgdg-widget-restored"
                        isMaximized={this.state.maximizedChart === "SalesByVolumeAndGross" ? true : false} isVisible={this.state.maximizedChart === "SalesByVolumeAndGross" || this.state.maximizedChart === null}
                        onMaximizeClick={(event) => this.onMaximizeClick(event, "SalesByVolumeAndGross")} onRestoreClick={(event) => this.onRestoreClick(event)}
                    >
                        <DashboardSalesByVolumeAndGrossComponent
                            dashboardItem={dashboardItem} stores={this.state.stores} isMaximized={this.state.maximizedChart === "SalesByVolumeAndGross"}
                            onStoreClick={this.onStoreClickForSalesByVolumeAndGrossStore} selectedStore={this.state.selectedStoreForSalesByVolumeAndGrossStore}
                        />
                    </DGDGWidgetComponent>
                );
            }

            if (dashboardItem.id === "QuickLinks") {
                dashboardItems.push(
                    <DGDGWidgetComponent key={dashboardItem.id}
                        title="Quick Links"
                        maximizedCss="dgdg-widget-maximized" restoredCss="col-4 dgdg-widget-restored"
                        isMaximized={this.state.maximizedChart === "QuickLinks" ? true : false} isVisible={this.state.maximizedChart === "QuickLinks" || this.state.maximizedChart === null}
                        onMaximizeClick={(event) => this.onMaximizeClick(event, "QuickLinks")} onRestoreClick={(event) => this.onRestoreClick(event)}
                    >
                        <DashboardQuickLinksComponent dashboardItem={dashboardItem} />
                    </DGDGWidgetComponent>
                );
            }
        });

        return <DGDGPageComponent>
            <div className="dgdg-site-scrollable-content">
                <div className="row" style={{ height: "100%", "margin": "-10px 0px" }}>
                    {dashboardItems}
                </div>
            </div>
        </DGDGPageComponent>;
    }
}
