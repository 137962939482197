import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormCalendarComponent } from "../common/dgdgcalendar/DGDGFormCalendarComponent";
import { DGDGFormCheckboxComponent } from "../common/dgdgcheckbox/DGDGFormCheckboxComponent";
import { DGDGFormComboBoxComponent } from "../common/dgdgcombobox/DGDGFormComboBoxComponent"
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";

export class FrontEndAdjustmentAddComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            if (state.accountNumbers === null && props.accountNumbers) {
                newState.accountNumbers = props.accountNumbers;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            showSpinner: false,
            isSaveEnabled: false,
            prevRowData: null,
            id: null,
            frontEndAdjustmentDate: null,
            minAdjustmentDate: null,
            maxAdjustmentDate: null,
            selectedStore: null,
            dealNumber: "",
            isDealNumberDirty: false,
            salesDetails: null,
            frontEndDealDate: null,
            customerNumber: "",
            customerName: "",
            stockNumber: "",
            isStockNumberDirty: false,
            selectedNewUsed: "New/Used/Wholesale",
            accountNumbers: null,
            selectedAccountNumber: null,
            displayAccountNumber: "",
            adjustTypes: this.adjustTypes,
            selectedAdjustType: this.adjustTypes[0],
            amount: "",
            selectedReason: null,
            comments: "",
            lastUpdatedBy: "",
            manual: false,
        };

        this.onStoreClick = this.onStoreClick.bind(this);
        this.onDealNumberChange = this.onDealNumberChange.bind(this);
        this.onStockNumberChange = this.onStockNumberChange.bind(this);
        this.onLookupClick = this.onLookupClick.bind(this);
        this.onStockNumberLookupClick = this.onStockNumberLookupClick.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onNewUsedClick = this.onNewUsedClick.bind(this);
        this.onAccountNumberClick = this.onAccountNumberClick.bind(this);
        this.onAccountNumberChange = this.onAccountNumberChange.bind(this);
        this.onAdjustTypeClick = this.onAdjustTypeClick.bind(this);
        this.onManualChanged = this.onManualChanged.bind(this);
        this.onReasonClick = this.onReasonClick.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onSaveNewClick = this.onSaveNewClick.bind(this);
        this.onSaveCloseClick = this.onSaveCloseClick.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.accountNumbers && this.state.accountNumbers === null) {
                if (this.props.rowData) {
                    this.filterAccountNumbers(this.props.rowData.store_number, this.props.rowData.new_used_wholesale);
                }
                else {
                    this.filterAccountNumbers(this.state.selectedStore.store_number, this.props.rowData.new_used_wholesale);
                }
            }

            if (this.props.rowData === null) {
                let frontEndAdjustmentDate = this.state.frontEndAdjustmentDate;
                let minAdjustmentDate = this.state.minAdjustmentDate;
                let maxAdjustmentDate = this.state.maxAdjustmentDate;

                if (prevProps.adjustmentDate !== this.props.adjustmentDate) {
                    frontEndAdjustmentDate = moment();
                    minAdjustmentDate = moment().startOf("month");
                    maxAdjustmentDate = moment().endOf("month");

                    let previousMonthStart = moment().subtract(1, "month").startOf("month");
                    let currentMonthStart = moment().startOf("month");
                    let selectedMonth = moment(this.props.adjustmentDate).startOf("month");

                    if (previousMonthStart <= selectedMonth && selectedMonth < currentMonthStart) {
                        frontEndAdjustmentDate = moment(this.props.adjustmentDate).endOf("month");
                        minAdjustmentDate = moment(this.props.adjustmentDate).startOf("month");
                        maxAdjustmentDate = moment(this.props.adjustmentDate).endOf("month");
                    }
                }

                if (this.state.id
                    || (!this.state.lastUpdatedBy && this.props.lastUpdatedBy)
                    || prevProps.adjustmentDate !== this.props.adjustmentDate) {
                    this.setState({
                        id: null,
                        lastUpdatedBy: this.props.lastUpdatedBy,
                        frontEndAdjustmentDate: frontEndAdjustmentDate,
                        minAdjustmentDate: minAdjustmentDate,
                        maxAdjustmentDate: maxAdjustmentDate
                    });
                }
            } else if (this.props.rowData !== prevProps.rowData) {
                let selectedStore = this.props.stores.filter(storeRow => {
                    return storeRow.store_number === this.props.rowData.store_number;
                });

                let selectedAccountNumber = null;
                if (this.props.rowData.account_number) {
                    selectedAccountNumber = this.props.accountNumbers.filter(accountRow => {
                        return accountRow.display_name === this.props.rowData.account_number;
                    });
                }

                let selectedReason = null;
                if (this.props.rowData.reason) {
                    selectedReason = this.props.comments.filter(commentRow => {
                        return commentRow.Description === this.props.rowData.reason;
                    });
                }

                let adjustTypes = this.props.rowData.type === "Manual" ? this.manualTypes : this.adjustTypes;
                let selectedAdjustType = adjustTypes.filter(adjustTypeRow => {
                    return adjustTypeRow.key === this.props.rowData.type;
                });

                this.filterAccountNumbers(this.props.rowData.store_number, this.props.rowData.new_used_wholesale);

                this.setState({
                    id: this.props.rowData.id,
                    frontEndAdjustmentDate: moment(this.props.rowData.adjustment_date),
                    selectedStore: selectedStore[0],
                    manual: this.props.rowData.type === "Manual",
                    dealNumber: this.props.rowData.deal_number ? this.props.rowData.deal_number : "",
                    frontEndDealDate: this.props.rowData.deal_date ? moment(this.props.rowData.deal_date) : null,
                    customerNumber: this.props.rowData.customer_number,
                    customerName: this.props.rowData.customer_name,
                    stockNumber: this.props.rowData.stock_number ? this.props.rowData.stock_number : "",
                    selectedNewUsed: this.props.rowData.new_used_wholesale === null ? "New/Used/Wholesale" : this.props.rowData.new_used_wholesale,
                    selectedAccountNumber: selectedAccountNumber?.length > 0 ? selectedAccountNumber[0] : null,
                    displayAccountNumber: selectedAccountNumber?.length > 0 ? selectedAccountNumber[0].display_name : "",
                    adjustTypes: adjustTypes,
                    selectedAdjustType: selectedAdjustType[0],
                    amount: this.props.rowData.amount,
                    selectedReason: selectedReason ? selectedReason[0] : null,
                    lastUpdatedBy: this.props.rowData.last_updated_by,
                    comments: this.props.rowData.comments ? this.props.rowData.comments : ""
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Front-end Adjustment";
    adjustTypes = [
        { key: "Adjust", value: "Adjust" }
    ];
    manualTypes = [
        { key: "Manual", value: "Manual" }
    ];


    validateForm() {
        let isSaveEnabled = false;
        if (this.state.manual) {
            isSaveEnabled = this.state.frontEndAdjustmentDate !== null && this.state.frontEndAdjustmentDate !== ""
                && this.state.selectedStore !== null
                && this.state.selectedAdjustType !== null
                && this.state.selectedNewUsed !== "New/Used/Wholesale"
                && this.state.selectedNewUsed !== null
                && this.state.selectedAccountNumber !== null
                && this.state.amount !== ""
                && this.state.comments !== ""
                && this.state.selectedReason !== null
        }
        else {
            isSaveEnabled = this.state.frontEndAdjustmentDate !== null && this.state.frontEndAdjustmentDate !== ""
                && this.state.stockNumber !== ""
                && this.state.selectedStore !== null
                && this.state.frontEndDealDate !== null && this.state.frontEndDealDate !== ""
                && this.state.customerNumber !== ""
                && this.state.customerName !== ""
                && this.state.selectedNewUsed !== "New/Used/Wholesale"
                && this.state.selectedNewUsed !== null
                && this.state.selectedAdjustType !== null
                && this.state.selectedAccountNumber !== null
                && this.state.amount !== ""
                && this.state.comments !== ""
                && this.state.selectedReason !== null
        }

        this.setState({
            isSaveEnabled: isSaveEnabled
        });
    }

    setData() {
        if (this.state.salesDetails && (!isNaN(parseInt(this.state.dealNumber)) || this.state.stockNumber)) {
            this.setState({
                selectedStore: this.state.stockNumber ? this.props.stores.filter(storeRow => storeRow.store_number === this.state.salesDetails.store_number)[0] : this.state.selectedStore,
                dealNumber: this.state.salesDetails.deal_number,
                frontEndDealDate: this.state.salesDetails.contract_date ? moment(this.state.salesDetails.contract_date) : null,
                customerName: this.state.salesDetails.customer_name,
                customerNumber: this.state.salesDetails.customer_number,
                stockNumber: this.state.stockNumber ? this.state.stockNumber : this.state.salesDetails.stock_number,
                selectedNewUsed: this.state.salesDetails.new_used_wholesale === null ? "New/Used/Wholesale" : this.state.salesDetails.new_used_wholesale
            }, this.filterAccountNumbers(this.state.salesDetails.store_number, this.state.salesDetails.new_used_wholesale));
        } else {
            this.setState({
                dealNumber: "",
                frontEndDealDate: null,
                customerName: "",
                customerNumber: "",
                stockNumber: "",
                selectedNewUsed: "New/Used/Wholesale",
                selectedAccountNumber: null
            }, this.filterAccountNumbers(this.state.selectedStore?.store_number, this.state.selectedNewUsed));
        }
    }

    filterAccountNumbers(storeNumber, selectedNewUsed) {
        let filteredAccountNumbers = this.props.accountNumbers;
        if (storeNumber !== null && selectedNewUsed !== "New/Used/Wholesale") {
            filteredAccountNumbers = filteredAccountNumbers.filter(row => {
                return row.StoreNumber === storeNumber && row.Type === selectedNewUsed;
            });
        }
        else {
            filteredAccountNumbers = [];
        }

        this.setState({
            accountNumbers: filteredAccountNumbers
        }, this.validateForm);
    }

    getFrontEndSalesDetails() {
        if (isNaN(parseInt(this.state.dealNumber)) || this.state.selectedStore === null) {
            this.setState({
                salesDetails: null
            }, this.setData);
        }
        else {
            let inputData = {
                storeNumber: parseInt(this.state.selectedStore.store_number),
                dealNumber: parseInt(this.state.dealNumber)
            };
            this.props.applicationInsights.trackTrace({ message: "/FrontEndAdjustmentAddComponent/getFrontEndSalesDetails", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FrontEndAdjustments/GetFrontEndSalesDetails", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        salesDetails: parsedResponse[0],
                        isDealNumberDirty: false
                    }, this.setData);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FrontEndAdjustments/GetFrontEndSalesDetails", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
    }

    getSalesDetailByStockNumber() {
        if (!this.state.stockNumber) {
            this.setState({
                salesDetails: null
            }, this.setData);
        }
        else {
            let inputData = {
                stockNumber: this.state.stockNumber
            };
            this.props.applicationInsights.trackTrace({ message: "/FrontEndAdjustmentAddComponent/getSalesDetailByStockNumber", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FrontEndAdjustments/GetSalesDetailByStockNumber", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        salesDetails: parsedResponse[0],
                        isStockNumberDirty: false
                    }, this.setData);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FrontEndAdjustments/GetSalesDetailByStockNumber", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
    }

    onStoreClick(id, store) {
        try {
            this.setState({
                selectedStore: store,
                selectedAccountNumber: null
            }, this.filterAccountNumbers(store.store_number, this.state.selectedNewUsed));

        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAccountNumberChange(id, filterValue) {
        try {
            let filteredData = [];
            let accountNumbers = [];
            if (this.state.selectedNewUsed !== "New/Used/Wholesale")
                accountNumbers = this.props.accountNumbers.filter(row => {
                    return row.StoreNumber === this.state.selectedStore.store_number && row.Type === this.state.selectedNewUsed;
                });
            else {
                accountNumbers = this.props.accountNumbers.filter(row => {
                    return row.StoreNumber === this.state.selectedStore.store_number;
                });
            }

            if (accountNumbers.length > 0) {
                filteredData = accountNumbers.filter(accountRow => {
                    return accountRow.display_name.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0
                });
            }

            this.setState({
                displayAccountNumber: filterValue,
                selectedAccountNumber: filteredData.length === 1 ? filteredData[0] : null,
                accountNumbers: filteredData
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAccountNumberClick(event, selectedAccountNumber) {
        try {
            this.setState({
                selectedAccountNumber: selectedAccountNumber,
                displayAccountNumber: selectedAccountNumber.display_name
            }, this.validateForm);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onLookupClick(id, value) {
        try {
            this.setState({
                [id]: value
            }, this.getFrontEndSalesDetails);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStockNumberLookupClick(id, value) {
        try {
            this.setState({
                [id]: value
            }, this.getSalesDetailByStockNumber);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDateChange(id, value) {
        try {
            this.setState({
                [id]: moment(value)
            }, this.validateForm);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDealNumberChange(id, value) {
        try {
            this.setState({
                [id]: value,
                isDealNumberDirty: value !== ""
            }, this.validateForm);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStockNumberChange(id, value) {
        try {
            this.setState({
                [id]: value,
                isStockNumberDirty: value !== ""
            }, this.validateForm);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({
                [id]: value
            }, this.validateForm);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onNewUsedClick(id, selectedNewUsed) {
        try {
            this.setState({
                selectedNewUsed: selectedNewUsed
            }, this.filterAccountNumbers(this.state.selectedStore?.store_number, selectedNewUsed));
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onReasonClick(event, selectedReason) {
        try {
            this.setState({
                selectedReason: selectedReason
            }, this.validateForm);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onManualChanged(id, value) {
        try {
            let adjustTypes = this.state.adjustTypes;
            let selectedAdjustType = this.state.selectedAdjustType;

            if (id === "manual") {
                if (value) {
                    adjustTypes = this.manualTypes;
                    selectedAdjustType = this.manualTypes[0];
                } else {
                    adjustTypes = this.adjustTypes;
                    selectedAdjustType = this.adjustTypes[0];
                }
            }

            this.setState({
                [id]: value,
                adjustTypes: adjustTypes,
                selectedAdjustType: selectedAdjustType
            }, this.validateForm);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAdjustTypeClick(id, selectedAdjustType) {
        try {
            this.setState({
                selectedAdjustType: selectedAdjustType
            }, this.validateForm);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveNewClick(event) {
        this.onSaveClick(event);
        this.setState({
            dealNumber: "",
            frontEndDealDate: null,
            customerNumber: "",
            stockNumber: "",
            selectedNewUsed: "New/Used/Wholesale",
            customerName: "",
            accountNumbers: [],
            selectedAccountNumber: null,
            displayAccountNumber: "",
            selectedAdjustType: this.adjustTypes[0],
            lastUpdatedBy: "",
            amount: "",
            comments: "",
            selectedReason: null,
            isSaveEnabled: false,
            manual: false
        });
    }

    onSaveCloseClick(event) {
        this.onSaveClick(event);
        this.onCancelClick(event);
    }

    onSaveClick(event) {
        try {
            if (this.state.manual) {
                this.props.onSaveClick(event, {
                    id: this.state.id,
                    adjustmentDate: CommonService.formatServerDateTime(this.state.frontEndAdjustmentDate),
                    adjustType: this.state.selectedAdjustType.key,
                    storeNumber: this.state.selectedStore.store_number,
                    newUsedWhsl: this.state.selectedNewUsed === "New/Used/Wholesale" ? null : this.state.selectedNewUsed,
                    accountNumber: this.state.selectedAccountNumber ? this.state.selectedAccountNumber.AccountNumber : null,
                    accountDescription: this.state.selectedAccountNumber ? this.state.selectedAccountNumber.Description : null,
                    type: this.state.selectedAdjustType.key,
                    amount: this.state.amount,
                    reason: this.state.selectedReason ? this.state.selectedReason.Description : "",
                    lastUpdatedBy: this.state.lastUpdatedBy,
                    comments: this.state.comments
                });
            } else {
                this.props.onSaveClick(event, {
                    id: this.state.id,
                    adjustmentDate: this.state.frontEndAdjustmentDate !== null ? CommonService.formatServerDateTime(this.state.frontEndAdjustmentDate) : null,
                    adjustType: this.state.selectedAdjustType.key,
                    storeNumber: this.state.selectedStore.store_number,
                    dealDate: this.state.frontEndDealDate !== null ? CommonService.formatServerDateTime(this.state.frontEndDealDate) : null,
                    dealNumber: this.state.dealNumber,
                    customerNumber: this.state.customerNumber,
                    customerName: this.state.customerName,
                    stockNumber: this.state.stockNumber,
                    newUsedWhsl: this.state.selectedNewUsed === "New/Used/Wholesale" ? null : this.state.selectedNewUsed,
                    accountNumber: this.state.selectedAccountNumber ? this.state.selectedAccountNumber.AccountNumber : null,
                    accountDescription: this.state.selectedAccountNumber ? this.state.selectedAccountNumber.Description : null,
                    type: this.state.selectedAdjustType.key,
                    amount: this.state.amount,
                    reason: this.state.selectedReason ? this.state.selectedReason.Description : "",
                    lastUpdatedBy: this.state.lastUpdatedBy,
                    comments: this.state.comments
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                dealNumber: "",
                frontEndDealDate: null,
                customerNumber: "",
                stockNumber: "",
                selectedNewUsed: "New/Used/Wholesale",
                customerName: "",
                accountNumbers: [],
                selectedAccountNumber: null,
                displayAccountNumber: "",
                selectedAdjustType: this.adjustTypes[0],
                lastUpdatedBy: "",
                amount: "",
                comments: "",
                selectedReason: null,
                isSaveEnabled: false,
                manual: false
            });

            this.props.onCancelClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.props.showDialog}
            cssName="dgdg-front-end-account-detail-dialog"
            title={this.props.title}
            onCancelClick={this.onCancelClick}
        >
            <DGDGModalDialogHeaderButtonComponent>
                <button className="btn btn-primary" onClick={this.onSaveNewClick} disabled={!this.state.isSaveEnabled || this.state.isDealNumberDirty || this.state.isStockNumberDirty}>
                    Save & New  <i className="fas fa-save dgdg-icon" />
                </button>
                <button className="btn btn-primary" onClick={this.onSaveCloseClick} disabled={!this.state.isSaveEnabled || this.state.isDealNumberDirty || this.state.isStockNumberDirty} style={{ "marginLeft": "15px" }}>
                    Save & Close  <i className="fas fa-save dgdg-icon" />
                </button>
            </DGDGModalDialogHeaderButtonComponent>
            <div className="dgdg-report-body">
                <div className="card">
                    <div className="card-block">
                        <table className="text-nowrap dgdg-front-end-table">
                            <tbody>
                                <tr>
                                    <td className="dgdg-font-blue">
                                        Adjustment Date:<span className="text-danger">*</span>
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td>
                                        <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="frontEndAdjustmentDate" onDateChange={this.onDateChange}
                                            value={this.state.frontEndAdjustmentDate}
                                            minDate={this.state.minAdjustmentDate ? this.state.minAdjustmentDate.format("MM/DD/YYYY") : this.state.minAdjustmentDate}
                                            maxDate={this.state.maxAdjustmentDate ? this.state.maxAdjustmentDate.format("MM/DD/YYYY") : this.state.maxAdjustmentDate}
                                        />
                                    </td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue dgdg-front-end-table-label">
                                        Stock #:<span className="text-danger">*</span>
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent id="stockNumber" disabled={this.state.manual} value={this.state.stockNumber} onChange={this.onStockNumberChange} onLookupClick={this.onStockNumberLookupClick} />
                                    </td>
                                    <td />
                                    <td className="dgdg-font-blue dgdg-font-blue dgdg-front-end-table-label">
                                        Manual:
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td>
                                        <DGDGFormCheckboxComponent disabled={this.state.selectedStore === null} id="manual" value={this.state.manual} onChange={this.onManualChanged} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dgdg-font-blue">
                                        Store Name:<span className="text-danger">*</span>
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td>
                                        <DGDGFormDropdownComponent data={this.props.stores} itemKey="display_name" value={this.state.selectedStore ? this.state.selectedStore.display_name : "Select Store"} onItemClick={this.onStoreClick} />
                                    </td>
                                    <td />
                                    <td className="dgdg-font-blue dgdg-front-end-table-label">
                                        Deal Number:
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent disabled={this.state.selectedStore === null || this.state.manual} id="dealNumber" value={this.state.dealNumber} onChange={this.onDealNumberChange} inputType="number" onLookupClick={this.onLookupClick} />
                                    </td>
                                    <td />
                                    <td className="dgdg-font-blue dgdg-front-end-table-label">
                                        Deal Date:<span className="text-danger">*</span>
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} disabled={this.state.dealNumber !== "" || this.state.stockNumber !== "" || this.state.selectedStore === null || this.state.manual} id="frontEndDealDate"
                                            value={this.state.frontEndDealDate}
                                            maxDate={moment().format("MM/DD/YYYY")}
                                            onDateChange={this.onDateChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dgdg-font-blue">
                                        Customer Number:<span className="text-danger">*</span>
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent disabled={this.state.dealNumber !== "" || this.state.stockNumber !== "" || this.state.selectedStore === null || this.state.manual} id="customerNumber" value={this.state.customerNumber} onChange={this.onTextChange} />
                                    </td>
                                    <td />
                                    <td className="dgdg-font-blue dgdg-front-end-table-label">
                                        Customer Name:<span className="text-danger">*</span>
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent disabled={this.state.dealNumber !== "" || this.state.stockNumber !== "" || this.state.selectedStore === null || this.state.manual} id="customerName" value={this.state.customerName} onChange={this.onTextChange} />
                                    </td>
                                    <td />
                                    <td className="dgdg-font-blue dgdg-front-end-table-label">
                                        Adjust Type:<span className="text-danger">*</span>
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormDropdownComponent data={this.state.adjustTypes} itemKey="value" value={this.state.selectedAdjustType ? this.state.selectedAdjustType.value : "Adjust Type"} onItemClick={this.onAdjustTypeClick} disabled />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dgdg-font-blue">
                                        New/Used/Wholesale:<span className="text-danger">*</span>
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormDropdownComponent disabled={this.state.dealNumber !== "" || this.state.stockNumber !== "" || this.state.selectedStore === null} data={["New", "Used", "Wholesale"]} value={this.state.selectedNewUsed} onItemClick={this.onNewUsedClick} />
                                    </td>
                                    <td />
                                    <td className="dgdg-font-blue dgdg-front-end-table-label">
                                        Account Number:<span className="text-danger">*</span>
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormComboBoxComponent disabled={this.state.selectedStore === null} id="ddpAccount" data={this.state.accountNumbers} itemKey="display_name" value={this.state.displayAccountNumber} onItemClick={this.onAccountNumberClick} onItemChange={this.onAccountNumberChange} autoDrop />
                                    </td>
                                    <td />
                                    <td className="dgdg-font-blue dgdg-front-end-table-label">
                                        Amount:<span className="text-danger">*</span>
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent disabled={this.state.selectedStore === null} id="amount" value={this.state.amount} onChange={this.onTextChange} inputType="money" />
                                    </td>
                                    <td />
                                    <td />
                                </tr>
                                <tr>
                                    <td className="dgdg-font-blue">
                                        Reason:<span className="text-danger">*</span>
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormDropdownComponent disabled={this.state.selectedStore === null} data={this.props.comments} itemKey="Description" value={this.state.selectedReason ? this.state.selectedReason.Description : "Select Reason"} onItemClick={this.onReasonClick} />
                                    </td>
                                    <td />
                                    <td className="dgdg-font-blue dgdg-front-end-table-label">
                                        Last Updated By:<span className="text-danger">*</span>
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent disabled={this.state.selectedStore === null} id="lastUpdatedBy" value={this.state.lastUpdatedBy} readOnly />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dgdg-font-blue">
                                        Comments:<span className="text-danger">*</span>
                                    </td>
                                    <td />
                                    <td colSpan="5" className="dgdg-front-end-table-comment">
                                        <DGDGFormInputComponent disabled={this.state.selectedStore === null} id="comments" value={this.state.comments} onChange={this.onTextChange} maxLength="100" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </DGDGModalDialogComponent>;
    }
}
