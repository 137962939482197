import React from "react";
import { Link } from "react-router-dom";

export class DashboardQuickLinksComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let quickLinks = [];
        if (this.props.dashboardItem) {
            this.props.dashboardItem.data.forEach(data => {
                if (data === "EmployeeTimeTrack") {
                    quickLinks.push(
                        <Link key="salesEmployeeTimeTrack" className="btn btn-info" to="/SalesEmployeeTimeTrack">
                            Employee Time Track <i className="fas fa-link dgdg-icon" />
                        </Link>
                    );
                    quickLinks.push(<br key="break1" />);
                    quickLinks.push(<br key="break2" />);
                }
                if (data === "FinancialStatement") {
                    quickLinks.push(
                        <Link key="financialStatement" className="btn btn-info" to="/FinancialStatement">
                            Financial Statement <i className="fas fa-link dgdg-icon" />
                        </Link>
                    );
                }
            });
        }
        return <div className="dgdg-widget-item">
            {quickLinks}
        </div>;
    }
}
