import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGFormCalendarComponent } from "../common/dgdgcalendar/DGDGFormCalendarComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";

export class FinancialStatementQueryComponent extends React.Component {
    static StoreNumberCookie = "FinancialStatementStoreNumberCookie";

    constructor(props) {
        super(props);
        this.state = {
            selectedMonth: null,
            selectedStore: null,
            minDate: "01/01/2017",
            currentDate: null,
            disableDate: false
        };

        this.onStoreClick = this.onStoreClick.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onGetStatementClick = this.onGetStatementClick.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidMount() {
        try {
            let currentDate = moment().subtract(1, 'months').startOf('month');
            this.getCurrentDate(currentDate);
            CommonService.clientAzureStorageLog("FinancialStatementQueryComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.stores && this.state.selectedStore === null) {
                let selectedStore = this.props.stores[0];
                let cookieStoreNumber = CommonService.getCookie(FinancialStatementQueryComponent.StoreNumberCookie);
                if (cookieStoreNumber) {
                    cookieStoreNumber = parseInt(cookieStoreNumber);
                    let filterStore = this.props.stores.filter(store => store.store_number === cookieStoreNumber);
                    if (filterStore.length === 1) {
                        selectedStore = filterStore[0];
                    }
                }

                this.setState({ selectedStore: selectedStore });
            } else {
                //if (!prevProps.failOverMonth && this.props.failOverMonth) {
                //    this.setState({
                //        selectedMonth: this.props.failOverMonth
                //    }, () => {
                //        this.props.onGetStatementClick(this.state.selectedMonth, this.state.selectedStore, false);
                //    });
                //}
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStoreClick(event, store) {
        try {
            this.setState({ selectedStore: store });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDateChange(id, value) {
        try {
            this.setState({
                [id]: moment(value),
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetStatementClick(event) {
        try {
            CommonService.setCookie(FinancialStatementQueryComponent.StoreNumberCookie, this.state.selectedStore.store_number, 30);
            this.props.onGetStatementClick(this.state.selectedMonth, this.state.selectedStore, false);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDownloadClick(event) {
        try {
            this.props.onDownloadClick(this.state.selectedMonth, this.state.selectedStore);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGetStatementClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getCurrentDate(selectedMonth) {
        try {
            let inputData = {
                month: CommonService.formatServerDateTime(selectedMonth),
            };

            this.props.applicationInsights.trackTrace({ message: "/FinancialStatementQueryComponent/getCurrentDate", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FinancialStatement/GetCurrentDate", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse.status === "BlobFound") {
                        this.setState({
                            currentDate: selectedMonth.format('MM/DD/YYYY'),
                            selectedMonth: selectedMonth
                        }, () => {
                            if (this.state.selectedStore && !this.props.showSpinner) {
                                this.props.onGetStatementClick(this.state.selectedMonth, this.state.selectedStore, true);
                            }
                        });
                    }
                    else {
                        if (selectedMonth.format('MM/DD/YYYY') === moment().subtract(2, 'months').startOf('month').format('MM/DD/YYYY')) {
                            this.setState({
                                disableDate: true
                            });
                        }
                        else {
                            this.getCurrentDate(selectedMonth.subtract(1, 'months').startOf('month'));
                        }
                    }
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("warning", parsedError, 4000);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="card dgdg-card-gutter">
            <div className="card-block">
                <div className="form-inline dgdg-fs-query">
                    <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="selectedMonth" label="Date" value={this.state.selectedMonth} onDateChange={this.onDateChange} numberOfMonths={1} showDate={[1]} minDate={this.state.minDate} maxDate={this.state.currentDate} disabled={this.state.disableDate} onKeyDown={this.onKeyDown} />
                    <DGDGFormDropdownComponent label="Store #" data={this.props.stores} itemKey="display_name" value={this.state.selectedStore ? this.state.selectedStore.display_name : ""} onItemClick={this.onStoreClick} onKeyDown={this.onKeyDown} />
                    <button disabled={this.props.showSpinner || this.state.selectedStore === null || this.state.selectedMonth === null} className="btn btn-primary dgdg-icon" onClick={(event) => this.onGetStatementClick(event)}>Get Statement</button>
                    <button disabled={this.props.showSpinner || this.state.selectedStore === null || this.state.selectedMonth === null} className="btn btn-primary dgdg-icon" onClick={(event) => this.onDownloadClick(event)}>Download<span className="far fa-download dgdg-icon" /></button>
                    <DGDGSpinnerComponent showSpinner={this.props.showSpinner} />
                </div>
            </div>
        </div>;
    }
}
